.show {
  display: block;
}
.hide {
  display: none;
}
.show-for-large {
  display: block;
}

.invis{
  opacity: 0;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently                         supported by Chrome and Opera */
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

#internships-react-main-wrapper {
	.intern-possibility {
		.accordion-title.item-title-hidden {
			.accordion__button[aria-expanded='true'] {
				.fa-chevron-down {
					&:before {
						content: "\f077";
					}
					color: rgb(0, 157, 214) !important;
				}
			}
			border-bottom: none !important;
		}
		.accordion-title-box {
			border-bottom: 1px solid #eaeaea !important;
		}
	}
}

@media only screen and (max-width: 991px) {
  .show-for-large {
    display: none;
  }
}
.react-container {
  .overlay {
    min-height: 350px;
  }
}
.overlay_filter {
  background-color: #ccc;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
  img {
    left: 50%;
    position: absolute;
    top: 50%;
  }
}
.path-node {
  .featured-top {
    margin-bottom: 50px;
	&.show-only-for-mobile {
		margin-bottom: 0;
	}
    /*.field--name-field-banner-info-text {
      .cd-intro .cd-words-wrapper {
        padding-bottom: 0px;
      }
    }*/
  }
  .carousel-container {
    position: relative;
    .slick-slider {
      width: 98%;
      margin: 0 auto;
    }
  }
  .styles_overlay__CLSq- {
    display: block;
    padding-top: 70px;
  }
}
#internships-react-main-wrapper {
  .internships-react-wrapper {
  .intern-instagram-main-wrapper {
  h1 {
	&.account-name {
		margin-bottom: 30px;
        margin-top: 50px;
        font-family: $font-sharpSans-Book;
		text-align: center;
	}
  }
  }
    h1 {
      margin-bottom: 60px;
      @include media-breakpoint-down(sm) {
        font-size: 26px;
      }
    }
    .main-title {
      cursor: pointer;
    }
    .banner-content-wrapper {
      h1 {
        margin-bottom: 0px;
      }
    }
    img {
      max-width: 100%;
    }
  }

  /*Dashboard Component Start*/
  .intern-banner-dashboard-main-wrapper {
    .intern-dashboard-title {
      @include media-breakpoint-up(lg) {
        display: none;
      }
      h1 {
        margin-bottom: 10px;
        font-family: $font-SourceSans-Semibold;
        font-size: 20px;
      }
      .title-border {
        @include media-breakpoint-down(md) {
          margin-bottom: 20px;
          border-bottom: 6px solid #896eb1;
          width: 65px;
        }
      }
    }
    .intern-dashboard-content {
      margin-top: 45px;
      .intern-dashboard-description {
        color: #2a2a2a;
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 40px;
          font-family: $font-sharpSans-Book;
          @include media-breakpoint-down(md) {
            font-size: 26px;
          }
        }
        p {
          font-size: 18px;
          padding-top: 22px;
        }
      }
      .upcoming-campus-link {
        margin-top: 20px;
        font-size: 18px;
        color: #009dd6;
        a {
          font-size: 20px;
          font-family: $font-sharpSans-Bold;
          color: #009dd6;
          text-decoration: none;
          padding-right: 10px;
        }
      }
    }
  }
  /*Dashboard Component End*/

  /* Locations Component Start*/
  .intern-locations-main-wrapper {
    padding-top: 50px;
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    @include media-breakpoint-down(md) {
      padding: 0 30px;
    }
	@include media-breakpoint-down(md) {
	  padding: 0 30px;
	}
	@include media-breakpoint-down(sm) {
	  padding-left: 0;
	  padding-right: 0;
	}
    .intern-locations-wrapper {
      .locations {
        .location {
          cursor: pointer;
          position: relative;
          min-height: 236px;
          font-family: $font-sharpSans-Medium;
          font-size: 22px;
          @include media-breakpoint-down(md) {
            font-size: 18px;
            font-family: $font-SourceSans-Semibold;
          }
          .logo-wrapper {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            @include media-breakpoint-down(sm) {
              //position: relative;
              max-width: 90%;
            }
            .title {
              padding-top: 10px;
              padding-bottom: 10px;
            }
            img {
              @include media-breakpoint-down(md) {
                margin: 0 auto;
              }
            }
          }
          .hover-logo {
            display: none;
          }
          .logo {
            display: block;
          }
          .location-border {
            display: none;
            width: 40px;
            margin: 0 auto;
          }
          .location-no-border {
            display: block;
            border: 3px solid #ffffff;
          }
          &.current{
            .hover-logo {
              display: block;
            }
            .logo {
              display: none;
            }
            .location-border {
              display: block;
              border-bottom-width: 6px;
              border-bottom-style: solid;
            }
            .location-no-border {
              display: none;
            }
          }
          &:hover {
              @include media-breakpoint-up(md) {
                .hover-logo {
                  display: block;
                }
                .logo {
                  display: none;
                }
                .location-border {
                  display: block;
                  border-width: 3px;
                  border-style: solid;
                }
                .location-no-border {
                  display: none;
                }
              }
          }
          img {
            max-width: 200px;
          }
		  &:hover {
			  @include media-breakpoint-up(md) {
				.hover-logo {
				  display: block;
				}
				.logo {
				  display: none;
				}
				.location-border {
				  display: block;
				  border-bottom-width: 6px;
				  border-bottom-style: solid;
				}
				.location-no-border {
				  display: none;
				}
			  }
		  }
		  img {
			max-width: 200px;
		  }
        }
      }
    }
  }
  /* Locations Component End*/
  /* Navigation Component Start*/
  .intern-navigation-main-wrapper {
    margin-top: 50px;
    background: #eeeeee;
    .intern-navigation-wrapper {
      .row {
        margin: 0px;
      }
      .left-navigation {
        margin: 60px 0;
        @include media-breakpoint-down(md) {
          margin: 0px 0px 40px;
          padding: 0px;
        }
        .location {
          margin-bottom: 60px;
          @include media-breakpoint-down(md) {
            margin: 40px 0;
          }
          .name {
            font-size: 23px;
            font-family: $font-SourceSans-Semibold;
            line-height: 50px;
          }
          line-height: 33px;
          .location-border {
            border-bottom-width: 6px;
            border-bottom-style: solid;
            width: 75px;
          }
        }
        .navigations {
          .navigation {
            margin-bottom: 15px;
            
            a {
              font-size: 20px;
              font-family: $font-SourceSans-Semibold;
              text-decoration: none;
              @include media-breakpoint-up(lg) {
                color: #2a2a2a !important;
              }
              &:hover {
                color: #0079c0 !important;
              }
            }
            > div {
              font-size: 20px;
              font-family: $font-SourceSans-Semibold;
              color: #212529;
              @include media-breakpoint-down(sm) {
                padding: 15px 0;
              }
              &.active {
                padding: 15px 20px;
                background: #dddddd;
                margin-left: -20px;
                margin-right: -20px;
              }
            }
            @include media-breakpoint-down(md) {
              margin-bottom: 30px;
              &:last-child {
                margin-bottom: 10px;
              }
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
              }
          }
        }
      }
      .right-navigation {
        margin: 65px 0;
        @include media-breakpoint-down(md) {
          margin: 0px;
          padding: 0px;
        }
        .MuiCardContent-root-5,
        .MuiCardContent-root-8,
        [class^="MuiCard"],
        [class^="MuiCollapse"] {
          @include media-breakpoint-down(md) {
            padding: 0px;
          }
        }
        .internship-content {
          @include media-breakpoint-up(lg) {
            padding: 0 30px;
            border-left: 1px solid #400095;
          }
          @include media-breakpoint-down(md) {
            margin: 0px;
            padding: 0px;
            border-top: 1px solid #a7a9ac;
          }
          .internship-logo {
            margin-bottom: 39px;
            @include media-breakpoint-down(md) {
              margin-bottom: 20px;
              margin-top: 35px;
            }
            img {
              max-width: 360px;
              @include media-breakpoint-down(sm) {
                max-width: 250px;
              }
            }
          }
          .internship-description {
            font-size: 18px;
            p {
              margin-bottom: 0.5rem;
              line-height: 30px;
              strong {
                font-family: $font-SourceSans-Bold;
                font-weight: normal;
              }
            }
            @include media-breakpoint-down(sm) {
              padding-bottom: 40px;
            }
          }
        }
      }
    }
  }
  /* Navigation Component End*/

  /* How to Apply Component Start*/
  .intern-how-to-apply-main-wrapper {
    margin-top: 50px;
    @include media-breakpoint-down(md) {
      padding: 0px 20px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 0px;
    }
    .MuiCardContent-root-7,
    .MuiCardContent-root-6 {
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
    h1 {
      text-align: center;
    }
    .banner-images {
      margin: 0px;
      @include media-breakpoint-down(md) {
        margin: 0px -20px;
      }
      .banner-image {
        padding: 0px;
        max-height: initial;
        overflow:hidden;
        @include media-breakpoint-down(md) {
          display: none;
          &:first-child {
            display: block;
          }
        }
        .imageloader {
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
        object-fit: cover;
        height: 100%;
		object-fit: cover;
		min-height:100%;
        }
      }
    }

    #how_to_apply {
      margin-top: 80px;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 30px;
      @include media-breakpoint-down(md) {
        margin-top: 60px;
        border-bottom: none;
        padding-bottom: 0px;
        .how-to-apply-desktop {
          display: none;
        }
      }
      @include media-breakpoint-down(sm) {
        margin-top: 0px;
      }
      .MuiCardContent-root-5 {
        padding: 0px !important;
      }
      h1 {
        margin-bottom: 30px;
        margin-top: 80px;
        font-family: $font-sharpSans-Book;
      }
      .steps {
        -webkit-column-width: 15em;
        -moz-column-width: 15em;
        column-width: 350px;
        display: block;
        .step {
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 30px;
          span {
            &.name {
              color: #896eb1;
              font-family: $font-SourceSans-Bold;
            }
          }
        }
      }
      .steps > .step {
        break-inside: avoid-column;
        -webkit-column-break-inside: avoid;
      }
      .container-fluid-lg {
        padding: 0px;
      }
    }
    .how-to-apply-top {
      display: none;
      @include media-breakpoint-down(md) {
        font-family: $font-sharpSans-Book;
        display: block;
        font-size: 26px;
        margin: 0;
        padding: 30px 0px;
        text-align: left;
        border-top: 1px solid #eaeaea;
      }
      @include media-breakpoint-down(sm) {
        border: none;
      }
    }
  }
  /* How to Apply Component End*/
  /* Intern Possibilities Component Start*/
  .intern-possibilities-main-wrapper {
    margin-top: 50px;
    padding: 0 100px;
    @include media-breakpoint-down(md) {
      padding: 0px;
      margin-top: 0px;
    }
    h1 {
      text-align: center;
      margin-bottom: 30px;
      font-family: $font-sharpSans-Book;
      @include media-breakpoint-down(md) {
        padding: 30px 0;
        margin: 0;
        text-align: left;
        border-bottom: 1px solid #eaeaea;
      }
    }
    .intern-possibilities-wrapper {
      position: relative;
      .ip-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
      }
      .intern-possibilities {
        &.overlay-show {
          opacity: 0.1;
        }
        .row {
          @include media-breakpoint-down(lg) {
            margin: 0px;
          }
        }
        .intern-possibility {
          margin-bottom: 20px;
          overflow: hidden;
          @include media-breakpoint-down(md) {
            margin-bottom: 0px;
            padding: 0px;
          }
          .outer-ip-box {
            cursor: pointer;
            height: 174px;
            background: #eeeeee;
            .title {
              position: relative;
              top: 50%;
              transform: translateY(-50%);
              text-align: center;
              font-size: 20px;
              font-family: $font-sharpSans-Semibold;
              padding: 0 30px;
            }
          }
          .outer-ip-box::after {
            content: "";
            background: #009dd6;
            width: 94%;
            height: 12px;
            position: absolute;
            bottom: 0px;
            -webkit-transform: translateY(6px);
            -o-transform: translateY(6px);
            transform: translateY(6px);
            -webkit-transition-property: transform;
            -webkit-transition-property: -webkit-transform;
            transition-property: -webkit-transform;
            -o-transition-property: -o-transform;
            transition-property: transform;
            transition-property: transform, -webkit-transform, -o-transform;
            -webkit-transition-duration: 0.3s;
            -o-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            -o-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
          .outer-ip-box:hover:after {
            -webkit-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
          }
          .ip-border {
            //border: 3px solid #00aeef;
          }
          .accordion__body--hidden {
            display: none;
          }
        }
      }
      .accordion-item-body {
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 30px;
        .content {
          .title {
            color: #009DD6;
            font-size: 30px;
            margin-top: 20px;
          }
          .description {
            font-size: 16px;
            .light-blue {
              color: #009DD6;
            }
          }
          h5 {
            color: #009DD6;
          }
          .logo1,
          .logo2 {
            img {
              //max-width: 50%;
              float: left;
            }
          }
        }
      }
      .accordion-item-body.accordion__body--hidden {
        border: none;
      }
      .intern-possibilities-popup {
        .content {
          .title {
            color: #009dd6;
            font-size: 30px;
            margin-top: 75px;
            padding: 0 75px 0 40px;
          }
          .description {
            font-size: 16px;
            padding: 0 75px 0 40px;
            .light-blue {
              color: #009DD6;
            }
            strong {
              font-family: "SourceSansPro-Semibold";
              font-weight: normal;
            }
          }
          h5 {
            color: #009DD6;
          }
          @include media-breakpoint-up(lg) {
            .logo1, .logo2 {
                height: 50%;
                .imageloader {
                    height: 100%;
                }
            }
              img {
                width: 100%;
                height: 100%;
                object-fit:cover;
              }
            }
        }
      }
    }
    .popup-overlay {
      position: relative !important;
      min-height: 780px;
      width: 100%;
      z-index: 99 !important;
      .popup-content {
        min-height: 780px;
        padding: 0 !important;
        width: 100% !important;
        background: #eeeeee !important;
        border: none !important;
        top: 0;
        .modal2 {
            min-height: 780px;
            .content {
                min-height: 780px;
            }
          .close {
            top: 60px;
            right: 52px;
            background: unset;
          }
        }
        @include media-breakpoint-up(lg) {
          img {
            width: 100%;
            height: auto;
            min-height: 390px;
            object-fit:cover;
          }
        }
      }
    }
  }
  /* Intern Possibilities Component End*/

  /* Open positions Component start */

  .intern-possibility .accordion-title {
    outline: 0;
    .fa-chevron-down {
      font-size: 20px;
      position: absolute;
      right: 0;
      top: 15px;
      &:before {
        content: "\f077";
      }
    }
    @include media-breakpoint-down(md) {
      border-bottom: none;
      margin: 0px auto !important;
      padding: 15px 0;
      //background: url("../images/resources/up-arrow.png") no-repeat scroll;
      background-size: 18px 12px;
      background-position: 99.7% 20px;
    }
  }
  .intern-possibility .accordion-title.item-title-hidden:hover {
    @include media-breakpoint-down(md) {
      border-bottom: 1px solid #eaeaea;
      margin: 0px auto !important;
      padding: 15px 0;
      //background: url("../images/resources/down-arrow-orange.png") no-repeat scroll;
      background-size: 18px 12px;
      background-position: 99.7% 20px;
    }
  }
  .intern-possibility .accordion-title.item-title-hidden {
    .fa-chevron-down {
      font-size: 20px;
      position: absolute;
      right: 0;
      top: 15px;
      color: inherit !important;
      &:before {
        content: "\f078";
      }
    }
    @include media-breakpoint-down(md) {
      border-bottom: 1px solid #eaeaea;
      margin: 0px auto !important;
      padding: 15px 0;
      //background: url("../images/resources/down-arrow.png") no-repeat scroll;
      background-size: 18px 12px;
      background-position: 99.7% 20px;
    }
  }

  .open-positions-wrapper .main-title,
  .faqs-wrapper .faqs-title,
  .open-possibilities-header,
  .how-to-apply-top {
    border-bottom: 1px solid #eaeaea;
    margin: 0px auto !important;
    padding: 30px 0;
    background: url("../images/resources/down-arrow.png") no-repeat scroll;
    background-size: 20px 20px;
    background-position: 99.7% 45px;

    &:hover {
      background: url("../images/resources/Arrow_Purple_Down_WhiteBG.png") no-repeat
        scroll;
      background-size: 20px 20px;
      background-position: 99.7% 45px;
    }
    &.true {
      background: url("../images/resources/Arrow_Purple_Up_WhiteBG.png") no-repeat scroll;
      background-size: 20px 20px;
      background-position: 99.7% 45px;
    }
    @include media-breakpoint-down(md) {
      font-size: 26px;
    }
  }
  @include media-breakpoint-up(lg) {
    .open-possibilities-header,
    .intern-possibility .accordion-title {
      background: none;
    }
  }
  @include media-breakpoint-down(sm) {
    .faqs-wrapper .faqs-title,
    .open-possibilities-header,
    .how-to-apply-top {
      background: none;
      &:hover,
      &.true {
        background: none;
      }
    }
  }
  [class^="MuiCard"],
  [class^="MuiCollapse"] {
    @include media-breakpoint-down(md) {
      padding: 0px;
    }
  }
  /* Open positions Component End */

  /* Videos Component Start*/
  .intern-videos-main-wrapper {
    margin-top: 90px;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-top: 0px;
    }
    .MuiCardContent-root-5 {
      padding: 0px !important;
    }
    .desktop {
        .video-description {
            width: 36%;
        }
        .video-wrapper {
            width: 64%;
        }
    }
    .video-description {
      background: #886db0;
      color: #ffffff;
      padding: 90px 0px;
      font-size: 18px;
      @include media-breakpoint-down(md) {
        padding: 30px 20px;
      }
      .video-inner-details {
        margin-bottom: 37px;
      }
      h2 {
        font-size: 40px;
        margin-bottom: 20px;
        font-family: $font-sharpSans-Book;
        @include media-breakpoint-down(md) {
          font-size: 34px;
          max-width: 100%;
        }
      }
      p {
        margin: 0 auto;
        margin-bottom: 20px;
        line-height: 30px;
        font-family: "SourceSansPro-Regular";
        @include media-breakpoint-up(lg){
          overflow: hidden;
          max-height: 180px;
        }
        @include media-breakpoint-down(md) {
          max-width: 100%;
        }
      }

      a,
      a:hover {
        text-decoration: none;
        font-family: $font-SourceSans-Bold;
        color: #ffffff;
      }
    }
    .video-wrapper {
      padding: 0;
      max-height: 520px;
		  overflow: hidden;
      .video-thumbnail {
        cursor: pointer;
      }
      .imageloader {
        width: 100%;
        height: 100%;
        max-height: initial;
        overflow:hidden;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .video-thumbnail {
        width: 100%;
        height: 100%;
      }
      .spin-loader {
        position: absolute;
        left: 50%;
        top: 50%;
      }
    }
    .row {
      margin: 0;
    }
    .popup-overlay {
      position: absolute !important;
      .popup-content {
        @include media-breakpoint-down(md) {
            width: 85% !important;
        }
      }
    }
  }
  /* Videos Component End*/

  .popup-overlay {
    background: rgba(255, 255, 255, 0.6) !important;
    display: block !important;
    .popup-content {
      top: 12%;
    }
    .modal2 > .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -50px;
      font-size: 40px;
      background: #ffffff;
      /*border-radius: 18px;
      border: 1px solid #cfcece;*/
      z-index: 999999;
      color: #000;
    }
  }

  .spin-loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .faqs-main-wrapper {
    .faqs-title {
      margin-top: 30px;
      margin-bottom: 10px;
      text-align: left !important;
      cursor: pointer;
    }
    .search-bar-container {
      display: none;
    }
    .accordion-container {
      .accordion-title-box:last-child {
        .item-title-hidden {
          border-bottom: none !important;
        }
      }
    }
  }
}

/* Jobs Component Start*/
.open-positions-main-wrapper {
  color: #2a2a2a;
  margin-top: 60px !important;
  padding-top: 60px !important;
  @include media-breakpoint-down(md) {
    padding-top: 40px !important;
    margin-top: 0px !important;
  }
  h1 {
    text-align: left;
    font-size: 40px;
    font-family: $font-sharpSans-Book;
    margin-bottom: 30px !important;
    &.main-title {
      @include media-breakpoint-up(lg) {
        color: #2a2a2a !important;
      }
    }
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 26px;
      margin-top: 30px;
    }
  }
  [class^="MuiCard"],
  [class^="MuiCollapse"] {
    @include media-breakpoint-down(md) {
      padding: 0px;
    }
  }

  .open-positions-selected-filters {
    .selected-filters {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 23px;
        align-items: center;
      }
      span {
        color: #400095;
        font-size: 16px;
        line-height: 24px;
      }
      i {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
  .no-jobs {
    font-family: $font-SourceSans-Bold;
  }

  .jobs-search-container{
    display: flex;
    @include media-breakpoint-down(md) {
      margin: 8px auto;
      display: block;
      width: 96%;
    }
    @include media-breakpoint-down(sm) {
      margin: 8px auto;
      display: block;
      width: 96%;
    }
    width: 80%;
    margin: 0 auto;

    .predictive-auto-search{
      @include media-breakpoint-down(md) {
        margin: 0 auto 8px;
        display: block;
      width: 100%;
      border-bottom: 0px;

      }
      width: 50%;
      .react-autosuggest__container{
        width: 100%;
        .react-autosuggest__suggestions-container{
          width: 40%;
          @include media-breakpoint-down(md) {
          width: 96%;
          }
        }
      }
    }



    .keyword-search{
      // flex: 6;
      width: 49%;
      // margin-left: 3%;
      display: flex;
      // border-bottom: 2px solid #a7a9ac;
      border-bottom: 2px solid #a7a9ac;

      @include media-breakpoint-down(md) {
        margin: 0 auto;
        display: block;
      width: 100%;
      border-bottom: 0px;

      }
      img{
        height: 16px;
        width: 16px;
        position: relative;
        top: 18px;
        right: 2%;
        cursor: pointer;
        border-bottom: none;
      
      }
    }
    .react-autosuggest__container{
      width: 50%;
      border-bottom: 2px solid #a7a9ac;
      @include media-breakpoint-down(md) {
        margin: 0 auto;
        display: block;
      width: 100%;
      }
      @include media-breakpoint-down(sm) {
        border: none;
      }
      .react-autosuggest__input{
        @include media-breakpoint-down(md) {
          border-width: 0px !important;
          border: none !important;
        }
        @include media-breakpoint-down(sm) {
          border-width: 0px !important;
          border: none !important;
        }
      }
    }
    .space-between{
      width: 8%;
      border: none;
    }
  }

  .intern-search-form{
    padding-top: 15px;
    padding-bottom: 20px;
    width: 35%;
    position: relative;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      margin: 0 auto;
      display: block;
    width: 100%;
    }

    .react-autosuggest__container{
      width: 100%;
      border-bottom: 2px solid #a7a9ac;
      @include media-breakpoint-down(md) {
        margin: 0 auto;
        display: block;
      width: 100%;
      }
      @include media-breakpoint-down(sm) {
        border: none;
      }
      .react-autosuggest__input{
        @include media-breakpoint-down(md) {
          border-width: 0px !important;
          border: none !important;
        }
        @include media-breakpoint-down(sm) {
          border-width: 0px !important;
          border: none !important;
        }
      }
      .react-autosuggest__suggestions-container{
        width: 100%;
      }
    }

    .intern-jobs-search-container input{
      width: 100%;
      border: none;
      font-size: 20px;
      height: 50px;
      outline: none;

        @include media-breakpoint-down(md) {
          background-color: #eeeeee;
          outline: none;
          border: none;
        }
        @include media-breakpoint-down(sm){
          border: none;
          width: 100%;
        }
    }
    .intern-jobs-search-container{
      margin-bottom: 50px;
      display: flex;
      position: relative;
      top: 20px;
      @include media-breakpoint-down(md) {
        background-color: #eeeeee;
        width: 50%;
        margin: 0 auto 50px;
        
      }
      @include media-breakpoint-down(sm) {
        background-color: #eeeeee;
        width: 100%;
        margin: 0 auto 50px;
        
      }
      .react-autosuggest__container{
        @include media-breakpoint-down(md) {
          border-bottom: none;

        }
      }
      // border-bottom: lightgray;

      .fa-arrow-right{
        position: relative;
        top: 20px;
        right: 16px;
        cursor: pointer;
      }
      // .intern-arrow{
      //   height: 22px;
      //   width: 22px;
      // }
    }
  }


  .search-form {
    margin: 0 auto;
    position: relative;
    @include media-breakpoint-down(md) {
      margin: 0 auto;
      display: block;
	  max-width: 100%;
    }
    input::placeholder {
      color: #2A2A2A;
    }
    input {
      border: none;
      font-size: 20px;
      height: 50px;
        outline: none;
        color: #2A2A2A;
        @include media-breakpoint-down(md) {
          background-color: #eeeeee;
          padding: 0 0 0 25px;
          width: 100%;
          outline: none;
          border: none;
        }
        @include media-breakpoint-down(sm){
          border: none;
        }
    }
    .input-job-search{
      width: 100%;
      padding: 0 14px 0 40px;
      @include media-breakpoint-down(md) {
        padding: 0 0px 0 40px;
        width: 100%;
        margin: 8px 0;
      }
      @include media-breakpoint-down(sm) {
        border: none;
      }
    }
    .location-auto-search{
      width: 24vw;
      padding: 0 32px 0 5px;
    }
    .location-auto-search::placeholder {
      color: #a7a9ac;
      opacity: 1; /* Firefox */
    }

    .input-job-search::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #a7a9ac;
      opacity: 1; /* Firefox */
    }
    .input-job-search::-ms-clear {
      display: none;
    }
    .fa-search {
      display: block;
      position: absolute;
      left: 10%;
      top: 16px;
      width: 20px;
      height: 20px;
      font-size: 21px;
      cursor:pointer;
      @include media-breakpoint-down(md) {
        top: 17px;
        left: 18px;
      }
      @include media-breakpoint-down (sm) {
        top: 17px;
        left: 8px;
      }
    }
    .fa-arrow-right {
      display: block;
      position: absolute;
      right: 10%;
      top: 16px;
      width: 28px;
      height: 18px;
      font-size: 21px;
      cursor:pointer;
      @include media-breakpoint-down(md) {
        top: 73px;
        right: 2%;
		}

    }
    .jobs-arrow-right {
      @include media-breakpoint-down(md) {
        top: 16px;
		  }
    }
    .location-arrow-clear {
      @include media-breakpoint-down(md) {
        top: 73px !important;
		  }
    }
    .custom-plus.cross {
      position: absolute;
      top: 16px;
      right: 9%;
      font-size: 25px;
      background-image: url("../images/svg/Icon_Add.svg");
      transform-style: preserve-3d;
      transition: 0.5s;
      width: 19px;
      height: 19px;
      margin-top: 1px;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
      &.show {
        display: block;
		cursor: pointer;
      }
	    @include media-breakpoint-down(lg) {
		    right: 10%;
		}
	    @include media-breakpoint-down(md) {
		    right: 7%;
		}
	    @include media-breakpoint-down(sm) {
		    right: 11%;
		}
		@include media-breakpoint-down(xs) {
		    right: 13%;
		}
    }
  }



  .open-positions-filters {
    position: relative;
    padding: 30px 0 50px 0;
    @include media-breakpoint-down(sm) {
      padding: 0px 0px 30px 0px;
    }
    .row {
      .filter:first-child {
        @include media-breakpoint-down(md) {
          display: block;
          padding: 13px !important;
        }
      }
      .filter {
        position: unset;
        padding: 10px;
        @include media-breakpoint-down(md) {
          padding: 7.5px 15px;
        }
        .selector {
          cursor: pointer;
          border: 2px solid #8E9095;
          padding: 7px 15px 7px 15px;
          border-radius: 10px;
          display: flex;
          width: 100%;
          background-color: transparent;

          .text {
            font-size: 18px;
            color: #6f7680;
            font-family: $font-SourceSans-Semibold;
          }
          .icon {
            margin: 4px 5px auto auto;
            color: #a7a9ac;
            font-size: 22px;
            &.custom-plus {
              background-image: url("../images/svg/Icon_Add.svg");
              transform-style: preserve-3d;
              transition: 0.5s;
              width: 14px;
              height: 14px;
              background-size: cover;
              background-repeat: no-repeat;
              margin-top: 7px;
            }
          }
          &.highlight {
            border-color: #400095;
            .custom-plus {
              background-image: url("../images/svg/Icon_Add_rotate.svg");
              transform-style: preserve-3d;
              transition: 0.5s;
              width: 14px;
              height: 14px;
              background-size: cover;
              background-repeat: no-repeat;
              transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
              margin-top: 7px;
            }
            .text {
              color: #400095;
            }
          }
          /*&.job-selector.highlight {
            .custom-plus {
              background-image: url("../images/svg/Icon_Add_rotate.svg");
              transform-style: preserve-3d;
              transition: 0.5s;
              width: 14px;
              height: 14px;
              background-size: cover;
              background-repeat: no-repeat;
              transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg);
              margin-top: 7px;
            }
          }*/
        }
        .angle-up-wrapper {
          height: 15px;
          width: 30px;
          margin: 0 auto;
          margin-top: 5px;
          z-index: 100;

          @include media-breakpoint-down(md) {
            height: auto;
          }
          .hide {
            display: none;
          }
        }
        .angle-up {
          background: url("../images/path.svg") no-repeat;
          width: 30px;
          height: 15px;
          background-size: 30px 17px;
          position: relative;
          z-index: 100;
          display: none;
          &.true {
            display: block;
          }
        }
      }
    }
    .expanded {
      z-index: 9999;
      background: #ffffff;
      left: 0px;
      right: 0px;
      padding: 34px 35px 26px 35px;
      @include media-breakpoint-down(md) {
        margin: 0;
        padding: 34px 10px 0px 10px;
        text-align: center;
      }
      .header {
        margin-left: 10px;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 10px;
        margin-bottom: 20px;
        position: relative;
        .text {
          font-size: 18px;
          color: #6f7680;
          font-family: $font-SourceSans-Bold;
          display:inline-block;
          cursor: pointer;
          i {
            margin-right: 10px;
          }
          &.static-text {
              font-family: $font-SourceSans-Regular;
            }
        }
        .cross {
          background-image: url("../images/svg/Icon_Add.svg");
          transform-style: preserve-3d;
          transition: 0.5s;
          width: 14px;
          height: 14px;
          background-size: cover;
          background-repeat: no-repeat;
          font-size: 22px;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
          color: #a7a9ac;
          cursor: pointer;
        }
      }
      .name-wrapper {
        label {
          width: 100%;
        }
        .name {
          cursor: pointer;
          padding: 10px;
          width: 100%;
          font-family: $font-SourceSans-Semibold;
          &.selected {
            background: #eaeaea;
          }
          &:hover {
            background: #eeeeee;
          }
          @include media-breakpoint-down(sm) {
            font-family: $font-SourceSans-Regular;
          }
        }
      }
      .actions {
        // margin-top: 20px;
        // text-align: right;
        // margin-bottom: 15px;
        // // position: -webkit-sticky; /* Safari */
        position: fixed;
        bottom: 0;
        display: flex;
        // justify-content: start;
      
        // .list-filters-apply {
        //   background: #400093;
        //   border: 1px solid #400093;
        // }
        // .clear-selected{
        //   background: white;
        //   border: 1px solid black;
        //   color: black;
        // }
      }
    }
    .MuiCollapse-container-1 {
      border: 1px solid rgba(119, 136, 153, 0.5);
      margin-top: -1px;
      @include media-breakpoint-down(md) {
        margin: 0 auto;
        margin-top: -1px;
        width: 50%;
      } 
      @include media-breakpoint-down(sm) {
        margin: 0 auto;
        margin-top: -1px;
        width: 270px;
      } 
    }
  }
  .open-positions-jobs {
    .filetrs-label {
      padding-bottom: 0;
      h4 {
        font-size: 18px;
        font-family: $font-SourceSans-Semibold;
      }
      .columnheader {
        font-family: 'SourceSansPro-Regular';
        font-size: 18px;
        margin-bottom: 0;
        font-weight: 700;
        line-height: 30px;
      }
    }
    .jobs-display {
      .single-job {
        // min-height: 120px;
        padding: 20px 0;
        // border-top: 1px solid #dedfe0;


        // flex-grow: 1;
        // flex-shrink: 1;
        // // flex-basis: auto;
        // transition: all 1s ease;
        
        @include media-breakpoint-down(md) {
          // min-height: 200px;
          margin: 0px;
        }
        .showing-jobs{
          // flex-basis: auto;
          display: flex;
          flex-direction: column;
          // height: 100%;
          &:nth-child(2n) {
            background: #eeeeee;
          }
        }
        .multiple-location-job{
          width: 105%;
        }
        &:last-child {
          border-bottom: 1px solid #7E7E7E;
        }
        .column {
          font-size: 16px;
          line-height: 30px;
          font-family: "SourceSansPro-Regular";
          font-weight: 600;
        }
        .job-title {
          a {
            color: #400093;
            font-size: 18px;
            line-height: 30px;
            color: #330077;
          }
          p{
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 5px;
          }
        }
        .sub-business {
          font-family: $font-SourceSans-Regular;
        }
        .show-locations{
          margin-top: 5px;
          .city{
            padding: 6px 0;
            color: rgb(64, 0, 147);
          }
        }
      }
      .hidden-job{
        flex: 0;
        max-height: 0;
        opacity: 0;
        transition: all .7s ease;
        div{
          max-height: 0 !important;
          transition: all .7s ease;
          div{
            max-height: 0 !important;
            transition: all .7s ease;
          }
        }
      }
      .visible-job{
        opacity: 1;
        padding: 20px 0;
        font-family: $font-SourceSans-Semibold;
        flex-grow: .1;
        flex-shrink: 1;
        transition: all .5s ease-in-out;
        border-bottom: 1px solid #7E7E7E;
		.show-locations {
          height: auto;
          font-size: 16px;
          line-height: 30px;
          font-family: "SourceSansPro-Regular";
          font-weight: 600;
          @include media-breakpoint-down(sm) {
            min-height: auto;
            padding-left: 15px;
          }
        }
        div{
          /*max-height: 320px !important;*/
          flex-grow: 1;
          color: rgb(64, 0, 147);
          transition: max-height .5s ease-in-out;
          div{
            /*max-height: 240px !important;*/
            flex-grow: 1;
            transition: all .5s linear;
            padding: 6px 0;
          }
        }
      }
    }

  }
  .open-positions-pagination {
    margin-top: 64px;
    display: flex;
    text-align: center;
    flex-direction: column;
    .pager-items {
      padding: 0;
      text-align: center;
      margin-top: 20px;
      @include media-breakpoint-down(md) {
        float: right;
      }
      .pager-item {
        display: inline-block;
        span {
          color: #eeeeee;
          line-height: 30px;
        }
        &.active {
          cursor: pointer;
          span {
            color: #400093;
          }
        }
        &.pagerer-prefix {
          padding: 0;
          font-family: 'SourceSansPro-Regular';
          span {
            color: #454546;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 1px;
            &.current-page {
              color: #454546;
            }
          }
          .pager_of {
            margin: 0 15px;
          }
          &.mobile {
            display: none;
            span {
              &.current-page {
                border: 1px solid #8E9095;
                padding: 5px 10px;
                border-radius: 0px;
                color: #454546;
                margin-left: 15px;
              }
            }
            > span {
              position: relative;
              top: -1px;
            }
          }
          &.desktop {
            display: inline-block;
          }
          @include media-breakpoint-down(lg) {
            &.mobile {
              display: inline-block;
            }
            &.desktop {
              display: none;
            }
          }
        }
      }
      .pager-item-previous,
      .pager-item-next {
        width: 30px;
        height: 30px;
        background-color: #eeeeee;
        span {
          color: #a7a9ac;
        }
        &.active {
          cursor: pointer;
          background-color: #400093;
          span {
            color: #ffffff;
          }
        }
        .far {
          font-size: 24px;
          line-height: 30px;
        }
        @include media-breakpoint-down(lg) {
          display: none;
          &.active {
            display: inline-block;
            background-color: #EEEEEE;
            width: inherit;
            span {
              color: #000000;
            }
            > div {
              background-color: #EEEEEE;
              width: 50px;
              border: 1px solid #8E9095;
              text-align: center;
              .far {
                line-height: 50px;
              }
            }
          }
        }
      }
    }
    .pager-items-count {
      .pager-item.pagerer-prefix {
        span.current-page{
          color: #2a2a2a;
        }
        .pager_of {
          margin: 0 6px;
          color: #2a2a2a;
        }
      } 
    }
  }
}

.bus-filter-item{
  margin-left: 200px;
  @include media-breakpoint-down(1199px){
    margin-left: 0vw;
    // background: yellow;
  };
  @include media-breakpoint-down(md) {
    margin: 0 auto;
    width: 380px !important;
    .MuiCollapse-container-1{
      width: 370px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
    width: 320px !important;
    .MuiCollapse-container-1{
      width: 320px !important;
    }
  }
}
/* Jobs Component End*/

/* FAQs Component Start*/
#internships-react-main-wrapper {
    .faqs-main-wrapper {
        
        .accordion-title {
            h3 {
                color: #7F679F;
                background-image: url("../images/resources/Arrow_Purple_Up_WhiteBG.png");
            }
            .accordion__button[aria-expanded='true'] {
              h3 {
                background: url("../images/resources/Arrow_Purple_Up_WhiteBG.png") no-repeat scroll !important;
                color: #7f679f !important;
                background-size: 20px 20px !important;
                background-position: 99.7% 27px !important;
              }
            }
            &.item-title-hidden {
                h3 {
                    color: #444444;
                    background-image: url("../images/resources/down-arrow.png");
                    &:hover {
                        color: #7F679F;
                        background-image: url("../images/resources/Arrow_Purple_Down_WhiteBG.png");
                    }
                }
            }
        }
        .accordion-item-body {
            border-bottom: 2px solid #7F679F;
        }
    }
}
.faqs-main-wrapper {
  .faqs-wrapper {
    h1 {
      &:first-child {
        text-align: center;
        font-family: $font-sharpSans-Book;
      }
    }
  }
  .faqs-container {
    text-align: center;
    background-color: white;
    height: auto;
    width: 100%;
    max-width: 1085px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently                         supported by Chrome and Opera */
    margin: 0 auto;
  }

  .accordion-container {
    display: flex;
    margin-left: 0;
  }

  .faqs-title {
    font-size: 40px;
    color: #444;
    margin: 5vh auto 25px;
    font-family: $font-sharpSans-Book;
    max-width: 1240px;
    text-align: center;
    padding: 0 20px;
  }

  .search-bar-container {
    position: sticky;
    position: -webkit-sticky;
    z-index: 10;
    top: 0;
    background: white;
    padding-bottom: 10px;
  }

  .search-bar {
    border-bottom: 1.35px solid gray;
    display: flex;
    margin: 0 auto;
    height: 40px;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 610px;
    position: relative;
    .fa-arrow-right {
      display: block;
      position: absolute;
      right: 15px;
      top: 16px;
      width: 20px;
      height: 20px;
      font-size: 21px;
    }
  }

  .search-bar input {
    width: 100%;
    max-width: 610px;
    height: 44px;
    font-size: 20px;
    outline: none;
    text-align: left;
    border: 0;
    outline: 0;
    background: transparent;
  }

  .search-bar input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .submit-button-magnifying-glass {
    align-content: right;
    outline: none;
    width: 40px;
    border: none;
    cursor: pointer;
    position: relative;
    background: url("../images/resources/magnifying-glass.png") no-repeat scroll;
    background-size: 19px 19px;
    background-position-y: 14px;
    background-position-x: 0px;
    padding-right: 18px;
  }

  .sub-header-title {
    text-align: left;
    color: #f36a30;
    margin: 40px 0 5px 0px;
    h3 {
      margin-bottom: 5px;
      font-size: 22px;
    }
  }

  .accordion-item-body {
    width: 100%;
    border-bottom: 2px solid #f36a30;
    text-align: left;
    overflow: hidden;
    animation-name: accordion-content;
    animation-duration: 1.5s;
    font-size: 16px;
    padding: 0px 0 20px 0;
    p {
      margin: 0 auto;
    }
  }

  .accordion__body--hidden {
    height: 0;
    -webkit-transition: height, 1.5s ease;
    -moz-transition: height, 1.5s ease;
    -ms-transition: height, 1.5s ease;
    -o-transition: height, 1.5s ease;
    transition: height, 1.5s ease;
  }



  //keyframes animation performance while using Opacity, Translate, and Filters is very good




  @keyframes accordion-content {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .accordion-body {
    width: 100%;
    margin-left: 0;
  }

  .accordion-title {
    color: #f36a30;
    cursor: pointer;
    text-align: left;
    outline: none;
    font-size: 18px;
    @include media-breakpoint-down(sm) {
      color: #f36a30;
    }
    h3 {
      font-size: 18px;
      position: relative;
      padding: 25px 0 35px 0;
      background: url("../images/resources/up-arrow.png") no-repeat scroll;
      background-size: 20px 20px;
      background-position: 99.7% 27px;
      margin-bottom: 0px;
      padding-right: 35px;
        @include media-breakpoint-down(md) {
            background-position: 99.7% 26px;
        }
    }
  }

  .accordion-title-box {
    transition: all 0.5s ease-in-out;
    width: 100%;
    &:last-child {
        .item-title-hidden {
            border: none;
        }
    }
  }

  .item-title-hidden {
    color: #444;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid rgba(119, 136, 153, 0.5);
    h3 {
      padding-right: 40px;
      font-size: 18px;
      position: relative;
      padding: 25px 35px 35px 0;
      background: url("../images/resources/down-arrow.png") no-repeat scroll;
      background-size: 20px 20px;
      background-position: 99.7% 27px;
      &:hover {
        background: url("../images/resources/down-arrow-orange.png") no-repeat
          scroll;
        background-size: 20px 20px;
        background-position: 99.7% 27px;
        color: #f36a30;
      }
    }
  }
  .accordion-title-box:last-child .item-title-hidden {
    @include media-breakpoint-down(md) {
      border-bottom: none;
    }
  }
  .MuiCardContent-root-5 {
    @include media-breakpoint-down(md) {
      padding: 0px;
    }
  }
  .accordion__body--hidden {
    display: none;
  }

  .accordion-title h1 {
    padding: 0 15px 0 0;
  }

  .highlight-text-title {
    background-color: yellow;
  }

  .down-arrow {
    height: 20px;
    width: 20px;
    position: relative;
    top: 14px;
  }

  .title-and-arrow {
    display: flex;
    justify-content: space-between;
    &:hover {
      img {
        filter: hue-rotate(180deg);
        -webkit-filter: hue-rotate(180deg);
      }
    }
  }

  .failed-search {
    border: 2.5px solid #f36a30;
    border-radius: 10px;
    padding: 10px 0;
    width: 60%;
    margin: 30px auto 0;
    h2 {
      font-size: 24px;
    }
  }

  .return-to-all {
    border: 1.5px solid #f36a30;
    margin: 25px 0 10px;
    height: 45px;
    width: 140px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    background: white;
    &:hover {
      animation-name: return-to-all-fade;
      animation-duration: 1s;
      background: peachpuff;
    }
  }

  @keyframes return-to-all-fade {
    from {
      background: white;
    }
    to {
      background: peachpuff;
    }
  }
}
/* FAQs Component end*/

.text-blue-magenta {
  color: #896eb1;
}

/*Instagram*/
.path-frontpage,
.path-internships {
  .styles_modal__gNwvD {
    max-width: 935px !important;
    margin: 58px auto;
    .styles_closeButton__20ID4 {
      position: absolute;
      top: -50px;
      right: 0;
      background: url(../images/icons/Close.png) !important;
      height: 20px;
      width: 20px;
      @include media-breakpoint-down(lg) {
        right: 0%;
      }
      @include media-breakpoint-down(sm) {
        top: -30px;
      }
      svg {
        display: none;
      }
    }
  }
}

.instagram-popup-container {
  display: flex;
  height: 60vh;
  min-height: 300px;
  max-height: 600px;
  margin-top: 120px;
  @include media-breakpoint-down(md) {
    max-height: 400px;
  }
  @include media-breakpoint-down(sm) {
    max-height: 100%;
    flex-flow: column;
    height: auto;
  }
  .popup-image {
    height: 100%;
    max-height: 600px;
    max-width: 600px;
    object-fit: cover;
    @include media-breakpoint-down(md) {
      max-height: 400px;
      max-width: 400px;
    }
    @include media-breakpoint-down(sm) {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      object-fit: unset;
    }
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
  }
  .next-button-popup {
    float: right;
    position: absolute;
    top: 49%;
    right: -5vw;
    background: url("../images/resources/arrow-point-to-right.png") no-repeat
      scroll;
    background-size: 20px 30px;
    height: 35px;
    width: 25px;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    @include media-breakpoint-down(lg) {
      right: -3vw;
    }
    @include media-breakpoint-down(sm) {
      right: 15px;
      //display:none;
      position: fixed;
    }
    @include media-breakpoint-down(md) {
      //display:none;
    }
  }
  .prev-button-popup {
    float: left;
    position: absolute;
    top: 49%;
    left: -5vw;
    background: url("../images/icons/carousel-left.png") no-repeat scroll;
    background-size: 20px 30px;
    height: 35px;
    width: 25px;
    opacity: 0.7;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    @include media-breakpoint-down(lg) {
      left: -3vw;
    }
    @include media-breakpoint-down(sm) {
      left: 15px;
      //display:none;
      position: fixed;
    }
    @include media-breakpoint-down(md) {
      //display:none;
    }
  }
  .popup-text-container {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    display: block;
    margin-left: 25px;
    margin-right: 25px;
    max-height: 520px;
    @include media-breakpoint-down(lg) {
      min-width: 200px;
    }
    @include media-breakpoint-down(sm) {
      max-height: 100%;
      width: auto;
    }
    p {
        width: 100%;
        // height: calc(100% - 170px);
        // overflow: auto;
        box-sizing: content-box;
        padding-right: 16px;
        word-wrap: break-word;
      word-break: break-word;
      @include media-breakpoint-down(sm) {
        // max-height: 50%;
        // overflow: hidden;
      }
    }
    word-wrap: break-word;
    .popup-title-container {
      display: flex;
      margin-top: 15px;
      height: 75px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
      }
      h4 {
        position: relative;
        top: 10px;
        margin-left: 5px;
        cursor: pointer;
        font-size: 18px;
      }
      .insta-check {
        position: relative;
        top: 13px;
        height: 15px;
        width: 15px;
        margin-left: 5px;
      }
      .account-link {
        position: relative;
        top: 10px;
        left: 0;
        margin-left: 5px;
        font-size: 16px;
        a {
          text-decoration: none;
        }
      }
      // .hashtag-value{
      //   a{
      //     color: lightblue !important;
      //   }
      // }
      @include media-breakpoint-down(sm) {
        height: auto;
        margin-bottom: 20px;
      }
    }
    .popup-footer {
      // position: sticky;
      // bottom: 20px;
      position: relative;
      margin-top: auto;
      @include media-breakpoint-down(sm) {
        span{
          font-size: 14px;
        }
        // position: unset;
        position: relative;
        bottom: auto;
        padding-bottom: 20px;
      }
      span {
        padding-right: 20px;
      }
    }
  }
}

.styles_modal__gNwvD {
  padding: 0;
}

.popup-container-mobile {
  //width: 80vw;
  padding-bottom: 10px;
  .popup-image-mobile-image {
    width: 100%;
  }
  .popup-text-container-mobile {
    margin-left: 15px;
    margin-right: 15px;
    height: 100%;
    .popup-title-container {
      display: flex;
      margin-top: 15px;
      height: 75px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
      }
      h4 {
        position: relative;
        top: 10px;
        margin-left: 5px;
        cursor: pointer;
        font-size: 18px;
      }
      .insta-check {
        position: relative;
        top: 13px;
        height: 15px;
        width: 15px;
        margin-left: 5px;
      }
      .account-link {
        position: relative;
        top: 10px;
        left: 5px;
        font-size: 16px;
        a {
          text-decoration: none;
        }
      }
    }
    .mobile-popup-title {
      height: auto;
      padding-bottom: 10px;
    }

    .mobile-popup-links {
      padding-bottom: 20px;
    }
  }
}

.insta-check {
  position: relative;
  top: 13px;
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.intern-instagram-main-wrapper {
  a {
    text-decoration: none;
  }

  .display-body {
    width: 100%;
    text-align: center;
  }

  .carousel-container {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    @include media-breakpoint-down(md) {
      width: 90%;
    }
    .slick-list {
      @include media-breakpoint-down(md) {
        width: 90% !important;
      }
    }
  }

  .carousel-container-mobile {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin: auto;
    @include media-breakpoint-up(lg){
        display: none;
    }
  }
  button:focus {
    outline: 0;
  }

  .carousel-item-container {
    text-align: center;
    overflow: visible;
    outline: 0;
  }

  .insta-image-container {
    position: relative;
    margin: auto auto;
    width: 90%;
    cursor: pointer;
    margin-top: 5%;
    background: #000000;
    // margin-bottom: 20px;
    .insta-image {
      position: relative;
      height: 100%;
      width: 100%;
      -webkit-transition: opacity 0.3s ease;
      -moz-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      -ms-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
    }
  }

  .hearts-and-comments {
    display: flex;
    justify-content: space-around;
    color: white;
    width: 60%;
    position: relative;
    margin: 0 auto;
    bottom: 56%;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    cursor: pointer;
    span {
      padding-right: 8px;
    }
    img {
      height: 25px;
      padding-right: 5px;
    }
  }

  .heart-fade-in {
    position: absolute;
  }

  .insta-image-container:hover .hearts-and-comments {
    opacity: 1;
  }
  .insta-image-container:hover .insta-image {
    opacity: 0.6;
  }
  .likes {
    font-size: 20px;
  }

  #likes {
    visibility: hidden;
  }
  #content:hover > #likes {
    visibility: visible;
  }

  .popup-content {
    position: relative;
    right: 25vw;
    left: 25vw;
    top: 40vh;
    height: 300px;
    width: 600px;
    overflow: visible;
    z-index: 6;
  }

  //BACKGROUND FADE:
  .styles_overlay__CLSq- {
    background: rgba(255, 255, 255, 0.7);
    //display: block;
  }

  //X OUT BUTTON on popup
  .styles_closeIcon__1QwbI {
    cursor: pointer;
  }

  .slick-next {
    display: none;
    height: 0px;
    width: 0px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 50px;
    line-height: 2;
  }

  .slick-next:before {
    content: "";
    background-image: url("../images/resources/arrow-point-to-right.png");
    background-repeat: no-repeat;
    background-size: 0px 0px;
  }
  .next-button,
  .prev-button {
    border: 1px solid transparent;
    background-color: transparent;
    display: none;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
  }
  &:hover {
      .next-button,
      .prev-button {
        display: block;
      }
  }
  .next-button {
    float: right;
    position: absolute;
    background-image: url(../images/icons/carousel-right.png) !important;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    top: 50%;
    width: 20px;
    height: 20px;
    z-index: 50;
    right: 0px;
    &:hover {
      background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
      -webkit-transform: scale(1.2); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: scale(1.2); /* IE 9 */
      transform: scale(1.2);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .prev-button {
    float: left;
    position: absolute;
    background-image: url(../images/icons/carousel-left.png) !important;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    width: 20px;
    height: 20px;
    top: 50%;
    z-index: 50;
    &:hover {
      background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
      -webkit-transform: scale(1.2); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: scale(1.2); /* IE 9 */
      transform: scale(1.2);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .next-button-popup-mobile {
    float: right;
    position: absolute;
    top: 49%;
    right: -7vw;
    background: url("../images/resources/arrow-point-to-right.png") no-repeat
      scroll;
    background-size: 20px 30px;
    height: 35px;
    width: 25px;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  .prev-button-popup-mobile {
    float: right;
    position: absolute;
    top: 49%;
    left: -7vw;
    background: url("../images/resources/arrow-point-to-right.png") no-repeat
      scroll;
    background-size: 20px 30px;
    height: 35px;
    width: 25px;
    cursor: pointer;
    opacity: 0.7;
    transform: scaleX(-1);
    &:hover {
      opacity: 1;
    }
  }
}
/*End Instagram*/

/* Find a Job Starts*/
.path-find-a-job {
    .container-fluid-lg {
        @include media-breakpoint-down(sm) {
            padding:0px 15px;
        }
        .external-links  {
            .col-12 {
                @include media-breakpoint-down(sm) {
                    padding:0px;
                }
                .item-wrapper {
                    @include media-breakpoint-down(sm) {
                        padding:0px 15px;
                    }
                    .link-item {
                        @include media-breakpoint-down(sm) {
                            text-align:left;
                        }
                        .fa-arrow-right {
                            @include media-breakpoint-down(sm) {
                                display:none;
                            }
                        }
                    }
                }
            }
        }
    }
	.container-fluid-lg {
		@include media-breakpoint-down(md) {
			padding:0px 15px;
		}
		.external-links  {
			.col-12 {
				@include media-breakpoint-down(md) {
					padding:0px;
				}
				.item-wrapper {
					@include media-breakpoint-down(md) {
						padding:0px 15px;
					}
					.link-item {
						@include media-breakpoint-down(md) {
							text-align:left;
						}
						.fa-arrow-right {
							@include media-breakpoint-down(md) {
								display:none;
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
.path-find-a-job, .path-internships {
	.job-aoi-listing {
		.aoi-listing {
			> .row {
				display: flex;
				flex-direction: column;
				max-height: 320px;
				height: 320px;
				.name-wrapper {
					flex:none;
				}
			}
		}
	}
	.job-business-listing {
		.business-listing {
			> .row {
				display: flex;
				flex-direction: column;
				max-height: 320px;
				height: 320px;
				.name-wrapper {
					flex:none;
				}
			}
		}
	}
	.job-locations-listing {
		.locations-listing {
			> .row {
				display: flex;
				flex-direction: column;
				max-height: 280px;
				height: 280px;
				.name-wrapper {
					flex:none;
				}
			}
		}
	}
}
}



.jobs-main-search-react {
  color: #2a2a2a;
  .title {
    text-align: center;
    font-size: 40px;
    margin-top: 75px;
    font-family: $font-sharpSans-Book;
    @include media-breakpoint-down(md) {
      margin-top: 35px;
      font-size: 26px;
      text-align: left;
    }
  }
  .description {
    font-size: 18px;
    padding: 0 10%;
    margin-top: 30px;
    text-align: center;
    line-height: 30px;
    @include media-breakpoint-down(lg) {
      padding: 0 50px;
    }
    @include media-breakpoint-down(md) {
      padding: 0px;
      text-align: left;
    }
  }
  .find-job-external-links {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .external-links {
    padding: 0 15%;

    @include media-breakpoint-down(lg) {
      padding: 0 50px;
    }
    @include media-breakpoint-down(md) {
      padding: 0px;
    }
    .item-wrapper {
      @include media-breakpoint-up(lg) {
        background: #400095;
        margin-top: 40px;
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          background: #4E535A;
        }
      }
    }
    a {
      color: #ffffff;
      margin: auto;
      text-decoration: none;
      font-family: $font-SourceSans-Semibold;
      text-align: center;
      line-height: 24px;
      font-size: 18px;
      font-weight: 100;
      display: block;
      width: 100%;
      @include media-breakpoint-down(md) {
        padding: 0px;
        color: #400095;
        margin: 0px;
        text-align: left;
      }
    }
    .link-item {
      font-size: 18px;
      padding: 0 35px 0 19px;
      display: table-cell;
      vertical-align: middle;
      height: 75px;
      text-align: center;
      position: relative;
      font-family: $font-SourceSans-Semibold;
      @include media-breakpoint-down(md) {
        padding: 0px;
        background: none;
        color: #400095;
        height: 45px;
      }

      .fas {
        color: #ffffff;
        margin: auto 5px auto auto;
        position: absolute;
        right: 11px;
        top: 30px;
      }
    }
  }
  #jobs_search-react-main-wrapper {
    border-top: 1px solid rgba(119, 136, 153, 0.5);
    margin-top: 60px;
    @include media-breakpoint-down(md) {
      margin-top: 25px;
    }
    .container-fluid-lg {
      padding: 0px;
    }
  }
}
/*Find a Job Ends */

// INSTAGRAM GRID STARTS:
.instagram-wrapper {
  .tab-wrapper {
    padding-bottom: 60px;
    padding-top: 40px;
    @include media-breakpoint-down(md) {
      padding-bottom: 30px;
      padding-top: 20px;
    }
    .row .col-6 {
      padding: 0 45px;
      &:first-child {
        border-right: 2px solid #cdcdcd;
      }
      @include media-breakpoint-down(md) {
        padding: 0 10px;
      }
    }
  }

  .active .switch-insta {
    position: relative;
    z-index: 1;
    text-align: center;
    padding-bottom: 20px;
    font-size: 34px;
    font-family: $font-sharpSans-Medium;
    color: #009dd6;
    display: inline;
    @include media-breakpoint-down(md) {
      font-size: 26px;
    }
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }

  .active .switch-insta:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 35px;
    margin: 0 0 0 -15px;
    border-bottom: 5px solid #009dd6;
    @include media-breakpoint-down(md) {
      margin: 0 0 0 -20px;
      width: 50px;
    }
    @include media-breakpoint-down(md) {
      margin: 0 0 0 -10px;
      width: 21px;
    }
  }
  .inactive .switch-insta {
    font-size: 34px;
    font-family: $font-sharpSans-Book;
    @include media-breakpoint-down(md) {
      font-size: 26px;
    }
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }
}
.instagrid-strap {
  .insta-img-container {
    padding: 10px;
  }
  .insta-image-container {
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}
.instagram-item-grid:hover .insta-image-container {
  opacity: 0.6;
}
.instagram-item-grid:hover .hearts-and-comments {
  opacity: 1;
}
.tab-wrapper {
  .active {
    color: #009DD6;
  }
  .inactive {
    cursor: pointer;
  }
  .align-right {
    text-align: right;
  }
}

// INSTAGRAM GRID ENDS

/*Brightcove */
.brighove-player-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
    .vjs-poster {
        background-size: 100% 100%;
    }
}
.home-instagram-react-wrapper {
  .hearts-and-comments {
    display: flex;
    justify-content: space-around;
    color: white;
    width: 60%;
    position: relative;
    margin: 0 auto;
    bottom: 170px;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    cursor: pointer;
    span {
      padding-right: 8px;
    }
    img {
      height: 25px;
      padding-right: 5px;
    }
  }

  .heart-fade-in {
    position: absolute;
  }

  .likes {
    font-size: 20px;
  }

  #likes {
    visibility: hidden;
  }
}

/* Intern open positions and FAQ wrapper */
.internships-react-wrapper {
  .faqs-main-wrapper {
    max-width: 1240px;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      padding: 0 20px;
    }
    h1 {
      margin-top: 40px;
      margin-bottom: 0px !important;
      text-align: left !important;
    }
  }
  .open-positions-main-wrapper {
    padding-top: 0px !important;
  }
  [class^="MuiCard"],
  [class^="MuiCollapse"] {
    @include media-breakpoint-down(lg) {
      padding: 0px;
    }
  }
  .faqs-main-wrapper .sub-header-title h3 {
    color: #876EAF !important;
    font-family: $font-SourceSans-Semibold;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1100px) {
    #internships-react-main-wrapper {
        .intern-possibilities-main-wrapper {
            .intern-possibilities-wrapper {
                .intern-possibilities {
                    .intern-possibility {
                        .outer-ip-box {
                            .title {
                                font-size: 18px;
                                padding: 0 20px;
                            }
                        }
                        .outer-ip-box::after {
                            width: 90%;
                        }
                    }
                }
            }
        }
        .intern-videos-main-wrapper {
            .video-description {
                padding:90px 100px;
                
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    #internships-react-main-wrapper {
        .intern-navigation-main-wrapper {
            .intern-navigation-wrapper { 
                .right-navigation {
                    .internship-content {
                        .internship-logo {
                            margin-bottom: 45px;
                        }
                        .internship-description {
                            p {
                                margin-bottom: 45px;
                            }
                            ul {
                                margin-bottom: 60px;
                            }
                        }
                    }
                }
            }
        }
        .internships-react-wrapper {
            .intern-possibility .accordion-item-body {
                .logo1 img, .logo2 img {
                    max-width: 50%;
                }
            }
        }	
    }
    .intern-locations-main-wrapper {
        .intern-locations-wrapper {
            .locations {
                .location {
                    .logo-wrapper {
                        width:90%;
                    }
					img{
					  max-width: 165px !important;
					}
                }
            }
        }
    }
}

// max-width: 1240px !important;



.react-autosuggest__input{
  width: 88%;
  background: url("../images/resources/map-pin.svg") no-repeat scroll;
  background-size: 28px 28px;
  background-position: 0.7% 10px;
  padding-left: 38px;
  @include media-breakpoint-down(md) {
    padding-left: 40px !important;
  }
}

.predictive-auto-search{
  .react-autosuggest__input{
    background: none;
  }
}


.react-autosuggest__input::placeholder{
  color: grey;
  // @include media-breakpoint-down(sm) {
  //   padding-left: 30px !important;
  // }
}

.react-autosuggest__suggestions-container{
  z-index: 1000;
}

.react-autosuggest__suggestions-container--open{
  text-decoration: none;
  background: white;
  position: absolute;
  width: 34.4%;
  -webkit-box-shadow: 2px 3px 6px 2px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    2px 3px 6px 2px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         2px 3px 6px 2px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  @include media-breakpoint-down(md) {
    width: 96%;
  }
}

#react-autowharever-1{
  margin: 0 auto;
}

ul.react-autosuggest__suggestions-list{
  list-style: none;
  padding: 0;
  margin-bottom: 0px;
}


.suggestion-list-item{
  padding: 8px 10px;
}

.suggestion-list-item:hover{
  background: gray;
  color: white
}


.suggest-more-container{
  margin-top: 10px;
  background: whitesmoke;
  color: gray;
  padding: 10px;
}

// .apply-items-container{
//   height: 100% !important;
//   display: flex;
//   justify-content: flex-end;
//   flex-direction: column;
// }








// @media (min-width: 975px) and (max-width: 1200px) {
//   .open-positions-filters{
//     .row{
//       background: red;
//       .selector{
//         .text{
//           font-size: 15px !important;
//         }
//       }
//     }
//   }
// }








//1200 max

.open-positions-filters .row{
  margin: 0 auto;
  display: flex;
  justify-content: center;


}

.filter-results{
  border: 1px solid #400095;
  border-radius: 6px;
  color: #400095;
  padding: 1px 8px;
  margin-right: 12px;
  cursor: pointer;
  background: none;
  margin-bottom: 6px;
  img{
    margin: 0 4px 2px 8px;
    height: 10px;
    width: 10px;
  }
  .filter-reslt-btn {
    background: none;
    border: none;
    padding: 0;
    line-height: 16px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 6px;
  }
}

.filter-results-aoi{
  border: 1px solid #400095;
  border-radius: 6px;
  color: #400095;
  padding: 2px 8px;
  margin-right: 12px;
  cursor: pointer;
  background: none;
  margin-bottom: 6px;
  width: auto;
  img{
    margin: 0 4px 2px 8px;
    height: 10px;
    width: 10px;
  }
  .filter-reslt-btn {
    background: none;
    border: none;
    padding: 0;
    line-height: 16px;
  }
}

.levels-content{
  // margin-left: 600px;
  .MuiCollapse-container-1 {
    max-height: 520px !important;
  }

  // @include media-breakpoint-down(lg) {
  //   margin-left: 49% !important;
  // }
  // @include media-breakpoint-down(md) {
  //   margin-left: 120px !important;
  // }
}

    // animation-name: test;
    // animation-duration: .5;
//   }

//   @-webkit-keyframes jobs-fade-in {
//     0%   {height: 0 !important;}
//     100% { height: 300px !important;}
// }
// @keyframes jobs-fade-in {
//     0%   {height: 0 !important;}
//     100% { height: 300px !important;}
// }



.showing-results{
  padding-top: 8px;
  margin-right: 15px;
  color: grey;
  // vertical-align: text-bottom;

}

.slight-break{
  height: 8px;
}

.filter-remove{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.multiple-locations{
  color: rgb(64, 0, 147);
  cursor: pointer;
  display: flex;
  // color: #330077;
  img{
    height: 12px;
    width: 14px;
    margin-left: 10px;
    margin-top: 10px;
  }
}

.purp-down{
  -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg);        /* FF */
  -o-transform: rotate(180deg);          /* Opera */
  -ms-transform: rotate(180deg);         /* IE9 */
  transform: rotate(180deg);  
}

  .keyword-close{
    position: absolute;
    top: 16px;
    right: 9%;
    font-size: 25px;
    background-image: url("../images/resources/x-button.svg");
    width: 19px;
    height: 19px;
    margin-top: 1px;
    @include media-breakpoint-down(lg) {
      right: 10%;
  }
    @include media-breakpoint-down(md) {
      display: none;
      right: 7%;
  }
    @include media-breakpoint-down(sm) {
      right: 11%;
  }
  @include media-breakpoint-down(xs) {
      right: 13%;
  }
  }



.job-locations-listing .expanded{
  width: 350px;
  padding: 0;
}
  .MuiCollapse-container-1{
    @include media-breakpoint-down(sm) {
      overflow: hidden !important;
      overflow-y: auto !important;
    }
  }
  .MuiCollapse-entered-2{
    @include media-breakpoint-down(sm) {
      overflow: hidden !important;
      overflow-y: auto !important;
    }
  }

.open-positions-wrapper{
  .search-form{
    .fa-search{
      @include media-breakpoint-down(md) {
        padding-left: 5px;
      }
    }
  }
}

.aoi-card-content-container{
  @include media-breakpoint-down(sm){

    width: 320px !important;
  }
}

.aoi-device-container{
  .MuiCollapse-container-1{
    width: 370px !important;
    .MuiCollapse-wrapper-3{
      margin-top: 16px;
      // width: 370px !important;
      .actions-container{
        margin-top: 7px !important;
      }
      .aoi-content-card{
        .MuiCardContent-root-5{
          padding: 0 16px;
        }
      }
    }
  }
}


.aoi-content-card{
  overflow:hidden;
  overflow-y: auto;
  .MuiCollapse-container-1{
    // z-index: 1 !important;

    // border-top-width: 0 !important;
  }

  // .MuiCollapse-entered-2{
  //   z-index: 1 !important;

  // }


  @include media-breakpoint-down(sm) {
    width: 320px !important;
  }
    .MuiCardContent-root-5{
    max-height: 480px;
    .collapsible-content{
      max-height: 460px !important;
    }
  }

  .MuiCollapse-wrapperInner-4{
    @include media-breakpoint-down(sm) {
      width: 320px;
    }
  }
  .MuiCollapse-wrapper-3{
    @include media-breakpoint-down(sm){
      width: 320px;
    }
  }
  // width: 360px;
  // margin-left: 100px;
  // position: relative;
  // height: 400px;
  .aoi-card-content-container{
    overflow-y: auto;
    // height: 400px;
    // width: 420px;
  }

  .job-aoi-filter{
    @include media-breakpoint-down(sm) {
      width: 320px !important;
    }
  }





  
  .MuiCollapse-container-1{
    position: absolute;
    width: 365px;
    height: 530px;
    z-index: 12;
    background: white;
    overflow: hidden;
    overflow-y: auto;
    @include media-breakpoint-down(md) {
      margin: 0 auto !important;
      position: relative;
    }
    .collapsible-content{
      @include media-breakpoint-down(md) {
        padding: 16px;
      }
    }
    .MuiCollapse-wrapperInner-4{
      @include media-breakpoint-down(sm) {
        width: 320px;
      }
    }
    .MuiCollapse-wrapper-3{
      @include media-breakpoint-down(sm) {
        width: 320px;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 320px;
    }

    .actions{
      .list-filters-apply{
        @include media-breakpoint-down(sm) {
          width: 130px;
        }
      }
      .clear-selected{
        @include media-breakpoint-down(sm) {
          width: 130px !important;
        }
      }
    }


  }
  .aoi-name{
    margin-left: 2px;
    font-size: 18px;
    // padding-bottom: 6px;
  }
  .aoi-item-add{
    display: flex;
    padding: 5px 0;
    cursor: pointer;
    .location-checkbox{
      width: 16px !important;
    }
    .add-check{
      img{
        width: 11px ;
        height: 11px;
      }
    }
  }
  .location-checkbox{
    width: 16px;
    height: 16px !important;
    top: 6px;
  }
  .add-check{
    width: 16px !important;
  }
  .collapsible-content{
    margin-top: 5px;
    i{
      margin: 10px 0;
    }
  }

}

.filter-businessess{
  .state-container{
    .profession-list{
      .location-checkbox{
        width: 17px !important;
      }
    }
  }
}

.profession-list{
  margin-top: 15px;
  div{
    .state-container{
      .location-checkbox{
        top: 18px;
        width: 16px;
      }
      .add-check{
        width: 16px;
      }
    }
    .actions-container{
      position: relative;
      bottom: 0;
      margin-top: 39px;
      .actions{
        padding-bottom: 12px;
      }
    
  }
}

  .bus-name{
    .location-checkbox{
      top: 16px !important;
    }
  }
}
.device-iphone{
	.profession-list{
		div:last-child{
			div.aoi-item-add{
				padding-bottom: 100px;
			}
		}
	}
}
.level-container{
  max-height: 480px;
  position: relative;
  .actions-container{
    position: sticky;
    bottom: 0;
    margin-top: 230px;
    .actions{
      padding-bottom: 12px;
    }
  }
  .MuiCardContent-root-5{
    max-height: 480px;
    .collapsible-content{
      max-height: 460px;
    }
    .profession-list{
      max-height: 420px;
      position: relative;
      display: flex; /*added*/
      flex-direction: column;
    }
  }
  @include media-breakpoint-down(md){
    padding: 16px;
    padding-bottom: 4px;
  }
  .aoi-item-add{
    @include media-breakpoint-down(md){
      display: flex;
      .aoi-name{
        margin-top: 8px;
        cursor: pointer;
      }
    }
  }
}




.state-container{
  color: black;
  .location-checkbox{
    position: relative;
    top: 16px;
    width: 16px;
  }
}

.bus-name{
  overflow: hidden;
  .bus-name{
    padding-top: 10px;
  }
  .Collapsible__trigger{
    padding: 10px 22px 0px 2px;
  }
  .location-checkbox{
    position: relative;
    top: 12px !important;
  }

}


.business-filter{
  margin-left: 400px;
  color: black;
  .MuiCollapse-container-1 {
    max-height: 500px !important;
  }
  .actions-container{
    margin-top: 40px;
    position: sticky;
    bottom: 0;
  }

  .Collapsible__trigger{
    padding-right: 0;
    width: 95%;
    margin-left: 5%;
  }

  .subbus-item{
    cursor: pointer;
    display: flex;
    margin: 0 auto;
  }

  .sub-business-name{
    margin-left: 12px;
  }

}

.aoi-card-content{
  .MuiCollapse-container-1 {
    margin-left: 0px !important;
    // @include media-breakpoint-down(lg) {
    //   margin-left: 0px !important;
    // }
    // @include media-breakpoint-down(md) {
    //   margin-left: 0px !important;
    // }
    @include media-breakpoint-down(sm){
      width: 320px;
    }
    .actions-container{
      margin-top: 10px;
      height: 80px;
    }
    .profession-list{
      height: auto;
    }
  }
}

.card-content-container{
   width: 420px;
  position: absolute;
  .location-content-card{
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      width: 320px;
      padding-top: 15px;
      margin: 0 auto;
      z-index: 10;
    }
    @include media-breakpoint-down(md) {
      width: 320px;
      max-height: 520px;
      padding-top: 15px;
      position: relative;
      margin: 0 auto;
      z-index: 10;
      background: white;
    }
  }
  @include media-breakpoint-down(md) {
    width: 320px;
    position: relative;
    margin: 0 auto;
    z-index: 10;
    background: white;
  }
  
  .MuiCardContent-root-5{
    position: relative;
    width: 100%;
    z-index: 10;
    background: white;
    height: 500px;
    overflow-y: auto;
    padding: 0 16px;
    margin-top: 16px;
    &::-webkit-scrollbar{
      width: 3px;
    }
    &::-webkit-scrollbar-track{
      background: white;
    }
    &::-webkit-scrollbar-thumb{
      background: #400093;
    }
    div{
      div{
        div{
              div{
                height: auto;
                .location-checkbox{
                  height: 16px;
                  width: 17px;
                }
              }

        }
      }
    }
  }
  .MuiCollapse-container-1 {
    width: 365px;
    margin-left: 0px;


    // @include media-breakpoint-down(lg) {
    //   margin-left: 230px;
    // }
    // @include media-breakpoint-down(md) {
    //   margin-left: 320px;
    // }


    @include media-breakpoint-down(md) {
      width: 365px;
    }
    @include media-breakpoint-down(md) {
      width: 320px;
    }
  }
}

.open-positions-filters{
  .MuiCollapse-container-1{
    @include media-breakpoint-down(sm) {
      width: 320px !important;
    }
  } 
  .MuiCollapse-entered-2{
    @include media-breakpoint-down(sm) {
      width: 320px !important;
    }
  }
}

.global-region-container{
  width: 100%;
  text-decoration: none;
  list-style: none;
  padding-left: 0;
  .global-region{
    width: 100%;
    height: 50px;
    background: #44445e;
    position: relative;
    color: white;
    padding: 0 20px;
    margin: 3px 0;
    cursor: pointer;
    h4{
      font-size: 20px;
      font-weight: 500;
      line-height: 50px;
    }
  }
}

.actions {
  padding-top: 14px;
  text-align: right;
  background: white;
  display: flex;
  padding-bottom: 16px;
  div {

    font-size: 16px;
    margin-left: 0;

    span{
      vertical-align: center;
      line-height: 50px;
    }
  }
  .list-filters-apply {
    background: #400093;
    height: 50px;
    color: #ffffff;
    width: 157.5px;
    margin-right: 8px;
    @include media-breakpoint-down(sm) {
      width: 130px;
    }
  }
  .clear-selected{
    color: black;
    background: white;
    border: 1px solid black;
    height: 50px;
    width: 157.5px;
    margin-left: 8px;
    @include media-breakpoint-down(sm) {
      width: 130px;
    }
  }
}


.actions {
  text-align: right;
  background: white;
  div {
    color: #ffffff;
    border: none;
    height: 50px;
    width: 160px;
    margin: 0 auto;
    font-size: 18px;
    cursor: pointer;
  }
  .list-filters-apply {
    background: #400093;
  }
  .clear-selected{
    color: black;
    background: white;
    border: 1px solid black;
  }
}


.location-accordion-container{
  position: relative;
  height: 100%;
  .actions-container{
    margin-top: 8px;
  }
}
.open-region-dropdown{
  position: relative;
  z-index: 10000000;
  margin: 0 auto;
  li{
    position: relative;
  }
}

.region-dropdown{
  @include media-breakpoint-down(md) {
    max-height: 418px !important;
    overflow-y: auto;
  }
  i{
    margin: 10 0;
  }
}

.job-location{
  cursor: auto;
  .multiple-locations{
    cursor: pointer;
  }
}






trigger-tag{
  display: block;
  font-weight: 300;
  text-decoration: none;
  position: relative;
  padding: 10px;
  font-size: 20px;
  font-family: 'SourceSansPro-regular';
  color: white;
  width: 100%;
  cursor: pointer;
  background: #4E535A;
  background-size: 14px 9px;
  background-position: 2% 10px;
  z-index: 6;
  @include media-breakpoint-down(md) {
    width: 300px;
    margin: 0 auto;
    }
}

.Collapsible{
  margin: 0px 0 1px;
}

state-dropdown-trigger{
  margin-left: 3px;
  font-size: 18px;
  line-height: 45px;
  display: block;
  text-decoration: none;
  position: relative;
  font-family: 'SourceSansPro-Regular';
  width: 100%;
  cursor: pointer;
  font-weight: normal;
  background: url("../images/resources/arrow-down-gray.png") no-repeat scroll;
  background-size: 14px 11px;
  background-position: 98.2% 14px;
}

.state-container{
  cursor: pointer;
  display: flex;
  margin: 0 auto;
  .rc-checkbox{
    line-height: 5px;
    padding: 14px 8px 8px;
    .rc-checkbox-input{
      border-radius: 3px;
    }
  }
  .Collapsible{
    width: 100%;
  }
}

.open-state-trigger-tag{
  state-dropdown-trigger{
    color: rgb(64, 0, 147);
    background: url("../images/resources/arrow-up-purp.png") no-repeat scroll;
    background-size: 14px 11px;
    background-position: 98.2% 14px;
  }
}

.first-state{
  font-size: 18px;
  margin-top: 7px;
  font-family: 'SourceSansPro-Regular';
  margin-left: 2px;
  padding-bottom: 10px;
}

.first-city{
  font-size: 16px;
  margin-top: 7px;
  font-family: 'SourceSansPro-Regular';
  margin-left: 2px;
  padding-bottom: 4px;
}

.Collapsible__trigger{
  font-size: 18px;
  line-height: 40px;
  line-height: 30px;
  display: block;
  text-decoration: none;
  position: relative;
  padding: 10px;
  padding-left: 12px;
  font-family: 'SourceSansPro-Regular';
  width: 100%;
  cursor: pointer;
  background: url("../images/resources/arrow-down-gray.png") no-repeat scroll;
  background-size: 14px 11px;
  background-position: 99% 17px;
}

.bus-name{
  .is-open{
    background: url("../images/resources/arrow-up-purp.png") no-repeat scroll;
    background-size: 14px 11px;
    background-position: 99% 17px;
  }
}

.state-dropdown-container{
  margin-top: 8px;
  margin-left: 8px;
}

.country-trigger-container{
  margin: 0 auto;
  display: flex;

  .all-selected{
    padding-left: 32px;
  }
    .region-check{
      display: none;
      height: 26px !important;
      width: 18px;
      position: absolute;
      margin-top: 11px;
      margin-left: 10px;
      z-index: 1;
      cursor: pointer;
    }

  .slide-title{
    padding-left: 32px;
    }

    @-webkit-keyframes test {
      0%   {padding-left: 20px !important; }
      100% { padding-left: 38px !important;}
  }
  @keyframes test {
      0%   {padding-left: 20px !important;}
      100% { padding-left: 38px !important;}
  }



.Collapsible__contentInner{
  overflow: hidden;
}

  // .open-region-dropdown{
    // .Collapsible__contentOuter{
      // .Collapsible__contentInner{
        // .country-trigger-container{
            .animate-check {
            position: absolute;
            display: block !important;
            float: left;
            height: 26px !important;
            // width: 16px;
            overflow: hidden;
            -webkit-animation: reveal .5s ease;
            -webkit-animation-fill-mode: backwards;
            animation: reveal .5s ease;
            width: 18px;
            z-index: 1;
            img{
              position: relative;
              // top: 8px;
              height: 16px;
              width: 16px;
              z-index: 1;
            }
        }
        // }
      // }
    // }
// }

.Collapsible{
  overflow: hidden !important;
}



// .show-locations{
//   padding-top: 200px;
//   position: relative;
// }

// .test{
//   font-size: 30px !important;
// }



@-webkit-keyframes reveal {
    0%   {width: 0px;}
    100% { width: 18px;}
}
@keyframes reveal {
    0%   {width: 0px;}
    100% { width: 18px;}
}







  .Collapsible{
    width: 100%;
  }
  // .Collapsible__trigger{
  //   padding-bottom: 3px;
  // }

  // .closed-slide-over{
  //   padding-left: 20px;
  // }

//   @-webkit-keyframes slide {
//     100% { left: 10px; }
// }

// @keyframes slide {
//     100% { left: 10px; }
// }

.Collapsible__contentOuter{
  width: 100%;
}


  @include media-breakpoint-down(md) {
    width: 86%;
    }
    .test-check{
      height: 20px;
      width: 30px;
    }
}

  .actions-container{
    margin-top: auto;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1000000000000;
    .actions{
      text-align: center !important;
    }
  }

// }

.filter-businesses {
  .actions-container{
    margin-top: 10px;
  }
}

.MuiCardContent-root-5:last-child{
  padding-bottom: 0px !important;
}



@include media-breakpoint-up(sm) {
.open-region-dropdown{
  trigger-tag {
    padding-left: 40px;
    background: rgb(64, 0, 147) url("../images/resources/checkmark-for-verification.svg") no-repeat scroll;
      -webkit-animation: slide-check .3s linear ; /* Safari */
      animation: slide-check .3s linear ;
      font-family: "SourceSansPro-Regular";
      background-size: 16px 16px;
      // background-size: 0;
      background-position: 17px 18px;
    }
  }
  @keyframes slide-check{
    0% {
      background-color: rgb(64, 0, 147);
      background-size: 0 0;
      padding-left: 10px;
      background-position: 17px 26px;
    }
    100% {
      background-color: rgb(64, 0, 147);
      background-size: 16px 16px;
      padding-left: 40px;
      background-position: 17px 18px;
    }
  }
  
  @-webkit-keyframes slide-check{
    0% {
      background-color: rgb(64, 0, 147);
      background-size: 0 0;
      padding-left: 10px;
      background-position: 17px 26px;
  
    }
    100% {
      background-color: rgb(64, 0, 147);
      background-size: 16px 16px;
      padding-left: 40px;
      background-position: 17px 18px;
  
    }
  }
} 

@include media-breakpoint-down(sm) {
  trigger-tag{
    background-image: url("../images/resources/checkmark-for-verification.svg") no-repeat scroll;
    // background-size: 24px 18px;
    background-position: 0px 26px;
    background-size: 16px 16px;
    // background-position: 17px 26px;
  }
  
  
  .open-region-dropdown{
    
    trigger-tag {
      padding-left: 40px;
      background: rgb(64, 0, 147) url("../images/resources/checkmark-for-verification.svg") no-repeat scroll;
      // background-position: 17px 18px;
      background-size: 16px 16px;
  
      // -webkit-animation: slide-check .3s linear ; /* Safari */
      // animation: slide-check .3s linear ;
      font-family: "SourceSansPro-Regular";
      background-size: 16px 16px;
      background-position: 17px 18px;
      transition: background .4s;
      -webkit-transition: background .4s;
      -moz-transition: background .4s;
      -o-transition: background .4s;
      transition: background-position .4s;
      -webkit-transition: background-position .4s;
      -moz-transition: background-position .4s;
      -o-transition: background-position .4s;
      transition: background-size .4s;
      -webkit-transition: background-size .4s;
      -moz-transition: background-size .4s;
      -o-transition: background-size .4s;
    } 
  }
}


.no-current-openings-container{
  padding: 8px 0;
  .no-current-openings{
    font-size: 18px;
    margin-left: 10px;
}
}









  .location-checkbox{
    position: relative;
    top: 14px;
    left: 0;
    height: 16px;
    width: 17px ;
    margin-right: 10px;
    background-color: #fff;
    border: solid #9F7FCA;
    border-width: 1px;
    border-radius: 5px;
    margin-right: 8px !important;
    margin-left: 2px;
  }

  .state-checkbox{
    position: relative;
    top: 12px;
    // left: 4px;
    margin-left: 2px;
    height: 16px !important;
    width: 17px !important;
    margin-right: 10px;
    background-color: #fff;
    border: solid #9F7FCA;
    border-width: 1px;
    border-radius: 5px;
    span{
      width: 16px;
      height: 16px;
    }
    // margin-right: 8px !important;
    // margin-left: 2px;
  }

  .all-cities-container {
    height: 40px !important;
    display: flex;
    .location-checkbox{
      width: 16px !important;
      height: 16px !important;
      top: 12px;
    }
    }

  .first-location-checkbox{
    position: relative;
    top: 14px;
    left: 0;
    height: 16px ;
    width: 15.7px ;
    margin-right: 10px;
    background-color: #fff;
    border: solid #9F7FCA;
    border-width: 1px;
    border-radius: 5px;
    margin-right: 8px !important;
    margin-left: 2px;
  }


.all-states{
  .first-state{
  }
  .location-checkbox{
    width: 17px !important;
    height: 16px;
  }
}

.state-dropdown-container{
  .state-container{
    .location-checkbox{
      width: 17px !important;
    }
    .add-check{
      width: 17px !important;
    }
    .all-cities-container{
      .location-checkbox{
        width: 16px !important;
      }
    }
    .city-container{
        .location-checkbox{
          width: 16px !important;
        }
    }
  }
}

.state-dropdown-container-int{
  // display: flex;
  // flex-direction: column;
  margin-left: 8px;
  text-align: left;
  .state-container{
    display: flex;
    flex-direction: column;

    width: 100%;
    .city-container{
      display: flex;
      width: 100%;
    }

  }
}

.city-container-wrapper{
  display: flex;
  flex-direction: column;
}

.filter-businesses{

  .state-container{
    .location-checkbox{
      width: 17.3px !important;
      height: 16px;
    }
    .add-check{
      width: 17.6px !important;
    }
    .bus-name{
      .location-checkbox{
        width: 16px !important;
        height: 16px;
      }
      .add-check{
        width: 16px !important;
      }
    }
  }
}

.add-check{
  background: rgb(64, 0, 147);
  border: #400095;
  height: 16px;
  width: 17px;
  img{
    height: 11px;
    width: 11px;
    position: relative;
    bottom: 6px;
    left: 2px;
  }
}

.first-add-check{
  background: rgb(64, 0, 147);
  border: #400095;
  height: 16px;
  width: 15.7px !important;
  img{
    height: 11px;
    width: 11px;
    position: relative;
    bottom: 6px;
    left: 2px;
  }
}



.city-container{
  .location-checkbox{
    width: 16px !important;
    height: 16px !important;
    top: 11px;
  }
  .add-check{
    img{
      bottom: 14px;
    }
  }
}


.region-drop-down{
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    margin-top: 10px;
    width: 40%;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
    margin-top: -1px;
    width: 80%;
  } 
}

.open-country-trigger-tag > .Collapsible__trigger{
  color: rgb(64, 0, 147);
  background: url("../images/resources/arrow-up-purp.png") no-repeat scroll;
  background-size: 14px 11px;
  background-position: 99% 20px;
}

.open-country-trigger-tag{
  width: 100% !important;
}

.city-container{
  display: flex;
  line-height: 40px;

  .rc-checkbox{
    line-height: 20px;
  }
}

.rc-checkbox-input{
  height: 15px;
  width: 15px;
  border-radius: 8px;
  color: purple;
}

.rc-checkbox-inner{
  border-radius: 8px;
}

.main-region-container{
  min-height: 410px !important;
}







.card {
  width: 500px;
  margin: 50px auto;
  clear: both;
  display: block;
  padding: 0px 0px;
  background-color: #009BFF;
  border-radius: 4px;
}
.card::after {
  clear: both;
  display: block;
  content: "";
}
.card .checkbox-container {
  float: left;
  width: 50%;
  box-sizing: border-box;
  text-align:center;
padding: 40px 0px;
}
.card .circular-container {
background-color:red;
}

.input-title {
  clear: both;
  padding: 22px 0px 0px 0px;
  font-size: 16px;
  color: rgba(255,255,255,.6);
  font-weight: 300;
}


.open-positions-filters{
  @include media-breakpoint-down(md) {
    flex-direction: column;
  } 
  .selector{
      font-size:16px;
      .fas {
        margin-right: 2px;
      }
  }
}

.job-location-filter{
  .MuiCollapse-container-1{
    @include media-breakpoint-down(md) {
      width: 320px !important;
      // max-height: 520px;
      // padding-top: 15px;
      position: relative;
      margin: 0 auto;
      // z-index: 10;
      // background: white;
    }
  }
}




//tablet add background
//test checks














.path-internships {
  .featured-top {
    .field--name-field-banner-logo-small {
      bottom: 20px;
    }
  }
  .site-footer {
		margin-top: 0;
	}
}
#internships-react-main-wrapper {
  .intern-possibilities-main-wrapper {
    .intern-possibilities-wrapper {
      .intern-possibilities {
        .intern-possibility {
          .outer-ip-box {
            .title {
              font-size: 18px;
              padding: 0 20px;
            }
          }
          .outer-ip-box::after {
            width: 90%;
          }
        }
      }
    }
  }
  .intern-videos-main-wrapper {
    .video-description {
      padding:90px 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      max-height: 520px;
		  overflow: hidden;
      @include media-breakpoint-down(lg){
        padding: 60px;
      }
      @include media-breakpoint-down(md){
        padding: 38px;
      }
      @include media-breakpoint-down(sm){
        padding: 19px;
        max-height: 630px;
      }
    }
  }
  .intern-how-to-apply-main-wrapper {
    .banner-images {
      .banner-image {
        img {
          height: 100%;
        }
      }
    }
  }
}
.open-positions-main-wrapper {
  .open-positions-filters {
    .expanded {
      margin:0px;
    }
  }
  .search-form {
    border-radius: 0px;
    .input-job-search {
      border-radius: 0px;
    }
  }
}
.path-find-a-job, .path-internships {
  .job-aoi-listing {
    .aoi-listing {
      > .row {
        display: flex;
        flex-direction: column;
        max-height: 360px;
        height: 360px;
      }
    }
  }
  .job-business-listing {
    .business-listing {
      > .row {
        display: flex;
        flex-direction: column;
        max-height: 360px;
        height: 360px;
      }
    }
  }
  .job-locations-listing {
    .locations-listing {
      > .row {
        display: flex;
        flex-direction: column;
        max-height: 320px;
        height: 320px;
      }
    }
  }
}
// }
@media (min-width: 768px) and (max-width: 992px) {
#internships-react-main-wrapper {
  .intern-navigation-main-wrapper {
    .intern-navigation-wrapper { 
      .right-navigation {
        .internship-content {
          .internship-logo {
            margin-bottom: 45px;
          }
          .internship-description {
            p {
              margin-bottom: 45px;
            }
            ul {
              margin-bottom: 60px;
            }
          }
        }
      }
    }
  }
  .internships-react-wrapper {
    .intern-possibility .accordion-item-body {
      .logo1 img, .logo2 img {
        max-width: 50%;
      }
    }
  }	
}
.intern-locations-main-wrapper {
  .intern-locations-wrapper {
    .locations {
      .location {
        .logo-wrapper {
          width:90%;
        }
      }
    }
  }
}
.path-find-a-job {
  .open-positions-main-wrapper {
    .search-form {
      max-width: 1240px !important;
      border-radius: 0px;
      .input-job-search {
        border-radius: 0px;
        outline:none;
      }
    }
  }
  .container-fluid-lg {
    .external-links {
      .col-12 {
        .item-wrapper {
          .link-item {
            .fa-arrow-right {
              display: none;
            }
          }
        }
      }
    }
  }
}
.path-internships {
  .instagram-popup-container {
    .next-button-popup {
      right: -4vw;
    }
    .prev-button-popup {
      left: -4vw;
    }
  }
} 
}
@media (max-width: 1024px) {
#internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility {
  padding: 0 10px;
} 
}
@media (max-width: 767px) {
#internships-react-main-wrapper {
  .intern-locations-main-wrapper {
    .intern-locations-wrapper {
      .locations {
        .location {
          .logo-wrapper {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.node--type-page-program {
  .instagram-popup-container {
    .popup-image {
      max-width: 100% !important;
    }
    .popup-text-container {
      margin: 0px 15px;
    }
  }
  .styles_overlay__CLSq- {
    padding: 0px 10px;
  }
}
}

.node--type-page-program{
.intern-instagram-main-wrapper {
  .carousel-container {
    padding-top: 0px;
  }
}
.instagram-popup-container {
  .popup-image {
    max-width: 500px;
  }
  .popup-text-container {
    .popup-title-container {
      h4 {
        top: 3px;
      }
    }
  }
}
.styles_closeButton__20ID4 {
  top: -46px;
  right: -4px;
}
} 

.IG-intern-title{
  padding: 35px 0 0 0;
  font-family: $font-sharpSans-Medium;
  text-align: center;
  width: 100%;
  a{
    margin: 0 auto;
    font-size: 34px;
    color: #009dd6;
    text-decoration: none;
  }
}
.remote-filter{
  .remote-item-add{
    bottom: 3px;
    position: relative;
  }
  cursor: pointer;
  .location-checkbox{
    top: 22px !important;
  }
  .remote-label{
    padding-left: 25px ;
	font-size: 18px;
    color: #6f7680;
  }
} 

// intern video section css added
.intern-video-modal-content {
  max-width: 100%;
  width: 90%;
}

.intern-video-modal-overlay {
  background: rgba(255,255,255,.9);
}

.intern-video-modal-content .close {
  margin-right: -25px;
  margin-top: -52px;
  background: #ffffff;
  font-size: 40px;
  cursor: pointer;
  padding: 2px 5px;
  opacity: .5;
}

#internships-react-main-wrapper {
	.intern-videos-main-wrapper {
		.video-description {
			a.btn-intern-video {
				font-family: "SourceSansPro-Semibold";
        color: #212529;
        font-size: 17px;
        line-height: 30px;
			}
		}
	}
}


#internships-react-main-wrapper {
	.intern-videos-main-wrapper {
		.video-description {
			a.btn-intern-video {
				&:hover {
					color: #886db0;
				}
			}
		}
	}
}

.btn-intern-video {
  font-family: "SourceSansPro-Semibold";
  color: #212529;
  font-size: 17px;
  line-height: 30px;
}

.btn-intern-video .fa-arrow-right {
  font-size: 16px;
  padding-left: 10px;
}

@media (min-width: 1023px) {
  .intern-video-modal-content {
    max-width: 650px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .intern-video-modal-content {
    max-width: 100%;
    width: 98%;
  }
}

@media (max-width: 768px) {
  .intern-video-modal-content .close {
    margin-right: 0px;
    margin-top: -65px;
  }
}

// open position new design layout css 
.open-positions-jobs {
  display: table;
  width: 100%;

  .jobs-row {
    background: #F5F3F3;
    border-bottom: 1px solid #7E7E7E;
    padding: 6px 0;
    th {
      font-family: 'SourceSansPro-Regular';
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
    }
  }

  .jobs-display {
    .job-ref-text {
      font-size: 18px;
    }
  }

  .interest-col {
    flex: 0 0 13.66667%;
    max-width: 13.66667%;
  }

  .date-col{
    flex: 0 0 11.33333%;
    max-width: 11.33333%;
  }
}

.open-positions-main-wrapper {
	.open-positions-jobs {
		.jobs-display {
			.showing-jobs {
				.single-job {
					background: #EEEEEE;
					word-wrap: break-word;
          align-items: center;
				}
				&:nth-child(4n+1) {
					.single-job {
						background: #FFFFFF;
					}
				}
			}
			.single-job {
				.jobs-icons {
					display: none;
				}
        .header-label {
          display: none;
        }
			}
		}
	}
}

.posted-txt {
	display: none;
}

// new pagination design css
.custom-pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style-type: none;
	margin: 0 auto;
	padding: 0;
	.page-arrows {
		background: #EEEEEE;
		padding: 10px;
		color: #000000;
		line-height: 8px;
		font-size: 20px;
		text-decoration: none;
    margin: 0 4px;
    transition: all 0.2s linear;
    border: 1px solid transparent;
    &:hover {
			border: 1px solid #400095;
		}
		&:focus {
			border: 1px solid #400095;
		}
	}
	.page-count {
    margin: 0 4px;
    a {
      display: inline-block;
      font-family: 'SourceSansPro-Regular';
      font-size: 18px;
      line-height: 30px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #454546;
      padding: 7px 18px;
      transition: all 0.2s linear;
      border: 1px solid transparent;
      cursor: pointer;
      &:hover {
				border: 1px solid #400095;
        text-decoration: none;
			}
			&:focus {
				border: 1px solid #400095;
        text-decoration: none;
			}
    }
	}
	.page-count.active {
     a {
      color: #ffffff;
      background-color: #400095;
     }
	}
	.page-breaks {
		font-family: 'SourceSansPro-Regular';
		font-size: 18px;
		line-height: 30px;
		font-weight: 600;
		letter-spacing: 1px;
		color: #454546;
	}
	.disabled {
		pointer-events: none;
    .page-arrows {
			color: #A7A9AC;
		}
	}
}

.mobile-custom-pagination {
  display: none;
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .levels-content .MuiCollapse-container-1{
    margin-left: -120px;
  }
}

@media only screen and (max-width: 991px) {
  .open-positions-main-wrapper {
    .open-positions-jobs {
      .jobs-display {
        .single-job {
          display: flex;
          flex-direction: column;
          padding: 28px 0;
          word-wrap: normal;
          line-height: 20px;
          .column {
            margin-bottom: 10px;
            padding: 0 19px;
          }
          .job-title {
            order: 1;
            p {
              margin-bottom: 0;
              font-size: 16px;
            }
          }
          .job-interest {
            order: 4;
            display: flex;
            align-items: center;
          }
          .job-business {
            order: 2;
            display: flex;
            align-items: center;
          }
          .job-location {
            order: 3;
            display: flex;
            align-items: center;
          }
          .job-level {
            order: 5;
            display: flex;
            align-items: center;
          }
          .job-date {
            order: 6;
            margin: 0;
          }
          &:first-child {
            border-top: 1px solid #7E7E7E;
          }
          &:last-child {
            border-bottom: 1px solid #7E7E7E;
          }
          .header-label {
            display: inline-block;
            min-width: 95px;
            color: #43474D;
            font-weight: 700;
          }
        }
      }

      .interest-col {
        flex: 0 0 100%;
        max-width: 100%;
      }
    
      .date-col{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .posted-txt {
    display: inline-block;
    padding-right: 5px;
  }

  .custom-pagination {
    justify-content: center;
    max-width: 100%;
  }

  .open-positions-main-wrapper .open-positions-jobs .jobs-display .showing-jobs .single-job {
    background: #FFFFFF;
    word-wrap: normal;
  }

  .open-positions-main-wrapper .open-positions-jobs .jobs-display .showing-jobs:nth-child(4n+1) .single-job {
    background: #EEEEEE;
  }
}

@media only screen and (min-width: 991.98px) {
  .open-positions-main-wrapper {
    .jobs-search-container {
      .react-autosuggest__container {
        border-bottom: none;
        border: 3px solid #CCCCCC;
      }
    }

    .search-form {
      input {
        padding-left: 45px;
      }

      .fa-search {
        left: 11.2%;
        top: 18px;
      }

      .fa-arrow-right {
        right: 10.5%;
        top: 16px;
      }
      .jobs-arrow-right {
        right:53.5%;
      }
      .location-arrow-clear {
        right: 14% !important;
      }
      .jobs-arrow-clear {
        right: 57% !important;
      }
    }

    .react-autosuggest__input {
      background-position: 2.7% 11px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .open-positions-main-wrapper {
    .open-positions-jobs {
      .jobs-display {
        .single-job {
          .header-label {
            display: none;
          }
          .jobs-icons {
            display: inline-flex;
            width: 24px;
            height: 24px;
          }
          .mob-field-name {
            width: calc(100% - 24px);
            margin-left: 7px;
          }
        }
      }
    }
    .open-positions-pagination {
      margin-top: 30px;
      .pager-items {
        text-align: right;
        .pager-item-next {
          >div {
            &:hover {
              border: 2px solid #400095;
            }
            &:focus {
              border: 2px solid #400095;
            }
          }
        }
        .pager-item-previous {
          >div {
            &:hover {
              border: 2px solid #400095;
            }
            &:focus {
              border: 2px solid #400095;
            }
          }
        }
      }
      .pager-items-count {
        margin-top: 15px;
       }
    }
  }

  .custom-pagination {
    display: none;
  }

  .mobile-custom-pagination {
    display: block;
  }

}
@media only screen and (min-width: 1200px) {
  .open-positions-main-wrapper {
    .open-positions-filters {
      max-width: 82%;
      width: 100%;
      margin: 0 auto;

      .row {
        justify-content: space-between;

        .filter {
          flex: 0 0 20%;
          max-width: 20%;
          padding: 10px 7px;

          &:last-child {
            flex: 0 0 160px;
            max-width: 160px;
            text-align: right;
          }
        }
      }
    }
  }

  .remote-filter {
    .location-checkbox {
      left: 20px;
      img {
        left: -3px;
      }
    }
  }
}


#internships-react-main-wrapper .open-positions-wrapper .main-title, 
#internships-react-main-wrapper .faqs-wrapper .faqs-title {
    border-bottom: 1px solid rgba(119,136,153,0.8);
}

// internship page banner video css
#internships-react-main-wrapper {
	.intern-banner-dashboard-main-wrapper {
		.featured-top {
			.field--name-field-banner-image.desktop.desktop-motion-image {
				display: none;
			}
			.field--name-field-banner-image.mobile.mobile-motion-image {
				display: none;
			}
		}
	}
	.featured-top {
		.field--name-field-banner-image.desktop.intern-banner-video {
			display: block;
			height: 775px;
		}
		.field--name-field-banner-image.intern-banner-video {
			.bright-video-wrap {
				width: 100%;
				height: 100%;
			}
		}
		.bright-video-wrap {
      .video-js {
        .vjs-dock-text {
          display: none;
        }
        .vjs-control-bar {
          display: none;
        }
      }
		}
	}
}
@media (prefers-reduced-motion) {
	#internships-react-main-wrapper {
		.desktop-wrapper {
			display: none;
		}
		.mobile-wrapper {
			display: none;
		}
		.intern-banner-dashboard-main-wrapper {
			.featured-top {
				.field--name-field-banner-image.desktop.desktop-motion-image {
					display: block;
				}
			}
		}
	}
}
@media (max-width: 991.98px) {
	#internships-react-main-wrapper {
		.desktop-wrapper {
			display: none;
		}
		.featured-top {
			.field--name-field-banner-image.mobile.mobile-intern-banner-video {
				display: block;
				height: auto;
			}
		}
	}
}
@media (max-width: 991.98px) and (prefers-reduced-motion) {
	#internships-react-main-wrapper {
		.desktop-wrapper {
			display: none;
		}
		.mobile-wrapper {
			display: none;
		}
		.intern-banner-dashboard-main-wrapper {
			.featured-top {
				.field--name-field-banner-image.desktop.desktop-motion-image {
					display: none;
				}
				.field--name-field-banner-image.mobile.mobile-motion-image {
					display: block;
				}
			}
		}
	}
}