/**
 * @file
 * Visual styles for Bootstrap Barrio's item list.
 */

.item-list ul {
    list-style: none;
    margin: 0 0 $list-group-item-padding-y 0;
    padding: 0;
    /* LTR */
}

[dir="rtl"] .item-list ul {
    padding: 0;
}

.item-list ul li {
    /* This is needed to reset styles in item-list.theme.css */
    margin: 0;
    padding: $list-group-item-padding-y $list-group-item-padding-x 0 0;
    /* LTR */
}

[dir="rtl"] .item-list ul li {
    /* This is needed to reset [dir="rtl"] styles in item-list.theme.css */
    margin: 0;
    padding: $list-group-item-padding-y 0 0 $list-group-item-padding-x;
}

.item-list .item-list__comma-list,
.item-list .item-list__comma-list li,
[dir="rtl"] .item-list .item-list__comma-list,
[dir="rtl"] .item-list .item-list__comma-list li {
    padding: 0;
}