/**
 * @file
 * Visual styles for icons.
 */

.icon-help {
    background: url(/../../images/help.png) 0 50% no-repeat;
    /* LTR */
    padding: 1px 0 1px ($spacer * 1.5);
    /* LTR */
}

[dir="rtl"] .icon-help {
    background-position: 100% 50%;
    padding: 1px ($spacer * 1.5) 1px 0;
}

.feed-icon {
    background: url(/../../images/feed.svg) no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    display: block;
    width: $font-size-base;
    height: $font-size-base;
}
.Facebook {
	background: url(../images/svg/Icon_Social_Facebook.svg) no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    display: block;
    width: $font-size-base * 2;
    height: $font-size-base * 2;
}
.Google {
	background: url(../images/svg/Icon_Social_Google.svg) no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    display: block;
    width: $font-size-base * 2;
    height: $font-size-base * 2;
}
.Instagram {
	background: url(../images/svg/Icon_Social_Instagram.svg) no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    display: block;
    width: $font-size-base * 2;
    height: $font-size-base * 2;
}
.Linkedin {
	background: url(../images/svg/Icon_Social_Linkedin.svg) no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    display: block;
    width: $font-size-base * 2;
    height: $font-size-base * 2;
}
.Twitter {
	background: url(../images/svg/icon-twitter-square.svg) no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    display: block;
    width: $font-size-base * 2;
    height: $font-size-base * 2;
}
.Account {
	background: url(../images/svg/Icon_Account.svg) no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    display: block;
    width: $font-size-base * 2;
    height: $font-size-base * 2;
}
.help {
	background: url(../images/svg/Icon_Help.svg) no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    display: block;
    width: $font-size-base * 2;
    height: $font-size-base * 2;
}