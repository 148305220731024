/* FAQ */


/* Intern Possibilities Component Start*/

.path-faq {
    .intern-possibilities-main-wrapper {
        .intern-possibilities-wrapper {
            .section_title {
                margin-top: 0px;
                padding-top: 0px;
            }
        }
        .mobile-display {
            display: none;
        }
        margin-top: 50px;
        padding: 0 100px;
        @include media-breakpoint-down(md) {
            padding: 0px;
            margin-top: 0px;
        }
        h1 {
            text-align: center;
            margin-bottom: 30px;
            font-family: $font-sharpSans-Book;
            @include media-breakpoint-down(md) {
                padding: 30px 0;
                margin: 0;
                text-align: left;
                border-bottom: 1px solid #eaeaea;
            }
            .popup-overlay {
                position: relative !important;
                min-height: 780px;
                width: 100%;
                z-index: 99 !important;
                background: rgba(255, 255, 255, 0.6) !important;
                display: block !important;
            }
        }
        .intern-possibilities-wrapper {
            position: relative;
            .ip-overlay {
                position: absolute;
                top: 50%;
                left: 50%;
            }
            .view-careers-resources-tiles {
                .view-content.row {
                    display: block;
                }
            }
            .intern-possibilities-popup {
                min-height: 550px;
                padding: 0 !important;
                width: 100% !important;
                background: #eeeeee !important;
                border: none !important;
                top: 0;
                .close {
                    position: absolute;
                    right: 60px;
                    top: 40px;
                }
            }
            .intern-possibilities {
                .intern-possibility {
                    a:hover {
                        color: #2A2A2A;
                        text-decoration: none;
                    }
                }
                .modal-dialog {
                    max-width: 100%;
                }
                &.overlay-show {
                    opacity: 0.1;
                }
                .row {
                    @include media-breakpoint-down(lg) {
                        margin: 0px;
                    }
                }
                .intern-possibility {
                    margin-bottom: 20px;
                    overflow: hidden;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0px;
                        padding: 0px;
                    }
                    .outer-ip-box {
                        cursor: pointer;
                        height: 174px;
                        background: #eeeeee;
                        .title {
                            position: relative;
                            top: 50%;
                            transform: translateY(-50%);
                            text-align: center;
                            font-size: 20px;
                            font-family: $font-sharpSans-Semibold;
                            padding: 0 30px;
                        }
                    }
                    .outer-ip-box::after {
                        content: "";
                        background: #F36A30;
                        width: 93%;
                        height: 12px;
                        position: absolute;
                        bottom: 0px;
                        -webkit-transform: translateY(6px);
                        -o-transform: translateY(6px);
                        transform: translateY(6px);
                        -webkit-transition-property: transform;
                        -webkit-transition-property: -webkit-transform;
                        transition-property: -webkit-transform;
                        -o-transition-property: -o-transform;
                        transition-property: transform;
                        transition-property: transform, -webkit-transform, -o-transform;
                        -webkit-transition-duration: 0.3s;
                        -o-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        -webkit-transition-timing-function: ease-out;
                        -o-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                    }
                    .outer-ip-box:hover:after {
                        -webkit-transform: translateY(0);
                        -o-transform: translateY(0);
                        transform: translateY(0);
                    }
                    .ip-border {
                        //border: 3px solid #00aeef;
                    }
                    .accordion__body--hidden {
                        display: none;
                    }
                }
            }
            .accordion-item-body {
                border-bottom: 1px solid #eaeaea;
                padding-bottom: 30px;
                .content {
                    .title {
                        color: #009DD6;
                        font-size: 30px;
                        margin-top: 20px;
                    }
                    .description {
                        font-size: 16px;
                        .light-blue {
                            color: #009DD6;
                        }
                    }
                    h5 {
                        color: #009DD6;
                    }
                    .logo1,
                    .logo2 {
                        img {
                            //max-width: 50%;
                            float: left;
                        }
                    }
                }
            }
            .accordion-item-body.accordion__body--hidden {
                border: none;
            }
            .intern-possibilities-popup {
                .content {
                    .title {
                        color: #F36A30;
                        font-size: 30px;
                        margin-top: 100px;
                        padding: 0 200px 0 200px;
                    }
                    .description {
                        font-size: 16px;
                        padding: 0 200px 0 200px;
                        .light-blue {
                            color: #009DD6;
                        }
                        strong {
                            font-family: "SourceSansPro-Semibold";
                            font-weight: normal;
                        }
                    }
                    h5 {
                        color: #F36A30;
                    }
                    @include media-breakpoint-up(lg) {
                        .logo1,
                        .logo2 {
                            height: 50%;
                            .imageloader {
                                height: 100%;
                            }
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        .modal-dialog {
            position: relative !important;
            min-height: 780px;
            width: 100%;
            z-index: 99 !important;
            .modal-content {
                min-height: 780px;
                padding: 0 !important;
                width: 100% !important;
                background: #eeeeee !important;
                border: none !important;
                top: 0;
                .modal2 {
                    min-height: 780px;
                    .content {
                        min-height: 780px;
                    }
                    .close {
                        top: 45px;
                        right: 45px;
                        background: unset;
                        position: relative;
                    }
                }
                @include media-breakpoint-up(lg) {
                    img {
                        width: 100%;
                        height: auto;
                        min-height: 390px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .testimonial-career-section {
        .cont-hide {
            display: none;
        }
    }
    .testimonial-career-section {
        .cont-right {
            .name-value {
                font-size: 22px;
                line-height: 26px;
                font-family: $font-sharpSans-Semibold;
                color: #2a2a2a;
                padding-bottom: 10px;
            }
            .desig-value {
                color: #2a2a2a;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 30px;
                font-family: $font-SourceSans-Semibold;
            }
            .desc-value {
                color: #2a2a2a;
                font-size: 16px;
                line-height: 24px;
                font-family: $font-sharpSans-Book;
            }
        }
    }
    .slick-prev,
    .slick-next {
        font-size: 15px !important;
        margin: 0px 15px;
    }
    .slick-prev:before,
    .slick-next:before {
        content: '' !important;
    }
    .slick-arrow {
        border-radius: 0px !important;
        height: 20px;
        width: 21px;
        z-index: 1;
        display: none;
        border: none;
        float: left;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        border: 1px solid transparent;
        background-color: transparent;
        display: block;
        background-size: 100% 100% !important;
        background-repeat: no-repeat;
    }
    .carousel-control-next-icon {
        background-image: url(../images/icons/carousel-right.png) !important;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .carousel-control-prev-icon {
        background-image: url(../images/icons/carousel-left.png) !important;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        border: 1px solid #FFF;
        background-color: transparent;
    }
    .slick-slider {
        .slick-list {
            width: 95% !important;
            margin: auto;
            .slick-slide {
                .slide__content {
                    .img-cover {
                        width: 160px;
                        height: 160px;
                        border-radius: 50%;
                        border: 1px solid #ccc;
                        margin: 0 auto;
                        @include media-breakpoint-down(sm) {
                            width: 180px;
                            height: 180px;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
    .slick__arrow {
        top: 35%;
    }
    .bg-img-text-career {
        background-image: url("../images/svg/Typography_Art_Gray_Verylight.png");
        /*background-size: 70% 75%;*/
        background-size: contain;
        background-position: right 0% bottom 0%;
        background-repeat: no-repeat;
        z-index: 4;
        position: relative;
        /*@include media-breakpoint-down(sm){
				background-size: 100% 42%;
			}*/
    }
    .early-carrer-section,
    .more-job-opp-section {
        .img-cover {
            position: relative;
            a:hover {
                text-decoration: none;
            }
            a {
                color: #2a2a2a;
            }
        }
        .img-cover:before,
        .img-cover:after {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 19px;
            left: 10px;
            width: 50%;
            top: 80%;
            max-width: 300px;
            background: #EEE;
            -webkit-box-shadow: 0 16px 16px #777;
            box-shadow: 0 16px 16px #777;
            -webkit-transform: rotate(-4deg);
            -o-transform: rotate(-4deg);
            transform: rotate(-4deg);
        }
        .img-cover:after {
            -webkit-transform: rotate(4deg);
            -moz-transform: rotate(4deg);
            -o-transform: rotate(4deg);
            -ms-transform: rotate(4deg);
            transform: rotate(4deg);
            right: 10px;
            left: auto;
        }
        .img-cont:hover .img-cover:before,
        .img-cont:hover .img-cover:after {
            -webkit-box-shadow: 0 20px 11px #777;
            -moz-box-shadow: 0 20px 11px #777;
            box-shadow: 0 20px 11px #777;
        }
    }
    .input-lg::-ms-clear {
        display: none;
    }
    #main-wrapper {
        .tt-view-faq_accordion:nth-child(2) {
            .views-row.carousel-desktop {
                padding: 0px;
                #carousel_FAQ {
                    ul {
                        li:hover {
                            opacity: 1;
                            .focused_img {
                                display: block;
                            }
                            .unfocused_img {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .tt-view-faq_accordion:last-child {
            .views-row {
                width: 100%;
            }
        }
        #faqs-react-main-wrapper-page-program {
            margin: 0 auto;
            @include media-breakpoint-down(lg) {
                padding: 0px 20px;
            }
        }
        #faqs-react-main-wrapper-faq-page {
            margin: 0 auto;
            @include media-breakpoint-down(lg) {
                padding: 0px 20px;
            }
        }
        .carouselParent {
            background: #F7F7F7;
            .carouselInner {
                max-width: 1240px;
                margin: 0 auto;
                position: relative;
            }
        }
        #carosalHeading0 {
            margin-top: 51px;
            @include media-breakpoint-down(sm) {
                margin-top: 0px;
            }
            img {
                height: 80px;
                @include media-breakpoint-down(sm) {
                    width: 166px;
                }
            }
        }
        #carosalHeading1 {
            height: 151px;
            @include media-breakpoint-down(sm) {
                height: auto;
            }
            img {
                height: 131px;
                @include media-breakpoint-down(sm) {
                    width: 163px;
                }
            }
        }
        #carosalHeading2 {
            height: 145px;
            margin-top: 9px;
            @include media-breakpoint-down(sm) {
                height: auto;
                margin-top: 0px;
            }
            img {
                @include media-breakpoint-down(sm) {
                    width: 163px;
                }
                height:122px;
            }
        }
        #carosalHeading3 {
            margin-top: 29px;
            height: 120px;
            @include media-breakpoint-down(sm) {
                height: auto;
                margin-top: 0px;
            }
            img {
                @include media-breakpoint-down(sm) {
                    width: 163px;
                }
                height:102px;
            }
        }
    }
    .site-footer {
        margin-top: 75px !important;
    }
}

.carousel-indicators {
    position: relative;
    margin-top: 6%;
    justify-content: left;
    padding: 0px;
    margin: 0 auto;
}

.carousel-indicators li {
    text-indent: 0;
    height: 100px;
    border: none;
    padding: 0px;
    margin: 0px;
    width: 25% !important;
}

.carousel-desktop h2.faq_title {
    padding-bottom: 10px;
}

.carousel-control-next,
.carousel-control-prev {
    width: 80px;
    top: 0px !important;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.carousel-indicators li.active span:after {
    content: '';
    position: absolute;
    bottom: -45px;
    left: 43%;
    width: 35px;
    height: 5px;
    background: #F36A30;
    display: block;
    @include media-breakpoint-down(md) {
        left: 40%;
        width: 37px;
    }
}

.carousel-indicators li span {
    font-size: 22px;
    text-align: center;
    padding-top: 14px;
    font-family: $font-sharpSans-Medium;
    color: #2A2A2A;
    display: block;
}

.carousel-inner {
    width: 90%;
    margin: auto;
    margin-top: 6%;
    background-color: #F7F7F7;
    padding-bottom: 3%;
    padding-top: 48px;
    margin-bottom: 90px;
    min-height: 220px;
    @include media-breakpoint-down(md) {
        width: 100%;
        padding: 15px 20px 0px;
        margin-top: 85px;
    }
}

.carousel-item p {
    font-size: 18px;
    color: #2A2A2A;
    margin-bottom: 0px;
    font-family: $font-SourceSans-Regular;
    @include media-breakpoint-down(md) {
        padding-left: 0px;
    }
}

.carousel-title h3 {
    font-size: 32px;
    color: #2A2A2A;
    font-family: $font-sharpSans-Medium;
    @include media-breakpoint-down(md) {
        text-align: center;
    }
}

span.step-color {
    color: #F36A30;
    font-size: 32px;
    font-family: $font-sharpSans-Semibold !important;
    @include media-breakpoint-down(sm) {
        font-size: 18px;
    }
}

.carousel-item .carousel-title {
    margin-top: 40px;
}

.carousel-item .carousel-description {
    padding-right: 5%;
    margin-top: 10px;
    border-left: 1px solid;
    @include media-breakpoint-down(md) {
        padding-right: 20px;
        margin-top: 20px;
        border-left: none;
    }
}

.view-faq h2.faq_title,
.section_title {
    text-align: center;
    margin-top: 15px;
    padding-top: 55px;
    width: 100%;
    font-size: 40px;
    font-family: $font-sharpSans-Book;
    color: #2A2A2A;
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
        font-size: 26px;
        margin-bottom: 10px;
    }
}

div#accordion {
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.cat_title,
.accordion-toggle {
    color: #F36A30;
}

.second_img img {
    width: 100%;
    object-fit: cover;
    padding: 0px;
}

.second_img {
    padding: 0px;
    height: auto !important;
    max-height: 520px;
    overflow: hidden;
    height: auto;
}

.second_content {
    background-color: #F36A30;
    padding: 0px;
}

li.card .focused_img,
li.card .unfocused_img {
    width: 100%;
}

li.card .focused_img {
    display: none;
}

li.card.active .focused_img {
    display: block;
}

li.card.active .unfocused_img {
    display: none;
}

.secondary_banner_section {
    margin-top: 77px;
    margin-bottom: 20px;
    .second_content_div {
        .learn-more {
            .btn:hover {
                color: #f36e37;
            }
            a {
                border-radius: 0;
                margin-top: 20px;
                span {
                    margin: 5px;
                }
            }
        }
    }
}

#accordion .card-header {
    background-color: #fff;
}

#accordion .accordion-title-box .accordion_title {
    cursor: pointer;
}

#accordion .accordion-title-box:last-child .accordion_title,
#accordion .accordion-title-box:last-child .accordion-item-body {
    border-bottom: none;
}

.accordion-toggle.collapsed {
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

#accordion .card-body {
    border-bottom: 1px solid #ff6600;
}


/* #accordion .accordion-toggle:before {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: grey;
}
#accordion .accordion-toggle[aria-expanded=true]:before {
    font-family: 'Glyphicons Halflings';
    content: "\e080";
    float: right;
    color: grey;
} */

.second_content .learn-more a {
    padding: 6.5px 10px 6.5px 10px;
    font-size: 18px;
    line-height: 30px;
    color: #2A2A2A;
    font-family: "sharpSans-Semibold";
    letter-spacing: 0.5px;
}

.second_content .learn-more a i {
    padding-left: 10px;
    font-size: 16px;
    color: #2A2A2A;
}

.second_content p.descr {
    color: #fff;
    font-size: 18px;
    font-family: $font-SourceSans-Regular;
    line-height: 30px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        max-height: 180px;
    }
    @include media-breakpoint-up(xl) {
        max-height: 210px;
    }
}

.second_content .second_content_div {
    padding: 40px 40px 40px 80px;
}

.second_content .second_content_div h2 strong {
    font-family: $font-sharpSans-Bold;
    font-size: 60px;
}

.second_content .second_content_div h2.white {
    font-size: 60px;
    font-family: $font-sharpSans-Light;
    line-height: 60px;
    margin-top: -10px;
}

.faq-search {
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.faq_accordion_title {
    text-align: center;
}

.FAQs-title {
    text-align: center !important;
}

#faq-search-input,
.second_content .learn-more a {
    /*     border-top: 0;
    border-left: 0;
    border-right: 0;
    background-image: url(../images/icons/search.png) !important;
    background-position: 80%;
    background-repeat: no-repeat;
    -webkit-background-size: 25px 25px;
    background-size: 20px;
	text-decoration: none; */
}

.align-center {
    text-align: center;
}

#accordionmobile .card-body {
    background-color: #eaeaea;
    line-height: 24px;
}

#accordionmobile .card-header {
    background-color: #fff;
    padding-top: 35px;
    @include media-breakpoint-down(sm) {
        padding: 0px;
    }
    a {
        color: inherit;
        @include media-breakpoint-down(sm) {
            padding: 35px 20px 12px;
            text-decoration: none;
        }
        &:hover {
            color: inherit;
        }
    }
}

#accordionmobile .accordion_step .focused_img {
    display: block;
    margin: auto;
}

#accordionmobile .accordion_step .unfocused_img {
    display: none;
}

#accordionmobile .accordion_step.collapsed .focused_img {
    display: none;
}

#accordionmobile .accordion_step.collapsed .unfocused_img {
    display: block;
    margin: auto;
}

#accordionmobile .collapse.show {
    border-bottom: 2px solid #f28658;
}

.faqs-main-wrapper {
	.accordion-title-box {
		.item-title-hidden {
			border-bottom: none !important;
			h3 {
				border-bottom: 1px solid rgba(119, 136, 153, 0.5) !important;
			}
		}
		&:last-child {
			.item-title-hidden {
				h3 {
					border-bottom: none !important;
				}
			}
		}
	}
	.accordion__button[aria-expanded='true'] {
		h3 {
			background: url("../images/resources/up-arrow.png") no-repeat scroll !important;
			font-size: 18px !important;
			position: relative !important;
			padding: 25px 0 35px !important;
			-webkit-background-size: 20px 20px !important;
			background-size: 20px 20px !important;
			background-position: 99.7% 27px !important;
			margin-bottom: 0 !important;
			padding-right: 35px !important;
			color: #f36a30 !important;
			border-bottom: none !important;
		}
		>.faqs-main-wrapper {
			.item-title-hidden {
				border-bottom: none !important;
			}
		}
	}
}

@media only screen and (min-width: 992px) {
    body.path-faq {
        .more-job-opp-section .title-value {
            text-align: center;
            margin-bottom: 40px;
        }
        .sidelink {
            a:hover {
                color: #D0118C;
                text-decoration: none;
            }
        }
        .testimonial-career-section {
            .desktop-display {
                display: block;
            }
        }
        .intern-possibility .accordion-title.item-title-hidden:hover {
            border-bottom: 1px solid #eaeaea;
            margin: 0px auto !important;
            padding: 15px 0;
            -webkit-background-size: 18px 12px;
            background-size: 18px 12px;
            background-position: 99.7% 20px;
        }
    }
    .node--type-careers-article {
        #block-contentfield-2 {
            .img-cover {
                height: 520px;
                .img-cover-left {
                    float: left;
                    width: 67%;
                    height: 520px;
                }
                .img-cover-right {
                    float: right;
                    width: 33%;
                    height: 520px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .fetured-bottom-cont {
                margin-top: 1%;
            }
        }
        .custom-nav-height {
            margin-top: 140px;
        }
    }
}

@media screen and (max-width: 767px) {
    .accordionmobile {
        display: block;
    }
    .carousel-desktop {
        display: none;
    }
    .path-faq {
        .faqs-wrapper {
            margin-top: 70px;
        }
        .accordionmobile {
            .accordion_step {
                p {
                    font-size: 18px;
                    margin-top: 15px;
                    .fa-chevron-down,
                    .fa-chevron-up {
                        float: right;
                        margin-top: 4px;
                        font-size: 20px;
                        color: #a7a9ac;
                    }
                    .fa-chevron-up {
                        color: #F06E37;
                    }
                }
            }
        }
        .testimonial-career-section {
            .mobile-display {
                display: block;
                .slick__arrow {
                    /** display:none; **/
                }
                .cont-right {
                    text-align: center;
                    margin-top: 7%;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .accordionmobile {
        display: none;
    }
    .carousel-desktop {
        display: block;
    }
}

.white {
    color: #fff;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    border: 1px solid transparent;
    background-color: transparent;
    display: block;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
}

.carousel-control-next-icon {
    background-image: url(../images/icons/carousel-right.png) !important;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.carousel-control-next-icon:hover {
    background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
    -webkit-transform: scale(1.2);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: scale(1.2);
    /* IE 9 */
    transform: scale(1.2);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.carousel-control-prev-icon {
    background-image: url(../images/icons/carousel-left.png) !important;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.carousel-control-prev-icon:hover {
    background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
    -webkit-transform: scale(1.2);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: scale(1.2);
    /* IE 9 */
    transform: scale(1.2);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

#accordion .card-header .accordion-toggle.collapsed {
    background-image: url('../images/icons/down-arrow.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 50px;
}

#accordion .card-header .accordion-toggle {
    background-image: url('../images/icons/up-arrow.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 50px;
}

.faq_content {
    margin: 0px auto;
    width: 71%;
}

@media (min-width: 1101px) and (max-width: 1350px) {
    .path-faq {
        .second_content {
            .second_content_div {
                .text-black {
                    line-height: 45px;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1100px) {
    .path-faq {
        .second_content {
            .second_content_div {
                padding: 20px 40px 20px 80px;
                .text-black {
                    line-height: 46px;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .path-faq {
        .carousel-item {
            .carousel-description {
                padding-bottom: 45px;
            }
        }
        .faqs-main-wrapper {
            .accordion-title {
                h3 {
                    line-height: 30px;
                }
            }
        }
        .testimonial-career-section {
            .ipad-display {
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .intern-possibilities-wrapper {
        .intern-possibilities.desktop-display {
            display: none;
        }
        .intern-possibilities.mobile-display {
            display: block;
        }
        .accordion-item-body {
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 30px;
        }
    }
    .intern-possibility .accordion-title {
        outline: 0;
        .fa-chevron-down {
            font-size: 20px;
            position: absolute;
            right: 0;
            top: 15px;
            &:before {
                content: "\f077";
            }
        }
        @include media-breakpoint-down(md) {
            border-bottom: none;
            margin: 0px auto !important;
            padding: 15px 0;
            //background: url("../images/resources/up-arrow.png") no-repeat scroll;
            background-size: 18px 12px;
            background-position: 99.7% 20px;
        }
    }
    .intern-possibility .accordion-title.item-title-hidden:hover {
        @include media-breakpoint-down(md) {
            border-bottom: 1px solid #eaeaea;
            margin: 0px auto !important;
            padding: 15px 0;
            //background: url("../images/resources/down-arrow-orange.png") no-repeat scroll;
            background-size: 18px 12px;
            background-position: 99.7% 20px;
        }
    }
    .intern-possibility .accordion-title.item-title-hidden {
        .fa-chevron-down {
            font-size: 20px;
            position: absolute;
            right: 0;
            top: 15px;
            color: inherit !important;
            &:before {
                content: "\f078";
            }
        }
        @include media-breakpoint-down(md) {
            border-bottom: 1px solid #eaeaea;
            margin: 0px auto !important;
            padding: 15px 0;
            //background: url("../images/resources/down-arrow.png") no-repeat scroll;
            background-size: 18px 12px;
            background-position: 99.7% 20px;
        }
    }
}