/**
 * @file
 * Default style for file module.
 */


/* File icons.*/

.file {
    padding-left: ($spacer * 1.5);
    /* LTR */
    display: inline-block;
    min-height: $font-size-base;
    background-repeat: no-repeat;
    background-position: left center;
    /* LTR */
}

[dir="rtl"] .file {
    padding-left: inherit;
    padding-right: ($spacer * 1.5);
    background-position: right center;
}

.file--general,
.file--application-octet-stream {
    background-image: url(../images/icons/application-octet-stream.png);
}

.file--package-x-generic {
    background-image: url(../images/icons/package-x-generic.png);
}

.file--x-office-spreadsheet {
    background-image: url(../images/icons/x-office-spreadsheet.png);
}

.file--x-office-document {
    background-image: url(../images/icons/x-office-document.png);
}

.file--x-office-presentation {
    background-image: url(../images/icons/x-office-presentation.png);
}

.file--text-x-script {
    background-image: url(../images/icons/text-x-script.png);
}

.file--text-html {
    background-image: url(../images/icons/text-html.png);
}

.file--text-plain {
    background-image: url(../images/icons/text-plain.png);
}

.file--application-pdf {
    background-image: url(../images/icons/application-pdf.png);
}

.file--application-x-executable {
    background-image: url(../images/icons/application-x-executable.png);
}

.file--audio {
    background-image: url(../images/icons/audio-x-generic.png);
}

.file--video {
    background-image: url(../images/icons/video-x-generic.png);
}

.file--text {
    background-image: url(../images/icons/text-x-generic.png);
}

.file--image {
    background-image: url(../images/icons/image-x-generic.png);
}