/**
 * @file
 * Styles for Bootstrap Barrio affix effect.
 */

.affix {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1035;
  left: 0;
}
