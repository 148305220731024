/**
 * @file
 * Visual styles for Bootstrap Barrio's form components.
 */

.form-required::after {
    content: '';
    vertical-align: super;
    display: inline-block;
    background-image: url(../../images/required.svg);
    background-repeat: no-repeat;
    background-size: ($input-height / 2) ($input-height / 2);
    width: ($input-height / 2);
    height: ($input-height / 2);
    margin: 0 0.3em;
}

form .filter-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}

form .form-type-textarea {
    margin-bottom: 0;
}