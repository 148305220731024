/**
 * @file
 * Styles for the skip link.
 */

.skip-link {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 50;
    background: $gray-700;
    font-size: $font-size-base;
    line-height: $line-height-lg;
    padding: 1px $spacer 2px;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    border-bottom-width: 0;
    outline: 0;
}

.skip-link.visually-hidden.focusable:focus {
    position: absolute !important;
    color: $white;
}