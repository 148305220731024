/*
Theme Name: Bootstrap 4 Sass
Description: Bootstrap 4 with Sass
*/
/* IMPORTS */
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
/* VARIABLES */
/* TYPOGRAPHY */
/* NBCUni Careers Fonts */
@font-face {
  font-family: 'Rock-SansNormal';
  src: url("../fonts/nbcu_rock/rocksansnormal/Rock-SansNormal.eot");
  src: url("../fonts/nbcu_rock/rocksansnormal/Rock-SansNormal.woff2") format("woff2"), url("../fonts/nbcu_rock/rocksansnormal/Rock-SansNormal.woff") format("woff"), url("../fonts/nbcu_rock/rocksansnormal/Rock-SansNormal.ttf") format("truetype"), url("../fonts/nbcu_rock/rocksansnormal/Rock-SansNormal.svg#Rock-SansNormal") format("svg"), url("../fonts/nbcu_rock/rocksansnormal/Rock-SansNormal.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Rock-SansItalic';
  src: url("../fonts/nbcu_rock/rocksansitalic/Rock-SansItalic.eot");
  src: url("../fonts/nbcu_rock/rocksansitalic/Rock-SansItalic.woff2") format("woff2"), url("../fonts/nbcu_rock/rocksansitalic/Rock-SansItalic.woff") format("woff"), url("../fonts/nbcu_rock/rocksansitalic/Rock-SansItalic.ttf") format("truetype"), url("../fonts/nbcu_rock/rocksansitalic/Rock-SansItalic.svg#Rock-SansItalic") format("svg"), url("../fonts/nbcu_rock/rocksansitalic/Rock-SansItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Rock-SansBold';
  src: url("../fonts/nbcu_rock/rocksansbold/Rock-SansBold.eot");
  src: url("../fonts/nbcu_rock/rocksansbold/Rock-SansBold.woff2") format("woff2"), url("../fonts/nbcu_rock/rocksansbold/Rock-SansBold.woff") format("woff"), url("../fonts/nbcu_rock/rocksansbold/Rock-SansBold.ttf") format("truetype"), url("../fonts/nbcu_rock/rocksansbold/Rock-SansBold.svg#Rock-SansBold") format("svg"), url("../fonts/nbcu_rock/rocksansbold/Rock-SansBold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Rock-SansBoldItalic';
  src: url("../fonts/nbcu_rock/rocksansbolditalic/Rock-SansBoldItalic.eot");
  src: url("../fonts/nbcu_rock/rocksansbolditalic/Rock-SansBoldItalic.woff2") format("woff2"), url("../fonts/nbcu_rock/rocksansbolditalic/Rock-SansBoldItalic.woff") format("woff"), url("../fonts/nbcu_rock/rocksansbolditalic/Rock-SansBoldItalic.ttf") format("truetype"), url("../fonts/nbcu_rock/rocksansbolditalic/Rock-SansBoldItalic.svg#Rock-SansBoldItalic") format("svg"), url("../fonts/nbcu_rock/rocksansbolditalic/Rock-SansBoldItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Black';
  src: url("../fonts/sharpSans/SharpSansNo1-Black.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-Black.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-Black.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-Black.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-BlackItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-BlackItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-BlackItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-BlackItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-BlackItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Bold';
  /*src: url('../fonts/sharpSans/SharpSansNo1-Bold.eot');
  src: url('../fonts/sharpSans/SharpSansNo1-Bold.woff2') format('woff2'),
       url('../fonts/sharpSans/SharpSansNo1-Bold.woff') format('woff'),
       url('../fonts/sharpSans/SharpSansNo1-Bold.eot?#iefix') format('embedded-opentype');*/
  src: url("../fonts/SharpSansNo1/SharpSansNo1-Bold.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Bold-New';
  src: url("../fonts/sharpSansNew/SharpSans-Bold.eot");
  src: url("../fonts/sharpSansNew/SharpSans-Bold.woff2") format("woff2"), url("../fonts/sharpSansNew/SharpSans-Bold.woff") format("woff"), url("../fonts/sharpSansNew/SharpSans-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-BoldItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-BoldItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-BoldItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-BoldItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-BoldItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Book';
  /*src: url('../fonts/sharpSans/SharpSansNo1-Book.eot');
  src: url('../fonts/sharpSans/SharpSansNo1-Book.woff2') format('woff2'),
       url('../fonts/sharpSans/SharpSansNo1-Book.woff') format('woff'),
       url('../fonts/sharpSans/SharpSansNo1-Book.eot?#iefix') format('embedded-opentype');*/
  src: url("../fonts/SharpSansNo1/SharpSansNo1-Book.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-BookItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-BookItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-BookItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-BookItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-BookItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Extrabold';
  src: url("../fonts/sharpSans/SharpSansNo1-Extrabold.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-Extrabold.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-Extrabold.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-Extrabold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-ExtraboldItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-ExtraboldItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-ExtraboldItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-ExtraboldItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-ExtraboldItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Hairline';
  src: url("../fonts/sharpSans/SharpSansNo1-Hairline.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-Hairline.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-Hairline.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-Hairline.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-HairlineItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-HairlineItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-HairlineItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-HairlineItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-HairlineItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Light';
  /*src: url('../fonts/sharpSans/SharpSansNo1-Light.eot');
  src: url('../fonts/sharpSans/SharpSansNo1-Light.woff2') format('woff2'),
       url('../fonts/sharpSans/SharpSansNo1-Light.woff') format('woff'),
       url('../fonts/sharpSans/SharpSansNo1-Light.eot?#iefix') format('embedded-opentype');*/
  src: url("../fonts/SharpSansNo1/SharpSansNo1-Light.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-LightItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-LightItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-LightItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-LightItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-LightItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Medium';
  /*src: url('../fonts/sharpSans/SharpSansNo1-Medium.eot');
  src: url('../fonts/sharpSans/SharpSansNo1-Medium.woff2') format('woff2'),
       url('../fonts/sharpSans/SharpSansNo1-Medium.woff') format('woff'),
       url('../fonts/sharpSans/SharpSansNo1-Medium.eot?#iefix') format('embedded-opentype');*/
  src: url("../fonts/SharpSansNo1/SharpSansNo1-Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-MediumItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-MediumItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-MediumItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-MediumItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-MediumItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Semibold';
  /*src: url('../fonts/sharpSans/SharpSansNo1-Semibold.eot');
  src: url('../fonts/sharpSans/SharpSansNo1-Semibold.woff2') format('woff2'),
       url('../fonts/sharpSans/SharpSansNo1-Semibold.woff') format('woff'),
       url('../fonts/sharpSans/SharpSansNo1-Semibold.eot?#iefix') format('embedded-opentype');*/
  src: url("../fonts/SharpSansNo1/SharpSansNo1-Semibold.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-SemiboldItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-SemiboldItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-SemiboldItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-SemiboldItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-SemiboldItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Thin';
  src: url("../fonts/sharpSans/SharpSansNo1-Thin.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-Thin.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-Thin.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-Thin.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-ThinItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-ThinItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-ThinItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-ThinItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-ThinItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-Ultrathin';
  src: url("../fonts/sharpSans/SharpSansNo1-Ultrathin.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-Ultrathin.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-Ultrathin.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-Ultrathin.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sharpSans-UltrathinItalic';
  src: url("../fonts/sharpSans/SharpSansNo1-UltrathinItalic.eot");
  src: url("../fonts/sharpSans/SharpSansNo1-UltrathinItalic.woff2") format("woff2"), url("../fonts/sharpSans/SharpSansNo1-UltrathinItalic.woff") format("woff"), url("../fonts/sharpSans/SharpSansNo1-UltrathinItalic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-Black';
  src: url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Black.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Black.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-Black.otf") format("opentype"), url("../fonts/SourceSansPro/TTF/SourceSansPro-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-BlackItalic';
  src: url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-BlackIt.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-BlackIt.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-BlackIt.otf") format("opentype"), url("../fonts/SourceSansPro/TTF/SourceSansPro-BlackIt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url("../fonts/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype"), url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Regular.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Regular.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: url("../fonts/SourceSansPro/SourceSansPro-Bold.ttf") format("truetype"), url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Bold.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Bold.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-BoldItalic';
  src: url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-BoldIt.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-BoldIt.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-BoldIt.otf") format("opentype"), url("../fonts/SourceSansPro/TTF/SourceSansPro-BoldIt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-Semibold';
  src: url("../fonts/SourceSansPro/SourceSansPro-Semibold.ttf") format("truetype"), url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Semibold.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Semibold.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-Semibold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-SemiboldItalic';
  src: url("../fonts/SourceSansPro/SourceSansPro-Semibold.ttf") format("truetype"), url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Semibold.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Semibold.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-Semibold.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-It';
  src: url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-It.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-It.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-It.otf") format("opentype"), url("../fonts/SourceSansPro/TTF/SourceSansPro-It.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-ExtraLight';
  src: url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-ExtraLight.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-ExtraLight.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-ExtraLight.otf") format("opentype"), url("../fonts/SourceSansPro/TTF/SourceSansPro-ExtraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-ExtraLightIt';
  src: url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-ExtraLightIt.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-ExtraLightIt.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-ExtraLightIt.otf") format("opentype"), url("../fonts/SourceSansPro/TTF/SourceSansPro-ExtraLightIt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-Light';
  src: url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Light.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Light.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-Light.otf") format("opentype"), url("../fonts/SourceSansPro/TTF/SourceSansPro-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro-LightItalic';
  src: url("../fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-LightIt.ttf.woff2") format("woff2"), url("../fonts/SourceSansPro/WOFF/OTF/SourceSansPro-LightIt.otf.woff") format("woff"), url("../fonts/SourceSansPro/OTF/SourceSansPro-LightIt.otf") format("opentype"), url("../fonts/SourceSansPro/TTF/SourceSansPro-LightIt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e"; }

.fa-abacus:before {
  content: "\f640"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acorn:before {
  content: "\f6ae"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-ad:before {
  content: "\f641"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adobe:before {
  content: "\f778"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-air-freshener:before {
  content: "\f5d0"; }

.fa-alarm-clock:before {
  content: "\f34e"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-alicorn:before {
  content: "\f6b0"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-analytics:before {
  content: "\f643"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angel:before {
  content: "\f779"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-ankh:before {
  content: "\f644"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-alt:before {
  content: "\f5d1"; }

.fa-apple-crate:before {
  content: "\f6b1"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-alt-down:before {
  content: "\f354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\f346"; }

.fa-arrow-alt-from-left:before {
  content: "\f347"; }

.fa-arrow-alt-from-right:before {
  content: "\f348"; }

.fa-arrow-alt-from-top:before {
  content: "\f349"; }

.fa-arrow-alt-left:before {
  content: "\f355"; }

.fa-arrow-alt-right:before {
  content: "\f356"; }

.fa-arrow-alt-square-down:before {
  content: "\f350"; }

.fa-arrow-alt-square-left:before {
  content: "\f351"; }

.fa-arrow-alt-square-right:before {
  content: "\f352"; }

.fa-arrow-alt-square-up:before {
  content: "\f353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\f34a"; }

.fa-arrow-alt-to-left:before {
  content: "\f34b"; }

.fa-arrow-alt-to-right:before {
  content: "\f34c"; }

.fa-arrow-alt-to-top:before {
  content: "\f34d"; }

.fa-arrow-alt-up:before {
  content: "\f357"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-from-bottom:before {
  content: "\f342"; }

.fa-arrow-from-left:before {
  content: "\f343"; }

.fa-arrow-from-right:before {
  content: "\f344"; }

.fa-arrow-from-top:before {
  content: "\f345"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-square-down:before {
  content: "\f339"; }

.fa-arrow-square-left:before {
  content: "\f33a"; }

.fa-arrow-square-right:before {
  content: "\f33b"; }

.fa-arrow-square-up:before {
  content: "\f33c"; }

.fa-arrow-to-bottom:before {
  content: "\f33d"; }

.fa-arrow-to-left:before {
  content: "\f33e"; }

.fa-arrow-to-right:before {
  content: "\f340"; }

.fa-arrow-to-top:before {
  content: "\f341"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows:before {
  content: "\f047"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-arrows-h:before {
  content: "\f07e"; }

.fa-arrows-v:before {
  content: "\f07d"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-atom:before {
  content: "\f5d2"; }

.fa-atom-alt:before {
  content: "\f5d3"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-axe:before {
  content: "\f6b2"; }

.fa-axe-battle:before {
  content: "\f6b3"; }

.fa-baby:before {
  content: "\f77c"; }

.fa-baby-carriage:before {
  content: "\f77d"; }

.fa-backpack:before {
  content: "\f5d4"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-bacon:before {
  content: "\f7e5"; }

.fa-badge:before {
  content: "\f335"; }

.fa-badge-check:before {
  content: "\f336"; }

.fa-badge-dollar:before {
  content: "\f645"; }

.fa-badge-percent:before {
  content: "\f646"; }

.fa-badger-honey:before {
  content: "\f6b4"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-balance-scale-left:before {
  content: "\f515"; }

.fa-balance-scale-right:before {
  content: "\f516"; }

.fa-ball-pile:before {
  content: "\f77e"; }

.fa-ballot:before {
  content: "\f732"; }

.fa-ballot-check:before {
  content: "\f733"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-barcode-alt:before {
  content: "\f463"; }

.fa-barcode-read:before {
  content: "\f464"; }

.fa-barcode-scan:before {
  content: "\f465"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball:before {
  content: "\f432"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-basketball-hoop:before {
  content: "\f435"; }

.fa-bat:before {
  content: "\f6b5"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-bolt:before {
  content: "\f376"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-slash:before {
  content: "\f377"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-school:before {
  content: "\f5d5"; }

.fa-bell-school-slash:before {
  content: "\f5d6"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bells:before {
  content: "\f77f"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bible:before {
  content: "\f647"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-biohazard:before {
  content: "\f780"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blanket:before {
  content: "\f498"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blender-phone:before {
  content: "\f6b6"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blog:before {
  content: "\f781"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bone:before {
  content: "\f5d7"; }

.fa-bone-break:before {
  content: "\f5d8"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-alt:before {
  content: "\f5d9"; }

.fa-book-dead:before {
  content: "\f6b7"; }

.fa-book-heart:before {
  content: "\f499"; }

.fa-book-medical:before {
  content: "\f7e6"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-book-reader:before {
  content: "\f5da"; }

.fa-book-spells:before {
  content: "\f6b8"; }

.fa-book-user:before {
  content: "\f7e7"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-books:before {
  content: "\f5db"; }

.fa-books-medical:before {
  content: "\f7e8"; }

.fa-boot:before {
  content: "\f782"; }

.fa-booth-curtain:before {
  content: "\f734"; }

.fa-bow-arrow:before {
  content: "\f6b9"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-bowling-pins:before {
  content: "\f437"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-alt:before {
  content: "\f49a"; }

.fa-box-ballot:before {
  content: "\f735"; }

.fa-box-check:before {
  content: "\f467"; }

.fa-box-fragile:before {
  content: "\f49b"; }

.fa-box-full:before {
  content: "\f49c"; }

.fa-box-heart:before {
  content: "\f49d"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-box-up:before {
  content: "\f49f"; }

.fa-box-usd:before {
  content: "\f4a0"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-boxes-alt:before {
  content: "\f4a1"; }

.fa-boxing-glove:before {
  content: "\f438"; }

.fa-brackets:before {
  content: "\f7e9"; }

.fa-brackets-curly:before {
  content: "\f7ea"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-brain:before {
  content: "\f5dc"; }

.fa-bread-loaf:before {
  content: "\f7eb"; }

.fa-bread-slice:before {
  content: "\f7ec"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-browser:before {
  content: "\f37e"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-bullseye-arrow:before {
  content: "\f648"; }

.fa-bullseye-pointer:before {
  content: "\f649"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-burrito:before {
  content: "\f7ed"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-bus-school:before {
  content: "\f5dd"; }

.fa-business-time:before {
  content: "\f64a"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-cabinet-filing:before {
  content: "\f64b"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calculator-alt:before {
  content: "\f64c"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-day:before {
  content: "\f783"; }

.fa-calendar-edit:before {
  content: "\f333"; }

.fa-calendar-exclamation:before {
  content: "\f334"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-star:before {
  content: "\f736"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-calendar-week:before {
  content: "\f784"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-alt:before {
  content: "\f332"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-campfire:before {
  content: "\f6ba"; }

.fa-campground:before {
  content: "\f6bb"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-candle-holder:before {
  content: "\f6bc"; }

.fa-candy-cane:before {
  content: "\f786"; }

.fa-candy-corn:before {
  content: "\f6bd"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-car-alt:before {
  content: "\f5de"; }

.fa-car-battery:before {
  content: "\f5df"; }

.fa-car-bump:before {
  content: "\f5e0"; }

.fa-car-crash:before {
  content: "\f5e1"; }

.fa-car-garage:before {
  content: "\f5e2"; }

.fa-car-mechanic:before {
  content: "\f5e3"; }

.fa-car-side:before {
  content: "\f5e4"; }

.fa-car-tilt:before {
  content: "\f5e5"; }

.fa-car-wash:before {
  content: "\f5e6"; }

.fa-caret-circle-down:before {
  content: "\f32d"; }

.fa-caret-circle-left:before {
  content: "\f32e"; }

.fa-caret-circle-right:before {
  content: "\f330"; }

.fa-caret-circle-up:before {
  content: "\f331"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-carrot:before {
  content: "\f787"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cash-register:before {
  content: "\f788"; }

.fa-cat:before {
  content: "\f6be"; }

.fa-cauldron:before {
  content: "\f6bf"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chair:before {
  content: "\f6c0"; }

.fa-chair-office:before {
  content: "\f6c1"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-charging-station:before {
  content: "\f5e7"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-line-down:before {
  content: "\f64d"; }

.fa-chart-network:before {
  content: "\f78a"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-chart-pie-alt:before {
  content: "\f64e"; }

.fa-chart-scatter:before {
  content: "\f7ee"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-cheese:before {
  content: "\f7ef"; }

.fa-cheese-swiss:before {
  content: "\f7f0"; }

.fa-cheeseburger:before {
  content: "\f7f1"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-bishop-alt:before {
  content: "\f43b"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-clock:before {
  content: "\f43d"; }

.fa-chess-clock-alt:before {
  content: "\f43e"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-king-alt:before {
  content: "\f440"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-knight-alt:before {
  content: "\f442"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-pawn-alt:before {
  content: "\f444"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-queen-alt:before {
  content: "\f446"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chess-rook-alt:before {
  content: "\f448"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-double-down:before {
  content: "\f322"; }

.fa-chevron-double-left:before {
  content: "\f323"; }

.fa-chevron-double-right:before {
  content: "\f324"; }

.fa-chevron-double-up:before {
  content: "\f325"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-square-down:before {
  content: "\f329"; }

.fa-chevron-square-left:before {
  content: "\f32a"; }

.fa-chevron-square-right:before {
  content: "\f32b"; }

.fa-chevron-square-up:before {
  content: "\f32c"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chimney:before {
  content: "\f78b"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-city:before {
  content: "\f64f"; }

.fa-claw-marks:before {
  content: "\f6c2"; }

.fa-clinic-medical:before {
  content: "\f7f2"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clipboard-list-check:before {
  content: "\f737"; }

.fa-clipboard-prescription:before {
  content: "\f5e8"; }

.fa-clipboard-user:before {
  content: "\f7f3"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-drizzle:before {
  content: "\f738"; }

.fa-cloud-hail:before {
  content: "\f739"; }

.fa-cloud-hail-mixed:before {
  content: "\f73a"; }

.fa-cloud-meatball:before {
  content: "\f73b"; }

.fa-cloud-moon:before {
  content: "\f6c3"; }

.fa-cloud-moon-rain:before {
  content: "\f73c"; }

.fa-cloud-rain:before {
  content: "\f73d"; }

.fa-cloud-rainbow:before {
  content: "\f73e"; }

.fa-cloud-showers:before {
  content: "\f73f"; }

.fa-cloud-showers-heavy:before {
  content: "\f740"; }

.fa-cloud-sleet:before {
  content: "\f741"; }

.fa-cloud-snow:before {
  content: "\f742"; }

.fa-cloud-sun:before {
  content: "\f6c4"; }

.fa-cloud-sun-rain:before {
  content: "\f743"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-clouds:before {
  content: "\f744"; }

.fa-clouds-moon:before {
  content: "\f745"; }

.fa-clouds-sun:before {
  content: "\f746"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-club:before {
  content: "\f327"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-code-commit:before {
  content: "\f386"; }

.fa-code-merge:before {
  content: "\f387"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-coffee-togo:before {
  content: "\f6c5"; }

.fa-coffin:before {
  content: "\f6c6"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-alt-check:before {
  content: "\f4a2"; }

.fa-comment-alt-dollar:before {
  content: "\f650"; }

.fa-comment-alt-dots:before {
  content: "\f4a3"; }

.fa-comment-alt-edit:before {
  content: "\f4a4"; }

.fa-comment-alt-exclamation:before {
  content: "\f4a5"; }

.fa-comment-alt-lines:before {
  content: "\f4a6"; }

.fa-comment-alt-medical:before {
  content: "\f7f4"; }

.fa-comment-alt-minus:before {
  content: "\f4a7"; }

.fa-comment-alt-plus:before {
  content: "\f4a8"; }

.fa-comment-alt-slash:before {
  content: "\f4a9"; }

.fa-comment-alt-smile:before {
  content: "\f4aa"; }

.fa-comment-alt-times:before {
  content: "\f4ab"; }

.fa-comment-check:before {
  content: "\f4ac"; }

.fa-comment-dollar:before {
  content: "\f651"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-edit:before {
  content: "\f4ae"; }

.fa-comment-exclamation:before {
  content: "\f4af"; }

.fa-comment-lines:before {
  content: "\f4b0"; }

.fa-comment-medical:before {
  content: "\f7f5"; }

.fa-comment-minus:before {
  content: "\f4b1"; }

.fa-comment-plus:before {
  content: "\f4b2"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comment-smile:before {
  content: "\f4b4"; }

.fa-comment-times:before {
  content: "\f4b5"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-alt:before {
  content: "\f4b6"; }

.fa-comments-alt-dollar:before {
  content: "\f652"; }

.fa-comments-dollar:before {
  content: "\f653"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compass-slash:before {
  content: "\f5e9"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-alt:before {
  content: "\f422"; }

.fa-compress-arrows-alt:before {
  content: "\f78c"; }

.fa-compress-wide:before {
  content: "\f326"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-container-storage:before {
  content: "\f4b7"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-conveyor-belt:before {
  content: "\f46e"; }

.fa-conveyor-belt-alt:before {
  content: "\f46f"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-corn:before {
  content: "\f6c7"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cow:before {
  content: "\f6c8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-credit-card-blank:before {
  content: "\f389"; }

.fa-credit-card-front:before {
  content: "\f38a"; }

.fa-cricket:before {
  content: "\f449"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-croissant:before {
  content: "\f7f6"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-cross:before {
  content: "\f654"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-crutch:before {
  content: "\f7f7"; }

.fa-crutches:before {
  content: "\f7f8"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-curling:before {
  content: "\f44a"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dagger:before {
  content: "\f6cb"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-debug:before {
  content: "\f7f9"; }

.fa-deer:before {
  content: "\f78e"; }

.fa-deer-rudolph:before {
  content: "\f78f"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-democrat:before {
  content: "\f747"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-desktop-alt:before {
  content: "\f390"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dewpoint:before {
  content: "\f748"; }

.fa-dharmachakra:before {
  content: "\f655"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diamond:before {
  content: "\f219"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-d10:before {
  content: "\f6cd"; }

.fa-dice-d12:before {
  content: "\f6ce"; }

.fa-dice-d20:before {
  content: "\f6cf"; }

.fa-dice-d4:before {
  content: "\f6d0"; }

.fa-dice-d6:before {
  content: "\f6d1"; }

.fa-dice-d8:before {
  content: "\f6d2"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-diploma:before {
  content: "\f5ea"; }

.fa-directions:before {
  content: "\f5eb"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-disease:before {
  content: "\f7fa"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-do-not-enter:before {
  content: "\f5ec"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dog:before {
  content: "\f6d3"; }

.fa-dog-leashed:before {
  content: "\f6d4"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-empty:before {
  content: "\f473"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-dolly-flatbed-alt:before {
  content: "\f475"; }

.fa-dolly-flatbed-empty:before {
  content: "\f476"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-dragon:before {
  content: "\f6d5"; }

.fa-draw-circle:before {
  content: "\f5ed"; }

.fa-draw-polygon:before {
  content: "\f5ee"; }

.fa-draw-square:before {
  content: "\f5ef"; }

.fa-dreidel:before {
  content: "\f792"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drumstick:before {
  content: "\f6d6"; }

.fa-drumstick-bite:before {
  content: "\f6d7"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-duck:before {
  content: "\f6d8"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dumpster:before {
  content: "\f793"; }

.fa-dumpster-fire:before {
  content: "\f794"; }

.fa-dungeon:before {
  content: "\f6d9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-ear:before {
  content: "\f5f0"; }

.fa-ear-muffs:before {
  content: "\f795"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-eclipse:before {
  content: "\f749"; }

.fa-eclipse-alt:before {
  content: "\f74a"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-egg:before {
  content: "\f7fb"; }

.fa-egg-fried:before {
  content: "\f7fc"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-elephant:before {
  content: "\f6da"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-h-alt:before {
  content: "\f39b"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ellipsis-v-alt:before {
  content: "\f39c"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-empty-set:before {
  content: "\f656"; }

.fa-engine-warning:before {
  content: "\f5f2"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-dollar:before {
  content: "\f657"; }

.fa-envelope-open-text:before {
  content: "\f658"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-ethernet:before {
  content: "\f796"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-square:before {
  content: "\f321"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-alt:before {
  content: "\f424"; }

.fa-expand-arrows:before {
  content: "\f31d"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expand-wide:before {
  content: "\f320"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-evil:before {
  content: "\f6db"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-female:before {
  content: "\f182"; }

.fa-field-hockey:before {
  content: "\f44c"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-figma:before {
  content: "\f799"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-certificate:before {
  content: "\f5f3"; }

.fa-file-chart-line:before {
  content: "\f659"; }

.fa-file-chart-pie:before {
  content: "\f65a"; }

.fa-file-check:before {
  content: "\f316"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-csv:before {
  content: "\f6dd"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-edit:before {
  content: "\f31c"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-exclamation:before {
  content: "\f31a"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-minus:before {
  content: "\f318"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-plus:before {
  content: "\f319"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-spreadsheet:before {
  content: "\f65b"; }

.fa-file-times:before {
  content: "\f317"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-user:before {
  content: "\f65c"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-files-medical:before {
  content: "\f7fd"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-film-alt:before {
  content: "\f3a0"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-alt:before {
  content: "\f7e4"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-fire-smoke:before {
  content: "\f74b"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-fireplace:before {
  content: "\f79a"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-fish-cooked:before {
  content: "\f7fe"; }

.fa-fist-raised:before {
  content: "\f6de"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-alt:before {
  content: "\f74c"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flag-usa:before {
  content: "\f74d"; }

.fa-flame:before {
  content: "\f6df"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flask-poison:before {
  content: "\f6e0"; }

.fa-flask-potion:before {
  content: "\f6e1"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flower:before {
  content: "\f7ff"; }

.fa-flower-daffodil:before {
  content: "\f800"; }

.fa-flower-tulip:before {
  content: "\f801"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-fog:before {
  content: "\f74e"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-minus:before {
  content: "\f65d"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-folder-plus:before {
  content: "\f65e"; }

.fa-folder-times:before {
  content: "\f65f"; }

.fa-folder-tree:before {
  content: "\f802"; }

.fa-folders:before {
  content: "\f660"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-football-helmet:before {
  content: "\f44f"; }

.fa-forklift:before {
  content: "\f47a"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-fragile:before {
  content: "\f4bb"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-french-fries:before {
  content: "\f803"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frosty-head:before {
  content: "\f79b"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-function:before {
  content: "\f661"; }

.fa-funnel-dollar:before {
  content: "\f662"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gas-pump-slash:before {
  content: "\f5f4"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-ghost:before {
  content: "\f6e2"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-gift-card:before {
  content: "\f663"; }

.fa-gifts:before {
  content: "\f79c"; }

.fa-gingerbread-man:before {
  content: "\f79d"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass:before {
  content: "\f804"; }

.fa-glass-champagne:before {
  content: "\f79e"; }

.fa-glass-cheers:before {
  content: "\f79f"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glass-whiskey:before {
  content: "\f7a0"; }

.fa-glass-whiskey-rocks:before {
  content: "\f7a1"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glasses-alt:before {
  content: "\f5f5"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-globe-europe:before {
  content: "\f7a2"; }

.fa-globe-snow:before {
  content: "\f7a3"; }

.fa-globe-stand:before {
  content: "\f5f6"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-golf-club:before {
  content: "\f451"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gopuram:before {
  content: "\f664"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-lines:before {
  content: "\f7a4"; }

.fa-grip-lines-vertical:before {
  content: "\f7a5"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guitar:before {
  content: "\f7a6"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-h1:before {
  content: "\f313"; }

.fa-h2:before {
  content: "\f314"; }

.fa-h3:before {
  content: "\f315"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hamburger:before {
  content: "\f805"; }

.fa-hammer:before {
  content: "\f6e3"; }

.fa-hammer-war:before {
  content: "\f6e4"; }

.fa-hamsa:before {
  content: "\f665"; }

.fa-hand-heart:before {
  content: "\f4bc"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-box:before {
  content: "\f47b"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-magic:before {
  content: "\f6e5"; }

.fa-hand-holding-seedling:before {
  content: "\f4bf"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-holding-water:before {
  content: "\f4c1"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-middle-finger:before {
  content: "\f806"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-receiving:before {
  content: "\f47c"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-heart:before {
  content: "\f4c3"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-hands-usd:before {
  content: "\f4c5"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-handshake-alt:before {
  content: "\f4c6"; }

.fa-hanukiah:before {
  content: "\f6e6"; }

.fa-hard-hat:before {
  content: "\f807"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-hat-santa:before {
  content: "\f7a7"; }

.fa-hat-winter:before {
  content: "\f7a8"; }

.fa-hat-witch:before {
  content: "\f6e7"; }

.fa-hat-wizard:before {
  content: "\f6e8"; }

.fa-haykal:before {
  content: "\f666"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-head-side:before {
  content: "\f6e9"; }

.fa-head-side-brain:before {
  content: "\f808"; }

.fa-head-side-medical:before {
  content: "\f809"; }

.fa-head-vr:before {
  content: "\f6ea"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-broken:before {
  content: "\f7a9"; }

.fa-heart-circle:before {
  content: "\f4c7"; }

.fa-heart-rate:before {
  content: "\f5f8"; }

.fa-heart-square:before {
  content: "\f4c8"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-helmet-battle:before {
  content: "\f6eb"; }

.fa-hexagon:before {
  content: "\f312"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hiking:before {
  content: "\f6ec"; }

.fa-hippo:before {
  content: "\f6ed"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-mask:before {
  content: "\f6ee"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-hockey-sticks:before {
  content: "\f454"; }

.fa-holly-berry:before {
  content: "\f7aa"; }

.fa-home:before {
  content: "\f015"; }

.fa-home-alt:before {
  content: "\f80a"; }

.fa-home-heart:before {
  content: "\f4c9"; }

.fa-home-lg:before {
  content: "\f80b"; }

.fa-home-lg-alt:before {
  content: "\f80c"; }

.fa-hood-cloak:before {
  content: "\f6ef"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-horse:before {
  content: "\f6f0"; }

.fa-horse-head:before {
  content: "\f7ab"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hospital-user:before {
  content: "\f80d"; }

.fa-hospitals:before {
  content: "\f80e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotdog:before {
  content: "\f80f"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-house-damage:before {
  content: "\f6f1"; }

.fa-house-flood:before {
  content: "\f74f"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-hryvnia:before {
  content: "\f6f2"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-humidity:before {
  content: "\f750"; }

.fa-hurricane:before {
  content: "\f751"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-ice-cream:before {
  content: "\f810"; }

.fa-ice-skate:before {
  content: "\f7ac"; }

.fa-icicles:before {
  content: "\f7ad"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-igloo:before {
  content: "\f7ae"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-inbox-in:before {
  content: "\f310"; }

.fa-inbox-out:before {
  content: "\f311"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-industry-alt:before {
  content: "\f3b3"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-info-square:before {
  content: "\f30f"; }

.fa-inhaler:before {
  content: "\f5f9"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-integral:before {
  content: "\f667"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-intersection:before {
  content: "\f668"; }

.fa-inventory:before {
  content: "\f480"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-island-tropical:before {
  content: "\f811"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-jack-o-lantern:before {
  content: "\f30e"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi:before {
  content: "\f669"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-journal-whills:before {
  content: "\f66a"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaaba:before {
  content: "\f66b"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-key:before {
  content: "\f084"; }

.fa-key-skeleton:before {
  content: "\f6f3"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-keynote:before {
  content: "\f66c"; }

.fa-khanda:before {
  content: "\f66d"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kidneys:before {
  content: "\f5fb"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kite:before {
  content: "\f6f4"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-knife-kitchen:before {
  content: "\f6f5"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-lambda:before {
  content: "\f66e"; }

.fa-lamp:before {
  content: "\f4ca"; }

.fa-landmark:before {
  content: "\f66f"; }

.fa-landmark-alt:before {
  content: "\f752"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laptop-code:before {
  content: "\f5fc"; }

.fa-laptop-medical:before {
  content: "\f812"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-layer-group:before {
  content: "\f5fd"; }

.fa-layer-minus:before {
  content: "\f5fe"; }

.fa-layer-plus:before {
  content: "\f5ff"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leaf-heart:before {
  content: "\f4cb"; }

.fa-leaf-maple:before {
  content: "\f6f6"; }

.fa-leaf-oak:before {
  content: "\f6f7"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-lightbulb-dollar:before {
  content: "\f670"; }

.fa-lightbulb-exclamation:before {
  content: "\f671"; }

.fa-lightbulb-on:before {
  content: "\f672"; }

.fa-lightbulb-slash:before {
  content: "\f673"; }

.fa-lights-holiday:before {
  content: "\f7b2"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lips:before {
  content: "\f600"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location:before {
  content: "\f601"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-location-circle:before {
  content: "\f602"; }

.fa-location-slash:before {
  content: "\f603"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-alt:before {
  content: "\f30d"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-lock-open-alt:before {
  content: "\f3c2"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-loveseat:before {
  content: "\f4cc"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luchador:before {
  content: "\f455"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lungs:before {
  content: "\f604"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-mace:before {
  content: "\f6f8"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mail-bulk:before {
  content: "\f674"; }

.fa-mailbox:before {
  content: "\f813"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-mandolin:before {
  content: "\f6f9"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-marker-alt-slash:before {
  content: "\f605"; }

.fa-map-marker-check:before {
  content: "\f606"; }

.fa-map-marker-edit:before {
  content: "\f607"; }

.fa-map-marker-exclamation:before {
  content: "\f608"; }

.fa-map-marker-minus:before {
  content: "\f609"; }

.fa-map-marker-plus:before {
  content: "\f60a"; }

.fa-map-marker-question:before {
  content: "\f60b"; }

.fa-map-marker-slash:before {
  content: "\f60c"; }

.fa-map-marker-smile:before {
  content: "\f60d"; }

.fa-map-marker-times:before {
  content: "\f60e"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mask:before {
  content: "\f6fa"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-meat:before {
  content: "\f814"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaphone:before {
  content: "\f675"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-menorah:before {
  content: "\f676"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-meteor:before {
  content: "\f753"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microscope:before {
  content: "\f610"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-mind-share:before {
  content: "\f677"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-hexagon:before {
  content: "\f307"; }

.fa-minus-octagon:before {
  content: "\f308"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mistletoe:before {
  content: "\f7b4"; }

.fa-mitten:before {
  content: "\f7b5"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-mobile-android:before {
  content: "\f3ce"; }

.fa-mobile-android-alt:before {
  content: "\f3cf"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-monitor-heart-rate:before {
  content: "\f611"; }

.fa-monkey:before {
  content: "\f6fb"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-moon-cloud:before {
  content: "\f754"; }

.fa-moon-stars:before {
  content: "\f755"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-mosque:before {
  content: "\f678"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mountain:before {
  content: "\f6fc"; }

.fa-mountains:before {
  content: "\f6fd"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-mug-hot:before {
  content: "\f7b6"; }

.fa-mug-marshmallows:before {
  content: "\f7b7"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-narwhal:before {
  content: "\f6fe"; }

.fa-neos:before {
  content: "\f612"; }

.fa-network-wired:before {
  content: "\f6ff"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-nintendo-switch:before {
  content: "\f418"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-octagon:before {
  content: "\f306"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-oil-can:before {
  content: "\f613"; }

.fa-oil-temp:before {
  content: "\f614"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-om:before {
  content: "\f679"; }

.fa-omega:before {
  content: "\f67a"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-ornament:before {
  content: "\f7b8"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-otter:before {
  content: "\f700"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-pager:before {
  content: "\f815"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-brush-alt:before {
  content: "\f5a9"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-pallet-alt:before {
  content: "\f483"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-parking:before {
  content: "\f540"; }

.fa-parking-circle:before {
  content: "\f615"; }

.fa-parking-circle-slash:before {
  content: "\f616"; }

.fa-parking-slash:before {
  content: "\f617"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-pastafarianism:before {
  content: "\f67b"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paw-alt:before {
  content: "\f701"; }

.fa-paw-claws:before {
  content: "\f702"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-peace:before {
  content: "\f67c"; }

.fa-pegasus:before {
  content: "\f703"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-paintbrush:before {
  content: "\f618"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-pennant:before {
  content: "\f456"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-pepper-hot:before {
  content: "\f816"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-booth:before {
  content: "\f756"; }

.fa-person-carry:before {
  content: "\f4cf"; }

.fa-person-dolly:before {
  content: "\f4d0"; }

.fa-person-dolly-empty:before {
  content: "\f4d1"; }

.fa-person-sign:before {
  content: "\f757"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-office:before {
  content: "\f67d"; }

.fa-phone-plus:before {
  content: "\f4d2"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-php:before {
  content: "\f457"; }

.fa-pi:before {
  content: "\f67e"; }

.fa-pie:before {
  content: "\f705"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-pig:before {
  content: "\f706"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-pizza:before {
  content: "\f817"; }

.fa-pizza-slice:before {
  content: "\f818"; }

.fa-place-of-worship:before {
  content: "\f67f"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-alt:before {
  content: "\f3de"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-hexagon:before {
  content: "\f300"; }

.fa-plus-octagon:before {
  content: "\f301"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-podium:before {
  content: "\f680"; }

.fa-podium-star:before {
  content: "\f758"; }

.fa-poll:before {
  content: "\f681"; }

.fa-poll-h:before {
  content: "\f682"; }

.fa-poll-people:before {
  content: "\f759"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-poo-storm:before {
  content: "\f75a"; }

.fa-poop:before {
  content: "\f619"; }

.fa-popcorn:before {
  content: "\f819"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-pray:before {
  content: "\f683"; }

.fa-praying-hands:before {
  content: "\f684"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-presentation:before {
  content: "\f685"; }

.fa-print:before {
  content: "\f02f"; }

.fa-print-search:before {
  content: "\f81a"; }

.fa-print-slash:before {
  content: "\f686"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pumpkin:before {
  content: "\f707"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-question-square:before {
  content: "\f2fd"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-quran:before {
  content: "\f687"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-rabbit:before {
  content: "\f708"; }

.fa-rabbit-fast:before {
  content: "\f709"; }

.fa-racquet:before {
  content: "\f45a"; }

.fa-radiation:before {
  content: "\f7b9"; }

.fa-radiation-alt:before {
  content: "\f7ba"; }

.fa-rainbow:before {
  content: "\f75b"; }

.fa-raindrops:before {
  content: "\f75c"; }

.fa-ram:before {
  content: "\f70a"; }

.fa-ramp-loading:before {
  content: "\f4d4"; }

.fa-random:before {
  content: "\f074"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-rectangle-landscape:before {
  content: "\f2fa"; }

.fa-rectangle-portrait:before {
  content: "\f2fb"; }

.fa-rectangle-wide:before {
  content: "\f2fc"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-repeat:before {
  content: "\f363"; }

.fa-repeat-1:before {
  content: "\f365"; }

.fa-repeat-1-alt:before {
  content: "\f366"; }

.fa-repeat-alt:before {
  content: "\f364"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-republican:before {
  content: "\f75e"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-restroom:before {
  content: "\f7bd"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-retweet-alt:before {
  content: "\f361"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-ring:before {
  content: "\f70b"; }

.fa-rings-wedding:before {
  content: "\f81b"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-route-highway:before {
  content: "\f61a"; }

.fa-route-interstate:before {
  content: "\f61b"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-triangle:before {
  content: "\f61c"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-running:before {
  content: "\f70c"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-rv:before {
  content: "\f7be"; }

.fa-sack:before {
  content: "\f81c"; }

.fa-sack-dollar:before {
  content: "\f81d"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-salad:before {
  content: "\f81e"; }

.fa-sandwich:before {
  content: "\f81f"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-satellite:before {
  content: "\f7bf"; }

.fa-satellite-dish:before {
  content: "\f7c0"; }

.fa-sausage:before {
  content: "\f820"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-scalpel:before {
  content: "\f61d"; }

.fa-scalpel-path:before {
  content: "\f61e"; }

.fa-scanner:before {
  content: "\f488"; }

.fa-scanner-keyboard:before {
  content: "\f489"; }

.fa-scanner-touchscreen:before {
  content: "\f48a"; }

.fa-scarecrow:before {
  content: "\f70d"; }

.fa-scarf:before {
  content: "\f7c1"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scroll:before {
  content: "\f70e"; }

.fa-scroll-old:before {
  content: "\f70f"; }

.fa-scrubber:before {
  content: "\f2f8"; }

.fa-scythe:before {
  content: "\f710"; }

.fa-sd-card:before {
  content: "\f7c2"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-dollar:before {
  content: "\f688"; }

.fa-search-location:before {
  content: "\f689"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shapes:before {
  content: "\f61f"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-all:before {
  content: "\f367"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-sheep:before {
  content: "\f711"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield:before {
  content: "\f132"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-shield-check:before {
  content: "\f2f7"; }

.fa-shield-cross:before {
  content: "\f712"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shipping-timed:before {
  content: "\f48c"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shish-kebab:before {
  content: "\f821"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shovel:before {
  content: "\f713"; }

.fa-shovel-snow:before {
  content: "\f7c3"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shredder:before {
  content: "\f68a"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-shuttlecock:before {
  content: "\f45b"; }

.fa-sickle:before {
  content: "\f822"; }

.fa-sigma:before {
  content: "\f68b"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signal-1:before {
  content: "\f68c"; }

.fa-signal-2:before {
  content: "\f68d"; }

.fa-signal-3:before {
  content: "\f68e"; }

.fa-signal-4:before {
  content: "\f68f"; }

.fa-signal-alt:before {
  content: "\f690"; }

.fa-signal-alt-1:before {
  content: "\f691"; }

.fa-signal-alt-2:before {
  content: "\f692"; }

.fa-signal-alt-3:before {
  content: "\f693"; }

.fa-signal-alt-slash:before {
  content: "\f694"; }

.fa-signal-slash:before {
  content: "\f695"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-sim-card:before {
  content: "\f7c4"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skating:before {
  content: "\f7c5"; }

.fa-skeleton:before {
  content: "\f620"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-ski-jump:before {
  content: "\f7c7"; }

.fa-ski-lift:before {
  content: "\f7c8"; }

.fa-skiing:before {
  content: "\f7c9"; }

.fa-skiing-nordic:before {
  content: "\f7ca"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skull-crossbones:before {
  content: "\f714"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-slash:before {
  content: "\f715"; }

.fa-sledding:before {
  content: "\f7cb"; }

.fa-sleigh:before {
  content: "\f7cc"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-sliders-h-square:before {
  content: "\f3f0"; }

.fa-sliders-v:before {
  content: "\f3f1"; }

.fa-sliders-v-square:before {
  content: "\f3f2"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-plus:before {
  content: "\f5b9"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smog:before {
  content: "\f75f"; }

.fa-smoke:before {
  content: "\f760"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-sms:before {
  content: "\f7cd"; }

.fa-snake:before {
  content: "\f716"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snow-blowing:before {
  content: "\f761"; }

.fa-snowboarding:before {
  content: "\f7ce"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-snowflakes:before {
  content: "\f7cf"; }

.fa-snowman:before {
  content: "\f7d0"; }

.fa-snowmobile:before {
  content: "\f7d1"; }

.fa-snowplow:before {
  content: "\f7d2"; }

.fa-socks:before {
  content: "\f696"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-soup:before {
  content: "\f823"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-spade:before {
  content: "\f2f4"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-spider:before {
  content: "\f717"; }

.fa-spider-black-widow:before {
  content: "\f718"; }

.fa-spider-web:before {
  content: "\f719"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-spinner-third:before {
  content: "\f3f4"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-square-root:before {
  content: "\f697"; }

.fa-square-root-alt:before {
  content: "\f698"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-squirrel:before {
  content: "\f71a"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-staff:before {
  content: "\f71b"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-and-crescent:before {
  content: "\f699"; }

.fa-star-christmas:before {
  content: "\f7d4"; }

.fa-star-exclamation:before {
  content: "\f2f3"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-star-of-david:before {
  content: "\f69a"; }

.fa-star-of-life:before {
  content: "\f621"; }

.fa-stars:before {
  content: "\f762"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steak:before {
  content: "\f824"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-steering-wheel:before {
  content: "\f622"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stocking:before {
  content: "\f7d5"; }

.fa-stomach:before {
  content: "\f623"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-stretcher:before {
  content: "\f825"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-sun-cloud:before {
  content: "\f763"; }

.fa-sun-dust:before {
  content: "\f764"; }

.fa-sun-haze:before {
  content: "\f765"; }

.fa-sunrise:before {
  content: "\f766"; }

.fa-sunset:before {
  content: "\f767"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-sword:before {
  content: "\f71c"; }

.fa-swords:before {
  content: "\f71d"; }

.fa-synagogue:before {
  content: "\f69b"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablet-android:before {
  content: "\f3fb"; }

.fa-tablet-android-alt:before {
  content: "\f3fc"; }

.fa-tablet-rugged:before {
  content: "\f48f"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer:before {
  content: "\f0e4"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tachometer-alt-average:before {
  content: "\f624"; }

.fa-tachometer-alt-fast:before {
  content: "\f625"; }

.fa-tachometer-alt-fastest:before {
  content: "\f626"; }

.fa-tachometer-alt-slow:before {
  content: "\f627"; }

.fa-tachometer-alt-slowest:before {
  content: "\f628"; }

.fa-tachometer-average:before {
  content: "\f629"; }

.fa-tachometer-fast:before {
  content: "\f62a"; }

.fa-tachometer-fastest:before {
  content: "\f62b"; }

.fa-tachometer-slow:before {
  content: "\f62c"; }

.fa-tachometer-slowest:before {
  content: "\f62d"; }

.fa-taco:before {
  content: "\f826"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tally:before {
  content: "\f69c"; }

.fa-tanakh:before {
  content: "\f827"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-tasks-alt:before {
  content: "\f828"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-teeth:before {
  content: "\f62e"; }

.fa-teeth-open:before {
  content: "\f62f"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-temperature-frigid:before {
  content: "\f768"; }

.fa-temperature-high:before {
  content: "\f769"; }

.fa-temperature-hot:before {
  content: "\f76a"; }

.fa-temperature-low:before {
  content: "\f76b"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tenge:before {
  content: "\f7d7"; }

.fa-tennis-ball:before {
  content: "\f45e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-theater-masks:before {
  content: "\f630"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-theta:before {
  content: "\f69e"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-thunderstorm:before {
  content: "\f76c"; }

.fa-thunderstorm-moon:before {
  content: "\f76d"; }

.fa-thunderstorm-sun:before {
  content: "\f76e"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-tilde:before {
  content: "\f69f"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-times-hexagon:before {
  content: "\f2ee"; }

.fa-times-octagon:before {
  content: "\f2f0"; }

.fa-times-square:before {
  content: "\f2d3"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tire:before {
  content: "\f631"; }

.fa-tire-flat:before {
  content: "\f632"; }

.fa-tire-pressure-warning:before {
  content: "\f633"; }

.fa-tire-rugged:before {
  content: "\f634"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toilet:before {
  content: "\f7d8"; }

.fa-toilet-paper:before {
  content: "\f71e"; }

.fa-toilet-paper-alt:before {
  content: "\f71f"; }

.fa-tombstone:before {
  content: "\f720"; }

.fa-tombstone-alt:before {
  content: "\f721"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tools:before {
  content: "\f7d9"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-toothbrush:before {
  content: "\f635"; }

.fa-torah:before {
  content: "\f6a0"; }

.fa-torii-gate:before {
  content: "\f6a1"; }

.fa-tornado:before {
  content: "\f76f"; }

.fa-tractor:before {
  content: "\f722"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-traffic-cone:before {
  content: "\f636"; }

.fa-traffic-light:before {
  content: "\f637"; }

.fa-traffic-light-go:before {
  content: "\f638"; }

.fa-traffic-light-slow:before {
  content: "\f639"; }

.fa-traffic-light-stop:before {
  content: "\f63a"; }

.fa-train:before {
  content: "\f238"; }

.fa-tram:before {
  content: "\f7da"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-trash-restore:before {
  content: "\f829"; }

.fa-trash-restore-alt:before {
  content: "\f82a"; }

.fa-treasure-chest:before {
  content: "\f723"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-tree-alt:before {
  content: "\f400"; }

.fa-tree-christmas:before {
  content: "\f7db"; }

.fa-tree-decorated:before {
  content: "\f7dc"; }

.fa-tree-large:before {
  content: "\f7dd"; }

.fa-tree-palm:before {
  content: "\f82b"; }

.fa-trees:before {
  content: "\f724"; }

.fa-trello:before {
  content: "\f181"; }

.fa-triangle:before {
  content: "\f2ec"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-trophy-alt:before {
  content: "\f2eb"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-container:before {
  content: "\f4dc"; }

.fa-truck-couch:before {
  content: "\f4dd"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-monster:before {
  content: "\f63b"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-pickup:before {
  content: "\f63c"; }

.fa-truck-plow:before {
  content: "\f7de"; }

.fa-truck-ramp:before {
  content: "\f4e0"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-turkey:before {
  content: "\f725"; }

.fa-turtle:before {
  content: "\f726"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-tv-retro:before {
  content: "\f401"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-unicorn:before {
  content: "\f727"; }

.fa-union:before {
  content: "\f6a2"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usd-circle:before {
  content: "\f2e8"; }

.fa-usd-square:before {
  content: "\f2e9"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-chart:before {
  content: "\f6a3"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-crown:before {
  content: "\f6a4"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-hard-hat:before {
  content: "\f82c"; }

.fa-user-headset:before {
  content: "\f82d"; }

.fa-user-injured:before {
  content: "\f728"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-md-chat:before {
  content: "\f82e"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-nurse:before {
  content: "\f82f"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-class:before {
  content: "\f63d"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-users-crown:before {
  content: "\f6a5"; }

.fa-users-medical:before {
  content: "\f830"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-fork:before {
  content: "\f2e3"; }

.fa-utensil-knife:before {
  content: "\f2e4"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-utensils-alt:before {
  content: "\f2e6"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-value-absolute:before {
  content: "\f6a6"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-plus:before {
  content: "\f4e1"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vihara:before {
  content: "\f6a7"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-volcano:before {
  content: "\f770"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume:before {
  content: "\f6a8"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f6a9"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-slash:before {
  content: "\f2e2"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vote-nay:before {
  content: "\f771"; }

.fa-vote-yea:before {
  content: "\f772"; }

.fa-vr-cardboard:before {
  content: "\f729"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walker:before {
  content: "\f831"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-wand:before {
  content: "\f72a"; }

.fa-wand-magic:before {
  content: "\f72b"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-warehouse-alt:before {
  content: "\f495"; }

.fa-watch:before {
  content: "\f2e1"; }

.fa-watch-fitness:before {
  content: "\f63e"; }

.fa-water:before {
  content: "\f773"; }

.fa-water-lower:before {
  content: "\f774"; }

.fa-water-rise:before {
  content: "\f775"; }

.fa-webcam:before {
  content: "\f832"; }

.fa-webcam-slash:before {
  content: "\f833"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whale:before {
  content: "\f72c"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheat:before {
  content: "\f72d"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whistle:before {
  content: "\f460"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wifi-1:before {
  content: "\f6aa"; }

.fa-wifi-2:before {
  content: "\f6ab"; }

.fa-wifi-slash:before {
  content: "\f6ac"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-wind:before {
  content: "\f72e"; }

.fa-wind-warning:before {
  content: "\f776"; }

.fa-window:before {
  content: "\f40e"; }

.fa-window-alt:before {
  content: "\f40f"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-windsock:before {
  content: "\f777"; }

.fa-wine-bottle:before {
  content: "\f72f"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-wreath:before {
  content: "\f7e2"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yin-yang:before {
  content: "\f6ad"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/webfonts/fa-regular-400.eot");
  src: url("../fonts/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/webfonts/fa-regular-400.woff2") format("woff2"), url("../fonts/webfonts/fa-regular-400.woff") format("woff"), url("../fonts/webfonts/fa-regular-400.ttf") format("truetype"), url("../fonts/webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/webfonts/fa-solid-900.eot");
  src: url("../fonts/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/webfonts/fa-solid-900.woff2") format("woff2"), url("../fonts/webfonts/fa-solid-900.woff") format("woff"), url("../fonts/webfonts/fa-solid-900.ttf") format("truetype"), url("../fonts/webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../fonts/webfonts/fa-light-300.eot");
  src: url("../fonts/webfonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/webfonts/fa-light-300.woff2") format("woff2"), url("../fonts/webfonts/fa-light-300.woff") format("woff"), url("../fonts/webfonts/fa-light-300.ttf") format("truetype"), url("../fonts/webfonts/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/webfonts/fa-brands-400.eot");
  src: url("../fonts/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/webfonts/fa-brands-400.woff2") format("woff2"), url("../fonts/webfonts/fa-brands-400.woff") format("woff"), url("../fonts/webfonts/fa-brands-400.ttf") format("truetype"), url("../fonts/webfonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0079C0;
  --secondary: #2A2A2A;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Rock-SansNormal";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0079C0;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #005283;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d9ed; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ab9de; }

.table-hover .table-primary:hover {
  background-color: #a4cee8; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a4cee8; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c3c3c3; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #909090; }

.table-hover .table-secondary:hover {
  background-color: #b6b6b6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b6b6b6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #41b9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0079C0;
  border-color: #0079C0; }
  .btn-primary:hover {
    color: #fff;
    background-color: #00619a;
    border-color: #00598d; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #00619a;
    border-color: #00598d;
    box-shadow: 0 0 0 0.2rem rgba(38, 141, 201, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00598d;
    border-color: #005180; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 141, 201, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #2A2A2A;
  border-color: #2A2A2A; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #171717;
    border-color: #111111; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #171717;
    border-color: #111111;
    box-shadow: 0 0 0 0.2rem rgba(74, 74, 74, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #2A2A2A;
    border-color: #2A2A2A; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #111111;
    border-color: #0a0a0a; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(74, 74, 74, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #0079C0;
  border-color: #0079C0; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0079C0;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }

.btn-outline-secondary {
  color: #2A2A2A;
  border-color: #2A2A2A; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #2A2A2A;
    border-color: #2A2A2A; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #2A2A2A;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #2A2A2A;
    border-color: #2A2A2A; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: normal;
  color: #0079C0;
  text-decoration: none; }
  .btn-link:hover {
    color: #005283;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0079C0; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0079C0;
    background-color: #0079C0; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #41b9ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #74cbff;
    border-color: #74cbff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0079C0;
  background-color: #0079C0; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 121, 192, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 121, 192, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 121, 192, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 121, 192, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #41b9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #41b9ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0079C0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #74cbff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0079C0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #74cbff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0079C0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #74cbff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0079C0; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0079C0;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #005283;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0079C0;
  border-color: #0079C0; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #0079C0; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #00598d; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #2A2A2A; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #111111; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #003f64;
  background-color: #cce4f2;
  border-color: #b8d9ed; }
  .alert-primary hr {
    border-top-color: #a4cee8; }
  .alert-primary .alert-link {
    color: #001f31; }

.alert-secondary {
  color: #161616;
  background-color: #d4d4d4;
  border-color: #c3c3c3; }
  .alert-secondary hr {
    border-top-color: #b6b6b6; }
  .alert-secondary .alert-link {
    color: black; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0079C0;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #003f64;
  background-color: #b8d9ed; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #003f64;
    background-color: #a4cee8; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #003f64;
    border-color: #003f64; }

.list-group-item-secondary {
  color: #161616;
  background-color: #c3c3c3; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #161616;
    background-color: #b6b6b6; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #161616;
    border-color: #161616; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Rock-SansNormal";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Rock-SansNormal";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0079C0 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00598d !important; }

.bg-secondary {
  background-color: #2A2A2A !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #111111 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0079C0 !important; }

.border-secondary {
  border-color: #2A2A2A !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-bold {
  font-weight: bold !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0079C0 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #004974 !important; }

.text-secondary {
  color: #2A2A2A !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #040404 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/**
 * @file
 * Styles for Bootstrap Barrio affix effect.
 */
.affix {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1035;
  left: 0; }

/**
 * @file
 * Visual styles for comments in Barrio.
 */
.alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999; }

/**
 * @file
 * Bootstrap Barrio specific styling for the Book module.
 */
.book-navigation .menu {
  border-top: 1px solid #dee2e6; }

.book-navigation .book-pager {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  margin: 0; }

/**
 * @file
 * Styling for the Book module.
 */
.book-navigation .menu {
  padding-bottom: 0;
  padding-top: 1em; }

.book-navigation .book-pager {
  margin: 0;
  overflow: auto;
  padding: 0.5em 0; }

.book-pager__item {
  display: inline-block;
  list-style-type: none;
  vertical-align: top; }

.book-pager__item--previous {
  text-align: left;
  /* LTR */
  width: 45%; }

[dir="rtl"] .book-pager__item--previous {
  float: right;
  text-align: right; }

.book-pager__item--center {
  text-align: center;
  width: 8%; }

.book-pager__item--next {
  float: right;
  /* LTR */
  text-align: right;
  /* LTR */
  width: 45%; }

[dir="rtl"] .book-pager__item--next {
  float: left;
  text-align: left; }

/**
 * @file
 * Styles for Bootstrap Barrio's breadcrumbs.
 */
.breadcrumb {
  font-size: 0.929em 0.875rem; }

.region-breadcrumb {
  padding: 0 15px 0.25em;
  width: 100%; }

/**
 * @file
 * Visual styles for comments in Bartik.
 */
/* This is required to win over specificity of #content h2 */
#content .comment-wrapper h2 {
  margin-bottom: 1em; }

#content .comment-wrapper h2.comment-form__title {
  margin-bottom: 1em; }

.field-node--comment {
  font-size: 0.875rem; }

.comment {
  margin-bottom: 1.25em;
  vertical-align: top;
  display: table; }

[dir="rtl"] .comment {
  direction: rtl; }

.comment__meta {
  padding: 0 30px 0 0;
  /* LTR */
  font-size: 1rem; }

[dir="rtl"] .comment__meta {
  padding: 0 0 0 30px; }

.comment__attribution img {
  border: 1px solid #dee2e6; }

/* This is required to win over specificity of .field--type-image img */
.comment .field--name-user-picture img {
  margin: 0; }

.comment__author .username {
  white-space: nowrap; }

.comment__author {
  margin: 4px 0;
  line-height: 1.2; }

.comment__time,
.comment__permalink {
  margin-bottom: 4px;
  color: #68696b;
  font-size: 0.875rem;
  line-height: 1.2;
  padding: 0 0.5em 0 0;
  /* LTR */ }

[dir="rtl"] .comment__time,
.comment__permalink {
  padding: 0 0 0 0.5em; }

.comment__permalink {
  font-size: 0.875rem;
  line-height: 1.2; }

.comment__content:before {
  content: '';
  position: absolute;
  right: 100%;
  /* LTR */
  top: 1.25em;
  border-top: 1.25em solid transparent;
  border-right: 1.25em solid #dee2e6;
  /* LTR */
  border-bottom: 1.25em solid transparent; }

[dir="rtl"] .comment__content:before {
  right: auto;
  left: 100%;
  border-right: none;
  border-left: 1.25em solid #dee2e6; }

.comment__content:after {
  content: '';
  position: absolute;
  right: 100%;
  /* LTR */
  top: 1.25em;
  border-top: 1.25em solid transparent;
  border-right: 1.25em solid #fff;
  /* LTR */
  border-bottom: 1.25em solid transparent;
  margin-right: -1px;
  /* LTR */ }

[dir="rtl"] .comment__content:after {
  right: auto;
  left: 100%;
  border-right: none;
  border-left: 1.25em solid #fff;
  margin-right: 0;
  margin-left: -1px; }

.comment__content h3 {
  margin-top: 0.94em;
  margin-bottom: 0.45em;
  font-size: 1.25rem; }

.comment__content nav {
  padding-top: 1px; }

.indented {
  margin-left: 40px;
  /* LTR */ }

[dir="rtl"] .indented {
  margin-right: 40px;
  margin-left: 0; }

.comment .links {
  padding: 0 0 0.25em 0; }

.comment .links-inline span {
  padding: 0 0.5em 0 0;
  /* LTR */
  font-size: 1rem; }

[dir="rtl"] .comment .links-inline span {
  padding: 0 0 0 0.5em; }

.comment--unpublished {
  margin-right: 5px;
  /* LTR */
  padding: 5px 2px 5px 5px;
  /* LTR */
  background: #7ecfff; }

[dir="rtl"] .comment--unpublished {
  margin-left: 5px;
  margin-right: 0;
  padding: 5px 5px 5px 2px; }

/**
 * @todo: unpublished nodes have class .node--unpublished.
 * change this to .comment--unpublished.
 */
.unpublished .comment-text .comment-arrow {
  border-left: 1px solid #7ecfff;
  border-right: 1px solid #7ecfff; }

.unpublished {
  padding: 1.25em 1.25em 0; }

.comment-footer {
  display: table-row; }

.comment--unpublished .comment__text:after,
.node--unpublished .comment__text:after {
  border-right-color: #7ecfff;
  /* LTR */ }

[dir="rtl"] .comment--unpublished .comment__content:after,
[dir="rtl"] .node--unpublished .comment__content:after {
  border-left-color: #7ecfff; }

/**
 * @file
 * Contextual links styles for Bootstrap Barrio.
 */
.contextual-links a {
  border-bottom: none;
  text-shadow: 0 0 0; }

/**
 * @file
 * Collapsible details.
 *
 * @see collapse.js
 * @see http://nicolasgallagher.com/css-background-image-hacks/
 */
details {
  border: 1px solid #dee2e6;
  margin-top: 1rem;
  margin-bottom: 1rem; }

details > .details-wrapper {
  padding: 0.5em 1.5em; }

/* @todo Regression: The summary of uncollapsible details are no longer
     vertically aligned with the .details-wrapper in browsers without native
     details support. */
summary {
  cursor: pointer;
  padding: 0.2em 0.5em; }

/**
 * @file
 * Visual styles for Bartik's dropbutton component.
 */
.js .dropbutton-wrapper .dropbutton-widget {
  /* This is required to win over specifity of .js td .dropbutton-widget */
  position: relative; }

.js .dropbutton-widget {
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4 #d2d2d2;
  background-color: #fff;
  background-image: -webkit-linear-gradient(top, #f3f3f3, #e8e8e8);
  background-image: linear-gradient(to bottom, #f3f3f3, #e8e8e8);
  color: #3a3a3a;
  cursor: pointer;
  text-align: center;
  margin: 0.125em 0;
  border-radius: 1em;
  overflow: hidden; }

.js .dropbutton-widget:hover {
  border-color: #e4e4e4 #d2d2d2 #b4b4b4 #d2d2d2; }

.js .dropbutton-widget .button {
  border: none;
  margin: 0;
  padding: 0.32em 1em;
  background: transparent none; }

.js .dropbutton-multiple .dropbutton-widget .dropbutton-action a {
  margin-right: 0;
  /* LTR */ }

[dir="rtl"].js .dropbutton-multiple .dropbutton-widget .dropbutton-action a {
  margin-left: 0; }

.js .dropbutton .secondary-action {
  border-top-color: #ccc; }

.js .dropbutton-toggle button {
  background-color: #e8e8e8;
  background-image: -webkit-linear-gradient(top, #e8e8e8, #d2d2d2);
  background-image: linear-gradient(to bottom, #e8e8e8, #d2d2d2); }

.js .dropbutton-toggle .dropbutton-arrow:hover {
  background: #ccc; }

.js .dropbutton a {
  color: #3a3a3a;
  border-bottom: 0 none; }

.js .dropbutton .dropbutton-action:hover,
.js .dropbutton a:hover {
  background: #dedede;
  border-bottom: 0 none; }

/**
 * @file
 * General styles for dropbuttons.
 */
.js .dropbutton-widget {
  background-color: white;
  border: 1px solid #cccccc; }

.js .dropbutton-widget:hover {
  border-color: #b8b8b8; }

.dropbutton .dropbutton-action > * {
  padding: 0.1em 0.5em;
  white-space: nowrap; }

.dropbutton .secondary-action {
  border-top: 1px solid #e8e8e8; }

.dropbutton-multiple .dropbutton {
  border-right: 1px solid #e8e8e8;
  /* LTR */ }

[dir="rtl"] .dropbutton-multiple .dropbutton {
  border-left: 1px solid #e8e8e8;
  border-right: 0 none; }

.dropbutton-multiple .dropbutton .dropbutton-action > * {
  margin-right: 0.25em;
  /* LTR */ }

[dir="rtl"] .dropbutton-multiple .dropbutton .dropbutton-action > * {
  margin-left: 0.25em;
  margin-right: 0; }

.js td .dropbutton-multiple {
  padding-right: 10em;
  margin-right: 2em;
  max-width: 100%; }

.js td .dropbutton-wrapper {
  min-height: 2em; }

.js .dropbutton-wrapper,
.js .dropbutton-widget {
  display: block;
  position: relative; }

.js td .dropbutton-widget {
  position: absolute; }

/**
 * @file
 * Feed icon styles for Bootstrap Barrio.
 */
.feed-icon {
  border-bottom: none;
  display: inline-block;
  padding: 1rem 0 0 0; }

/**
 * @file
 * Visual styles for Bootstrap Barrio's field components.
 */
.field--type-entity-reference {
  margin: 0 0 1.2rem; }

.field--type-entity-reference .field__label {
  font-weight: normal;
  margin: 0;
  padding-right: 0.3rem;
  /* LTR */ }

[dir="rtl"] .field--type-entity-reference .field__label {
  padding-left: 0.3rem;
  padding-right: 0; }

.field--type-entity-reference ul.links {
  padding: 0;
  margin: 0;
  list-style: none; }

.field--type-entity-reference ul.links li {
  float: left;
  /* LTR */
  padding: 0 1rem 0 0;
  /* LTR */
  white-space: nowrap; }

[dir="rtl"] .field--type-entity-reference ul.links li {
  padding: 0 0 0 1rem;
  float: right; }

@media all and (min-width: 560px) {
  .node .field--type-image {
    float: left;
    /* LTR */
    margin: 0 1rem 0 0;
    /* LTR */ }
  [dir="rtl"] .node .field--type-image {
    float: right;
    margin: 0 0 0 1rem; }
  .node .field--type-image + .field--type-image {
    clear: both; } }

.field--type-image img,
.field--name-field-user-picture img {
  margin: 0 0 1rem; }

.field--type-image a {
  border-bottom: none; }

.field--name-field-tags {
  margin: 0 0 1.2rem; }

.field--name-field-tags .field__label {
  font-weight: normal;
  margin: 0;
  padding-right: 5px;
  /* LTR */ }

[dir="rtl"] .field--name-field-tags .field__label {
  padding-left: 5px;
  padding-right: 0; }

.field--name-field-tags .field__label,
.field--name-field-tags ul.links {
  font-size: 0.875rem; }

.node--view-mode-teaser .field--name-field-tags .field__label,
.node--view-mode-teaser .field--name-field-tags ul.links {
  font-size: 0.875rem; }

.field--name-field-tags ul.links {
  padding: 0;
  margin: 0; }

.field--name-field-tags ul.links li {
  float: left;
  /* LTR */
  padding: 0 1rem 0 0;
  /* LTR */
  white-space: nowrap; }

[dir="rtl"] .field--name-field-tags ul.links li {
  padding: 0 0 0 1rem;
  float: right; }

.field--label-inline .field__label,
.field--label-inline .field__item {
  display: inline-block; }

/**
 * @file
 * Default style for file module.
 */
/* File icons.*/
.file {
  padding-left: 1.5rem;
  /* LTR */
  display: inline-block;
  min-height: 1rem;
  background-repeat: no-repeat;
  background-position: left center;
  /* LTR */ }

[dir="rtl"] .file {
  padding-left: inherit;
  padding-right: 1.5rem;
  background-position: right center; }

.file--general,
.file--application-octet-stream {
  background-image: url(../images/icons/application-octet-stream.png); }

.file--package-x-generic {
  background-image: url(../images/icons/package-x-generic.png); }

.file--x-office-spreadsheet {
  background-image: url(../images/icons/x-office-spreadsheet.png); }

.file--x-office-document {
  background-image: url(../images/icons/x-office-document.png); }

.file--x-office-presentation {
  background-image: url(../images/icons/x-office-presentation.png); }

.file--text-x-script {
  background-image: url(../images/icons/text-x-script.png); }

.file--text-html {
  background-image: url(../images/icons/text-html.png); }

.file--text-plain {
  background-image: url(../images/icons/text-plain.png); }

.file--application-pdf {
  background-image: url(../images/icons/application-pdf.png); }

.file--application-x-executable {
  background-image: url(../images/icons/application-x-executable.png); }

.file--audio {
  background-image: url(../images/icons/audio-x-generic.png); }

.file--video {
  background-image: url(../images/icons/video-x-generic.png); }

.file--text {
  background-image: url(../images/icons/text-x-generic.png); }

.file--image {
  background-image: url(../images/icons/image-x-generic.png); }

/**
 * @file
 * Visual styles for Bootstrap Barrio's form components.
 */
.form-required::after {
  content: '';
  vertical-align: super;
  display: inline-block;
  background-image: url(../../images/required.svg);
  background-repeat: no-repeat;
  background-size: calc(1.5em + 0.75rem + 2px)/2 calc(1.5em + 0.75rem + 2px)/2;
  width: calc(1.5em + 0.75rem + 2px)/2;
  height: calc(1.5em + 0.75rem + 2px)/2;
  margin: 0 0.3em; }

form .filter-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem; }

form .form-type-textarea {
  margin-bottom: 0; }

/* ------------------ Header ------------------ */
#navbar-top {
  padding: 0 1rem; }

#navbar-top.navbar-dark .nav-link:hover,
#navbar-top.navbar-dark .nav-link:focus {
  color: rgba(255, 255, 255, 0.75); }

#navbar-top.navbar-light .nav-link:hover,
#navbar-top.navbar-light .nav-link:focus {
  color: rgba(0, 0, 0, 0.7); }

#navbar-top.navbar-light .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-brand {
  margin-right: 0;
  padding-left: 40px !important;
  padding-top: 0.5125rem; }
  @media (max-width: 1199.98px) {
    .navbar-brand {
      padding-left: 20px !important; } }
  .navbar-brand img {
    width: 250px; }
    @media (max-width: 1199.98px) {
      .navbar-brand img {
        width: 220px; } }

@media (max-width: 1199.98px) {
  .site-footer .navbar-brand {
    padding-left: 0px !important; } }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

#block-findajob {
  font-size: 18px;
  padding: 30px; }
  #block-findajob a {
    text-decoration: none; }
  @media (min-width: 992px) {
    #block-findajob {
      cursor: pointer;
      background-color: #400095;
      margin-left: 20px;
      max-width: 185px;
      width: 21%;
      height: 75px;
      padding: 0; }
      #block-findajob:hover {
        background-color: #4E535A; }
      #block-findajob p {
        margin-bottom: 0px;
        text-align: center; }
        #block-findajob p a {
          padding: 22px;
          display: block;
          color: #FFFFFF;
          text-decoration: none; } }

#navbar-main {
  padding: 0px;
  background-color: #FFFFFF !important; }
  @media (max-width: 991.98px) {
    #navbar-main {
      height: 70px !important; } }
  #navbar-main #CollapsingNavbar #block-nbcucareers-main-menu {
    margin-left: auto;
    width: 82%; }
    @media (max-width: 1199.98px) {
      #navbar-main #CollapsingNavbar #block-nbcucareers-main-menu {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px; } }
    @media (max-width: 991.98px) {
      #navbar-main #CollapsingNavbar #block-nbcucareers-main-menu {
        background-image: url(../images/Typography_Art_Gray.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right bottom;
        overflow: scroll; } }
    #navbar-main #CollapsingNavbar #block-nbcucareers-main-menu ul .nav-link {
      position: relative;
      display: inline-block;
      font-size: 17px;
      padding: 0px;
      margin: 0.5rem 0rem;
      color: #2A2A2A;
      /*border-bottom: 2px solid transparent;*/ }
      @media (max-width: 991.98px) {
        #navbar-main #CollapsingNavbar #block-nbcucareers-main-menu ul .nav-link {
          font-size: 20px;
          font-family: "sharpSans-Semibold";
          margin: 0px;
          padding: 10px 0px;
          width: 100%; } }
  #navbar-main #CollapsingNavbar a .fa-times, #navbar-main #CollapsingNavbar span.fa-times, #navbar-main #CollapsingNavbar .mobileSocialLink {
    display: none; }
  @media (max-width: 991.98px) {
    #navbar-main {
      height: 50px; }
      #navbar-main a.navbar-brand {
        z-index: 100;
        padding-top: 25px;
        padding-left: 15px; } }
    @media (max-width: 991.98px) and (max-width: 991.98px) {
      #navbar-main a.navbar-brand {
        padding-top: 13px; } }
  @media (max-width: 991.98px) {
      #navbar-main #CollapsingNavbar {
        z-index: 10;
        /* to position it in front of the other content */
        position: fixed;
        overflow: hidden;
        /* to prevent scrollbar appearing */
        left: 0;
        top: 0px;
        width: 100%;
        z-index: 0;
        height: 100%;
        min-height: 0px;
        max-height: 0%;
        visibility: visible; }
        #navbar-main #CollapsingNavbar a .fa-times, #navbar-main #CollapsingNavbar span.fa-times {
          float: right;
          display: block;
          position: fixed;
          top: 10px;
          right: 5px;
          z-index: 1500;
          font-size: 32px;
          padding: 10px 10px;
          height: 40px;
          line-height: 20px; }
        #navbar-main #CollapsingNavbar .mobileSocialLink {
          padding-bottom: 20px;
          padding-top: 25px;
          display: block; }
          #navbar-main #CollapsingNavbar .mobileSocialLink ul li {
            margin-right: 15px; }
            #navbar-main #CollapsingNavbar .mobileSocialLink ul li a {
              display: inline !important; }
          #navbar-main #CollapsingNavbar .mobileSocialLink ul li:first-child {
            margin-left: 15px; }
      #navbar-main .navigationParent {
        position: fixed;
        top: 0px;
        border: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        padding-right: 20px;
        -webkit-transform: translate3d(-100vw, 0, 0);
        -ms-transform: translate3d(-100vw, 0, 0);
        transform: translate3d(-100vw, 0, 0);
        -webkit-transition: 700ms cubic-bezier(0.23, 1, 0.32, 1);
        transition: 700ms cubic-bezier(0.23, 1, 0.32, 1);
        background: #0079C0; }
      #navbar-main .navigation {
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0px;
        right: 0px;
        width: 98% !important;
        z-index: 100;
        padding: 20px;
        padding-top: 10px;
        background: #EEEEEE;
        -webkit-transform: translate3d(-100vw, 0, 0);
        -ms-transform: translate3d(-100vw, 0, 0);
        transform: translate3d(-100vw, 0, 0);
        -webkit-transition: all cubic-bezier(0.23, 1, 0.32, 1);
        transition: all cubic-bezier(0.23, 1, 0.32, 1);
        -webkit-transition-delay: 200ms;
        transition-delay: 200ms;
        -webkit-transition-duration: 800ms;
        transition-duration: 800ms; }
      #navbar-main #CollapsingNavbar .navigation, #navbar-main .navigationParent {
        transform: translateX(100%);
        -webkit-transform: translateX(100%); }
      #navbar-main #CollapsingNavbar.show .navigation {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: 600ms cubic-bezier(0.23, 1, 0.32, 1);
        transition: 600ms cubic-bezier(0.23, 1, 0.32, 1);
        -webkit-transition-delay: 300ms;
        transition-delay: 300ms;
        padding-top: 10px; }
      #navbar-main #CollapsingNavbar.show .navigationParent {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: all cubic-bezier(0.23, 1, 0.32, 1);
        transition: all cubic-bezier(0.23, 1, 0.32, 1);
        -webkit-transition-delay: 100ms;
        transition-delay: 100ms;
        -webkit-transition-duration: 800ms;
        transition-duration: 800ms; }
      #navbar-main #block-findajob {
        display: none; }
      #navbar-main .bg-img-text-menu {
        z-index: 4;
        position: relative;
        width: 100%; }
      #navbar-main .cl-effect-11 {
        margin-top: 0px; } }

.intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.desktop {
  height: auto; }

.intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.mobile {
  height: 300px; }

.featured-top {
  min-height: 675px; }
  @media (max-width: 1199.98px) {
    .featured-top {
      min-height: 475px; } }
  @media (max-width: 991.98px) {
    .featured-top {
      min-height: 375px; } }
  @media (max-width: 767.98px) {
    .featured-top {
      min-height: 200px; } }
  .featured-top.show-only-for-mobile {
    display: none; }
    @media (max-width: 991.98px) {
      .featured-top.show-only-for-mobile {
        display: block;
        min-height: auto; } }
  .featured-top .block-fieldblock {
    width: 100%; }
  .featured-top .field--name-field-banner-image {
    position: relative; }
    .featured-top .field--name-field-banner-image.desktop {
      display: block;
      height: 775px; }
    .featured-top .field--name-field-banner-image.desktop_image_display {
      display: none; }
    @media (prefers-reduced-motion) {
      .featured-top .field--name-field-banner-image.desktop_image_display {
        display: block; }
      .featured-top .field--name-field-banner-image.desktop_video_display {
        display: none; } }
    .featured-top .field--name-field-banner-image.mobile {
      display: none; }
    @media (max-width: 991.98px) {
      .featured-top .field--name-field-banner-image.desktop {
        display: none; }
      .featured-top .field--name-field-banner-image.mobile {
        display: block;
        height: auto;
        background-size: cover;
        background-position: center; }
      .featured-top .field--name-field-banner-image.mobile_img {
        height: 300px; }
      .featured-top .field--name-field-banner-image.mobile_image_display {
        display: none; } }

@media (max-width: 991.98px) and (prefers-reduced-motion) {
  .featured-top .field--name-field-banner-image.mobile_image_display {
    display: block; }
  .featured-top .field--name-field-banner-image.mobile_video_display {
    display: none; } }
    .featured-top .field--name-field-banner-image img {
      /*max-height: 675px;*/
      max-width: 100%;
      height: auto;
      height: 100%;
      width: 100%;
      min-height: 675px; }
      @media (max-width: 1199.98px) {
        .featured-top .field--name-field-banner-image img {
          min-height: 475px; } }
      @media (max-width: 991.98px) {
        .featured-top .field--name-field-banner-image img {
          min-height: 375px; } }
      @media (max-width: 767.98px) {
        .featured-top .field--name-field-banner-image img {
          min-height: 235px; } }
    .featured-top .field--name-field-banner-image .video-js {
      width: 100%;
      height: 100%; }
    .featured-top .field--name-field-banner-image.desktop video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%; }
    .featured-top .field--name-field-banner-image.mobile video {
      position: relative; }
  .featured-top .field--name-field-banner-image:after {
    content: " ";
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: url(../images/Hero_gradiant_wht4.png) no-repeat;
    background-repeat: repeat-y;
    /*background-size: 75%;
	  opacity:0.85;*/
    background-size: 100%; }
  .featured-top .banner-content-wrapper-top {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%; }
    .featured-top .banner-content-wrapper-top .banner-content-wrapper {
      max-width: 1240px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding-top: 40px;
      padding-left: 30px;
      padding-bottom: 1px; }
      @media (min-width: 1200px) {
        .featured-top .banner-content-wrapper-top .banner-content-wrapper {
          padding-top: 130px;
          padding-left: 0px;
          padding-bottom: 0px; } }
  .featured-top .search-form-wrapper {
    width: 46%;
    padding-top: 35px;
    padding-left: 0px;
    font-size: 20px; }
    .featured-top .search-form-wrapper .fa-search, .featured-top .search-form-wrapper .fa-arrow-right {
      display: none;
      cursor: pointer; }
    .featured-top .search-form-wrapper .input-search {
      width: 100%;
      height: 50px;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      border-bottom: 2px solid #a7a9ac;
      padding-left: 50px;
      outline: none;
      color: #2A2A2A; }
    .featured-top .search-form-wrapper .input-search::-ms-clear {
      display: none; }
    .featured-top .search-form-wrapper .input-search::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #2A2A2A;
      opacity: 1;
      /* Firefox */ }
    .featured-top .search-form-wrapper .input-search:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #2A2A2A; }
    .featured-top .search-form-wrapper .input-search::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #2A2A2A; }
  .featured-top .field--name-field-banner-content .field--name-field-banner-info-text, .featured-top .field--name-field-banner-content .field--name-field-banner-text, .featured-top .field--name-field-banner-content-para .field--name-field-banner-info-text, .featured-top .field--name-field-banner-content-para .field--name-field-banner-text {
    width: 46%;
    /*position: absolute;
			top: 13%;
			padding-left: 100px;*/
    color: #FFFFFF; }
  @media (min-width: 768px) {
    .featured-top .field--name-field-banner-logo-small {
      position: absolute;
      bottom: 50px;
      z-index: 99; } }
  @media (min-width: 1200px) {
    .featured-top .field--name-field-banner-logo-small {
      position: absolute;
      bottom: 57px;
      z-index: 99; } }
  .featured-top .field--name-field-banner-logo-small img {
    max-width: 200px !important;
    height: 60px;
    margin-bottom: 0px; }
  .featured-top .field--name-field-banner-info-text, .featured-top .field--name-field-banner-text {
    z-index: 99; }
    .featured-top .field--name-field-banner-info-text h2, .featured-top .field--name-field-banner-info-text .h2, .featured-top .field--name-field-banner-text h2, .featured-top .field--name-field-banner-text .h2 {
      font-size: 90px;
      line-height: 1;
      font-family: "sharpSans-Bold";
      margin-bottom: 0px; }
      @media (max-width: 1199.98px) {
        .featured-top .field--name-field-banner-info-text h2, .featured-top .field--name-field-banner-info-text .h2, .featured-top .field--name-field-banner-text h2, .featured-top .field--name-field-banner-text .h2 {
          font-size: 70px; } }
      @media (max-width: 767.98px) {
        .featured-top .field--name-field-banner-info-text h2, .featured-top .field--name-field-banner-info-text .h2, .featured-top .field--name-field-banner-text h2, .featured-top .field--name-field-banner-text .h2 {
          font-size: 50px;
          line-height: 53px; } }
    .featured-top .field--name-field-banner-info-text .cd-intro .cd-words-wrapper, .featured-top .field--name-field-banner-text .cd-intro .cd-words-wrapper {
      width: 100% !important;
      padding-bottom: 20px;
      color: #fff; }
      .featured-top .field--name-field-banner-info-text .cd-intro .cd-words-wrapper b, .featured-top .field--name-field-banner-text .cd-intro .cd-words-wrapper b {
        font-family: "sharpSans-Light";
        font-size: 90px;
        font-weight: normal;
        white-space: pre-wrap;
        line-height: 1; }
        @media (max-width: 1199.98px) {
          .featured-top .field--name-field-banner-info-text .cd-intro .cd-words-wrapper b, .featured-top .field--name-field-banner-text .cd-intro .cd-words-wrapper b {
            font-size: 70px; } }
        @media (max-width: 767.98px) {
          .featured-top .field--name-field-banner-info-text .cd-intro .cd-words-wrapper b, .featured-top .field--name-field-banner-text .cd-intro .cd-words-wrapper b {
            font-size: 50px;
            line-height: 53px; } }
    .featured-top .field--name-field-banner-info-text h3, .featured-top .field--name-field-banner-info-text .h3, .featured-top .field--name-field-banner-text h3, .featured-top .field--name-field-banner-text .h3 {
      font-size: 1.625rem;
      font-family: "sharpSans-Medium"; }
      @media (max-width: 1199.98px) {
        .featured-top .field--name-field-banner-info-text h3, .featured-top .field--name-field-banner-info-text .h3, .featured-top .field--name-field-banner-text h3, .featured-top .field--name-field-banner-text .h3 {
          font-size: 1.625rem; } }
    .featured-top .field--name-field-banner-info-text .text-heading-3, .featured-top .field--name-field-banner-text .text-heading-3 {
      line-height: 36px; }
      @media (max-width: 991.98px) {
        .featured-top .field--name-field-banner-info-text .text-heading-3, .featured-top .field--name-field-banner-text .text-heading-3 {
          margin-bottom: 25px !important; } }
  .featured-top .search-form {
    position: relative; }
    .featured-top .search-form .fa-search {
      display: block;
      position: absolute;
      left: 15px;
      top: 16px;
      width: 20px;
      height: 20px;
      font-size: 21px;
      color: #000; }
    .featured-top .search-form .fa-arrow-right {
      display: block;
      position: absolute;
      right: 15px;
      top: 16px;
      width: 20px;
      height: 20px;
      font-size: 21px;
      color: #000; }
  .featured-top .field-collection-item--name-field-banner-content .content {
    position: relative; }
  @media (max-width: 991.98px) {
    .featured-top .field-collection-item--name-field-banner-content .content {
      position: relative;
      border-top: 1px solid #ccc; }
      .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top {
        position: static;
        z-index: 99;
        width: 100%;
        background: #16181A;
        border-top: 1px solid #ccc; }
        .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-info-text {
          width: 100%;
          margin-top: 30px;
          padding-left: 35px; } }
      @media (max-width: 991.98px) and (max-width: 991.98px) {
        .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-info-text {
          padding-left: 0px; } }
  @media (max-width: 991.98px) {
          .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-info-text h1, .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-info-text h2, .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-info-text h3 {
            margin-bottom: 0rem; }
          .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-info-text .cd-headline .cd-words-wrapper {
            width: 100% !important; }
        .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .search-form-wrapper {
          width: 100%;
          padding-top: 0px;
          position: absolute;
          top: 0px;
          margin-left: -30px; }
        .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
          position: absolute;
          bottom: 6%;
          left: 26px;
          display: none; }
          .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img {
            width: 150px  !important;
            height: 50px; }
        .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .mobile_logo_small.field--name-field-banner-logo-small {
          display: block;
          z-index: 2; }
      .featured-top .field-collection-item--name-field-banner-content .content .field--name-field-banner-image::after {
        opacity: 0; }
    .featured-top .paragraph--type--banner-content .content {
      position: relative; }
      .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top {
        position: static;
        z-index: 99;
        width: 100%;
        background: #16181A;
        border-top: 1px solid #ccc; }
        .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small-right {
          display: none; }
        .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
          position: absolute;
          bottom: 45px;
          left: 26px;
          display: none; }
          .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img {
            width: 150px  !important;
            height: 50px; }
        .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .mobile_logo_small.field--name-field-banner-logo-small {
          display: block;
          z-index: 2; }
      .featured-top .paragraph--type--banner-content .content .field--name-field-banner-image:after {
        background: unset; }
      .featured-top .paragraph--type--banner-content .content .field--name-field-banner-info-text {
        width: 100%; } }

/* Banner text color update */
.node--type-careers-dashboard .text-heading {
  color: #D0118C; }

.node--type-page-program .text-heading {
  color: #deb932; }

.node--type-media-tech-page .text-heading {
  color: #009DD6 !important; }

.node--type-media-tech-page .featured-top {
  margin-bottom: 29px !important; }

.node--type-specialized-programs .banner-content-wrapper .text-heading, .node--type-programs-dashboard .banner-content-wrapper .text-heading, .node--type-location-dashboard .banner-content-wrapper .text-heading {
  color: #00AEEF; }

.node--type-internships-dashboard .banner-content-wrapper .text-heading, .node--type-benefits-dashboard .banner-content-wrapper .text-heading {
  color: #896EB1; }

.node--type-brand-dashboard .banner-content-wrapper .text-heading {
  color: #59A63F; }

.node--type-culture-dashboard .banner-content-wrapper .text-heading, .node--type-faq-dashboard .banner-content-wrapper .text-heading {
  color: #F36A30; }

/* CSS for sliding in the underline from the left and sliding it out to the right */
@media (max-width: 991.98px) {
  .cl-effect-11 {
    margin-top: 20px; } }

.cl-effect-11 .nav-item {
  padding: 0px; }
  @media (max-width: 991.98px) {
    .cl-effect-11 .nav-item {
      text-align: left;
      padding-left: 15px; } }

.cl-effect-11 .nav-item-find-a-job a {
  color: #007EAB !important; }

@media (min-width: 992px) {
  .cl-effect-11 .nav-item-careers .nav-link::before, .cl-effect-11 .nav-item-careers .nav-link.is-active {
    border-bottom: 5px solid #D0118C; }
  .cl-effect-11 .nav-item-programs .nav-link::before, .cl-effect-11 .nav-item-programs .nav-link.is-active, .cl-effect-11 .nav-item-locations .nav-link::before, .cl-effect-11 .nav-item-locations .nav-link.is-active {
    border-bottom: 5px solid #009DD6; }
  .cl-effect-11 .nav-item-programs.sub-menu-item--active .nav-link {
    border-bottom: 5px solid #009DD6; }
  .cl-effect-11 .nav-item-internships .nav-link::before, .cl-effect-11 .nav-item-internships .nav-link.is-active, .cl-effect-11 .nav-item-benefits .nav-link::before, .cl-effect-11 .nav-item-benefits .nav-link.is-active {
    border-bottom: 5px solid #896EB1; }
  .cl-effect-11 .nav-item-brands .nav-link::before, .cl-effect-11 .nav-item-brands .nav-link.is-active {
    border-bottom: 5px solid #59A63F; }
  .cl-effect-11 .nav-item-culture .nav-link::before, .cl-effect-11 .nav-item-culture .nav-link.is-active, .cl-effect-11 .nav-item-faq .nav-link::before, .cl-effect-11 .nav-item-faq .nav-link.is-active {
    border-bottom: 5px solid #F36A30; }
  .cl-effect-11 .nav-item-find-a-job {
    display: none; } }

.cl-effect-11 a {
  padding: 0;
  color: #0972b4;
  text-shadow: none; }

.navbar-toggler.navbar-toggler-right {
  /*outline:none;*/
  z-index: 2; }

@media (min-width: 992px) {
  .cl-effect-11 a::before {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 0;
    max-width: 0;
    content: attr(data-hover);
    -webkit-transition: max-width 0.5s;
    -moz-transition: max-width 0.5s;
    transition: max-width 0.5s; } }

.cl-effect-11 a:hover::before,
.cl-effect-11 a:focus::before {
  max-width: 100%; }

/*
.path-find-a-job, .path-all-locations, .path-faq, .path-candidate-privacy-policy, .node--type-page, body.node--type-careers-article .custom-nav-height {
	.header{
		border-bottom: 1px solid #2A2A2A;
	}
}
*/
@media (max-width: 33.9em) {
  .navbar-toggleable-xs .navbar-nav .nav-item + .nav-item,
  .navbar-toggleable-xs.collapse.in .navbar-nav .nav-item {
    margin-left: 0;
    float: none; } }

@media (max-width: 47.9em) {
  .navbar-toggleable-sm .navbar-nav .nav-item + .nav-item,
  .navbar-toggleable-xs.collapse.in .navbar-nav .nav-item {
    margin-left: 0;
    float: none; } }

@media only screen and (max-width: 767px) {
  .featured-top .search-form {
    border-radius: 0px; }
    .featured-top .search-form .input-search {
      border-radius: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .featured-top .field--name-field-banner-image.mobile {
    height: auto; }
  .featured-top .field--name-field-banner-image.mobile_img {
    height: 420px; }
  .featured-top .search-form {
    border-radius: 0px; }
    .featured-top .search-form .input-search {
      border-radius: 0px; } }

@media only screen and (min-width: 993px) and (max-width: 1100px) {
  #block-findajob p a {
    padding: 22px 10px; }
  .featured-top .search-form {
    border-radius: 0px; }
    .featured-top .search-form .input-search {
      border-radius: 0px; } }

@media only screen and (min-width: 993px) {
  #header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000; }
  .path-home .featured-top, .path-careers .featured-top, .path-node .featured-top, .path-user-login .layout-main-wrapper,
  .node--type-location-dashboard .layout-main-wrapper, .node--type-faq-dashboard .layout-main-wrapper, .node--type-jobs-search .layout-main-wrapper, .path-candidate-privacy-policy .layout-main-wrapper {
    margin-top: 75px !important; }
  /*.user-logged-in {
		#header {
			position: fixed;
			top: 39px;
			width: 100%;
			z-index: 2000;
		}
	}
	.path-home.user-logged-in .featured-top, .path-careers.user-logged-in .featured-top, .path-node.user-logged-in .featured-top {
		margin-top: 114px;
	}
	.node--type-location-dashboard.user-logged-in .layout-main-wrapper, .path-candidate-privacy-policy .layout-main-wrapper, .path-user-login .layout-main-wrapper,
	.node--type-faq-dashboard.user-logged-in .layout-main-wrapper, .node--type-jobs-search.user-logged-in .layout-main-wrapper {
		margin-top: 114px;
	}
	.toolbar-tray-open .toolbar-menu .menu-item--expanded.hover-intent ul, .toolbar-oriented .toolbar-bar {
		z-index:2001;
	}*/ }

@media only screen and (max-width: 992px) {
  #header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
    background: #fff; }
  .path-home .featured-top, .path-careers .featured-top, .path-node .featured-top, .path-user-login .layout-main-wrapper,
  .node--type-location-dashboard .layout-main-wrapper, .node--type-faq-dashboard .layout-main-wrapper, .node--type-jobs-search .layout-main-wrapper, .path-candidate-privacy-policy .layout-main-wrapper {
    margin-top: 70px !important; }
  /*.user-logged-in {
		#header {
			position: fixed;
			top: 39px;
			width: 100%;
			z-index: 2000;
			background:#fff;
		}
	}
	.path-home.user-logged-in .featured-top, .path-careers.user-logged-in .featured-top, .path-node.user-logged-in .featured-top {
		margin-top: 109px;
	}
	.node--type-location-dashboard.user-logged-in .layout-main-wrapper, .path-candidate-privacy-policy .layout-main-wrapper, .path-user-login .layout-main-wrapper,
	.node--type-faq-dashboard.user-logged-in .layout-main-wrapper, .node--type-jobs-search.user-logged-in .layout-main-wrapper {
		margin-top: 109px;
	}
	.toolbar-oriented .toolbar-bar {
		z-index:2001;
	}*/ }

@media only screen and (max-width: 767px) {
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 170px; }
  .node--type-page-program .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    bottom: 17px; }
  .node--type-media-tech-page .featured-top .field--name-field-banner-image.mobile {
    display: block;
    height: 300px !important;
    background-size: cover;
    background-position: right; }
    .node--type-media-tech-page .featured-top .field--name-field-banner-image.mobile::after {
      opacity: 0; }
  .node--type-media-tech-page .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    bottom: 15px;
    left: 18px; }
    .node--type-media-tech-page .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img {
      height: 50px !important;
      padding-right: 10px; }
      .node--type-media-tech-page .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img:last-child {
        width: 103px !important;
        height: 55px !important;
        border-left: 1px solid #979797;
        padding-left: 10px; } }

/* iphone 6/7/8 Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 400px; }
  .node--type-page-program .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    bottom: 56px; }
  .node--type-page-program .program_experience {
    width: 100% !important; }
    .node--type-page-program .program_experience .erg_groups {
      padding-left: 0% !important;
      margin-top: 50px !important; }
      .node--type-page-program .program_experience .erg_groups .erg_img {
        margin-bottom: 50px !important;
        padding-left: 0px; }
  .node--type-page-program .slick-prev {
    left: -35px !important; }
  .node--type-page-program .slick-next {
    right: -35px !important; } }

/* iphone 6/7/8 plus Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 345px; }
  .node--type-page-program .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    bottom: 56px; } }

/* iphone 5, 5S, 5C, and 5SE Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 260px; }
  .node--type-page-program .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    bottom: 72px; }
  .node--type-page-program .program_experience .erg_groups {
    padding-left: 0% !important;
    margin-top: 50px !important; }
    .node--type-page-program .program_experience .erg_groups .erg_img {
      margin-bottom: 50px !important;
      padding-left: 0px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .node--type-page-program .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    bottom: 45px; }
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    height: 360px; }
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details {
    width: 100% !important; }
  .node--type-media-tech-page .featured-top .field--name-field-banner-image.mobile {
    height: 420px;
    background-size: cover;
    background-position: right;
    display: block; }
  .node--type-media-tech-page .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    bottom: 30px !important;
    left: 20px !important; }
    .node--type-media-tech-page .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img {
      width: 150px !important;
      height: 50px !important; }
      .node--type-media-tech-page .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img:first-child {
        margin-right: 20px; }
      .node--type-media-tech-page .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img:last-child {
        width: 146px !important;
        height: 77px !important;
        border-left: 1px solid #979797;
        padding-left: 20px;
        position: relative; }
        .node--type-media-tech-page .featured-top .paragraph--type--banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img:last-child::before {
          display: block;
          content: '#';
          background: #979797;
          height: 68px;
          width: 1px; } }

/* iphone SE Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    height: 150px; } }

/* ihone 6/7/8 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    height: 175px; } }

/* iphone 6/7/8 pluds Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    height: 197px; } }

/* samsung galaxy S5 Portrait */
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .node--type-page-program .featured-top .field--name-field-banner-image.mobile {
    height: 170px; } }

@media only screen and (min-device-width: 1440px) {
  .image-banner {
    height: auto !important; }
  .featured-top .field--name-field-banner-logo-small {
    bottom: 60px !important; }
  .featured-top .banner_logos {
    bottom: 60px !important; }
    .featured-top .banner_logos .field--name-field-banner-logo-small {
      bottom: 0px !important; } }

/* home page gradient overlay css */
.path-home .featured-top .field--name-field-banner-image::after {
  background: -webkit-gradient(linear, left top, right top, color-stop(20%, rgba(0, 0, 0, 0.8)), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 20%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(0, 0, 0, 0.8) 20%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 20%, rgba(255, 255, 255, 0) 100%);
  background: url(../images/Hero_gradiant_wht4.png);
  z-index: 0;
  background-repeat: repeat-y;
  -webkit-background-size: 100% 100%;
  background-size: 100%; }

.path-home .featured-top .field--name-field-banner-info-text .cd-intro .cd-words-wrapper b {
  color: #ffffff; }

.path-home .featured-top .field--name-field-banner-info-text .text-heading-3 {
  color: #ffffff; }

.path-home .featured-top .field--name-field-banner-text .cd-intro .cd-words-wrapper b {
  color: #ffffff; }

.path-home .featured-top .field--name-field-banner-text .text-heading-3 {
  color: #ffffff; }

/**
 * @file
 * Styles for the help block.
 */
.block-help {
  border: 1px solid #dee2e6;
  padding: 0 1.5rem;
  margin-bottom: 2rem; }

/**
 * @file
 * Visual styles for icons.
 */
.icon-help {
  background: url(/../../images/help.png) 0 50% no-repeat;
  /* LTR */
  padding: 1px 0 1px 1.5rem;
  /* LTR */ }

[dir="rtl"] .icon-help {
  background-position: 100% 50%;
  padding: 1px 1.5rem 1px 0; }

.feed-icon {
  background: url(/../../images/feed.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 1rem;
  height: 1rem; }

.Facebook {
  background: url(../images/svg/Icon_Social_Facebook.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 2rem;
  height: 2rem; }

.Google {
  background: url(../images/svg/Icon_Social_Google.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 2rem;
  height: 2rem; }

.Instagram {
  background: url(../images/svg/Icon_Social_Instagram.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 2rem;
  height: 2rem; }

.Linkedin {
  background: url(../images/svg/Icon_Social_Linkedin.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 2rem;
  height: 2rem; }

.Twitter {
  background: url(../images/svg/icon-twitter-square.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 2rem;
  height: 2rem; }

.Account {
  background: url(../images/svg/Icon_Account.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 2rem;
  height: 2rem; }

.help {
  background: url(../images/svg/Icon_Help.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 2rem;
  height: 2rem; }

/**
 * @file
 * Styles for Bootstrap Barrio's image buttons.
 */
.image-button.is-disabled:hover,
.image-button.is-disabled:active,
.image-button.is-disabled:focus,
.image-button.is-disabled {
  background: transparent;
  opacity: 0.5;
  cursor: default; }

/**
 * @file
 * Basic styling for comment module.
 */
/**
 * Indent threaded comments.
 */
.indented {
  margin-left: 1.5rem;
  /* LTR */ }

[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 1.5rem; }

/**
 * @file
 * Visual styles for Bootstrap Barrio's item list.
 */
.item-list ul {
  list-style: none;
  margin: 0 0 0.75rem 0;
  padding: 0;
  /* LTR */ }

[dir="rtl"] .item-list ul {
  padding: 0; }

.item-list ul li {
  /* This is needed to reset styles in item-list.theme.css */
  margin: 0;
  padding: 0.75rem 1.25rem 0 0;
  /* LTR */ }

[dir="rtl"] .item-list ul li {
  /* This is needed to reset [dir="rtl"] styles in item-list.theme.css */
  margin: 0;
  padding: 0.75rem 0 0 1.25rem; }

.item-list .item-list__comma-list,
.item-list .item-list__comma-list li,
[dir="rtl"] .item-list .item-list__comma-list,
[dir="rtl"] .item-list .item-list__comma-list li {
  padding: 0; }

/**
 * @file
 * List group.
 * A list of links, grouped together.
 */
.list-group__link {
  border-top: 1px solid #dee2e6;
  padding: 0.75rem 0 0; }

.list-group__description {
  margin: 0 0 0.75rem; }

/**
 * @file
 * Visual styles for Bootstrap Barrio's node component.
 */
.node__content {
  font-size: 1rem;
  margin-top: 1rem; }

/* View mode teaser styles. */
.node--view-mode-teaser {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
  padding-bottom: 1rem; }

.node--view-mode-teaser h2 {
  margin-top: 0;
  padding-top: 0.5em; }

.node--view-mode-teaser.node--sticky {
  background: #f9f9f9;
  background: rgba(0, 0, 0, 0.024);
  border: 1px solid #dee2e6;
  padding: 0 1rem 1rem; }

.node--view-mode-teaser .node__content {
  clear: none;
  font-size: 1rem;
  line-height: 1.5; }

/* Node metadata styles. */
.node__meta {
  font-size: 0.875rem;
  margin-bottom: -5px; }

.node__meta .field--name-field-user-picture img {
  float: left;
  /* LTR */
  margin: 1px 1rem 0 0;
  /* LTR */ }

[dir="rtl"] .node__meta .field--name-field-user-picture img {
  float: right;
  margin-left: 1rem;
  margin-right: 0; }

/* Node links styles. */
.node__links {
  text-align: right;
  /* LTR */
  font-size: 1rem; }

[dir="rtl"] .node__links {
  text-align: left; }

/* Unpublished node styles. */
.node--unpublished {
  padding: 1rem 1rem 0; }

.node--unpublished .comment-text .comment-arrow {
  border-left: 1px solid #fff4f4;
  border-right: 1px solid #fff4f4; }

.node-preview-container {
  background: #d1e8f5;
  background-image: -webkit-linear-gradient(top, #d1e8f5, #d3e8f4);
  background-image: linear-gradient(to bottom, #d1e8f5, #d3e8f4);
  font-family: Arial, sans-serif;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3333);
  position: fixed;
  z-index: 499;
  width: 100%;
  padding: 10px; }

.node-preview-backlink {
  background-color: #419ff1;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #419ff1, #1076d5);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #419ff1, #1076d5);
  /* LTR */
  border: 1px solid #0048c8;
  border-radius: .4em;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  color: #fff;
  font-size: 0.9em;
  line-height: normal;
  margin: 0;
  padding: 4px 1em 4px 0.6em;
  /* LTR */
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5); }

[dir="rtl"] .node-preview-backlink {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #419ff1, #1076d5);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #419ff1, #1076d5);
  padding: 4px 0.6em 4px 1em;
  float: right; }

.node-preview-backlink:focus,
.node-preview-backlink:hover {
  background-color: #419cf1;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #59abf3, #2a90ef);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #59abf3, #2a90ef);
  /* LTR */
  border: 1px solid #0048c8;
  text-decoration: none;
  color: #fff; }

[dir="rtl"] .node-preview-backlink:focus,
[dir="rtl"] .node-preview-backlink:hover {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #59abf3, #2a90ef);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #59abf3, #2a90ef); }

.node-preview-backlink:active {
  background-color: #0e69be;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #0e69be, #2a93ef);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #0e69be, #2a93ef);
  /* LTR */
  border: 1px solid #0048c8;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25); }

[dir="rtl"] .node-preview-backlink:active {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #0e69be, #2a93ef);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #0e69be, #2a93ef); }

.node-preview-backlink::before {
  content: '';
  width: 10px;
  display: inline-block; }

/**
 * @file
 * Styles for Bootstrap Barrio page.
 */
/**
 * @file
 * The visual styles for Bootstrap Barrio's search form(s).
 */
input[type="search"] {
  box-sizing: border-box; }

header #search-block-form {
  padding: 5px 15px;
  flex-flow: row wrap; }

#search-block-form {
  display: flex; }

/**
 * @file
 * Visual styles for the Shortcut action link in Bootstrap Barrio.
 */
.shortcut-wrapper {
  margin: 2.2em 0 1.1em 0;
  /* Same as usual .page-title margin. */ }

.shortcut-wrapper .page-title {
  float: left;
  /* LTR */
  margin: 0; }

[dir="rtl"] .shortcut-wrapper .page-title {
  float: right; }

.shortcut-action {
  border-bottom: none;
  margin-left: 0.5em;
  /* LTR */
  padding-top: 0.35em; }

[dir="rtl"] .shortcut-action {
  margin-left: 0;
  margin-right: 0.5em; }

.shortcut-action:hover,
.shortcut-action:active,
.shortcut-action:focus {
  border-bottom: none; }

/* ------------------ Sidebar ----------------- */
.sidebar .block {
  border-style: solid;
  border-width: 1px;
  padding: 1rem 1rem;
  margin: 0 0 1rem; }
  .sidebar .block .content {
    font-size: 1rem;
    line-height: 1.5; }

.sidebar h2 {
  margin: 0 0 0.5rem;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 5px;
  text-shadow: 0 1px 0 #fff;
  font-size: 1.25rem;
  line-height: 1.2; }

.sidebar tbody {
  border: none; }

.sidebar tr.even,
.sidebar tr.odd {
  background: none;
  border-bottom: 1px solid #dee2e6; }

.sidebar nav ul.nav {
  margin: -1rem; }

/**
 * @file
 * Footer Styling.
 */
.site-footer {
  padding: 1.4rem 0 1.9rem 0; }
  .site-footer .layout-container {
    padding: 0 1rem; }
  .site-footer .nav-item .nav-link, .site-footer .menu--account .nav-link, .site-footer #block-footermenu4 .nav-link {
    color: #2A2A2A;
    padding: 0 20px 0 0;
    font-size: 15px; }

@media (max-width: 991.98px) {
  #block-footersocialicons {
    padding: 0px; } }

#block-footersocialicons ul.nav {
  padding-left: 13.5px; }
  #block-footersocialicons ul.nav li {
    margin: 7.5px !important;
    margin-top: 16px !important; }

#block-footerlogotagline {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }
  #block-footerlogotagline .content {
    float: right; }
    @media (max-width: 1199.98px) {
      #block-footerlogotagline .content {
        float: left; } }
    #block-footerlogotagline .content img {
      width: 190px;
      height: 55px;
      float: right; }
      @media (max-width: 991.98px) {
        #block-footerlogotagline .content img {
          float: none;
          margin-left: 10px; } }

#block-useraccountmenu, #block-footermenu4 {
  float: right;
  width: 35.7%;
  padding-top: 10px; }
  #block-useraccountmenu ul.nav, #block-footermenu4 ul.nav {
    float: right; }
    #block-useraccountmenu ul.nav a[href], #block-footermenu4 ul.nav a[href] {
      overflow: hidden;
      background-position: left 6px !important;
      background-size: 1.5rem !important;
      padding-left: 34px;
      padding-bottom: 3px;
      padding-top: 6px;
      padding-right: 0px; }
    #block-useraccountmenu ul.nav .nav-item-help:before, #block-footermenu4 ul.nav .nav-item-help:before {
      background: url(../images/svg/Icon_Help.svg) no-repeat;
      width: 24px;
      height: 24px;
      content: "";
      display: block;
      float: left;
      margin: 0 13px; }
      @media (max-width: 991.98px) {
        #block-useraccountmenu ul.nav .nav-item-help:before, #block-footermenu4 ul.nav .nav-item-help:before {
          margin-left: 0px; } }
    @media (max-width: 991.98px) {
      #block-useraccountmenu ul.nav .nav-item-help, #block-footermenu4 ul.nav .nav-item-help {
        padding-left: 0px !important; } }
    #block-useraccountmenu ul.nav .nav-item-candidate-zone:before, #block-footermenu4 ul.nav .nav-item-candidate-zone:before {
      background: url(../images/svg/Icon_Account.svg) no-repeat;
      width: 24px;
      height: 24px;
      content: "";
      display: block;
      float: left;
      margin: 0 10px; }

.Google, .Instagram, .Linkedin, .Facebook, .Twitter {
  width: 24px;
  height: 24px; }

/* Footer Top Styling. */
.site-footer__top .content {
  margin-top: 0; }

#block-footermenu2 li.nav-item:first-child {
  margin-bottom: 6px; }

.site-footer .nav-item .nav-link.choice-icon {
  background-image: url(../images/svg/Your_Privacy_Choices_Icon.svg);
  background-position: left 0px top 4px;
  background-repeat: no-repeat;
  background-size: 30px 14px;
  padding: 0 20px 0 39px; }

.site-footer__bottom img {
  height: 21px; }

/* Footer Bottom Styling. */
.site-footer__bottom .block {
  margin: 0.5rem 0; }

.site-footer__bottom .content {
  padding: 0.5rem 0;
  margin-top: 0; }

.region-footer-third {
  border-bottom: 1px solid gray;
  padding-bottom: 1.5px; }

.region-footer-fourth, .region-footer-fifth {
  padding-top: 15px; }

.region-footer-fifth a.navbar-brand {
  padding-left: 0px !important; }

.menu--footer-social-icons h2 {
  font-size: 15px;
  font-weight: bold;
  color: #2A2A2A;
  margin-top: 1rem;
  font-family: "SourceSansPro-Bold";
  margin-bottom: 15px;
  font-weight: normal; }

.menu--footer-social-icons .nav-item {
  margin: 1rem; }
  .menu--footer-social-icons .nav-item .nav-link {
    padding: 0; }

/* One Trust Footer Styling Start. */
#block-nbcucareers-footer #ot-sdk-btn.ot-sdk-show-settings {
  border: none;
  background-color: #eee;
  color: #2a2a2a;
  padding: 0 20px 0 39px;
  font-size: 15px;
  line-height: unset; }
  #block-nbcucareers-footer #ot-sdk-btn.ot-sdk-show-settings:hover {
    color: #2a2a2a;
    background-color: #eee; }

.site-footer .nav-item .nav-link.choice-icon.us {
  background-image: url(../images/svg/Your_Privacy_Choices_Icon.svg) !important;
  padding-left: 39px !important; }

.site-footer .nav-item .nav-link.choice-icon {
  background-image: none !important;
  padding-left: 0 !important; }

/* One Trust Footer Styling End. */
@media (min-width: 992px) {
  #block-footerlogotagline {
    flex: 0 0 19%;
    max-width: 19%; }
  #block-nbcucareers-footer {
    flex: 0 0 25%;
    max-width: 25%; } }

@media only screen and (max-width: 961px) {
  .region-footer-third {
    border-bottom: none;
    padding: 0px 20px; }
  .region-footer-fourth {
    border-bottom: 1px solid #ccc;
    padding: 0px 20px; }
  .Google, .Instagram, .Linkedin, .Facebook, .Twitter {
    width: 40px;
    height: 40px; }
  .Twitter {
    background-size: cover; }
  #block-useraccountmenu, #block-footermenu4 {
    float: left;
    width: 100%; }
  #block-useraccountmenu ul.nav, #block-footermenu4 ul.nav {
    float: left; }
  .site-footer__top {
    position: static; }
  .site-footer .container-fluid, .site-footer .container-sm, .site-footer .container-md, .site-footer .container-lg, .site-footer .container-xl {
    position: relative; }
  #block-nbcucareers-footer {
    padding: 35px 0px 11px 0px; }
  .region-footer-fourth, .region-footer-fifth {
    padding-top: 23px; }
  .site-footer__bottom img {
    width: 220px; }
  #block-useraccountmenu, #block-footermenu4 {
    float: left;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: -77px;
    padding-left: 20px;
    background: #eee; }
    #block-useraccountmenu ul.nav a[href], #block-footermenu4 ul.nav a[href] {
      overflow: hidden;
      background-position: left 6px !important;
      -webkit-background-size: 1.5rem 1.5rem !important;
      background-size: 40px !important;
      padding-left: 54px;
      padding-bottom: 16px;
      padding-top: 19px; }
  #block-footersocialicons ul.nav li {
    margin: 12.5px !important;
    margin-top: 8px !important; }
  .region-footer-fifth a {
    padding-left: 20px; }
  #block-nbcucareers-footer ul.nav,
  #block-footermenu3 ul.nav {
    padding-right: 0px; }
  #block-footerlogotagline .content {
    float: none;
    margin-left: -10px;
    margin-top: 35px; }
  #block-nbcucareers-footer ul.nav li a, #block-footermenu3 ul.nav li a {
    padding: 1rem 0rem; }
  .region-footer-fourth {
    border-bottom: 1px solid #ccc;
    padding: 0px 0px; }
  .site-footer .nav-item .nav-link.choice-icon {
    background-position: left 0px top 21px;
    padding: 1rem 0 1rem 0 !important; } }

@media only screen and (max-width: 600px) {
  #block-footersocialicons ul.nav {
    width: 100%;
    padding: 0px; }
    #block-footersocialicons ul.nav li:first-child {
      margin-left: 15px !important; }
  .site-footer {
    padding-top: 5px; }
  .menu--footer-social-icons h2 {
    margin-bottom: 15px;
    padding-left: 15px; }
  .region-footer-third {
    border-bottom: 1px solid #ccc;
    padding: 0px 0px 8px 0px; }
  .region-footer-fourth, .region-footer-fifth {
    padding-top: 13px; }
  #block-nbcucareers-footer ul.nav li a {
    padding: 1rem 0rem; }
  .region-footer-fourth {
    border-bottom: 1px solid #ccc;
    padding: 0px; }
  .site-footer .nav-item .nav-link.choice-icon {
    background-position: left 0px top 21px;
    padding: 1rem 0 1rem 0 !important; } }

@media only screen and (max-width: 380px) {
  #block-footersocialicons ul.nav li {
    margin: 5.5px !important; } }

/**
 * @file
 * Styles for the skip link.
 */
.skip-link {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 50;
  background: #495057;
  font-size: 1rem;
  line-height: 1.5;
  padding: 1px 1rem 2px;
  border-radius: 0 0 0.3rem 0.3rem;
  border-bottom-width: 0;
  outline: 0; }

.skip-link.visually-hidden.focusable:focus {
  position: absolute !important;
  color: #fff; }

/**
 * @file
 * Styles for Bootstrap Barrio's tables.
 */
.sticky-header thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.sticky-header th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

/**
 * @file
 * Visual styles for table drag.
 */
tr.drag {
  background-color: #fffff0; }

tr.drag-previous {
  background-color: #ffd; }

body div.tabledrag-changed-warning {
  margin-bottom: 0.5rem; }

/**
 * @file
 * Table select behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc; }

td.checkbox,
th.checkbox {
  text-align: center; }

[dir="rtl"] td.checkbox,
[dir="rtl"] th.checkbox {
  /* This is required to win over specificity of [dir="rtl"] td */
  text-align: center; }

/**
 * @file
 * Table sort indicator.
 */
th.is-active img {
  display: inline; }

td.is-active {
  background-color: #dee2e6; }

/**
 * @file
 * Tablesort indicator styles.
 */
.tablesort {
  width: 14px;
  height: 14px;
  vertical-align: top;
  margin: 1px 0 0 5px; }

.tablesort--asc {
  background-image: url(/core/misc/icons/ffffff/twistie-down.svg); }

.tablesort--desc {
  background-image: url(/core/misc/icons/ffffff/twistie-up.svg); }

/**
 * @file
 * Theme styling for tabs.
 */
nav.tabs {
  margin-bottom: 1rem; }

/**
 * @file
 * Visual styles for a resizable textarea.
 */
.form-textarea-wrapper textarea {
  display: block;
  margin: 0;
  width: 100%;
  box-sizing: border-box; }

/**
 * @file
 * Visual styles for Bartik's ui-widget component.
 */
/**
 * @file
 * Styles for Bartik's modal windows
 */
.ui-widget-overlay {
  background: #000;
  opacity: 0.7; }

.ui-dialog {
  border-radius: 0; }

.ui-dialog input,
.ui-dialog select,
.ui-dialog textarea {
  font-size: 1rem; }

.ui-dialog .button {
  background-color: #fff;
  background-image: -webkit-linear-gradient(top, #f3f3f3, #e8e8e8);
  background-image: linear-gradient(to bottom, #f3f3f3, #e8e8e8);
  border: 1px solid #e4e4e4;
  border-bottom-color: #b4b4b4;
  border-left-color: #d2d2d2;
  border-right-color: #d2d2d2;
  color: #3a3a3a;
  cursor: pointer;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  padding: 0.250em 1.063em;
  border-radius: 1em; }

/**
 * @file
 * Theme styling for user module.
 */
/* Visual styling for the Password strength indicator */
.password-strength__meter {
  margin-top: 0.5em;
  background-color: #e9ecef; }

.password-strength__indicator {
  background-color: #28a745;
  -webkit-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out; }

.password-strength__indicator.is-weak {
  background-color: #dc3545; }

.password-strength__indicator.is-fair {
  background-color: #ffc107; }

.password-strength__indicator.is-good {
  background-color: #17a2b8; }

.password-strength__indicator.is-strong {
  background-color: #28a745; }

.password-confirm,
.password-field,
.password-strength,
.password-confirm-match {
  width: 55%; }

.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  max-width: 34.7em;
  border: 1px solid #dee2e6; }

.password-suggestions ul {
  margin-bottom: 0; }

.confirm-parent,
.password-parent {
  clear: left;
  /* LTR */
  margin: 0;
  max-width: 33em;
  overflow: hidden; }

[dir="rtl"] .confirm-parent,
[dir="rtl"] .password-parent {
  clear: right; }

/* Styling for the status indicator of the passwords match test.  */
.password-confirm .ok {
  color: #28a745;
  font-weight: bold; }

.password-confirm .error {
  color: #dc3545;
  font-weight: bold; }

/* Login page stype */
.page-user-login main .region-content,
.page-user-password main .region-content {
  max-width: 400px;
  background: white;
  padding: 1rem 1rem;
  margin: 0 auto;
  border-radius: 0.3rem; }

/**
 * @file
 * Vertical tabs component.
 */
/* Vertical Tabs Reset */
.vertical-tabs__menu {
  margin: -1px 0 -1px;
  /* LTR */
  padding: 0; }

[dir="rtl"] .vertical-tabs__menu {
  margin-left: 0;
  margin-right: -1em;
  /* This is required to win specificity over [dir="rtl"] .region-content ul */
  padding: 0; }

/**
 * @file
 * Vertical Tabs.
 */
.vertical-tabs {
  margin: 0;
  /* LTR */
  border: 1px solid #dee2e6;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem; }

[dir="rtl"] .vertical-tabs {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: 0; }

.vertical-tabs__menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* LTR */
  width: 15em;
  margin: 0;
  /* LTR */
  padding: 0;
  border-top: 1px solid #dee2e6;
  list-style: none; }

[dir="rtl"] .vertical-tabs__menu {
  margin-left: 0;
  margin-right: -15em; }

.vertical-tabs__pane {
  margin: 0;
  border: 0; }

.vertical-tabs__pane > summary {
  display: none; }

/* Layout of each tab. */
.vertical-tabs__menu-item {
  border: 1px solid #dee2e6;
  border-top: 0;
  background: #eee; }

.vertical-tabs__menu-item a {
  display: block;
  padding: 0.5em 0.6em;
  text-decoration: none; }

.vertical-tabs__menu-item a:focus .vertical-tabs__menu-item-title,
.vertical-tabs__menu-item a:active .vertical-tabs__menu-item-title,
.vertical-tabs__menu-item a:hover .vertical-tabs__menu-item-title {
  text-decoration: underline; }

.vertical-tabs__menu-item a:hover {
  outline: 1px dotted; }

.vertical-tabs__menu-item.is-selected {
  border-right-width: 0;
  /* LTR */
  background-color: #fff; }

[dir="rtl"] .vertical-tabs__menu-item.is-selected {
  border-left-width: 0;
  border-right-width: 1px; }

.vertical-tabs__menu-item.is-selected .vertical-tabs__menu-item-title {
  color: #000; }

.vertical-tabs__menu-item-summary {
  display: block;
  margin-bottom: 0;
  line-height: normal; }

/**
 * @file
 * Styling for Views in Bartik.
 */
/* Tab styles */
.views-displays .tabs .open > a {
  border-radius: 0.3rem 0.3rem 0 0; }

.views-displays .tabs .open > a:hover,
.views-displays .tabs .open > a:focus {
  color: #0079C0; }

.views-displays .secondary .form-submit {
  font-size: 0.875rem; }

/* Tabs action list styles */
.views-displays .tabs .action-list {
  padding: 0; }

/* Contextual filter options styles */
.views-filterable-options .filterable-option:nth-of-type(even) .form-type-checkbox {
  background-color: #F9F9F9; }

/* Views action dropbutton styles */
.views-ui-display-tab-actions .dropbutton .form-submit {
  color: #005283; }

.views-ui-display-tab-actions .dropbutton .form-submit:hover,
.views-ui-display-tab-actions .dropbutton .form-submit:focus {
  color: #0079C0; }

/* -------------------------------- 

Primary style

-------------------------------- */
.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left; }

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0; }

.cd-words-wrapper b.is-visible {
  position: relative; }

.no-js .cd-words-wrapper b {
  opacity: 0; }

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1; }

/* -------------------------------- 

xslide 

-------------------------------- */
.cd-headline.slide span {
  display: inline-block;
  padding: 0; }

.cd-headline.slide .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top; }

.cd-headline.slide b {
  opacity: 0;
  top: 0; }

.cd-headline.slide b.is-visible {
  top: 0;
  opacity: 1;
  -webkit-animation: slide-in 0.6s;
  -moz-animation: slide-in 0.6s;
  animation: slide-in 0.6s; }

.cd-headline.slide b.is-hidden {
  -webkit-animation: slide-out 0.6s;
  -moz-animation: slide-out 0.6s;
  animation: slide-out 0.6s; }

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%); }
  60% {
    opacity: 1;
    -moz-transform: translateY(20%); }
  100% {
    opacity: 1;
    -moz-transform: translateY(0); } }

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    -o-transform: translateY(20%);
    transform: translateY(20%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0); }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%); } }

@-moz-keyframes slide-out {
  0% {
    opacity: 1;
    -moz-transform: translateY(0); }
  60% {
    opacity: 0;
    -moz-transform: translateY(120%); }
  100% {
    opacity: 0;
    -moz-transform: translateY(100%); } }

@keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
    -moz-transform: translateY(120%);
    -ms-transform: translateY(120%);
    -o-transform: translateY(120%);
    transform: translateY(120%); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%); } }

/* MIXINS */
.btn-primary a {
  color: #fff;
  background-color: #0079C0;
  border-color: #0079C0; }
  .btn-primary a:hover {
    color: #fff;
    background-color: #00619a;
    border-color: #00598d; }
  .btn-primary a:focus, .btn-primary a.focus {
    color: #fff;
    background-color: #00619a;
    border-color: #00598d;
    box-shadow: 0 0 0 0.2rem rgba(38, 141, 201, 0.5); }
  .btn-primary a.disabled, .btn-primary a:disabled {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .btn-primary a:not(:disabled):not(.disabled):active, .btn-primary a:not(:disabled):not(.disabled).active,
  .show > .btn-primary a.dropdown-toggle {
    color: #fff;
    background-color: #00598d;
    border-color: #005180; }
    .btn-primary a:not(:disabled):not(.disabled):active:focus, .btn-primary a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 141, 201, 0.5); }

.btn-secondary a {
  color: #fff;
  background-color: #2A2A2A;
  border-color: #2A2A2A; }
  .btn-secondary a:hover {
    color: #fff;
    background-color: #171717;
    border-color: #111111; }
  .btn-secondary a:focus, .btn-secondary a.focus {
    color: #fff;
    background-color: #171717;
    border-color: #111111;
    box-shadow: 0 0 0 0.2rem rgba(74, 74, 74, 0.5); }
  .btn-secondary a.disabled, .btn-secondary a:disabled {
    color: #fff;
    background-color: #2A2A2A;
    border-color: #2A2A2A; }
  .btn-secondary a:not(:disabled):not(.disabled):active, .btn-secondary a:not(:disabled):not(.disabled).active,
  .show > .btn-secondary a.dropdown-toggle {
    color: #fff;
    background-color: #111111;
    border-color: #0a0a0a; }
    .btn-secondary a:not(:disabled):not(.disabled):active:focus, .btn-secondary a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(74, 74, 74, 0.5); }

.btn-success a {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success a:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success a:focus, .btn-success a.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success a.disabled, .btn-success a:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success a:not(:disabled):not(.disabled):active, .btn-success a:not(:disabled):not(.disabled).active,
  .show > .btn-success a.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success a:not(:disabled):not(.disabled):active:focus, .btn-success a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info a {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info a:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info a:focus, .btn-info a.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info a.disabled, .btn-info a:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info a:not(:disabled):not(.disabled):active, .btn-info a:not(:disabled):not(.disabled).active,
  .show > .btn-info a.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info a:not(:disabled):not(.disabled):active:focus, .btn-info a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning a {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning a:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning a:focus, .btn-warning a.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning a.disabled, .btn-warning a:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning a:not(:disabled):not(.disabled):active, .btn-warning a:not(:disabled):not(.disabled).active,
  .show > .btn-warning a.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning a:not(:disabled):not(.disabled):active:focus, .btn-warning a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger a {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger a:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger a:focus, .btn-danger a.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger a.disabled, .btn-danger a:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger a:not(:disabled):not(.disabled):active, .btn-danger a:not(:disabled):not(.disabled).active,
  .show > .btn-danger a.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger a:not(:disabled):not(.disabled):active:focus, .btn-danger a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light a {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light a:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light a:focus, .btn-light a.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light a.disabled, .btn-light a:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light a:not(:disabled):not(.disabled):active, .btn-light a:not(:disabled):not(.disabled).active,
  .show > .btn-light a.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light a:not(:disabled):not(.disabled):active:focus, .btn-light a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark a {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark a:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark a:focus, .btn-dark a.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark a.disabled, .btn-dark a:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark a:not(:disabled):not(.disabled):active, .btn-dark a:not(:disabled):not(.disabled).active,
  .show > .btn-dark a.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark a:not(:disabled):not(.disabled):active:focus, .btn-dark a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary a {
  color: #0079C0;
  border-color: #0079C0; }
  .btn-outline-primary a:hover {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .btn-outline-primary a:focus, .btn-outline-primary a.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }
  .btn-outline-primary a.disabled, .btn-outline-primary a:disabled {
    color: #0079C0;
    background-color: transparent; }
  .btn-outline-primary a:not(:disabled):not(.disabled):active, .btn-outline-primary a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary a.dropdown-toggle {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
    .btn-outline-primary a:not(:disabled):not(.disabled):active:focus, .btn-outline-primary a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }

.btn-outline-secondary a {
  color: #2A2A2A;
  border-color: #2A2A2A; }
  .btn-outline-secondary a:hover {
    color: #fff;
    background-color: #2A2A2A;
    border-color: #2A2A2A; }
  .btn-outline-secondary a:focus, .btn-outline-secondary a.focus {
    box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5); }
  .btn-outline-secondary a.disabled, .btn-outline-secondary a:disabled {
    color: #2A2A2A;
    background-color: transparent; }
  .btn-outline-secondary a:not(:disabled):not(.disabled):active, .btn-outline-secondary a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary a.dropdown-toggle {
    color: #fff;
    background-color: #2A2A2A;
    border-color: #2A2A2A; }
    .btn-outline-secondary a:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5); }

.btn-outline-success a {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success a:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success a:focus, .btn-outline-success a.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success a.disabled, .btn-outline-success a:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success a:not(:disabled):not(.disabled):active, .btn-outline-success a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success a.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success a:not(:disabled):not(.disabled):active:focus, .btn-outline-success a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info a {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info a:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info a:focus, .btn-outline-info a.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info a.disabled, .btn-outline-info a:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info a:not(:disabled):not(.disabled):active, .btn-outline-info a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info a.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info a:not(:disabled):not(.disabled):active:focus, .btn-outline-info a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning a {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning a:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning a:focus, .btn-outline-warning a.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning a.disabled, .btn-outline-warning a:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning a:not(:disabled):not(.disabled):active, .btn-outline-warning a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning a.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning a:not(:disabled):not(.disabled):active:focus, .btn-outline-warning a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger a {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger a:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger a:focus, .btn-outline-danger a.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger a.disabled, .btn-outline-danger a:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger a:not(:disabled):not(.disabled):active, .btn-outline-danger a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger a.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger a:not(:disabled):not(.disabled):active:focus, .btn-outline-danger a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light a {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light a:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light a:focus, .btn-outline-light a.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light a.disabled, .btn-outline-light a:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light a:not(:disabled):not(.disabled):active, .btn-outline-light a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light a.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light a:not(:disabled):not(.disabled):active:focus, .btn-outline-light a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark a {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark a:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark a:focus, .btn-outline-dark a.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark a.disabled, .btn-outline-dark a:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark a:not(:disabled):not(.disabled):active, .btn-outline-dark a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark a.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark a:not(:disabled):not(.disabled):active:focus, .btn-outline-dark a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

div.brand-display-page .brand-bg-grey {
  background-color: #8E9095; }

div.brand-display-page .brand-bg-green:hover {
  background-color: #59A63F; }

div.brand-display-page .brand-bg-blue:hover {
  background-color: #009DD6; }

div.brand-display-page .brand-bg-tomato:hover {
  background-color: #F36A30; }

div.brand-display-page a img {
  width: 100%; }

div.brand-display-page .brand-bg-lgray:hover {
  background-color: #876EAF; }

div.brand-display-page .brand-col-sep .views-field {
  margin-top: 2%;
  margin-bottom: 2%; }

div.brand-display-page ul.pager {
  text-align: center;
  margin-top: 1%; }

div.brand-display-page ul.pager li {
  list-style: none; }

div.brand-display-page ul.pager a.button {
  background-color: #EEEEEE;
  background-image: none;
  border-radius: 0;
  padding: 1%;
  color: #E7E6EB; }

div.brand-display-page ul.pager a.button:hover {
  background-color: #00AFF0; }

div.brand-dashboard-display {
  position: relative; }

div.brand-dashboard-left {
  position: absolute;
  top: 40%;
  margin-left: 5%; }

div.brand-dashboard-display div.banner-dashboard-img img {
  width: 100%; }

div.brand-display-page div.brand-col-sep {
  display: none; }

div.brand-display-page .brand-contentload-button {
  text-align: center;
  margin-top: 3%;
  margin-bottom: 3%; }
  @media (max-width: 991.98px) {
    div.brand-display-page .brand-contentload-button {
      margin-top: 45px; } }

div.brand-display-page .brand-contentload-button #showLess {
  display: none;
  /*padding:1%;		
	color:#A7A9Ac;
	background-color:#EEEEEE;
	cursor:pointer;
	width:25%;
	margin-left:40%;*/
  color: #fff;
  background-color: #009DD6;
  cursor: pointer;
  width: 145px;
  margin: 0 auto;
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  font-family: "SourceSansPro-Semibold"; }
  div.brand-display-page .brand-contentload-button #showLess i {
    margin-left: 10px; }
  @media (max-width: 991.98px) {
    div.brand-display-page .brand-contentload-button #showLess {
      width: 130px;
      height: 45px;
      line-height: 45px; } }

div.brand-display-page .brand-contentload-button #loadMore {
  /*padding:1%;		
	color:#929599;
	background-color:#009DD6;
	cursor:pointer;
	width:25%;
	margin-left:40%;*/
  color: #fff;
  background-color: #009DD6;
  cursor: pointer;
  width: 145px;
  margin: 0 auto;
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  font-family: "SourceSansPro-Semibold"; }
  div.brand-display-page .brand-contentload-button #loadMore i {
    margin-left: 10px;
    font-weight: bold; }
  @media (max-width: 991.98px) {
    div.brand-display-page .brand-contentload-button #loadMore {
      width: 130px;
      height: 45px;
      line-height: 45px; } }

div.brand-display-page .brand-contentload-button div#loadMore:hover, div.brand-display-page .brand-contentload-button div#showLess:hover {
  background-color: #009DD6;
  color: #fff; }

.text-green {
  color: #71C15B; }

div.brand-page-cont h2 {
  font-size: 40px;
  color: #2a2a2a;
  font-family: "sharpSans-Bold";
  line-height: 46px; }

div.brand-page-cont p {
  font-size: 18px;
  color: #2a2a2a;
  margin-top: 10px;
  font-family: "SourceSansPro-Regular";
  line-height: 30px; }
  @media (max-width: 991.98px) {
    div.brand-page-cont p {
      line-height: 35px; } }

@media (max-width: 991.98px) {
  body.path-brands div.brand-page-cont {
    padding: 0 20px; } }

body.path-brands h1.title {
  color: #71C15B; }

div.brand-display-page .brand-contentload-button #loadMore::after {
  /*content: "\2193";
    display: inline-block; 
	margin-left: 1rem;	*/ }

div.brand-display-page .brand-contentload-button #showLess::after {
  /*content: "\2191";
    display: inline-block; 
	margin-left: 1rem;	*/ }

body.path-brands #block-nbcucareers-content {
  display: none; }

body.path-brands .brand-title .side-title {
  font-family: "sharpSans-Semibold";
  font-size: 23px;
  line-height: 34px; }
  @media (max-width: 991.98px) {
    body.path-brands .brand-title .side-title {
      font-size: 20px; } }

body.path-brands .brand-title .title-border {
  border-bottom: 6px solid #59A63F;
  width: 75px; }
  @media (max-width: 991.98px) {
    body.path-brands .brand-title .title-border {
      margin-bottom: 20px; } }

body.path-brands .body-subtitle {
  font-family: "sharpSans-Book";
  font-size: 40px;
  line-height: 46px; }
  @media (max-width: 991.98px) {
    body.path-brands .body-subtitle {
      font-size: 26px;
      line-height: 36px; } }

body.path-brands .brand-title {
  clear: both; }

.path-brands #main-wrapper {
  background-image: url("../images/svg/culture_text_bg_grey.png");
  background-size: auto;
  background-position: right 0% bottom 0%;
  background-repeat: no-repeat;
  padding-bottom: 53px; }

.path-brands .site-footer {
  margin-top: 0px !important; }

.path-brands .featured-top {
  margin-bottom: 32px;
  /*.cd-words-wrapper {
			min-height:192px;
			height:192px;
			@include media-breakpoint-down(sm) {
				min-height:140px;
				height:140px;
			}
		}*/ }

.path-brands .site-footer {
  margin-top: 53px; }

.path-brands .brand-header > div:nth-child(2) {
  padding: 0px; }

.path-brands #main-wrapper .container-fluid .container-fluid-lg, .path-brands #main-wrapper .container-sm .container-fluid-lg, .path-brands #main-wrapper .container-md .container-fluid-lg, .path-brands #main-wrapper .container-lg .container-fluid-lg, .path-brands #main-wrapper .container-xl .container-fluid-lg {
  padding: 0px; }

.path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(2) .view-content.row, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(2) .view-content.row, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(2) .view-content.row, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(2) .view-content.row, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(2) .view-content.row {
  padding: 0 15px; }
  .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(2) .view-content.row > div, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(2) .view-content.row > div, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(2) .view-content.row > div, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(2) .view-content.row > div, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(2) .view-content.row > div {
    width: 100%; }
  .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child {
    padding: 0px; }

.path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3), .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3), .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3), .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3), .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) {
  margin-top: 40px; }
  .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content {
    margin-left: -27px;
    margin-right: -24px; }
    .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content > div, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content > div, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content > div, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content > div, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content > div {
      padding: 3.5px 10px; }
      .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey {
        min-height: 290px;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: color, background-color;
        -o-transition-property: color, background-color;
        transition-property: color, background-color; }
        @media (max-width: 1199.98px) {
          .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey {
            min-height: 230px; } }
        @media (max-width: 991.98px) {
          .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey {
            min-height: 150px; } }
        @media (max-width: 767.98px) {
          .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey {
            min-height: 160px; } }
        @media (max-width: 767.98px) {
          .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content > div .brand-bg-grey {
            min-height: 130px; } }

.path-brands #main-wrapper .container-fluid .bg_grey, .path-brands #main-wrapper .container-sm .bg_grey, .path-brands #main-wrapper .container-md .bg_grey, .path-brands #main-wrapper .container-lg .bg_grey, .path-brands #main-wrapper .container-xl .bg_grey {
  background-color: #8E9095; }

@media screen and (max-width: 1280px) {
  .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content {
    margin-left: 0px;
    margin-right: 0px; }
  .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .views-element-container.col-auto, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .views-element-container.col-auto, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .views-element-container.col-auto, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .views-element-container.col-auto, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .views-element-container.col-auto {
    padding: 0px 5px; }
  .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(2) .view-content.row .row.brand-header > div:first-child {
    padding-left: 15px; } }

.benefits-tiles-content strong {
  display: block;
  margin: 28px 0 12px;
  font-size: 20px;
  font-family: "sharpSans-Semibold"; }

.benefits-tiles-content p {
  font-size: 16px; }

@media only screen and (max-width: 991.5px) {
  .benefits-tiles-content {
    text-align: center; } }

body.path-benefits .brand-title .side-title {
  font-size: 23px;
  font-family: "sharpSans-Semibold";
  margin-top: 12px;
  line-height: 28.75px;
  font-weight: 770; }

body.path-benefits .brand-title .title-border {
  border-bottom: 6px solid #896eb1;
  width: 75px;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    body.path-benefits .brand-title .title-border {
      margin-bottom: 20px; } }

body.path-benefits main #block-nbcucareers-content {
  display: none; }

div.benefits-tiles-top .view-content div.with-benefits-tiles-background {
  position: relative;
  background-color: #896EB1;
  background-size: cover;
  background-position: right 0% bottom 0%;
  background-repeat: no-repeat; }

div.benefits-tiles-top .view-content div.with-benefits-tiles-background img {
  opacity: 0; }

div.benefits-tiles-top .view-content div.with-benefits-tiles-background .benefits-tiles-body {
  position: absolute;
  top: 0px;
  width: 95%; }

.benefits-tiles-bottom, .benefits-tiles-middle {
  margin-bottom: 2px; }

.text-white {
  color: #fff; }

.with-benefits-tiles-background .benefits-tiles-body {
  margin: 7%; }

.path-benefits .benefits-tiles-top, .path-benefits .benefits-tiles-middle, .path-benefits .benefits-tiles-bottom {
  margin-bottom: 40px; }

.path-benefits .with-benefits-tiles-background .img-fluid {
  max-width: 100% !important;
  width: 100%;
  height: 295px; }

.path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right {
  padding-left: 50px !important; }

.path-benefits .without-benefits-tiles-background .benefits-tiles-right {
  text-align: center; }

.path-benefits .without-benefits-tiles-background .benefits-tiles-right strong {
  font-size: 20px;
  font-family: "sharpSans-Semibold"; }

.path-benefits .without-benefits-tiles-background .benefits-tiles-right p {
  margin-top: 10px;
  font-size: 18px;
  color: #2A2A2A;
  line-height: 30px; }

.path-benefits .benefits-tiles-bottom .view-footer p {
  font-size: 16px;
  font-family: sharpSans-Semibold;
  margin-top: 48px;
  margin-bottom: 48px; }

.path-benefits .benefits-tiles-top .views-row, .path-benefits .benefits-tiles-middle .views-row, .path-benefits .benefits-tiles-bottom .views-row {
  padding-left: 10px !important;
  padding-right: 10px !important; }
  @media (max-width: 991.98px) {
    .path-benefits .benefits-tiles-top .views-row, .path-benefits .benefits-tiles-middle .views-row, .path-benefits .benefits-tiles-bottom .views-row {
      padding-left: 15px !important;
      padding-right: 15px !important; } }

.path-benefits .row-bottom-benefits .without-benefits-tiles-background .benefits-tiles-right, .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right {
  text-align: left; }
  .path-benefits .row-bottom-benefits .without-benefits-tiles-background .benefits-tiles-right p, .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right p {
    padding-right: 55px;
    padding-left: 0px; }

.path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-body {
  padding-top: 35px; }
  @media (max-width: 991.98px) {
    .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-body {
      padding-top: 3%; } }
  .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-body .benefits-tiles-left {
    padding-top: 5%; }

.path-benefits .benefits-tiles-top .benefits-tiles-left img {
  width: 100px;
  position: relative;
  margin-left: 50px; }

.color-lavendar {
  color: #896eb1; }

.view-bottom-banner-section .bottom-banner-section-sep {
  width: 100%; }
  .view-bottom-banner-section .bottom-banner-section-sep .program-img-container, .view-bottom-banner-section .bottom-banner-section-sep .program-image-top, .view-bottom-banner-section .bottom-banner-section-sep .program-image-bottom {
    margin: 0px;
    padding: 0px;
    height: auto !important;
    overflow: hidden; }
    .view-bottom-banner-section .bottom-banner-section-sep .program-img-container img, .view-bottom-banner-section .bottom-banner-section-sep .program-image-top img, .view-bottom-banner-section .bottom-banner-section-sep .program-image-bottom img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media (min-width: 992px) {
    .view-bottom-banner-section .bottom-banner-section-sep .program-img-container {
      max-height: 520px; } }

.path-benefits .benefits-tiles-left img {
  width: 100px; }

.path-benefits .featured-top {
  margin-bottom: 0px !important; }

.path-benefits .tt-view-benefits_tiles {
  margin: 0 auto;
  overflow: visible; }

@media (max-width: 767.98px) {
  .path-benefits .container-fluid-lg {
    padding: 0px 15px; } }

@media (max-width: 767.98px) {
  .path-benefits .banner-content-wrapper {
    padding-bottom: 1px !important; } }

@media (max-width: 767.98px) {
  .path-benefits .field--name-field-banner-info-text {
    margin-top: 30px !important; } }

.path-benefits #main-wrapper {
  margin-top: 15px; }
  .path-benefits #main-wrapper .container-fluid, .path-benefits #main-wrapper .container-sm, .path-benefits #main-wrapper .container-md, .path-benefits #main-wrapper .container-lg, .path-benefits #main-wrapper .container-xl {
    max-width: 1270px; }
    .path-benefits #main-wrapper .container-fluid .tt-view-benefits_tiles .view-content.row, .path-benefits #main-wrapper .container-sm .tt-view-benefits_tiles .view-content.row, .path-benefits #main-wrapper .container-md .tt-view-benefits_tiles .view-content.row, .path-benefits #main-wrapper .container-lg .tt-view-benefits_tiles .view-content.row, .path-benefits #main-wrapper .container-xl .tt-view-benefits_tiles .view-content.row {
      margin: -10px; }
    .path-benefits #main-wrapper .container-fluid .tt-view-benefits_tiles:nth-child(2) .views-element-container, .path-benefits #main-wrapper .container-sm .tt-view-benefits_tiles:nth-child(2) .views-element-container, .path-benefits #main-wrapper .container-md .tt-view-benefits_tiles:nth-child(2) .views-element-container, .path-benefits #main-wrapper .container-lg .tt-view-benefits_tiles:nth-child(2) .views-element-container, .path-benefits #main-wrapper .container-xl .tt-view-benefits_tiles:nth-child(2) .views-element-container {
      overflow: -webkit-paged-x; }
    .path-benefits #main-wrapper .container-fluid .tt-view-benefits_tiles:nth-child(2) .view-content.row, .path-benefits #main-wrapper .container-sm .tt-view-benefits_tiles:nth-child(2) .view-content.row, .path-benefits #main-wrapper .container-md .tt-view-benefits_tiles:nth-child(2) .view-content.row, .path-benefits #main-wrapper .container-lg .tt-view-benefits_tiles:nth-child(2) .view-content.row, .path-benefits #main-wrapper .container-xl .tt-view-benefits_tiles:nth-child(2) .view-content.row {
      margin: 0px; }
      .path-benefits #main-wrapper .container-fluid .tt-view-benefits_tiles:nth-child(2) .view-content.row .views-row, .path-benefits #main-wrapper .container-sm .tt-view-benefits_tiles:nth-child(2) .view-content.row .views-row, .path-benefits #main-wrapper .container-md .tt-view-benefits_tiles:nth-child(2) .view-content.row .views-row, .path-benefits #main-wrapper .container-lg .tt-view-benefits_tiles:nth-child(2) .view-content.row .views-row, .path-benefits #main-wrapper .container-xl .tt-view-benefits_tiles:nth-child(2) .view-content.row .views-row {
        width: 100%; }
    @media (max-width: 991.98px) {
      .path-benefits #main-wrapper .container-fluid .tt-view-benefits_tiles:nth-child(4), .path-benefits #main-wrapper .container-sm .tt-view-benefits_tiles:nth-child(4), .path-benefits #main-wrapper .container-md .tt-view-benefits_tiles:nth-child(4), .path-benefits #main-wrapper .container-lg .tt-view-benefits_tiles:nth-child(4), .path-benefits #main-wrapper .container-xl .tt-view-benefits_tiles:nth-child(4) {
        margin: 8.5px 0px; } }
    .path-benefits #main-wrapper .container-fluid .brand-header > div:nth-child(2) h2, .path-benefits #main-wrapper .container-sm .brand-header > div:nth-child(2) h2, .path-benefits #main-wrapper .container-md .brand-header > div:nth-child(2) h2, .path-benefits #main-wrapper .container-lg .brand-header > div:nth-child(2) h2, .path-benefits #main-wrapper .container-xl .brand-header > div:nth-child(2) h2 {
      font-size: 40px;
      color: #2a2a2a;
      font-family: "sharpSans-Book";
      line-height: 48px;
      margin-top: 15px;
      font-weight: 770; }
    .path-benefits #main-wrapper .container-fluid .brand-header > div:nth-child(2) p, .path-benefits #main-wrapper .container-sm .brand-header > div:nth-child(2) p, .path-benefits #main-wrapper .container-md .brand-header > div:nth-child(2) p, .path-benefits #main-wrapper .container-lg .brand-header > div:nth-child(2) p, .path-benefits #main-wrapper .container-xl .brand-header > div:nth-child(2) p {
      font-size: 22px;
      color: #2a2a2a;
      margin-top: 10px;
      font-family: "SourceSansPro-Regular";
      line-height: 30px; }
    .path-benefits #main-wrapper .container-fluid .brand-dashboard-display-page, .path-benefits #main-wrapper .container-sm .brand-dashboard-display-page, .path-benefits #main-wrapper .container-md .brand-dashboard-display-page, .path-benefits #main-wrapper .container-lg .brand-dashboard-display-page, .path-benefits #main-wrapper .container-xl .brand-dashboard-display-page {
      height: 240px; }
      @media (max-width: 767.98px) {
        .path-benefits #main-wrapper .container-fluid .brand-dashboard-display-page, .path-benefits #main-wrapper .container-sm .brand-dashboard-display-page, .path-benefits #main-wrapper .container-md .brand-dashboard-display-page, .path-benefits #main-wrapper .container-lg .brand-dashboard-display-page, .path-benefits #main-wrapper .container-xl .brand-dashboard-display-page {
          height: auto; } }
    .path-benefits #main-wrapper .container-fluid .views-element-container, .path-benefits #main-wrapper .container-sm .views-element-container, .path-benefits #main-wrapper .container-md .views-element-container, .path-benefits #main-wrapper .container-lg .views-element-container, .path-benefits #main-wrapper .container-xl .views-element-container {
      padding: 0px;
      padding-bottom: 15px; }
    .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row .benefits-tiles-body {
      min-height: 295px; }
      @media (max-width: 991.98px) {
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row .benefits-tiles-body {
          min-height: auto; } }
      @media (max-width: 991.98px) {
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row .benefits-tiles-body .benefits-tiles-left {
          text-align: center; } }
    .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child {
      padding: 0 15px; }
      @media (max-width: 767.98px) {
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child {
          padding: 0px !important; } }
      .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body {
        margin: 0px;
        height: 295px;
        width: 100%;
        min-height: 100% !important; }
        @media (max-width: 767.98px) {
          .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body {
            padding: 25px 0px !important;
            height: auto; } }
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right {
          width: 100%;
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 75%;
          -ms-flex: 0 0 75%;
          flex: 0 0 100%;
          max-width: 100%; }
          .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2) {
            font-size: 70px;
            color: #000;
            font-family: "sharpSans-Bold";
            line-height: 70px; }
            @media (max-width: 1199.98px) {
              .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2) {
                font-size: 60px;
                line-height: 60px; } }
          .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3) {
            font-size: 70px;
            color: #fff;
            font-family: "sharpSans-Light";
            line-height: 70px;
            margin-top: -10px; }
            @media (max-width: 1199.98px) {
              .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3) {
                font-size: 60px;
                line-height: 60px;
                margin-top: 0; } }
          .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child {
            font-size: 26px;
            color: #fff;
            font-family: "sharpSans-Book";
            line-height: 36px; }
            @media (max-width: 1199.98px) {
              .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child {
                font-size: 26px;
                line-height: 36px; } }
    .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body {
      margin: 0px; }
      .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left {
        min-height: 140px;
        position: relative; }
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img {
          position: absolute;
          bottom: 19%;
          left: 35%; }
          @media (max-width: 991.98px) {
            .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img {
              left: 24%; } }
      .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right {
        min-height: 145px;
        padding: 0px 15px; }
        @media (max-width: 1199.98px) {
          .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right {
            min-height: 165px; } }
    .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row:first-child .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row:first-child .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row:first-child .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row:first-child .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row:first-child .benefits-tiles-left img {
      bottom: 23%; }
    .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body {
      margin: 0px; }
      .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left {
        min-height: 140px;
        position: relative; }
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img {
          position: absolute;
          bottom: 25%;
          left: 35%; }
          @media (max-width: 991.98px) {
            .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img {
              left: 24%; } }
      .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right {
        min-height: 145px;
        padding: 0px 15px; }
        @media (max-width: 1199.98px) {
          .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right {
            min-height: 165px; } }
    .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body {
      max-height: 285px; }
      @media (max-width: 1199.98px) {
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body {
          min-height: 305px; } }
      @media (max-width: 991.98px) {
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body {
          min-height: auto;
          padding: 30px 0px 26px; } }
      .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left {
        flex: 0 0 30%;
        max-width: 30%;
        text-align: center; }
        .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img {
          top: 3%;
          bottom: auto;
          left: 35%; }
      .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right {
        flex: 0 0 68%;
        max-width: 68%; }

@media screen and (max-width: 991px) {
  .path-benefits .benefits-tiles-top .benefits-tiles-left img {
    width: 70px; }
  .path-benefits .benefits-tiles-bottom .row-bottom-benefits .benefits-tiles-left {
    width: 18.5% !important; }
  .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-left {
    flex: 0 0 19%; } }
  @media screen and (max-width: 991px) and (max-width: 575.98px) {
    .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-left {
      flex: 0 0 38%; } }

@media screen and (max-width: 991px) {
  .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right {
    flex: 0 0 75%;
    max-width: 100%; } }
  @media screen and (max-width: 991px) and (max-width: 575.98px) {
    .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right {
      flex: 0 0 60%; } }

@media screen and (max-width: 991px) {
  .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right p {
    padding-right: 0px !important; }
  .body.path-benefits .brand-title .side-title {
    line-height: 2.8;
    margin-bottom: 20px; }
  .path-benefits .without-benefits-tiles-background .benefits-tiles-right {
    text-align: left; }
  .path-benefits .without-benefits-tiles-background .benefits-tiles-right p {
    margin-top: 0px; }
  .path-benefits .without-benefits-tiles-background {
    margin-bottom: 7.5px;
    margin-top: 7.5px; }
  .path-benefits .with-benefits-tiles-background {
    margin-top: 90px;
    margin-bottom: 7.5px; }
  .path-benefits .with-benefits-tiles-background .img-fluid {
    height: 253px; }
  .path-benefits .without-benefits-tiles-background .benefits-tiles-right strong {
    font-size: 17px !important; }
  .path-benefits .benefits-tiles-middle .benefits-tiles-left img, .path-benefits .benefits-tiles-bottom .benefits-tiles-left img {
    width: 70px !important;
    top: 25%;
    position: relative; }
  .path-benefits .without-benefits-tiles-background .benefits-tiles-body {
    padding: 15px; }
  .path-benefits .without-benefits-tiles-background .benefits-tiles-right {
    padding-left: 10px !important;
    padding-right: 10px !important; }
  .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right {
    padding-left: 0px !important; }
  .path-benefits .benefits-tiles-top .benefits-tiles-left img {
    margin-left: 0px; }
  .path-benefits .benefits-tiles-body.row {
    min-height: 151px; }
  .path-benefits .row-bottom-benefits .without-benefits-tiles-background .benefits-tiles-left {
    text-align: center; }
  .path-brands #main-wrapper .container-fluid-lg {
    padding: 0px 5px; }
  .path-benefits .benefits-tiles-top, .path-benefits .benefits-tiles-middle, .path-benefits .benefits-tiles-bottom {
    margin-bottom: 0px; } }

@media screen and (max-width: 767px) {
  .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row {
    min-height: 200px; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img {
    left: 20%; }
  .path-benefits .with-benefits-tiles-background .benefits-tiles-right h1 {
    font-size: 1.5rem;
    line-height: 1; }
  .path-benefits .benefits-title {
    font-size: 1.5rem; }
  .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .view-content > div, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .view-content > div, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .view-content > div, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .view-content > div, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .view-content > div {
    padding: 1px 6px; }
  .path-brands #main-wrapper .container-fluid .tt-view-brands:nth-child(3) .views-element-container.col-auto, .path-brands #main-wrapper .container-sm .tt-view-brands:nth-child(3) .views-element-container.col-auto, .path-brands #main-wrapper .container-md .tt-view-brands:nth-child(3) .views-element-container.col-auto, .path-brands #main-wrapper .container-lg .tt-view-brands:nth-child(3) .views-element-container.col-auto, .path-brands #main-wrapper .container-xl .tt-view-brands:nth-child(3) .views-element-container.col-auto {
    padding: 0px 10px; }
  .body-subtitle {
    margin-top: 10px; }
  .path-benefits #main-wrapper {
    margin-top: 12px; }
  .path-benefits .featured-top {
    margin-bottom: 11px; }
  .path-benefits #main-wrapper .container-fluid .brand-header > div:nth-child(2) h2, .path-benefits #main-wrapper .container-sm .brand-header > div:nth-child(2) h2, .path-benefits #main-wrapper .container-md .brand-header > div:nth-child(2) h2, .path-benefits #main-wrapper .container-lg .brand-header > div:nth-child(2) h2, .path-benefits #main-wrapper .container-xl .brand-header > div:nth-child(2) h2 {
    font-size: 26px !important; }
  .path-benefits #main-wrapper .container-fluid .brand-header > div:nth-child(2) p, .path-benefits #main-wrapper .container-sm .brand-header > div:nth-child(2) p, .path-benefits #main-wrapper .container-md .brand-header > div:nth-child(2) p, .path-benefits #main-wrapper .container-lg .brand-header > div:nth-child(2) p, .path-benefits #main-wrapper .container-xl .brand-header > div:nth-child(2) p {
    font-size: 18px !important; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .view-content.row, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .view-content.row, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .view-content.row, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .view-content.row, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .view-content.row, .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .view-content.row, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .view-content.row, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .view-content.row, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .view-content.row, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .view-content.row, .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .view-content.row, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .view-content.row, .path-benefits #main-wrapper .container-md .benefits-tiles-top .view-content.row, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .view-content.row, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .view-content.row {
    padding: 0;
    margin-left: -15px;
    margin-right: -15px; }
  .path-benefits .with-benefits-tiles-background {
    margin-top: 35px; }
  .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row .benefits-tiles-left, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row .benefits-tiles-left {
    width: 26%; }
  .path-benefits .benefits-tiles-body.row {
    min-height: 150px; }
  .path-benefits .benefits-tiles-middle .benefits-tiles-left img, .path-benefits .benefits-tiles-bottom .benefits-tiles-left img {
    /* margin-left: 11px;
		padding-bottom: 45px; */ }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2) {
    font-size: 47px !important;
    line-height: 47px !important; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:last-child {
    font-size: 18px !important;
    line-height: 30px !important; }
  .path-benefits .with-benefits-tiles-background .img-fluid {
    height: 204px; }
  .path-benefits .without-benefits-tiles-background .benefits-tiles-right strong {
    font-size: 18px !important; }
  /* .path-benefits .without-benefits-tiles-background {
		padding-top: 10px !important;
		padding-left: 10px !important;
	} */
  .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row {
    width: 100%; }
  .path-benefits .benefits-tiles-bottom .views-row:nth-child(2) .benefits-tiles-right, .path-benefits .benefits-tiles-bottom .views-row:nth-child(3) .benefits-tiles-right {
    padding-left: 10px !important; }
  .path-benefits .benefits-tiles-bottom .row-bottom-benefits .benefits-tiles-right p {
    padding: 0px; }
  .path-benefits .benefits-tiles-bottom .row-bottom-benefits .benefits-tiles-left img {
    margin-left: 20px; }
  .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row .benefits-tiles-left, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row .benefits-tiles-left {
    vertical-align: top;
    padding-top: 10px; }
  .path-benefits .without-benefits-tiles-background .benefits-tiles-body {
    padding: 21px; }
  .path-benefits .benefits-tiles-bottom .views-row:nth-child(2) .benefits-tiles-left img {
    margin-top: 16px; }
  .path-benefits .benefits-tiles-top .benefits-tiles-left img {
    margin-left: 20px; }
  .path-benefits .benefits-tiles-bottom .view-footer p {
    margin-top: 48px;
    margin-bottom: 48px; }
  .bottom-banner-section-sep .program-image-top {
    display: none; }
  .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-body {
    align-items: normal; }
    .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-body .benefits-tiles-left img {
      margin-top: 10px; }
    .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-body .benefits-tiles-right {
      margin-top: 10px; } }

@media only screen and (max-width: 961px) {
  .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row {
    text-align: center; }
    .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row .benefits-tiles-left, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row .benefits-tiles-left {
      display: table-cell;
      vertical-align: middle;
      width: 37%; }
    .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row .benefits-tiles-right, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row .benefits-tiles-right {
      display: table-cell;
      vertical-align: middle; } }

@media screen and (max-width: 680px) {
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-top .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .benefits-tiles-left img {
    margin-left: 0px; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left img {
    left: 3%; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left img {
    left: 3%; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img {
    left: 3%; }
  .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row {
    min-height: 230px; } }

@media screen and (max-width: 577px) {
  .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right {
    max-width: 70%; }
  .path-benefits .benefits-tiles-bottom .row-bottom-benefits .benefits-tiles-right {
    padding-left: 10px !important; }
  .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row {
    min-height: auto; }
  .path-benefits .with-benefits-tiles-background .img-fluid {
    height: 204px; }
  .path-benefits .benefits-tiles-bottom .row-bottom-benefits .benefits-tiles-left {
    width: 33% !important; }
  .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row .benefits-tiles-left, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row .benefits-tiles-left {
    width: 31%; }
  .path-benefits .benefits-tiles-top .without-benefits-tiles-background .benefits-tiles-right {
    padding-left: 10px !important; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(3), .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-sm .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-md .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-lg .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2), .path-benefits #main-wrapper .container-xl .benefits-tiles-top .views-row:first-child .benefits-tiles-body .benefits-tiles-right h2:nth-child(2) {
    font-size: 40px !important;
    line-height: 48px !important;
    font-weight: 750 !important; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-top .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-top .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-top .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-top .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-top .benefits-tiles-left img {
    top: 34%; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-left {
    min-height: auto; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-left img {
    top: 33%; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right {
    min-height: auto; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:nth-child(2) .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:nth-child(2) .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:nth-child(2) .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:nth-child(2) .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:nth-child(2) .benefits-tiles-left img {
    margin-top: 0px; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body {
    max-height: 437px; }
    .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left {
      flex: 0 0 34%;
      max-width: 34%; }
      .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img {
        top: 34%; }
    .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right {
      flex: 0 0 66%;
      max-width: 66%; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-right p:last-child, .path-benefits #main-wrapper .container-sm .benefits-tiles-right p:last-child, .path-benefits #main-wrapper .container-md .benefits-tiles-right p:last-child, .path-benefits #main-wrapper .container-lg .benefits-tiles-right p:last-child, .path-benefits #main-wrapper .container-xl .benefits-tiles-right p:last-child {
    margin-bottom: 0px; } }

@media (min-width: 576px) and (max-width: 767px) {
  .path-benefits .tt-view-benefits_tiles:nth-child(4) .benefits-tiles-body.row, .path-benefits .tt-view-benefits_tiles:nth-child(5) .benefits-tiles-body.row {
    min-height: 327px; }
  .path-benefits .benefits-tiles-middle .views-row:nth-child(1), .path-benefits .benefits-tiles-middle .views-row:nth-child(3) {
    padding-right: 7.5px !important; }
  .path-benefits .benefits-tiles-middle .views-row:nth-child(2), .path-benefits .benefits-tiles-middle .views-row:nth-child(4) {
    padding-left: 7.5px !important; }
  .path-benefits .benefits-tiles-bottom .views-row:nth-child(2) {
    padding-right: 7.5px !important; }
  .path-benefits .benefits-tiles-bottom .views-row:last-child {
    padding-left: 7.5px !important; } }

@media (max-width: 767px) {
  .path-benefits .view-bottom-banner-section .program-img-container .program-image-bottom {
    width: 100% !important; } }

@media (min-width: 768px) and (max-width: 992px) {
  .path-benefits .view-bottom-banner-section .program-img-container {
    max-height: 270px;
    min-height: 270px; }
    .path-benefits .view-bottom-banner-section .program-img-container .program-image-top {
      width: 64%; }
    .path-benefits .view-bottom-banner-section .program-img-container .program-image-bottom {
      width: 36%; }
  .path-benefits .benefits-tiles-bottom .view-footer p {
    margin-bottom: 48px; }
  .path-benefits .benefits-tiles-bottom .views-row {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .path-benefits .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left {
    flex: 0 0 17% !important;
    max-width: 17% !important; }
    .path-benefits .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-left img {
      top: 25% !important; }
  .path-benefits .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body .benefits-tiles-right {
    flex: 0 0 82% !important;
    max-width: 82% !important; }
  .path-benefits .benefits-tiles-middle .views-row {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .path-benefits .benefits-tiles-top .with-benefits-tiles-background img {
    object-fit: cover; }
  .path-benefits .benefits-tiles-top .views-row {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .path-benefits .benefits-tiles-last .with-benefits-tiles-background img {
    object-fit: cover; }
  .path-benefits .benefits-tiles-last .views-row {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .path-benefits .container-fluid .tt-view-benefits_tiles:nth-child(4), .path-benefits .container-sm .tt-view-benefits_tiles:nth-child(4), .path-benefits .container-md .tt-view-benefits_tiles:nth-child(4), .path-benefits .container-lg .tt-view-benefits_tiles:nth-child(4), .path-benefits .container-xl .tt-view-benefits_tiles:nth-child(4) {
    margin: 5.5px 0px !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-middle .views-row .benefits-tiles-body .benefits-tiles-right {
    min-height: 208px; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row .benefits-tiles-body .benefits-tiles-right {
    min-height: 177px; }
  .path-benefits #main-wrapper .container-fluid .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-sm .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-md .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-lg .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body, .path-benefits #main-wrapper .container-xl .benefits-tiles-bottom .views-row:first-child .benefits-tiles-body {
    min-height: 316px; }
  .path-benefits #main-wrapper .with-benefits-tiles-background .img-fluid {
    height: 346px; } }

/* new benefits design changes css */
.benefits-col-list {
  overflow: hidden; }
  .benefits-col-list .benefits-list-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .benefits-col-list .benefits-main-content {
    position: initial;
    margin-bottom: 20px;
    cursor: pointer; }
  .benefits-col-list .benefits-main-col {
    position: relative;
    display: flex;
    align-items: center;
    background: #FBFBFB;
    padding: 20px 45px 20px 20px;
    background-image: url("../images/plus-default.png");
    background-position: right 20px top 30px;
    background-repeat: no-repeat;
    border-bottom: 2px solid transparent; }
    .benefits-col-list .benefits-main-col:hover {
      background-color: #F9F7FC;
      background-image: url("../images/plus-hover.png");
      border-bottom: 2px solid #7F679F; }
    .benefits-col-list .benefits-main-col .benefits-col-title {
      font-size: 20px;
      line-height: 26px;
      font-family: "sharpSans-Semibold";
      color: #2A2A2A; }
    .benefits-col-list .benefits-main-col .benefits-col-icon {
      margin-right: 20px; }
      .benefits-col-list .benefits-main-col .benefits-col-icon .default-benefits-icon {
        display: block; }
      .benefits-col-list .benefits-main-col .benefits-col-icon .active-benefits-icon {
        display: none; }
  .benefits-col-list .benefits-main-col.active {
    background-color: #7F679F;
    background-image: url("../images/minus-icon.png");
    border: 0px solid #7F679F; }
    .benefits-col-list .benefits-main-col.active .benefits-col-title {
      color: #fff; }
    .benefits-col-list .benefits-main-col.active .benefits-col-icon {
      margin-right: 20px; }
      .benefits-col-list .benefits-main-col.active .benefits-col-icon .active-benefits-icon {
        display: block; }
      .benefits-col-list .benefits-main-col.active .benefits-col-icon .default-benefits-icon {
        display: none; }
  .benefits-col-list .benefits-col-descriptions {
    position: relative;
    width: 1080px;
    display: none;
    left: 103%;
    transform: translateX(-50%); }
    .benefits-col-list .benefits-col-descriptions p {
      font-size: 16px;
      font-family: "SourceSansPro-Regular";
      color: #2A2A2A;
      line-height: 24px;
      padding: 60px 20px; }

.benefits-main-content:nth-child(even) .benefits-col-descriptions {
  left: 97%;
  margin-left: -100%; }

@media screen and (min-width: 992px) and (max-width: 1140px) {
  .benefits-col-list .benefits-col-descriptions {
    width: 900px; } }

@media only screen and (max-width: 991.98px) {
  .benefits-col-list .benefits-col-descriptions {
    width: 100%;
    left: initial;
    transform: initial; }
    .benefits-col-list .benefits-col-descriptions p {
      padding: 44px 24px 24px 24px; }
  .benefits-main-content:nth-child(even) .benefits-col-descriptions {
    left: initial;
    margin-left: 0; } }

.path-benefits #main-wrapper .container-fluid .brand-dashboard-display-page, .path-benefits #main-wrapper .container-sm .brand-dashboard-display-page, .path-benefits #main-wrapper .container-md .brand-dashboard-display-page, .path-benefits #main-wrapper .container-lg .brand-dashboard-display-page, .path-benefits #main-wrapper .container-xl .brand-dashboard-display-page {
  height: auto;
  min-height: 240px; }

/* FAQ */
/* Intern Possibilities Component Start*/
.path-faq .intern-possibilities-main-wrapper {
  margin-top: 50px;
  padding: 0 100px; }
  .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .section_title {
    margin-top: 0px;
    padding-top: 0px; }
  .path-faq .intern-possibilities-main-wrapper .mobile-display {
    display: none; }
  @media (max-width: 991.98px) {
    .path-faq .intern-possibilities-main-wrapper {
      padding: 0px;
      margin-top: 0px; } }
  .path-faq .intern-possibilities-main-wrapper h1 {
    text-align: center;
    margin-bottom: 30px;
    font-family: "sharpSans-Book"; }
    @media (max-width: 991.98px) {
      .path-faq .intern-possibilities-main-wrapper h1 {
        padding: 30px 0;
        margin: 0;
        text-align: left;
        border-bottom: 1px solid #eaeaea; } }
    .path-faq .intern-possibilities-main-wrapper h1 .popup-overlay {
      position: relative !important;
      min-height: 780px;
      width: 100%;
      z-index: 99 !important;
      background: rgba(255, 255, 255, 0.6) !important;
      display: block !important; }
  .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper {
    position: relative; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .ip-overlay {
      position: absolute;
      top: 50%;
      left: 50%; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .view-careers-resources-tiles .view-content.row {
      display: block; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup {
      min-height: 550px;
      padding: 0 !important;
      width: 100% !important;
      background: #eeeeee !important;
      border: none !important;
      top: 0; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .close {
        position: absolute;
        right: 60px;
        top: 40px; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility a:hover {
      color: #2A2A2A;
      text-decoration: none; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .modal-dialog {
      max-width: 100%; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities.overlay-show {
      opacity: 0.1; }
    @media (max-width: 1199.98px) {
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .row {
        margin: 0px; } }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility {
      margin-bottom: 20px;
      overflow: hidden; }
      @media (max-width: 991.98px) {
        .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility {
          margin-bottom: 0px;
          padding: 0px; } }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box {
        cursor: pointer;
        height: 174px;
        background: #eeeeee; }
        .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box .title {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 20px;
          font-family: "sharpSans-Semibold";
          padding: 0 30px; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box::after {
        content: "";
        background: #F36A30;
        width: 93%;
        height: 12px;
        position: absolute;
        bottom: 0px;
        -webkit-transform: translateY(6px);
        -o-transform: translateY(6px);
        transform: translateY(6px);
        -webkit-transition-property: transform;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        -o-transition-property: -o-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform, -o-transform;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        -o-transition-timing-function: ease-out;
        transition-timing-function: ease-out; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box:hover:after {
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .accordion__body--hidden {
        display: none; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body {
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 30px; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .title {
        color: #009DD6;
        font-size: 30px;
        margin-top: 20px; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .description {
        font-size: 16px; }
        .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .description .light-blue {
          color: #009DD6; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content h5 {
        color: #009DD6; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .logo1 img,
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .logo2 img {
        float: left; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body.accordion__body--hidden {
      border: none; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .title {
      color: #F36A30;
      font-size: 30px;
      margin-top: 100px;
      padding: 0 200px 0 200px; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .description {
      font-size: 16px;
      padding: 0 200px 0 200px; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .description .light-blue {
        color: #009DD6; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .description strong {
        font-family: "SourceSansPro-Semibold";
        font-weight: normal; }
    .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content h5 {
      color: #F36A30; }
    @media (min-width: 992px) {
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .logo1,
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .logo2 {
        height: 50%; }
        .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .logo1 .imageloader,
        .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .logo2 .imageloader {
          height: 100%; }
      .path-faq .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .path-faq .intern-possibilities-main-wrapper .modal-dialog {
    position: relative !important;
    min-height: 780px;
    width: 100%;
    z-index: 99 !important; }
    .path-faq .intern-possibilities-main-wrapper .modal-dialog .modal-content {
      min-height: 780px;
      padding: 0 !important;
      width: 100% !important;
      background: #eeeeee !important;
      border: none !important;
      top: 0; }
      .path-faq .intern-possibilities-main-wrapper .modal-dialog .modal-content .modal2 {
        min-height: 780px; }
        .path-faq .intern-possibilities-main-wrapper .modal-dialog .modal-content .modal2 .content {
          min-height: 780px; }
        .path-faq .intern-possibilities-main-wrapper .modal-dialog .modal-content .modal2 .close {
          top: 45px;
          right: 45px;
          background: unset;
          position: relative; }
      @media (min-width: 992px) {
        .path-faq .intern-possibilities-main-wrapper .modal-dialog .modal-content img {
          width: 100%;
          height: auto;
          min-height: 390px;
          object-fit: cover; } }

.path-faq .testimonial-career-section .cont-hide {
  display: none; }

.path-faq .testimonial-career-section .cont-right .name-value {
  font-size: 22px;
  line-height: 26px;
  font-family: "sharpSans-Semibold";
  color: #2a2a2a;
  padding-bottom: 10px; }

.path-faq .testimonial-career-section .cont-right .desig-value {
  color: #2a2a2a;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 30px;
  font-family: "SourceSansPro-Semibold"; }

.path-faq .testimonial-career-section .cont-right .desc-value {
  color: #2a2a2a;
  font-size: 16px;
  line-height: 24px;
  font-family: "sharpSans-Book"; }

.path-faq .slick-prev,
.path-faq .slick-next {
  font-size: 15px !important;
  margin: 0px 15px; }

.path-faq .slick-prev:before,
.path-faq .slick-next:before {
  content: '' !important; }

.path-faq .slick-arrow {
  border-radius: 0px !important;
  height: 20px;
  width: 21px;
  z-index: 1;
  display: none;
  border: none;
  float: left; }

.path-faq .carousel-control-next-icon,
.path-faq .carousel-control-prev-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: block;
  background-size: 100% 100% !important;
  background-repeat: no-repeat; }

.path-faq .carousel-control-next-icon {
  background-image: url(../images/icons/carousel-right.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-faq .carousel-control-prev-icon {
  background-image: url(../images/icons/carousel-left.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-faq .carousel-control-next-icon,
.path-faq .carousel-control-prev-icon {
  border: 1px solid #FFF;
  background-color: transparent; }

.path-faq .slick-slider .slick-list {
  width: 95% !important;
  margin: auto; }
  .path-faq .slick-slider .slick-list .slick-slide .slide__content .img-cover {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 0 auto; }
    @media (max-width: 767.98px) {
      .path-faq .slick-slider .slick-list .slick-slide .slide__content .img-cover {
        width: 180px;
        height: 180px; } }
    .path-faq .slick-slider .slick-list .slick-slide .slide__content .img-cover img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.path-faq .slick__arrow {
  top: 35%; }

.path-faq .bg-img-text-career {
  background-image: url("../images/svg/Typography_Art_Gray_Verylight.png");
  /*background-size: 70% 75%;*/
  background-size: contain;
  background-position: right 0% bottom 0%;
  background-repeat: no-repeat;
  z-index: 4;
  position: relative;
  /*@include media-breakpoint-down(sm){
				background-size: 100% 42%;
			}*/ }

.path-faq .early-carrer-section .img-cover,
.path-faq .more-job-opp-section .img-cover {
  position: relative; }
  .path-faq .early-carrer-section .img-cover a:hover,
  .path-faq .more-job-opp-section .img-cover a:hover {
    text-decoration: none; }
  .path-faq .early-carrer-section .img-cover a,
  .path-faq .more-job-opp-section .img-cover a {
    color: #2a2a2a; }

.path-faq .early-carrer-section .img-cover:before,
.path-faq .early-carrer-section .img-cover:after,
.path-faq .more-job-opp-section .img-cover:before,
.path-faq .more-job-opp-section .img-cover:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 19px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #EEE;
  -webkit-box-shadow: 0 16px 16px #777;
  box-shadow: 0 16px 16px #777;
  -webkit-transform: rotate(-4deg);
  -o-transform: rotate(-4deg);
  transform: rotate(-4deg); }

.path-faq .early-carrer-section .img-cover:after,
.path-faq .more-job-opp-section .img-cover:after {
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  -o-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
  right: 10px;
  left: auto; }

.path-faq .early-carrer-section .img-cont:hover .img-cover:before,
.path-faq .early-carrer-section .img-cont:hover .img-cover:after,
.path-faq .more-job-opp-section .img-cont:hover .img-cover:before,
.path-faq .more-job-opp-section .img-cont:hover .img-cover:after {
  -webkit-box-shadow: 0 20px 11px #777;
  -moz-box-shadow: 0 20px 11px #777;
  box-shadow: 0 20px 11px #777; }

.path-faq .input-lg::-ms-clear {
  display: none; }

.path-faq #main-wrapper .tt-view-faq_accordion:nth-child(2) .views-row.carousel-desktop {
  padding: 0px; }
  .path-faq #main-wrapper .tt-view-faq_accordion:nth-child(2) .views-row.carousel-desktop #carousel_FAQ ul li:hover {
    opacity: 1; }
    .path-faq #main-wrapper .tt-view-faq_accordion:nth-child(2) .views-row.carousel-desktop #carousel_FAQ ul li:hover .focused_img {
      display: block; }
    .path-faq #main-wrapper .tt-view-faq_accordion:nth-child(2) .views-row.carousel-desktop #carousel_FAQ ul li:hover .unfocused_img {
      display: none; }

.path-faq #main-wrapper .tt-view-faq_accordion:last-child .views-row {
  width: 100%; }

.path-faq #main-wrapper #faqs-react-main-wrapper-page-program {
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    .path-faq #main-wrapper #faqs-react-main-wrapper-page-program {
      padding: 0px 20px; } }

.path-faq #main-wrapper #faqs-react-main-wrapper-faq-page {
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    .path-faq #main-wrapper #faqs-react-main-wrapper-faq-page {
      padding: 0px 20px; } }

.path-faq #main-wrapper .carouselParent {
  background: #F7F7F7; }
  .path-faq #main-wrapper .carouselParent .carouselInner {
    max-width: 1240px;
    margin: 0 auto;
    position: relative; }

.path-faq #main-wrapper #carosalHeading0 {
  margin-top: 51px; }
  @media (max-width: 767.98px) {
    .path-faq #main-wrapper #carosalHeading0 {
      margin-top: 0px; } }
  .path-faq #main-wrapper #carosalHeading0 img {
    height: 80px; }
    @media (max-width: 767.98px) {
      .path-faq #main-wrapper #carosalHeading0 img {
        width: 166px; } }

.path-faq #main-wrapper #carosalHeading1 {
  height: 151px; }
  @media (max-width: 767.98px) {
    .path-faq #main-wrapper #carosalHeading1 {
      height: auto; } }
  .path-faq #main-wrapper #carosalHeading1 img {
    height: 131px; }
    @media (max-width: 767.98px) {
      .path-faq #main-wrapper #carosalHeading1 img {
        width: 163px; } }

.path-faq #main-wrapper #carosalHeading2 {
  height: 145px;
  margin-top: 9px; }
  @media (max-width: 767.98px) {
    .path-faq #main-wrapper #carosalHeading2 {
      height: auto;
      margin-top: 0px; } }
  .path-faq #main-wrapper #carosalHeading2 img {
    height: 122px; }
    @media (max-width: 767.98px) {
      .path-faq #main-wrapper #carosalHeading2 img {
        width: 163px; } }

.path-faq #main-wrapper #carosalHeading3 {
  margin-top: 29px;
  height: 120px; }
  @media (max-width: 767.98px) {
    .path-faq #main-wrapper #carosalHeading3 {
      height: auto;
      margin-top: 0px; } }
  .path-faq #main-wrapper #carosalHeading3 img {
    height: 102px; }
    @media (max-width: 767.98px) {
      .path-faq #main-wrapper #carosalHeading3 img {
        width: 163px; } }

.path-faq .site-footer {
  margin-top: 75px !important; }

.carousel-indicators {
  position: relative;
  margin-top: 6%;
  justify-content: left;
  padding: 0px;
  margin: 0 auto; }

.carousel-indicators li {
  text-indent: 0;
  height: 100px;
  border: none;
  padding: 0px;
  margin: 0px;
  width: 25% !important; }

.carousel-desktop h2.faq_title {
  padding-bottom: 10px; }

.carousel-control-next,
.carousel-control-prev {
  width: 80px;
  top: 0px !important; }
  @media (max-width: 991.98px) {
    .carousel-control-next,
    .carousel-control-prev {
      display: none; } }

.carousel-indicators li.active span:after {
  content: '';
  position: absolute;
  bottom: -45px;
  left: 43%;
  width: 35px;
  height: 5px;
  background: #F36A30;
  display: block; }
  @media (max-width: 991.98px) {
    .carousel-indicators li.active span:after {
      left: 40%;
      width: 37px; } }

.carousel-indicators li span {
  font-size: 22px;
  text-align: center;
  padding-top: 14px;
  font-family: "sharpSans-Medium";
  color: #2A2A2A;
  display: block; }

.carousel-inner {
  width: 90%;
  margin: auto;
  margin-top: 6%;
  background-color: #F7F7F7;
  padding-bottom: 3%;
  padding-top: 48px;
  margin-bottom: 90px;
  min-height: 220px; }
  @media (max-width: 991.98px) {
    .carousel-inner {
      width: 100%;
      padding: 15px 20px 0px;
      margin-top: 85px; } }

.carousel-item p {
  font-size: 18px;
  color: #2A2A2A;
  margin-bottom: 0px;
  font-family: "SourceSansPro-Regular"; }
  @media (max-width: 991.98px) {
    .carousel-item p {
      padding-left: 0px; } }

.carousel-title h3 {
  font-size: 32px;
  color: #2A2A2A;
  font-family: "sharpSans-Medium"; }
  @media (max-width: 991.98px) {
    .carousel-title h3 {
      text-align: center; } }

span.step-color {
  color: #F36A30;
  font-size: 32px;
  font-family: "sharpSans-Semibold" !important; }
  @media (max-width: 767.98px) {
    span.step-color {
      font-size: 18px; } }

.carousel-item .carousel-title {
  margin-top: 40px; }

.carousel-item .carousel-description {
  padding-right: 5%;
  margin-top: 10px;
  border-left: 1px solid; }
  @media (max-width: 991.98px) {
    .carousel-item .carousel-description {
      padding-right: 20px;
      margin-top: 20px;
      border-left: none; } }

.view-faq h2.faq_title,
.section_title {
  text-align: center;
  margin-top: 15px;
  padding-top: 55px;
  width: 100%;
  font-size: 40px;
  font-family: "sharpSans-Book";
  color: #2A2A2A;
  margin-bottom: 60px; }
  @media (max-width: 991.98px) {
    .view-faq h2.faq_title,
    .section_title {
      font-size: 26px;
      margin-bottom: 10px; } }

div#accordion {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%; }

.cat_title,
.accordion-toggle {
  color: #F36A30; }

.second_img img {
  width: 100%;
  object-fit: cover;
  padding: 0px; }

.second_img {
  padding: 0px;
  height: auto !important;
  max-height: 520px;
  overflow: hidden;
  height: auto; }

.second_content {
  background-color: #F36A30;
  padding: 0px; }

li.card .focused_img,
li.card .unfocused_img {
  width: 100%; }

li.card .focused_img {
  display: none; }

li.card.active .focused_img {
  display: block; }

li.card.active .unfocused_img {
  display: none; }

.secondary_banner_section {
  margin-top: 77px;
  margin-bottom: 20px; }
  .secondary_banner_section .second_content_div .learn-more .btn:hover {
    color: #f36e37; }
  .secondary_banner_section .second_content_div .learn-more a {
    border-radius: 0;
    margin-top: 20px; }
    .secondary_banner_section .second_content_div .learn-more a span {
      margin: 5px; }

#accordion .card-header {
  background-color: #fff; }

#accordion .accordion-title-box .accordion_title {
  cursor: pointer; }

#accordion .accordion-title-box:last-child .accordion_title,
#accordion .accordion-title-box:last-child .accordion-item-body {
  border-bottom: none; }

.accordion-toggle.collapsed {
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important; }

#accordion .card-body {
  border-bottom: 1px solid #ff6600; }

/* #accordion .accordion-toggle:before {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: grey;
}
#accordion .accordion-toggle[aria-expanded=true]:before {
    font-family: 'Glyphicons Halflings';
    content: "\e080";
    float: right;
    color: grey;
} */
.second_content .learn-more a {
  padding: 6.5px 10px 6.5px 10px;
  font-size: 18px;
  line-height: 30px;
  color: #2A2A2A;
  font-family: "sharpSans-Semibold";
  letter-spacing: 0.5px; }

.second_content .learn-more a i {
  padding-left: 10px;
  font-size: 16px;
  color: #2A2A2A; }

.second_content p.descr {
  color: #fff;
  font-size: 18px;
  font-family: "SourceSansPro-Regular";
  line-height: 30px;
  overflow: hidden; }
  @media (min-width: 992px) {
    .second_content p.descr {
      max-height: 180px; } }
  @media (min-width: 1200px) {
    .second_content p.descr {
      max-height: 210px; } }

.second_content .second_content_div {
  padding: 40px 40px 40px 80px; }

.second_content .second_content_div h2 strong {
  font-family: "sharpSans-Bold";
  font-size: 60px; }

.second_content .second_content_div h2.white {
  font-size: 60px;
  font-family: "sharpSans-Light";
  line-height: 60px;
  margin-top: -10px; }

.faq-search {
  text-align: center;
  margin-top: 3%;
  margin-bottom: 3%; }

.faq_accordion_title {
  text-align: center; }

.FAQs-title {
  text-align: center !important; }

#faq-search-input,
.second_content .learn-more a {
  /*     border-top: 0;
    border-left: 0;
    border-right: 0;
    background-image: url(../images/icons/search.png) !important;
    background-position: 80%;
    background-repeat: no-repeat;
    -webkit-background-size: 25px 25px;
    background-size: 20px;
	text-decoration: none; */ }

.align-center {
  text-align: center; }

#accordionmobile .card-body {
  background-color: #eaeaea;
  line-height: 24px; }

#accordionmobile .card-header {
  background-color: #fff;
  padding-top: 35px; }
  @media (max-width: 767.98px) {
    #accordionmobile .card-header {
      padding: 0px; } }
  #accordionmobile .card-header a {
    color: inherit; }
    @media (max-width: 767.98px) {
      #accordionmobile .card-header a {
        padding: 35px 20px 12px;
        text-decoration: none; } }
    #accordionmobile .card-header a:hover {
      color: inherit; }

#accordionmobile .accordion_step .focused_img {
  display: block;
  margin: auto; }

#accordionmobile .accordion_step .unfocused_img {
  display: none; }

#accordionmobile .accordion_step.collapsed .focused_img {
  display: none; }

#accordionmobile .accordion_step.collapsed .unfocused_img {
  display: block;
  margin: auto; }

#accordionmobile .collapse.show {
  border-bottom: 2px solid #f28658; }

.faqs-main-wrapper .accordion-title-box .item-title-hidden {
  border-bottom: none !important; }
  .faqs-main-wrapper .accordion-title-box .item-title-hidden h3 {
    border-bottom: 1px solid rgba(119, 136, 153, 0.5) !important; }

.faqs-main-wrapper .accordion-title-box:last-child .item-title-hidden h3 {
  border-bottom: none !important; }

.faqs-main-wrapper .accordion__button[aria-expanded='true'] h3 {
  background: url("../images/resources/up-arrow.png") no-repeat scroll !important;
  font-size: 18px !important;
  position: relative !important;
  padding: 25px 0 35px !important;
  -webkit-background-size: 20px 20px !important;
  background-size: 20px 20px !important;
  background-position: 99.7% 27px !important;
  margin-bottom: 0 !important;
  padding-right: 35px !important;
  color: #f36a30 !important;
  border-bottom: none !important; }

.faqs-main-wrapper .accordion__button[aria-expanded='true'] > .faqs-main-wrapper .item-title-hidden {
  border-bottom: none !important; }

@media only screen and (min-width: 992px) {
  body.path-faq .more-job-opp-section .title-value {
    text-align: center;
    margin-bottom: 40px; }
  body.path-faq .sidelink a:hover {
    color: #D0118C;
    text-decoration: none; }
  body.path-faq .testimonial-career-section .desktop-display {
    display: block; }
  body.path-faq .intern-possibility .accordion-title.item-title-hidden:hover {
    border-bottom: 1px solid #eaeaea;
    margin: 0px auto !important;
    padding: 15px 0;
    -webkit-background-size: 18px 12px;
    background-size: 18px 12px;
    background-position: 99.7% 20px; }
  .node--type-careers-article #block-contentfield-2 .img-cover {
    height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover .img-cover-left {
      float: left;
      width: 67%;
      height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover .img-cover-right {
      float: right;
      width: 33%;
      height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover img {
      width: 100%;
      height: 100%; }
  .node--type-careers-article #block-contentfield-2 .fetured-bottom-cont {
    margin-top: 1%; }
  .node--type-careers-article .custom-nav-height {
    margin-top: 140px; } }

@media screen and (max-width: 767px) {
  .accordionmobile {
    display: block; }
  .carousel-desktop {
    display: none; }
  .path-faq .faqs-wrapper {
    margin-top: 70px; }
  .path-faq .accordionmobile .accordion_step p {
    font-size: 18px;
    margin-top: 15px; }
    .path-faq .accordionmobile .accordion_step p .fa-chevron-down,
    .path-faq .accordionmobile .accordion_step p .fa-chevron-up {
      float: right;
      margin-top: 4px;
      font-size: 20px;
      color: #a7a9ac; }
    .path-faq .accordionmobile .accordion_step p .fa-chevron-up {
      color: #F06E37; }
  .path-faq .testimonial-career-section .mobile-display {
    display: block; }
    .path-faq .testimonial-career-section .mobile-display .slick__arrow {
      /** display:none; **/ }
    .path-faq .testimonial-career-section .mobile-display .cont-right {
      text-align: center;
      margin-top: 7%; } }

@media screen and (min-width: 768px) {
  .accordionmobile {
    display: none; }
  .carousel-desktop {
    display: block; } }

.white {
  color: #fff; }

.carousel-control-next-icon,
.carousel-control-prev-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: block;
  background-size: 100% 100% !important;
  background-repeat: no-repeat; }

.carousel-control-next-icon {
  background-image: url(../images/icons/carousel-right.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.carousel-control-next-icon:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

.carousel-control-prev-icon {
  background-image: url(../images/icons/carousel-left.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.carousel-control-prev-icon:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

#accordion .card-header .accordion-toggle.collapsed {
  background-image: url("../images/icons/down-arrow.png");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 50px; }

#accordion .card-header .accordion-toggle {
  background-image: url("../images/icons/up-arrow.png");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 50px; }

.faq_content {
  margin: 0px auto;
  width: 71%; }

@media (min-width: 1101px) and (max-width: 1350px) {
  .path-faq .second_content .second_content_div .text-black {
    line-height: 45px; } }

@media (min-width: 993px) and (max-width: 1100px) {
  .path-faq .second_content .second_content_div {
    padding: 20px 40px 20px 80px; }
    .path-faq .second_content .second_content_div .text-black {
      line-height: 46px; } }

@media (min-width: 768px) and (max-width: 992px) {
  .path-faq .carousel-item .carousel-description {
    padding-bottom: 45px; }
  .path-faq .faqs-main-wrapper .accordion-title h3 {
    line-height: 30px; }
  .path-faq .testimonial-career-section .ipad-display {
    display: block; } }

@media only screen and (max-width: 991px) {
  .intern-possibilities-wrapper .intern-possibilities.desktop-display {
    display: none; }
  .intern-possibilities-wrapper .intern-possibilities.mobile-display {
    display: block; }
  .intern-possibilities-wrapper .accordion-item-body {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 30px; }
  .intern-possibility .accordion-title {
    outline: 0; }
    .intern-possibility .accordion-title .fa-chevron-down {
      font-size: 20px;
      position: absolute;
      right: 0;
      top: 15px; }
      .intern-possibility .accordion-title .fa-chevron-down:before {
        content: "\f077"; } }
  @media only screen and (max-width: 991px) and (max-width: 991.98px) {
    .intern-possibility .accordion-title {
      border-bottom: none;
      margin: 0px auto !important;
      padding: 15px 0;
      background-size: 18px 12px;
      background-position: 99.7% 20px; } }

@media only screen and (max-width: 991px) and (max-width: 991.98px) {
  .intern-possibility .accordion-title.item-title-hidden:hover {
    border-bottom: 1px solid #eaeaea;
    margin: 0px auto !important;
    padding: 15px 0;
    background-size: 18px 12px;
    background-position: 99.7% 20px; } }

@media only screen and (max-width: 991px) {
  .intern-possibility .accordion-title.item-title-hidden .fa-chevron-down {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 15px;
    color: inherit !important; }
    .intern-possibility .accordion-title.item-title-hidden .fa-chevron-down:before {
      content: "\f078"; } }

@media only screen and (max-width: 991px) and (max-width: 991.98px) {
  .intern-possibility .accordion-title.item-title-hidden {
    border-bottom: 1px solid #eaeaea;
    margin: 0px auto !important;
    padding: 15px 0;
    background-size: 18px 12px;
    background-position: 99.7% 20px; } }

/**
 * @file
 * Styles for Bootstrap Barrio page.
 */
/* Home page slider styles */
#block-nbcucareers-footer, #block-footermenu3 {
  padding: 0px; }

#block-nbcucareers-footer ul li, #block-footermenu3 ul li {
  padding-left: 0px; }

@media (max-width: 767.98px) {
  #block-nbcucareers-footer ul li.nav-item,
  #block-footermenu3 ul li.nav-item {
    height: 52px; } }

#block-nbcucareers-footer ul li.nav-item:nth-child(7),
#block-footermenu3 ul li.nav-item:nth-child(7) {
  height: 30px; }
  @media (max-width: 1199.98px) {
    #block-nbcucareers-footer ul li.nav-item:nth-child(7),
    #block-footermenu3 ul li.nav-item:nth-child(7) {
      height: 52px; } }
  @media (max-width: 991.98px) {
    #block-nbcucareers-footer ul li.nav-item:nth-child(7),
    #block-footermenu3 ul li.nav-item:nth-child(7) {
      height: 30px; } }

@media (max-width: 767.98px) {
  #block-nbcucareers-footer ul li.nav-item,
  #block-footermenu3 ul li.nav-item {
    height: 52px !important; } }

@media (min-width: 768px) {
  #block-nbcucareers-footer ul {
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    height: auto;
    /* account for borders */ }
  #block-nbcucareers-footer li,
  #block-footermenu3 li {
    height: 32px;
    min-width: 188px; } }
  @media (min-width: 768px) and (min-width: 992px) {
    #block-nbcucareers-footer li,
    #block-footermenu3 li {
      min-width: 200px; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    #block-nbcucareers-footer li,
    #block-footermenu3 li {
      min-width: 180px; } }

#block-footermenu2 {
  padding: 0px; }
  @media (max-width: 767.98px) {
    #block-footermenu2 > ul > li {
      margin-bottom: 15px; }
      #block-footermenu2 > ul > li:last-child {
        margin-bottom: 0; } }
  #block-footermenu2 ul li {
    padding-left: 0px; }
    #block-footermenu2 ul li ul {
      padding: 0px; }
      #block-footermenu2 ul li ul li {
        padding-left: 0px;
        list-style-type: none;
        display: inline-block; }
        #block-footermenu2 ul li ul li a.pipe-link:before {
          content: '|';
          padding-left: 1px;
          padding-right: 4px; }
        #block-footermenu2 ul li ul li a {
          padding: 0px; }

.site-footer .copyright {
  text-align: right;
  padding-top: 8px; }
  @media (max-width: 991.98px) {
    .site-footer .copyright {
      text-align: left;
      padding-left: 0px; } }

#block-nbcucareers-content .slider-content-wrapper {
  width: 100%; }

#header .copyright {
  display: none; }

.main-content {
  padding: 0px; }

.slider-main-wrapper {
  padding-top: 80px !important; }

@media (max-width: 767.98px) {
  .styles_modal__gNwvD {
    width: 95%; } }

.path-home .img-fluid {
  height: 100%; }

.path-home .featured-top {
  margin-bottom: 0px; }
  .path-home .featured-top .field--name-field-banner-image img {
    margin-bottom: 0px; }

.path-home .modal-backdrop.show {
  opacity: 0.9; }

.path-home .styles_overlay__CLSq- {
  display: block; }
  .path-home .styles_overlay__CLSq- .styles_modal__gNwvD {
    margin-top: 112px; }
    @media (max-width: 767.98px) {
      .path-home .styles_overlay__CLSq- .styles_modal__gNwvD {
        margin-top: 30px; } }

.path-home .home_slider_main {
  background-image: url("../images/svg/culture_text_bg_grey.png");
  /* background-size: 65% 100%;*/
  background-size: contain;
  background-position: right 0% bottom 0%;
  background-repeat: no-repeat;
  /*@include media-breakpoint-down(sm){
			background-size: 100% 70%;
		}*/ }
  .path-home .home_slider_main .slider-main-wrapper {
    padding: 0px;
    padding-bottom: 30px;
    /*.slick-slide.slick-active {
				.slider-text {
					@include media-breakpoint-down(md) {
						display:block;
					}
				}
			}*/ }
    .path-home .home_slider_main .slider-main-wrapper .slick-slide {
      padding-bottom: 40px; }
      .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content {
        height: 505px; }
        @media (max-width: 991.98px) {
          .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content {
            height: auto;
            margin: 10px; } }
        @media (max-width: 767.98px) {
          .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content {
            height: auto;
            margin: 7px; } }
        .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text {
          position: relative;
          height: 100%; }
          @media (max-width: 991.98px) {
            .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text {
              min-height: 450px;
              /*display:none;*/ } }
          @media (max-width: 767.98px) {
            .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text {
              min-height: 560px;
              /*display:none;*/ } }
          .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text .trophy-img {
            width: 50px;
            height: 54px;
            position: absolute;
            bottom: 20px;
            right: 20px;
            background-image: url(../images/svg/icon_trophy.svg);
            background-repeat: no-repeat; }
            @media (max-width: 991.98px) {
              .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text .trophy-img {
                display: none; } }

.path-home .secondary_banner_section {
  margin-top: 0px;
  margin-bottom: 0;
  background: #000000; }
  .path-home .secondary_banner_section .secondary_content_div h2 {
    padding-right: 20px;
    margin-bottom: 20px; }

@media (max-width: 991.98px) {
  .path-home .slick-arrow {
    display: none !important; } }

.path-home .slick-arrow:hover {
  border: none !important; }

.path-home .slick-prev {
  left: 0px !important; }

.path-home .slick-next {
  right: 0px !important; }

@media (min-width: 576px) {
  .path-home #video_popup .modal-dialog {
    max-width: 650px; } }

.path-home #video_popup .modal-dialog .modal-content {
  border: none;
  background: none; }
  .path-home #video_popup .modal-dialog .modal-content .modal-header {
    border: none; }
    .path-home #video_popup .modal-dialog .modal-content .modal-header .close {
      cursor: pointer;
      padding: 2px 5px;
      line-height: 20px;
      font-size: 40px;
      background: #ffffff;
      z-index: 999999;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; }
  .path-home #video_popup .modal-dialog .modal-content .modal-body .video-js {
    height: 350px;
    width: 100%; }
    @media (max-width: 767.98px) {
      .path-home #video_popup .modal-dialog .modal-content .modal-body .video-js {
        height: 205px; } }

@media only screen and (max-width: 400px) {
  .path-home #video_popup .modal-body .video-js {
    height: 184px !important; } }

.path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .instagram-item-grid {
  position: relative;
  background: #000000; }

.path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .insta-image-container img {
  object-fit: cover; }

.path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .hearts-and-comments {
  position: absolute;
  top: 46%;
  left: 25%; }
  .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .hearts-and-comments span {
    margin-left: 10px; }
  @media (max-width: 991.98px) {
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .hearts-and-comments {
      display: none; } }
  .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .hearts-and-comments img {
    height: 25px; }

.path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .col-md-3, .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .col-md-6 {
  padding: 0px 10px; }

.path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .col-md-3:first-child, .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .col-md-6:first-child {
  padding-left: 15px; }

.path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .col-md-3:last-child, .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row .col-md-6:last-child {
  padding-right: 15px; }

.path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:first-child .instagram-item-grid {
  margin-bottom: 20px; }
  @media (max-width: 991.98px) {
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:first-child .instagram-item-grid {
      margin-bottom: 12px; } }
  @media (max-width: 991.98px) {
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:first-child .instagram-item-grid .hearts-and-comments {
      display: none; } }
  .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:first-child .instagram-item-grid .hearts-and-comments img {
    height: 60px; }
    @media (max-width: 1199.98px) {
      .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:first-child .instagram-item-grid .hearts-and-comments img {
        height: 45px; } }

.path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:nth-child(2) .row .instagram-item-grid {
  margin-bottom: 20px; }
  @media (max-width: 991.98px) {
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:nth-child(2) .row .instagram-item-grid {
      margin-bottom: 12px; } }
  .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:nth-child(2) .row .instagram-item-grid .insta-image-container img {
    object-fit: cover; }
  @media (max-width: 991.98px) {
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:nth-child(2) .row .instagram-item-grid .hearts-and-comments {
      display: none; } }
  .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > div:nth-child(2) .row .instagram-item-grid .hearts-and-comments img {
    height: 25px; }

.path-home .site-footer {
  margin-top: 0; }

.slider-header {
  padding: 10px 50px;
  font-size: 21px;
  background: #009DD6;
  z-index: 99;
  color: #FFFFFF;
  position: absolute;
  margin-top: -25px;
  width: 80%;
  max-width: 505px;
  letter-spacing: 1.6px;
  font-family: "sharpSans-Medium"; }
  @media (max-width: 991.98px) {
    .slider-header {
      max-width: 352px;
      left: 0;
      right: 0;
      margin: -35px auto auto;
      text-align: center;
      font-size: 21px;
      line-height: 1.2; } }
  @media (max-width: 767.98px) {
    .slider-header {
      margin: -55px auto auto; } }
  @media (max-width: 575.98px) {
    .slider-header {
      max-width: 252px;
      padding: 10px; } }

.slider-wrapper .slider-content {
  color: #2A2A2A; }
  .slider-wrapper .slider-content .slider-text {
    background: #EEEEEE;
    padding: 50px; }
    @media (max-width: 991.98px) {
      .slider-wrapper .slider-content .slider-text {
        padding: 40px; } }
    @media (max-width: 767.98px) {
      .slider-wrapper .slider-content .slider-text {
        padding: 30px 24px; } }
    .slider-wrapper .slider-content .slider-text .slider-title {
      font-size: 40px;
      font-family: "sharpSans-Book";
      line-height: 1.2; }
      @media (max-width: 991.98px) {
        .slider-wrapper .slider-content .slider-text .slider-title {
          font-size: 26px;
          line-height: 1.5; } }
    .slider-wrapper .slider-content .slider-text .slider-desc {
      font-size: 18px;
      line-height: 30px;
      padding-top: 28px; }
      .slider-wrapper .slider-content .slider-text .slider-desc p {
        margin-bottom: 0px; }
    .slider-wrapper .slider-content .slider-text .slider-readmore {
      padding-top: 11.5px; }
      .slider-wrapper .slider-content .slider-text .slider-readmore .slider-readmore-label {
        font-size: 18px; }
      .slider-wrapper .slider-content .slider-text .slider-readmore ul li {
        font-family: "SourceSansPro-Semibold";
        font-size: 18px; }
        .slider-wrapper .slider-content .slider-text .slider-readmore ul li a {
          text-decoration: none;
          word-wrap: break-word;
          color: #0079C0; }
  .slider-wrapper .slider-content .slider-featured-image {
    padding: 0px;
    max-width: 505px;
    height: 100%;
    background: #eee; }
    @media (max-width: 991.98px) {
      .slider-wrapper .slider-content .slider-featured-image {
        max-width: 100%;
        height: 370px; } }
    @media (max-width: 767.98px) {
      .slider-wrapper .slider-content .slider-featured-image {
        max-width: 100%;
        overflow: hidden;
        height: 205px; } }
    .slider-wrapper .slider-content .slider-featured-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 0; }
  .slider-wrapper .slider-content .slider-featured-image::before, .slider-wrapper .slider-content .slider-text::after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 22px;
    left: 13%;
    width: 100%;
    top: 91%;
    max-width: 450px;
    background: #EEE;
    -webkit-box-shadow: 0px 23px 18px 0px #777;
    box-shadow: 0px 23px 18px 0px #777;
    -webkit-transform: rotate(-1.5deg);
    -o-transform: rotate(-1.5deg);
    transform: rotate(-1.5deg); }
  @media (max-width: 991.98px) {
    .slider-wrapper .slider-content .slider-text::before {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 22px;
      left: 7%;
      width: 100%;
      top: 91%;
      max-width: 180px;
      background: #EEE;
      box-shadow: 0px 17px 15px 0px #777;
      transform: rotate(-5.5deg); } }
  .slider-wrapper .slider-content .slider-text::after {
    -webkit-transform: rotate(1.5deg);
    -o-transform: rotate(1.5deg);
    transform: rotate(1.5deg);
    right: 13%;
    left: auto; }
    @media (max-width: 991.98px) {
      .slider-wrapper .slider-content .slider-text::after {
        max-width: 180px;
        right: 7%;
        bottom: 22px;
        box-shadow: 0px 17px 15px 0px #777;
        transform: rotate(5.5deg); } }
  .slider-wrapper .slider-content .slider-readmore ul {
    padding: 0px;
    list-style: none; }

.secondary_banner_section {
  background: #F36A30;
  color: #FFFFFF;
  display: flex; }
  @media (max-width: 991.98px) {
    .secondary_banner_section .secondary_banner_content {
      order: 2; } }
  .secondary_banner_section .secondary_content_div {
    padding: 15% 25px 15% 34%;
    max-height: 520px;
    overflow: hidden; }
    @media (max-width: 1199.98px) {
      .secondary_banner_section .secondary_content_div {
        padding: 15%; } }
    @media (max-width: 991.98px) {
      .secondary_banner_section .secondary_content_div {
        padding: 48px;
        order: 2; } }
    @media (max-width: 767.98px) {
      .secondary_banner_section .secondary_content_div {
        max-height: 630px;
        padding: 48px 24px; } }
    .secondary_banner_section .secondary_content_div h2 {
      font-size: 40px;
      line-height: 48px;
      font-family: "sharpSans-Medium"; }
      @media (max-width: 991.98px) {
        .secondary_banner_section .secondary_content_div h2 {
          font-size: 32px; } }
    .secondary_banner_section .secondary_content_div p {
      font-size: 18px;
      overflow: hidden;
      line-height: 30px;
      font-family: "SourceSansPro-Regular";
      margin-bottom: 40px; }
      @media (min-width: 992px) {
        .secondary_banner_section .secondary_content_div p {
          max-height: 180px; } }
      @media (min-width: 1200px) {
        .secondary_banner_section .secondary_content_div p {
          /* max-height: 212px; */ } }
    .secondary_banner_section .secondary_content_div .btn {
      width: 150px;
      font-size: 18px;
      font-family: "SourceSansPro-Semibold";
      border-radius: 0;
      height: 45px;
      line-height: 30px;
      color: #2A2A2A; }
      .secondary_banner_section .secondary_content_div .btn:hover {
        color: #f36e37; }
      .secondary_banner_section .secondary_content_div .btn .fa-arrow-right {
        font-size: 16px;
        padding-left: 10px; }
  .secondary_banner_section .secondary_banner_img {
    max-height: 520px;
    overflow: hidden;
    text-align: right; }
    .secondary_banner_section .secondary_banner_img span {
      width: 100%;
      height: 100%; }
    .secondary_banner_section .secondary_banner_img img {
      width: 100%;
      object-fit: cover; }

@media (min-width: 1200px) {
  .path-frontpage .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding-top: 90px;
    padding-left: 0px; } }

.path-frontpage .slick-arrow {
  border-radius: 0px !important;
  height: 20px;
  width: 21px;
  z-index: 1;
  display: none !important; }

.path-frontpage .carousel-control-next-icon, .path-frontpage .carousel-control-prev-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: block !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat; }
  @media (max-width: 991.98px) {
    .path-frontpage .carousel-control-next-icon, .path-frontpage .carousel-control-prev-icon {
      display: none !important; } }

.instagram-popup-container .prev-button-popup, .instagram-popup-container .next-button-popup {
  background-size: 100% 100% !important;
  height: 20px !important;
  width: 20px !important;
  background-repeat: no-repeat; }

.path-frontpage #main-wrapper .home_slider_main .carousel-control-prev-icon, .instagram-popup-container .prev-button-popup {
  background-image: url(../images/icons/carousel-left.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-frontpage #main-wrapper .home_slider_main .carousel-control-prev-icon:hover, .instagram-popup-container .prev-button-popup:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

.path-frontpage #main-wrapper .home_slider_main .carousel-control-next-icon, .instagram-popup-container .next-button-popup {
  background-image: url(../images/icons/carousel-right.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-frontpage #main-wrapper .home_slider_main .carousel-control-next-icon:hover, .instagram-popup-container .next-button-popup:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

.instagram-popup-container .prev-button-popup:hover, .instagram-popup-container .next-button-popup:hover {
  background-size: 100% 108%; }

.path-frontpage .slick-arrow::before {
  content: ' ' !important;
  font-size: 0px !important; }

.path-frontpage .slick-arrow:hover {
  border: 1px solid #ccc; }

@media only screen and (max-width: 767px) {
  .path-home .home-instagram-react-main-wrapper {
    padding: 0 15px; }
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-6 {
      padding: 0px 15px; }
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-3 {
      padding: 0px 6px; }
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-3:first-child, .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-3:nth-child(3), .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-6:first-child {
      padding-left: 15px; }
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-3:last-child, .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-3:nth-child(2), .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-6:last-child {
      padding-right: 15px; }
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-3:nth-child(3), .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap > .row .col-md-3:nth-child(4) {
      margin-top: 15px; }
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > .col-12:first-child {
      padding: 0px; }
    .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > .col-12:last-child {
      margin-top: 15px; }
      .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > .col-12:last-child .col-6:first-child {
        padding-right: 6px; }
      .path-home .home-instagram-react-main-wrapper .instagram-wrapper .instagrid-strap .row.large-image > .col-12:last-child .col-6:last-child {
        padding-left: 6px; } }

@media only screen and (min-width: 960px) and (max-width: 992px) {
  #block-nbcucareers-footer ul li.nav-item:nth-child(7) {
    height: 52px; } }

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .path-home #video_popup .modal-dialog {
    max-width: 768px; }
    .path-home #video_popup .modal-dialog .modal-content .modal-body .video-js {
      height: 414px; }
    .path-home #video_popup .modal-dialog .modal-header .close {
      margin-right: 0px; }
  .path-home .home-instagram-react-wrapper .tab-wrapper .align-right, .path-home .home-instagram-react-wrapper .tab-wrapper .align-left {
    text-align: center; }
  .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text .slider-desc {
    padding-top: 10px; }
  .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text .trophy-img {
    display: block !important; }
  #block-nbcucareers-footer li {
    min-width: 175px; }
  #block-nbcucareers-footer ul li.nav-item:nth-child(7) {
    height: 48px; }
  #block-footermenu2 .menu-item--expanded {
    padding-top: 10px; }
    #block-footermenu2 .menu-item--expanded > div {
      font-weight: bold; } }

@media only screen and (min-width: 993px) and (max-width: 1199px) {
  .path-home .slider-wrapper .slider-content .slider-text {
    padding: 46px; }
    .path-home .slider-wrapper .slider-content .slider-text .slider-title {
      font-size: 34px; }
    .path-home .slider-wrapper .slider-content .slider-text .slider-readmore ul li {
      padding-right: 20px; }
  .path-home .slider-wrapper .slider-content .trophy-img {
    right: 16px;
    width: 45px; }
  .path-home .featured-top .field--name-field-banner-logo-small {
    bottom: 10px; }
  .path-home .featured-top .search-form-wrapper {
    padding-top: 20px; }
  .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text .slider-desc {
    padding-top: 10px; }
  .path-home .slick-next.slick-arrow {
    margin-right: 15px; }
  .path-home .slick-prev.slick-arrow {
    margin-left: 15px; } }

.error-page {
  max-width: 1240px;
  margin: 0 auto;
  padding: 5%; }
  .error-page .error-header {
    padding: 0px 20px; }
    .error-page .error-header h1.page-title {
      margin-top: 90px;
      font-family: "sharpSans-Bold";
      text-align: center;
      font-size: 90px;
      color: #2a2a2a;
      padding: 0 0 25px 0; }
      @media (max-width: 991.98px) {
        .error-page .error-header h1.page-title {
          font-size: 70px; } }
      @media (max-width: 767.98px) {
        .error-page .error-header h1.page-title {
          font-size: 50px; } }
  .error-page .error-body {
    padding: 0px 20px; }
    .error-page .error-body h4 {
      text-align: center; }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .path-home .slider-wrapper .slider-content .slider-featured-image {
    height: 340px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .path-home .home_slider_main .slider-main-wrapper .slick-slide .slider-content .slider-text {
    min-height: 400px; } }

.search-form-wrapper .search-form .custom-plus.cross {
  position: absolute;
  top: 16px;
  right: 9%;
  font-size: 25px;
  display: none;
  background-image: url("../images/svg/Icon_Add.svg");
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  width: 19px;
  height: 19px;
  margin-top: 1px;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg); }

.search-form-wrapper .search-form .custom-plus.cross.show {
  display: block;
  cursor: pointer; }

@media only screen and (max-width: 767px) {
  .search-form-wrapper .search-form .custom-plus.cross.show {
    margin-right: 20px; } }

.node--type-page-program .styles_modal__gNwvD {
  max-width: 935px; }

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .secondary_banner_section .secondary_content_div {
    padding: 15% 100px; } }

.hide-onload {
  display: none; }

.path-culture .culture_testimonials {
  padding: 50px 0px 80px; }

.path-culture .vjs-poster {
  background-size: 100% 100%; }

@media (max-width: 767.98px) {
  .path-culture .container-fluid-lg {
    padding: 0px 15px; } }

.path-culture .featured-top {
  /* margin-bottom:30px !important; */ }
  .path-culture .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding-bottom: 15%; }
    @media (max-width: 991.98px) {
      .path-culture .featured-top .banner-content-wrapper-top .banner-content-wrapper {
        padding-bottom: 1px; } }
  .path-culture .featured-top .fa-search, .path-culture .featured-top .fa-arrow-right {
    cursor: pointer; }

.path-culture .row.no-gutters.culture_sec_banner {
  margin-top: 100px; }
  @media (max-width: 991.98px) {
    .path-culture .row.no-gutters.culture_sec_banner {
      margin-top: 0px; } }

.path-culture .main-content .tab3 {
  margin-right: 0px !important;
  margin-left: 0px; }

.path-culture .slick-next {
  right: 2px; }

.path-culture .bg-img-text-award {
  background-image: url("../images/svg/text_background_image.png");
  /*background-size: 70% 135%;*/
  background-size: contain;
  background-position: right 0% bottom 0%;
  background-repeat: no-repeat; }
  @media (max-width: 767.98px) {
    .path-culture .bg-img-text-award {
      background-size: 340px 344px; } }

.path-culture .bg-img-text-social {
  background-color: #EEEEEE;
  background-image: url("../images/svg/culture_text_bg_white.png");
  /*background-size: 70% 65%;*/
  background-size: contain;
  background-position: right 0% bottom 0%;
  background-repeat: no-repeat; }
  @media (max-width: 991.98px) {
    .path-culture .bg-img-text-social {
      background: none;
      background-color: #EEEEEE; } }
  @media (max-width: 767.98px) {
    .path-culture .bg-img-text-social .social-diversity {
      padding: 0px 15px; } }

.path-culture .site-footer {
  margin-top: 0px; }

.path-culture .brand-page-cont h2 {
  font-family: "sharpSans-Book";
  margin-bottom: 40px; }
  @media (max-width: 575.98px) {
    .path-culture .brand-page-cont h2 {
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .path-culture .brand-page-cont h2 {
      font-size: 26px; } }

.path-culture .single-slider-main:after, .path-culture .single-slider-main:before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 20px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #EEE;
  -webkit-box-shadow: 0 16px 17px #777;
  box-shadow: 0 16px 17px #777;
  -webkit-transform: rotate(-4deg);
  -o-transform: rotate(-4deg);
  transform: rotate(-4deg); }

.path-culture .single-slider-main:after {
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  -o-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
  right: 10px;
  left: auto; }

.path-culture .hover-awards-and-recognition .single-slider-main:hover:before, .path-culture .hover-awards-and-recognition .single-slider-main:hover:after {
  -webkit-box-shadow: 0 43px 11px #777;
  -moz-box-shadow: 0 43px 11px #777;
  box-shadow: 0 43px 11px #777;
  bottom: 0px;
  height: 25px; }
  @media (max-width: 1199.98px) {
    .path-culture .hover-awards-and-recognition .single-slider-main:hover:before, .path-culture .hover-awards-and-recognition .single-slider-main:hover:after {
      -webkit-box-shadow: 0 28px 11px #777;
      -moz-box-shadow: 0 28px 11px #777;
      box-shadow: 0 28px 11px #777; } }

.path-culture .testimonial-nav-main:after, .path-culture .testimonial-nav-main:before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 19px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #EEE;
  -webkit-box-shadow: 0 6px 12px #777;
  box-shadow: 0 6px 12px #777;
  -webkit-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg); }

@media (max-width: 767.98px) {
  .path-culture .testimonial-nav-main:before {
    left: 20px; } }

.path-culture .testimonial-nav-main {
  position: relative; }

.path-culture #awards-and-recognition {
  padding-top: 72px; }

.path-culture .testimonial-nav-main:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 28px;
  left: auto; }

.path-culture .hover-testimonial-nav-main .testimonial-nav-main:hover:before, .path-culture .hover-testimonial-nav-main .testimonial-nav-main:hover:after {
  -webkit-box-shadow: 0 8px 9px #777;
  -moz-box-shadow: 0 8px 9px #777;
  box-shadow: 0 8px 9px #777; }

.path-culture .slick-arrow {
  border-radius: 0px !important;
  height: 20px;
  width: 21px;
  z-index: 1;
  display: none; }

.path-culture .back_fade {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: #fff;
  z-index: 1050;
  opacity: .8;
  display: none; }

.path-culture .slick-arrow:hover {
  border: 1px solid #FFF; }

.path-culture .slick-arrow::before {
  content: ' ' !important;
  font-size: 0px !important; }

.path-culture .carousel-control-next-icon, .path-culture .carousel-control-prev-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: block;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  display: block; }

.path-culture .carousel-control-next-icon {
  background-image: url(../images/icons/carousel-right.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-culture .carousel-control-next-icon:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

.path-culture .carousel-control-prev-icon {
  background-image: url(../images/icons/carousel-left.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-culture .carousel-control-prev-icon:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

@media (max-width: 991.98px) {
  .path-culture .culture-title {
    padding: 0px; } }

.path-culture .culture-title h2.side-title {
  font-size: 23px;
  font-family: "sharpSans-Semibold"; }
  @media (max-width: 767.98px) {
    .path-culture .culture-title h2.side-title {
      font-size: 20px;
      padding-left: 15px; } }
  @media (max-width: 991.98px) {
    .path-culture .culture-title h2.side-title {
      padding-left: 15px; } }
  .path-culture .culture-title h2.side-title span {
    padding-right: 5px; }

.path-culture .culture-title .title-border {
  border-bottom: 6px solid #F36A30;
  width: 75px;
  margin-bottom: 61px; }
  @media (max-width: 767.98px) {
    .path-culture .culture-title .title-border {
      margin-bottom: 60px;
      margin-left: 15px; } }
  @media (max-width: 575.98px) {
    .path-culture .culture-title .title-border {
      margin-bottom: 60px;
      margin-left: 15px; } }
  @media (max-width: 991.98px) {
    .path-culture .culture-title .title-border {
      margin-bottom: 60px;
      margin-left: 15px; } }

.path-culture .culture-title a {
  color: #686868;
  text-decoration: none;
  font-family: "SourceSansPro-Semibold";
  font-size: 20px;
  line-height: 27px; }
  @media (max-width: 767.98px) {
    .path-culture .culture-title a {
      font-size: 18px;
      line-height: 45px; } }

.path-culture .culture-title a:hover {
  color: #F36A30;
  font-family: "SourceSansPro-Semibold";
  font-size: 20px;
  line-height: 27px; }
  @media (max-width: 767.98px) {
    .path-culture .culture-title a:hover {
      font-size: 18px;
      line-height: 45px; } }

@media (max-width: 991.98px) {
  .path-culture .culture-title p {
    margin-bottom: 0px;
    padding-left: 15px; } }

.path-culture .tab3, .path-culture .culture_awards_recognition {
  margin-top: 45px; }
  @media (max-width: 575.98px) {
    .path-culture .tab3, .path-culture .culture_awards_recognition {
      margin-top: 10%; } }
  @media (max-width: 767.98px) {
    .path-culture .tab3, .path-culture .culture_awards_recognition {
      margin-top: 10%; } }
  @media (max-width: 991.98px) {
    .path-culture .tab3, .path-culture .culture_awards_recognition {
      margin-top: 0%; } }

.path-culture .tab3 {
  margin-top: 10px !important; }
  .path-culture .tab3 .inner-content .tab3_content .text-white {
    line-height: 36px;
    font-family: "sharpSans-Book";
    font-size: 26px; }

.path-culture .culture_awards_recognition {
  padding-bottom: 70px; }

.path-culture .erg_heading {
  padding-top: 45px; }
  .path-culture .erg_heading h4 {
    font-size: 26px;
    font-family: "sharpSans-Semibold"; }
    @media (max-width: 767.98px) {
      .path-culture .erg_heading h4 {
        padding: 25px 0 0; } }
  @media (max-width: 991.98px) {
    .path-culture .erg_heading {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 50px; } }
  @media (max-width: 767.98px) {
    .path-culture .erg_heading {
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 0px; } }

.path-culture .erg_groups.mobile-hide {
  width: 1000px;
  margin: 0 auto;
  margin-top: 5%; }

.path-culture .second_content_culture .second_content_div h4 {
  font-size: 20px;
  line-height: 28px;
  font-family: "SourceSansPro-Bold";
  padding-bottom: 40px;
  text-transform: uppercase; }
  @media (max-width: 767.98px) {
    .path-culture .second_content_culture .second_content_div h4 {
      padding-bottom: 0px;
      padding-right: 15px; } }

.path-culture .second_content_culture .second_content_div h2 {
  font-size: 40px;
  line-height: 46px;
  font-family: "sharpSans-Medium";
  padding-bottom: 20px; }

.path-culture .second_content_culture .second_content_div p {
  font-size: 18px;
  line-height: 30px;
  font-family: "SourceSansPro-Regular";
  word-spacing: 1px;
  overflow: hidden; }
  @media (min-width: 992px) {
    .path-culture .second_content_culture .second_content_div p {
      max-height: 180px; } }
  @media (min-width: 1200px) {
    .path-culture .second_content_culture .second_content_div p {
      max-height: 210px;
      margin-bottom: 0px; } }

@media (min-width: 992px) {
  .path-culture .second_content_culture.noHeader .second_content_div p {
    max-height: 305px; } }

@media (min-width: 1200px) {
  .path-culture .second_content_culture.noHeader .second_content_div p {
    max-height: 300px; } }

.path-culture .second_content_culture .second_content_div {
  padding: 75px 0px 75px 60px; }
  @media (max-width: 1199.98px) {
    .path-culture .second_content_culture .second_content_div {
      padding: 75px 20px 50px 20px; } }
  @media (max-width: 767.98px) {
    .path-culture .second_content_culture .second_content_div h4 {
      font-size: 18px;
      text-transform: uppercase; }
    .path-culture .second_content_culture .second_content_div h2 {
      font-size: 26px; }
    .path-culture .second_content_culture .second_content_div p {
      font-size: 18px;
      line-height: 35px; } }

.path-culture .tab1-title, .path-culture .tab2-title {
  color: #0079C0;
  margin-top: 4%;
  margin-bottom: 4%;
  font-size: 40px;
  line-height: 46px;
  font-family: "sharpSans-Book"; }
  @media (max-width: 767.98px) {
    .path-culture .tab1-title, .path-culture .tab2-title {
      font-size: 26px;
      line-height: 30px; } }

.path-culture .tab1 .left-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.path-culture #talent-development .left-image {
  padding: 0px 0px 70px 0px; }
  @media (max-width: 991.98px) {
    .path-culture #talent-development .left-image {
      padding: 0px; } }
  @media (max-width: 767.98px) {
    .path-culture #talent-development .left-image {
      padding: 0px; } }

.path-culture #talent-development .right-content .inner-content {
  padding: 0px 15px 70px 35px; }
  @media (max-width: 1199.98px) {
    .path-culture #talent-development .right-content .inner-content {
      padding: 0px 30px 60px 30px; } }
  @media (max-width: 767.98px) {
    .path-culture #talent-development .right-content .inner-content {
      padding: 0px; } }
  .path-culture #talent-development .right-content .inner-content img {
    width: 250px; }
    @media (max-width: 1199.98px) {
      .path-culture #talent-development .right-content .inner-content img {
        padding-top: 0px; } }
    @media (max-width: 991.98px) {
      .path-culture #talent-development .right-content .inner-content img {
        padding-top: 20px;
        width: 100%; } }
  .path-culture #talent-development .right-content .inner-content .tab1-title {
    color: #0079C0;
    font-size: 30px;
    line-height: 36px; }
  .path-culture #talent-development .right-content .inner-content a {
    color: #212529; }

.path-culture .tab2 .right-content {
  padding-left: 0px; }
  @media (max-width: 991.98px) {
    .path-culture .tab2 .right-content {
      padding: 0px 15px; } }
  .path-culture .tab2 .right-content .inner-content {
    padding: 10%; }
    @media (max-width: 991.98px) {
      .path-culture .tab2 .right-content .inner-content {
        padding: 20px 0px; } }

.path-culture .tab2 .left-image img, .path-culture .tab3 .left-image img, .path-culture .erg_heading h2 {
  width: 100%; }

.path-culture .culture_sec_banner .spotlight_second_img img {
  width: 100%;
  height: 100%;
  max-height: 520px; }

.path-culture .tab2 .left-image img {
  margin-bottom: 70px; }

.path-culture .erg_heading h2 {
  font-size: 40px;
  line-height: 46px;
  font-family: "sharpSans-Book"; }
  @media (max-width: 575.98px) {
    .path-culture .erg_heading h2 {
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .path-culture .erg_heading h2 {
      font-size: 26px; } }

.path-culture .slick-arrow {
  display: none; }

@media (max-width: 767.98px) {
  .path-culture .mobile-tab3-outer {
    padding: 0 !important; }
  .path-culture .mobile-outer-award {
    padding: 0px !important;
    margin: 45px 0px 50px 0px; }
    .path-culture .mobile-outer-award .slick-list {
      width: 100% !important;
      margin: auto; }
    .path-culture .mobile-outer-award .close-button {
      right: 30px !important;
      top: -6px; }
    .path-culture .mobile-outer-award .slider-testi-for-parent .slick-list {
      width: 85% !important;
      margin: auto; } }

@media (max-width: 991.98px) {
  .path-culture .mobile-tab3-outer {
    padding: 0 !important; }
  .path-culture .brand-page-cont h2 {
    font-size: 26px; }
  .path-culture .culture_awards_recognition .carousel-control-next-icon, .path-culture .culture_awards_recognition .carousel-control-prev-icon {
    display: none; } }

.path-culture .tab3 .right-content {
  background-color: #F36A30;
  background-image: url("../images/svg/ERG_Art_lightWhite.png");
  background-size: 90% 90%;
  background-position: right 0% bottom 0%;
  background-repeat: no-repeat; }
  @media (max-width: 767.98px) {
    .path-culture .tab3 .right-content {
      background-size: 100% 100%; } }

@media (max-width: 991.98px) {
  .path-culture .erg_groups.desktop-hide {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .path-culture .erg_groups.desktop-hide {
    margin-left: 15px !important;
    margin-right: 15px !important;
    margin-bottom: 70px; } }

@media (max-width: 991.98px) {
  .path-culture .erg_groups.desktop-hide .collapse.show {
    border-bottom: 2px solid #f06e37; } }

@media (max-width: 991.98px) {
  .path-culture .erg_groups.desktop-hide .card-body {
    padding: 20px 0px; } }

@media (max-width: 991.98px) {
  .path-culture .erg_groups.desktop-hide .card-body img {
    margin-bottom: 30px; } }

@media (max-width: 991.98px) {
  .path-culture .erg_groups.desktop-hide .card-body p {
    font-size: 18px;
    line-height: 35px;
    font-family: "SourceSansPro-Regular";
    word-spacing: 1px; } }

.path-culture .tab1 .left-image {
  padding: 70px 0px 0px 0px; }
  @media (max-width: 991.98px) {
    .path-culture .tab1 .left-image {
      padding: 0px; } }
  @media (max-width: 767.98px) {
    .path-culture .tab1 .left-image {
      padding: 0px; } }

.path-culture .tab2 .left-image {
  padding: 70px 0px 0px 0px; }
  @media (max-width: 991.98px) {
    .path-culture .tab2 .left-image {
      padding: 0px; } }
  @media (max-width: 767.98px) {
    .path-culture .tab2 .left-image {
      padding: 0px; }
      .path-culture .tab2 .left-image img {
        margin-bottom: 0px; } }

.path-culture .tab1, .path-culture .tab2 {
  margin: 0px; }
  @media (max-width: 991.98px) {
    .path-culture .tab1, .path-culture .tab2 {
      margin: 0px -15px; } }

.path-culture .tab3 .left-image {
  padding: 0px 0px 0px 20px; }
  @media (max-width: 767.98px) {
    .path-culture .tab3 .left-image {
      padding: 0px 0px 0px 0px; }
      .path-culture .tab3 .left-image img {
        height: 250px; } }

.path-culture .tab3 .right-content {
  padding: 0px 20px 0px 20px; }

.path-culture .culture_sec_banner .spotlight_second_img {
  padding: 0px; }

.path-culture .tab3_content span.text-black {
  font-size: 70px;
  font-family: "sharpSans-Bold";
  line-height: 74px; }
  @media (max-width: 575.98px) {
    .path-culture .tab3_content span.text-black {
      font-size: 30px;
      line-height: 38px; } }
  @media (max-width: 767.98px) {
    .path-culture .tab3_content span.text-black {
      font-size: 30px;
      line-height: 38px; } }

.path-culture .tab3_content span.text-white {
  font-size: 70px;
  font-family: "sharpSans-Light";
  line-height: 74px;
  padding-top: 20px; }
  @media (max-width: 575.98px) {
    .path-culture .tab3_content span.text-white {
      font-size: 30px;
      line-height: 38px; } }
  @media (max-width: 767.98px) {
    .path-culture .tab3_content span.text-white {
      font-size: 30px;
      line-height: 38px; } }

.path-culture .tab3_content p.text-white {
  font-size: 26px;
  font-family: "sharpSans-Book";
  line-height: 36px;
  padding-top: 40px; }
  @media (max-width: 767.98px) {
    .path-culture .tab3_content p.text-white {
      font-size: 18px;
      line-height: 35px; } }

.path-culture #employee-resource-groups h2 {
  font-size: 40px;
  line-height: 46px;
  margin-top: 34px;
  font-family: "sharpSans-Book";
  margin-bottom: 40px; }

.path-culture .right-content .inner-content {
  padding: 70px 35px 0px; }
  @media (max-width: 1199.98px) {
    .path-culture .right-content .inner-content {
      padding: 30px; }
      .path-culture .right-content .inner-content img {
        padding-top: 20px; } }
  @media (max-width: 767.98px) {
    .path-culture .right-content .inner-content {
      padding: 0px; } }
  .path-culture .right-content .inner-content p {
    font-size: 18px;
    line-height: 30px;
    font-family: "SourceSansPro-Regular";
    padding-right: 10px; }
    @media (max-width: 767.98px) {
      .path-culture .right-content .inner-content p {
        font-size: 18px;
        line-height: 35px;
        padding-right: 0px; } }
  .path-culture .right-content .inner-content a {
    font-size: 18px;
    line-height: 30px;
    font-family: "SourceSansPro-Semibold";
    text-decoration: none;
    color: #0079C0; }
    @media (max-width: 767.98px) {
      .path-culture .right-content .inner-content a {
        font-size: 18px;
        line-height: 35px; } }

.path-culture .erg_groups .erg_img {
  text-align: center;
  margin-bottom: 55px; }

.path-culture .erg_groups .erg_img img {
  margin: 0 auto; }

.path-culture .slider-for-parent {
  position: relative; }
  .path-culture .slider-for-parent .close-button {
    padding: 0;
    background-color: transparent;
    border: 0;
    font-size: 34px;
    font-weight: 700;
    line-height: 15px;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 1800;
    position: absolute;
    right: 30px; }
  .path-culture .slider-for-parent .slick-list {
    width: 85%;
    margin: 0 auto; }
  .path-culture .slider-for-parent .slider {
    z-index: 1500; }
  .path-culture .slider-for-parent .single-slider-img {
    background-color: #ccc; }
    .path-culture .slider-for-parent .single-slider-img .slider-left {
      width: 60%;
      float: left;
      position: relative;
      height: 620px; }
      @media (max-width: 991.98px) {
        .path-culture .slider-for-parent .single-slider-img .slider-left {
          height: 378px; } }
      .path-culture .slider-for-parent .single-slider-img .slider-left img {
        max-height: 620px;
        max-width: 100%;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto; }
      .path-culture .slider-for-parent .single-slider-img .slider-left .slider-title {
        display: none;
        position: absolute;
        top: 15%;
        margin-left: 5%;
        font-size: 35px;
        width: 85%; }
    .path-culture .slider-for-parent .single-slider-img .slider-right .slider-learn-more {
      margin-top: 50px; }
      .path-culture .slider-for-parent .single-slider-img .slider-right .slider-learn-more a {
        border-radius: 0; }
  .path-culture .slider-for-parent .cr-sky-blue .slider-right {
    background-color: #2ba9e0; }
  .path-culture .slider-for-parent .cr-sky-blue .slider-learn-more a:hover {
    color: #2ba9e0; }
  .path-culture .slider-for-parent .cr-lavendar .slider-right {
    background-color: #886db0; }
  .path-culture .slider-for-parent .cr-lavendar .slider-learn-more a:hover {
    color: #886db0; }
  .path-culture .slider-for-parent .cr-light-green .slider-right {
    background-color: #59a63f; }
  .path-culture .slider-for-parent .cr-light-green .slider-learn-more a:hover {
    color: #59a63f; }
  .path-culture .slider-for-parent .cr-orange .slider-right {
    background-color: #F36A30; }
  .path-culture .slider-for-parent .cr-orange .slider-learn-more a:hover {
    color: #F36A30; }
  .path-culture .slider-for-parent .cr-pink .slider-right {
    background-color: #D0118C; }
  .path-culture .slider-for-parent .cr-pink .slider-learn-more a:hover {
    color: #D0118C; }
  .path-culture .slider-for-parent .slider-right {
    width: 40%;
    float: left;
    padding: 5%;
    color: #fff;
    min-height: 620px; }
    .path-culture .slider-for-parent .slider-right .slider-learn-more a {
      /* color: #000;
					background-color: #fff;
					padding: 3% 3% 3% 3%;
					font-size: 12px;
					font-weight: bold;
					text-decoration: none; */ }
      .path-culture .slider-for-parent .slider-right .slider-learn-more a i {
        margin: 5px; }
    .path-culture .slider-for-parent .slider-right .slider-learn-more a:hover {
      background-color: #fff; }

.path-culture .slider-nav-parent .single-slider-main {
  background: #eee;
  margin: .5rem;
  position: relative;
  min-height: 330px;
  padding: 8% 5% 5% 8%;
  margin-bottom: 15px;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color; }
  @media (max-width: 991.98px) {
    .path-culture .slider-nav-parent .single-slider-main {
      min-height: 250px; } }
  .path-culture .slider-nav-parent .single-slider-main .slider-title {
    font-size: 24px;
    line-height: 32px;
    font-family: "sharpSans-Semibold";
    padding-top: 26px; }
    @media (max-width: 991.98px) {
      .path-culture .slider-nav-parent .single-slider-main .slider-title {
        font-size: 20px; } }
  .path-culture .slider-nav-parent .single-slider-main .cultureAwardsWhiteLogo {
    display: none; }
  .path-culture .slider-nav-parent .single-slider-main .cultureAwardsDefaultLogo {
    display: block; }

.path-culture .slider-nav-parent .single-slider-main:hover .cultureAwardsWhiteLogo {
  display: block; }

.path-culture .slider-nav-parent .single-slider-main:hover .cultureAwardsDefaultLogo {
  display: none; }

.path-culture .slider-nav-parent .slick-list {
  width: 85%;
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    .path-culture .slider-nav-parent .slick-list {
      width: 100%; } }

.path-culture .slider-nav-parent .slider-bottom {
  position: absolute;
  top: 77%;
  width: 100%; }
  .path-culture .slider-nav-parent .slider-bottom .trophy-text {
    font-family: "sharpSans-Bold";
    color: #A7A9AC; }
  .path-culture .slider-nav-parent .slider-bottom .trophy-img {
    width: 64px;
    height: 58px;
    position: absolute;
    top: -26%;
    right: 18%;
    font-size: 58px;
    color: #a09f9f;
    background-image: url(../images/svg/icon_trophy.svg);
    background-repeat: no-repeat; }

.path-culture .slider-nav-parent .cr-sky-blue .slider-title {
  color: #0079C0; }

.path-culture .slider-nav-parent .cr-sky-blue:hover {
  background-color: #009DD6; }
  .path-culture .slider-nav-parent .cr-sky-blue:hover .slider-title, .path-culture .slider-nav-parent .cr-sky-blue:hover .slider-bottom .trophy-text {
    color: #fff; }
  .path-culture .slider-nav-parent .cr-sky-blue:hover .slider-bottom .trophy-img {
    width: 64px;
    height: 58px;
    position: absolute;
    top: -26%;
    right: 18%;
    font-size: 58px;
    color: #a09f9f;
    background-image: url(../images/svg/icon_trophy_white.svg);
    background-repeat: no-repeat; }
    @media (max-width: 767.98px) {
      .path-culture .slider-nav-parent .cr-sky-blue:hover .slider-bottom .trophy-img {
        padding: 0px 20px;
        top: -4%;
        right: 7%; } }

.path-culture .slider-nav-parent .cr-lavendar .slider-title {
  color: #886db0; }

.path-culture .slider-nav-parent .cr-lavendar:hover {
  background-color: #886db0; }
  .path-culture .slider-nav-parent .cr-lavendar:hover .slider-title, .path-culture .slider-nav-parent .cr-lavendar:hover .slider-bottom .trophy-text {
    color: #fff; }
  .path-culture .slider-nav-parent .cr-lavendar:hover .slider-bottom .trophy-img {
    width: 64px;
    height: 58px;
    position: absolute;
    top: -26%;
    right: 18%;
    font-size: 58px;
    color: #a09f9f;
    background-image: url(../images/svg/icon_trophy_white.svg);
    background-repeat: no-repeat; }
    @media (max-width: 767.98px) {
      .path-culture .slider-nav-parent .cr-lavendar:hover .slider-bottom .trophy-img {
        padding: 0px 20px;
        top: -4%;
        right: 7%; } }

.path-culture .slider-nav-parent .cr-light-green .slider-title {
  color: #59a63f; }

.path-culture .slider-nav-parent .cr-light-green:hover {
  background-color: #59a63f; }
  .path-culture .slider-nav-parent .cr-light-green:hover .slider-title, .path-culture .slider-nav-parent .cr-light-green:hover .slider-bottom .trophy-text {
    color: #fff; }
  .path-culture .slider-nav-parent .cr-light-green:hover .slider-bottom .trophy-img {
    width: 64px;
    height: 58px;
    position: absolute;
    top: -26%;
    right: 18%;
    font-size: 58px;
    color: #a09f9f;
    background-image: url(../images/svg/icon_trophy_white.svg);
    background-repeat: no-repeat; }

.path-culture .slider-nav-parent .cr-orange .slider-title {
  color: #F36A30; }

.path-culture .slider-nav-parent .cr-orange:hover {
  background-color: #F36A30; }
  .path-culture .slider-nav-parent .cr-orange:hover .slider-title, .path-culture .slider-nav-parent .cr-orange:hover .slider-bottom .trophy-text {
    color: #fff; }
  .path-culture .slider-nav-parent .cr-orange:hover .slider-bottom .trophy-img {
    width: 64px;
    height: 58px;
    position: absolute;
    top: -26%;
    right: 18%;
    font-size: 58px;
    color: #a09f9f;
    background-image: url(../images/svg/icon_trophy_white.svg);
    background-repeat: no-repeat; }

.path-culture .slider-nav-parent .cr-pink .slider-title {
  color: #D0118C; }

.path-culture .slider-nav-parent .cr-pink:hover {
  background-color: #D0118C; }
  .path-culture .slider-nav-parent .cr-pink:hover .slider-title, .path-culture .slider-nav-parent .cr-pink:hover .slider-bottom .trophy-text {
    color: #fff; }
  .path-culture .slider-nav-parent .cr-pink:hover .slider-bottom .trophy-img {
    width: 64px;
    height: 58px;
    position: absolute;
    top: -26%;
    right: 18%;
    font-size: 58px;
    color: #a09f9f;
    background-image: url(../images/svg/icon_trophy_white.svg);
    background-repeat: no-repeat; }

.path-culture .slider-testi-nav-parent .slick-list {
  width: 85%;
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    .path-culture .slider-testi-nav-parent .slick-list {
      width: 100%; } }

.path-culture .slider-testi-nav-parent .testimonial-nav-main {
  padding: 10px 20px 14px 0px; }
  @media (max-width: 767.98px) {
    .path-culture .slider-testi-nav-parent .testimonial-nav-main {
      padding: 10px 7.5px 14px 7.5px; } }
  .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-image {
    position: relative;
    height: 260px;
    vertical-align: middle;
    border: 1px solid #ccc; }
    .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-image img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
  .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-image:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: url(../images/svg/Icon_Video.svg);
    width: 75px;
    color: #fff;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
  .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-details {
    color: #fff;
    text-align: center;
    padding: 2% 4%;
    position: relative;
    min-height: 96px;
    display: table;
    width: 100%;
    height: 102px; }
    @media (max-width: 767.98px) {
      .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-details {
        min-height: 130px;
        height: 130px; } }
    @media (max-width: 767.98px) {
      .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-details {
        min-height: 150px;
        height: 150px; } }
    .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-details .test_UserDetailsubDiv {
      display: table-cell;
      vertical-align: middle; }
    .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-details .user-title {
      color: #FFFFFF;
      font-size: 24px;
      font-family: "SourceSansPro-Regular"; }
    .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-details .user-designation {
      font-size: 18px;
      font-family: "SourceSansPro-Semibold";
      text-transform: uppercase;
      width: 92%;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-details .user-designation {
          padding: 10px;
          font-family: "SourceSansPro-Semibold"; } }
    .path-culture .slider-testi-nav-parent .testimonial-nav-main .user-details i {
      position: absolute;
      right: 10px;
      bottom: 20px;
      transform: rotate(45deg); }
  .path-culture .slider-testi-nav-parent .testimonial-nav-main .cr-orange {
    background-color: #F36A30; }
  .path-culture .slider-testi-nav-parent .testimonial-nav-main .cr-sky-blue {
    background-color: #009DD6; }
  .path-culture .slider-testi-nav-parent .testimonial-nav-main .cr-lavendar {
    background-color: #7F679F; }

.path-culture .slider-testi-for-parent {
  position: relative;
  width: 750px;
  margin: 0 auto; }
  @media (max-width: 767.98px) {
    .path-culture .slider-testi-for-parent {
      width: 100%;
      padding-top: 35px;
      padding-bottom: 30px; } }
  .path-culture .slider-testi-for-parent .close-button {
    padding: 0;
    background-color: transparent;
    border: 0;
    font-size: 34px;
    font-weight: 700;
    line-height: 15px;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 1800;
    position: absolute;
    right: 60px;
    top: 10px; }
    @media (max-width: 767.98px) {
      .path-culture .slider-testi-for-parent .close-button {
        top: 0px; } }
  .path-culture .slider-testi-for-parent .slick-list {
    width: 630px;
    margin: 0 auto; }
  .path-culture .slider-testi-for-parent .slider {
    z-index: 1500;
    padding-top: 60px; }
    @media (max-width: 767.98px) {
      .path-culture .slider-testi-for-parent .slider {
        padding-top: 35px; } }
  .path-culture .slider-testi-for-parent .testimonial-for-main .brightcove-video-main .video-js {
    width: 100%;
    margin: 0 auto;
    height: 355px; }
    @media (max-width: 767.98px) {
      .path-culture .slider-testi-for-parent .testimonial-for-main .brightcove-video-main .video-js {
        height: 197px; } }
  .path-culture .slider-testi-for-parent .testimonial-for-main .user-details {
    padding: 2%;
    color: #fff; }
    @media (max-width: 767.98px) {
      .path-culture .slider-testi-for-parent .testimonial-for-main .user-details {
        padding: 20px; } }
    .path-culture .slider-testi-for-parent .testimonial-for-main .user-details .user-title {
      font-size: 24px;
      line-height: 35px; }
      @media (max-width: 767.98px) {
        .path-culture .slider-testi-for-parent .testimonial-for-main .user-details .user-title {
          font-size: 24px;
          font-family: "SourceSansPro-Regular"; } }
    .path-culture .slider-testi-for-parent .testimonial-for-main .user-details .user-content {
      border-left: 1px solid #fff;
      font-family: "SourceSansPro-Semibold"; }
      .path-culture .slider-testi-for-parent .testimonial-for-main .user-details .user-content .user-designation {
        font-size: 18px;
        line-height: 35px;
        text-transform: uppercase; }
      .path-culture .slider-testi-for-parent .testimonial-for-main .user-details .user-content .user-des {
        font-size: 18px;
        padding-top: 5px; }
  .path-culture .slider-testi-for-parent .testimonial-for-main .cr-orange {
    background-color: #F36A30; }
  .path-culture .slider-testi-for-parent .testimonial-for-main .cr-sky-blue {
    background-color: #009DD6; }
  .path-culture .slider-testi-for-parent .testimonial-for-main .cr-lavendar {
    background-color: #7F679F; }

@media (max-width: 767.98px) {
  .path-culture .slider-nav-parent .slick-slide .cr-light-green {
    background-color: #59a63f; }
  .path-culture .slider-nav-parent .slick-slide .cr-sky-blue {
    background-color: #009DD6; }
  .path-culture .slider-nav-parent .slick-slide .cr-lavendar {
    background-color: #7F679F; }
  .path-culture .slider-nav-parent .slick-slide .slider-title, .path-culture .slider-nav-parent .slick-slide .slider-bottom .trophy-text {
    color: #fff; }
  .path-culture .slider-nav-parent .slick-slide .cr-orange {
    background-color: #F36A30; }
  .path-culture .slider-nav-parent .slick-slide .cr-pink {
    background-color: #D0118C; }
  .path-culture .slider-nav-parent .slick-slide .slider-bottom .trophy-img {
    width: 64px;
    height: 58px;
    position: absolute;
    top: -4%;
    right: 7%;
    font-size: 58px;
    color: #a09f9f;
    background-image: url(../images/svg/icon_trophy_white.svg);
    background-repeat: no-repeat;
    background-size: 65%; }
  .path-culture .slider-nav-parent .slick-slide .cultureAwardsDefaultLogo {
    display: none; }
  .path-culture .slider-nav-parent .slick-slide .cultureAwardsWhiteLogo {
    display: block; } }

@media only screen and (max-width: 680px) {
  .path-culture .slider-nav-parent .slider-nav .slick-prev, .path-culture .slider-nav-parent .slider-nav .slick-next, .path-culture .slider-testi-nav-parent .slider-nav .slick-prev, .path-culture .slider-testi-nav-parent .slider-nav .slick-next {
    display: none !important; } }

@media only screen and (max-width: 767px) {
  .path-culture .awards-and-recognition {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .path-culture .right-content .inner-content img {
    width: 220px; }
  .path-culture .culture-title p:last-child {
    border-bottom: 4px solid #FFFFFF;
    margin-bottom: 5%; }
  .path-culture .culture-title a {
    color: #F36A30;
    font-family: "SourceSansPro-Semibold";
    display: block;
    width: 100%; }
  .path-culture .desktop-hide {
    display: block; }
  .path-culture .mobile-hide {
    display: none !important; }
  .path-culture .culture_sec_banner .second_content_culture {
    background-color: #886db0; }
    .path-culture .culture_sec_banner .second_content_culture .second_content_div {
      padding: 15px 0px 20px 15px; }
  .path-culture .erg_heading {
    text-align: left;
    padding: 0px 15px; }
  .path-culture .erg_groups .accordion .card-header {
    padding: 4px 0px 5px 0px;
    background-color: #fff !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    overflow: auto; }
    .path-culture .erg_groups .accordion .card-header a.collapsed {
      color: #212529; }
      .path-culture .erg_groups .accordion .card-header a.collapsed span.arrow {
        float: right;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        border-radius: 2px;
        -webkit-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #fff;
        margin-top: 5px;
        border-top: 3px solid #ccc;
        border-left: 3px solid #ccc; }
    .path-culture .erg_groups .accordion .card-header a {
      font-size: 18px;
      font-family: "sharpSans-Semibold";
      padding: 10px 0px;
      color: #F36A30;
      z-index: 500;
      width: 100%;
      float: left;
      text-decoration: none; }
      .path-culture .erg_groups .accordion .card-header a span.arrow {
        float: right;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        right: 3px;
        border-radius: 2px;
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 9px;
        border-top: 3px solid #F36A30;
        border-left: 3px solid #F36A30; }
  .path-culture .erg_groups {
    font-size: 18px;
    font-family: "sharpSans-Light"; }
  .path-culture .slider-testi-nav-parent .slider-nav .slick-prev, .path-culture .slider-testi-nav-parent .slider-nav .slick-next {
    display: none !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .path-culture .awards-and-recognition {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .path-culture .container-fluid-lg {
    padding: 0px 15px; }
  .path-culture .culture_testimonials {
    padding: 50px 0px 80px; }
  .path-culture .culture-title p {
    overflow: auto; }
  .path-culture .culture-title a {
    color: #F36A30;
    font-family: "SourceSansPro-Semibold";
    width: 100%;
    float: left; }
  .path-culture .slider-nav-parent .slick-list {
    width: 85%; }
  .path-culture .slider-nav-parent .slick-next {
    right: 10px; }
  .path-culture .slider-nav-parent .slick-prev {
    left: 10px; }
  .path-culture .slider-nav-parent .single-slider-main .slider-title {
    padding-top: 0px;
    font-size: 18px;
    line-height: 30px; }
  .path-culture .slider-nav-parent .slider-bottom .trophy-img {
    width: 35px;
    height: 32px;
    position: absolute;
    top: 9%;
    right: 13%;
    font-size: 50px; }
  .path-culture .slider-nav-parent .single-slider-main:hover .trophy-img {
    width: 35px !important;
    top: 9% !important;
    right: 13% !important; }
  .path-culture .culture_awards_recognition .carousel-control-next-icon, .path-culture .culture_awards_recognition .carousel-control-prev-icon {
    display: block; }
  .path-culture .culture-title a {
    color: #F36A30; }
  .path-culture .desktop-hide {
    display: block; }
  .path-culture .mobile-hide {
    display: none; }
  .path-culture .culture_sec_banner .second_content_culture {
    background-color: #dedede; }
    .path-culture .culture_sec_banner .second_content_culture .second_content_div {
      margin: 2% auto; }
      .path-culture .culture_sec_banner .second_content_culture .second_content_div .text-white {
        color: #000 !important; }
  .path-culture .erg_groups .accordion .card-header {
    background-color: #fff !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    overflow: auto; }
    .path-culture .erg_groups .accordion .card-header a.collapsed {
      color: #212529; }
      .path-culture .erg_groups .accordion .card-header a.collapsed span.arrow {
        float: right;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        border-radius: 2px;
        -webkit-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #fff;
        margin-top: 5px;
        border-top: 3px solid #ccc;
        border-left: 3px solid #ccc; }
    .path-culture .erg_groups .accordion .card-header a {
      font-size: 18px;
      font-family: "sharpSans-Semibold";
      padding: 10px 0px;
      color: #F36A30;
      width: 100%;
      float: left;
      text-decoration: none; }
      .path-culture .erg_groups .accordion .card-header a span.arrow {
        float: right;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        border-radius: 2px;
        right: 3px;
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 9px;
        border-top: 3px solid #F36A30;
        border-left: 3px solid #F36A30; }
  .path-culture .erg_groups {
    font-size: 18px;
    font-family: "sharpSans-Light"; }
  .path-culture .slider-for-parent .slider-right {
    min-height: 378px; }
    .path-culture .slider-for-parent .slider-right .slider-right-title h2 {
      font-size: 1rem; }
    .path-culture .slider-for-parent .slider-right .slider-body {
      font-size: 13px;
      line-height: 15px; }
  .path-culture .slider-testi-nav-parent .slider-nav .slick-prev, .path-culture .slider-testi-nav-parent .slider-nav .slick-next {
    display: none !important; } }

@media only screen and (min-width: 992px) {
  .path-culture .right-content .inner-content img {
    width: 200px; }
  .path-culture .desktop-hide {
    display: none; }
  .path-culture .mobile-hide {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .path-culture .culture_sec_banner .second_content_culture {
    background-color: #886db0; }
    .path-culture .culture_sec_banner .second_content_culture .second_content_div {
      padding: 9% 50px 7% 100px; }
  .path-culture .erg_heading {
    text-align: center; }
  .path-culture .carousel-control-next-icon, .path-culture .carousel-control-prev-icon {
    display: block; } }

@media only screen and (max-width: 400px) {
  .testimonial-for-main .brightcove-video-main .video-js {
    height: 180px !important; } }

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .path-culture .slider-for-parent .single-slider-img .slider-left {
    height: 423px; }
  .path-culture .slider-for-parent .slider-right {
    min-height: 423px; }
  .path-culture .testimonial-nav-main:after {
    transform: rotate(2deg); }
  .path-culture .testimonial-nav-main:before {
    transform: rotate(-2deg); }
  .path-culture .slider-nav-parent .single-slider-main {
    min-height: 200px; }
    .path-culture .slider-nav-parent .single-slider-main .slider-title {
      line-height: 28px; }
    .path-culture .slider-nav-parent .single-slider-main .cultureAwardsDefaultLogo, .path-culture .slider-nav-parent .single-slider-main .cultureAwardsWhiteLogo {
      width: 100px; }
  .path-culture .slider-nav-parent .single-slider-main:hover:before, .path-culture .slider-nav-parent .single-slider-main:hover:after {
    box-shadow: 0 19px 11px #777; } }

@media only screen and (min-width: 993px) and (max-width: 1100px) {
  .path-culture .culture_sec_banner .second_content_culture .second_content_div {
    padding: 30px 30px 30px 20px; } }

@media only screen and (min-width: 993px) and (max-width: 1199px) {
  .path-culture .slider-nav-parent .slick-list {
    width: 90%; }
  .path-culture .slider-nav-parent .single-slider-main {
    min-height: 250px; }
  .path-culture .slider-for-parent .single-slider-img .slider-left {
    background-size: 35% 50%; }
  .path-culture .culture_sec_banner .second_content_culture {
    background-color: #886db0; }
  .path-culture .slider-testi-nav-parent .slick-list {
    width: 90%; }
  .path-culture .slider-testi-nav-parent .slick-next {
    right: 10px; }
  .path-culture .slider-testi-nav-parent .slick-prev {
    left: 10px; } }

body.path-careers div#early-careers, body.path-careers div#professional-careers {
  border-top: 1px solid #cccccc; }

body.path-careers h2.title-value {
  margin-top: 42px;
  font-size: 40px;
  line-height: 46px;
  font-family: "sharpSans-Book"; }
  @media (max-width: 575.98px) {
    body.path-careers h2.title-value {
      font-size: 26px;
      line-height: 30px; } }
  @media (max-width: 767.98px) {
    body.path-careers h2.title-value {
      font-size: 26px;
      line-height: 30px; } }

body.path-careers .desc-value {
  margin-top: 20px;
  margin-bottom: 36px;
  font-size: 18px;
  line-height: 30px;
  font-family: "SourceSansPro-Regular"; }

body.path-careers h2.side-title, body.node--type-careers-article h2.side-title {
  font-size: 23px;
  font-family: "sharpSans-Semibold"; }
  @media (max-width: 767.98px) {
    body.path-careers h2.side-title, body.node--type-careers-article h2.side-title {
      font-size: 20px; } }
  body.path-careers h2.side-title a, body.node--type-careers-article h2.side-title a {
    font-size: 23px;
    font-family: "sharpSans-Semibold"; }

body.path-careers .title-border, body.node--type-careers-article .title-border {
  border-bottom: 5px solid #D0118C;
  width: 75px;
  margin-bottom: 63px; }
  @media (max-width: 991.98px) {
    body.path-careers .title-border, body.node--type-careers-article .title-border {
      margin-bottom: 20px; } }

.text-purple {
  color: #D0118C; }

/** Early Carrer section**/
body.path-careers .early-carrer-section .img-cover {
  text-align: center;
  background-color: #EEEEEE;
  height: 215px;
  line-height: 175px;
  margin-bottom: 35px;
  width: 100%; }

body.path-careers .early-carrer-section .img-cover img {
  max-width: 70%;
  height: auto;
  margin-top: 30px;
  max-height: 175px; }

/** Professional career section **/
body.path-careers .career-termlist {
  text-align: center;
  background-color: #EEEEEE;
  height: 180px;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%; }

@media (max-width: 991.98px) {
  body.path-careers .container-fluid-lg {
    padding: 0px 15px; } }

@media (max-width: 991.98px) {
  body.path-careers .container-fluid-lg .careerSideHeader {
    padding: 0px; } }

@media (max-width: 991.98px) {
  body.path-careers .container-fluid-lg .careerSideHeader .side-title, body.path-careers .container-fluid-lg .careerSideHeader .sidelink {
    padding: 5px 15px;
    margin-bottom: 0px; } }

@media (max-width: 991.98px) {
  body.path-careers .container-fluid-lg .careerSideHeader .title-border {
    margin-left: 15px; } }

@media (max-width: 991.98px) {
  body.path-careers .container-fluid-lg .mobileCareerSection {
    padding: 0px; } }

body.path-careers #early-careers .img-cont {
  padding: 0px 10px; }

body.path-careers #early-careers .img-cont:first-child {
  padding-left: 15px; }

body.path-careers #early-careers .img-cont:last-child {
  padding-right: 15px; }

body.path-careers .view-career-proffosinal .view-content.row {
  margin: 0px -25px; }
  @media (max-width: 991.98px) {
    body.path-careers .view-career-proffosinal .view-content.row {
      margin: 0px -15px; } }
  body.path-careers .view-career-proffosinal .view-content.row .career-col-sep {
    padding: 0px 10px; }
    @media (max-width: 991.98px) {
      body.path-careers .view-career-proffosinal .view-content.row .career-col-sep {
        padding: 0px; } }

body.path-careers .view-career-testimonial .justify-content-lg-center .img-cont {
  padding: 0px 10px; }
  @media (max-width: 991.98px) {
    body.path-careers .view-career-testimonial .justify-content-lg-center .img-cont {
      padding: 0px 7.5	px; } }

body.path-careers .career-termlist .career-cont-top {
  height: 180px;
  display: table;
  width: 100%; }

body.path-careers .career-termlist a:hover {
  text-decoration: none; }

body.path-careers .career-termlist .career-cont-bottom {
  height: 180px;
  width: 100%; }

body.path-careers .career-termlist:hover .career-cont-top {
  display: none; }

body.path-careers .career-termlist .career-cont-top .career-term {
  vertical-align: middle;
  display: table-cell;
  font-size: 20px;
  line-height: 26px;
  color: #2a2a2a;
  text-decoration: none;
  font-family: "sharpSans-Semibold";
  padding: 0px 20px;
  word-break: break-word; }

body.path-careers .career-termlist .career-cont-bottom .career-job-image {
  text-align: center; }

body.path-careers .career-termlist .career-cont-bottom .career-job-image img {
  width: 100px;
  height: 100px;
  margin-top: 10%; }

body.path-careers .career-termlist .career-cont-bottom .career-job-count {
  margin-top: 1%;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
  text-decoration: none;
  font-family: "sharpSans-Semibold"; }

body.path-careers div.career-col-sep .cr-tomato div.career-cont-bottom {
  background-color: #F36A30; }

body.path-careers div.career-col-sep .cr-blue div.career-cont-bottom {
  background-color: #009DD6; }

body.path-careers div.career-col-sep .cr-gray div.career-cont-bottom {
  background-color: #896EB1; }

body.path-careers div.career-col-sep .cr-yellow div.career-cont-bottom {
  background-color: #59A63F; }

body.path-careers div.career-col-sep .cr-tomato .career-termlist {
  border-bottom: 6px solid #F36A30; }

body.path-careers div.career-col-sep .cr-blue .career-termlist {
  border-bottom: 6px solid #009DD6; }

body.path-careers div.career-col-sep .cr-gray .career-termlist {
  border-bottom: 6px solid #896EB1; }

body.path-careers div.career-col-sep .cr-yellow .career-termlist {
  border-bottom: 6px solid #59A63F; }

/** More Job Opp **/
body.path-careers .more-job-opp-section .img-cover {
  text-align: center;
  background-color: #EEE;
  height: 295px;
  margin-bottom: 15px;
  width: 100%; }

body.path-careers .more-job-opp-section .img-cover img {
  max-width: 60%;
  height: auto;
  max-height: 170px; }

body.path-careers .more-job-opp-section .img-ele {
  height: 170px;
  width: 100%;
  line-height: 230px; }

/** Testimonial **/
body.path-careers .testimonial-career-section {
  margin-top: 120px; }

/** Career and future leader programme **/
body.path-careers .testimonial-career-section .cont-hide {
  display: none; }

body.path-careers .early-carrer-section .img-cover, body.path-careers .more-job-opp-section .img-cover {
  position: relative; }
  body.path-careers .early-carrer-section .img-cover a:hover, body.path-careers .more-job-opp-section .img-cover a:hover {
    text-decoration: none; }
  body.path-careers .early-carrer-section .img-cover a, body.path-careers .more-job-opp-section .img-cover a {
    color: #2a2a2a; }

body.path-careers .early-carrer-section .img-cover:before, body.path-careers .early-carrer-section .img-cover:after, body.path-careers .more-job-opp-section .img-cover:before, body.path-careers .more-job-opp-section .img-cover:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 19px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #EEE;
  -webkit-box-shadow: 0 16px 16px #777;
  box-shadow: 0 16px 16px #777;
  -webkit-transform: rotate(-4deg);
  -o-transform: rotate(-4deg);
  transform: rotate(-4deg); }

body.path-careers .early-carrer-section .img-cover:after, body.path-careers .more-job-opp-section .img-cover:after {
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  -o-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
  right: 10px;
  left: auto; }

body.path-careers .early-carrer-section .img-cont:hover .img-cover:before, body.path-careers .early-carrer-section .img-cont:hover .img-cover:after, body.path-careers .more-job-opp-section .img-cont:hover .img-cover:before, body.path-careers .more-job-opp-section .img-cont:hover .img-cover:after {
  -webkit-box-shadow: 0 20px 11px #777;
  -moz-box-shadow: 0 20px 11px #777;
  box-shadow: 0 20px 11px #777; }

.node--type-careers-article .brand-page-cont h1.title-value {
  font-family: "sharpSans-Light";
  margin-top: 5px; }

.node--type-careers-article .brand-page-cont h4 {
  font-size: 22px;
  margin-top: 24px;
  margin-bottom: 0px;
  line-height: 30px; }

.node--type-careers-article .brand-page-cont p {
  margin-top: 9px;
  margin-bottom: 0px; }

.node--type-careers-article .brand-page-cont p strong {
  margin-top: 24px;
  font-family: "SourceSansPro-Semibold";
  font-size: 23px; }

.node--type-careers-article .tt-view-benefits_tiles .program-img-container {
  padding: 0px; }
  @media (min-width: 992px) {
    .node--type-careers-article .tt-view-benefits_tiles .program-img-container {
      margin-top: 60px; } }

.node--type-careers-article .programjob-inner-container {
  padding-left: 7.5%;
  margin-bottom: 50px; }
  .node--type-careers-article .programjob-inner-container h3.side-cont-title {
    font-family: "sharpSans-Semibold";
    font-size: 23px;
    color: #2a2a2a;
    word-break: break-word; }
  .node--type-careers-article .programjob-inner-container p {
    font-size: 18px;
    line-height: 30px;
    margin-top: 16px;
    margin-bottom: 0px; }
  .node--type-careers-article .programjob-inner-container .apply-button-cont {
    margin-top: 16px; }
    .node--type-careers-article .programjob-inner-container .apply-button-cont a {
      font-family: "sharpSans-Bold";
      font-size: 18px;
      border-radius: 0px;
      padding-top: 2%;
      padding-bottom: 2%; }
      .node--type-careers-article .programjob-inner-container .apply-button-cont a i {
        font-size: 16px; }
  .node--type-careers-article .programjob-inner-container .apply-button-cont.disabled {
    cursor: not-allowed;
    color: #fff;
    background-color: #929599; }
  .node--type-careers-article .programjob-inner-container button.apply-button-cont {
    font-family: "sharpSans-Bold";
    font-size: 18px;
    border-radius: 0px;
    padding-top: 2%;
    padding-bottom: 2%; }
    .node--type-careers-article .programjob-inner-container button.apply-button-cont i {
      font-size: 16px; }
  .node--type-careers-article .programjob-inner-container .job-qualification-cont {
    padding-right: 55px; }
    .node--type-careers-article .programjob-inner-container .job-qualification-cont ul {
      margin: 0px;
      padding: 0px;
      padding-left: 20px; }
      .node--type-careers-article .programjob-inner-container .job-qualification-cont ul li {
        margin: 0px;
        padding: 0px;
        font-size: 18px;
        line-height: 30px;
        margin-top: 10px;
        margin-bottom: 0px;
        padding-left: 15px; }
    .node--type-careers-article .programjob-inner-container .job-qualification-cont h3.side-cont-title {
      margin-bottom: 6px; }
  .node--type-careers-article .programjob-inner-container .job-apply-cointer {
    padding-left: 55px; }

.node--type-careers-article #block-contentfield-2 {
  width: 100%; }
  .node--type-careers-article #block-contentfield-2 .fetured-bottom-cont p {
    margin-top: 1%;
    line-height: 2.0rem; }

@media (min-width: 1200px) {
  .node--type-careers-article .job-qualification-cont {
    padding-right: 76px; } }

.node--type-careers-article .container .log-img {
  display: none; }

.node--type-careers-article .programjob-main-container {
  margin-top: 60px; }

.node--type-careers-article h2.side-title a {
  color: #212529;
  text-decoration: none; }

.node--type-careers-article div.Apply-button-cont i {
  padding-top: 7px;
  padding-left: 7px;
  font-size: 15px;
  font-weight: 600; }

.node--type-careers-article div.Apply-button-cont a {
  color: #fff;
  margin-top: 35px;
  background-color: #929599;
  font-family: "SourceSansPro-Semibold";
  width: 130px;
  height: 45px; }

.node--type-careers-article div.Apply-button-cont a:hover {
  background-color: #009DD6; }

.node--type-careers-article button.Apply-button-cont i {
  float: right;
  padding-top: 10px;
  padding-left: 7px;
  font-size: 15px; }

.node--type-careers-article button.Apply-button-cont {
  color: #FFFFFF;
  background-color: #929599;
  cursor: pointer;
  width: 120px;
  margin: 0 auto;
  line-height: 35px;
  font-size: 18px;
  font-family: "SourceSansPro-Semibold"; }

.node--type-careers-article .Apply-button-cont.disabled {
  cursor: not-allowed; }

.node--type-article .cd-words-wrapper b {
  white-space: normal; }

.node--type-article h3.title-value {
  margin-bottom: 20px; }

.node--type-article .field--name-body {
  margin-bottom: 50px; }

.node--type-article .side-cont-title {
  font-size: 40px;
  font-family: "sharpSans-Book";
  margin-bottom: 30px;
  word-break: break-word; }

.node--type-article .qualification-desc {
  font-family: "SourceSansPro-Regular"; }

.listOrdered {
  list-style: none;
  padding: 0; }

.path-careers .cd-words-wrapper b {
  white-space: normal; }

.path-careers .sidelink {
  font-size: 20px;
  line-height: 26px;
  font-family: "sharpSans-Semibold";
  margin-bottom: 16px; }
  .path-careers .sidelink a {
    color: #686868;
    font-size: 20px;
    line-height: 26px;
    font-family: "SourceSansPro-Semibold";
    text-decoration: none; }
    @media (max-width: 575.98px) {
      .path-careers .sidelink a {
        font-size: 18px;
        line-height: 38px;
        color: #D0118C;
        width: 100%;
        display: block; } }
    @media (max-width: 767.98px) {
      .path-careers .sidelink a {
        font-size: 18px;
        line-height: 38px;
        color: #D0118C; } }

.path-careers .sidelink:last-child {
  margin-bottom: 30px !important; }

.path-careers .sidelink:first-of-type a {
  color: #D0118C !important; }

.path-careers .field--name-field-job-name a {
  color: #2a2a2a;
  font-size: 20px;
  line-height: 26px;
  font-family: "sharpSans-Semibold";
  text-decoration: none; }

.path-careers .field--name-field-job-name a:hover {
  color: #D0118C; }

@media (max-width: 575.98px) {
  .path-careers .field--name-field-job-name {
    padding-bottom: 30px; }
    .path-careers .field--name-field-job-name a {
      color: #D0118C; } }

@media (max-width: 767.98px) {
  .path-careers .field--name-field-job-name {
    padding-bottom: 30px; }
    .path-careers .field--name-field-job-name a {
      color: #D0118C; } }

.path-careers .tt-view-carrer_job-opp {
  margin-top: 20px; }

.path-careers div.brand-page-cont h2 {
  font-size: 40px;
  line-height: 46px;
  font-family: "sharpSans-Book";
  padding-bottom: 25px; }
  @media (max-width: 575.98px) {
    .path-careers div.brand-page-cont h2 {
      font-size: 26px;
      line-height: 30px; } }
  @media (max-width: 767.98px) {
    .path-careers div.brand-page-cont h2 {
      font-size: 26px;
      line-height: 30px; } }

.path-careers div.brand-page-cont p {
  font-size: 18px;
  color: #2a2a2a;
  margin-top: 10px;
  font-family: "SourceSansPro-Regular";
  line-height: 30px;
  padding-bottom: 33px; }

.path-careers #more-jobopportunities {
  margin-top: 50px; }
  @media (max-width: 991.98px) {
    .path-careers #more-jobopportunities > .row {
      margin: 0px; } }
  .path-careers #more-jobopportunities .view-id-career_testimonial .row.justify-content-lg-center {
    margin-bottom: 5px; }
    @media (max-width: 767.98px) {
      .path-careers #more-jobopportunities .view-id-career_testimonial .row.justify-content-lg-center {
        margin-bottom: 0px; } }
  .path-careers #more-jobopportunities .view-id-career_testimonial .row.justify-content-lg-center:last-child {
    margin-bottom: 40px; }
  .path-careers #more-jobopportunities .title-field {
    font-size: 20px;
    line-height: 26px;
    padding: 30px 15px;
    text-decoration: none;
    font-family: "sharpSans-Semibold";
    color: #2a2a2a; }
    @media (max-width: 575.98px) {
      .path-careers #more-jobopportunities .title-field {
        font-size: 18px;
        line-height: 26px;
        padding: 30px 10%; } }
    @media (max-width: 767.98px) {
      .path-careers #more-jobopportunities .title-field {
        font-size: 18px;
        line-height: 26px;
        padding: 30px 10%; } }
  .path-careers #more-jobopportunities a {
    text-decoration: none;
    color: #2a2a2a; }

.path-careers .testimonial-career-section .cont-right .name-value {
  font-size: 22px;
  line-height: 26px;
  font-family: "sharpSans-Semibold";
  color: #2a2a2a;
  padding-bottom: 10px; }

.path-careers .testimonial-career-section .cont-right .desig-value {
  color: #2a2a2a;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 30px;
  font-family: "SourceSansPro-Semibold"; }

.path-careers .testimonial-career-section .cont-right .desc-value {
  color: #2a2a2a;
  font-size: 16px;
  line-height: 24px;
  font-family: "sharpSans-Book"; }

.path-careers .slick-prev, .path-careers .slick-next {
  font-size: 15px !important;
  margin: 0px 15px; }

.path-careers .slick-prev:before, .path-careers .slick-next:before {
  content: '' !important; }

.path-careers .slick-arrow {
  border-radius: 0px !important;
  height: 20px;
  width: 21px;
  z-index: 1;
  display: none;
  border: none;
  float: left; }

.path-careers .carousel-control-next-icon, .path-careers .carousel-control-prev-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: block;
  background-size: 100% 100% !important;
  background-repeat: no-repeat; }

.path-careers .carousel-control-next-icon {
  background-image: url(../images/icons/carousel-right.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-careers .carousel-control-prev-icon {
  background-image: url(../images/icons/carousel-left.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-careers .carousel-control-next-icon, .path-careers .carousel-control-prev-icon {
  border: 1px solid #FFF;
  background-color: transparent; }

.path-careers .slick-slider .slick-list {
  width: 95% !important;
  margin: auto; }
  .path-careers .slick-slider .slick-list .slick-slide .slide__content .img-cover {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 0 auto; }
    @media (max-width: 767.98px) {
      .path-careers .slick-slider .slick-list .slick-slide .slide__content .img-cover {
        width: 180px;
        height: 180px; } }
    .path-careers .slick-slider .slick-list .slick-slide .slide__content .img-cover img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.path-careers .slick__arrow {
  top: 35%; }

.path-careers .bg-img-text-career {
  background-image: url("../images/svg/Typography_Art_Gray_Verylight.png");
  /*background-size: 70% 75%;*/
  background-size: contain;
  background-position: right 0% bottom 0%;
  background-repeat: no-repeat;
  z-index: 4;
  position: relative;
  /*@include media-breakpoint-down(sm){
			background-size: 100% 42%;
		}*/ }

.path-careers .site-footer {
  margin-top: 0 !important;
  padding-top: 80px; }
  @media (max-width: 991.98px) {
    .path-careers .site-footer {
      padding-top: 10px; } }

@media only screen and (max-width: 767px) {
  body.path-careers .more-job-opp-section .title-value {
    margin-bottom: 5px;
    text-align: center; }
  body.path-careers .professional-career-section .career-col-sep {
    padding-left: 0px; }
    body.path-careers .professional-career-section .career-col-sep a {
      color: #D0118C;
      font-size: 18px;
      font-family: "sharpSans-Semibold"; }
    body.path-careers .professional-career-section .career-col-sep .career-termname {
      padding-bottom: 15px;
      padding-top: 15px;
      padding-left: 15px; }
  body.path-careers .sidelink {
    color: #D0118C; }
  body.path-careers .testimonial-career-section .mobile-display {
    display: block; }
    body.path-careers .testimonial-career-section .mobile-display .slick__arrow {
      /** display:none; **/ }
    body.path-careers .testimonial-career-section .mobile-display .cont-right {
      text-align: center;
      margin-top: 7%; }
  .node--type-careers-article #block-contentfield-2 .img-cover .img-cover-left {
    clear: both;
    width: 100%; }
  .node--type-careers-article #block-contentfield-2 .img-cover .img-cover-right {
    clear: both;
    width: 100%; }
  .node--type-careers-article #block-contentfield-2 .img-cover img {
    width: 100%;
    height: 100%; }
  .node--type-careers-article #block-contentfield-2 .fetured-bottom-cont {
    margin-top: 4%; }
  .node--type-careers-article .container .imgcover-top {
    display: block;
    margin-top: 1%;
    margin-bottom: 3%; }
  .node--type-careers-article .bottom-banner-section-sep .program-image-top {
    display: block !important; }
  .node--type-careers-article div.brand-page-cont p {
    line-height: 35px; }
  .node--type-careers-article .job-qualification-cont {
    margin-bottom: 24px; }
  body.path-careers h2.side-title {
    margin-bottom: 10px; }
  .brand-page-cont h2 {
    padding-top: 40px;
    border-top: 1px solid #A7A9AC; }
  .tt-view-carrer_job-opp .col-auto {
    padding-left: 15px !important; }
  body.path-careers .more-job-opp-section .img-cover img {
    max-width: 60% !important; }
  .slick-slider .slick-list {
    width: 100% !important;
    margin: auto; }
  .testimonial-career-section .col-auto {
    padding-right: 0px !important;
    padding-left: 0px !important; }
  .testimonial-career-section .row {
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .testimonial-career-section-cont .testimonial-career-section {
    padding: 0px !important; }
  .testimonial-career-section-cont {
    padding-right: 0px !important;
    padding-left: 0px !important; }
  .testimonial-career-section .slick > div {
    margin-right: 0px !important;
    margin-left: 0px !important; }
  body.path-careers #main-wrapper .view-career-testimonial .slick-slide .img-cover img {
    margin: auto; }
  .jobopportunities {
    padding-right: 0px !important;
    padding-left: 0px !important; }
  .jobopportunities-lg {
    padding-right: 0px !important;
    padding-left: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.path-careers .more-job-opp-section .title-value {
    margin-bottom: 40px;
    text-align: center; }
  body.path-careers .professional-career-section .career-col-sep {
    padding-left: 0px; }
    body.path-careers .professional-career-section .career-col-sep a {
      color: #D0118C;
      font-size: 26px;
      line-height: 46px;
      font-family: "sharpSans-Semibold";
      width: 100%;
      display: block; }
    body.path-careers .professional-career-section .career-col-sep a:hover {
      text-decoration: none; }
    body.path-careers .professional-career-section .career-col-sep .career-termname {
      padding: 10px 0px 10px 15px; }
  body.path-careers .sidelink {
    color: #D0118C; }
  body.path-careers .testimonial-career-section .ipad-display {
    display: block; }
  .node--type-careers-article #block-contentfield-2 .img-cover {
    height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover .img-cover-left {
      float: left;
      width: 67%;
      height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover .img-cover-right {
      float: right;
      width: 33%;
      height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover img {
      width: 100%;
      height: 100%; }
  .node--type-careers-article #block-contentfield-2 .fetured-bottom-cont {
    margin-top: 4%; }
  .node--type-careers-article .container .imgcover-bottom {
    display: block;
    margin-top: 1%;
    margin-bottom: 3%; }
  /*.featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-info-text .cd-headline .cd-words-wrapper{
		width:50% !important;
	}*/
  body.path-careers h2.side-title {
    margin-bottom: 10px; }
  div.brand-page-cont {
    margin-top: 30px; }
  .path-careers #more-jobopportunities .title-field {
    font-size: 18px;
    line-height: 24px; }
  body.path-careers #main-wrapper .view-career-testimonial .slick-slide .img-cover img {
    margin: auto; }
  .node--type-careers-article .container {
    margin-bottom: 20px !important; } }

@media only screen and (min-width: 1025px) {
  body.path-careers .carousel-control-next-icon:hover {
    background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
    -webkit-transform: scale(1.2);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: scale(1.2);
    /* IE 9 */
    transform: scale(1.2);
    background-size: 100% 100%;
    background-repeat: no-repeat; }
  body.path-careers .carousel-control-prev-icon:hover {
    background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
    -webkit-transform: scale(1.2);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: scale(1.2);
    /* IE 9 */
    transform: scale(1.2);
    background-size: 100% 100%;
    background-repeat: no-repeat; } }

@media only screen and (min-width: 992px) {
  body.path-careers .more-job-opp-section .title-value {
    text-align: center;
    margin-bottom: 40px; }
  body.path-careers .sidelink a:hover {
    color: #D0118C;
    text-decoration: none; }
  body.path-careers .testimonial-career-section .desktop-display {
    display: block; }
  .node--type-careers-article #block-contentfield-2 .img-cover {
    height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover .img-cover-left {
      float: left;
      width: 67%;
      height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover .img-cover-right {
      float: right;
      width: 33%;
      height: 520px; }
    .node--type-careers-article #block-contentfield-2 .img-cover img {
      width: 100%;
      height: 100%; }
  .node--type-careers-article #block-contentfield-2 .fetured-bottom-cont {
    margin-top: 1%; }
  .node--type-careers-article .custom-nav-height {
    margin-top: 140px; } }

@media (max-width: 767.98px) {
  .path-careers p.sidelink a, .path-careers li.sidelink a, .path-careers .sidelink a .field__item, .path-careers .professional-career-section .career-col-sep span, .path-careers .professional-career-section .career-col-sep a {
    display: block;
    width: 100%;
    text-decoration: none; }
  .node--type-careers-article .container {
    margin-bottom: 20px !important; } }

.path-careers-future-leader-programme .text-heading {
  color: #D0118C; }

@media (min-width: 768px) and (max-width: 992px) {
  .path-careers p.sidelink a, .path-careers li.sidelink a, .path-careers .sidelink a .field__item {
    display: block;
    width: 100%;
    text-decoration: none; }
  .node--type-careers-article .bottom-banner-section-sep {
    padding-top: 25px; }
    .node--type-careers-article .bottom-banner-section-sep .program-img-container {
      max-height: 270px;
      min-height: 270px; }
      .node--type-careers-article .bottom-banner-section-sep .program-img-container .program-image-bottom {
        width: 36%; }
      .node--type-careers-article .bottom-banner-section-sep .program-img-container .program-image-top {
        width: 64%; }
  .node--type-careers-article .programjob-main-container {
    margin-top: 45px; }
  .node--type-careers-article .programjob-inner-container .job-apply-cointer {
    padding-top: 50px; }
  body.path-careers .desc-value {
    margin-bottom: 45px; }
  body.path-careers h2.title-value {
    margin-top: 45px; }
  body.path-careers #early-careers .img-cont {
    padding: 0px 7px; }
  body.path-careers .more-job-opp-section .img-cover {
    height: 180px; }
    body.path-careers .more-job-opp-section .img-cover .img-ele {
      line-height: 100px;
      height: auto; }
    body.path-careers .more-job-opp-section .img-cover img {
      max-height: 80px; }
  body.path-careers #more-jobopportunities .title-field {
    padding: 0px 15px; }
  body.path-careers .view-id-career_testimonial .row.justify-content-lg-center {
    margin-bottom: 5px !important; } }

@media (min-width: 993px) and (max-width: 1199px) {
  .node--type-careers-article .programjob-main-container {
    margin-top: 45px; }
  .node--type-careers-article .view-bottom-banner-section .bottom-banner-section-sep .program-img-container {
    max-height: 270px; }
  body.path-careers .desc-value {
    margin-bottom: 45px; }
  body.path-careers h2.title-value {
    margin-top: 45px; }
  body.path-careers #early-careers .img-cont {
    padding: 0px 7px; }
  body.path-careers .more-job-opp-section .img-cover {
    height: 180px; }
    body.path-careers .more-job-opp-section .img-cover .img-ele {
      line-height: 100px;
      height: auto; }
    body.path-careers .more-job-opp-section .img-cover img {
      max-height: 80px; }
  body.path-careers #more-jobopportunities .title-field {
    padding: 0px 15px; } }

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  body.path-careers .testimonial-career-section .desktop-display, body.path-careers .testimonial-career-section .mobile-display {
    display: none; }
  body.path-careers .testimonial-career-section .ipad-display {
    display: block; } }

.testimonial-career-section-cont .slick__slide li {
  border: none; }

/** Program and Specialized programme **/
body.node--type-specialized-programs .sp_header_banner {
  margin-bottom: 50px; }
  body.node--type-specialized-programs .sp_header_banner .featured-top {
    margin-bottom: 0px; }
    body.node--type-specialized-programs .sp_header_banner .featured-top .field--name-field-banner-image img {
      margin-bottom: 0px; }

body.node--type-specialized-programs .view-specialized-program-image .program-img-container span.over-thumbnail {
  width: 100%;
  height: 100%;
  position: absolute; }

body.node--type-specialized-programs div.content_area {
  margin: 0px;
  padding: 0px;
  color: #2A2A2A;
  min-height: 250px; }
  body.node--type-specialized-programs div.content_area h3.side-title a {
    font-family: "sharpSans-Semibold";
    line-height: 33px;
    font-size: 23px;
    color: #2A2A2A;
    text-decoration: none; }
  body.node--type-specialized-programs div.content_area .title-border {
    width: 75px;
    height: 6px;
    background-color: #009DD6; }
  body.node--type-specialized-programs div.content_area .sub-title-border {
    width: 90px;
    height: 1px;
    background-color: #A7a9a3; }
  body.node--type-specialized-programs div.content_area .brand-page-cont h2.body-title {
    font-family: "sharpSans-Light";
    margin-top: 5px; }
  body.node--type-specialized-programs div.content_area .brand-page-cont h4 {
    font-size: 22px;
    margin-top: 24px;
    margin-bottom: 0px;
    line-height: 30px; }
  body.node--type-specialized-programs div.content_area .brand-page-cont p {
    margin-top: 9px;
    margin-bottom: 0px; }
  body.node--type-specialized-programs div.content_area .brand-page-cont p strong {
    margin-top: 24px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 23px; }
  body.node--type-specialized-programs div.content_area .col-lg-3 {
    padding-left: 0px; }
    @media (max-width: 991.98px) {
      body.node--type-specialized-programs div.content_area .col-lg-3 {
        padding-right: 0px; } }
  body.node--type-specialized-programs div.content_area .col-lg-9 {
    padding-left: 5px; }
  @media (max-width: 991.98px) {
    body.node--type-specialized-programs div.content_area .specialized_SubHeader {
      margin: 0px -15px; } }
  @media (max-width: 991.98px) {
    body.node--type-specialized-programs div.content_area .specialized_SubHeader .item-list {
      width: 100%;
      margin-top: 10px; } }

body.node--type-specialized-programs h3.bussiness-title {
  word-break: break-word;
  font-family: "sharpSans-Semibold";
  font-size: 23px;
  line-height: 26px;
  word-break: break-word; }

body.node--type-specialized-programs .program-img-container {
  margin: 0px;
  padding: 0px; }
  body.node--type-specialized-programs .program-img-container .program-image-top, body.node--type-specialized-programs .program-img-container .program-image-bottom {
    padding: 0px; }
    body.node--type-specialized-programs .program-img-container .program-image-top img, body.node--type-specialized-programs .program-img-container .program-image-bottom img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 0 0; }

body.node--type-specialized-programs .tt-view-spprogram, body.node--type-specialized-programs .tt-view-spprogram .views-row {
  width: 100%; }

body.node--type-specialized-programs .tt-view-program-with-argument .video-cont {
  padding: 0px; }
  @media (min-width: 992px) {
    body.node--type-specialized-programs .tt-view-program-with-argument .video-cont {
      margin-top: 60px; } }

body.node--type-specialized-programs div.apply-button-cont i {
  float: right;
  padding-top: 6px;
  padding-left: 7px;
  font-size: 15px; }

body.node--type-specialized-programs div.apply-button-cont a {
  padding: 0px !important;
  margin: 0px !important; }
  body.node--type-specialized-programs div.apply-button-cont a button.active {
    color: #FFFFFF !important;
    background-color: #009DD6 !important;
    width: 120px;
    height: 45px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
    border: none !important; }

body.node--type-specialized-programs div.apply-button-cont button.disabled {
  color: #fff;
  background-color: #929599; }

body.node--type-specialized-programs div.apply-button-cont a:hover {
  background-color: #009DD6; }

body.node--type-specialized-programs button.apply-button-cont i {
  float: right;
  padding-top: 6px;
  padding-left: 7px;
  font-size: 15px; }

body.node--type-specialized-programs button.apply-button-cont {
  color: #fff;
  background-color: #929599;
  width: 120px;
  height: 45px;
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

body.node--type-specialized-programs .program-image-top {
  display: block;
  position: relative; }

body.node--type-specialized-programs .program-image-top:hover span.over-lay {
  -moz-opacity: .80;
  opacity: .80;
  filter: alpha(opacity=80);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 100px;
  height: 100px;
  background: url(../images/playbutton.png) no-repeat;
  background-size: 75%; }

body.node--type-specialized-programs .tt-view-program li a {
  color: #686868 !important;
  font-family: "SourceSansPro-Semibold" !important; }

body.node--type-specialized-programs .tt-view-program li.active a {
  color: #009DD6 !important; }

body.path-programs .site-footer {
  margin-top: 15px !important; }

@media (max-width: 991.98px) {
  body.path-programs .container-fluid-lg {
    padding: 0px 15px; } }

body.path-programs .featured-top {
  margin-bottom: 45px;
  /*.cd-words-wrapper {
			min-height:192px;
			height:192px;
			@include media-breakpoint-down(sm) {
				min-height:140px;
				height:140px;
				padding-bottom:10px;
			}
		}*/ }
  body.path-programs .featured-top .field--name-field-banner-image img {
    margin-bottom: 0px; }

body.path-programs .business_tiles {
  padding-top: 55px; }
  @media (max-width: 991.98px) {
    body.path-programs .business_tiles {
      padding-top: 0px; } }

body.path-programs div.content_area {
  margin: 0px;
  padding: 0px;
  color: #2A2A2A;
  min-height: 250px; }
  body.path-programs div.content_area h3.side-title {
    font-family: "sharpSans-Semibold";
    line-height: 33px;
    font-size: 23px; }
  body.path-programs div.content_area h2.side-title {
    font-family: "sharpSans-Semibold";
    line-height: 33px;
    font-size: 23px; }
  body.path-programs div.content_area .title-border {
    width: 75px;
    height: 6px;
    background-color: #009DD6; }
  body.path-programs div.content_area .brand-page-cont h2 {
    font-family: "sharpSans-Book";
    margin-top: 5px; }
  body.path-programs div.content_area .brand-page-cont p:first-child {
    margin-top: 20px;
    margin-bottom: 0px; }
  body.path-programs div.content_area .brand-page-cont p:last-child {
    margin-top: 20px;
    margin-bottom: 0px; }

body.path-programs .secondary_banner_section {
  background: #deb932; }
  body.path-programs .secondary_banner_section .secondary_banner_img img {
    width: 100%;
    height: 100%;
    max-height: 520px;
    overflow: hidden;
    object-fit: cover; }
  body.path-programs .secondary_banner_section .secondary_banner_img {
    height: auto !important; }
  @media (max-width: 991.98px) {
    body.path-programs .secondary_banner_section {
      margin-top: 80px; } }
  @media (max-width: 767.98px) {
    body.path-programs .secondary_banner_section {
      margin-top: 0px; } }

body.path-programs .view-program-business {
  max-width: 1020px;
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    body.path-programs .view-program-business {
      margin-top: 50px; } }
  @media (max-width: 991.98px) {
    body.path-programs .view-program-business {
      margin-top: 45px; } }
  body.path-programs .view-program-business .program-col-sep .row-col .tiles-header .content_term_datas {
    list-style: none;
    padding: 0; }

body.path-programs .play_now {
  display: block;
  position: relative; }

body.path-programs .play_now:hover span {
  -moz-opacity: .80;
  opacity: .80;
  filter: alpha(opacity=80);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 100px;
  height: 100px;
  background: url(../images/playbutton.png) no-repeat;
  background-size: 75%; }

body.path-programs .secondary_banner_content {
  height: auto !important; }

body.path-programs .secondary_content_div {
  display: table-cell;
  vertical-align: middle;
  padding: 30px 50px 35px 100px; }
  @media (max-width: 1199.98px) {
    body.path-programs .secondary_content_div {
      padding: 30px 20px 30px 105px;
      padding-left: 50px; } }
  @media (max-width: 991.98px) {
    body.path-programs .secondary_content_div {
      padding: 30px 20px 35px 105px;
      padding-left: 50px; } }
  @media (max-width: 767.98px) {
    body.path-programs .secondary_content_div {
      padding: 30px 15px 35px 15px; } }
  body.path-programs .secondary_content_div .btn:hover {
    background-color: #fff;
    color: #deb932; }
  @media (min-width: 992px) {
    body.path-programs .secondary_content_div p {
      margin-bottom: 30px;
      line-height: 25px; } }
  @media (max-width: 991.98px) {
    body.path-programs .secondary_content_div p {
      line-height: 23px;
      margin-bottom: 20px; } }
  body.path-programs .secondary_content_div h2 {
    line-height: 42px; }
    @media (max-width: 991.98px) {
      body.path-programs .secondary_content_div h2 {
        line-height: 35px; } }

@media (min-width: 576px) {
  body.node--type-specialized-programs #mymodal .modal-dialog, body.node--type-specialized-programs #mymodal_one .modal-dialog {
    max-width: 650px; } }

body.node--type-specialized-programs #mymodal .modal-dialog .modal-body .video-js, body.node--type-specialized-programs #mymodal_one .modal-dialog .modal-body .video-js {
  height: 350px;
  width: 100%; }
  @media (max-width: 767.98px) {
    body.node--type-specialized-programs #mymodal .modal-dialog .modal-body .video-js, body.node--type-specialized-programs #mymodal_one .modal-dialog .modal-body .video-js {
      height: 205px; } }

@media only screen and (max-width: 400px) {
  #mymodal .modal-body .video-js, #mymodal_one .modal-body .video-js {
    height: 184px !important; } }

form#node-specialized-programs-form {
  border: 1px solid red; }

@media only screen and (max-width: 767px) {
  body.path-programs .view-program-business {
    margin-bottom: 35px; }
    body.path-programs .view-program-business .program-col-sep .row-col {
      border-bottom: 1px solid #A7a9a3;
      margin-bottom: 15px;
      width: 100%; }
      body.path-programs .view-program-business .program-col-sep .row-col .views-field-name {
        font-family: "sharpSans-Semibold";
        font-size: 18px;
        color: #2a2a2a;
        word-break: break-word; }
      body.path-programs .view-program-business .program-col-sep .row-col .content_parentterm {
        font-size: 22px; }
        body.path-programs .view-program-business .program-col-sep .row-col .content_parentterm .views-field-name {
          padding-left: 15px; }
      body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas {
        margin: 10px 0; }
        body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas .term_data {
          padding: 15px 0px 15px 15px;
          margin-bottom: 0; }
          body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas .term_data a {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            font-style: normal;
            font-size: 18px;
            color: #2A2A2A;
            width: 100%;
            display: block;
            word-break: break-word;
            text-transform: capitalize;
            text-decoration: none; }
          body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas .term_data a:hover {
            text-decoration: underline; }
        body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas .term_data:last-child {
          margin-bottom: 0; }
    body.path-programs .view-program-business .program-col-sep:last-child .row-col {
      margin-bottom: 0;
      border-bottom: none; }
      body.path-programs .view-program-business .program-col-sep:last-child .row-col .content_term_datas {
        margin-bottom: 0; }
        body.path-programs .view-program-business .program-col-sep:last-child .row-col .content_term_datas .term_data:last-child {
          margin-bottom: 0; }
  body.path-programs .secondary_banner_content {
    padding: 0px; }
  body.path-programs .content_area > div:first-child, body.path-programs .content_area > div:last-child {
    padding-left: 0px; }
  body.path-programs .featured-top {
    margin-bottom: 40px; }
  body.path-programs .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    z-index: 1; }
  body.path-programs div.content_area {
    min-height: 600px; }
    body.path-programs div.content_area h3.side-title {
      font-size: 20px; }
    body.path-programs div.content_area .title-border {
      width: 65px; }
    body.path-programs div.content_area .brand-page-cont h2 {
      font-size: 26px;
      margin-bottom: 0px;
      margin-top: 8%; }
    body.path-programs div.content_area .brand-page-cont p {
      margin-top: 10px !important;
      margin-bottom: 0px !important; }
  body.path-programs .business_tiles {
    margin: 0px -15px; }
    body.path-programs .business_tiles .row {
      margin: 0px; }
    body.path-programs .business_tiles .col-auto, body.path-programs .business_tiles .program-col-sep {
      padding-left: 0px;
      padding-right: 0px; }
  body.node--type-specialized-programs .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    z-index: 1; } }

@media only screen and (max-width: 767px) and (max-width: 991.98px) {
  body.node--type-specialized-programs .container-fluid-lg {
    padding: 0px 15px; } }

@media only screen and (max-width: 767px) {
  body.node--type-specialized-programs .container {
    padding: 0px;
    margin: 0px; }
    body.node--type-specialized-programs .container .col-md-12 {
      margin: 0px;
      padding: 0px; }
  body.node--type-specialized-programs .sp_header_banner {
    margin: 0px;
    margin-bottom: 40px; }
  body.node--type-specialized-programs .cd-words-wrapper {
    min-height: 192px;
    height: 192px; } }
  @media only screen and (max-width: 767px) and (max-width: 767.98px) {
    body.node--type-specialized-programs .cd-words-wrapper {
      min-height: 140px;
      height: 140px;
      padding-bottom: 10px; } }

@media only screen and (max-width: 767px) {
  body.node--type-specialized-programs .content_area .content_area > div:first-child {
    min-height: 190px; }
  body.node--type-specialized-programs .content_area h3.side-title {
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    line-height: 30px; }
  body.node--type-specialized-programs .content_area .title-border {
    width: 65px; }
  body.node--type-specialized-programs .content_area .bussiness-title {
    margin-top: 44px;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    line-height: 40px; }
  body.node--type-specialized-programs .content_area .sub-title-border {
    background-color: #a7a9ac; }
  body.node--type-specialized-programs .content_area .tt-view-program {
    margin: 0px;
    padding: 0px;
    padding-bottom: 35px;
    border-bottom: 1px solid #A7A9Ac; }
    body.node--type-specialized-programs .content_area .tt-view-program ul {
      margin: 0px;
      padding: 0px; }
      body.node--type-specialized-programs .content_area .tt-view-program ul li {
        margin: 0px;
        padding: 0px;
        line-height: 50px; }
        body.node--type-specialized-programs .content_area .tt-view-program ul li .field-content {
          display: block;
          width: 100%;
          padding-left: 15px; }
        body.node--type-specialized-programs .content_area .tt-view-program ul li a {
          font-size: 18px;
          line-height: 30px;
          word-break: break-word;
          text-decoration: none;
          width: 100%;
          display: inline-block;
          margin-top: 15px;
          margin-bottom: 0px;
          font-family: "SourceSansPro-Regular"; }
  body.node--type-specialized-programs .content_area .brand-page-cont {
    margin-top: 30px;
    margin-bottom: 45px; }
    body.node--type-specialized-programs .content_area .brand-page-cont h2.body-title {
      font-size: 26px;
      font-family: "sharpSans-Book" !important;
      border: none;
      padding-top: 0px; }
    body.node--type-specialized-programs .content_area .brand-page-cont h4 {
      font-size: 18px !important; }
    body.node--type-specialized-programs .content_area .brand-page-cont p {
      font-size: 18px !important;
      margin-top: 10px !important;
      font-family: "SourceSansPro-Regular"; }
      body.node--type-specialized-programs .content_area .brand-page-cont p strong {
        margin-top: 45px !important;
        font-size: 20px !important;
        font-family: "SourceSansPro-Semibold" !important; }
  body.node--type-specialized-programs .program-img-container {
    min-height: 512px; }
  body.node--type-specialized-programs .programjob-main-container {
    padding-top: 45px; }
    body.node--type-specialized-programs .programjob-main-container .program-page-link {
      margin: 35px -15px 0px -15px;
      padding: 10px 15px; }
      body.node--type-specialized-programs .programjob-main-container .program-page-link a {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 18px;
        width: 100%;
        display: block;
        text-decoration: none;
        color: #009DD6; }
    body.node--type-specialized-programs .programjob-main-container .col-12 {
      margin: 0px;
      padding: 0px; }
    body.node--type-specialized-programs .programjob-main-container .job-qualification-cont {
      margin-bottom: 30px; }
      body.node--type-specialized-programs .programjob-main-container .job-qualification-cont h3 {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 20px; }
      body.node--type-specialized-programs .programjob-main-container .job-qualification-cont ul {
        margin: 0px;
        padding: 0px;
        padding-left: 20px; }
        body.node--type-specialized-programs .programjob-main-container .job-qualification-cont ul li {
          margin: 0px;
          padding: 0px;
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 0px;
          padding-left: 25px; }
    body.node--type-specialized-programs .programjob-main-container .job-apply-cointer .side-cont-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 20px; }
    body.node--type-specialized-programs .programjob-main-container .job-apply-cointer p {
      font-size: 18px;
      margin-top: 30px; }
    body.node--type-specialized-programs .programjob-main-container .job-apply-cointer .apply-button-cont {
      margin-top: 45px;
      width: 130px;
      height: 45px;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      letter-spacing: 0.5px;
      border-radius: inherit; }
      body.node--type-specialized-programs .programjob-main-container .job-apply-cointer .apply-button-cont a {
        text-decoration: none; }
  body.node--type-specialized-programs .view-program-termname {
    position: relative; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-info-text {
      width: 50%;
      position: relative;
      top: 5%;
      left: 5%;
      color: #FFFFFF;
      z-index: 99; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-info-text h2 {
        font-size: 1.625rem; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-image {
      position: relative; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-image img {
        max-width: 100%;
        height: auto;
        min-height: 675px;
        height: 100%;
        width: 100%; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-logo-small {
      position: absolute;
      bottom: 1%;
      left: 5%;
      z-index: 99; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-logo-small img {
        max-width: 200px;
        height: 100px; }
  body.node--type-specialized-programs .view-specialized-program-image div.field--name-name {
    display: none; }
  body.node--type-specialized-programs .view-specialized-program-image div.modal-content {
    background: none;
    border: none; }
    body.node--type-specialized-programs .view-specialized-program-image div.modal-content div.modal-header {
      border: none; }
    body.node--type-specialized-programs .view-specialized-program-image div.modal-content div.modal-header .close {
      cursor: pointer;
      padding: 2px 5px;
      line-height: 20px;
      font-size: 40px;
      background: #ffffff;
      z-index: 999999;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.path-programs .container-fluid-lg {
    padding: 0px 15px; }
  body.path-programs .view-program-business {
    margin-bottom: 80px; }
    body.path-programs .view-program-business .program-col-sep .row-col {
      border-bottom: 1px solid #A7a9a3;
      margin-bottom: 15px;
      width: 100%; }
      body.path-programs .view-program-business .program-col-sep .row-col .views-field-name {
        font-family: "sharpSans-Semibold";
        font-size: 18px;
        color: #2a2a2a;
        word-break: break-word; }
      body.path-programs .view-program-business .program-col-sep .row-col .content_parentterm .views-field-name {
        padding-left: 15px; }
      body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas {
        margin: 10px 0; }
        body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas .term_data {
          padding: 15px 0px 15px 15px;
          margin-bottom: 0; }
          body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas .term_data a {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            font-style: normal;
            font-size: 18px;
            color: #2A2A2A;
            word-break: break-word;
            text-transform: capitalize;
            text-decoration: none;
            display: block; }
          body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas .term_data a:hover {
            text-decoration: underline; }
        body.path-programs .view-program-business .program-col-sep .row-col .content_term_datas .term_data:last-child {
          margin-bottom: 0; }
    body.path-programs .view-program-business .program-col-sep:last-child .row-col {
      margin-bottom: 0;
      border-bottom: none; }
      body.path-programs .view-program-business .program-col-sep:last-child .row-col .content_term_datas {
        margin-bottom: 0; }
        body.path-programs .view-program-business .program-col-sep:last-child .row-col .content_term_datas .term_data:last-child {
          margin-bottom: 0; }
  body.path-programs .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    z-index: 1; }
  body.path-programs .secondary_banner_content {
    padding: 0 15px; }
  body.path-programs .content_area > div:first-child, body.path-programs .content_area > div:last-child {
    padding-left: 0px; }
  body.path-programs .featured-top {
    margin-bottom: 40px; }
  body.path-programs div.content_area h3.side-title {
    font-size: 20px; }
  body.path-programs div.content_area .title-border {
    width: 65px; }
  body.path-programs div.content_area .brand-page-cont h2 {
    font-size: 26px; }
  body.path-programs div.content_area .brand-page-cont p:first-child {
    margin-top: 20px;
    margin-bottom: 0px; }
  body.path-programs .business_tiles {
    margin: 0px -15px; }
    body.path-programs .business_tiles .row {
      margin: 0px; }
    body.path-programs .business_tiles .col-auto, body.path-programs .business_tiles .program-col-sep {
      padding-left: 0px;
      padding-right: 0px; }
  body.node--type-specialized-programs .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
    z-index: 1; }
  body.node--type-specialized-programs .container-fluid-lg {
    padding: 0px 15px; }
  body.node--type-specialized-programs .container {
    padding: 0px;
    margin: 0px; }
    body.node--type-specialized-programs .container .col-md-12 {
      margin: 0px;
      padding: 0px; }
  body.node--type-specialized-programs .sp_header_banner {
    margin: 0px;
    margin-bottom: 60px; }
  body.node--type-specialized-programs .content_area h3.side-title {
    font-size: 20px; }
  body.node--type-specialized-programs .content_area .title-border {
    width: 65px; }
  body.node--type-specialized-programs .content_area .bussiness-title {
    margin-top: 30px;
    font-size: 26px;
    font-family: "sharpSans-Book"; }
  body.node--type-specialized-programs .content_area .sub-title-border {
    border: none;
    height: 0px;
    width: 0px;
    background-color: none;
    display: none; }
  body.node--type-specialized-programs .content_area .tt-view-program {
    margin: 0px;
    padding: 0px;
    padding-bottom: 45px;
    border-bottom: 1px solid #2a2a2a; }
    body.node--type-specialized-programs .content_area .tt-view-program ul {
      margin: 0px;
      padding: 0px; }
      body.node--type-specialized-programs .content_area .tt-view-program ul li {
        margin: 0px;
        padding: 0px;
        line-height: 50px; }
        body.node--type-specialized-programs .content_area .tt-view-program ul li .field-content {
          display: block;
          width: 100%;
          padding-left: 15px; }
        body.node--type-specialized-programs .content_area .tt-view-program ul li a {
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 600;
          font-style: normal;
          font-size: 18px;
          line-height: 50px;
          word-break: break-word;
          text-decoration: none;
          margin-bottom: 0px;
          display: block; }
  body.node--type-specialized-programs .content_area .brand-page-cont {
    margin-top: 40px;
    margin-bottom: 45px; }
    body.node--type-specialized-programs .content_area .brand-page-cont h2.body-title {
      font-size: 26px;
      font-family: "sharpSans-Book" !important; }
    body.node--type-specialized-programs .content_area .brand-page-cont h4 {
      font-size: 18px !important; }
    body.node--type-specialized-programs .content_area .brand-page-cont p {
      font-size: 18px !important;
      margin-top: 20px !important; }
      body.node--type-specialized-programs .content_area .brand-page-cont p strong {
        margin-top: 45px !important;
        font-size: 20px !important;
        font-family: "SourceSansPro-Semibold" !important; }
  body.node--type-specialized-programs .program-img-container {
    min-height: 512px; }
  body.node--type-specialized-programs .programjob-main-container {
    padding-top: 45px; }
    body.node--type-specialized-programs .programjob-main-container .program-page-link {
      margin: 45px -15px 40px -15px;
      padding: 10px 15px; }
      body.node--type-specialized-programs .programjob-main-container .program-page-link a {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 18px;
        text-decoration: none;
        width: 100%;
        display: block;
        color: #009DD6; }
    body.node--type-specialized-programs .programjob-main-container .col-12 {
      margin: 0px;
      padding: 0px; }
    body.node--type-specialized-programs .programjob-main-container .job-qualification-cont {
      margin-bottom: 30px; }
      body.node--type-specialized-programs .programjob-main-container .job-qualification-cont h3 {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 20px; }
      body.node--type-specialized-programs .programjob-main-container .job-qualification-cont ul {
        margin: 0px;
        padding: 0px;
        padding-left: 20px; }
        body.node--type-specialized-programs .programjob-main-container .job-qualification-cont ul li {
          margin: 0px;
          padding: 0px;
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 0px;
          padding-left: 25px; }
    body.node--type-specialized-programs .programjob-main-container .job-apply-cointer .side-cont-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 20px; }
    body.node--type-specialized-programs .programjob-main-container .job-apply-cointer p {
      font-size: 18px;
      margin-top: 30px; }
    body.node--type-specialized-programs .programjob-main-container .job-apply-cointer .apply-button-cont {
      margin-top: 45px;
      width: 130px;
      height: 45px;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      border-radius: inherit; }
      body.node--type-specialized-programs .programjob-main-container .job-apply-cointer .apply-button-cont a {
        text-decoration: none; }
  body.node--type-specialized-programs .view-program-termname {
    position: relative; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-info-text {
      width: 50%;
      top: 13%;
      left: 100px;
      color: #FFFFFF;
      z-index: 99; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-info-text h2 {
        font-size: 3.625rem; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-image {
      position: relative; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-image img {
        max-width: 100%;
        height: auto;
        min-height: 675px;
        height: 100%;
        width: 100%; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-logo-small {
      position: absolute;
      bottom: 55px;
      left: 100px;
      z-index: 99; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-logo-small img {
        max-width: 200px;
        height: 100px; }
  body.node--type-specialized-programs .view-specialized-program-image div.field--name-name {
    display: none; }
  body.node--type-specialized-programs .view-specialized-program-image div.modal-content {
    background: none;
    border: none; }
    body.node--type-specialized-programs .view-specialized-program-image div.modal-content div.modal-header {
      border: none; }
    body.node--type-specialized-programs .view-specialized-program-image div.modal-content div.modal-header .close {
      cursor: pointer;
      padding: 2px 5px;
      line-height: 20px;
      font-size: 40px;
      background: #ffffff;
      z-index: 999999;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; } }

@media only screen and (min-width: 992px) {
  body.node--type-specialized-programs h3.bussiness-title {
    margin-top: 66px; }
  body.node--type-specialized-programs .program-img-container {
    margin: 0px;
    padding: 0px;
    min-height: 520px;
    max-height: 520px;
    overflow: hidden; }
    body.node--type-specialized-programs .program-img-container .program-image-top, body.node--type-specialized-programs .program-img-container .program-image-bottom {
      min-height: 520px;
      max-height: 520px;
      padding: 0px; }
      body.node--type-specialized-programs .program-img-container .program-image-top img, body.node--type-specialized-programs .program-img-container .program-image-bottom img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
  body.node--type-specialized-programs .featured-top .field--name-field-banner-info-text .cd-intro .cd-words-wrapper b {
    white-space: inherit;
    width: 50%; }
  body.node--type-specialized-programs .programjob-main-container {
    padding-top: 70px; }
    body.node--type-specialized-programs .programjob-main-container .program-page-link a {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 20px;
      line-height: 26px;
      word-break: break-word;
      text-decoration: none; }
    body.node--type-specialized-programs .programjob-main-container .programjob-inner-container {
      padding-left: 7.5%;
      margin-bottom: 50px; }
      body.node--type-specialized-programs .programjob-main-container .programjob-inner-container h3.side-cont-title {
        font-family: "sharpSans-Semibold";
        font-size: 23px;
        color: #2a2a2a;
        word-break: break-word; }
      body.node--type-specialized-programs .programjob-main-container .programjob-inner-container p {
        font-size: 18px;
        line-height: 30px;
        margin-top: 16px;
        margin-bottom: 0px; }
      body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .apply-button-cont {
        margin-top: 16px; }
        body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .apply-button-cont a {
          font-family: "sharpSans-Bold";
          font-size: 18px;
          border-radius: 0px;
          padding-top: 2%;
          padding-bottom: 2%; }
          body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .apply-button-cont a i {
            font-size: 16px; }
      body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .apply-button-cont.disabled {
        cursor: not-allowed; }
      body.node--type-specialized-programs .programjob-main-container .programjob-inner-container button.apply-button-cont {
        font-family: "sharpSans-Bold";
        font-size: 18px;
        border-radius: 0px;
        padding-top: 2%;
        padding-bottom: 2%; }
        body.node--type-specialized-programs .programjob-main-container .programjob-inner-container button.apply-button-cont i {
          font-size: 16px; }
      body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .job-qualification-cont {
        padding-right: 55px; }
        body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .job-qualification-cont ul {
          margin: 0px;
          padding: 0px;
          padding-left: 20px; }
          body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .job-qualification-cont ul li {
            margin: 0px;
            padding: 0px;
            font-size: 18px;
            line-height: 30px;
            margin-top: 10px;
            margin-bottom: 0px;
            padding-left: 15px; }
        body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .job-qualification-cont h3.side-cont-title {
          margin-bottom: 6px; }
      body.node--type-specialized-programs .programjob-main-container .programjob-inner-container .job-apply-cointer {
        padding-left: 55px; }
    body.node--type-specialized-programs .programjob-main-container .jobapply-inner {
      min-height: 140px;
      padding-bottom: 45px; }
  body.node--type-specialized-programs .brand-page-cont {
    margin-left: 5px; }
  body.node--type-specialized-programs .tt-view-program .row, body.node--type-specialized-programs .tt-view-program .col-auto {
    margin: 0px;
    padding: 0px;
    width: 100%; }
  body.node--type-specialized-programs .tt-view-program .view-program-termname {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-style: normal; }
    body.node--type-specialized-programs .tt-view-program .view-program-termname .row, body.node--type-specialized-programs .tt-view-program .view-program-termname .col-auto {
      margin: 0px;
      padding: 0px;
      width: 100%; }
    body.node--type-specialized-programs .tt-view-program .view-program-termname ul {
      margin: 0px;
      padding: 0px;
      margin-top: 20px; }
    body.node--type-specialized-programs .tt-view-program .view-program-termname li {
      margin: 0px;
      padding: 0px;
      margin-top: 20px; }
      body.node--type-specialized-programs .tt-view-program .view-program-termname li a {
        font-size: 20px;
        line-height: 26px;
        word-break: break-word;
        text-decoration: none;
        color: #686868; }
      body.node--type-specialized-programs .tt-view-program .view-program-termname li a:active {
        color: #009DD6; }
      body.node--type-specialized-programs .tt-view-program .view-program-termname li a:hover {
        color: #009DD6; }
    body.node--type-specialized-programs .tt-view-program .view-program-termname li.active a {
      color: #009DD6; }
  body.node--type-specialized-programs .view-program-termname {
    position: relative; }
    body.node--type-specialized-programs .view-program-termname .item-list {
      width: 100%; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-info-text {
      width: 50%;
      position: absolute;
      top: 13%;
      left: 100px;
      color: #FFFFFF;
      z-index: 99; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-info-text h2 {
        font-size: 5.625rem; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-image {
      position: relative; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-image img {
        max-width: 100%;
        height: auto;
        min-height: 675px;
        height: 100%;
        width: 100%; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-image .field-content:after {
        content: " ";
        z-index: 10;
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: url(../images/Image_Fade.svg) no-repeat; }
    body.node--type-specialized-programs .view-program-termname .views-field-field-banner-logo-small {
      position: absolute;
      bottom: 55px;
      left: 100px;
      z-index: 99; }
      body.node--type-specialized-programs .view-program-termname .views-field-field-banner-logo-small img {
        max-width: 200px;
        height: 100px; }
  body.node--type-specialized-programs .sp_header_banner {
    margin: 0px;
    margin-bottom: 50px; }
    body.node--type-specialized-programs .sp_header_banner .cd-words-wrapper {
      min-height: 192px;
      height: 192px; } }
    @media only screen and (min-width: 992px) and (max-width: 767.98px) {
      body.node--type-specialized-programs .sp_header_banner .cd-words-wrapper {
        min-height: 140px;
        height: 140px;
        padding-bottom: 10px; } }

@media only screen and (min-width: 992px) {
  body.node--type-specialized-programs .view-specialized-program-image div.field--name-name {
    display: none; }
  body.node--type-specialized-programs .view-specialized-program-image div.modal-content {
    background: none;
    border: none; }
    body.node--type-specialized-programs .view-specialized-program-image div.modal-content div.modal-header {
      border: none; }
    body.node--type-specialized-programs .view-specialized-program-image div.modal-content div.modal-header .close {
      cursor: pointer;
      padding: 2px 5px;
      line-height: 20px;
      font-size: 40px;
      background: #ffffff;
      z-index: 999999;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; }
  body.path-programs .featured-top .field--name-field-banner-info-text .cd-intro .cd-words-wrapper b {
    white-space: inherit; }
  body.path-programs .content_area .col-lg-3 {
    padding-left: 0px; }
  body.path-programs .content_area .col-lg-9 {
    padding-left: 5px; }
  body.path-programs .tt-view-program {
    width: 100%; }
  body.path-programs .business_tiles {
    margin-left: 0px;
    padding-left: 0px;
    width: 100%; }
    body.path-programs .business_tiles .views-element-container {
      padding: 0px; } }

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  body.path-programs .view-program-business {
    margin-left: 0px; } }

@media only screen and (min-width: 992px) {
  body.path-programs .view-program-business .row {
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0px;
    display: flex;
    justify-content: center; }
  body.path-programs .view-program-business .program-col-sep {
    margin-bottom: 20px;
    padding: 0px;
    padding-left: 20px;
    width: 100%;
    max-width: 340px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    body.path-programs .view-program-business .program-col-sep {
      max-width: 360px; } }

@media only screen and (min-width: 992px) {
    body.path-programs .view-program-business .program-col-sep .row-col {
      width: 100%;
      height: 100%;
      background: #eeeeee; }
      body.path-programs .view-program-business .program-col-sep .row-col .tiles-header {
        height: 100%;
        text-align: center;
        font-family: "sharpSans-Semibold";
        padding: 20px 10px 0px 10px;
        overflow: hidden; }
        body.path-programs .view-program-business .program-col-sep .row-col .tiles-header .content_parentterm {
          position: relative;
          text-align: center;
          font-size: 20px;
          padding: 0 5px; }
          body.path-programs .view-program-business .program-col-sep .row-col .tiles-header .content_parentterm .views-field-name {
            line-height: 26px;
            word-break: break-word;
            margin-top: 10px; }
        body.path-programs .view-program-business .program-col-sep .row-col .tiles-header .content_term_datas {
          position: relative;
          text-align: center;
          font-size: 18px;
          margin-top: 20px; }
          body.path-programs .view-program-business .program-col-sep .row-col .tiles-header .content_term_datas .term_data {
            margin-top: 10px;
            line-height: 22px; }
          body.path-programs .view-program-business .program-col-sep .row-col .tiles-header .content_term_datas a {
            color: #2A2A2A;
            line-height: 24px;
            font-size: 18px;
            font-family: "SourceSansPro-Regular";
            font-style: normal;
            word-break: break-word;
            text-transform: capitalize;
            text-decoration: none; }
          body.path-programs .view-program-business .program-col-sep .row-col .tiles-header .content_term_datas a:hover {
            text-decoration: underline; }
      body.path-programs .view-program-business .program-col-sep .row-col .tiles-border {
        height: 6px;
        display: none; }
    body.path-programs .view-program-business .program-col-sep .cr-tomato .tiles-border {
      background-color: #F36E37; }
    body.path-programs .view-program-business .program-col-sep .cr-blue .tiles-border {
      background-color: #009DD6; }
    body.path-programs .view-program-business .program-col-sep .cr-gray .tiles-border {
      background-color: #896EB1; }
    body.path-programs .view-program-business .program-col-sep .cr-yellow .tiles-border {
      background-color: #896EB1; } }

.path-programs {
  /* Underline Reveal */
  /*.cr-tomato .term_data a:hover  {
		color: #F36E37 !important;
		@include media-breakpoint-down(sm) {
			color: #009DD6 !important;
		}
	}
	.cr-blue .term_data a:hover  {
		color: #009DD6 !important;
	}
	.cr-gray .term_data a:hover  {
		color: #896EB1 !important;
		@include media-breakpoint-down(sm) {
			color: #009DD6 !important;
		}
	}
	.cr-yellow .term_data a:hover {
		color: #59A63F !important;
		@include media-breakpoint-down(sm) {
			color: #009DD6 !important;
		}
	}*/ }
  .path-programs .hvr-underline-reveal {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    padding-bottom: 35px; }
    @media (max-width: 991.98px) {
      .path-programs .hvr-underline-reveal {
        padding-bottom: 0px; } }
  .path-programs .hvr-underline-reveal:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098D1;
    height: 20px;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  .path-programs .hvr-underline-reveal:hover:before, .path-programs .hvr-underline-reveal:focus:before, .path-programs .hvr-underline-reveal:active:before {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  .path-programs .cr-tomato.hvr-underline-reveal:before {
    background: #F36E37; }
    @media (max-width: 991.98px) {
      .path-programs .cr-tomato.hvr-underline-reveal:before {
        background: transparent; } }
  .path-programs .cr-blue.hvr-underline-reveal:before {
    background: #009DD6; }
    @media (max-width: 991.98px) {
      .path-programs .cr-blue.hvr-underline-reveal:before {
        background: transparent; } }
  .path-programs .cr-gray.hvr-underline-reveal:before {
    background: #896EB1; }
    @media (max-width: 991.98px) {
      .path-programs .cr-gray.hvr-underline-reveal:before {
        background: transparent; } }
  .path-programs .cr-yellow.hvr-underline-reveal:before {
    background: #59A63F; }
    @media (max-width: 991.98px) {
      .path-programs .cr-yellow.hvr-underline-reveal:before {
        background: transparent; } }

@media (min-width: 993px) and (max-width: 1199px) {
  body.node--type-specialized-programs .programjob-main-container {
    padding-top: 45px; }
  body.node--type-specialized-programs .featured-top .search-form-wrapper {
    padding-top: 15px; }
  body.node--type-specialized-programs .featured-top .field--name-field-banner-logo-small {
    bottom: 30px; }
  .path-programs .featured-top .field--name-field-banner-logo-small {
    bottom: 20px; } }

@media (min-width: 768px) and (max-width: 992px) {
  body.node--type-specialized-programs .program-img-container {
    max-height: 270px;
    margin: 0;
    overflow: hidden;
    min-height: 270px !important; }
    body.node--type-specialized-programs .program-img-container .program-image-bottom {
      width: 36%;
      max-height: 270px;
      min-height: 270px; }
    body.node--type-specialized-programs .program-img-container .program-image-top {
      width: 64%;
      max-height: 270px;
      min-height: 270px; }
  body.path-programs .secondary_content_div {
    padding: 30px 20px 35px 30px; }
    body.path-programs .secondary_content_div p {
      margin-bottom: 30px;
      max-height: 180px; } }

@media (max-width: 767px) {
  body.node--type-specialized-programs .program-img-container .program-image-bottom {
    width: 100%; } }

@media (max-width: 991px) {
  body.path-programs .business_tiles .row {
    padding: 0 20px; } }

.path-all-locations .featured-top {
  min-height: 1px;
  margin: 0 !important; }
  .path-all-locations .featured-top .search-form-wrapper {
    display: none; }
    @media (max-width: 991.98px) {
      .path-all-locations .featured-top .search-form-wrapper {
        width: 100%;
        display: Block;
        padding: 0; } }

.path-all-locations .slider-nav .single-slider-img.highlight-slide .slider-left .location_featured_image {
  display: none; }

.path-all-locations .slider-nav .single-slider-img.highlight-slide .slider-left .location_featured_hover_image {
  display: block !important; }

.path-all-locations .slider-nav .single-slider-img.highlight-slide .slider-title {
  font-family: "sharpSans-Semibold"; }

.path-all-locations .slider-nav .single-slider-img.highlight-slide .slider-border {
  width: 10% !important;
  height: 5px !important;
  margin: auto; }

.path-all-locations .slider-nav {
  z-index: 100; }

.path-all-locations .slick-arrow {
  border-radius: 0px !important;
  height: 20px;
  width: 21px;
  z-index: 1;
  display: none !important;
  background: transparent;
  border: none;
  position: absolute;
  outline: none;
  top: 40%; }

.path-all-locations .slick-prev.slick-arrow {
  left: 10px; }

.path-all-locations .slick-next.slick-arrow {
  right: 10px; }

.path-all-locations .slick-arrow:hover {
  border: 1px solid #FFF; }

.path-all-locations .slick-arrow::before {
  content: ' ' !important;
  font-size: 0px !important; }

.path-all-locations .carousel-control-next-icon, .path-all-locations .carousel-control-prev-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: block !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat; }

.path-all-locations .carousel-control-next-icon {
  background-image: url(../images/icons/carousel-right.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-all-locations .carousel-control-next-icon:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

.path-all-locations .carousel-control-prev-icon {
  background-image: url(../images/icons/carousel-left.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.path-all-locations .carousel-control-prev-icon:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

.path-all-locations .job-top-title {
  font-size: 40px;
  color: #2A2A2A;
  padding-bottom: 27px;
  padding-top: 70px;
  font-family: "sharpSans-Book"; }
  @media (max-width: 991.98px) {
    .path-all-locations .job-top-title {
      padding-top: 35px; } }

.path-all-locations .job-body-desc {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  text-align: justify;
  padding-left: 11%;
  padding-right: 11%;
  padding-bottom: 20px;
  font-family: "SourceSansPro-Regular"; }
  @media (max-width: 1199.98px) {
    .path-all-locations .job-body-desc {
      padding-left: 0px;
      padding-right: 0px; } }
  @media (max-width: 991.98px) {
    .path-all-locations .job-body-desc {
      text-align: left; } }

.path-all-locations .slider-nav .slick-list {
  width: 87%;
  margin: 0 auto;
  padding-bottom: 35px !important; }
  @media (max-width: 575.98px) {
    .path-all-locations .slider-nav .slick-list {
      width: 100%; } }

.path-all-locations .single-slider-img {
  cursor: pointer; }
  .path-all-locations .single-slider-img .slider-left {
    width: 100%;
    float: left;
    position: relative;
    height: 200px; }
    .path-all-locations .single-slider-img .slider-left img {
      max-height: 200px;
      height: auto;
      position: absolute;
      /*top: 0;*/
      bottom: 25px;
      left: 0;
      right: 0;
      margin: 0 auto; }
    .path-all-locations .single-slider-img .slider-left .location_featured_hover_image {
      display: none; }
  .path-all-locations .single-slider-img .slider-title {
    position: relative;
    top: 15%;
    font-size: 25px;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    font-family: "sharpSans-Book"; }

.path-all-locations .single-slider-img:hover .location_featured_image {
  display: none; }

.path-all-locations .single-slider-img:hover .location_featured_hover_image {
  display: block;
  /*@include media-breakpoint-up(md) {
            display:block !important;
          }*/ }
  @media (min-width: 992px) {
    .path-all-locations .single-slider-img:hover .location_featured_hover_image {
      display: block !important; } }

.path-all-locations .slider-outer-banner {
  padding: 50px 0px 30px; }

.path-all-locations .single-slider-main {
  color: #FFFFFF;
  position: relative;
  min-height: 200px;
  max-width: 1030px;
  margin: 0 auto; }
  .path-all-locations .single-slider-main .slider-title {
    line-height: 50px;
    font-size: 18px; }
    .path-all-locations .single-slider-main .slider-title a {
      background: #FFFFFF;
      color: #000000;
      padding: 11px 20px 11px 20px;
      font-size: 18px;
      font-family: "sharpSans-Semibold";
      text-decoration: none;
      word-spacing: 5px;
      letter-spacing: 0.5px; }
    .path-all-locations .single-slider-main .slider-title a:hover {
      color: #FFA500; }
    .path-all-locations .single-slider-main .slider-title span {
      font-size: 36px;
      font-family: "sharpSans-Medium"; }
  .path-all-locations .single-slider-main .slider-description {
    font-size: 18px;
    font-family: "SourceSansPro-Regular";
    line-height: 30px; }
    .path-all-locations .single-slider-main .slider-description p {
      border-left: 1px solid #FFFFFF;
      padding-left: 10px; }

.path-all-locations .slider-for {
  z-index: 50; }

.path-all-locations .slider-for .slick-list {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px; }

.path-all-locations .slider-bottom {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 520px; }
  .path-all-locations .slider-bottom img {
    width: 100%;
    object-fit: cover; }
  @media (max-width: 767.98px) {
    .path-all-locations .slider-bottom img {
      width: 100%;
      height: 100%;
      min-height: auto;
      object-fit: cover; } }
  @media (max-width: 991.98px) {
    .path-all-locations .slider-bottom img {
      width: 100%;
      height: 100%;
      min-height: auto;
      object-fit: cover; } }

.path-all-locations .slider-bottom.mobile-image-banner {
  display: none; }

.path-all-locations .field--name-field-highlight-title {
  color: #009DD6;
  font-size: 18px;
  padding-bottom: 20px;
  padding-top: 60px;
  border-bottom: 1px solid #a39ea3;
  font-family: "SourceSansPro-Semibold"; }

.path-all-locations .featured_locations_heading {
  padding-top: 60px; }

.path-all-locations .continent-country {
  border-bottom: 1px solid #a39ea3;
  padding-bottom: 3px; }

.path-all-locations .continent-content-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .path-all-locations .continent-content-section .continent-country a {
    color: #2A2A2A;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    font-family: "sharpSans-Bold"; }
  .path-all-locations .continent-content-section .continent-country a:hover {
    color: #009DD6; }
  .path-all-locations .continent-content-section a {
    color: #2A2A2A;
    font-size: 18px;
    text-decoration: none;
    font-family: "SourceSansPro-Regular"; }

.path-all-locations .featured_locations_heading h2 {
  font-family: "sharpSans-Book";
  font-size: 40px; }
  @media (max-width: 575.98px) {
    .path-all-locations .featured_locations_heading h2 {
      font-size: 26px; } }
  @media (max-width: 991.98px) {
    .path-all-locations .featured_locations_heading h2 {
      font-size: 26px; } }

.path-all-locations .continent-content-section-us {
  display: flex;
  flex-direction: row; }
  .path-all-locations .continent-content-section-us .continent-country a {
    color: #2A2A2A;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    font-family: "sharpSans-Bold"; }
  .path-all-locations .continent-content-section-us .continent-country a:hover {
    color: #009DD6; }
  .path-all-locations .continent-content-section-us a {
    color: #2A2A2A;
    font-size: 18px;
    text-decoration: none;
    font-family: "SourceSansPro-Regular"; }

.path-all-locations .continent-content-column-us, .path-all-locations .continent-content-column {
  padding-right: 20px; }
  .path-all-locations .continent-content-column-us h3, .path-all-locations .continent-content-column h3 {
    font-weight: normal; }

.path-all-locations .continent-content-column-us:last-child,
.path-all-locations .continent-content-column:last-child {
  padding-right: 0px; }

.path-all-locations .tab_wrapper ul li.continent-section .continent, .path-all-locations .tab_wrapper ul li.continent-section .continent-hover {
  padding-top: 30px;
  width: 100%;
  height: 100%;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  opacity: 1;
  position: absolute;
  left: 0px; }

.path-all-locations .tab_wrapper ul li.continent-section .continent-hover {
  opacity: 0; }

.path-all-locations .tab_wrapper ul li.continent-section a {
  color: #2A2A2A;
  font-size: 22px;
  text-decoration: none;
  font-family: "sharpSans-Medium"; }

.path-all-locations .tab_wrapper ul li.continent-section:hover .continent-hover {
  opacity: 1; }

.path-all-locations .tab_wrapper ul li.continent-section:hover .continent {
  opacity: 0; }

.path-all-locations .country-tab-img {
  position: relative;
  height: 325px; }
  @media (max-width: 1199.98px) {
    .path-all-locations .country-tab-img {
      height: 290px; } }
  @media (max-width: 991.98px) {
    .path-all-locations .country-tab-img {
      height: 207px; } }

.path-all-locations .tab_wrapper ul li.continent-section.active .continent-hover {
  padding-top: 30px;
  opacity: 1; }

.path-all-locations .tab_wrapper ul li.continent-section.active .continent {
  padding-top: 30px;
  opacity: 0; }

.path-all-locations .tab_wrapper ul li.continent-section.active a {
  color: #009DD6;
  font-size: 22px;
  text-decoration: none;
  font-family: "sharpSans-Semibold"; }

.path-all-locations .tab_wrapper ul li.continent-section.active .tab_wrapper .country-tab-text {
  height: 100px;
  vertical-align: middle;
  display: table-cell;
  width: 250px;
  padding-left: 40px; }

.path-all-locations .wrapper {
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 15px; }

.path-all-locations .wrapper > h1 {
  text-align: center; }

.path-all-locations .wrapper > h1 span {
  border-bottom: 2px solid #49a4d9;
  display: inline-block;
  padding: 0 10px 10px;
  color: #49a4d9;
  transition: all 0.5s linear; }

.path-all-locations .tab_wrapper ul,
.path-all-locations li {
  margin: 0;
  padding: 0;
  list-style: none; }

.path-all-locations .first.tab_1_1 .continent-content-column-us:last-child {
  width: 100%; }
  .path-all-locations .first.tab_1_1 .continent-content-column-us:last-child .nav-continent-column {
    height: 662px; }
    @media (max-width: 991.98px) {
      .path-all-locations .first.tab_1_1 .continent-content-column-us:last-child .nav-continent-column {
        height: auto; } }

.path-all-locations .tab_wrapper .nav-continent-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  flex-wrap: wrap; }
  @media (max-width: 991.98px) {
    .path-all-locations .tab_wrapper .nav-continent-column {
      display: block;
      flex-direction: normal;
      height: auto;
      flex-wrap: normal; } }
  .path-all-locations .tab_wrapper .nav-continent-column .continent-state {
    flex-flow: column;
    width: 20.3%;
    height: auto;
    display: flex;
    min-height: 10px;
    max-height: fit-content;
    margin-bottom: 20px; }
    @media (max-width: 991.98px) {
      .path-all-locations .tab_wrapper .nav-continent-column .continent-state {
        display: block;
        flex-flow: normal; } }
    .path-all-locations .tab_wrapper .nav-continent-column .continent-state ul {
      width: 200px;
      font-size: 18px;
      color: #2A2A2A;
      line-height: 16px;
      margin-top: 10px; }
    .path-all-locations .tab_wrapper .nav-continent-column .continent-state li {
      width: 200px;
      font-size: 18px;
      color: #2A2A2A;
      line-height: 25px; }
      .path-all-locations .tab_wrapper .nav-continent-column .continent-state li a:hover {
        color: #009DD6; }
    .path-all-locations .tab_wrapper .nav-continent-column .continent-state h3 {
      margin-bottom: 0px; }
      .path-all-locations .tab_wrapper .nav-continent-column .continent-state h3 a:hover {
        color: #009DD6; }

.path-all-locations .tab_wrapper .continent-content-column-us .nav-continent-column .continent-state h3 a {
  font-family: "sharpSans-Bold"; }

.path-all-locations .tab_wrapper .nav-continent-column h3 {
  margin-top: 20px;
  line-height: 16px;
  font-weight: normal;
  font-size: 18px;
  color: #2A2A2A; }

.path-all-locations .tab_wrapper {
  margin: 0px;
  display: inline-block;
  width: 100%;
  position: relative; }

.path-all-locations .tab_wrapper * {
  box-sizing: border-box; }

.path-all-locations .tab_wrapper > ul {
  clear: both;
  display: block;
  margin: 0px -10px;
  width: auto; }

.path-all-locations .tab_wrapper > ul li {
  float: left;
  cursor: pointer;
  font-size: 22px;
  border-bottom: 0;
  padding: 0px 10px;
  position: relative;
  color: #2A2A2A;
  font-weight: bold;
  text-align: center;
  width: 100%;
  font-family: "SourceSansPro-Semibold"; }

.path-all-locations .tab_wrapper .country-tab-text {
  height: 100px;
  vertical-align: middle;
  display: table-cell;
  width: 250px;
  padding-left: 40px; }

.path-all-locations .tab_wrapper .continent-section .country-tab-text-border {
  width: 35px;
  height: 5px;
  border-bottom: 5px solid transparent;
  margin: auto;
  clear: both;
  padding-top: 10px; }

.path-all-locations .tab_wrapper .continent-section.active .country-tab-text-border {
  width: 35px;
  height: 5px;
  border-bottom: 5px solid #009DD6;
  margin: auto;
  clear: both;
  padding-top: 10px; }

.path-all-locations .tab_wrapper > ul li.active {
  border-color: #49a4d9;
  color: #49a4d9; }

.path-all-locations .tab_wrapper > ul li.active:after {
  content: "";
  position: absolute;
  bottom: -1px;
  height: 1px;
  left: 0;
  background: #fff;
  width: 100%; }

.path-all-locations .tab_wrapper .controller {
  display: block;
  overflow: hidden;
  clear: both;
  position: absolute;
  top: 0;
  right: 0; }

.path-all-locations .tab_wrapper .controller span {
  padding: 8px 22px;
  background: #49a4d9;
  color: #fff;
  text-transform: uppercase;
  display: inline-block; }

.path-all-locations .tab_wrapper .controller span.next {
  float: right;
  margin-left: 10px; }

.path-all-locations .tab_wrapper .content_wrapper {
  float: left;
  width: 100%;
  border-top: 0;
  box-sizing: border-box; }

.path-all-locations .tab_wrapper .content_wrapper .tab_content {
  display: none; }

.path-all-locations .tab_wrapper .content_wrapper .tab_content.active {
  /* display: block; */ }

.path-all-locations .tab_wrapper .content_wrapper .tab_content .error {
  color: red; }

.path-all-locations .tab_wrapper .content_wrapper .accordian_header {
  display: none;
  padding: 18px 20px;
  text-transform: uppercase;
  font-weight: bold;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #fff;
  position: relative;
  cursor: pointer; }

.path-all-locations .tab_wrapper .content_wrapper .accordian_header.active {
  color: #0079C0;
  border-bottom: 1px solid #ccc; }

.path-all-locations .tab_wrapper .content_wrapper .accordian_header.active:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 5px;
  height: 100%;
  /*background: #49a4d9;*/ }

.path-all-locations .tab_wrapper .content_wrapper .accordian_header .arrow {
  float: right;
  display: inline-block;
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 2px;
  margin-top: 6px;
  transform: rotate(-135deg);
  background: transparent;
  border-top: 3px solid #ccc;
  border-left: 3px solid #ccc;
  /* &:after{
						position: absolute;
						top: 0;
						content: "";
						left: 0;
						width: 10px;
						height: 10px;
						background: $white;
						border-top: 3px solid $border_color;
						border-left: 3px solid $border_color;
					} */ }

.path-all-locations .tab_wrapper .content_wrapper .accordian_header.active .arrow {
  transform: rotate(45deg);
  margin-top: 12px;
  border-top: 3px solid #49a4d9;
  border-left: 3px solid #49a4d9; }

.path-all-locations .tab_wrapper.left_side > ul {
  width: 25%;
  float: left;
  border-bottom: 1px solid #ccc; }

.path-all-locations .tab_wrapper.left_side > ul li {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 12px 18px;
  border-width: 1px;
  border-right: 0;
  border-left: 0; }

.path-all-locations .tab_wrapper.left_side > ul li:after {
  left: 0;
  top: 0;
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #ccc; }

.path-all-locations .tab_wrapper.left_side > ul li.active {
  border-color: #ccc; }

.path-all-locations .tab_wrapper.left_side > ul li.active:after {
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background: #49a4d9; }

.path-all-locations .tab_wrapper.left_side > ul li.active:before {
  right: -1px;
  position: absolute;
  top: 0;
  content: "";
  width: 2px;
  height: 100%;
  background: #fff; }

.path-all-locations .tab_wrapper.left_side .content_wrapper {
  width: 75%;
  border: 1px solid #ccc;
  float: left; }

.path-all-locations .tab_wrapper.left_side .controller {
  top: -50px; }

.path-all-locations .tab_wrapper.right_side > ul {
  width: 25%;
  float: right;
  border-bottom: 1px solid #ccc; }

.path-all-locations .tab_wrapper.right_side > ul li {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 12px 18px;
  border-width: 1px;
  border-right: 0;
  border-left: 0;
  text-align: right; }

.path-all-locations .tab_wrapper.right_side > ul li:after {
  right: 0;
  left: inherit;
  top: 0;
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #ccc; }

.path-all-locations .tab_wrapper.right_side > ul li.active {
  border-color: #ccc; }

.path-all-locations .tab_wrapper.right_side > ul li.active:after {
  right: 0;
  left: inherit;
  top: 0;
  width: 5px;
  height: 100%;
  content: "";
  background: #49a4d9; }

.path-all-locations .tab_wrapper.right_side > ul li.active:before {
  left: -2px;
  right: inherit;
  position: absolute;
  top: 0;
  content: "";
  width: 2px;
  height: 100%;
  background: #fff; }

.path-all-locations .tab_wrapper.right_side .content_wrapper {
  width: 75%;
  float: left;
  border: 1px solid #ccc; }

.path-all-locations .tab_wrapper.right_side .controller {
  top: -50px; }

.path-all-locations .tab_wrapper.accordion .content_wrapper {
  border-color: #ccc; }

.path-all-locations .tab_wrapper.accordion .content_wrapper .accordian_header {
  display: block; }

.path-all-locations .tab_wrapper.accordion > ul {
  display: none; }

.path-all-locations .tab_wrapper .active_tab {
  display: none;
  position: relative;
  background: #000000;
  padding: 12px;
  text-align: center;
  padding-right: 50px;
  color: #fff;
  cursor: pointer;
  border-radius: 30px; }

.path-all-locations .tab_wrapper .active_tab .arrow {
  width: 30px;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  background: #fff;
  position: absolute;
  right: 6px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%); }

.path-all-locations .tab_wrapper .active_tab .arrow:after {
  content: "";
  width: 10px;
  display: inline-block;
  height: 10px;
  border-right: 3px solid #000;
  border-bottom: 3px solid #000;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -8px; }

.path-all-locations .tab_wrapper .active_tab .text {
  text-indent: 39px;
  display: block; }

@media only screen and (max-width: 992px) {
  .path-all-locations .slider-nav .slick-slide .single-slider-img .slider-left .location_featured_image {
    display: block; }
  .path-all-locations .slider-nav .slick-slide .single-slider-img .slider-left .location_featured_hover_image {
    display: none; }
  .path-all-locations .slider-nav .slick-current .single-slider-img .slider-left .location_featured_image {
    display: none; }
  .path-all-locations .slider-nav .slick-current .single-slider-img .slider-left .location_featured_hover_image {
    display: block !important; }
  .path-all-locations .slider-nav .slick-current .single-slider-img .slider-title {
    font-family: "sharpSans-Semibold"; }
  .path-all-locations .slider-nav .slick-current .single-slider-img .slider-border {
    width: 10% !important;
    height: 5px !important;
    margin: auto; } }

@media only screen and (max-width: 992px) {
  .path-all-locations .tab_wrapper > ul {
    display: none; }
  .path-all-locations .tab_wrapper .content_wrapper {
    border: 0; }
  .path-all-locations .mobile-margin-location {
    padding: 0px; }
    .path-all-locations .mobile-margin-location .tab_content {
      padding: 15px 0px; }
      .path-all-locations .mobile-margin-location .tab_content .continent-country {
        margin: 0px 15px; }
      .path-all-locations .mobile-margin-location .tab_content .nav-continent-column h3 {
        padding-left: 0px; }
        .path-all-locations .mobile-margin-location .tab_content .nav-continent-column h3 a {
          padding-left: 15px; }
      .path-all-locations .mobile-margin-location .tab_content .nav-continent-column ul li a {
        padding-left: 15px; }
  .path-all-locations .continent-state h3 {
    margin-top: 30px;
    overflow: auto; }
  .path-all-locations .continent-state h3 a {
    font-family: "sharpSans-Bold";
    width: 100%;
    float: left;
    padding: 10px 0px; }
  .path-all-locations .tab_wrapper .content_wrapper .accordian_header {
    display: block; }
  .path-all-locations .tab_wrapper.left_side .content_wrapper, .path-all-locations .tab_wrapper.right_side .content_wrapper {
    width: 100%;
    border-top: 0; }
  .path-all-locations .tab_wrapper .controller {
    display: none; }
  .path-all-locations .tab_wrapper.show-as-dropdown > .tab_list {
    border: 0;
    box-shadow: 0 0 8px #ccc;
    position: absolute;
    z-index: 3;
    background: #fff;
    border-radius: 5px;
    margin-top: 15px; }
  .path-all-locations .tab_wrapper.show-as-dropdown > .tab_list:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 50%;
    width: 14px;
    height: 14px;
    background: #fff;
    transform: rotate(45deg);
    box-shadow: -3px -4px 5px #efefef;
    margin-left: -7px; }
  .path-all-locations .tab_wrapper.show-as-dropdown > .tab_list li {
    border: 0;
    border-bottom: 1px solid #ccc; }
  .path-all-locations .tab_wrapper.show-as-dropdown > .tab_list li.active:after {
    display: none; }
  .path-all-locations .tab_wrapper.show-as-dropdown .active_tab {
    display: block; }
  .path-all-locations .tab_wrapper.show-as-dropdown > .tab_list {
    display: none; }
  .path-all-locations .tab_wrapper.show-as-dropdown > .content_wrapper > .accordian_header {
    display: none; }
  .path-all-locations .tab_wrapper.show-as-dropdown > .tab_list li {
    display: block;
    float: none;
    width: 100%; }
  .path-all-locations .slick-arrow {
    display: none !important; }
  .path-all-locations .tab_wrapper .content_wrapper .accordian_header {
    padding-left: 15px !important; }
  .path-all-locations .continent-content-section-us {
    flex-direction: column; }
  .path-all-locations .tab_wrapper .nav-continent-column .continent-state {
    width: 100%;
    max-height: fit-content;
    margin-bottom: 0px; }
  .path-all-locations .tab_wrapper .content_wrapper .accordian_header {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #FFF;
    font-size: 18px;
    text-transform: capitalize;
    background: #eee; }
  .path-all-locations .single-slider-main .slider-description p {
    border: none; }
  .path-all-locations .slider-bottom.mobile-image-banner {
    display: block !important; }
  .path-all-locations .slider-bottom.desktop-image-banner {
    display: none; }
  .path-all-locations .job-top-title {
    font-size: 26px; }
  .path-all-locations .text-center {
    text-align: left !important; }
  .path-all-locations .job-body-desc {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .path-all-locations .tab_wrapper .nav-continent-column .continent-state ul, .path-all-locations .tab_wrapper .nav-continent-column .continent-state li {
    width: 100%;
    line-height: 45px;
    overflow: auto; }
    .path-all-locations .tab_wrapper .nav-continent-column .continent-state ul a, .path-all-locations .tab_wrapper .nav-continent-column .continent-state li a {
      color: #009DD6;
      font-size: 18px;
      font-family: "sharpSans-Semibold"; }
  .path-all-locations .continent-content-column .continent-state h3 a {
    color: #009DD6;
    font-size: 18px;
    font-family: "sharpSans-Semibold"; }
  .path-all-locations .tab_wrapper .nav-continent-column .continent-state ul {
    overflow: auto; }
  .path-all-locations .tab_wrapper .nav-continent-column .continent-state li a {
    float: left;
    width: 100%; }
  .path-all-locations .continent-content-column-us, .path-all-locations .continent-content-column {
    padding-right: 0px !important;
    width: 100%; }
  .path-all-locations .featured_locations_heading {
    font-size: 26px;
    color: #2A2A2A;
    text-align: center !important;
    padding-top: 40px; }
  .path-all-locations .single-slider-img .slider-title {
    font-size: 18px; }
  .path-all-locations .slider-nav .slick-current .slider-border {
    margin: auto; }
  .path-all-locations .single-slider-main .slider-title span {
    font-size: 26px; }
  .path-all-locations .single-slider-main .slider-title a {
    font-size: 18px; }
  .path-all-locations .single-slider-main .slider-description p {
    padding-left: 0px !important;
    padding-top: 20px; }
  .path-all-locations .slider-outer-banner {
    padding: 30px 0px; } }

@media only screen and (min-width: 993px) {
  .path-all-locations .tab_wrapper.show-as-dropdown > .tab_list {
    display: block !important;
    overflow: hidden; } }

@media only screen and (min-width: 993px) and (max-width: 1100px) {
  .path-all-locations .single-slider-img .slider-left img {
    width: 90%; }
  .path-all-locations .slider-nav .slick-current .slider-border {
    margin: auto; }
  .path-all-locations .first.tab_1_1 .continent-content-column-us:last-child .nav-continent-column {
    height: 685px; }
  .path-all-locations .tab_wrapper .nav-continent-column .continent-state ul {
    width: 110px; }
  .path-all-locations .tab_wrapper .nav-continent-column .continent-state li {
    width: 110px; }
  .path-all-locations .tab_wrapper ul li.continent-section .continent {
    width: 100%;
    height: 100%; }
  .path-all-locations .single-slider-main .slider-title a {
    padding: 10px 10px 10px 15px; }
  .path-all-locations .tab_wrapper ul li.continent-section .continent-hover {
    width: 100%;
    height: 100%; }
  .path-all-locations .tab_wrapper .nav-continent-column .continent-state {
    max-height: 350pxpx; }
  .path-all-locations .tab_wrapper ul li.continent-section.active a, .path-all-locations .tab_wrapper ul li.continent-section a {
    font-size: 16px; }
  .path-all-locations .tab_wrapper .country-tab-text {
    padding-left: 0px; } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .path-all-locations ::i-block-chrome a, .path-all-locations .tab_wrapper ul li.continent-section a {
    font-size: 20px !important; }
  .path-all-locations ::i-block-chrome a, .path-all-locations .tab_wrapper ul li.continent-section.active a {
    font-size: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .path-all-locations .slider-nav .slick-list {
    width: 100%; } }

@media only screen and (min-width: 1801px) {
  .path-all-locations .slider-for .slick-list {
    height: auto !important; }
  .path-all-locations .slider-bottom {
    max-height: initial; } }

.node--type-page-program .featured-top .field--name-field-banner-image:after {
  background-size: 75% !important; }

.node--type-page-program #faqs-react-main-wrapper-page-program {
  margin-top: 93px; }
  .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .sub-header-title,
  .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .item-title-hidden h3:hover,
  .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .accordion-title {
    color: #009DD6; }
  .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .accordion-item-body {
    border-bottom: 2px solid #009DD6; }
  .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .item-title-hidden h3 {
    color: #444; }
  .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .accordion-title h3 {
    background: url(../images/resources/blue-up-arrow.png) no-repeat scroll;
    background-size: 20px 11px;
    -webkit-background-size: 20px 11px;
    background-position: 99.7% 31px; }
  .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .accordion-title.item-title-hidden {
    color: #444;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid rgba(119, 136, 153, 0.5); }
    .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .accordion-title.item-title-hidden h3 {
      padding-right: 40px;
      font-size: 18px;
      position: relative;
      padding: 25px 35px 35px 0;
      background: url("../images/resources/down-arrow.png") no-repeat scroll;
      background-size: 20px 20px;
      -webkit-background-size: 20px 20px;
      background-position: 99.7% 27px; }
      .node--type-page-program #faqs-react-main-wrapper-page-program .faqs-main-wrapper .accordion-title.item-title-hidden h3:hover {
        background: url("../images/resources/blue-down-arrow.png") no-repeat scroll;
        background-size: 20px 11px;
        -webkit-background-size: 20px 11px;
        background-position: 99.7% 31px; }

.node--type-page-program #block-contentfield-3 {
  position: relative; }

.node--type-page-program .banner_logos {
  position: absolute;
  bottom: 40px; }
  .node--type-page-program .banner_logos .field__label {
    display: none; }
  .node--type-page-program .banner_logos .field--name-field-banner-logo-small {
    float: left;
    padding-right: 20px;
    border-right: 1px solid #979797;
    position: relative;
    bottom: 0;
    width: 220px; }
  .node--type-page-program .banner_logos .field--name-field-banner-logo-small-right {
    float: left;
    padding-left: 20px; }

.node--type-page-program .cultureHeaderSection .side-title {
  font-family: "sharpSans-Semibold";
  line-height: 29px;
  font-size: 23px;
  font-weight: 600;
  color: #2A2A2A; }

.node--type-page-program .cultureHeaderSection .title-border {
  width: 75px;
  height: 6px;
  background-color: #009DD6; }

.node--type-page-program div.brand-page-cont h2 {
  font-family: "sharpSans-Book";
  margin-top: 0px;
  color: #2A2A2A;
  font-size: 40px;
  line-height: 50px;
  font-weight: 300; }

.node--type-page-program div.brand-page-cont p {
  font-size: 18px;
  color: #2A2A2A;
  margin-top: 10px;
  line-height: 30px; }

.node--type-page-program .erg_heading p {
  font-size: 18px;
  color: #2A2A2A;
  margin-top: 20px;
  font-family: "SourceSansPro-Regular";
  line-height: 30px;
  text-align: center; }

.node--type-page-program .erg_heading .employee-resource-groups {
  text-align: center; }
  .node--type-page-program .erg_heading .employee-resource-groups h2 {
    font-size: 40px;
    line-height: 46px;
    margin-top: 74px;
    font-family: "sharpSans-Book";
    margin-bottom: 20px; }

.node--type-page-program .program_experience {
  width: 70%;
  margin: 0 auto;
  margin-top: 0%; }
  .node--type-page-program .program_experience .erg_heading .employee-resource-groups h2 {
    margin-top: 74px; }
  .node--type-page-program .program_experience .erg_groups {
    margin: 0 auto;
    margin-top: 100px;
    padding-left: 7%; }
    .node--type-page-program .program_experience .erg_groups .erg_img {
      margin-bottom: 93px;
      padding-right: 0px;
      max-width: 25.2%;
      margin-right: 43px; }
    .node--type-page-program .program_experience .erg_groups .erg_title {
      padding-left: 0px; }
      .node--type-page-program .program_experience .erg_groups .erg_title h4 {
        font-weight: 600;
        font-size: 23px;
        line-height: 25px;
        margin-bottom: 8px;
        color: #2A2A2A;
        font-family: "sharpSans-Semibold";
        padding-left: 0px; }
      .node--type-page-program .program_experience .erg_groups .erg_title p {
        font-size: 16px;
        line-height: 30px;
        color: #2A2A2A; }

.node--type-page-program .add_marg_top {
  margin-top: 40px;
  display: table; }

.node--type-page-program .program_alumni .erg_heading .employee-resource-groups h2 {
  margin-bottom: 40px; }

.node--type-page-program .culture_sec_banner {
  margin-top: 83px; }
  .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div {
    padding: 16% 50px 7% 100px;
    align-self: unset !important;
    position: relative; }
    .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div h4 {
      font-size: 48px;
      line-height: 60px;
      font-family: "sharpSans-Bold";
      padding-bottom: 40px;
      font-weight: bold;
      letter-spacing: 0.53px; }
    .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div h2 {
      font-size: 40px;
      line-height: 46px;
      font-family: "sharpSans-Medium";
      padding-bottom: 20px; }
    .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div p {
      max-height: 180px;
      margin-bottom: 0px;
      font-size: 18px;
      line-height: 30px;
      color: #fff;
      overflow: hidden; }
    .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div a,
    .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div div.btn {
      position: absolute;
      bottom: 70px;
      height: 45px;
      padding: 10px 15px;
      font-size: 17px;
      line-height: 22px;
      color: #fff;
      font-family: "SourceSansPro-Semibold"; }
      .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div a i,
      .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div div.btn i {
        margin-left: 5px; }
    .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div a.apply-button {
      color: #212529; }
    .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div .btn-disabled {
      border: 1px solid #878787;
      opacity: 0.9;
      background: #878788; }
  .node--type-page-program .culture_sec_banner .grey-color {
    background-color: #b9babd; }
  .node--type-page-program .culture_sec_banner .purple-color {
    background-color: #deb932; }
  .node--type-page-program .culture_sec_banner .spotlight_second_img {
    position: relative;
    padding: 0px; }
    .node--type-page-program .culture_sec_banner .spotlight_second_img .spotlight_text_box {
      position: absolute;
      bottom: 40px;
      left: 89px;
      z-index: 9999;
      color: #fff; }
      .node--type-page-program .culture_sec_banner .spotlight_second_img .spotlight_text_box span:first-child {
        font-weight: bold;
        font-size: 48px;
        line-height: 60px;
        font-family: "sharpSans-Bold";
        letter-spacing: 0.53px; }
      .node--type-page-program .culture_sec_banner .spotlight_second_img .spotlight_text_box span:last-child {
        font-size: 42px;
        font-weight: 300;
        line-height: 54px;
        font-family: "sharpSans-Light";
        letter-spacing: 0.46px; }
    .node--type-page-program .culture_sec_banner .spotlight_second_img img {
      width: 100%;
      height: 100%;
      max-height: 520px; }

.node--type-page-program .slider-alumni-nav-parent .slick-list {
  width: 85%;
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    .node--type-page-program .slider-alumni-nav-parent .slick-list {
      width: 100%; } }

.node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main {
  padding: 10px 20px 14px 0px; }
  @media (max-width: 767.98px) {
    .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main {
      padding: 10px 7.5px 14px 7.5px; } }
  .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-image {
    position: relative;
    height: 260px;
    vertical-align: middle;
    border: 1px solid #ccc; }
    .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-image img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
    .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-image:before {
      position: absolute;
      top: 50%;
      left: 50%;
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      content: url(../images/svg/Icon_Video.svg);
      width: 75px;
      color: #fff;
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
  .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-details {
    color: #fff;
    text-align: center;
    padding: 2% 4%;
    position: relative;
    min-height: 96px;
    display: table;
    width: 100%;
    height: 102px; }
    @media (max-width: 767.98px) {
      .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-details {
        min-height: 130px;
        height: 130px; } }
    @media (max-width: 767.98px) {
      .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-details {
        min-height: 150px;
        height: 150px; } }
    .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-details .test_UserDetailsubDiv {
      display: table-cell;
      vertical-align: middle; }
    .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-details .user-title {
      color: #FFFFFF;
      font-size: 24px;
      font-family: "SourceSansPro-Regular"; }
    .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-details .user-designation {
      font-size: 18px;
      font-family: "SourceSansPro-Semibold";
      text-transform: uppercase;
      width: 92%;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-details .user-designation {
          padding: 10px;
          font-family: "SourceSansPro-Semibold"; } }
    .node--type-page-program .slider-alumni-nav-parent .testimonial-nav-main .user-details i {
      position: absolute;
      right: 10px;
      bottom: 20px;
      transform: rotate(45deg); }

.node--type-page-program .testimonial-nav-main:after,
.node--type-page-program .testimonial-nav-main:before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 19px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #EEE;
  -webkit-box-shadow: 0 6px 12px #777;
  box-shadow: 0 6px 12px #777;
  -webkit-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg); }

.node--type-page-program .testimonial-nav-main {
  position: relative; }

.node--type-page-program .testimonial-nav-main:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 28px;
  left: auto; }

.node--type-page-program .hover-testimonial-nav-main .testimonial-nav-main:hover:before,
.node--type-page-program .hover-testimonial-nav-main .testimonial-nav-main:hover:after {
  -webkit-box-shadow: 0 8px 9px #777;
  -moz-box-shadow: 0 8px 9px #777;
  box-shadow: 0 8px 9px #777; }

.node--type-page-program .slick-arrow {
  border-radius: 0px !important;
  height: 20px;
  width: 21px;
  z-index: 1;
  display: none; }

.node--type-page-program .cr-lavendar {
  background-color: #7F679F; }

.node--type-page-program .cr-orange {
  background-color: #F36A30; }

.node--type-page-program .cr-sky-blue {
  background-color: #009DD6; }

.node--type-page-program .cr-light-green {
  background-color: #59a63f; }

.node--type-page-program .slider-alumni-for-parent {
  position: relative;
  width: 750px;
  margin: 0 auto 93px; }
  .node--type-page-program .slider-alumni-for-parent .close-button {
    padding: 0;
    background-color: transparent;
    border: 0;
    font-size: 34px;
    font-weight: 700;
    line-height: 15px;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 1800;
    position: absolute;
    right: 60px;
    top: 10px; }
  .node--type-page-program .slider-alumni-for-parent .slider {
    z-index: 1500;
    padding-top: 60px; }
  .node--type-page-program .slider-alumni-for-parent .slick-list {
    width: 630px;
    margin: 0 auto; }
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .brightcove-video-main .video-js {
    width: 100%;
    margin: 0 auto;
    height: 355px; }
    @media (max-width: 767.98px) {
      .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .brightcove-video-main .video-js {
        height: 197px; } }
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details {
    padding: 2%;
    color: #fff;
    margin: 0; }
    @media (max-width: 767.98px) {
      .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details {
        padding: 20px; } }
    .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details .user-title {
      font-size: 24px;
      line-height: 35px; }
      @media (max-width: 767.98px) {
        .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details .user-title {
          font-size: 24px;
          font-family: "SourceSansPro-Regular"; } }
    .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details .user-content {
      border-left: 1px solid #fff;
      font-family: "SourceSansPro-Semibold"; }
      .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details .user-content .user-designation {
        font-size: 18px;
        line-height: 35px;
        text-transform: uppercase; }
      .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details .user-content .user-des {
        font-size: 18px;
        padding-top: 5px; }
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .cr-orange {
    background-color: #F36A30; }
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .cr-sky-blue {
    background-color: #009DD6; }
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .cr-lavendar {
    background-color: #7F679F; }
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .cr-light-green {
    background-color: #59a63f; }

.node--type-page-program .slick-arrow:hover {
  border: 1px solid #FFF; }

.node--type-page-program .slick-arrow::before {
  content: ' ' !important;
  font-size: 0px !important; }

.node--type-page-program .slick-next.slick-arrow {
  margin-right: 0; }

.node--type-page-program .slick-next {
  right: 0; }

.node--type-page-program .slick-prev {
  left: 0; }

.node--type-page-program .slick-prev.slick-arrow {
  margin-left: 0; }

.node--type-page-program .carousel-control-next-icon,
.node--type-page-program .carousel-control-prev-icon {
  border: 1px solid transparent;
  background-color: transparent;
  background-size: 100% 100% !important;
  background-repeat: no-repeat; }

.node--type-page-program .carousel-control-next-icon {
  background-image: url(../images/icons/carousel-right.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.node--type-page-program .carousel-control-next-icon:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

.node--type-page-program .carousel-control-prev-icon {
  background-image: url(../images/icons/carousel-left.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform; }

.node--type-page-program .carousel-control-prev-icon:hover {
  background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
  -webkit-transform: scale(1.2);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: scale(1.2);
  /* IE 9 */
  transform: scale(1.2);
  background-size: 100% 100%;
  background-repeat: no-repeat; }

.node--type-page-program .desktop-hide {
  display: none; }

@media only screen and (max-width: 600px) {
  .node--type-page-program .desktop-hide {
    display: block;
    margin-top: 0px !important; }
  .node--type-page-program .mobile-hide {
    display: none; }
  .node--type-page-program .erg_heading .employee-resource-groups h2 {
    font-size: 26px;
    line-height: 26px;
    margin-top: 20px; }
  .node--type-page-program .program_experience {
    width: 100%;
    padding: 0px; }
    .node--type-page-program .program_experience .erg_groups {
      padding: 0px 10px; }
      .node--type-page-program .program_experience .erg_groups .erg_img {
        margin-bottom: 30px; }
        .node--type-page-program .program_experience .erg_groups .erg_img img {
          width: 100%; }
      .node--type-page-program .program_experience .erg_groups .card-body img {
        width: 100%;
        margin-bottom: 15px; }
    .node--type-page-program .program_experience .erg_heading .employee-resource-groups h2 {
      margin-top: 20px; }
    .node--type-page-program .program_experience .erg_heading p {
      padding: 0px 10px; }
  .node--type-page-program .testimonial-for-main .user-details {
    padding: 47px 20px 20px 20px !important;
    display: inline-block !important;
    width: 100% !important; }
    .node--type-page-program .testimonial-for-main .user-details .user-image {
      width: 100% !important;
      height: auto !important; }
      .node--type-page-program .testimonial-for-main .user-details .user-image img {
        height: auto !important; }
    .node--type-page-program .testimonial-for-main .user-details .user-content {
      width: 100% !important;
      margin-left: 0px !important; }
  .node--type-page-program .program_alumni {
    padding: 0px 10px; }
    .node--type-page-program .program_alumni .erg_heading .employee-resource-groups h2 {
      margin-top: 40px; }
  .node--type-page-program .employee-resource-groups {
    font-size: 26px;
    line-height: 26px; }
  .node--type-page-program .culture_sec_banner {
    margin-top: 40px;
    margin-bottom: 0px; }
    .node--type-page-program .culture_sec_banner .mobile-hide {
      display: block; }
    .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div {
      padding: 30px 15px 35px 15px !important;
      width: 100%;
      height: auto;
      min-height: 300px; }
      .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div h4 {
        font-size: 34px;
        line-height: 40px; }
      .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div .text-white {
        padding-bottom: 10px !important; }
      .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div a {
        position: unset; }
  .node--type-page-program .spotlight_text_box {
    bottom: 28px !important;
    left: 10px !important; }
    .node--type-page-program .spotlight_text_box span:first-child {
      font-size: 42px !important;
      line-height: 54px !important; }
    .node--type-page-program .spotlight_text_box span:last-child {
      font-size: 38px !important;
      line-height: 48px !important; }
  .node--type-page-program .slider-alumni-for-parent .close-button {
    top: 10px !important;
    right: 11px !important; }
  .node--type-page-program .brand-page-cont h2 {
    font-size: 30px !important;
    line-height: 42px !important; }
  .node--type-page-program .card-body {
    padding: 0px; }
  .node--type-page-program .mobile_logo_small {
    position: absolute;
    bottom: 54px;
    left: 26px;
    z-index: 999; } }

@media only screen and (max-width: 600px) and (max-width: 991.98px) {
  .node--type-page-program .erg_groups.desktop-hide .collapse.show {
    border-bottom: 2px solid #f06e37;
    border-top: 1px solid #ffffff;
    top: -1px;
    position: relative; } }

@media only screen and (max-width: 600px) {
  .node--type-page-program .erg_groups .accordion .card-header {
    padding: 4px 0px 5px 0px;
    background-color: #fff !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    overflow: auto; }
    .node--type-page-program .erg_groups .accordion .card-header a.collapsed {
      color: #212529; }
      .node--type-page-program .erg_groups .accordion .card-header a.collapsed span.arrow {
        float: right;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        border-radius: 2px;
        -webkit-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #fff;
        margin-top: 5px;
        border-top: 3px solid #ccc;
        border-left: 3px solid #ccc; }
    .node--type-page-program .erg_groups .accordion .card-header a {
      font-size: 18px;
      font-family: "sharpSans-Semibold";
      padding: 10px 0px;
      color: #F36A30;
      z-index: 500;
      width: 100%;
      float: left;
      text-decoration: none; }
      .node--type-page-program .erg_groups .accordion .card-header a span.arrow {
        float: right;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        right: 3px;
        border-radius: 2px;
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 9px;
        border-top: 3px solid #F36A30;
        border-left: 3px solid #F36A30; } }

/* Small devices (portrait tablets and large phones ) */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 676px) and (max-width: 991px) {
  .node--type-page-program .slider-alumni-nav-parent .slider-nav .slick-prev, .node--type-page-program .slider-alumni-nav-parent .slider-nav .slick-next, .node--type-page-program .slider-alumni-nav-parent .slider-nav .slick-arrow {
    display: none !important; } }

@media only screen and (min-width: 992px) {
  .node--type-page-program .carousel-control-next-icon,
  .node--type-page-program .carousel-control-prev-icon {
    display: block; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .node--type-page-program .slider-alumni-nav-parent .slick-list {
    width: 85%; }
  .node--type-page-program .slider-alumni-nav-parent .slick-next {
    right: 10px; }
  .node--type-page-program .slider-alumni-nav-parent .slick-prev {
    left: 10px; } }

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .node--type-page-program .featured-top .field--name-field-banner-content-para .field--name-field-banner-info-text {
    width: 60%; }
  .node--type-page-program .program_experience {
    width: 100%; }
    .node--type-page-program .program_experience .erg_heading .employee-resource-groups h2 {
      margin-top: 40px; }
    .node--type-page-program .program_experience .erg_groups {
      padding-left: 0%;
      margin-top: 50px; }
      .node--type-page-program .program_experience .erg_groups .accordion .card-body {
        padding: 15px 0px 0px; }
    .node--type-page-program .program_experience .mobile-hide {
      display: none; }
    .node--type-page-program .program_experience .desktop-hide {
      display: block; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (max-width: 991.98px) {
    .node--type-page-program .program_experience .erg_groups.desktop-hide .collapse.show {
      border-bottom: 2px solid #f06e37;
      border-top: 1px solid #ffffff;
      top: -1px;
      position: relative; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .node--type-page-program .program_experience .erg_groups .accordion .card-header {
      padding: 4px 0px 5px 0px;
      background-color: #fff !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      overflow: auto; }
      .node--type-page-program .program_experience .erg_groups .accordion .card-header a.collapsed {
        color: #212529; }
        .node--type-page-program .program_experience .erg_groups .accordion .card-header a.collapsed span.arrow {
          float: right;
          display: inline-block;
          width: 12px;
          height: 12px;
          position: relative;
          border-radius: 2px;
          -webkit-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
          background: #fff;
          margin-top: 5px;
          border-top: 3px solid #ccc;
          border-left: 3px solid #ccc; }
      .node--type-page-program .program_experience .erg_groups .accordion .card-header a {
        font-size: 18px;
        font-family: "sharpSans-Semibold";
        padding: 10px 0px;
        color: #F36A30;
        z-index: 500;
        width: 100%;
        float: left;
        text-decoration: none; }
        .node--type-page-program .program_experience .erg_groups .accordion .card-header a span.arrow {
          float: right;
          display: inline-block;
          width: 12px;
          height: 12px;
          position: relative;
          right: 3px;
          border-radius: 2px;
          -webkit-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          margin-top: 9px;
          border-top: 3px solid #F36A30;
          border-left: 3px solid #F36A30; }
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details .user-image {
    width: 200px; }
    .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details .user-image img {
      height: auto; }
  .node--type-page-program .culture_sec_banner .second_content_culture .second_content_div {
    padding: 5% 50px 27% 100px; }
  .node--type-page-program #faqs-react-main-wrapper-page-program {
    padding: 0px 20px; }
  .node--type-page-program .card-body p {
    margin-top: 20px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .node--type-page-program .slider-alumni-for-parent {
    width: 100%; } }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
  .node--type-page-program .culture_sec_banner .spotlight_second_img img {
    max-height: 100%; } }

@media only screen and (max-width: 400px) {
  .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .brightcove-video-main .video-js {
    height: 180px !important; } }

@media only screen and (max-width: 767px) {
  .node--type-page-program .erg_groups .accordion .card-header {
    padding: 4px 0px 5px 0px;
    background-color: #fff !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    overflow: auto; }
    .node--type-page-program .erg_groups .accordion .card-header a.collapsed {
      color: #212529; }
      .node--type-page-program .erg_groups .accordion .card-header a.collapsed span.arrow {
        float: right;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        border-radius: 2px;
        -webkit-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #fff;
        margin-top: 5px;
        border-top: 3px solid #ccc;
        border-left: 3px solid #ccc; }
    .node--type-page-program .erg_groups .accordion .card-header a {
      font-size: 18px;
      font-family: "sharpSans-Semibold";
      padding: 10px 0px;
      color: #F36A30;
      z-index: 500;
      width: 100%;
      float: left;
      text-decoration: none; }
      .node--type-page-program .erg_groups .accordion .card-header a span.arrow {
        float: right;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        right: 3px;
        border-radius: 2px;
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 9px;
        border-top: 3px solid #F36A30;
        border-left: 3px solid #F36A30; }
  .node--type-page-program .banner_logos {
    display: none; }
  .node--type-page-program .mobile_logo_small {
    display: block; }
  .node--type-page-program .slider-alumni-for-parent {
    width: 100%;
    padding-top: 35px;
    padding-bottom: 30px; }
    .node--type-page-program .slider-alumni-for-parent .close-button {
      top: 0 !important;
      right: 30px !important; }
    .node--type-page-program .slider-alumni-for-parent .slider {
      padding-top: 35px; }
    .node--type-page-program .slider-alumni-for-parent .slick-list {
      width: 85.5% !important;
      margin: auto; }
    .node--type-page-program .slider-alumni-for-parent .testimonial-for-main .user-details {
      padding: 20px 10px !important;
      display: flex !important;
      margin: 0; }
  .node--type-page-program .brand-page-cont h2 {
    border-top: none; }
  .node--type-page-program #faqs-react-main-wrapper-page-program {
    padding: 0px 20px;
    margin-top: 40px; }
  .node--type-page-program .featured-top aside {
    padding: 0px; }
    .node--type-page-program .featured-top aside section {
      margin: 0px; } }

@media (min-width: 576px) {
  .pageprogram_popup .modal-dialog {
    max-width: 650px; } }

.pageprogram_popup .modal-dialog .modal-content {
  border: none;
  background: none; }
  .pageprogram_popup .modal-dialog .modal-content .modal-header {
    border: none; }
    .pageprogram_popup .modal-dialog .modal-content .modal-header .close {
      cursor: pointer;
      padding: 2px 5px;
      line-height: 20px;
      font-size: 40px;
      background: #ffffff;
      z-index: 999999;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; }
  .pageprogram_popup .modal-dialog .modal-content .modal-body .video-js {
    height: 350px;
    width: 100%; }
    @media (max-width: 767.98px) {
      .pageprogram_popup .modal-dialog .modal-content .modal-body .video-js {
        height: 205px; } }

.node--type-media-tech-page .instagram-popup-container .popup-text-container p {
  box-sizing: border-box; }

.node--type-media-tech-page .featured-top .field--name-field-banner-image:after {
  background-size: 70% !important; }

.node--type-media-tech-page .title-border {
  width: 65px !important;
  height: 6px;
  background-color: #009DD6;
  margin-bottom: 31px;
  border-bottom: none !important; }

.node--type-media-tech-page #faqs-react-main-wrapper {
  margin-top: 93px; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .sub-header-title, .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .item-title-hidden h3:hover, .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .accordion-title {
    color: #009DD6; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .accordion-item-body {
    border-bottom: 2px solid #009DD6; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .item-title-hidden h3 {
    color: #444; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .accordion-title h3 {
    background: url(../images/resources/blue-up-arrow.png) no-repeat scroll;
    background-size: 20px 11px;
    -webkit-background-size: 20px 11px;
    background-position: 99.7% 31px; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .accordion-title.item-title-hidden {
    color: #444;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid rgba(119, 136, 153, 0.5); }
    .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .accordion-title.item-title-hidden h3 {
      padding-right: 40px;
      font-size: 18px;
      position: relative;
      padding: 25px 35px 35px 0;
      background: url("../images/resources/down-arrow.png") no-repeat scroll;
      background-size: 20px 20px;
      -webkit-background-size: 20px 20px;
      background-position: 99.7% 27px; }
      .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .accordion-title.item-title-hidden h3:hover {
        background: url("../images/resources/blue-down-arrow.png") no-repeat scroll;
        background-size: 20px 11px;
        -webkit-background-size: 20px 11px;
        background-position: 99.7% 31px; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .mediatech-faq {
    text-align: left !important;
    width: 1080px;
    cursor: pointer;
    padding: 30px 0;
    background: url("../images/resources/blue-up-arrow.png") no-repeat scroll !important;
    background-size: 20px 10px !important;
    background-position: 99.7% 45px !important;
    border-bottom-style: solid;
    border-bottom-width: .8px;
    border-bottom-color: #eaeaea; }
    .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .mediatech-faq.false {
      background: url("../images/resources/down-arrow.png") no-repeat scroll !important;
      background-size: 20px 20px !important;
      background-position: 99.7% 40px !important; }
      .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .mediatech-faq.false:hover {
        background: url("../images/resources/blue-down-arrow.png") no-repeat scroll !important;
        background-size: 19px 10px !important;
        background-position: 99.7% 45px !important; }

.node--type-media-tech-page .brand-page-cont h2 {
  color: #2A2A2A;
  font-size: 40px;
  letter-spacing: 0.44px;
  line-height: 50px;
  margin-bottom: 21px;
  font-family: "sharpSans-Medium" !important; }

.node--type-media-tech-page .brand-page-cont p {
  margin-top: 10px;
  font-family: "SourceSansPro-Regular"; }

.node--type-media-tech-page .mt-stat-blocks {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  height: 132px; }
  .node--type-media-tech-page .mt-stat-blocks .static-title {
    color: #009DD6;
    font-family: "SourceSansPro-Semibold";
    font-size: 18px;
    line-height: 23px;
    padding-top: 25px; }
  .node--type-media-tech-page .mt-stat-blocks .static-desc p {
    line-height: 23px;
    margin-top: 0px;
    font-size: 17px;
    font-family: "SourceSansPro-Regular"; }

.node--type-media-tech-page #opportunities {
  margin-bottom: 53px;
  margin-top: 29px; }
  .node--type-media-tech-page #opportunities h2 {
    font-family: "sharpSans-Medium";
    font-size: 40px;
    letter-spacing: 0.44px;
    line-height: 50px; }
  .node--type-media-tech-page #opportunities .opportunity-desc {
    font-family: "SourceSansPro-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px; }
    .node--type-media-tech-page #opportunities .opportunity-desc span {
      font-family: "SourceSansPro-Bold";
      font-weight: regular; }

.node--type-media-tech-page .mt-programs {
  margin-left: 0px !important;
  margin-right: 0px !important; }
  .node--type-media-tech-page .mt-programs .mt-asso-block {
    background-color: #009DD6; }
  .node--type-media-tech-page .mt-programs .mt-intern-block {
    background-color: #59A63F; }
  .node--type-media-tech-page .mt-programs .mt-pgm-block {
    padding-left: 73px;
    padding-top: 39px;
    padding-bottom: 60px;
    padding-right: 85px; }
  .node--type-media-tech-page .mt-programs .mt-asso-block .mt-pgm-link .btn-light:hover {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #009DD6; }
  .node--type-media-tech-page .mt-programs .mt-intern-block .mt-pgm-link .btn-light:hover {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #59A63F; }
  .node--type-media-tech-page .mt-programs .mt-pgm-title {
    color: #FFFFFF;
    font-family: "sharpSans-Semibold";
    font-size: 23px;
    letter-spacing: 0.01px;
    line-height: 29px; }
  .node--type-media-tech-page .mt-programs .mt-pgm-desc {
    color: #FFFFFF;
    font-family: "SourceSansPro-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px; }
  .node--type-media-tech-page .mt-programs .mt-pgm-link a {
    color: #2A2A2A;
    font-family: "SourceSansPro-Semibold";
    font-size: 17px;
    letter-spacing: 0.52px;
    line-height: 22px;
    width: 150px;
    height: 45px;
    padding: 10px 0px; }
    .node--type-media-tech-page .mt-programs .mt-pgm-link a span {
      position: relative;
      left: 2px;
      top: 2px; }

.node--type-media-tech-page .employee-resource-groups h2 {
  font-size: 34px !important;
  letter-spacing: 0;
  line-height: 44px;
  font-family: "sharpSans-Medium" !important; }

.node--type-media-tech-page #faqs-react-main-wrapper {
  margin-bottom: 97px; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper h1 {
    font-size: 40px;
    letter-spacing: 0.44px !important;
    line-height: 50px;
    font-family: "sharpSans-Medium"; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper .sub-header-title {
    font-family: "SourceSansPro-Semibold" !important; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper .accordion-container:first-child {
    margin-bottom: 35px; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper .accordion-container {
    padding-right: 100px; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper .accordion-body > *:last-child .accordion-title {
    border-bottom: none !important; }
  .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper .MuiCardContent-root-5, .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper .opened-track-content {
    padding-bottom: 0px !important; }

.node--type-media-tech-page .intern-instagram-main-wrapper.container-fluid-lg {
  max-width: 1135px !important; }

.node--type-media-tech-page .cultureHeaderSection .side-title span {
  font-family: "sharpSans-Semibold";
  font-size: 23px;
  letter-spacing: 0.25px;
  line-height: 29px; }

.node--type-media-tech-page .cultureHeaderSection .sidelink {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 23px;
  color: #686868;
  font-family: "SourceSansPro-Semibold"; }
  .node--type-media-tech-page .cultureHeaderSection .sidelink a {
    color: #686868 !important; }
  .node--type-media-tech-page .cultureHeaderSection .sidelink a:hover {
    color: #009DD6 !important;
    text-decoration: none; }

.node--type-media-tech-page .cultureHeaderSection .sidelink:last-child {
  margin-bottom: 0px; }

.node--type-media-tech-page .banner_logos .field--name-field-banner-logo-small-right {
  padding-left: 23px;
  position: relative;
  bottom: 10px; }
  .node--type-media-tech-page .banner_logos .field--name-field-banner-logo-small-right .field__item img {
    margin-bottom: 0px; }

.node--type-media-tech-page .banner_logos .field--name-field-banner-logo-small {
  padding-right: 23px; }

.node--type-media-tech-page .ops-row {
  margin-top: 38px;
  margin-left: 0px;
  margin-right: 0px; }

.node--type-media-tech-page .mt-asso-block .mt-pgm-desc p {
  margin-bottom: 20px;
  margin-top: 20px; }
  .node--type-media-tech-page .mt-asso-block .mt-pgm-desc p:last-child {
    margin-bottom: 51px; }

.node--type-media-tech-page .mt-asso-block .as-link .btn-light {
  color: #2A2A2A;
  font-family: "Source Sans Pro";
  font-size: 17px;
  letter-spacing: 0.52px;
  line-height: 22px; }

.node--type-media-tech-page .mt-asso-block .as-link span {
  margin-left: 5px; }

.node--type-media-tech-page .mt-intern-block .mt-pgm-desc p {
  margin-bottom: 20px;
  margin-top: 20px; }
  .node--type-media-tech-page .mt-intern-block .mt-pgm-desc p:last-child {
    margin-bottom: 51px; }

.node--type-media-tech-page .mt-intern-block .in-link .btn-light {
  color: #2A2A2A;
  font-family: "Source Sans Pro";
  font-size: 17px;
  letter-spacing: 0.52px;
  line-height: 22px; }

.node--type-media-tech-page .mt-intern-block .in-link span {
  margin-left: 5px; }

.node--type-media-tech-page .sec-banner-section {
  margin-left: 0px !important;
  margin-right: 0px !important; }
  .node--type-media-tech-page .sec-banner-section .desktop-hide {
    display: none; }
  .node--type-media-tech-page .sec-banner-section .sec-banner-content {
    background: #7F679F;
    padding: 122px 125px 69px 96px; }
    .node--type-media-tech-page .sec-banner-section .sec-banner-content .sec-banner-title p {
      color: #FFFFFF;
      font-family: "sharpSans-Bold";
      font-size: 48px;
      letter-spacing: 0.53px;
      line-height: 60px; }
      .node--type-media-tech-page .sec-banner-section .sec-banner-content .sec-banner-title p:first-child {
        margin-bottom: 0px; }
      .node--type-media-tech-page .sec-banner-section .sec-banner-content .sec-banner-title p:last-child {
        font-family: "sharpSans-Medium";
        font-size: 48px;
        margin-bottom: 18px; }
    .node--type-media-tech-page .sec-banner-section .sec-banner-content .sec-banner-desc {
      color: #FFFFFF;
      font-family: "SourceSansPro-Regular";
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 23px; }
  .node--type-media-tech-page .sec-banner-section .sec_banner_img {
    padding: 0px; }
    .node--type-media-tech-page .sec-banner-section .sec_banner_img .sec-feature-img .img-fluid {
      width: 100%; }

.node--type-media-tech-page .mt-stat-blocks {
  border-right: 1px solid #979797;
  padding-left: 51px; }
  .node--type-media-tech-page .mt-stat-blocks:last-child {
    border-right: 0px; }
  .node--type-media-tech-page .mt-stat-blocks:first-child {
    padding-left: 0px; }

@media (min-width: 576px) {
  .node--type-media-tech-page #video_popup .modal-dialog {
    max-width: 650px; } }

.node--type-media-tech-page #video_popup .modal-dialog .modal-content {
  border: none;
  background: none; }
  .node--type-media-tech-page #video_popup .modal-dialog .modal-content .modal-header {
    border: none; }
    .node--type-media-tech-page #video_popup .modal-dialog .modal-content .modal-header .close {
      cursor: pointer;
      padding: 2px 5px;
      line-height: 20px;
      font-size: 40px;
      background: #ffffff;
      z-index: 999999;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; }
  .node--type-media-tech-page #video_popup .modal-dialog .modal-content .modal-body .video-js {
    height: 350px;
    width: 100%; }
    @media (max-width: 767.98px) {
      .node--type-media-tech-page #video_popup .modal-dialog .modal-content .modal-body .video-js {
        height: 205px; } }

@media only screen and (max-width: 768px) {
  .node--type-media-tech-page .text-heading-1 {
    font-size: 48px; }
  .node--type-media-tech-page .carousel-control-next-icon {
    display: block !important;
    top: -10px; }
  .node--type-media-tech-page .carousel-control-prev-icon {
    display: block !important;
    top: -10px; }
  .node--type-media-tech-page #faqs-react-main-wrapper {
    padding: 0px 20px; }
    .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper h1 {
      width: 100%;
      color: #009DD6 !important; }
    .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper .accordion-container {
      padding-right: 0px !important; }
  .node--type-media-tech-page .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding-top: 67px;
    padding-left: 20px;
    font-size: 48px;
    padding-right: 45px; }
  .node--type-media-tech-page .featured-top .cd-intro .cd-words-wrapper b {
    font-size: 48px; }
  .node--type-media-tech-page .sidelink {
    font-family: "SourceSansPro-Semibold"; }
  .node--type-media-tech-page .cultureHeaderSection .side-title span {
    font-size: 20px !important; }
  .node--type-media-tech-page div.brand-page-cont h2 {
    font-size: 26px !important;
    font-family: "sharpSans-Book" !important; }
  .node--type-media-tech-page .mt-stat-blocks {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: auto;
    border-right: none; }
    .node--type-media-tech-page .mt-stat-blocks .static-title {
      font-family: "SourceSansPro-Bold" !important;
      font-weight: normal !important; }
    .node--type-media-tech-page .mt-stat-blocks:first-child {
      padding-left: 15px; }
    .node--type-media-tech-page .mt-stat-blocks .static-desc {
      padding-bottom: 20px;
      border-bottom: 1px solid #979797; }
      .node--type-media-tech-page .mt-stat-blocks .static-desc:last-child {
        padding-bottom: 0px;
        border-bottom: unset; }
  .node--type-media-tech-page .mt-programs .mt-pgm-block {
    padding: 30px; }
  .node--type-media-tech-page .sec-banner-section .desktop-hide {
    display: block; }
  .node--type-media-tech-page .sec-banner-section .mobile-hide {
    display: none; }
  .node--type-media-tech-page .sec-banner-section .sec-banner-content {
    padding: 30px; }
    .node--type-media-tech-page .sec-banner-section .sec-banner-content .sec-banner-title p:first-child {
      font-size: 36px;
      line-height: 45px; }
    .node--type-media-tech-page .sec-banner-section .sec-banner-content .sec-banner-title p:last-child {
      font-size: 36px;
      line-height: 45px; }
  .node--type-media-tech-page .mt-programs {
    margin: 0px; }
  .node--type-media-tech-page .cultureHeaderSection .sidelink a {
    color: #0079C0 !important; }
  .node--type-media-tech-page .employee-resource-groups h2 {
    font-size: 24px !important; }
  .node--type-media-tech-page .faqs-main-wrapper .faqs-wrapper h1 {
    font-size: 26px !important;
    color: #009DD6 !important;
    font-family: "sharpSans-Book" !important;
    margin-left: 0px; }
  .node--type-media-tech-page #opportunities {
    margin-top: 0px;
    margin-bottom: 51px; }
    .node--type-media-tech-page #opportunities .opportunity-desc p span {
      font-family: "SourceSansPro-Bold" !important;
      font-weight: normal !important; }
  .node--type-media-tech-page .mt-programs .mt-pgm-block {
    padding: 51px 25px 59px 23px; }
  .node--type-media-tech-page .sec-banner-logo {
    display: block !important;
    position: unset;
    margin-top: 51px; }
  .node--type-media-tech-page .styles_overlay__CLSq- {
    padding: 0px 34px; }
  .path-mediatech-internship-program .brand-page-row {
    margin-top: 0px !important; }
  .path-mediatech-internship-program .slick-slide:focus, .path-mediatech-internship-program .single-slider-img:focus {
    outline: none; }
  .path-mediatech-internship-program .carousel-control-next-icon, .path-mediatech-internship-program .carousel-control-prev-icon {
    display: none !important; }
  .path-mediatech-internship-program .title-border {
    margin-bottom: 30px; }
  .path-mediatech-internship-program .featured_locations_heading h2 {
    margin-bottom: 80px; }
  .path-mediatech-internship-program .internship-cards {
    width: 100% !important;
    display: block !important;
    padding: 0px 15px;
    margin-top: 15px !important; }
    .path-mediatech-internship-program .internship-cards .internship-cards-box {
      margin-right: 0px !important;
      margin-bottom: 15px;
      padding: 55px 10px 30px 20px !important; }
      .path-mediatech-internship-program .internship-cards .internship-cards-box .internships-title p {
        margin-bottom: 90px !important;
        font-size: 34px !important;
        line-height: 36px !important; }
        .path-mediatech-internship-program .internship-cards .internship-cards-box .internships-title p span {
          font-size: 34px !important; }
      .path-mediatech-internship-program .internship-cards .internship-cards-box .internship-duration {
        font-size: 18px;
        line-height: 23px; }
      .path-mediatech-internship-program .internship-cards .internship-cards-box .internships-details {
        font-size: 23px;
        line-height: 23px; }
  .path-mediatech-internship-program .sidelink a {
    color: #0079C0 !important; }
  .path-mediatech-internship-program .side-title {
    margin-top: 29px;
    font-size: 20px !important;
    line-height: 25px !important; }
  .path-mediatech-internship-program .job-container-section {
    padding: 0px !important; }
  .path-mediatech-internship-program .job-container {
    padding: 0px !important; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-job-inner-container {
      padding: 0px 15px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont {
      padding: 0px;
      margin-bottom: 64px; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont:last-child {
        margin-bottom: 0px; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .side-cont-title {
        margin-bottom: 14px !important;
        font-size: 20px !important;
        line-height: 25px !important; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .program-info-list, .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .eligibility-list {
        padding: 0px; }
        .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .program-info-list ul, .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .eligibility-list ul {
          padding: 0px 0px 0px 20px !important; }
          .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .program-info-list ul li, .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .eligibility-list ul li {
            list-style: disc; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer {
      padding: 0px;
      margin-top: 0px !important;
      margin-bottom: 54px; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont {
        padding: 0px 0px; }
        .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont .btn-light {
          padding: 0px 0px; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .side-cont-title {
        margin-bottom: 9px !important; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner div p {
        margin-bottom: 38px !important; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-page-link {
      padding-left: 16px !important;
      padding-top: 10px;
      padding-bottom: 10px; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-page-link a {
        font-family: 'Source Sans Pro', sans-serif !important;
        color: #009DD6 !important;
        font-weight: 600 !important;
        font-style: normal !important; }
  .path-mediatech-internship-program .path-all-locations .featured_locations_heading {
    padding-top: 62px; }
  .path-mediatech-internship-program .slider-outer-banner {
    padding: 54px 0px 84px 0px; }
    .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-title {
      margin-bottom: 54px;
      font-size: 28px;
      line-height: 35px; }
      .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-title span {
        font-size: 28px !important;
        line-height: 35px !important; }
    .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description {
      border-left: unset !important;
      padding: 0px 0px 0px 20px !important;
      background-color: unset; }
      .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-sm-12 {
        padding: 0px !important; }
        .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-sm-12 h2 {
          font-size: 23px !important;
          line-height: 29px !important;
          margin-bottom: 15px !important; }
        .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-sm-12 p {
          font-size: 16px !important;
          line-height: 25px !important;
          margin-bottom: 39px !important;
          padding-top: 0px; }
  .mediatech-associates .mediatech-page-link {
    margin-bottom: 78px !important; }
  .path-mediatech-associate-program #tracks-react-main-wrapper .slick-slide.slick-active.slick-current {
    width: 16% !important; }
  .path-mediatech-associate-program #tracks-react-main-wrapper .slick-slide.slick-active {
    white-space: nowrap; }
  .path-mediatech-associate-program #tracks-react-main-wrapper .slick-slide.slick-cloned:last-child {
    display: none; }
  .path-mediatech-associate-program .internship-cards {
    width: 100% !important;
    display: block !important;
    padding: 0px 15px;
    margin-top: 56px !important; }
    .path-mediatech-associate-program .internship-cards .internship-cards-box {
      margin-right: 0px !important;
      margin-bottom: 27px;
      padding: 70px 17px 30px 20px !important;
      height: 310px !important; }
      .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title {
        margin-top: 34px !important; }
        .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title p {
          font-size: 126px !important;
          font-family: "sharpSans-Semibold" !important;
          font-weight: normal;
          line-height: 14px !important; }
          .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title p span {
            font-size: 29px !important;
            font-family: "sharpSans-Semibold" !important;
            font-weight: normal;
            right: -80px !important;
            top: -14px !important; }
      .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-details {
        line-height: 23px; }
  .path-mediatech-associate-program .job-container-section {
    padding: 0px !important; }
  .path-mediatech-associate-program .job-container {
    padding: 0px !important; }
    .path-mediatech-associate-program .job-container .mediatech-job-main-container {
      margin-top: 54px; }
      .path-mediatech-associate-program .job-container .mediatech-job-main-container .mediatech-job-inner-container {
        padding: 0px 15px; }
      .path-mediatech-associate-program .job-container .mediatech-job-main-container #hear-from-our-alumni .alumni-title {
        margin-top: 64px; }
      .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-qualification-cont {
        padding: 0px;
        margin-bottom: 64px; }
        .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-qualification-cont .side-cont-title {
          margin-bottom: 14px !important;
          font-size: 20px !important;
          line-height: 25px !important; }
        .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-qualification-cont .program-info-list {
          padding: 0px; }
          .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-qualification-cont .program-info-list ul {
            padding: 0px 0px 0px 20px !important; }
            .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-qualification-cont .program-info-list ul li {
              list-style: disc; }
      .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-apply-cointer {
        padding: 0px;
        margin-bottom: 74px !important;
        margin-top: 0px !important; }
        .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-apply-cointer:last-child {
          margin-bottom: 0px !important; }
        .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .side-cont-title {
          margin-bottom: 9px !important; }
        .path-mediatech-associate-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner div p {
          margin-bottom: 29px !important; }
      .path-mediatech-associate-program .job-container .mediatech-job-main-container .mediatech-page-link {
        padding: 10px 0px 10px 16px; }
        .path-mediatech-associate-program .job-container .mediatech-job-main-container .mediatech-page-link a {
          color: #0079C0 !important; }
  .path-mediatech-associate-program .side-title {
    margin-top: 0px;
    font-size: 20px !important;
    line-height: 25px !important;
    font-family: "sharpSans-Semibold" !important;
    padding: 0px 15px !important;
    margin-top: 2px; }
  .path-mediatech-associate-program .sidelink {
    font-weight: normal;
    margin-bottom: 20px !important;
    padding: 0px 15px !important; }
    .path-mediatech-associate-program .sidelink a {
      color: #0079C0 !important; }
  .path-mediatech-associate-program .title-border {
    margin-bottom: 22px !important;
    margin-top: 10px; }
  .path-mediatech-associate-program .brand-page-cont h2 {
    padding-top: 36px !important; }
  .path-mediatech-associate-program .brand-page-cont p {
    margin-bottom: 0px;
    padding-bottom: 0px !important; }
  .path-mediatech-associate-program .brand-page-row {
    margin-top: 2px !important; }
  .path-mediatech-associate-program .tracks-section-wrapper {
    width: 100%;
    padding: 0px 15px; }
    .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container {
      margin-top: 47px; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-title {
        padding-bottom: 27px; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-description, .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-description {
        margin-bottom: 24px; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .opened-track-content, .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .opened-track-content {
        padding-top: 8px; }
        .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .opened-track-content .track-tab-container .mobile-track-slider-container .slick-slider .slick-list .slick-track, .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .opened-track-content .track-tab-container .mobile-track-slider-container .slick-slider .slick-list .slick-track {
          height: 40px; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-tab-container div, .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-tab-container div {
        margin-right: 0px;
        width: 100%; }
        .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-tab-container div .mobile-track-slide, .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-tab-container div .mobile-track-slide {
          width: 100%; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-tab-container .mobile-track-slider-container .slick-list, .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-tab-container .mobile-track-slider-container .slick-list {
        margin-left: 0px; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .explore-tracks {
        font-size: 26px;
        letter-spacing: 0.29px;
        line-height: 34px;
        font-family: "sharpSans-Medium";
        text-align: left;
        height: auto; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .node--type-media-tech-page .text-heading-1 {
    font-size: 48px; }
  .node--type-media-tech-page .path-mediatech-associate-program .carousel-control-next-icon, .node--type-media-tech-page .path-mediatech-associate-program .slick-next {
    display: block !important; }
  .node--type-media-tech-page .path-mediatech-associate-program .carousel-control-prev-icon, .node--type-media-tech-page .path-mediatech-associate-program .slick-prev {
    display: block !important; }
  .node--type-media-tech-page .slick-next {
    right: 0px !important; }
  .node--type-media-tech-page .slick-prev {
    left: 0px !important; }
  .node--type-media-tech-page #faqs-react-main-wrapper {
    padding: 0px 20px; }
    .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper h1 {
      width: 100%; }
    .node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .faqs-wrapper .accordion-container {
      padding-right: 0px !important; }
  .node--type-media-tech-page .sec-banner-logo {
    display: none; }
  .node--type-media-tech-page .text-heading-1 {
    font-size: 70px; }
  .node--type-media-tech-page .featured-top .cd-intro .cd-words-wrapper b {
    font-size: 70px; }
  .node--type-media-tech-page .employee-resource-groups h2 {
    font-size: 34px !important; }
  .node--type-media-tech-page .ops-row .mt-stat-blocks {
    max-width: 33%;
    padding-left: 23px !important;
    border-right: 1px solid #979797; }
    .node--type-media-tech-page .ops-row .mt-stat-blocks:first-child {
      padding-left: 0px !important; }
    .node--type-media-tech-page .ops-row .mt-stat-blocks:last-child {
      border-right: unset; }
    .node--type-media-tech-page .ops-row .mt-stat-blocks .static-desc {
      border-bottom: unset; }
  .node--type-media-tech-page .faqs-main-wrapper .faqs-wrapper h1:first-child {
    color: #009DD6;
    font-family: "sharpSans-Book" !important;
    margin-left: 0px; }
  .node--type-media-tech-page .cultureHeaderSection .side-title span {
    font-size: 20px !important;
    line-height: 25px; }
  .node--type-media-tech-page div.brand-page-cont h2 {
    margin-top: 69px; }
  .node--type-media-tech-page .sec-banner-logo {
    position: absolute;
    bottom: 36px;
    right: 32px; }
  .node--type-media-tech-page .sec-banner-section .desktop-hide {
    display: block; }
  .node--type-media-tech-page .sec-banner-section .mobile-hide {
    display: none; }
  .node--type-media-tech-page .sec-banner-section .sec-banner-content {
    padding-bottom: 68px; }
    .node--type-media-tech-page .sec-banner-section .sec-banner-content .sec-banner-desc {
      max-width: 65%; }
  .node--type-media-tech-page .featured-top .field--name-field-banner-content-para .field--name-field-banner-info-text {
    width: 82%; }
  .node--type-media-tech-page .intern-instagram-main-wrapper .insta-image-container {
    height: 560px !important; }
    .node--type-media-tech-page .intern-instagram-main-wrapper .insta-image-container .insta-image-mobile {
      height: 100% !important; }
  .path-mediatech-internship-program .carousel-control-next-icon, .path-mediatech-internship-program .carousel-control-prev-icon {
    top: 50% !important;
    display: none !important; }
  .path-mediatech-internship-program div.brand-page-cont h2 {
    margin-top: 28px !important; }
  .path-mediatech-internship-program .internship-cards {
    display: table;
    margin-top: 52px; }
    .path-mediatech-internship-program .internship-cards .internship-cards-box {
      width: 31%;
      display: inline-block;
      margin-right: 12px !important;
      padding: 41px 10px 20px 25px !important;
      height: 234px !important; }
      .path-mediatech-internship-program .internship-cards .internship-cards-box .internships-title p {
        margin-bottom: 38px !important;
        line-height: 24px !important;
        font-size: 26px !important; }
        .path-mediatech-internship-program .internship-cards .internship-cards-box .internships-title p span {
          line-height: 24px !important;
          font-size: 26px !important; }
  .path-mediatech-internship-program .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding-top: 44px; }
  .path-mediatech-internship-program div.brand-page-cont h2 h2 {
    margin-top: 38px; }
  .path-mediatech-internship-program div.brand-page-cont h2 p {
    margin-top: 0px;
    margin-bottom: 52px; }
  .path-mediatech-internship-program .slider-outer-banner {
    padding: 50px 0px 46px 0px;
    margin-top: 10px; }
    .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-title {
      margin-bottom: 54px;
      font-size: 36px !important;
      line-height: 45px !important;
      padding-left: 30px; }
      .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-title span {
        font-size: 36px !important;
        line-height: 45px !important; }
    .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description {
      border-left: 1px solid #fff !important;
      padding: 0px !important; }
      .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-md-8 {
        padding: 0px;
        padding: 0px 0px 0px 52px !important; }
        .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-md-8 h2 {
          font-size: 23px;
          line-height: 29px;
          margin-bottom: 15px; }
        .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-md-8 p {
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 35px;
          padding-top: 0px; }
  .path-mediatech-internship-program .brand-page-row {
    margin-top: 14px; }
  .path-mediatech-internship-program .title-border {
    margin-bottom: 34px; }
  .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont {
    padding: 0px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont .btn-light {
      padding: 0px; }
  .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-page-link a {
    font-family: 'Source Sans Pro', sans-serif !important;
    color: #009DD6 !important;
    font-style: normal !important;
    font-weight: 600 !important; }
  .path-mediatech-associate-program .carousel-control-next-icon {
    margin-right: 0px !important; }
  .path-mediatech-associate-program .carousel-control-prev-icon {
    margin-left: 0px !important; }
  .path-mediatech-associate-program .internship-cards {
    width: 100% !important;
    margin-top: 39px !important; }
    .path-mediatech-associate-program .internship-cards .internship-cards-box {
      width: 233px;
      display: inline-grid;
      margin-right: 11px !important;
      padding: 43px 12px 26px 12px !important;
      height: 225px !important;
      margin-bottom: 0px !important; }
      .path-mediatech-associate-program .internship-cards .internship-cards-box:last-child {
        margin-right: 0px !important; }
      .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title {
        margin-top: 45px !important; }
        .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title p {
          margin-bottom: 0px !important;
          line-height: 0px !important;
          font-size: 105px !important;
          font-family: "sharpSans-Semibold" !important;
          letter-spacing: 1.16px !important; }
          .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title p span {
            font-size: 24px !important;
            line-height: 31px !important;
            top: 0px !important;
            right: -64px !important;
            font-family: "sharpSans-Semibold" !important; }
      .path-mediatech-associate-program .internship-cards .internship-cards-box .internship-duration {
        display: none; }
      .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-details {
        font-size: 16px !important;
        line-height: 20px !important;
        padding-top: 0px !important; }
        .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-details::before {
          content: '';
          border-bottom: 1px solid #fff;
          width: 96%;
          display: block;
          margin-top: 7px;
          margin-bottom: 4px; }
  .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer {
    margin-bottom: 65px; }
  .path-mediatech-associate-program .mediatech-job-main-container .mediatech-page-link {
    padding-left: 0px;
    margin-bottom: 86px !important; }
    .path-mediatech-associate-program .mediatech-job-main-container .mediatech-page-link a {
      color: #0079C0 !important; }
  .path-mediatech-associate-program .mediatech-job-main-container #hear-from-our-alumni .alumni-title {
    margin-top: 116px; }
  .path-mediatech-associate-program .mediatech-job-main-container #hear-from-our-alumni .testimonial-career-section-cont .testimonial-career-section .ipad-display {
    height: 300px; }
  .path-mediatech-associate-program div.brand-page-cont {
    margin-top: 0px !important; }
    .path-mediatech-associate-program div.brand-page-cont h2 {
      margin-top: 0px !important;
      margin-bottom: 20px;
      padding-top: 34px !important; }
  .path-mediatech-associate-program .title-border {
    margin-bottom: 22px; }
  .path-mediatech-associate-program .side-title {
    margin-top: 17px !important; }
  .path-mediatech-associate-program .tracks-section-wrapper {
    width: 100%;
    padding: 0px 15px; }
    .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container {
      margin-top: 100px; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .explore-tracks {
        margin-bottom: 63px; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-title {
        padding-bottom: 31px; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-tab-container {
        margin-bottom: 58px !important; }
        .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-tab-container div {
          margin-right: 30px !important;
          width: auto !important; }
          .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-tab-container div:last-child {
            margin-right: 0px !important; }
        .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-tab-container .track-tab-title {
          font-size: 20px;
          line-height: 25px;
          padding: 10px 0px !important; }
        .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-tab-container .track-accent {
          margin-right: 0px !important; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-description p {
        margin-bottom: 30px !important; }
      .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-header-title {
        font-family: "sharpSans-Semibold";
        font-size: 20px;
        line-height: 25px;
        margin-top: 0px; } }

.path-mediatech-internship-program .sidelink a {
  color: #686868;
  font-family: "SourceSansPro-Semibold";
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.01px;
  line-height: 23px; }
  .path-mediatech-internship-program .sidelink a:hover {
    text-decoration: none !important;
    color: #009DD6; }

.path-mediatech-internship-program .internship-cards {
  width: 1027px;
  margin: 56px auto 0px;
  display: flex; }
  .path-mediatech-internship-program .internship-cards .internship-cards-box {
    margin-right: 30px;
    padding: 52px 10px 52px 24px;
    height: 311px; }
    .path-mediatech-internship-program .internship-cards .internship-cards-box:last-child {
      margin-right: 0px; }
    .path-mediatech-internship-program .internship-cards .internship-cards-box .internships-title p {
      color: #FFFFFF;
      font-family: "sharpSans-Bold";
      font-size: 34px;
      font-weight: normal;
      letter-spacing: 0.29px;
      line-height: 34px;
      margin-bottom: 100px; }
      .path-mediatech-internship-program .internship-cards .internship-cards-box .internships-title p span {
        font-family: "sharpSans-Book";
        font-size: 34px; }
    .path-mediatech-internship-program .internship-cards .internship-cards-box .internship-duration {
      color: #FFFFFF;
      font-family: "SourceSansPro-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 22px; }
      .path-mediatech-internship-program .internship-cards .internship-cards-box .internship-duration::after {
        content: '';
        border-bottom: 1px solid #fff;
        width: 88%;
        display: block;
        margin-top: 7px;
        margin-bottom: 4px; }
    .path-mediatech-internship-program .internship-cards .internship-cards-box .internships-details {
      color: #FFFFFF;
      font-family: "SourceSansPro-Regular";
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px; }

.path-mediatech-internship-program .slider-outer-banner {
  padding: 45px 0px 45px; }
  .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description {
    border-left: 1px solid white;
    padding: 0px; }
    .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-lg-6 {
      padding: 0px 0px 0px 82px; }
      .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-lg-6 h2 {
        font-family: "sharpSans-Semibold";
        color: #FFFFFF;
        font-size: 23px;
        font-weight: normal;
        letter-spacing: 0.25px;
        line-height: 29px;
        margin-bottom: 20px; }
      .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-description .col-lg-6 p {
        color: #FFFFFF;
        font-family: "SourceSansPro-Regular";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 25px;
        border-left: none !important;
        padding-left: 0px; }
  .path-mediatech-internship-program .slider-outer-banner .single-slider-main .slider-title span {
    font-family: "sharpSans-Semibold";
    color: #FFFFFF;
    font-size: 36px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 45px;
    text-align: center; }

.path-mediatech-internship-program .job-container {
  margin-top: 50px; }
  .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .side-cont-title {
    color: #2A2A2A;
    font-family: "sharpSans-Semibold";
    font-size: 23px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 29px;
    margin-bottom: 25px; }
  .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .program-info-list ul, .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .eligibility-list ul {
    padding: 0px 0px 0px 20px;
    margin: 0px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .program-info-list ul li, .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-qualification-cont .eligibility-list ul li {
      list-style: disc;
      color: #2A2A2A;
      font-family: "SourceSansPro-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 30px; }
  .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer {
    margin-top: 49px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .side-cont-title {
      margin-bottom: 25px;
      color: #2A2A2A;
      font-family: "sharpSans-Semibold";
      font-size: 23px;
      font-weight: normal;
      letter-spacing: 0.25px;
      line-height: 29px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner div p {
      color: #2A2A2A;
      font-family: "SourceSansPro-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 29px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont {
      color: #FFFFFF;
      font-family: "SourceSansPro-Semibold";
      font-size: 17px;
      letter-spacing: 0.52px;
      line-height: 22px;
      height: 45px;
      width: 150px !important; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont:focus {
        box-shadow: none !important; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont a {
        padding: 0px 0px; }
        .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont a:focus {
          box-shadow: none !important; }
        .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont a:hover {
          background-color: unset;
          border-color: #fff; }
        .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont a .btn-light:focus {
          box-shadow: none !important; }
        .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont a .btn-light:hover {
          background: #0079C0 !important; }
        .path-mediatech-internship-program .job-container .mediatech-job-main-container .job-apply-cointer .jobapply-inner .apply-button-cont a .btn-light.active {
          background: #009DD6; }
  .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-page-link {
    margin-top: 30px;
    padding-left: 15px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-page-link a {
      color: #2A2A2A;
      font-family: "SourceSansPro-Semibold";
      font-size: 20px;
      letter-spacing: 0.63px;
      line-height: 26px;
      font-weight: normal;
      font-style: normal; }
      .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-page-link a:hover {
        text-decoration: none !important;
        color: #009DD6; }

.path-mediatech-internship-program div.brand-page-cont h2 {
  font-family: "sharpSans-Medium"; }

.path-mediatech-internship-program .brand-page-row {
  margin-top: 28px; }
  .path-mediatech-internship-program .brand-page-row .side-title {
    font-family: "sharpSans-Semibold";
    color: #2A2A2A;
    font-size: 23px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 29px; }

.path-mediatech-internship-program .featured-top {
  margin-top: 75px !important; }

/* Iphone X Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .path-mediatech-internship-program .internship-cards {
    width: 100%;
    display: block;
    padding-left: 20px; }
    .path-mediatech-internship-program .internship-cards .internship-cards-box {
      margin-right: 15px; }
  .path-mediatech-internship-program .job-container {
    padding: 0px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont:first-child {
      margin-bottom: 50px; }
    .path-mediatech-internship-program .job-container .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer {
      padding: 0px; }
  .path-mediatech-associate-program .internship-cards {
    width: 100% !important;
    display: block !important;
    padding-left: 20px; }
    .path-mediatech-associate-program .internship-cards .internship-cards-box {
      margin-right: 30px !important; }
  .path-mediatech-associate-program .job-container {
    padding: 0px !important; }
    .path-mediatech-associate-program .job-container .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont {
      padding: 0px 10px !important; }
      .path-mediatech-associate-program .job-container .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont:first-child {
        margin-bottom: 50px; }
    .path-mediatech-associate-program .job-container .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer {
      padding: 0px 10px !important; } }

.mediatech-associates .internship-cards-row {
  margin-left: 0px;
  margin-right: 0px; }
  .mediatech-associates .internship-cards-row .internship-cards {
    width: 1027px;
    margin: 56px auto 0px;
    display: flex;
    color: white; }
    .mediatech-associates .internship-cards-row .internship-cards .internship-cards-box {
      margin-right: 30px;
      padding: 60px 16px 60px 16px;
      height: 311px;
      flex: 0 0 31.33333%; }
      .mediatech-associates .internship-cards-row .internship-cards .internship-cards-box:last-child {
        margin-right: 0px; }
      .mediatech-associates .internship-cards-row .internship-cards .internship-cards-box .internships-title p {
        color: #FFFFFF;
        font-family: "sharpSans-Bold";
        font-size: 145px;
        font-weight: normal;
        letter-spacing: 0.29px;
        margin-bottom: 0; }
        .mediatech-associates .internship-cards-row .internship-cards .internship-cards-box .internships-title p span {
          font-family: "sharpSans-Book";
          font-size: 34px; }
      .mediatech-associates .internship-cards-row .internship-cards .internship-cards-box .internship-duration {
        color: #FFFFFF;
        font-family: "SourceSansPro-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 22px; }
        .mediatech-associates .internship-cards-row .internship-cards .internship-cards-box .internship-duration::after {
          content: '';
          border-bottom: 1px solid #fff;
          width: 88%;
          display: block;
          margin-top: 7px;
          margin-bottom: 4px; }
      .mediatech-associates .internship-cards-row .internship-cards .internship-cards-box .internships-details {
        color: #FFFFFF;
        font-family: "SourceSansPro-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        padding-top: 8px; }

.mediatech-associates #opportunities {
  text-align: center; }

.mediatech-associates .mediatech-page-link {
  margin-bottom: 167px;
  margin-top: 0px; }

.mediatech-associates .brand-page-cont p {
  margin-bottom: 0px;
  padding-bottom: 0px !important; }

.path-mediatech-associate-program .featured-top {
  margin-top: 75px !important; }

.path-mediatech-associate-program .mediatech-job-main-container {
  text-align: left;
  margin-top: 94px; }
  .path-mediatech-associate-program .mediatech-job-main-container ul {
    padding-left: 3px; }
  .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont .side-cont-title {
    color: #2A2A2A;
    font-family: "sharpSans-Semibold";
    font-size: 23px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 29px;
    margin-bottom: 25px; }
  .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont .program-info-list p, .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont .eligibility-list p {
    color: #2A2A2A;
    font-family: "SourceSansPro-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 10px; }
  .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont .program-info-list ul, .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont .eligibility-list ul {
    padding: 0px 0px 0px 20px;
    margin: 0px; }
    .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont .program-info-list ul li, .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-qualification-cont .eligibility-list ul li {
      list-style: disc;
      color: #2A2A2A;
      font-family: "SourceSansPro-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 30px; }
  .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer {
    margin-top: 49px; }
    .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner .side-cont-title {
      margin-bottom: 25px;
      color: #2A2A2A;
      font-family: "sharpSans-Semibold";
      font-size: 23px;
      font-weight: normal;
      letter-spacing: 0.25px;
      line-height: 29px; }
    .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner div p {
      color: #2A2A2A;
      font-family: "SourceSansPro-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 29px; }
    .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner .apply-button-cont {
      color: #FFFFFF;
      font-family: "SourceSansPro-Semibold";
      font-size: 17px;
      letter-spacing: 0.52px;
      line-height: 22px;
      height: 45px;
      width: 150px !important; }
      .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner .apply-button-cont .fas {
        position: relative;
        top: 1px;
        left: 2px; }
      .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner .apply-button-cont a {
        padding: 0px; }
        .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner .apply-button-cont a .btn-light.active {
          background: #009DD6;
          border: #009DD6; }
        .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner .apply-button-cont a .btn-light:hover {
          background: #0079C0 !important; }
        .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner .apply-button-cont a .btn-light:focus {
          box-shadow: none; }
        .path-mediatech-associate-program .mediatech-job-main-container .mediatech-job-inner-container .job-apply-cointer .jobapply-inner .apply-button-cont a .btn-light .fas {
          position: relative;
          top: 1px;
          left: 2px; }
  .path-mediatech-associate-program .mediatech-job-main-container .mediatech-page-link {
    padding-left: 15px; }
    .path-mediatech-associate-program .mediatech-job-main-container .mediatech-page-link a {
      color: #2A2A2A;
      font-family: "SourceSansPro-Semibold";
      font-size: 20px;
      letter-spacing: 0.63px;
      line-height: 26px;
      font-weight: normal;
      font-style: normal; }
      .path-mediatech-associate-program .mediatech-job-main-container .mediatech-page-link a:hover {
        text-decoration: none !important;
        color: #009DD6; }
  .path-mediatech-associate-program .mediatech-job-main-container #hear-from-our-alumni .alumni-title {
    text-align: center;
    font-family: "sharpSans-Medium";
    font-size: 40px;
    line-height: 50px;
    color: #2A2A2A;
    letter-spacing: 0.44px;
    font-weight: 500;
    margin-top: 100px;
    margin-bottom: 0px; }
  .path-mediatech-associate-program .mediatech-job-main-container #hear-from-our-alumni .testimonial-career-section-cont .testimonial-career-section {
    margin-top: 60px; }
    .path-mediatech-associate-program .mediatech-job-main-container #hear-from-our-alumni .testimonial-career-section-cont .testimonial-career-section .desktop-display {
      height: 300px; }

.path-mediatech-associate-program .cultureHeaderSection .careerSideHeader .title-border {
  margin-bottom: 31px; }

.path-mediatech-associate-program .cultureHeaderSection .brand-page-cont h2 {
  padding-bottom: 0px; }

.path-mediatech-associate-program .brand-page-row {
  margin-top: 28px; }
  .path-mediatech-associate-program .brand-page-row .side-title {
    font-family: "sharpSans-Semibold";
    color: #2A2A2A;
    font-size: 23px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 29px; }

.path-mediatech-associate-program .internship-cards {
  width: 1027px;
  margin: 40px auto 0px;
  display: flex;
  color: white; }
  .path-mediatech-associate-program .internship-cards .internship-cards-box {
    margin-right: 30px;
    padding: 60px 16px 60px 16px;
    height: 311px; }
    .path-mediatech-associate-program .internship-cards .internship-cards-box:last-child {
      margin-right: 0px; }
    .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title {
      margin-top: 70px; }
      .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title p {
        color: #FFFFFF;
        font-family: "sharpSans-Semibold";
        font-size: 145px;
        font-weight: normal;
        letter-spacing: 0.29px;
        margin-bottom: 0;
        line-height: 10px; }
        .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-title p span {
          font-family: "sharpSans-Semibold";
          font-size: 34px;
          line-height: 34px;
          font-weight: normal;
          position: relative;
          top: -10px;
          right: -92px; }
    .path-mediatech-associate-program .internship-cards .internship-cards-box .internship-duration {
      color: #FFFFFF;
      font-family: "SourceSansPro-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 22px; }
      .path-mediatech-associate-program .internship-cards .internship-cards-box .internship-duration::after {
        content: '';
        border-bottom: 1px solid #fff;
        width: 96%;
        display: block;
        margin-top: 7px;
        margin-bottom: 4px; }
    .path-mediatech-associate-program .internship-cards .internship-cards-box .internships-details {
      color: #FFFFFF;
      font-family: "SourceSansPro-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 23px;
      padding-top: 8px; }

.path-mediatech-associate-program .sidelink a {
  color: #686868;
  font-family: "SourceSansPro-Semibold";
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.01px;
  line-height: 23px; }
  .path-mediatech-associate-program .sidelink a:hover {
    text-decoration: none !important;
    color: #009DD6; }

.tracks-section-wrapper {
  width: 1027px;
  margin: 0 auto; }
  @media (max-width: 767.98px) {
    .tracks-section-wrapper {
      width: 92vw; } }
  @media (max-width: 991.98px) {
    .tracks-section-wrapper {
      width: 86vw; } }
  .tracks-section-wrapper .all-tracks-container {
    margin-top: 109px; }
    .tracks-section-wrapper .all-tracks-container .explore-tracks {
      height: 50px;
      color: #2A2A2A;
      font-family: "sharpSans-Medium";
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0.44px;
      line-height: 50px;
      text-align: center;
      margin-bottom: 22px; }
      @media (max-width: 767.98px) {
        .tracks-section-wrapper .all-tracks-container .explore-tracks {
          height: 120px; } }
    .tracks-section-wrapper .all-tracks-container .track-container {
      margin-bottom: 34px;
      box-sizing: border-box;
      border-bottom: 1.5px solid #A7A9AC;
      padding-bottom: 0px; }
      .tracks-section-wrapper .all-tracks-container .track-container:last-child {
        margin-bottom: 0px; }
      .tracks-section-wrapper .all-tracks-container .track-container .track-title {
        font-size: 18px;
        color: #2A2A2A;
        font-family: "sharpSans-Medium";
        max-width: 1240px;
        text-align: left;
        padding: 0px 0px 30px 0px;
        background: url("../images/resources/down-arrow.png") no-repeat scroll;
        background-size: 20px 20px;
        background-position: 99.7% 0;
        margin-bottom: 0px;
        font-family: "SourceSansPro-Regular";
        line-height: 23px; }
        .tracks-section-wrapper .all-tracks-container .track-container .track-title:hover {
          background: url("../images/resources/blue-down-arrow.png") no-repeat scroll;
          background-size: 19px 11px;
          background-position: 99.7% 5px;
          cursor: pointer;
          color: #009DD6; }
        .tracks-section-wrapper .all-tracks-container .track-container .track-title.true {
          background: url("../images/resources/blue-up-arrow.png") no-repeat scroll;
          background-size: 19px 11px;
          background-position: 99.7% 0px;
          color: #009DD6; }
      .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-description {
        font-size: 17px;
        margin: 24px 0 34px;
        font-family: "sharpSans-Medium"; }
      .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-container {
        display: flex; }
        .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-container .track-tab-title {
          padding: 10px 0px;
          color: #2A2A2A;
          font-family: "SourceSansPro-Semibold";
          font-size: 18px;
          font-weight: 500;
          line-height: 23px;
          color: #686868;
          cursor: pointer; }
          .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-container .track-tab-title.selected {
            color: #2A2A2A; }
          .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-container .track-tab-title:first-child {
            padding-left: 0; }
        .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-container .track-accent {
          height: 5px !important;
          width: 50px !important;
          margin-top: 8px;
          background: #009DD6;
          margin-right: 0px !important; }
        .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-container .mobile-track-slider-container {
          display: block;
          width: 98vw;
          margin: 0 auto; }
        .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-container .mobile-track-tab-title {
          font-family: "SourceSansPro-Semibold";
          font-size: 18px;
          font-weight: 500; }
      .tracks-section-wrapper .all-tracks-container .track-container .opened-track-content .track-tab-header-title {
        margin-top: 30px;
        margin-bottom: 20px;
        font-family: "SourceSansPro-Semibold";
        font-size: 18px; }
      .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-6, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-7, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-8, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content {
        padding: 0px; }
        .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-description, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-6 .track-description, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-7 .track-description, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-8 .track-description, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-description {
          font-family: "SourceSansPro-Regular";
          font-size: 18px;
          line-height: 30px;
          color: #2A2A2A;
          margin-top: 2px;
          margin-bottom: 32px; }
        .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-tab-container, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-6 .track-tab-container, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-7 .track-tab-container, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-8 .track-tab-container, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-tab-container {
          margin-bottom: 30px; }
          .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-tab-container div, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-6 .track-tab-container div, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-7 .track-tab-container div, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-8 .track-tab-container div, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-tab-container div {
            margin-right: 100px;
            width: auto; }
            .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-tab-container div:last-child, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-6 .track-tab-container div:last-child, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-7 .track-tab-container div:last-child, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-8 .track-tab-container div:last-child, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-tab-container div:last-child {
              margin-right: 0px !important; }
          .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-5 .track-tab-container .track-tab-title, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-6 .track-tab-container .track-tab-title, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-7 .track-tab-container .track-tab-title, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .MuiCardContent-root-8 .track-tab-container .track-tab-title, .tracks-section-wrapper .all-tracks-container .track-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .opened-track-content .track-tab-container .track-tab-title {
            font-weight: normal; }

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .node--type-media-tech-page .featured-top .field--name-field-banner-info-text h2 {
    font-size: 43px; } }

@media only screen and (max-width: 600px) {
  .node--type-page-program.node--type-media-tech-page .erg_heading .employee-resource-groups h2 {
    margin-top: 40px; } }

.path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container {
  border-bottom: none !important;
  margin-bottom: 25px !important; }
  .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .accordion__button[aria-expanded='false'] h3.track-title {
    border-bottom: 1.5px solid #A7A9AC !important; }
  .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .accordion__button[aria-expanded='true'] h3.track-title {
    background: url("../images/resources/blue-up-arrow.png") no-repeat scroll !important;
    margin-bottom: 0px !important;
    padding: 0px 0 10px 0 !important;
    color: #009DD6 !important;
    background-size: 20px 10px !important;
    background-position: 99.7% 12px !important;
    border-bottom: none !important; }
  .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-description {
    font-family: "SourceSansPro-Regular";
    font-size: 18px;
    line-height: 30px;
    color: #2A2A2A;
    margin-top: 2px;
    margin-bottom: 32px;
    border-bottom: 1.5px solid #A7A9AC; }
  .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .track-inner-description {
    margin-bottom: 32px; }

@media (max-width: 991.98px) {
  .path-mediatech-associate-program .tracks-section-wrapper .all-tracks-container .track-container .accordion__button[aria-expanded='true'] h3.track-title {
    background-position: 99.7% 7px !important; } }

.node--type-media-tech-page #faqs-react-main-wrapper .faqs-main-wrapper .accordion__button[aria-expanded='true'] h3 {
  color: #009DD6 !important;
  background: url(../images/resources/blue-up-arrow.png) no-repeat scroll !important;
  background-size: 20px 11px !important;
  -webkit-background-size: 20px 11px !important;
  background-position: 99.7% 31px !important; }

.show {
  display: block; }

.hide {
  display: none; }

.show-for-large {
  display: block; }

.invis {
  opacity: 0;
  overflow: hidden;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently                         supported by Chrome and Opera */ }
  @media (max-width: 767.98px) {
    .invis {
      display: none !important; } }

#internships-react-main-wrapper .intern-possibility .accordion-title.item-title-hidden {
  border-bottom: none !important; }
  #internships-react-main-wrapper .intern-possibility .accordion-title.item-title-hidden .accordion__button[aria-expanded='true'] .fa-chevron-down {
    color: #009dd6 !important; }
    #internships-react-main-wrapper .intern-possibility .accordion-title.item-title-hidden .accordion__button[aria-expanded='true'] .fa-chevron-down:before {
      content: "\f077"; }

#internships-react-main-wrapper .intern-possibility .accordion-title-box {
  border-bottom: 1px solid #eaeaea !important; }

@media only screen and (max-width: 991px) {
  .show-for-large {
    display: none; } }

.react-container .overlay {
  min-height: 350px; }

.overlay_filter {
  background-color: #ccc;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11; }
  .overlay_filter img {
    left: 50%;
    position: absolute;
    top: 50%; }

.path-node .featured-top {
  margin-bottom: 50px;
  /*.field--name-field-banner-info-text {
      .cd-intro .cd-words-wrapper {
        padding-bottom: 0px;
      }
    }*/ }
  .path-node .featured-top.show-only-for-mobile {
    margin-bottom: 0; }

.path-node .carousel-container {
  position: relative; }
  .path-node .carousel-container .slick-slider {
    width: 98%;
    margin: 0 auto; }

.path-node .styles_overlay__CLSq- {
  display: block;
  padding-top: 70px; }

#internships-react-main-wrapper {
  /*Dashboard Component Start*/
  /*Dashboard Component End*/
  /* Locations Component Start*/
  /* Locations Component End*/
  /* Navigation Component Start*/
  /* Navigation Component End*/
  /* How to Apply Component Start*/
  /* How to Apply Component End*/
  /* Intern Possibilities Component Start*/
  /* Intern Possibilities Component End*/
  /* Open positions Component start */
  /* Open positions Component End */
  /* Videos Component Start*/
  /* Videos Component End*/
  /* Safari */ }
  #internships-react-main-wrapper .internships-react-wrapper .intern-instagram-main-wrapper h1.account-name {
    margin-bottom: 30px;
    margin-top: 50px;
    font-family: "sharpSans-Book";
    text-align: center; }
  #internships-react-main-wrapper .internships-react-wrapper h1 {
    margin-bottom: 60px; }
    @media (max-width: 767.98px) {
      #internships-react-main-wrapper .internships-react-wrapper h1 {
        font-size: 26px; } }
  #internships-react-main-wrapper .internships-react-wrapper .main-title {
    cursor: pointer; }
  #internships-react-main-wrapper .internships-react-wrapper .banner-content-wrapper h1 {
    margin-bottom: 0px; }
  #internships-react-main-wrapper .internships-react-wrapper img {
    max-width: 100%; }
  @media (min-width: 992px) {
    #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-title {
      display: none; } }
  #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-title h1 {
    margin-bottom: 10px;
    font-family: "SourceSansPro-Semibold";
    font-size: 20px; }
  @media (max-width: 991.98px) {
    #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-title .title-border {
      margin-bottom: 20px;
      border-bottom: 6px solid #896eb1;
      width: 65px; } }
  #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content {
    margin-top: 45px; }
    #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description {
      color: #2a2a2a; }
      #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h2,
      #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h3,
      #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h4,
      #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h5,
      #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h6 {
        font-size: 40px;
        font-family: "sharpSans-Book"; }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h2,
          #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h3,
          #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h4,
          #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h5,
          #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description h6 {
            font-size: 26px; } }
      #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .intern-dashboard-description p {
        font-size: 18px;
        padding-top: 22px; }
    #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .upcoming-campus-link {
      margin-top: 20px;
      font-size: 18px;
      color: #009dd6; }
      #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .intern-dashboard-content .upcoming-campus-link a {
        font-size: 20px;
        font-family: "sharpSans-Bold";
        color: #009dd6;
        text-decoration: none;
        padding-right: 10px; }
  #internships-react-main-wrapper .intern-locations-main-wrapper {
    padding-top: 50px; }
    @media (max-width: 767.98px) {
      #internships-react-main-wrapper .intern-locations-main-wrapper {
        padding-left: 0;
        padding-right: 0; } }
    @media (max-width: 991.98px) {
      #internships-react-main-wrapper .intern-locations-main-wrapper {
        padding: 0 30px; } }
    @media (max-width: 991.98px) {
      #internships-react-main-wrapper .intern-locations-main-wrapper {
        padding: 0 30px; } }
    @media (max-width: 767.98px) {
      #internships-react-main-wrapper .intern-locations-main-wrapper {
        padding-left: 0;
        padding-right: 0; } }
    #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location {
      cursor: pointer;
      position: relative;
      min-height: 236px;
      font-family: "sharpSans-Medium";
      font-size: 22px; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location {
          font-size: 18px;
          font-family: "SourceSansPro-Semibold"; } }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .logo-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center; }
        @media (max-width: 767.98px) {
          #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .logo-wrapper {
            max-width: 90%; } }
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .logo-wrapper .title {
          padding-top: 10px;
          padding-bottom: 10px; }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .logo-wrapper img {
            margin: 0 auto; } }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .hover-logo {
        display: none; }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .logo {
        display: block; }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .location-border {
        display: none;
        width: 40px;
        margin: 0 auto; }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .location-no-border {
        display: block;
        border: 3px solid #ffffff; }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location.current .hover-logo {
        display: block; }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location.current .logo {
        display: none; }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location.current .location-border {
        display: block;
        border-bottom-width: 6px;
        border-bottom-style: solid; }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location.current .location-no-border {
        display: none; }
      @media (min-width: 768px) {
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location:hover .hover-logo {
          display: block; }
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location:hover .logo {
          display: none; }
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location:hover .location-border {
          display: block;
          border-width: 3px;
          border-style: solid; }
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location:hover .location-no-border {
          display: none; } }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location img {
        max-width: 200px; }
      @media (min-width: 768px) {
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location:hover .hover-logo {
          display: block; }
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location:hover .logo {
          display: none; }
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location:hover .location-border {
          display: block;
          border-bottom-width: 6px;
          border-bottom-style: solid; }
        #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location:hover .location-no-border {
          display: none; } }
      #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location img {
        max-width: 200px; }
  #internships-react-main-wrapper .intern-navigation-main-wrapper {
    margin-top: 50px;
    background: #eeeeee; }
    #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .row {
      margin: 0px; }
    #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation {
      margin: 60px 0; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation {
          margin: 0px 0px 40px;
          padding: 0px; } }
      #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .location {
        margin-bottom: 60px;
        line-height: 33px; }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .location {
            margin: 40px 0; } }
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .location .name {
          font-size: 23px;
          font-family: "SourceSansPro-Semibold";
          line-height: 50px; }
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .location .location-border {
          border-bottom-width: 6px;
          border-bottom-style: solid;
          width: 75px; }
      #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation {
        margin-bottom: 15px; }
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation a {
          font-size: 20px;
          font-family: "SourceSansPro-Semibold";
          text-decoration: none; }
          @media (min-width: 992px) {
            #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation a {
              color: #2a2a2a !important; } }
          #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation a:hover {
            color: #0079c0 !important; }
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation > div {
          font-size: 20px;
          font-family: "SourceSansPro-Semibold";
          color: #212529; }
          @media (max-width: 767.98px) {
            #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation > div {
              padding: 15px 0; } }
          #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation > div.active {
            padding: 15px 20px;
            background: #dddddd;
            margin-left: -20px;
            margin-right: -20px; }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation {
            margin-bottom: 30px; }
            #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation:last-child {
              margin-bottom: 10px; } }
        @media (max-width: 767.98px) {
          #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .left-navigation .navigations .navigation {
            margin-bottom: 0; } }
    #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation {
      margin: 65px 0; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation {
          margin: 0px;
          padding: 0px; } }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .MuiCardContent-root-5,
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .MuiCardContent-root-8,
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation [class^="MuiCard"],
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation [class^="MuiCollapse"] {
          padding: 0px; } }
      @media (min-width: 992px) {
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content {
          padding: 0 30px;
          border-left: 1px solid #400095; } }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content {
          margin: 0px;
          padding: 0px;
          border-top: 1px solid #a7a9ac; } }
      #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-logo {
        margin-bottom: 39px; }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-logo {
            margin-bottom: 20px;
            margin-top: 35px; } }
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-logo img {
          max-width: 360px; }
          @media (max-width: 767.98px) {
            #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-logo img {
              max-width: 250px; } }
      #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-description {
        font-size: 18px; }
        #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-description p {
          margin-bottom: 0.5rem;
          line-height: 30px; }
          #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-description p strong {
            font-family: "SourceSansPro-Bold";
            font-weight: normal; }
        @media (max-width: 767.98px) {
          #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-description {
            padding-bottom: 40px; } }
  #internships-react-main-wrapper .intern-how-to-apply-main-wrapper {
    margin-top: 50px; }
    @media (max-width: 991.98px) {
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper {
        padding: 0px 20px; } }
    @media (max-width: 767.98px) {
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper {
        margin-top: 0px; } }
    @media (max-width: 991.98px) {
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .MuiCardContent-root-7,
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .MuiCardContent-root-6 {
        padding: 0; } }
    #internships-react-main-wrapper .intern-how-to-apply-main-wrapper h1 {
      text-align: center; }
    #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .banner-images {
      margin: 0px; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .banner-images {
          margin: 0px -20px; } }
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .banner-images .banner-image {
        padding: 0px;
        max-height: initial;
        overflow: hidden; }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .banner-images .banner-image {
            display: none; }
            #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .banner-images .banner-image:first-child {
              display: block; } }
        #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .banner-images .banner-image .imageloader {
          width: 100%;
          height: 100%; }
        #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .banner-images .banner-image img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          object-fit: cover;
          min-height: 100%; }
    #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply {
      margin-top: 80px;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 30px; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply {
          margin-top: 60px;
          border-bottom: none;
          padding-bottom: 0px; }
          #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply .how-to-apply-desktop {
            display: none; } }
      @media (max-width: 767.98px) {
        #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply {
          margin-top: 0px; } }
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply .MuiCardContent-root-5 {
        padding: 0px !important; }
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply h1 {
        margin-bottom: 30px;
        margin-top: 80px;
        font-family: "sharpSans-Book"; }
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply .steps {
        -webkit-column-width: 15em;
        -moz-column-width: 15em;
        column-width: 350px;
        display: block; }
        #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply .steps .step {
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 30px; }
          #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply .steps .step span.name {
            color: #896eb1;
            font-family: "SourceSansPro-Bold"; }
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply .steps > .step {
        break-inside: avoid-column;
        -webkit-column-break-inside: avoid; }
      #internships-react-main-wrapper .intern-how-to-apply-main-wrapper #how_to_apply .container-fluid-lg {
        padding: 0px; }
    #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .how-to-apply-top {
      display: none; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .how-to-apply-top {
          font-family: "sharpSans-Book";
          display: block;
          font-size: 26px;
          margin: 0;
          padding: 30px 0px;
          text-align: left;
          border-top: 1px solid #eaeaea; } }
      @media (max-width: 767.98px) {
        #internships-react-main-wrapper .intern-how-to-apply-main-wrapper .how-to-apply-top {
          border: none; } }
  #internships-react-main-wrapper .intern-possibilities-main-wrapper {
    margin-top: 50px;
    padding: 0 100px; }
    @media (max-width: 991.98px) {
      #internships-react-main-wrapper .intern-possibilities-main-wrapper {
        padding: 0px;
        margin-top: 0px; } }
    #internships-react-main-wrapper .intern-possibilities-main-wrapper h1 {
      text-align: center;
      margin-bottom: 30px;
      font-family: "sharpSans-Book"; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-possibilities-main-wrapper h1 {
          padding: 30px 0;
          margin: 0;
          text-align: left;
          border-bottom: 1px solid #eaeaea; } }
    #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper {
      position: relative; }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .ip-overlay {
        position: absolute;
        top: 50%;
        left: 50%; }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities.overlay-show {
        opacity: 0.1; }
      @media (max-width: 1199.98px) {
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .row {
          margin: 0px; } }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility {
        margin-bottom: 20px;
        overflow: hidden; }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility {
            margin-bottom: 0px;
            padding: 0px; } }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box {
          cursor: pointer;
          height: 174px;
          background: #eeeeee; }
          #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box .title {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 20px;
            font-family: "sharpSans-Semibold";
            padding: 0 30px; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box::after {
          content: "";
          background: #009dd6;
          width: 94%;
          height: 12px;
          position: absolute;
          bottom: 0px;
          -webkit-transform: translateY(6px);
          -o-transform: translateY(6px);
          transform: translateY(6px);
          -webkit-transition-property: transform;
          -webkit-transition-property: -webkit-transform;
          transition-property: -webkit-transform;
          -o-transition-property: -o-transform;
          transition-property: transform;
          transition-property: transform, -webkit-transform, -o-transform;
          -webkit-transition-duration: 0.3s;
          -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          -o-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box:hover:after {
          -webkit-transform: translateY(0);
          -o-transform: translateY(0);
          transform: translateY(0); }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .accordion__body--hidden {
          display: none; }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body {
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 30px; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .title {
          color: #009DD6;
          font-size: 30px;
          margin-top: 20px; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .description {
          font-size: 16px; }
          #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .description .light-blue {
            color: #009DD6; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content h5 {
          color: #009DD6; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .logo1 img,
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body .content .logo2 img {
          float: left; }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .accordion-item-body.accordion__body--hidden {
        border: none; }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .title {
        color: #009dd6;
        font-size: 30px;
        margin-top: 75px;
        padding: 0 75px 0 40px; }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .description {
        font-size: 16px;
        padding: 0 75px 0 40px; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .description .light-blue {
          color: #009DD6; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .description strong {
          font-family: "SourceSansPro-Semibold";
          font-weight: normal; }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content h5 {
        color: #009DD6; }
      @media (min-width: 992px) {
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .logo1, #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .logo2 {
          height: 50%; }
          #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .logo1 .imageloader, #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content .logo2 .imageloader {
            height: 100%; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities-popup .content img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
    #internships-react-main-wrapper .intern-possibilities-main-wrapper .popup-overlay {
      position: relative !important;
      min-height: 780px;
      width: 100%;
      z-index: 99 !important; }
      #internships-react-main-wrapper .intern-possibilities-main-wrapper .popup-overlay .popup-content {
        min-height: 780px;
        padding: 0 !important;
        width: 100% !important;
        background: #eeeeee !important;
        border: none !important;
        top: 0; }
        #internships-react-main-wrapper .intern-possibilities-main-wrapper .popup-overlay .popup-content .modal2 {
          min-height: 780px; }
          #internships-react-main-wrapper .intern-possibilities-main-wrapper .popup-overlay .popup-content .modal2 .content {
            min-height: 780px; }
          #internships-react-main-wrapper .intern-possibilities-main-wrapper .popup-overlay .popup-content .modal2 .close {
            top: 60px;
            right: 52px;
            background: unset; }
        @media (min-width: 992px) {
          #internships-react-main-wrapper .intern-possibilities-main-wrapper .popup-overlay .popup-content img {
            width: 100%;
            height: auto;
            min-height: 390px;
            object-fit: cover; } }
  #internships-react-main-wrapper .intern-possibility .accordion-title {
    outline: 0; }
    #internships-react-main-wrapper .intern-possibility .accordion-title .fa-chevron-down {
      font-size: 20px;
      position: absolute;
      right: 0;
      top: 15px; }
      #internships-react-main-wrapper .intern-possibility .accordion-title .fa-chevron-down:before {
        content: "\f077"; }
    @media (max-width: 991.98px) {
      #internships-react-main-wrapper .intern-possibility .accordion-title {
        border-bottom: none;
        margin: 0px auto !important;
        padding: 15px 0;
        background-size: 18px 12px;
        background-position: 99.7% 20px; } }
  @media (max-width: 991.98px) {
    #internships-react-main-wrapper .intern-possibility .accordion-title.item-title-hidden:hover {
      border-bottom: 1px solid #eaeaea;
      margin: 0px auto !important;
      padding: 15px 0;
      background-size: 18px 12px;
      background-position: 99.7% 20px; } }
  #internships-react-main-wrapper .intern-possibility .accordion-title.item-title-hidden .fa-chevron-down {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 15px;
    color: inherit !important; }
    #internships-react-main-wrapper .intern-possibility .accordion-title.item-title-hidden .fa-chevron-down:before {
      content: "\f078"; }
  @media (max-width: 991.98px) {
    #internships-react-main-wrapper .intern-possibility .accordion-title.item-title-hidden {
      border-bottom: 1px solid #eaeaea;
      margin: 0px auto !important;
      padding: 15px 0;
      background-size: 18px 12px;
      background-position: 99.7% 20px; } }
  #internships-react-main-wrapper .open-positions-wrapper .main-title,
  #internships-react-main-wrapper .faqs-wrapper .faqs-title,
  #internships-react-main-wrapper .open-possibilities-header,
  #internships-react-main-wrapper .how-to-apply-top {
    border-bottom: 1px solid #eaeaea;
    margin: 0px auto !important;
    padding: 30px 0;
    background: url("../images/resources/down-arrow.png") no-repeat scroll;
    background-size: 20px 20px;
    background-position: 99.7% 45px; }
    #internships-react-main-wrapper .open-positions-wrapper .main-title:hover,
    #internships-react-main-wrapper .faqs-wrapper .faqs-title:hover,
    #internships-react-main-wrapper .open-possibilities-header:hover,
    #internships-react-main-wrapper .how-to-apply-top:hover {
      background: url("../images/resources/Arrow_Purple_Down_WhiteBG.png") no-repeat scroll;
      background-size: 20px 20px;
      background-position: 99.7% 45px; }
    #internships-react-main-wrapper .open-positions-wrapper .main-title.true,
    #internships-react-main-wrapper .faqs-wrapper .faqs-title.true,
    #internships-react-main-wrapper .open-possibilities-header.true,
    #internships-react-main-wrapper .how-to-apply-top.true {
      background: url("../images/resources/Arrow_Purple_Up_WhiteBG.png") no-repeat scroll;
      background-size: 20px 20px;
      background-position: 99.7% 45px; }
    @media (max-width: 991.98px) {
      #internships-react-main-wrapper .open-positions-wrapper .main-title,
      #internships-react-main-wrapper .faqs-wrapper .faqs-title,
      #internships-react-main-wrapper .open-possibilities-header,
      #internships-react-main-wrapper .how-to-apply-top {
        font-size: 26px; } }
  @media (min-width: 992px) {
    #internships-react-main-wrapper .open-possibilities-header,
    #internships-react-main-wrapper .intern-possibility .accordion-title {
      background: none; } }
  @media (max-width: 767.98px) {
    #internships-react-main-wrapper .faqs-wrapper .faqs-title,
    #internships-react-main-wrapper .open-possibilities-header,
    #internships-react-main-wrapper .how-to-apply-top {
      background: none; }
      #internships-react-main-wrapper .faqs-wrapper .faqs-title:hover, #internships-react-main-wrapper .faqs-wrapper .faqs-title.true,
      #internships-react-main-wrapper .open-possibilities-header:hover,
      #internships-react-main-wrapper .open-possibilities-header.true,
      #internships-react-main-wrapper .how-to-apply-top:hover,
      #internships-react-main-wrapper .how-to-apply-top.true {
        background: none; } }
  @media (max-width: 991.98px) {
    #internships-react-main-wrapper [class^="MuiCard"],
    #internships-react-main-wrapper [class^="MuiCollapse"] {
      padding: 0px; } }
  #internships-react-main-wrapper .intern-videos-main-wrapper {
    margin-top: 90px;
    position: relative; }
    @media (max-width: 991.98px) {
      #internships-react-main-wrapper .intern-videos-main-wrapper {
        margin-top: 0px; } }
    #internships-react-main-wrapper .intern-videos-main-wrapper .MuiCardContent-root-5 {
      padding: 0px !important; }
    #internships-react-main-wrapper .intern-videos-main-wrapper .desktop .video-description {
      width: 36%; }
    #internships-react-main-wrapper .intern-videos-main-wrapper .desktop .video-wrapper {
      width: 64%; }
    #internships-react-main-wrapper .intern-videos-main-wrapper .video-description {
      background: #886db0;
      color: #ffffff;
      padding: 90px 0px;
      font-size: 18px; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-videos-main-wrapper .video-description {
          padding: 30px 20px; } }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-description .video-inner-details {
        margin-bottom: 37px; }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-description h2 {
        font-size: 40px;
        margin-bottom: 20px;
        font-family: "sharpSans-Book"; }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-videos-main-wrapper .video-description h2 {
            font-size: 34px;
            max-width: 100%; } }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-description p {
        margin: 0 auto;
        margin-bottom: 20px;
        line-height: 30px;
        font-family: "SourceSansPro-Regular"; }
        @media (min-width: 992px) {
          #internships-react-main-wrapper .intern-videos-main-wrapper .video-description p {
            overflow: hidden;
            max-height: 180px; } }
        @media (max-width: 991.98px) {
          #internships-react-main-wrapper .intern-videos-main-wrapper .video-description p {
            max-width: 100%; } }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-description a,
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-description a:hover {
        text-decoration: none;
        font-family: "SourceSansPro-Bold";
        color: #ffffff; }
    #internships-react-main-wrapper .intern-videos-main-wrapper .video-wrapper {
      padding: 0;
      max-height: 520px;
      overflow: hidden; }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-wrapper .video-thumbnail {
        cursor: pointer; }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-wrapper .imageloader {
        width: 100%;
        height: 100%;
        max-height: initial;
        overflow: hidden; }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-wrapper .video-thumbnail {
        width: 100%;
        height: 100%; }
      #internships-react-main-wrapper .intern-videos-main-wrapper .video-wrapper .spin-loader {
        position: absolute;
        left: 50%;
        top: 50%; }
    #internships-react-main-wrapper .intern-videos-main-wrapper .row {
      margin: 0; }
    #internships-react-main-wrapper .intern-videos-main-wrapper .popup-overlay {
      position: absolute !important; }
      @media (max-width: 991.98px) {
        #internships-react-main-wrapper .intern-videos-main-wrapper .popup-overlay .popup-content {
          width: 85% !important; } }
  #internships-react-main-wrapper .popup-overlay {
    background: rgba(255, 255, 255, 0.6) !important;
    display: block !important; }
    #internships-react-main-wrapper .popup-overlay .popup-content {
      top: 12%; }
    #internships-react-main-wrapper .popup-overlay .modal2 > .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -50px;
      font-size: 40px;
      background: #ffffff;
      /*border-radius: 18px;
      border: 1px solid #cfcece;*/
      z-index: 999999;
      color: #000; }
  #internships-react-main-wrapper .spin-loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  #internships-react-main-wrapper .faqs-main-wrapper .faqs-title {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: left !important;
    cursor: pointer; }
  #internships-react-main-wrapper .faqs-main-wrapper .search-bar-container {
    display: none; }
  #internships-react-main-wrapper .faqs-main-wrapper .accordion-container .accordion-title-box:last-child .item-title-hidden {
    border-bottom: none !important; }

/* Jobs Component Start*/
.open-positions-main-wrapper {
  color: #2a2a2a;
  margin-top: 60px !important;
  padding-top: 60px !important; }
  @media (max-width: 991.98px) {
    .open-positions-main-wrapper {
      padding-top: 40px !important;
      margin-top: 0px !important; } }
  .open-positions-main-wrapper h1 {
    text-align: left;
    font-size: 40px;
    font-family: "sharpSans-Book";
    margin-bottom: 30px !important; }
    @media (min-width: 992px) {
      .open-positions-main-wrapper h1.main-title {
        color: #2a2a2a !important; } }
    @media (min-width: 576px) {
      .open-positions-main-wrapper h1 {
        margin-top: 30px; } }
    @media (max-width: 767.98px) {
      .open-positions-main-wrapper h1 {
        font-size: 26px;
        margin-top: 30px; } }
  @media (max-width: 991.98px) {
    .open-positions-main-wrapper [class^="MuiCard"],
    .open-positions-main-wrapper [class^="MuiCollapse"] {
      padding: 0px; } }
  .open-positions-main-wrapper .open-positions-selected-filters .selected-filters {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    .open-positions-main-wrapper .open-positions-selected-filters .selected-filters:last-child {
      margin-bottom: 23px;
      align-items: center; }
    .open-positions-main-wrapper .open-positions-selected-filters .selected-filters span {
      color: #400095;
      font-size: 16px;
      line-height: 24px; }
    .open-positions-main-wrapper .open-positions-selected-filters .selected-filters i {
      font-size: 18px;
      line-height: 18px; }
  .open-positions-main-wrapper .no-jobs {
    font-family: "SourceSansPro-Bold"; }
  .open-positions-main-wrapper .jobs-search-container {
    display: flex;
    width: 80%;
    margin: 0 auto; }
    @media (max-width: 991.98px) {
      .open-positions-main-wrapper .jobs-search-container {
        margin: 8px auto;
        display: block;
        width: 96%; } }
    @media (max-width: 767.98px) {
      .open-positions-main-wrapper .jobs-search-container {
        margin: 8px auto;
        display: block;
        width: 96%; } }
    .open-positions-main-wrapper .jobs-search-container .predictive-auto-search {
      width: 50%; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .jobs-search-container .predictive-auto-search {
          margin: 0 auto 8px;
          display: block;
          width: 100%;
          border-bottom: 0px; } }
      .open-positions-main-wrapper .jobs-search-container .predictive-auto-search .react-autosuggest__container {
        width: 100%; }
        .open-positions-main-wrapper .jobs-search-container .predictive-auto-search .react-autosuggest__container .react-autosuggest__suggestions-container {
          width: 40%; }
          @media (max-width: 991.98px) {
            .open-positions-main-wrapper .jobs-search-container .predictive-auto-search .react-autosuggest__container .react-autosuggest__suggestions-container {
              width: 96%; } }
    .open-positions-main-wrapper .jobs-search-container .keyword-search {
      width: 49%;
      display: flex;
      border-bottom: 2px solid #a7a9ac; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .jobs-search-container .keyword-search {
          margin: 0 auto;
          display: block;
          width: 100%;
          border-bottom: 0px; } }
      .open-positions-main-wrapper .jobs-search-container .keyword-search img {
        height: 16px;
        width: 16px;
        position: relative;
        top: 18px;
        right: 2%;
        cursor: pointer;
        border-bottom: none; }
    .open-positions-main-wrapper .jobs-search-container .react-autosuggest__container {
      width: 50%;
      border-bottom: 2px solid #a7a9ac; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .jobs-search-container .react-autosuggest__container {
          margin: 0 auto;
          display: block;
          width: 100%; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .jobs-search-container .react-autosuggest__container {
          border: none; } }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .jobs-search-container .react-autosuggest__container .react-autosuggest__input {
          border-width: 0px !important;
          border: none !important; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .jobs-search-container .react-autosuggest__container .react-autosuggest__input {
          border-width: 0px !important;
          border: none !important; } }
    .open-positions-main-wrapper .jobs-search-container .space-between {
      width: 8%;
      border: none; }
  .open-positions-main-wrapper .intern-search-form {
    padding-top: 15px;
    padding-bottom: 20px;
    width: 35%;
    position: relative;
    margin: 0 auto; }
    @media (max-width: 991.98px) {
      .open-positions-main-wrapper .intern-search-form {
        margin: 0 auto;
        display: block;
        width: 100%; } }
    .open-positions-main-wrapper .intern-search-form .react-autosuggest__container {
      width: 100%;
      border-bottom: 2px solid #a7a9ac; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .intern-search-form .react-autosuggest__container {
          margin: 0 auto;
          display: block;
          width: 100%; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .intern-search-form .react-autosuggest__container {
          border: none; } }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .intern-search-form .react-autosuggest__container .react-autosuggest__input {
          border-width: 0px !important;
          border: none !important; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .intern-search-form .react-autosuggest__container .react-autosuggest__input {
          border-width: 0px !important;
          border: none !important; } }
      .open-positions-main-wrapper .intern-search-form .react-autosuggest__container .react-autosuggest__suggestions-container {
        width: 100%; }
    .open-positions-main-wrapper .intern-search-form .intern-jobs-search-container input {
      width: 100%;
      border: none;
      font-size: 20px;
      height: 50px;
      outline: none; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .intern-search-form .intern-jobs-search-container input {
          background-color: #eeeeee;
          outline: none;
          border: none; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .intern-search-form .intern-jobs-search-container input {
          border: none;
          width: 100%; } }
    .open-positions-main-wrapper .intern-search-form .intern-jobs-search-container {
      margin-bottom: 50px;
      display: flex;
      position: relative;
      top: 20px; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .intern-search-form .intern-jobs-search-container {
          background-color: #eeeeee;
          width: 50%;
          margin: 0 auto 50px; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .intern-search-form .intern-jobs-search-container {
          background-color: #eeeeee;
          width: 100%;
          margin: 0 auto 50px; } }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .intern-search-form .intern-jobs-search-container .react-autosuggest__container {
          border-bottom: none; } }
      .open-positions-main-wrapper .intern-search-form .intern-jobs-search-container .fa-arrow-right {
        position: relative;
        top: 20px;
        right: 16px;
        cursor: pointer; }
  .open-positions-main-wrapper .search-form {
    margin: 0 auto;
    position: relative; }
    @media (max-width: 991.98px) {
      .open-positions-main-wrapper .search-form {
        margin: 0 auto;
        display: block;
        max-width: 100%; } }
    .open-positions-main-wrapper .search-form input::placeholder {
      color: #2A2A2A; }
    .open-positions-main-wrapper .search-form input {
      border: none;
      font-size: 20px;
      height: 50px;
      outline: none;
      color: #2A2A2A; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .search-form input {
          background-color: #eeeeee;
          padding: 0 0 0 25px;
          width: 100%;
          outline: none;
          border: none; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .search-form input {
          border: none; } }
    .open-positions-main-wrapper .search-form .input-job-search {
      width: 100%;
      padding: 0 14px 0 40px; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .search-form .input-job-search {
          padding: 0 0px 0 40px;
          width: 100%;
          margin: 8px 0; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .search-form .input-job-search {
          border: none; } }
    .open-positions-main-wrapper .search-form .location-auto-search {
      width: 24vw;
      padding: 0 32px 0 5px; }
    .open-positions-main-wrapper .search-form .location-auto-search::placeholder {
      color: #a7a9ac;
      opacity: 1;
      /* Firefox */ }
    .open-positions-main-wrapper .search-form .input-job-search::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #a7a9ac;
      opacity: 1;
      /* Firefox */ }
    .open-positions-main-wrapper .search-form .input-job-search::-ms-clear {
      display: none; }
    .open-positions-main-wrapper .search-form .fa-search {
      display: block;
      position: absolute;
      left: 10%;
      top: 16px;
      width: 20px;
      height: 20px;
      font-size: 21px;
      cursor: pointer; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .search-form .fa-search {
          top: 17px;
          left: 18px; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .search-form .fa-search {
          top: 17px;
          left: 8px; } }
    .open-positions-main-wrapper .search-form .fa-arrow-right {
      display: block;
      position: absolute;
      right: 10%;
      top: 16px;
      width: 28px;
      height: 18px;
      font-size: 21px;
      cursor: pointer; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .search-form .fa-arrow-right {
          top: 73px;
          right: 2%; } }
    @media (max-width: 991.98px) {
      .open-positions-main-wrapper .search-form .jobs-arrow-right {
        top: 16px; } }
    @media (max-width: 991.98px) {
      .open-positions-main-wrapper .search-form .location-arrow-clear {
        top: 73px !important; } }
    .open-positions-main-wrapper .search-form .custom-plus.cross {
      position: absolute;
      top: 16px;
      right: 9%;
      font-size: 25px;
      background-image: url("../images/svg/Icon_Add.svg");
      transform-style: preserve-3d;
      transition: 0.5s;
      width: 19px;
      height: 19px;
      margin-top: 1px;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg); }
      .open-positions-main-wrapper .search-form .custom-plus.cross.show {
        display: block;
        cursor: pointer; }
      @media (max-width: 1199.98px) {
        .open-positions-main-wrapper .search-form .custom-plus.cross {
          right: 10%; } }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .search-form .custom-plus.cross {
          right: 7%; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .search-form .custom-plus.cross {
          right: 11%; } }
      @media (max-width: 575.98px) {
        .open-positions-main-wrapper .search-form .custom-plus.cross {
          right: 13%; } }
  .open-positions-main-wrapper .open-positions-filters {
    position: relative;
    padding: 30px 0 50px 0; }
    @media (max-width: 767.98px) {
      .open-positions-main-wrapper .open-positions-filters {
        padding: 0px 0px 30px 0px; } }
    @media (max-width: 991.98px) {
      .open-positions-main-wrapper .open-positions-filters .row .filter:first-child {
        display: block;
        padding: 13px !important; } }
    .open-positions-main-wrapper .open-positions-filters .row .filter {
      position: unset;
      padding: 10px; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .open-positions-filters .row .filter {
          padding: 7.5px 15px; } }
      .open-positions-main-wrapper .open-positions-filters .row .filter .selector {
        cursor: pointer;
        border: 2px solid #8E9095;
        padding: 7px 15px 7px 15px;
        border-radius: 10px;
        display: flex;
        width: 100%;
        background-color: transparent;
        /*&.job-selector.highlight {
            .custom-plus {
              background-image: url("../images/svg/Icon_Add_rotate.svg");
              transform-style: preserve-3d;
              transition: 0.5s;
              width: 14px;
              height: 14px;
              background-size: cover;
              background-repeat: no-repeat;
              transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1)
                rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg);
              margin-top: 7px;
            }
          }*/ }
        .open-positions-main-wrapper .open-positions-filters .row .filter .selector .text {
          font-size: 18px;
          color: #6f7680;
          font-family: "SourceSansPro-Semibold"; }
        .open-positions-main-wrapper .open-positions-filters .row .filter .selector .icon {
          margin: 4px 5px auto auto;
          color: #a7a9ac;
          font-size: 22px; }
          .open-positions-main-wrapper .open-positions-filters .row .filter .selector .icon.custom-plus {
            background-image: url("../images/svg/Icon_Add.svg");
            transform-style: preserve-3d;
            transition: 0.5s;
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 7px; }
        .open-positions-main-wrapper .open-positions-filters .row .filter .selector.highlight {
          border-color: #400095; }
          .open-positions-main-wrapper .open-positions-filters .row .filter .selector.highlight .custom-plus {
            background-image: url("../images/svg/Icon_Add_rotate.svg");
            transform-style: preserve-3d;
            transition: 0.5s;
            width: 14px;
            height: 14px;
            background-size: cover;
            background-repeat: no-repeat;
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
            margin-top: 7px; }
          .open-positions-main-wrapper .open-positions-filters .row .filter .selector.highlight .text {
            color: #400095; }
      .open-positions-main-wrapper .open-positions-filters .row .filter .angle-up-wrapper {
        height: 15px;
        width: 30px;
        margin: 0 auto;
        margin-top: 5px;
        z-index: 100; }
        @media (max-width: 991.98px) {
          .open-positions-main-wrapper .open-positions-filters .row .filter .angle-up-wrapper {
            height: auto; } }
        .open-positions-main-wrapper .open-positions-filters .row .filter .angle-up-wrapper .hide {
          display: none; }
      .open-positions-main-wrapper .open-positions-filters .row .filter .angle-up {
        background: url("../images/path.svg") no-repeat;
        width: 30px;
        height: 15px;
        background-size: 30px 17px;
        position: relative;
        z-index: 100;
        display: none; }
        .open-positions-main-wrapper .open-positions-filters .row .filter .angle-up.true {
          display: block; }
    .open-positions-main-wrapper .open-positions-filters .expanded {
      z-index: 9999;
      background: #ffffff;
      left: 0px;
      right: 0px;
      padding: 34px 35px 26px 35px; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .open-positions-filters .expanded {
          margin: 0;
          padding: 34px 10px 0px 10px;
          text-align: center; } }
      .open-positions-main-wrapper .open-positions-filters .expanded .header {
        margin-left: 10px;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 10px;
        margin-bottom: 20px;
        position: relative; }
        .open-positions-main-wrapper .open-positions-filters .expanded .header .text {
          font-size: 18px;
          color: #6f7680;
          font-family: "SourceSansPro-Bold";
          display: inline-block;
          cursor: pointer; }
          .open-positions-main-wrapper .open-positions-filters .expanded .header .text i {
            margin-right: 10px; }
          .open-positions-main-wrapper .open-positions-filters .expanded .header .text.static-text {
            font-family: "SourceSansPro-Regular"; }
        .open-positions-main-wrapper .open-positions-filters .expanded .header .cross {
          background-image: url("../images/svg/Icon_Add.svg");
          transform-style: preserve-3d;
          transition: 0.5s;
          width: 14px;
          height: 14px;
          background-size: cover;
          background-repeat: no-repeat;
          font-size: 22px;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
          color: #a7a9ac;
          cursor: pointer; }
      .open-positions-main-wrapper .open-positions-filters .expanded .name-wrapper label {
        width: 100%; }
      .open-positions-main-wrapper .open-positions-filters .expanded .name-wrapper .name {
        cursor: pointer;
        padding: 10px;
        width: 100%;
        font-family: "SourceSansPro-Semibold"; }
        .open-positions-main-wrapper .open-positions-filters .expanded .name-wrapper .name.selected {
          background: #eaeaea; }
        .open-positions-main-wrapper .open-positions-filters .expanded .name-wrapper .name:hover {
          background: #eeeeee; }
        @media (max-width: 767.98px) {
          .open-positions-main-wrapper .open-positions-filters .expanded .name-wrapper .name {
            font-family: "SourceSansPro-Regular"; } }
      .open-positions-main-wrapper .open-positions-filters .expanded .actions {
        position: fixed;
        bottom: 0;
        display: flex; }
    .open-positions-main-wrapper .open-positions-filters .MuiCollapse-container-1 {
      border: 1px solid rgba(119, 136, 153, 0.5);
      margin-top: -1px; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .open-positions-filters .MuiCollapse-container-1 {
          margin: 0 auto;
          margin-top: -1px;
          width: 50%; } }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .open-positions-filters .MuiCollapse-container-1 {
          margin: 0 auto;
          margin-top: -1px;
          width: 270px; } }
  .open-positions-main-wrapper .open-positions-jobs .filetrs-label {
    padding-bottom: 0; }
    .open-positions-main-wrapper .open-positions-jobs .filetrs-label h4 {
      font-size: 18px;
      font-family: "SourceSansPro-Semibold"; }
    .open-positions-main-wrapper .open-positions-jobs .filetrs-label .columnheader {
      font-family: 'SourceSansPro-Regular';
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 700;
      line-height: 30px; }
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job {
    padding: 20px 0; }
    @media (max-width: 991.98px) {
      .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job {
        margin: 0px; } }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .showing-jobs {
      display: flex;
      flex-direction: column; }
      .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .showing-jobs:nth-child(2n) {
        background: #eeeeee; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .multiple-location-job {
      width: 105%; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job:last-child {
      border-bottom: 1px solid #7E7E7E; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .column {
      font-size: 16px;
      line-height: 30px;
      font-family: "SourceSansPro-Regular";
      font-weight: 600; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-title a {
      color: #400093;
      font-size: 18px;
      line-height: 30px;
      color: #330077; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-title p {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 5px; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .sub-business {
      font-family: "SourceSansPro-Regular"; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .show-locations {
      margin-top: 5px; }
      .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .show-locations .city {
        padding: 6px 0;
        color: #400093; }
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .hidden-job {
    flex: 0;
    max-height: 0;
    opacity: 0;
    transition: all .7s ease; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .hidden-job div {
      max-height: 0 !important;
      transition: all .7s ease; }
      .open-positions-main-wrapper .open-positions-jobs .jobs-display .hidden-job div div {
        max-height: 0 !important;
        transition: all .7s ease; }
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .visible-job {
    opacity: 1;
    padding: 20px 0;
    font-family: "SourceSansPro-Semibold";
    flex-grow: .1;
    flex-shrink: 1;
    transition: all .5s ease-in-out;
    border-bottom: 1px solid #7E7E7E; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .visible-job .show-locations {
      height: auto;
      font-size: 16px;
      line-height: 30px;
      font-family: "SourceSansPro-Regular";
      font-weight: 600; }
      @media (max-width: 767.98px) {
        .open-positions-main-wrapper .open-positions-jobs .jobs-display .visible-job .show-locations {
          min-height: auto;
          padding-left: 15px; } }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .visible-job div {
      /*max-height: 320px !important;*/
      flex-grow: 1;
      color: #400093;
      transition: max-height .5s ease-in-out; }
      .open-positions-main-wrapper .open-positions-jobs .jobs-display .visible-job div div {
        /*max-height: 240px !important;*/
        flex-grow: 1;
        transition: all .5s linear;
        padding: 6px 0; }
  .open-positions-main-wrapper .open-positions-pagination {
    margin-top: 64px;
    display: flex;
    text-align: center;
    flex-direction: column; }
    .open-positions-main-wrapper .open-positions-pagination .pager-items {
      padding: 0;
      text-align: center;
      margin-top: 20px; }
      @media (max-width: 991.98px) {
        .open-positions-main-wrapper .open-positions-pagination .pager-items {
          float: right; } }
      .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item {
        display: inline-block; }
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item span {
          color: #eeeeee;
          line-height: 30px; }
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.active {
          cursor: pointer; }
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.active span {
            color: #400093; }
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix {
          padding: 0;
          font-family: 'SourceSansPro-Regular'; }
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix span {
            color: #454546;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 1px; }
            .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix span.current-page {
              color: #454546; }
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix .pager_of {
            margin: 0 15px; }
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix.mobile {
            display: none; }
            .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix.mobile span.current-page {
              border: 1px solid #8E9095;
              padding: 5px 10px;
              border-radius: 0px;
              color: #454546;
              margin-left: 15px; }
            .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix.mobile > span {
              position: relative;
              top: -1px; }
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix.desktop {
            display: inline-block; }
          @media (max-width: 1199.98px) {
            .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix.mobile {
              display: inline-block; }
            .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item.pagerer-prefix.desktop {
              display: none; } }
      .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous,
      .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next {
        width: 30px;
        height: 30px;
        background-color: #eeeeee; }
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous span,
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next span {
          color: #a7a9ac; }
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous.active,
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next.active {
          cursor: pointer;
          background-color: #400093; }
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous.active span,
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next.active span {
            color: #ffffff; }
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous .far,
        .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next .far {
          font-size: 24px;
          line-height: 30px; }
        @media (max-width: 1199.98px) {
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous,
          .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next {
            display: none; }
            .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous.active,
            .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next.active {
              display: inline-block;
              background-color: #EEEEEE;
              width: inherit; }
              .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous.active span,
              .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next.active span {
                color: #000000; }
              .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous.active > div,
              .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next.active > div {
                background-color: #EEEEEE;
                width: 50px;
                border: 1px solid #8E9095;
                text-align: center; }
                .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous.active > div .far,
                .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next.active > div .far {
                  line-height: 50px; } }
    .open-positions-main-wrapper .open-positions-pagination .pager-items-count .pager-item.pagerer-prefix span.current-page {
      color: #2a2a2a; }
    .open-positions-main-wrapper .open-positions-pagination .pager-items-count .pager-item.pagerer-prefix .pager_of {
      margin: 0 6px;
      color: #2a2a2a; }

.bus-filter-item {
  margin-left: 200px;
  margin-left: 0vw; }
  @media (max-width: 991.98px) {
    .bus-filter-item {
      margin: 0 auto;
      width: 380px !important; }
      .bus-filter-item .MuiCollapse-container-1 {
        width: 370px !important; } }
  @media (max-width: 767.98px) {
    .bus-filter-item {
      margin: 0 auto;
      width: 320px !important; }
      .bus-filter-item .MuiCollapse-container-1 {
        width: 320px !important; } }

/* Jobs Component End*/
/* FAQs Component Start*/
#internships-react-main-wrapper .faqs-main-wrapper .accordion-title h3 {
  color: #7F679F;
  background-image: url("../images/resources/Arrow_Purple_Up_WhiteBG.png"); }

#internships-react-main-wrapper .faqs-main-wrapper .accordion-title .accordion__button[aria-expanded='true'] h3 {
  background: url("../images/resources/Arrow_Purple_Up_WhiteBG.png") no-repeat scroll !important;
  color: #7f679f !important;
  background-size: 20px 20px !important;
  background-position: 99.7% 27px !important; }

#internships-react-main-wrapper .faqs-main-wrapper .accordion-title.item-title-hidden h3 {
  color: #444444;
  background-image: url("../images/resources/down-arrow.png"); }
  #internships-react-main-wrapper .faqs-main-wrapper .accordion-title.item-title-hidden h3:hover {
    color: #7F679F;
    background-image: url("../images/resources/Arrow_Purple_Down_WhiteBG.png"); }

#internships-react-main-wrapper .faqs-main-wrapper .accordion-item-body {
  border-bottom: 2px solid #7F679F; }

.faqs-main-wrapper .faqs-wrapper h1:first-child {
  text-align: center;
  font-family: "sharpSans-Book"; }

.faqs-main-wrapper .faqs-container {
  text-align: center;
  background-color: white;
  height: auto;
  width: 100%;
  max-width: 1085px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently                         supported by Chrome and Opera */
  margin: 0 auto; }

.faqs-main-wrapper .accordion-container {
  display: flex;
  margin-left: 0; }

.faqs-main-wrapper .faqs-title {
  font-size: 40px;
  color: #444;
  margin: 5vh auto 25px;
  font-family: "sharpSans-Book";
  max-width: 1240px;
  text-align: center;
  padding: 0 20px; }

.faqs-main-wrapper .search-bar-container {
  position: sticky;
  position: -webkit-sticky;
  z-index: 10;
  top: 0;
  background: white;
  padding-bottom: 10px; }

.faqs-main-wrapper .search-bar {
  border-bottom: 1.35px solid gray;
  display: flex;
  margin: 0 auto;
  height: 40px;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 610px;
  position: relative; }
  .faqs-main-wrapper .search-bar .fa-arrow-right {
    display: block;
    position: absolute;
    right: 15px;
    top: 16px;
    width: 20px;
    height: 20px;
    font-size: 21px; }

.faqs-main-wrapper .search-bar input {
  width: 100%;
  max-width: 610px;
  height: 44px;
  font-size: 20px;
  outline: none;
  text-align: left;
  border: 0;
  outline: 0;
  background: transparent; }

.faqs-main-wrapper .search-bar input:focus::-webkit-input-placeholder {
  color: transparent; }

.faqs-main-wrapper .submit-button-magnifying-glass {
  align-content: right;
  outline: none;
  width: 40px;
  border: none;
  cursor: pointer;
  position: relative;
  background: url("../images/resources/magnifying-glass.png") no-repeat scroll;
  background-size: 19px 19px;
  background-position-y: 14px;
  background-position-x: 0px;
  padding-right: 18px; }

.faqs-main-wrapper .sub-header-title {
  text-align: left;
  color: #f36a30;
  margin: 40px 0 5px 0px; }
  .faqs-main-wrapper .sub-header-title h3 {
    margin-bottom: 5px;
    font-size: 22px; }

.faqs-main-wrapper .accordion-item-body {
  width: 100%;
  border-bottom: 2px solid #f36a30;
  text-align: left;
  overflow: hidden;
  animation-name: accordion-content;
  animation-duration: 1.5s;
  font-size: 16px;
  padding: 0px 0 20px 0; }
  .faqs-main-wrapper .accordion-item-body p {
    margin: 0 auto; }

.faqs-main-wrapper .accordion__body--hidden {
  height: 0;
  -webkit-transition: height, 1.5s ease;
  -moz-transition: height, 1.5s ease;
  -ms-transition: height, 1.5s ease;
  -o-transition: height, 1.5s ease;
  transition: height, 1.5s ease; }

@keyframes accordion-content {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.faqs-main-wrapper .accordion-body {
  width: 100%;
  margin-left: 0; }

.faqs-main-wrapper .accordion-title {
  color: #f36a30;
  cursor: pointer;
  text-align: left;
  outline: none;
  font-size: 18px; }
  @media (max-width: 767.98px) {
    .faqs-main-wrapper .accordion-title {
      color: #f36a30; } }
  .faqs-main-wrapper .accordion-title h3 {
    font-size: 18px;
    position: relative;
    padding: 25px 0 35px 0;
    background: url("../images/resources/up-arrow.png") no-repeat scroll;
    background-size: 20px 20px;
    background-position: 99.7% 27px;
    margin-bottom: 0px;
    padding-right: 35px; }
    @media (max-width: 991.98px) {
      .faqs-main-wrapper .accordion-title h3 {
        background-position: 99.7% 26px; } }

.faqs-main-wrapper .accordion-title-box {
  transition: all 0.5s ease-in-out;
  width: 100%; }
  .faqs-main-wrapper .accordion-title-box:last-child .item-title-hidden {
    border: none; }

.faqs-main-wrapper .item-title-hidden {
  color: #444;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid rgba(119, 136, 153, 0.5); }
  .faqs-main-wrapper .item-title-hidden h3 {
    padding-right: 40px;
    font-size: 18px;
    position: relative;
    padding: 25px 35px 35px 0;
    background: url("../images/resources/down-arrow.png") no-repeat scroll;
    background-size: 20px 20px;
    background-position: 99.7% 27px; }
    .faqs-main-wrapper .item-title-hidden h3:hover {
      background: url("../images/resources/down-arrow-orange.png") no-repeat scroll;
      background-size: 20px 20px;
      background-position: 99.7% 27px;
      color: #f36a30; }

@media (max-width: 991.98px) {
  .faqs-main-wrapper .accordion-title-box:last-child .item-title-hidden {
    border-bottom: none; } }

@media (max-width: 991.98px) {
  .faqs-main-wrapper .MuiCardContent-root-5 {
    padding: 0px; } }

.faqs-main-wrapper .accordion__body--hidden {
  display: none; }

.faqs-main-wrapper .accordion-title h1 {
  padding: 0 15px 0 0; }

.faqs-main-wrapper .highlight-text-title {
  background-color: yellow; }

.faqs-main-wrapper .down-arrow {
  height: 20px;
  width: 20px;
  position: relative;
  top: 14px; }

.faqs-main-wrapper .title-and-arrow {
  display: flex;
  justify-content: space-between; }
  .faqs-main-wrapper .title-and-arrow:hover img {
    filter: hue-rotate(180deg);
    -webkit-filter: hue-rotate(180deg); }

.faqs-main-wrapper .failed-search {
  border: 2.5px solid #f36a30;
  border-radius: 10px;
  padding: 10px 0;
  width: 60%;
  margin: 30px auto 0; }
  .faqs-main-wrapper .failed-search h2 {
    font-size: 24px; }

.faqs-main-wrapper .return-to-all {
  border: 1.5px solid #f36a30;
  margin: 25px 0 10px;
  height: 45px;
  width: 140px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  background: white; }
  .faqs-main-wrapper .return-to-all:hover {
    animation-name: return-to-all-fade;
    animation-duration: 1s;
    background: peachpuff; }

@keyframes return-to-all-fade {
  from {
    background: white; }
  to {
    background: peachpuff; } }

/* FAQs Component end*/
.text-blue-magenta {
  color: #896eb1; }

/*Instagram*/
.path-frontpage .styles_modal__gNwvD,
.path-internships .styles_modal__gNwvD {
  max-width: 935px !important;
  margin: 58px auto; }
  .path-frontpage .styles_modal__gNwvD .styles_closeButton__20ID4,
  .path-internships .styles_modal__gNwvD .styles_closeButton__20ID4 {
    position: absolute;
    top: -50px;
    right: 0;
    background: url(../images/icons/Close.png) !important;
    height: 20px;
    width: 20px; }
    @media (max-width: 1199.98px) {
      .path-frontpage .styles_modal__gNwvD .styles_closeButton__20ID4,
      .path-internships .styles_modal__gNwvD .styles_closeButton__20ID4 {
        right: 0%; } }
    @media (max-width: 767.98px) {
      .path-frontpage .styles_modal__gNwvD .styles_closeButton__20ID4,
      .path-internships .styles_modal__gNwvD .styles_closeButton__20ID4 {
        top: -30px; } }
    .path-frontpage .styles_modal__gNwvD .styles_closeButton__20ID4 svg,
    .path-internships .styles_modal__gNwvD .styles_closeButton__20ID4 svg {
      display: none; }

.instagram-popup-container {
  display: flex;
  height: 60vh;
  min-height: 300px;
  max-height: 600px;
  margin-top: 120px; }
  @media (max-width: 991.98px) {
    .instagram-popup-container {
      max-height: 400px; } }
  @media (max-width: 767.98px) {
    .instagram-popup-container {
      max-height: 100%;
      flex-flow: column;
      height: auto; } }
  .instagram-popup-container .popup-image {
    height: 100%;
    max-height: 600px;
    max-width: 600px;
    object-fit: cover;
    -webkit-user-select: none;
    /* Chrome/Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+ */ }
    @media (max-width: 991.98px) {
      .instagram-popup-container .popup-image {
        max-height: 400px;
        max-width: 400px; } }
    @media (max-width: 767.98px) {
      .instagram-popup-container .popup-image {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        object-fit: unset; } }
  .instagram-popup-container .next-button-popup {
    float: right;
    position: absolute;
    top: 49%;
    right: -5vw;
    background: url("../images/resources/arrow-point-to-right.png") no-repeat scroll;
    background-size: 20px 30px;
    height: 35px;
    width: 25px;
    cursor: pointer;
    opacity: 0.7; }
    .instagram-popup-container .next-button-popup:hover {
      opacity: 1; }
    @media (max-width: 1199.98px) {
      .instagram-popup-container .next-button-popup {
        right: -3vw; } }
    @media (max-width: 767.98px) {
      .instagram-popup-container .next-button-popup {
        right: 15px;
        position: fixed; } }
  .instagram-popup-container .prev-button-popup {
    float: left;
    position: absolute;
    top: 49%;
    left: -5vw;
    background: url("../images/icons/carousel-left.png") no-repeat scroll;
    background-size: 20px 30px;
    height: 35px;
    width: 25px;
    opacity: 0.7;
    cursor: pointer; }
    .instagram-popup-container .prev-button-popup:hover {
      opacity: 1; }
    @media (max-width: 1199.98px) {
      .instagram-popup-container .prev-button-popup {
        left: -3vw; } }
    @media (max-width: 767.98px) {
      .instagram-popup-container .prev-button-popup {
        left: 15px;
        position: fixed; } }
  .instagram-popup-container .popup-text-container {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    display: block;
    margin-left: 25px;
    margin-right: 25px;
    max-height: 520px;
    word-wrap: break-word; }
    @media (max-width: 1199.98px) {
      .instagram-popup-container .popup-text-container {
        min-width: 200px; } }
    @media (max-width: 767.98px) {
      .instagram-popup-container .popup-text-container {
        max-height: 100%;
        width: auto; } }
    .instagram-popup-container .popup-text-container p {
      width: 100%;
      box-sizing: content-box;
      padding-right: 16px;
      word-wrap: break-word;
      word-break: break-word; }
    .instagram-popup-container .popup-text-container .popup-title-container {
      display: flex;
      margin-top: 15px;
      height: 75px; }
      .instagram-popup-container .popup-text-container .popup-title-container img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer; }
      .instagram-popup-container .popup-text-container .popup-title-container h4 {
        position: relative;
        top: 10px;
        margin-left: 5px;
        cursor: pointer;
        font-size: 18px; }
      .instagram-popup-container .popup-text-container .popup-title-container .insta-check {
        position: relative;
        top: 13px;
        height: 15px;
        width: 15px;
        margin-left: 5px; }
      .instagram-popup-container .popup-text-container .popup-title-container .account-link {
        position: relative;
        top: 10px;
        left: 0;
        margin-left: 5px;
        font-size: 16px; }
        .instagram-popup-container .popup-text-container .popup-title-container .account-link a {
          text-decoration: none; }
      @media (max-width: 767.98px) {
        .instagram-popup-container .popup-text-container .popup-title-container {
          height: auto;
          margin-bottom: 20px; } }
    .instagram-popup-container .popup-text-container .popup-footer {
      position: relative;
      margin-top: auto; }
      @media (max-width: 767.98px) {
        .instagram-popup-container .popup-text-container .popup-footer {
          position: relative;
          bottom: auto;
          padding-bottom: 20px; }
          .instagram-popup-container .popup-text-container .popup-footer span {
            font-size: 14px; } }
      .instagram-popup-container .popup-text-container .popup-footer span {
        padding-right: 20px; }

.styles_modal__gNwvD {
  padding: 0; }

.popup-container-mobile {
  padding-bottom: 10px; }
  .popup-container-mobile .popup-image-mobile-image {
    width: 100%; }
  .popup-container-mobile .popup-text-container-mobile {
    margin-left: 15px;
    margin-right: 15px;
    height: 100%; }
    .popup-container-mobile .popup-text-container-mobile .popup-title-container {
      display: flex;
      margin-top: 15px;
      height: 75px; }
      .popup-container-mobile .popup-text-container-mobile .popup-title-container img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer; }
      .popup-container-mobile .popup-text-container-mobile .popup-title-container h4 {
        position: relative;
        top: 10px;
        margin-left: 5px;
        cursor: pointer;
        font-size: 18px; }
      .popup-container-mobile .popup-text-container-mobile .popup-title-container .insta-check {
        position: relative;
        top: 13px;
        height: 15px;
        width: 15px;
        margin-left: 5px; }
      .popup-container-mobile .popup-text-container-mobile .popup-title-container .account-link {
        position: relative;
        top: 10px;
        left: 5px;
        font-size: 16px; }
        .popup-container-mobile .popup-text-container-mobile .popup-title-container .account-link a {
          text-decoration: none; }
    .popup-container-mobile .popup-text-container-mobile .mobile-popup-title {
      height: auto;
      padding-bottom: 10px; }
    .popup-container-mobile .popup-text-container-mobile .mobile-popup-links {
      padding-bottom: 20px; }

.insta-check {
  position: relative;
  top: 13px;
  height: 15px;
  width: 15px;
  margin-left: 5px; }

.intern-instagram-main-wrapper a {
  text-decoration: none; }

.intern-instagram-main-wrapper .display-body {
  width: 100%;
  text-align: center; }

.intern-instagram-main-wrapper .carousel-container {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px; }
  @media (max-width: 991.98px) {
    .intern-instagram-main-wrapper .carousel-container {
      width: 90%; } }
  @media (max-width: 991.98px) {
    .intern-instagram-main-wrapper .carousel-container .slick-list {
      width: 90% !important; } }

.intern-instagram-main-wrapper .carousel-container-mobile {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin: auto; }
  @media (min-width: 992px) {
    .intern-instagram-main-wrapper .carousel-container-mobile {
      display: none; } }

.intern-instagram-main-wrapper button:focus {
  outline: 0; }

.intern-instagram-main-wrapper .carousel-item-container {
  text-align: center;
  overflow: visible;
  outline: 0; }

.intern-instagram-main-wrapper .insta-image-container {
  position: relative;
  margin: auto auto;
  width: 90%;
  cursor: pointer;
  margin-top: 5%;
  background: #000000; }
  .intern-instagram-main-wrapper .insta-image-container .insta-image {
    position: relative;
    height: 100%;
    width: 100%;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease; }

.intern-instagram-main-wrapper .hearts-and-comments {
  display: flex;
  justify-content: space-around;
  color: white;
  width: 60%;
  position: relative;
  margin: 0 auto;
  bottom: 56%;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  cursor: pointer; }
  .intern-instagram-main-wrapper .hearts-and-comments span {
    padding-right: 8px; }
  .intern-instagram-main-wrapper .hearts-and-comments img {
    height: 25px;
    padding-right: 5px; }

.intern-instagram-main-wrapper .heart-fade-in {
  position: absolute; }

.intern-instagram-main-wrapper .insta-image-container:hover .hearts-and-comments {
  opacity: 1; }

.intern-instagram-main-wrapper .insta-image-container:hover .insta-image {
  opacity: 0.6; }

.intern-instagram-main-wrapper .likes {
  font-size: 20px; }

.intern-instagram-main-wrapper #likes {
  visibility: hidden; }

.intern-instagram-main-wrapper #content:hover > #likes {
  visibility: visible; }

.intern-instagram-main-wrapper .popup-content {
  position: relative;
  right: 25vw;
  left: 25vw;
  top: 40vh;
  height: 300px;
  width: 600px;
  overflow: visible;
  z-index: 6; }

.intern-instagram-main-wrapper .styles_overlay__CLSq- {
  background: rgba(255, 255, 255, 0.7); }

.intern-instagram-main-wrapper .styles_closeIcon__1QwbI {
  cursor: pointer; }

.intern-instagram-main-wrapper .slick-next {
  display: none;
  height: 0px;
  width: 0px; }

.intern-instagram-main-wrapper .slick-prev:before,
.intern-instagram-main-wrapper .slick-next:before {
  font-size: 50px;
  line-height: 2; }

.intern-instagram-main-wrapper .slick-next:before {
  content: "";
  background-image: url("../images/resources/arrow-point-to-right.png");
  background-repeat: no-repeat;
  background-size: 0px 0px; }

.intern-instagram-main-wrapper .next-button,
.intern-instagram-main-wrapper .prev-button {
  border: 1px solid transparent;
  background-color: transparent;
  display: none;
  background-size: 100% 100% !important;
  background-repeat: no-repeat; }

.intern-instagram-main-wrapper:hover .next-button,
.intern-instagram-main-wrapper:hover .prev-button {
  display: block; }

.intern-instagram-main-wrapper .next-button {
  float: right;
  position: absolute;
  background-image: url(../images/icons/carousel-right.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  top: 50%;
  width: 20px;
  height: 20px;
  z-index: 50;
  right: 0px; }
  .intern-instagram-main-wrapper .next-button:hover {
    background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
    -webkit-transform: scale(1.2);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: scale(1.2);
    /* IE 9 */
    transform: scale(1.2);
    background-size: 100% 100%;
    background-repeat: no-repeat; }

.intern-instagram-main-wrapper .prev-button {
  float: left;
  position: absolute;
  background-image: url(../images/icons/carousel-left.png) !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  width: 20px;
  height: 20px;
  top: 50%;
  z-index: 50; }
  .intern-instagram-main-wrapper .prev-button:hover {
    background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
    -webkit-transform: scale(1.2);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: scale(1.2);
    /* IE 9 */
    transform: scale(1.2);
    background-size: 100% 100%;
    background-repeat: no-repeat; }

.intern-instagram-main-wrapper .next-button-popup-mobile {
  float: right;
  position: absolute;
  top: 49%;
  right: -7vw;
  background: url("../images/resources/arrow-point-to-right.png") no-repeat scroll;
  background-size: 20px 30px;
  height: 35px;
  width: 25px;
  cursor: pointer;
  opacity: 0.7; }
  .intern-instagram-main-wrapper .next-button-popup-mobile:hover {
    opacity: 1; }

.intern-instagram-main-wrapper .prev-button-popup-mobile {
  float: right;
  position: absolute;
  top: 49%;
  left: -7vw;
  background: url("../images/resources/arrow-point-to-right.png") no-repeat scroll;
  background-size: 20px 30px;
  height: 35px;
  width: 25px;
  cursor: pointer;
  opacity: 0.7;
  transform: scaleX(-1); }
  .intern-instagram-main-wrapper .prev-button-popup-mobile:hover {
    opacity: 1; }

/*End Instagram*/
/* Find a Job Starts*/
@media (max-width: 767.98px) {
  .path-find-a-job .container-fluid-lg {
    padding: 0px 15px; } }

@media (max-width: 767.98px) {
  .path-find-a-job .container-fluid-lg .external-links .col-12 {
    padding: 0px; } }

@media (max-width: 767.98px) {
  .path-find-a-job .container-fluid-lg .external-links .col-12 .item-wrapper {
    padding: 0px 15px; } }

@media (max-width: 767.98px) {
  .path-find-a-job .container-fluid-lg .external-links .col-12 .item-wrapper .link-item {
    text-align: left; } }

@media (max-width: 767.98px) {
  .path-find-a-job .container-fluid-lg .external-links .col-12 .item-wrapper .link-item .fa-arrow-right {
    display: none; } }

@media (max-width: 991.98px) {
  .path-find-a-job .container-fluid-lg {
    padding: 0px 15px; } }

@media (max-width: 991.98px) {
  .path-find-a-job .container-fluid-lg .external-links .col-12 {
    padding: 0px; } }

@media (max-width: 991.98px) {
  .path-find-a-job .container-fluid-lg .external-links .col-12 .item-wrapper {
    padding: 0px 15px; } }

@media (max-width: 991.98px) {
  .path-find-a-job .container-fluid-lg .external-links .col-12 .item-wrapper .link-item {
    text-align: left; } }

@media (max-width: 991.98px) {
  .path-find-a-job .container-fluid-lg .external-links .col-12 .item-wrapper .link-item .fa-arrow-right {
    display: none; } }

@media (min-width: 992px) {
  .path-find-a-job .job-aoi-listing .aoi-listing > .row, .path-internships .job-aoi-listing .aoi-listing > .row {
    display: flex;
    flex-direction: column;
    max-height: 320px;
    height: 320px; }
    .path-find-a-job .job-aoi-listing .aoi-listing > .row .name-wrapper, .path-internships .job-aoi-listing .aoi-listing > .row .name-wrapper {
      flex: none; }
  .path-find-a-job .job-business-listing .business-listing > .row, .path-internships .job-business-listing .business-listing > .row {
    display: flex;
    flex-direction: column;
    max-height: 320px;
    height: 320px; }
    .path-find-a-job .job-business-listing .business-listing > .row .name-wrapper, .path-internships .job-business-listing .business-listing > .row .name-wrapper {
      flex: none; }
  .path-find-a-job .job-locations-listing .locations-listing > .row, .path-internships .job-locations-listing .locations-listing > .row {
    display: flex;
    flex-direction: column;
    max-height: 280px;
    height: 280px; }
    .path-find-a-job .job-locations-listing .locations-listing > .row .name-wrapper, .path-internships .job-locations-listing .locations-listing > .row .name-wrapper {
      flex: none; } }

.jobs-main-search-react {
  color: #2a2a2a; }
  .jobs-main-search-react .title {
    text-align: center;
    font-size: 40px;
    margin-top: 75px;
    font-family: "sharpSans-Book"; }
    @media (max-width: 991.98px) {
      .jobs-main-search-react .title {
        margin-top: 35px;
        font-size: 26px;
        text-align: left; } }
  .jobs-main-search-react .description {
    font-size: 18px;
    padding: 0 10%;
    margin-top: 30px;
    text-align: center;
    line-height: 30px; }
    @media (max-width: 1199.98px) {
      .jobs-main-search-react .description {
        padding: 0 50px; } }
    @media (max-width: 991.98px) {
      .jobs-main-search-react .description {
        padding: 0px;
        text-align: left; } }
  .jobs-main-search-react .find-job-external-links {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px; }
  .jobs-main-search-react .external-links {
    padding: 0 15%; }
    @media (max-width: 1199.98px) {
      .jobs-main-search-react .external-links {
        padding: 0 50px; } }
    @media (max-width: 991.98px) {
      .jobs-main-search-react .external-links {
        padding: 0px; } }
    @media (min-width: 992px) {
      .jobs-main-search-react .external-links .item-wrapper {
        background: #400095;
        margin-top: 40px; } }
    @media (min-width: 992px) {
      .jobs-main-search-react .external-links .item-wrapper:hover {
        background: #4E535A; } }
    .jobs-main-search-react .external-links a {
      color: #ffffff;
      margin: auto;
      text-decoration: none;
      font-family: "SourceSansPro-Semibold";
      text-align: center;
      line-height: 24px;
      font-size: 18px;
      font-weight: 100;
      display: block;
      width: 100%; }
      @media (max-width: 991.98px) {
        .jobs-main-search-react .external-links a {
          padding: 0px;
          color: #400095;
          margin: 0px;
          text-align: left; } }
    .jobs-main-search-react .external-links .link-item {
      font-size: 18px;
      padding: 0 35px 0 19px;
      display: table-cell;
      vertical-align: middle;
      height: 75px;
      text-align: center;
      position: relative;
      font-family: "SourceSansPro-Semibold"; }
      @media (max-width: 991.98px) {
        .jobs-main-search-react .external-links .link-item {
          padding: 0px;
          background: none;
          color: #400095;
          height: 45px; } }
      .jobs-main-search-react .external-links .link-item .fas {
        color: #ffffff;
        margin: auto 5px auto auto;
        position: absolute;
        right: 11px;
        top: 30px; }
  .jobs-main-search-react #jobs_search-react-main-wrapper {
    border-top: 1px solid rgba(119, 136, 153, 0.5);
    margin-top: 60px; }
    @media (max-width: 991.98px) {
      .jobs-main-search-react #jobs_search-react-main-wrapper {
        margin-top: 25px; } }
    .jobs-main-search-react #jobs_search-react-main-wrapper .container-fluid-lg {
      padding: 0px; }

/*Find a Job Ends */
.instagram-wrapper .tab-wrapper {
  padding-bottom: 60px;
  padding-top: 40px; }
  @media (max-width: 991.98px) {
    .instagram-wrapper .tab-wrapper {
      padding-bottom: 30px;
      padding-top: 20px; } }
  .instagram-wrapper .tab-wrapper .row .col-6 {
    padding: 0 45px; }
    .instagram-wrapper .tab-wrapper .row .col-6:first-child {
      border-right: 2px solid #cdcdcd; }
    @media (max-width: 991.98px) {
      .instagram-wrapper .tab-wrapper .row .col-6 {
        padding: 0 10px; } }

.instagram-wrapper .active .switch-insta {
  position: relative;
  z-index: 1;
  text-align: center;
  padding-bottom: 20px;
  font-size: 34px;
  font-family: "sharpSans-Medium";
  color: #009dd6;
  display: inline; }
  @media (max-width: 991.98px) {
    .instagram-wrapper .active .switch-insta {
      font-size: 26px; } }
  @media (max-width: 991.98px) {
    .instagram-wrapper .active .switch-insta {
      font-size: 22px; } }

.instagram-wrapper .active .switch-insta:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 35px;
  margin: 0 0 0 -15px;
  border-bottom: 5px solid #009dd6; }
  @media (max-width: 991.98px) {
    .instagram-wrapper .active .switch-insta:after {
      margin: 0 0 0 -20px;
      width: 50px; } }
  @media (max-width: 991.98px) {
    .instagram-wrapper .active .switch-insta:after {
      margin: 0 0 0 -10px;
      width: 21px; } }

.instagram-wrapper .inactive .switch-insta {
  font-size: 34px;
  font-family: "sharpSans-Book"; }
  @media (max-width: 991.98px) {
    .instagram-wrapper .inactive .switch-insta {
      font-size: 26px; } }
  @media (max-width: 991.98px) {
    .instagram-wrapper .inactive .switch-insta {
      font-size: 22px; } }

.instagrid-strap .insta-img-container {
  padding: 10px; }

.instagrid-strap .insta-image-container {
  height: 100%;
  width: 100%;
  cursor: pointer; }
  .instagrid-strap .insta-image-container:hover {
    opacity: 0.6; }

.instagram-item-grid:hover .insta-image-container {
  opacity: 0.6; }

.instagram-item-grid:hover .hearts-and-comments {
  opacity: 1; }

.tab-wrapper .active {
  color: #009DD6; }

.tab-wrapper .inactive {
  cursor: pointer; }

.tab-wrapper .align-right {
  text-align: right; }

/*Brightcove */
.brighove-player-wrapper {
  position: relative;
  padding-bottom: 56.25%; }
  .brighove-player-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .brighove-player-wrapper .vjs-poster {
    background-size: 100% 100%; }

.home-instagram-react-wrapper .hearts-and-comments {
  display: flex;
  justify-content: space-around;
  color: white;
  width: 60%;
  position: relative;
  margin: 0 auto;
  bottom: 170px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  cursor: pointer; }
  .home-instagram-react-wrapper .hearts-and-comments span {
    padding-right: 8px; }
  .home-instagram-react-wrapper .hearts-and-comments img {
    height: 25px;
    padding-right: 5px; }

.home-instagram-react-wrapper .heart-fade-in {
  position: absolute; }

.home-instagram-react-wrapper .likes {
  font-size: 20px; }

.home-instagram-react-wrapper #likes {
  visibility: hidden; }

/* Intern open positions and FAQ wrapper */
.internships-react-wrapper .faqs-main-wrapper {
  max-width: 1240px;
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    .internships-react-wrapper .faqs-main-wrapper {
      padding: 0 20px; } }
  .internships-react-wrapper .faqs-main-wrapper h1 {
    margin-top: 40px;
    margin-bottom: 0px !important;
    text-align: left !important; }

.internships-react-wrapper .open-positions-main-wrapper {
  padding-top: 0px !important; }

@media (max-width: 1199.98px) {
  .internships-react-wrapper [class^="MuiCard"],
  .internships-react-wrapper [class^="MuiCollapse"] {
    padding: 0px; } }

.internships-react-wrapper .faqs-main-wrapper .sub-header-title h3 {
  color: #876EAF !important;
  font-family: "SourceSansPro-Semibold"; }

@media only screen and (min-width: 993px) and (max-width: 1100px) {
  #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box .title {
    font-size: 18px;
    padding: 0 20px; }
  #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box::after {
    width: 90%; }
  #internships-react-main-wrapper .intern-videos-main-wrapper .video-description {
    padding: 90px 100px; } }

@media (min-width: 768px) and (max-width: 992px) {
  #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-logo {
    margin-bottom: 45px; }
  #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-description p {
    margin-bottom: 45px; }
  #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-description ul {
    margin-bottom: 60px; }
  #internships-react-main-wrapper .internships-react-wrapper .intern-possibility .accordion-item-body .logo1 img, #internships-react-main-wrapper .internships-react-wrapper .intern-possibility .accordion-item-body .logo2 img {
    max-width: 50%; }
  .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .logo-wrapper {
    width: 90%; }
  .intern-locations-main-wrapper .intern-locations-wrapper .locations .location img {
    max-width: 165px !important; } }

.react-autosuggest__input {
  width: 88%;
  background: url("../images/resources/map-pin.svg") no-repeat scroll;
  background-size: 28px 28px;
  background-position: 0.7% 10px;
  padding-left: 38px; }
  @media (max-width: 991.98px) {
    .react-autosuggest__input {
      padding-left: 40px !important; } }

.predictive-auto-search .react-autosuggest__input {
  background: none; }

.react-autosuggest__input::placeholder {
  color: grey; }

.react-autosuggest__suggestions-container {
  z-index: 1000; }

.react-autosuggest__suggestions-container--open {
  text-decoration: none;
  background: white;
  position: absolute;
  width: 34.4%;
  -webkit-box-shadow: 2px 3px 6px 2px #ccc;
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 2px 3px 6px 2px #ccc;
  /* Firefox 3.5 - 3.6 */
  box-shadow: 2px 3px 6px 2px #ccc;
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */ }
  @media (max-width: 991.98px) {
    .react-autosuggest__suggestions-container--open {
      width: 96%; } }

#react-autowharever-1 {
  margin: 0 auto; }

ul.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0px; }

.suggestion-list-item {
  padding: 8px 10px; }

.suggestion-list-item:hover {
  background: gray;
  color: white; }

.suggest-more-container {
  margin-top: 10px;
  background: whitesmoke;
  color: gray;
  padding: 10px; }

.open-positions-filters .row {
  margin: 0 auto;
  display: flex;
  justify-content: center; }

.filter-results {
  border: 1px solid #400095;
  border-radius: 6px;
  color: #400095;
  padding: 1px 8px;
  margin-right: 12px;
  cursor: pointer;
  background: none;
  margin-bottom: 6px; }
  .filter-results img {
    margin: 0 4px 2px 8px;
    height: 10px;
    width: 10px; }
  .filter-results .filter-reslt-btn {
    background: none;
    border: none;
    padding: 0;
    line-height: 16px; }
  @media (max-width: 767.98px) {
    .filter-results {
      margin-top: 6px; } }

.filter-results-aoi {
  border: 1px solid #400095;
  border-radius: 6px;
  color: #400095;
  padding: 2px 8px;
  margin-right: 12px;
  cursor: pointer;
  background: none;
  margin-bottom: 6px;
  width: auto; }
  .filter-results-aoi img {
    margin: 0 4px 2px 8px;
    height: 10px;
    width: 10px; }
  .filter-results-aoi .filter-reslt-btn {
    background: none;
    border: none;
    padding: 0;
    line-height: 16px; }

.levels-content .MuiCollapse-container-1 {
  max-height: 520px !important; }

.showing-results {
  padding-top: 8px;
  margin-right: 15px;
  color: grey; }

.slight-break {
  height: 8px; }

.filter-remove {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; }

.multiple-locations {
  color: #400093;
  cursor: pointer;
  display: flex; }
  .multiple-locations img {
    height: 12px;
    width: 14px;
    margin-left: 10px;
    margin-top: 10px; }

.purp-down {
  -webkit-transform: rotate(180deg);
  /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg);
  /* FF */
  -o-transform: rotate(180deg);
  /* Opera */
  -ms-transform: rotate(180deg);
  /* IE9 */
  transform: rotate(180deg); }

.keyword-close {
  position: absolute;
  top: 16px;
  right: 9%;
  font-size: 25px;
  background-image: url("../images/resources/x-button.svg");
  width: 19px;
  height: 19px;
  margin-top: 1px; }
  @media (max-width: 1199.98px) {
    .keyword-close {
      right: 10%; } }
  @media (max-width: 991.98px) {
    .keyword-close {
      display: none;
      right: 7%; } }
  @media (max-width: 767.98px) {
    .keyword-close {
      right: 11%; } }
  @media (max-width: 575.98px) {
    .keyword-close {
      right: 13%; } }

.job-locations-listing .expanded {
  width: 350px;
  padding: 0; }

@media (max-width: 767.98px) {
  .MuiCollapse-container-1 {
    overflow: hidden !important;
    overflow-y: auto !important; } }

@media (max-width: 767.98px) {
  .MuiCollapse-entered-2 {
    overflow: hidden !important;
    overflow-y: auto !important; } }

@media (max-width: 991.98px) {
  .open-positions-wrapper .search-form .fa-search {
    padding-left: 5px; } }

@media (max-width: 767.98px) {
  .aoi-card-content-container {
    width: 320px !important; } }

.aoi-device-container .MuiCollapse-container-1 {
  width: 370px !important; }
  .aoi-device-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 {
    margin-top: 16px; }
    .aoi-device-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .actions-container {
      margin-top: 7px !important; }
    .aoi-device-container .MuiCollapse-container-1 .MuiCollapse-wrapper-3 .aoi-content-card .MuiCardContent-root-5 {
      padding: 0 16px; }

.aoi-content-card {
  overflow: hidden;
  overflow-y: auto; }
  @media (max-width: 767.98px) {
    .aoi-content-card {
      width: 320px !important; } }
  .aoi-content-card .MuiCardContent-root-5 {
    max-height: 480px; }
    .aoi-content-card .MuiCardContent-root-5 .collapsible-content {
      max-height: 460px !important; }
  @media (max-width: 767.98px) {
    .aoi-content-card .MuiCollapse-wrapperInner-4 {
      width: 320px; } }
  @media (max-width: 767.98px) {
    .aoi-content-card .MuiCollapse-wrapper-3 {
      width: 320px; } }
  .aoi-content-card .aoi-card-content-container {
    overflow-y: auto; }
  @media (max-width: 767.98px) {
    .aoi-content-card .job-aoi-filter {
      width: 320px !important; } }
  .aoi-content-card .MuiCollapse-container-1 {
    position: absolute;
    width: 365px;
    height: 530px;
    z-index: 12;
    background: white;
    overflow: hidden;
    overflow-y: auto; }
    @media (max-width: 991.98px) {
      .aoi-content-card .MuiCollapse-container-1 {
        margin: 0 auto !important;
        position: relative; } }
    @media (max-width: 991.98px) {
      .aoi-content-card .MuiCollapse-container-1 .collapsible-content {
        padding: 16px; } }
    @media (max-width: 767.98px) {
      .aoi-content-card .MuiCollapse-container-1 .MuiCollapse-wrapperInner-4 {
        width: 320px; } }
    @media (max-width: 767.98px) {
      .aoi-content-card .MuiCollapse-container-1 .MuiCollapse-wrapper-3 {
        width: 320px; } }
    @media (max-width: 767.98px) {
      .aoi-content-card .MuiCollapse-container-1 {
        width: 320px; } }
    @media (max-width: 767.98px) {
      .aoi-content-card .MuiCollapse-container-1 .actions .list-filters-apply {
        width: 130px; } }
    @media (max-width: 767.98px) {
      .aoi-content-card .MuiCollapse-container-1 .actions .clear-selected {
        width: 130px !important; } }
  .aoi-content-card .aoi-name {
    margin-left: 2px;
    font-size: 18px; }
  .aoi-content-card .aoi-item-add {
    display: flex;
    padding: 5px 0;
    cursor: pointer; }
    .aoi-content-card .aoi-item-add .location-checkbox {
      width: 16px !important; }
    .aoi-content-card .aoi-item-add .add-check img {
      width: 11px;
      height: 11px; }
  .aoi-content-card .location-checkbox {
    width: 16px;
    height: 16px !important;
    top: 6px; }
  .aoi-content-card .add-check {
    width: 16px !important; }
  .aoi-content-card .collapsible-content {
    margin-top: 5px; }
    .aoi-content-card .collapsible-content i {
      margin: 10px 0; }

.filter-businessess .state-container .profession-list .location-checkbox {
  width: 17px !important; }

.profession-list {
  margin-top: 15px; }
  .profession-list div .state-container .location-checkbox {
    top: 18px;
    width: 16px; }
  .profession-list div .state-container .add-check {
    width: 16px; }
  .profession-list div .actions-container {
    position: relative;
    bottom: 0;
    margin-top: 39px; }
    .profession-list div .actions-container .actions {
      padding-bottom: 12px; }
  .profession-list .bus-name .location-checkbox {
    top: 16px !important; }

.device-iphone .profession-list div:last-child div.aoi-item-add {
  padding-bottom: 100px; }

.level-container {
  max-height: 480px;
  position: relative; }
  .level-container .actions-container {
    position: sticky;
    bottom: 0;
    margin-top: 230px; }
    .level-container .actions-container .actions {
      padding-bottom: 12px; }
  .level-container .MuiCardContent-root-5 {
    max-height: 480px; }
    .level-container .MuiCardContent-root-5 .collapsible-content {
      max-height: 460px; }
    .level-container .MuiCardContent-root-5 .profession-list {
      max-height: 420px;
      position: relative;
      display: flex;
      /*added*/
      flex-direction: column; }
  @media (max-width: 991.98px) {
    .level-container {
      padding: 16px;
      padding-bottom: 4px; } }
  @media (max-width: 991.98px) {
    .level-container .aoi-item-add {
      display: flex; }
      .level-container .aoi-item-add .aoi-name {
        margin-top: 8px;
        cursor: pointer; } }

.state-container {
  color: black; }
  .state-container .location-checkbox {
    position: relative;
    top: 16px;
    width: 16px; }

.bus-name {
  overflow: hidden; }
  .bus-name .bus-name {
    padding-top: 10px; }
  .bus-name .Collapsible__trigger {
    padding: 10px 22px 0px 2px; }
  .bus-name .location-checkbox {
    position: relative;
    top: 12px !important; }

.business-filter {
  margin-left: 400px;
  color: black; }
  .business-filter .MuiCollapse-container-1 {
    max-height: 500px !important; }
  .business-filter .actions-container {
    margin-top: 40px;
    position: sticky;
    bottom: 0; }
  .business-filter .Collapsible__trigger {
    padding-right: 0;
    width: 95%;
    margin-left: 5%; }
  .business-filter .subbus-item {
    cursor: pointer;
    display: flex;
    margin: 0 auto; }
  .business-filter .sub-business-name {
    margin-left: 12px; }

.aoi-card-content .MuiCollapse-container-1 {
  margin-left: 0px !important; }
  @media (max-width: 767.98px) {
    .aoi-card-content .MuiCollapse-container-1 {
      width: 320px; } }
  .aoi-card-content .MuiCollapse-container-1 .actions-container {
    margin-top: 10px;
    height: 80px; }
  .aoi-card-content .MuiCollapse-container-1 .profession-list {
    height: auto; }

.card-content-container {
  width: 420px;
  position: absolute; }
  .card-content-container .location-content-card {
    margin: 0 auto; }
    @media (max-width: 767.98px) {
      .card-content-container .location-content-card {
        width: 320px;
        padding-top: 15px;
        margin: 0 auto;
        z-index: 10; } }
    @media (max-width: 991.98px) {
      .card-content-container .location-content-card {
        width: 320px;
        max-height: 520px;
        padding-top: 15px;
        position: relative;
        margin: 0 auto;
        z-index: 10;
        background: white; } }
  @media (max-width: 991.98px) {
    .card-content-container {
      width: 320px;
      position: relative;
      margin: 0 auto;
      z-index: 10;
      background: white; } }
  .card-content-container .MuiCardContent-root-5 {
    position: relative;
    width: 100%;
    z-index: 10;
    background: white;
    height: 500px;
    overflow-y: auto;
    padding: 0 16px;
    margin-top: 16px; }
    .card-content-container .MuiCardContent-root-5::-webkit-scrollbar {
      width: 3px; }
    .card-content-container .MuiCardContent-root-5::-webkit-scrollbar-track {
      background: white; }
    .card-content-container .MuiCardContent-root-5::-webkit-scrollbar-thumb {
      background: #400093; }
    .card-content-container .MuiCardContent-root-5 div div div div {
      height: auto; }
      .card-content-container .MuiCardContent-root-5 div div div div .location-checkbox {
        height: 16px;
        width: 17px; }
  .card-content-container .MuiCollapse-container-1 {
    width: 365px;
    margin-left: 0px; }
    @media (max-width: 991.98px) {
      .card-content-container .MuiCollapse-container-1 {
        width: 365px; } }
    @media (max-width: 991.98px) {
      .card-content-container .MuiCollapse-container-1 {
        width: 320px; } }

@media (max-width: 767.98px) {
  .open-positions-filters .MuiCollapse-container-1 {
    width: 320px !important; } }

@media (max-width: 767.98px) {
  .open-positions-filters .MuiCollapse-entered-2 {
    width: 320px !important; } }

.global-region-container {
  width: 100%;
  text-decoration: none;
  list-style: none;
  padding-left: 0; }
  .global-region-container .global-region {
    width: 100%;
    height: 50px;
    background: #44445e;
    position: relative;
    color: white;
    padding: 0 20px;
    margin: 3px 0;
    cursor: pointer; }
    .global-region-container .global-region h4 {
      font-size: 20px;
      font-weight: 500;
      line-height: 50px; }

.actions {
  padding-top: 14px;
  text-align: right;
  background: white;
  display: flex;
  padding-bottom: 16px; }
  .actions div {
    font-size: 16px;
    margin-left: 0; }
    .actions div span {
      vertical-align: center;
      line-height: 50px; }
  .actions .list-filters-apply {
    background: #400093;
    height: 50px;
    color: #ffffff;
    width: 157.5px;
    margin-right: 8px; }
    @media (max-width: 767.98px) {
      .actions .list-filters-apply {
        width: 130px; } }
  .actions .clear-selected {
    color: black;
    background: white;
    border: 1px solid black;
    height: 50px;
    width: 157.5px;
    margin-left: 8px; }
    @media (max-width: 767.98px) {
      .actions .clear-selected {
        width: 130px; } }

.actions {
  text-align: right;
  background: white; }
  .actions div {
    color: #ffffff;
    border: none;
    height: 50px;
    width: 160px;
    margin: 0 auto;
    font-size: 18px;
    cursor: pointer; }
  .actions .list-filters-apply {
    background: #400093; }
  .actions .clear-selected {
    color: black;
    background: white;
    border: 1px solid black; }

.location-accordion-container {
  position: relative;
  height: 100%; }
  .location-accordion-container .actions-container {
    margin-top: 8px; }

.open-region-dropdown {
  position: relative;
  z-index: 10000000;
  margin: 0 auto; }
  .open-region-dropdown li {
    position: relative; }

@media (max-width: 991.98px) {
  .region-dropdown {
    max-height: 418px !important;
    overflow-y: auto; } }

.region-dropdown i {
  margin: 10 0; }

.job-location {
  cursor: auto; }
  .job-location .multiple-locations {
    cursor: pointer; }

trigger-tag {
  display: block;
  font-weight: 300;
  text-decoration: none;
  position: relative;
  padding: 10px;
  font-size: 20px;
  font-family: 'SourceSansPro-regular';
  color: white;
  width: 100%;
  cursor: pointer;
  background: #4E535A;
  background-size: 14px 9px;
  background-position: 2% 10px;
  z-index: 6; }
  @media (max-width: 991.98px) {
    trigger-tag {
      width: 300px;
      margin: 0 auto; } }

.Collapsible {
  margin: 0px 0 1px; }

state-dropdown-trigger {
  margin-left: 3px;
  font-size: 18px;
  line-height: 45px;
  display: block;
  text-decoration: none;
  position: relative;
  font-family: 'SourceSansPro-Regular';
  width: 100%;
  cursor: pointer;
  font-weight: normal;
  background: url("../images/resources/arrow-down-gray.png") no-repeat scroll;
  background-size: 14px 11px;
  background-position: 98.2% 14px; }

.state-container {
  cursor: pointer;
  display: flex;
  margin: 0 auto; }
  .state-container .rc-checkbox {
    line-height: 5px;
    padding: 14px 8px 8px; }
    .state-container .rc-checkbox .rc-checkbox-input {
      border-radius: 3px; }
  .state-container .Collapsible {
    width: 100%; }

.open-state-trigger-tag state-dropdown-trigger {
  color: #400093;
  background: url("../images/resources/arrow-up-purp.png") no-repeat scroll;
  background-size: 14px 11px;
  background-position: 98.2% 14px; }

.first-state {
  font-size: 18px;
  margin-top: 7px;
  font-family: 'SourceSansPro-Regular';
  margin-left: 2px;
  padding-bottom: 10px; }

.first-city {
  font-size: 16px;
  margin-top: 7px;
  font-family: 'SourceSansPro-Regular';
  margin-left: 2px;
  padding-bottom: 4px; }

.Collapsible__trigger {
  font-size: 18px;
  line-height: 40px;
  line-height: 30px;
  display: block;
  text-decoration: none;
  position: relative;
  padding: 10px;
  padding-left: 12px;
  font-family: 'SourceSansPro-Regular';
  width: 100%;
  cursor: pointer;
  background: url("../images/resources/arrow-down-gray.png") no-repeat scroll;
  background-size: 14px 11px;
  background-position: 99% 17px; }

.bus-name .is-open {
  background: url("../images/resources/arrow-up-purp.png") no-repeat scroll;
  background-size: 14px 11px;
  background-position: 99% 17px; }

.state-dropdown-container {
  margin-top: 8px;
  margin-left: 8px; }

.country-trigger-container {
  margin: 0 auto;
  display: flex; }
  .country-trigger-container .all-selected {
    padding-left: 32px; }
  .country-trigger-container .region-check {
    display: none;
    height: 26px !important;
    width: 18px;
    position: absolute;
    margin-top: 11px;
    margin-left: 10px;
    z-index: 1;
    cursor: pointer; }
  .country-trigger-container .slide-title {
    padding-left: 32px; }

@-webkit-keyframes test {
  0% {
    padding-left: 20px !important; }
  100% {
    padding-left: 38px !important; } }

@keyframes test {
  0% {
    padding-left: 20px !important; }
  100% {
    padding-left: 38px !important; } }
  .country-trigger-container .Collapsible__contentInner {
    overflow: hidden; }
  .country-trigger-container .animate-check {
    position: absolute;
    display: block !important;
    float: left;
    height: 26px !important;
    overflow: hidden;
    -webkit-animation: reveal .5s ease;
    -webkit-animation-fill-mode: backwards;
    animation: reveal .5s ease;
    width: 18px;
    z-index: 1; }
    .country-trigger-container .animate-check img {
      position: relative;
      height: 16px;
      width: 16px;
      z-index: 1; }
  .country-trigger-container .Collapsible {
    overflow: hidden !important; }

@-webkit-keyframes reveal {
  0% {
    width: 0px; }
  100% {
    width: 18px; } }

@keyframes reveal {
  0% {
    width: 0px; }
  100% {
    width: 18px; } }
  .country-trigger-container .Collapsible {
    width: 100%; }
  .country-trigger-container .Collapsible__contentOuter {
    width: 100%; }
  @media (max-width: 991.98px) {
    .country-trigger-container {
      width: 86%; } }
  .country-trigger-container .test-check {
    height: 20px;
    width: 30px; }

.actions-container {
  margin-top: auto;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1000000000000; }
  .actions-container .actions {
    text-align: center !important; }

.filter-businesses .actions-container {
  margin-top: 10px; }

.MuiCardContent-root-5:last-child {
  padding-bottom: 0px !important; }

@media (min-width: 576px) {
  .open-region-dropdown trigger-tag {
    padding-left: 40px;
    background: #400093 url("../images/resources/checkmark-for-verification.svg") no-repeat scroll;
    -webkit-animation: slide-check .3s linear;
    /* Safari */
    animation: slide-check .3s linear;
    font-family: "SourceSansPro-Regular";
    background-size: 16px 16px;
    background-position: 17px 18px; }
  @keyframes slide-check {
    0% {
      background-color: #400093;
      background-size: 0 0;
      padding-left: 10px;
      background-position: 17px 26px; }
    100% {
      background-color: #400093;
      background-size: 16px 16px;
      padding-left: 40px;
      background-position: 17px 18px; } }
  @-webkit-keyframes slide-check {
    0% {
      background-color: #400093;
      background-size: 0 0;
      padding-left: 10px;
      background-position: 17px 26px; }
    100% {
      background-color: #400093;
      background-size: 16px 16px;
      padding-left: 40px;
      background-position: 17px 18px; } } }

@media (max-width: 767.98px) {
  trigger-tag {
    background-image: url("../images/resources/checkmark-for-verification.svg") no-repeat scroll;
    background-position: 0px 26px;
    background-size: 16px 16px; }
  .open-region-dropdown trigger-tag {
    padding-left: 40px;
    background: #400093 url("../images/resources/checkmark-for-verification.svg") no-repeat scroll;
    background-size: 16px 16px;
    font-family: "SourceSansPro-Regular";
    background-size: 16px 16px;
    background-position: 17px 18px;
    transition: background .4s;
    -webkit-transition: background .4s;
    -moz-transition: background .4s;
    -o-transition: background .4s;
    transition: background-position .4s;
    -webkit-transition: background-position .4s;
    -moz-transition: background-position .4s;
    -o-transition: background-position .4s;
    transition: background-size .4s;
    -webkit-transition: background-size .4s;
    -moz-transition: background-size .4s;
    -o-transition: background-size .4s; } }

.no-current-openings-container {
  padding: 8px 0; }
  .no-current-openings-container .no-current-openings {
    font-size: 18px;
    margin-left: 10px; }

.location-checkbox {
  position: relative;
  top: 14px;
  left: 0;
  height: 16px;
  width: 17px;
  margin-right: 10px;
  background-color: #fff;
  border: solid #9F7FCA;
  border-width: 1px;
  border-radius: 5px;
  margin-right: 8px !important;
  margin-left: 2px; }

.state-checkbox {
  position: relative;
  top: 12px;
  margin-left: 2px;
  height: 16px !important;
  width: 17px !important;
  margin-right: 10px;
  background-color: #fff;
  border: solid #9F7FCA;
  border-width: 1px;
  border-radius: 5px; }
  .state-checkbox span {
    width: 16px;
    height: 16px; }

.all-cities-container {
  height: 40px !important;
  display: flex; }
  .all-cities-container .location-checkbox {
    width: 16px !important;
    height: 16px !important;
    top: 12px; }

.first-location-checkbox {
  position: relative;
  top: 14px;
  left: 0;
  height: 16px;
  width: 15.7px;
  margin-right: 10px;
  background-color: #fff;
  border: solid #9F7FCA;
  border-width: 1px;
  border-radius: 5px;
  margin-right: 8px !important;
  margin-left: 2px; }

.all-states .location-checkbox {
  width: 17px !important;
  height: 16px; }

.state-dropdown-container .state-container .location-checkbox {
  width: 17px !important; }

.state-dropdown-container .state-container .add-check {
  width: 17px !important; }

.state-dropdown-container .state-container .all-cities-container .location-checkbox {
  width: 16px !important; }

.state-dropdown-container .state-container .city-container .location-checkbox {
  width: 16px !important; }

.state-dropdown-container-int {
  margin-left: 8px;
  text-align: left; }
  .state-dropdown-container-int .state-container {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .state-dropdown-container-int .state-container .city-container {
      display: flex;
      width: 100%; }

.city-container-wrapper {
  display: flex;
  flex-direction: column; }

.filter-businesses .state-container .location-checkbox {
  width: 17.3px !important;
  height: 16px; }

.filter-businesses .state-container .add-check {
  width: 17.6px !important; }

.filter-businesses .state-container .bus-name .location-checkbox {
  width: 16px !important;
  height: 16px; }

.filter-businesses .state-container .bus-name .add-check {
  width: 16px !important; }

.add-check {
  background: #400093;
  border: #400095;
  height: 16px;
  width: 17px; }
  .add-check img {
    height: 11px;
    width: 11px;
    position: relative;
    bottom: 6px;
    left: 2px; }

.first-add-check {
  background: #400093;
  border: #400095;
  height: 16px;
  width: 15.7px !important; }
  .first-add-check img {
    height: 11px;
    width: 11px;
    position: relative;
    bottom: 6px;
    left: 2px; }

.city-container .location-checkbox {
  width: 16px !important;
  height: 16px !important;
  top: 11px; }

.city-container .add-check img {
  bottom: 14px; }

.region-drop-down {
  margin: 0 auto; }
  @media (min-width: 768px) {
    .region-drop-down {
      margin-top: 10px;
      width: 40%; } }
  @media (max-width: 767.98px) {
    .region-drop-down {
      margin: 0 auto;
      margin-top: -1px;
      width: 80%; } }

.open-country-trigger-tag > .Collapsible__trigger {
  color: #400093;
  background: url("../images/resources/arrow-up-purp.png") no-repeat scroll;
  background-size: 14px 11px;
  background-position: 99% 20px; }

.open-country-trigger-tag {
  width: 100% !important; }

.city-container {
  display: flex;
  line-height: 40px; }
  .city-container .rc-checkbox {
    line-height: 20px; }

.rc-checkbox-input {
  height: 15px;
  width: 15px;
  border-radius: 8px;
  color: purple; }

.rc-checkbox-inner {
  border-radius: 8px; }

.main-region-container {
  min-height: 410px !important; }

.card {
  width: 500px;
  margin: 50px auto;
  clear: both;
  display: block;
  padding: 0px 0px;
  background-color: #009BFF;
  border-radius: 4px; }

.card::after {
  clear: both;
  display: block;
  content: ""; }

.card .checkbox-container {
  float: left;
  width: 50%;
  box-sizing: border-box;
  text-align: center;
  padding: 40px 0px; }

.card .circular-container {
  background-color: red; }

.input-title {
  clear: both;
  padding: 22px 0px 0px 0px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300; }

@media (max-width: 991.98px) {
  .open-positions-filters {
    flex-direction: column; } }

.open-positions-filters .selector {
  font-size: 16px; }
  .open-positions-filters .selector .fas {
    margin-right: 2px; }

@media (max-width: 991.98px) {
  .job-location-filter .MuiCollapse-container-1 {
    width: 320px !important;
    position: relative;
    margin: 0 auto; } }

.path-internships .featured-top .field--name-field-banner-logo-small {
  bottom: 20px; }

.path-internships .site-footer {
  margin-top: 0; }

#internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box .title {
  font-size: 18px;
  padding: 0 20px; }

#internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility .outer-ip-box::after {
  width: 90%; }

#internships-react-main-wrapper .intern-videos-main-wrapper .video-description {
  padding: 90px 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 520px;
  overflow: hidden; }
  @media (max-width: 1199.98px) {
    #internships-react-main-wrapper .intern-videos-main-wrapper .video-description {
      padding: 60px; } }
  @media (max-width: 991.98px) {
    #internships-react-main-wrapper .intern-videos-main-wrapper .video-description {
      padding: 38px; } }
  @media (max-width: 767.98px) {
    #internships-react-main-wrapper .intern-videos-main-wrapper .video-description {
      padding: 19px;
      max-height: 630px; } }

#internships-react-main-wrapper .intern-how-to-apply-main-wrapper .banner-images .banner-image img {
  height: 100%; }

.open-positions-main-wrapper .open-positions-filters .expanded {
  margin: 0px; }

.open-positions-main-wrapper .search-form {
  border-radius: 0px; }
  .open-positions-main-wrapper .search-form .input-job-search {
    border-radius: 0px; }

.path-find-a-job .job-aoi-listing .aoi-listing > .row, .path-internships .job-aoi-listing .aoi-listing > .row {
  display: flex;
  flex-direction: column;
  max-height: 360px;
  height: 360px; }

.path-find-a-job .job-business-listing .business-listing > .row, .path-internships .job-business-listing .business-listing > .row {
  display: flex;
  flex-direction: column;
  max-height: 360px;
  height: 360px; }

.path-find-a-job .job-locations-listing .locations-listing > .row, .path-internships .job-locations-listing .locations-listing > .row {
  display: flex;
  flex-direction: column;
  max-height: 320px;
  height: 320px; }

@media (min-width: 768px) and (max-width: 992px) {
  #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-logo {
    margin-bottom: 45px; }
  #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-description p {
    margin-bottom: 45px; }
  #internships-react-main-wrapper .intern-navigation-main-wrapper .intern-navigation-wrapper .right-navigation .internship-content .internship-description ul {
    margin-bottom: 60px; }
  #internships-react-main-wrapper .internships-react-wrapper .intern-possibility .accordion-item-body .logo1 img, #internships-react-main-wrapper .internships-react-wrapper .intern-possibility .accordion-item-body .logo2 img {
    max-width: 50%; }
  .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .logo-wrapper {
    width: 90%; }
  .path-find-a-job .open-positions-main-wrapper .search-form {
    max-width: 1240px !important;
    border-radius: 0px; }
    .path-find-a-job .open-positions-main-wrapper .search-form .input-job-search {
      border-radius: 0px;
      outline: none; }
  .path-find-a-job .container-fluid-lg .external-links .col-12 .item-wrapper .link-item .fa-arrow-right {
    display: none; }
  .path-internships .instagram-popup-container .next-button-popup {
    right: -4vw; }
  .path-internships .instagram-popup-container .prev-button-popup {
    left: -4vw; } }

@media (max-width: 1024px) {
  #internships-react-main-wrapper .intern-possibilities-main-wrapper .intern-possibilities-wrapper .intern-possibilities .intern-possibility {
    padding: 0 10px; } }

@media (max-width: 767px) {
  #internships-react-main-wrapper .intern-locations-main-wrapper .intern-locations-wrapper .locations .location .logo-wrapper img {
    width: 100%; }
  .node--type-page-program .instagram-popup-container .popup-image {
    max-width: 100% !important; }
  .node--type-page-program .instagram-popup-container .popup-text-container {
    margin: 0px 15px; }
  .node--type-page-program .styles_overlay__CLSq- {
    padding: 0px 10px; } }

.node--type-page-program .intern-instagram-main-wrapper .carousel-container {
  padding-top: 0px; }

.node--type-page-program .instagram-popup-container .popup-image {
  max-width: 500px; }

.node--type-page-program .instagram-popup-container .popup-text-container .popup-title-container h4 {
  top: 3px; }

.node--type-page-program .styles_closeButton__20ID4 {
  top: -46px;
  right: -4px; }

.IG-intern-title {
  padding: 35px 0 0 0;
  font-family: "sharpSans-Medium";
  text-align: center;
  width: 100%; }
  .IG-intern-title a {
    margin: 0 auto;
    font-size: 34px;
    color: #009dd6;
    text-decoration: none; }

.remote-filter {
  cursor: pointer; }
  .remote-filter .remote-item-add {
    bottom: 3px;
    position: relative; }
  .remote-filter .location-checkbox {
    top: 22px !important; }
  .remote-filter .remote-label {
    padding-left: 25px;
    font-size: 18px;
    color: #6f7680; }

.intern-video-modal-content {
  max-width: 100%;
  width: 90%; }

.intern-video-modal-overlay {
  background: rgba(255, 255, 255, 0.9); }

.intern-video-modal-content .close {
  margin-right: -25px;
  margin-top: -52px;
  background: #ffffff;
  font-size: 40px;
  cursor: pointer;
  padding: 2px 5px;
  opacity: .5; }

#internships-react-main-wrapper .intern-videos-main-wrapper .video-description a.btn-intern-video {
  font-family: "SourceSansPro-Semibold";
  color: #212529;
  font-size: 17px;
  line-height: 30px; }

#internships-react-main-wrapper .intern-videos-main-wrapper .video-description a.btn-intern-video:hover {
  color: #886db0; }

.btn-intern-video {
  font-family: "SourceSansPro-Semibold";
  color: #212529;
  font-size: 17px;
  line-height: 30px; }

.btn-intern-video .fa-arrow-right {
  font-size: 16px;
  padding-left: 10px; }

@media (min-width: 1023px) {
  .intern-video-modal-content {
    max-width: 650px;
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .intern-video-modal-content {
    max-width: 100%;
    width: 98%; } }

@media (max-width: 768px) {
  .intern-video-modal-content .close {
    margin-right: 0px;
    margin-top: -65px; } }

.open-positions-jobs {
  display: table;
  width: 100%; }
  .open-positions-jobs .jobs-row {
    background: #F5F3F3;
    border-bottom: 1px solid #7E7E7E;
    padding: 6px 0; }
    .open-positions-jobs .jobs-row th {
      font-family: 'SourceSansPro-Regular';
      font-size: 18px;
      line-height: 30px;
      font-weight: 700; }
  .open-positions-jobs .jobs-display .job-ref-text {
    font-size: 18px; }
  .open-positions-jobs .interest-col {
    flex: 0 0 13.66667%;
    max-width: 13.66667%; }
  .open-positions-jobs .date-col {
    flex: 0 0 11.33333%;
    max-width: 11.33333%; }

.open-positions-main-wrapper .open-positions-jobs .jobs-display .showing-jobs .single-job {
  background: #EEEEEE;
  word-wrap: break-word;
  align-items: center; }

.open-positions-main-wrapper .open-positions-jobs .jobs-display .showing-jobs:nth-child(4n+1) .single-job {
  background: #FFFFFF; }

.open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .jobs-icons {
  display: none; }

.open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .header-label {
  display: none; }

.posted-txt {
  display: none; }

.custom-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0 auto;
  padding: 0; }
  .custom-pagination .page-arrows {
    background: #EEEEEE;
    padding: 10px;
    color: #000000;
    line-height: 8px;
    font-size: 20px;
    text-decoration: none;
    margin: 0 4px;
    transition: all 0.2s linear;
    border: 1px solid transparent; }
    .custom-pagination .page-arrows:hover {
      border: 1px solid #400095; }
    .custom-pagination .page-arrows:focus {
      border: 1px solid #400095; }
  .custom-pagination .page-count {
    margin: 0 4px; }
    .custom-pagination .page-count a {
      display: inline-block;
      font-family: 'SourceSansPro-Regular';
      font-size: 18px;
      line-height: 30px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #454546;
      padding: 7px 18px;
      transition: all 0.2s linear;
      border: 1px solid transparent;
      cursor: pointer; }
      .custom-pagination .page-count a:hover {
        border: 1px solid #400095;
        text-decoration: none; }
      .custom-pagination .page-count a:focus {
        border: 1px solid #400095;
        text-decoration: none; }
  .custom-pagination .page-count.active a {
    color: #ffffff;
    background-color: #400095; }
  .custom-pagination .page-breaks {
    font-family: 'SourceSansPro-Regular';
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #454546; }
  .custom-pagination .disabled {
    pointer-events: none; }
    .custom-pagination .disabled .page-arrows {
      color: #A7A9AC; }

.mobile-custom-pagination {
  display: none; }

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .levels-content .MuiCollapse-container-1 {
    margin-left: -120px; } }

@media only screen and (max-width: 991px) {
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job {
    display: flex;
    flex-direction: column;
    padding: 28px 0;
    word-wrap: normal;
    line-height: 20px; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .column {
      margin-bottom: 10px;
      padding: 0 19px; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-title {
      order: 1; }
      .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-title p {
        margin-bottom: 0;
        font-size: 16px; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-interest {
      order: 4;
      display: flex;
      align-items: center; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-business {
      order: 2;
      display: flex;
      align-items: center; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-location {
      order: 3;
      display: flex;
      align-items: center; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-level {
      order: 5;
      display: flex;
      align-items: center; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .job-date {
      order: 6;
      margin: 0; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job:first-child {
      border-top: 1px solid #7E7E7E; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job:last-child {
      border-bottom: 1px solid #7E7E7E; }
    .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .header-label {
      display: inline-block;
      min-width: 95px;
      color: #43474D;
      font-weight: 700; }
  .open-positions-main-wrapper .open-positions-jobs .interest-col {
    flex: 0 0 100%;
    max-width: 100%; }
  .open-positions-main-wrapper .open-positions-jobs .date-col {
    flex: 0 0 100%;
    max-width: 100%; }
  .posted-txt {
    display: inline-block;
    padding-right: 5px; }
  .custom-pagination {
    justify-content: center;
    max-width: 100%; }
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .showing-jobs .single-job {
    background: #FFFFFF;
    word-wrap: normal; }
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .showing-jobs:nth-child(4n+1) .single-job {
    background: #EEEEEE; } }

@media only screen and (min-width: 991.98px) {
  .open-positions-main-wrapper .jobs-search-container .react-autosuggest__container {
    border-bottom: none;
    border: 3px solid #CCCCCC; }
  .open-positions-main-wrapper .search-form input {
    padding-left: 45px; }
  .open-positions-main-wrapper .search-form .fa-search {
    left: 11.2%;
    top: 18px; }
  .open-positions-main-wrapper .search-form .fa-arrow-right {
    right: 10.5%;
    top: 16px; }
  .open-positions-main-wrapper .search-form .jobs-arrow-right {
    right: 53.5%; }
  .open-positions-main-wrapper .search-form .location-arrow-clear {
    right: 14% !important; }
  .open-positions-main-wrapper .search-form .jobs-arrow-clear {
    right: 57% !important; }
  .open-positions-main-wrapper .react-autosuggest__input {
    background-position: 2.7% 11px; } }

@media only screen and (max-width: 767px) {
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .header-label {
    display: none; }
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .jobs-icons {
    display: inline-flex;
    width: 24px;
    height: 24px; }
  .open-positions-main-wrapper .open-positions-jobs .jobs-display .single-job .mob-field-name {
    width: calc(100% - 24px);
    margin-left: 7px; }
  .open-positions-main-wrapper .open-positions-pagination {
    margin-top: 30px; }
    .open-positions-main-wrapper .open-positions-pagination .pager-items {
      text-align: right; }
      .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next > div:hover {
        border: 2px solid #400095; }
      .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-next > div:focus {
        border: 2px solid #400095; }
      .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous > div:hover {
        border: 2px solid #400095; }
      .open-positions-main-wrapper .open-positions-pagination .pager-items .pager-item-previous > div:focus {
        border: 2px solid #400095; }
    .open-positions-main-wrapper .open-positions-pagination .pager-items-count {
      margin-top: 15px; }
  .custom-pagination {
    display: none; }
  .mobile-custom-pagination {
    display: block; } }

@media only screen and (min-width: 1200px) {
  .open-positions-main-wrapper .open-positions-filters {
    max-width: 82%;
    width: 100%;
    margin: 0 auto; }
    .open-positions-main-wrapper .open-positions-filters .row {
      justify-content: space-between; }
      .open-positions-main-wrapper .open-positions-filters .row .filter {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 10px 7px; }
        .open-positions-main-wrapper .open-positions-filters .row .filter:last-child {
          flex: 0 0 160px;
          max-width: 160px;
          text-align: right; }
  .remote-filter .location-checkbox {
    left: 20px; }
    .remote-filter .location-checkbox img {
      left: -3px; } }

#internships-react-main-wrapper .open-positions-wrapper .main-title,
#internships-react-main-wrapper .faqs-wrapper .faqs-title {
  border-bottom: 1px solid rgba(119, 136, 153, 0.8); }

#internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.desktop.desktop-motion-image {
  display: none; }

#internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.mobile.mobile-motion-image {
  display: none; }

#internships-react-main-wrapper .featured-top .field--name-field-banner-image.desktop.intern-banner-video {
  display: block;
  height: 775px; }

#internships-react-main-wrapper .featured-top .field--name-field-banner-image.intern-banner-video .bright-video-wrap {
  width: 100%;
  height: 100%; }

#internships-react-main-wrapper .featured-top .bright-video-wrap .video-js .vjs-dock-text {
  display: none; }

#internships-react-main-wrapper .featured-top .bright-video-wrap .video-js .vjs-control-bar {
  display: none; }

@media (prefers-reduced-motion) {
  #internships-react-main-wrapper .desktop-wrapper {
    display: none; }
  #internships-react-main-wrapper .mobile-wrapper {
    display: none; }
  #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.desktop.desktop-motion-image {
    display: block; } }

@media (max-width: 991.98px) {
  #internships-react-main-wrapper .desktop-wrapper {
    display: none; }
  #internships-react-main-wrapper .featured-top .field--name-field-banner-image.mobile.mobile-intern-banner-video {
    display: block;
    height: auto; } }

@media (max-width: 991.98px) and (prefers-reduced-motion) {
  #internships-react-main-wrapper .desktop-wrapper {
    display: none; }
  #internships-react-main-wrapper .mobile-wrapper {
    display: none; }
  #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.desktop.desktop-motion-image {
    display: none; }
  #internships-react-main-wrapper .intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.mobile.mobile-motion-image {
    display: block; } }

.path-candidate-privacy-policy strong {
  font-family: "SourceSansPro-Bold";
  font-weight: normal !important; }

.path-candidate-privacy-policy .layout-main-wrapper {
  overflow: auto; }

@media (max-width: 767.98px) {
  .path-candidate-privacy-policy .container-fluid-lg {
    padding: 0px 15px; } }

.path-candidate-privacy-policy .privacy_topdiv {
  margin-top: 80px; }
  .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-header .view-display-id-privacyheader h2 {
    font-family: "sharpSans-Light";
    color: #000;
    font-size: 40px; }
  .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-header .view-display-id-privacyheader p {
    font-family: "SourceSansPro-Regular";
    color: #2a2a2a;
    font-size: 18px;
    line-height: 30px; }
  .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-header .view-display-id-privacyheader P:first-child {
    margin-top: 30px; }
  @media (max-width: 991.98px) {
    .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu {
      order: 2; } }
  @media (max-width: 767.98px) {
    .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu {
      padding: 0px; } }
  @media (max-width: 767.98px) {
    .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu .side-title {
      padding-left: 15px; } }
  @media (max-width: 767.98px) {
    .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu .title-border {
      margin-left: 15px; } }
  @media (max-width: 767.98px) {
    .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu .view-content.row {
      margin: 0px -15px; } }
  @media (max-width: 767.98px) {
    .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu .view-content.row .item-list {
      width: 100%; } }
  .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu ul li {
    a: hover;
      a-color: #009DD6;
      a-font-size: 20px; }
    @media (max-width: 767.98px) {
      .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu ul li {
        padding: 0px; } }
    @media (max-width: 767.98px) {
      .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu ul li .field-content {
        width: 100%;
        float: left; } }
    .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu ul li a {
      text-decoration: none;
      font-family: "SourceSansPro-Semibold";
      color: #6d6d6d;
      font-size: 20px; }
      @media (max-width: 767.98px) {
        .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu ul li a {
          padding: 10px 15px;
          width: 100%;
          float: left; } }
    .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu ul li .selected a {
      color: #009DD6;
      font-size: 20px; }
  .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu h2.side-title {
    font-family: "sharpSans-Semibold";
    font-size: 20px; }
    @media (max-width: 991.98px) {
      .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu h2.side-title {
        margin-top: 30px; } }
  .path-candidate-privacy-policy .privacy_topdiv .tt-view-privacy-menu .title-border {
    width: 65px;
    height: 6px;
    background-color: #26ACE2;
    margin-bottom: 30px; }

.path-candidate-privacy-policy .privacy_contnetdiv {
  margin-top: 27px; }
  @media (max-width: 991.98px) {
    .path-candidate-privacy-policy .privacy_contnetdiv {
      margin-top: 37px; } }
  .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-name .privacy-title-esction {
    font-family: "sharpSans-Semibold";
    color: #009DD6;
    font-size: 24px; }
  .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-description__value .field-content p:first-child {
    margin: 35px 0px 30px 0px; }
  .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-description__value .field-content p.subHeader {
    font-family: "sharpSans-Medium";
    color: #009DD6;
    font-size: 20px; }
  .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-description__value .field-content p {
    font-family: "SourceSansPro-Regular";
    color: #2a2a2a;
    font-size: 18px;
    line-height: 30px; }
  .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-description__value .field-content ul {
    margin-bottom: 40px;
    list-style-type: none; }
    .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-description__value .field-content ul li {
      font-family: "SourceSansPro-Regular";
      color: #2a2a2a;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 15px; }
  .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-description__value .field-content ul.bulletRequired {
    list-style-type: disc;
    list-style-position: inside; }
    .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-description__value .field-content ul.bulletRequired li {
      font-size: 18px;
      line-height: 30px;
      font-weight: normal;
      margin-bottom: 0px; }
  .path-candidate-privacy-policy .privacy_contnetdiv .view-display-id-privacycontent .views-field-description__value .field-content #moresensitive {
    color: #009DD6; }

.node--type-page h1.page-title {
  margin-top: 90px;
  font-family: "sharpSans-Book";
  text-align: center;
  font-size: 40px;
  color: #2a2a2a;
  padding: 0 0 25px 0; }

.node--type-page ol.terms_conditions {
  margin-top: 60px;
  font-family: "SourceSansPro-Regular";
  font-size: 20px;
  color: #009DD6;
  padding-left: 20px; }

.node--type-page p.title, .node--type-page h2.title {
  font-family: "sharpSans-Semibold";
  font-size: 24px;
  color: #2a2a2a;
  padding-bottom: 30px;
  line-height: 24px;
  font-weight: normal; }

.node--type-page .font-blue {
  color: #009DD6 !important; }

.node--type-page .basic-page p {
  font-size: 18px; }

.path-nbcuniversal-careers-terms-use h1.page-title {
  text-align: left; }

/** Talent section */
.path-find-a-job #jobs-search-message-wrapper {
  border-top: 1px solid rgba(119, 136, 153, 0.5);
  margin-top: 60px; }
  .path-find-a-job #jobs-search-message-wrapper .joblist-message {
    font-weight: bold;
    font-style: italic;
    font-size: 20px;
    margin-top: 20px; }

.path-find-a-job .talent-regions-wrapper {
  color: #2a2a2a; }
  .path-find-a-job .talent-regions-wrapper .modal-header {
    padding: 8px 10px;
    text-align: center;
    width: 100%;
    background: #eee;
    border: none; }
    .path-find-a-job .talent-regions-wrapper .modal-header h2 {
      width: 100%;
      font-weight: 700;
      font-size: 18px;
      margin: 0;
      color: #000; }
  .path-find-a-job .talent-regions-wrapper h3 {
    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px; }
  .path-find-a-job .talent-regions-wrapper .modal-body {
    padding: 35px; }
  .path-find-a-job .talent-regions-wrapper .talent-external-links li {
    list-style: none !important; }
  .path-find-a-job .talent-regions-wrapper .iframe-wrapper {
    width: 100%;
    min-height: 1400px;
    border: 0px; }
  .path-find-a-job .talent-regions-wrapper .title {
    text-align: center;
    font-size: 40px;
    margin-top: 75px;
    font-family: "sharpSans-Book"; }
    @media (max-width: 991.98px) {
      .path-find-a-job .talent-regions-wrapper .title {
        margin-top: 35px;
        font-size: 26px;
        text-align: left; } }
  .path-find-a-job .talent-regions-wrapper .description {
    font-size: 18px;
    padding: 0 10%;
    margin-top: 30px;
    text-align: center;
    line-height: 30px; }
    @media (max-width: 1199.98px) {
      .path-find-a-job .talent-regions-wrapper .description {
        padding: 0 50px; } }
    @media (max-width: 991.98px) {
      .path-find-a-job .talent-regions-wrapper .description {
        padding: 0px;
        text-align: left; } }
  .path-find-a-job .talent-regions-wrapper .talent-external-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 50px; }
    @media (max-width: 1199.98px) {
      .path-find-a-job .talent-regions-wrapper .talent-external-links {
        padding: 0 50px; } }
    @media (max-width: 991.98px) {
      .path-find-a-job .talent-regions-wrapper .talent-external-links {
        padding: 0px; } }
    .path-find-a-job .talent-regions-wrapper .talent-external-links .item-wrapper {
      background: #400095;
      margin-top: 10px; }
      .path-find-a-job .talent-regions-wrapper .talent-external-links .item-wrapper:hover {
        background: #4E535A; }
    .path-find-a-job .talent-regions-wrapper .talent-external-links a {
      color: #ffffff;
      margin: auto;
      text-decoration: none;
      font-family: "SourceSansPro-Semibold";
      text-align: center;
      line-height: 24px;
      font-size: 18px;
      font-weight: 100;
      display: block;
      width: 100%; }
      @media (max-width: 991.98px) {
        .path-find-a-job .talent-regions-wrapper .talent-external-links a {
          padding: 0px;
          color: #009DD6;
          margin: 0px;
          text-align: left; } }
    .path-find-a-job .talent-regions-wrapper .talent-external-links .link-item {
      font-size: 18px;
      display: table-cell;
      vertical-align: middle;
      height: 75px;
      text-align: center;
      position: relative;
      font-family: "Source Sans Pro";
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 50px;
      width: 270px;
      color: #FFFFFF; }
      .path-find-a-job .talent-regions-wrapper .talent-external-links .link-item .fas {
        color: #ffffff;
        margin: auto 5px auto auto;
        position: absolute;
        right: 11px;
        top: 30px; }

.path-find-a-job .talent-regions-wrapper {
  position: absolute;
  left: 50%;
  z-index: 2002;
  min-height: 400px;
  max-width: 600px;
  width: 100%;
  top: 30px;
  transform: translate(-50%, 0);
  border-radius: 3px; }
  .path-find-a-job .talent-regions-wrapper .modal-content {
    border: none; }

.path-find-a-job .dark-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  width: 100vw;
  height: 100vh;
  background-color: #00000085; }

@media only screen and (max-width: 991px) {
  .path-find-a-job .talent-regions-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translate(0, 0);
    height: 100%;
    width: 100%;
    border-radius: 0;
    max-width: 100%; }
    .path-find-a-job .talent-regions-wrapper .modal-content {
      width: 100%;
      height: auto;
      max-width: none;
      border-radius: 0; } }

@media screen and (max-width: 991px) and (max-height: 600px) {
  .path-find-a-job .talent-regions-wrapper .modal-body {
    background: #fff;
    padding-bottom: 120px; } }

.node--type-business-page .featured-top .field--name-field-banner-image:after {
  background: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.0001)), color-stop(27.64%, rgba(0, 0, 0, 0.0001)), color-stop(54.49%, rgba(0, 0, 0, 0.884634)), to(#000000));
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 27.64%, rgba(0, 0, 0, 0.884634) 54.49%, #000000 100%);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 27.64%, rgba(0, 0, 0, 0.884634) 54.49%, #000000 100%);
  opacity: 0.95; }

.node--type-business-page .featured-top .field--name-field-banner-logo-small img {
  max-width: 156px !important;
  width: 100%;
  height: auto; }

.business-content-card {
  padding: 70px 0; }
  .business-content-card .business-width-wrap {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .business-content-card .business-width-wrap .busienss-content-info {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41%;
      flex: 0 0 41%; }
      .business-content-card .business-width-wrap .busienss-content-info h4 {
        font-family: 'sharpSans-Medium';
        font-size: 40px;
        line-height: 48px;
        color: #2A2A2A;
        margin-bottom: 16px;
        position: relative; }
      .business-content-card .business-width-wrap .busienss-content-info .border-line {
        display: inline-block;
        width: 96px;
        height: 4px;
        margin-bottom: 32px; }
      .business-content-card .business-width-wrap .busienss-content-info p {
        font-size: 18px;
        line-height: 30px;
        color: #2A2A2A;
        padding-bottom: 25px;
        margin: 0; }
        .business-content-card .business-width-wrap .busienss-content-info p:last-child {
          padding-bottom: 0; }
    .business-content-card .business-width-wrap .business-img-wrap {
      position: relative;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 54%;
      flex: 0 0 54%;
      background-color: #000;
      border-radius: 16px; }
    .business-content-card .business-width-wrap .video-wrap {
      position: relative;
      cursor: pointer; }
      .business-content-card .business-width-wrap .video-wrap::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: url("https://stg.nbcunicareers.com/themes/custom/nbcucareers/images/svg/Icon_Video.svg") no-repeat;
        width: 64px;
        height: 64px;
        z-index: 10; }
      .business-content-card .business-width-wrap .video-wrap .content-banner-img {
        opacity: 0.5; }
    .business-content-card .business-width-wrap .content-banner-img {
      width: 100%;
      border-radius: 16px; }
    .business-content-card .business-width-wrap .business-talent-btn {
      display: inline-block;
      font-size: 18px;
      font-family: "SourceSansPro-Regular";
      font-weight: 600;
      color: #FFFFFF;
      line-height: 30px;
      text-decoration: none;
      text-align: center;
      background: #000;
      padding: 7px 20px; }
      .business-content-card .business-width-wrap .business-talent-btn .fa-arrow-right {
        padding-left: 10px;
        font-weight: 500;
        font-size: 18px; }

.business-video-popup .modal-dialog {
  max-width: 650px; }
  .business-video-popup .modal-dialog .modal-content {
    border: none;
    background: none; }
    .business-video-popup .modal-dialog .modal-content .modal-body .video-js {
      height: 350px;
      width: 100%; }
  .business-video-popup .modal-dialog .modal-header {
    border: none; }
  .business-video-popup .modal-dialog .close {
    cursor: pointer;
    padding: 2px 5px;
    line-height: 20px;
    font-size: 40px;
    background: #ffffff;
    z-index: 999999;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5; }

.business-open-position-section .position-title {
  font-family: 'sharpSans-Medium';
  font-size: 40px;
  line-height: 48px;
  color: #2A2A2A;
  padding: 40px 0; }

.business-open-position-section .open-positions-main-wrapper {
  margin-top: 0 !important;
  padding: 0 !important; }

.business-open-position-section h1.main-title {
  margin-top: 0;
  margin-bottom: 0 !important; }

@media (min-width: 1200px) {
  .business-open-position-section .open-positions-main-wrapper {
    padding-top: 40px !important; } }

@media (max-width: 1199.98px) {
  .node--type-business-page .featured-top .field--name-field-banner-info-text .h2 {
    letter-spacing: 1.4px; }
  #business-page-react-main-wrapper .business-page-react-main-wrapper .open-positions-main-wrapper.container-fluid-lg {
    padding: 0 !important; } }

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding-top: 100px; } }

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .business-video-popup .modal-dialog {
    max-width: 768px; }
    .business-video-popup .modal-dialog .close {
      margin-right: 0px; }
    .business-video-popup .modal-dialog .modal-content .modal-body .video-js {
      height: 414px; } }

@media (max-width: 991.98px) {
  .node--type-business-page .featured-top {
    margin-bottom: 0; }
    .node--type-business-page .featured-top .banner-content-wrapper-top {
      height: auto; }
      .node--type-business-page .featured-top .banner-content-wrapper-top .banner-content-wrapper {
        height: auto;
        padding-top: 27px;
        padding-left: 32px;
        padding-bottom: 27px; }
    .node--type-business-page .featured-top .field--name-field-banner-image.mobile_img {
      height: 300px; }
    .node--type-business-page .featured-top .field-collection-item--name-field-banner-content .content .field--name-field-banner-image::after {
      background: -webkit-gradient(linear, right top, left top, color-stop(-42%, rgba(0, 0, 0, 0.0001)), color-stop(0.64%, rgba(0, 0, 0, 0.0001)), color-stop(0.49%, rgba(0, 0, 0, 0.884634)), to(#000000));
      background: -o-linear-gradient(right, rgba(0, 0, 0, 0.0001) -42%, rgba(0, 0, 0, 0.0001) 0.64%, rgba(0, 0, 0, 0.884634) 0.49%, #000000 100%);
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) -42%, rgba(0, 0, 0, 0.0001) 0.64%, rgba(0, 0, 0, 0.884634) 0.49%, #000000 100%);
      opacity: 0.35; }
    .node--type-business-page .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small {
      left: 32px; }
      .node--type-business-page .featured-top .field-collection-item--name-field-banner-content .content .banner-content-wrapper-top .field--name-field-banner-logo-small img {
        width: 100% !important;
        height: auto; }
  .business-content-card {
    padding: 40px; }
    .business-content-card .business-width-wrap {
      max-width: 100%;
      padding: 0;
      width: 100%;
      margin: 0 auto;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      .business-content-card .business-width-wrap .business-img-wrap {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 40px; }
    .business-content-card .business-width-wrap.reverse-column {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
      .business-content-card .business-width-wrap.reverse-column .busienss-content-info {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%; }
        .business-content-card .business-width-wrap.reverse-column .busienss-content-info .border-line {
          margin-bottom: 20px; } }

@media (max-width: 767.98px) {
  .node--type-business-page .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding: 32px 22px; }
  .node--type-business-page .featured-top .field--name-field-banner-info-text .h2 {
    letter-spacing: 0.02em; }
  .node--type-business-page .featured-top .field--name-field-banner-info-text .cd-intro .cd-words-wrapper {
    padding-bottom: 0; }
  .node--type-business-page .featured-top .field--name-field-banner-image.mobile_img {
    height: 320px; }
  .business-content-card {
    padding: 40px 20px 30px; }
    .business-content-card .business-width-wrap .busienss-content-info h4 {
      font-size: 26px; }
    .business-content-card .business-width-wrap .busienss-content-info .border-line {
      margin-bottom: 24px; }
  .business-video-popup .modal-dialog .modal-content .modal-body .video-js {
    height: 205px; }
  .business-open-position-section .position-title {
    display: none; } }

@media only screen and (max-width: 400px) {
  .business-video-popup .modal-dialog .modal-content .modal-body .video-js {
    height: 184px; } }

.node--type-talent-community-page .featured-top .field--name-field-banner-image.desktop {
  height: 775px !important; }

.node--type-talent-community-page .featured-top .field--name-field-banner-image img {
  margin: 0 !important;
  object-fit: cover; }

.node--type-talent-community-page .featured-top .field--name-field-banner-image:after {
  background: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.0001)), color-stop(27.64%, rgba(0, 0, 0, 0.0001)), color-stop(54.49%, rgba(0, 0, 0, 0.884634)), to(#000000));
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 27.64%, rgba(0, 0, 0, 0.884634) 54.49%, #000000 100%);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 27.64%, rgba(0, 0, 0, 0.884634) 54.49%, #000000 100%);
  opacity: 0.95; }

.node--type-talent-community-page .site-footer {
  margin-top: 0; }

.talent-banner-wrapper .talent-main-wrapper {
  width: 46%; }
  .talent-banner-wrapper .talent-main-wrapper .heading {
    font-size: 60px;
    line-height: 74px;
    color: #FFFFFF;
    font-family: 'sharpsans-light'; }
  .talent-banner-wrapper .talent-main-wrapper .field--name-field-banner-desc {
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
    font-family: 'SourceSansPro-Regular';
    margin: 24px 0; }
  .talent-banner-wrapper .talent-main-wrapper .talent-btn {
    background: #FFFFFF;
    padding: 7px 20px;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    border: none;
    font-family: 'SourceSansPro-Semibold';
    margin-top: 8px; }
    .talent-banner-wrapper .talent-main-wrapper .talent-btn .fas {
      font-size: 16px;
      padding-left: 10px; }
    .talent-banner-wrapper .talent-main-wrapper .talent-btn:active {
      background-color: #FFFFFF !important;
      color: #000000 !important; }

@media (max-width: 991.98px) {
  .node--type-talent-community-page .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding: 40px 30px; }
  .talent-banner-wrapper .talent-main-wrapper {
    width: 100%; } }

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .node--type-talent-community-page .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding-top: 45px; } }

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .node--type-talent-community-page .featured-top .field--name-field-banner-image.mobile_img {
    background-size: auto; } }

@media (max-width: 767.98px) {
  .node--type-talent-community-page .featured-top .banner-content-wrapper-top .banner-content-wrapper {
    padding: 32px 22px; }
  .talent-banner-wrapper .talent-main-wrapper .heading {
    font-size: 42px;
    line-height: 52px; } }

/* GENERAL STYLES
-------------------------------------------------*/
body {
  font-family: "SourceSansPro-Regular";
  background: #FFFFFF !important; }

/* body.modal-open {
	padding-right: 0px !important;
} */
.navbar-collapse {
  font-family: "sharpSans-Semibold"; }

/* COLORS
--------------------------------------------------*/
a {
  color: #2A2A2A; }

.btn {
  font-size: 18px;
  border-radius: 0px; }

.bg-inverse {
  background: #2A2A2A !important; }

.site-footer {
  background: #EEEEEE	 !important;
  margin-top: 80px;
  position: relative; }

.styles_overlay__CLSq-, .modal-backdrop {
  background: rgba(255, 255, 255, 0.9); }

@media (max-width: 1199.98px) {
  .styles_overlay__CLSq- {
    padding: 2.2rem; } }

.mobile_logo_small {
  display: none; }

.menu--main li a {
  color: #2A2A2A; }

.menu--account li a {
  color: #2A2A2A; }

.site-name-slogan a {
  text-decoration: none; }

.card-group img {
  margin: 0;
  width: 100%;
  height: auto; }

.card-group .field--type-image {
  margin: 0; }

.slick {
  width: 100%; }

.slick > div {
  margin: 0 15px; }

.container {
  max-width: 1280px; }

.extra-width {
  max-width: 1440px; }

.header, .highlighted, .featured-top, #main-wrapper, .container-fluid-xl {
  margin: 0 auto; }

.container-fluid-xl {
  max-width: 1440px; }

.featured-top {
  margin-bottom: 55px; }

.site-footer .container-fluid, .site-footer .container-sm, .site-footer .container-md, .site-footer .container-lg, .site-footer .container-xl, .container-fluid-lg {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0px; }
  @media (max-width: 1199.98px) {
    .site-footer .container-fluid, .site-footer .container-sm, .site-footer .container-md, .site-footer .container-lg, .site-footer .container-xl, .container-fluid-lg {
      padding: 0px 20px; } }

.container-fluid-md {
  max-width: 991px;
  margin: 0 auto;
  padding: 20px; }

#main-wrapper .container-fluid, #main-wrapper .container-sm, #main-wrapper .container-md, #main-wrapper .container-lg, #main-wrapper .container-xl {
  padding: 0px; }

#main-wrapper .container-fluid > .row, #main-wrapper .container-sm > .row, #main-wrapper .container-md > .row, #main-wrapper .container-lg > .row, #main-wrapper .container-xl > .row {
  margin: 0px; }

.text-heading {
  color: #009DD6; }

.text-black {
  color: #000000; }

.video-thumbnail {
  position: relative;
  cursor: pointer; }
  .video-thumbnail:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: url("../images/svg/Icon_Video.svg");
    width: 75px;
    color: #fff;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
  .video-thumbnail:hover:before {
    color: #eee; }

#back-to-top {
  position: absolute;
  bottom: 30%;
  right: 40px;
  z-index: 9900;
  text-align: center;
  line-height: 30px;
  color: #444;
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  text-decoration: none;
  transition: opacity 0.2s ease-out; }

.btn-light.disabled, .btn-light:disabled {
  color: #FFFFFF;
  background-color: #929599; }

.video-js:not(.vjs-fullscreen).vjs-layout-tiny .vjs-subs-caps-button,
.video-js:not(.vjs-fullscreen).vjs-layout-x-small:not(.vjs-live) .vjs-subs-caps-button,
.video-js:not(.vjs-fullscreen).vjs-layout-x-small:not(.vjs-liveui) .vjs-subs-caps-button {
  display: block !important; }

.vjs-layout-tiny .vjs-menu-button-popup .vjs-menu .vjs-menu-content, .vjs-layout-x-small .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 14em; }

@media only screen and (max-width: 5000px) {
  #back-to-top.show {
    display: block; } }

.home-internship-talent-banner {
  margin: 40px 0 0;
  background: #F8F8F8;
  border-bottom: 1px solid #F1F1F1;
  padding: 40px 0; }

.talent-community-popup {
  display: flex;
  position: fixed;
  bottom: 12px;
  right: 24px;
  border-radius: 12px;
  border: 1px solid #000000;
  background: #FFFFFF;
  z-index: 9900; }
  .talent-community-popup .close-talent {
    position: absolute;
    top: -5px;
    left: -5px;
    cursor: pointer; }
  .talent-community-popup .talent-community-popup-link {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    color: #000000;
    padding: 8px 12px;
    text-decoration: none;
    cursor: pointer; }
    .talent-community-popup .talent-community-popup-link img {
      margin-right: 8px; }
    .talent-community-popup .talent-community-popup-link .fas {
      margin: 2px 0 0 8px;
      font-size: 15px;
      line-height: 16px;
      color: #2a2a2a;
      font-weight: 900; }

.cookie-check {
  display: none; }

.talent-regions-wrapper {
  position: absolute;
  left: 50%;
  z-index: 9991;
  min-height: 400px;
  max-width: 600px;
  width: 100%;
  top: 30px;
  transform: translate(-50%, 0);
  border-radius: 3px;
  color: #2a2a2a; }
  .talent-regions-wrapper .modal-content {
    border: none; }
  .talent-regions-wrapper .modal-header {
    padding: 8px 10px;
    text-align: center;
    width: 100%;
    background: #eee;
    border: none; }
    .talent-regions-wrapper .modal-header h2 {
      width: 100%;
      font-weight: 700;
      font-size: 18px;
      margin: 0;
      color: #000; }
  .talent-regions-wrapper h3 {
    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px; }
  .talent-regions-wrapper .modal-body {
    padding: 35px; }
  .talent-regions-wrapper .talent-external-links li {
    list-style: none !important; }
  .talent-regions-wrapper .iframe-wrapper {
    width: 100%;
    min-height: 1400px;
    border: 0px; }
  .talent-regions-wrapper .title {
    text-align: center;
    font-size: 40px;
    margin-top: 75px;
    font-family: "sharpSans-Book"; }
    @media (max-width: 991.98px) {
      .talent-regions-wrapper .title {
        margin-top: 35px;
        font-size: 26px;
        text-align: left; } }
  .talent-regions-wrapper .description {
    font-size: 18px;
    padding: 0 10%;
    margin-top: 30px;
    text-align: center;
    line-height: 30px; }
    @media (max-width: 1199.98px) {
      .talent-regions-wrapper .description {
        padding: 0 50px; } }
    @media (max-width: 991.98px) {
      .talent-regions-wrapper .description {
        padding: 0px;
        text-align: left; } }
  .talent-regions-wrapper .talent-external-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 50px; }
    @media (max-width: 1199.98px) {
      .talent-regions-wrapper .talent-external-links {
        padding: 0 50px; } }
    @media (max-width: 991.98px) {
      .talent-regions-wrapper .talent-external-links {
        padding: 0px; } }
    .talent-regions-wrapper .talent-external-links .item-wrapper {
      background: #400095;
      margin-top: 10px; }
      .talent-regions-wrapper .talent-external-links .item-wrapper:hover {
        background: #4E535A; }
    .talent-regions-wrapper .talent-external-links a {
      color: #ffffff;
      margin: auto;
      text-decoration: none;
      font-family: "SourceSansPro-Semibold";
      text-align: center;
      line-height: 24px;
      font-size: 18px;
      font-weight: 100;
      display: block;
      width: 100%; }
      @media (max-width: 991.98px) {
        .talent-regions-wrapper .talent-external-links a {
          padding: 0px;
          color: #009DD6;
          margin: 0px;
          text-align: left; } }
    .talent-regions-wrapper .talent-external-links .link-item {
      font-size: 18px;
      display: table-cell;
      vertical-align: middle;
      height: 75px;
      text-align: center;
      position: relative;
      font-family: "Source Sans Pro";
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 50px;
      width: 270px;
      color: #FFFFFF; }
      .talent-regions-wrapper .talent-external-links .link-item .fas {
        color: #ffffff;
        margin: auto 5px auto auto;
        position: absolute;
        right: 11px;
        top: 30px; }

.dark-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  width: 100vw;
  height: 100vh;
  background-color: #00000085; }

.talent-banner-section {
  display: flex;
  margin: 0;
  padding: 0; }
  .talent-banner-section .talent-banner-img img {
    height: 100%;
    width: 100%;
    object-fit: cover; }
    @media (max-width: 991.98px) {
      .talent-banner-section .talent-banner-img img {
        height: auto; } }
    @media (max-width: 767.98px) {
      .talent-banner-section .talent-banner-img img {
        height: auto; } }
  .talent-banner-section .talent-banner-content {
    max-width: 480px;
    color: #2A2A2A;
    padding: 40px 30px;
    align-self: center; }
    @media (max-width: 1199.98px) {
      .talent-banner-section .talent-banner-content {
        padding: 50px 30px;
        max-width: 430px; } }
    @media (max-width: 991.98px) {
      .talent-banner-section .talent-banner-content {
        max-width: 100%;
        padding: 48px; } }
    @media (max-width: 767.98px) {
      .talent-banner-section .talent-banner-content {
        max-width: 100%;
        padding: 48px 24px; } }
    .talent-banner-section .talent-banner-content h2 {
      font-size: 40px;
      line-height: 48px;
      font-family: "sharpSans-Medium";
      margin-bottom: 20px;
      padding-right: 45px; }
      @media (max-width: 1199.98px) {
        .talent-banner-section .talent-banner-content h2 {
          padding-right: 15px; } }
      @media (max-width: 991.98px) {
        .talent-banner-section .talent-banner-content h2 {
          font-size: 32px;
          padding-right: 0; } }
    .talent-banner-section .talent-banner-content p {
      font-size: 18px;
      line-height: 30px;
      font-family: "SourceSansPro-Regular";
      margin-bottom: 32px; }
      @media (max-width: 991.98px) {
        .talent-banner-section .talent-banner-content p {
          margin-bottom: 40px; } }
    .talent-banner-section .talent-banner-content .talent-btn {
      background: #000000;
      padding: 7px 20px;
      display: inline-flex;
      align-items: center;
      line-height: 30px; }
      .talent-banner-section .talent-banner-content .talent-btn .fas {
        font-size: 16px;
        padding-left: 10px; }

@media (min-width: 1440px) {
  .talent-banner-section {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto; } }

@media only screen and (max-width: 991px) {
  .talent-community-popup {
    bottom: initial;
    top: 85svh; }
  .talent-regions-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translate(0, 0);
    height: 100%;
    width: 100%;
    border-radius: 0;
    max-width: 100%; }
    .talent-regions-wrapper .modal-content {
      width: 100%;
      height: auto;
      max-width: none;
      border-radius: 0; }
  #back-to-top {
    bottom: 12%; } }

@media screen and (max-width: 991px) and (max-height: 600px) {
  .talent-regions-wrapper .modal-body {
    background: #fff;
    padding-bottom: 120px; } }

@media only screen and (max-width: 767px) {
  .talent-community-popup {
    top: 90svh;
    right: 16px; }
    .talent-community-popup .talent-community-popup-link img {
      width: 72px;
      height: 37px; }
  #back-to-top {
    bottom: 26px; } }

.what_nbcuniversal, .path-culture .slider-for, .path-culture .slider-nav, .node--type-page-program .slider-for, .node--type-page-program .slider-nav {
  display: none !important; }

.what_nbcuniversal.slick-initialized, .path-culture .slider-for.slick-initialized, .path-culture .slider-nav.slick-initialized, .node--type-page-program .slider-for.slick-initialized, .node--type-page-program .slider-nav.slick-initialized {
  display: block !important; }
