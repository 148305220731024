.node--type-page-program {
    .featured-top .field--name-field-banner-image:after {
        background-size: 75% !important;
    }
    #faqs-react-main-wrapper-page-program {
        margin-top: 93px;
        .faqs-main-wrapper {
            .sub-header-title,
            .item-title-hidden h3:hover,
            .accordion-title {
                color: #009DD6;
            }
            .accordion-item-body {
                border-bottom: 2px solid #009DD6;
            }
            .item-title-hidden h3 {
                color: #444;
            }
            .accordion-title h3 {
                background: url(../images/resources/blue-up-arrow.png) no-repeat scroll;
                background-size: 20px 11px;
                -webkit-background-size: 20px 11px;
                background-position: 99.7% 31px;
            }
            .accordion-title.item-title-hidden {
                color: #444;
                border: 0;
                outline: 0;
                background: transparent;
                border-bottom: 1px solid rgba(119, 136, 153, 0.5);
                h3 {
                    padding-right: 40px;
                    font-size: 18px;
                    position: relative;
                    padding: 25px 35px 35px 0;
                    background: url("../images/resources/down-arrow.png") no-repeat scroll;
                    background-size: 20px 20px;
                    -webkit-background-size: 20px 20px;
                    background-position: 99.7% 27px;
                    &:hover {
                        background: url("../images/resources/blue-down-arrow.png") no-repeat scroll;
                        background-size: 20px 11px;
                        -webkit-background-size: 20px 11px;
                        background-position: 99.7% 31px;
                    }
                }
            }
        }
    }
    #block-contentfield-3 {
        position: relative;
    }
    .banner_logos {
        position: absolute;
        bottom: 40px;
        .field__label {
            display: none;
        }
        .field--name-field-banner-logo-small {
            float: left;
            padding-right: 20px;
            border-right: 1px solid #979797;
            position: relative;
            bottom: 0;
            width: 220px;
        }
        .field--name-field-banner-logo-small-right {
            float: left;
            padding-left: 20px;
        }
    }
    .cultureHeaderSection {
        .side-title {
            font-family: "sharpSans-Semibold";
            line-height: 29px;
            font-size: 23px;
            font-weight: 600;
            color: #2A2A2A;
        }
        .title-border {
            width: 75px;
            height: 6px;
            background-color: #009DD6;
        }
    }
    div.brand-page-cont {
        h2 {
            font-family: "sharpSans-Book";
            margin-top: 0px;
            color: #2A2A2A;
            font-size: 40px;
            line-height: 50px;
            font-weight: 300;
        }
        p {
            font-size: 18px;
            color: #2A2A2A;
            margin-top: 10px;
            line-height: 30px;
        }
    }
    .erg_heading {
        p {
            font-size: 18px;
            color: #2A2A2A;
            margin-top: 20px;
            font-family: "SourceSansPro-Regular";
            line-height: 30px;
            text-align: center;
        }
        .employee-resource-groups {
            text-align: center;
            h2 {
                font-size: 40px;
                line-height: 46px;
                margin-top: 74px;
                font-family: "sharpSans-Book";
                margin-bottom: 20px;
            }
        }
    }
    .program_experience {
        width: 70%;
        margin: 0 auto;
        margin-top: 0%;
        .erg_heading {
            .employee-resource-groups {
                h2 {
                    margin-top: 74px;
                }
            }
        }
        .erg_groups {
            margin: 0 auto;
            margin-top: 100px;
            padding-left: 7%;
            .erg_img {
                margin-bottom: 93px;
                padding-right: 0px;
                max-width: 25.2%;
                margin-right: 43px;
            }
            .erg_title {
                padding-left: 0px;
                h4 {
                    font-weight: 600;
                    font-size: 23px;
                    line-height: 25px;
                    margin-bottom: 8px;
                    color: #2A2A2A;
                    font-family: "sharpSans-Semibold";
                    padding-left: 0px;
                }
                p {
                    font-size: 16px;
                    line-height: 30px;
                    color: #2A2A2A;
                }
            }
        }
    }
    .add_marg_top {
        margin-top: 40px;
        display: table;
    }
    .program_alumni {
        .erg_heading {
            .employee-resource-groups {
                h2 {
                    margin-bottom: 40px;
                }
            }
        }
    }
    .culture_sec_banner {
        margin-top: 83px;
        .second_content_culture {
            .second_content_div {
                padding: 16% 50px 7% 100px;
                align-self: unset !important;
                position: relative;
                h4 {
                    font-size: 48px;
                    line-height: 60px;
                    font-family: "sharpSans-Bold";
                    padding-bottom: 40px;
                    font-weight: bold;
                    letter-spacing: 0.53px;
                }
                h2 {
                    font-size: 40px;
                    line-height: 46px;
                    font-family: "sharpSans-Medium";
                    padding-bottom: 20px;
                }
                p {
                    max-height: 180px;
                    margin-bottom: 0px;
                    font-size: 18px;
                    line-height: 30px;
                    color: #fff;
                    overflow: hidden;
                }
                a,
                div.btn {
                    position: absolute;
                    bottom: 70px;
                    height: 45px;
                    padding: 10px 15px;
                    font-size: 17px;
                    line-height: 22px;
                    color: #fff;
                    font-family: "SourceSansPro-Semibold";
                    i {
                        margin-left: 5px;
                    }

                }
                a.apply-button {
                 color: #212529;
                }
                .btn-disabled {
                    border: 1px solid #878787;
                    opacity: 0.9;
                    background: #878788;
                }
            }
        }
        .grey-color {
            background-color: #b9babd;
        }
        .purple-color {
            background-color: #deb932;
        }
        .spotlight_second_img {
            position: relative;
            padding: 0px;
            .spotlight_text_box {
                position: absolute;
                bottom: 40px;
                left: 89px;
                z-index: 9999;
                color: #fff;
                span:first-child {
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 60px;
                    font-family: "sharpSans-Bold";
                    letter-spacing: 0.53px;
                }
                span:last-child {
                    font-size: 42px;
                    font-weight: 300;
                    line-height: 54px;
                    font-family: "sharpSans-Light";
                    letter-spacing: 0.46px;
                }
            }
            img {
                width: 100%;
                height: 100%;
                max-height: 520px;
            }
        }
    }
    .slider-alumni-nav-parent {
        .slick-list {
            width: 85%;
            margin: 0 auto;
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
        .testimonial-nav-main {
            padding: 10px 20px 14px 0px;
            @include media-breakpoint-down(sm) {
                padding: 10px 7.5px 14px 7.5px;
            }
            .user-image {
                position: relative;
                height: 260px;
                vertical-align: middle;
                border: 1px solid #ccc;
                img {
                    width: 100%;
                    height: auto;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                &:before {
					position: absolute;
					top: 50%;
					left: 50%;
					-o-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					-webkit-transform: translate(-50%, -50%);
					content: url(../images/svg/Icon_Video.svg);
					width: 75px;
					color: #fff;
					text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
				}
            }
            .user-details {
                color: #fff;
                text-align: center;
                padding: 2% 4%;
                position: relative;
                min-height: 96px;
                display: table;
                width: 100%;
                height: 102px;
                @include media-breakpoint-down(sm) {
                    min-height: 130px;
                    height: 130px;
                }
                @include media-breakpoint-down(sm) {
                    min-height: 150px;
                    height: 150px;
                }
                .test_UserDetailsubDiv {
                    display: table-cell;
                    vertical-align: middle;
                }
                .user-title {
                    color: #FFFFFF;
                    font-size: 24px;
                    font-family: $font-SourceSans-Regular;
                }
                .user-designation {
                    font-size: 18px;
                    font-family: $font-SourceSans-Semibold;
                    text-transform: uppercase;
                    width: 92%;
                    margin: 0 auto;
                    @include media-breakpoint-down(md) {
                        padding: 10px;
                        font-family: $font-SourceSans-Semibold;
                    }
                }
                i {
                    position: absolute;
                    right: 10px;
                    bottom: 20px;
                    transform: rotate(45deg);
                }
            }
        }
    }
    .testimonial-nav-main:after,
    .testimonial-nav-main:before {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 19px;
        left: 10px;
        width: 50%;
        top: 80%;
        max-width: 300px;
        background: #EEE;
        -webkit-box-shadow: 0 6px 12px #777;
        box-shadow: 0 6px 12px #777;
        -webkit-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        transform: rotate(-3deg);
    }
    .testimonial-nav-main {
        position: relative;
    }
    .testimonial-nav-main:after {
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
        transform: rotate(3deg);
        right: 28px;
        left: auto;
    }
    .hover-testimonial-nav-main .testimonial-nav-main:hover:before,
    .hover-testimonial-nav-main .testimonial-nav-main:hover:after {
        -webkit-box-shadow: 0 8px 9px #777;
        -moz-box-shadow: 0 8px 9px #777;
        box-shadow: 0 8px 9px #777;
    }
    .slick-arrow {
        border-radius: 0px !important;
        height: 20px;
        width: 21px;
        z-index: 1;
        display: none;
    }
    .cr-lavendar {
        background-color: #7F679F;
    }
    .cr-orange {
        background-color: #F36A30;
    }
    .cr-sky-blue {
        background-color: #009DD6; 
    }
    .cr-light-green {
        background-color: #59a63f;
    }
    .slider-alumni-for-parent {
        position: relative;
        width: 750px;
        margin: 0 auto 93px;
        .close-button {
			padding: 0;
			background-color: transparent;
			border: 0;
			font-size: 34px;
			font-weight: 700;
			line-height: 15px;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			opacity: .5;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			z-index: 1800;
			position: absolute;
			right: 60px;
            top: 10px;
		}
        .slider {
            z-index: 1500;
            padding-top: 60px;
        }
        .slick-list {
            width: 630px;
            margin: 0 auto;
        }
        .testimonial-for-main {
            .brightcove-video-main {
				.video-js {
					width: 100%;
					margin: 0 auto;
					height: 355px;
					@include media-breakpoint-down(sm){
						height: 197px;
					}
				}				
			}
            .user-details {
				padding: 2%;
				color: #fff;
                margin: 0;
				@include media-breakpoint-down(sm){
					padding: 20px;
				}
				.user-title {
					font-size: 24px;
					line-height:35px;
					@include media-breakpoint-down(sm){
						font-size: 24px;
						font-family:$font-SourceSans-Regular;
					}
				}
				.user-content {
					border-left: 1px solid #fff;
					font-family:$font-SourceSans-Semibold;
					.user-designation{
						font-size: 18px;	
						line-height:35px;
						text-transform: uppercase;
					}
					.user-des {
						font-size: 18px;
						padding-top: 5px;							
					}
				}
			}
			.cr-orange {
				background-color: #F36A30;
			}
			.cr-sky-blue {
				background-color: #009DD6; 
			}
			.cr-lavendar {
				background-color: #7F679F;
			}
            .cr-light-green {
                background-color: #59a63f;
            }
        }
    }
    .slick-arrow:hover {
        border: 1px solid #FFF;
    }
    .slick-arrow::before {
        content: ' ' !important;
        font-size: 0px !important;
    }
    .slick-next.slick-arrow {
        margin-right: 0;
    }
    .slick-next {
        right: 0;
    }
    .slick-prev {
        left: 0;
    }
    .slick-prev.slick-arrow {
        margin-left: 0;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        border: 1px solid transparent;
        background-color: transparent;
        // display: block;
        background-size: 100% 100% !important;
        background-repeat: no-repeat;
    }
    .carousel-control-next-icon {
        background-image: url(../images/icons/carousel-right.png) !important;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .carousel-control-next-icon:hover {
        background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
        -webkit-transform: scale(1.2);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: scale(1.2);
        /* IE 9 */
        transform: scale(1.2);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .carousel-control-prev-icon {
        background-image: url(../images/icons/carousel-left.png) !important;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .carousel-control-prev-icon:hover {
        background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
        -webkit-transform: scale(1.2);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: scale(1.2);
        /* IE 9 */
        transform: scale(1.2);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .desktop-hide {
        display: none;
    }
}

// media quieries Extra small devices
@media only screen and (max-width: 600px) {
    .node--type-page-program {
        .desktop-hide {
            display: block;
            margin-top: 0px !important;
        }
        .mobile-hide {
            display: none;
        }
        .erg_heading {
            .employee-resource-groups {
                h2 {
                    font-size: 26px;
                    line-height: 26px;
                    margin-top: 20px;
                }
            }
        }
        .program_experience {
            width: 100%;
            padding: 0px;
            .erg_groups {
                padding: 0px 10px;
                .erg_img {
                    margin-bottom: 30px;
                    img {
                        width: 100%;
                    }
                }
                .card-body {
                    img {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }
            }
            .erg_heading {
                .employee-resource-groups {
                    h2 {
                        margin-top: 20px;
                    }
                }
                p {
                    padding: 0px 10px;
                }
            }
        }
        .testimonial-for-main {
            .user-details {
                padding: 47px 20px 20px 20px !important;
                display: inline-block !important;
                width: 100% !important;
                .user-image {
                    width: 100% !important;
                    height: auto !important;
                    img {
                        height: auto !important;
                    }
                }
                .user-content {
                    width: 100% !important;
                    margin-left: 0px !important;
                }
            }
        }
        .program_alumni {
            padding: 0px 10px;
            .erg_heading .employee-resource-groups h2 {
                margin-top: 40px;
            }
        }
        .employee-resource-groups {
            font-size: 26px;
            line-height: 26px;
        }
        .culture_sec_banner {
            margin-top: 40px;
            margin-bottom: 0px;
            .mobile-hide {
                display: block;
            }
            .second_content_culture {
                .second_content_div {
                    padding: 30px 15px 35px 15px !important;
                    width: 100%;
                    height: auto;
                    min-height: 300px;
                    h4 {
                        font-size: 34px;
                        line-height: 40px;
                    }
                    .text-white {
                        padding-bottom: 10px !important;
                    }
                    a {
                        position: unset;
                    }
                }
            }
        }
        .spotlight_text_box {
            bottom: 28px !important;
            left: 10px !important;
            span:first-child {
                font-size: 42px !important;
                line-height: 54px !important;
            }
            span:last-child {
                font-size: 38px !important;
                line-height: 48px !important;
            }
        }
        .slider-alumni-for-parent {
            .close-button {
                top: 10px !important;
                right: 11px !important;
            }
        }
        .brand-page-cont {
            h2 {
                font-size: 30px !important;
                line-height: 42px !important;
            }
        }
        .card-body {
            padding: 0px;
        }
        .mobile_logo_small {
            position: absolute;
            bottom: 54px;
            left: 26px;
            z-index: 999;
        }
        .erg_groups.desktop-hide {
            .collapse.show {
                @include media-breakpoint-down(md) {
                    border-bottom: 2px solid #f06e37;
                    border-top: 1px solid #ffffff;
                    top: -1px;
                    position: relative;
                }
            }
        }
        .erg_groups .accordion .card-header {
            padding: 4px 0px 5px 0px;
            background-color: #fff !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            overflow: auto;
            a.collapsed {
                color: #212529;
                span.arrow {
                    float: right;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    position: relative;
                    border-radius: 2px;
                    -webkit-transform: rotate(-135deg);
                    -o-transform: rotate(-135deg);
                    transform: rotate(-135deg);
                    background: #fff;
                    margin-top: 5px;
                    border-top: 3px solid #ccc;
                    border-left: 3px solid #ccc;
                }
            }
            a {
                font-size: 18px;
                font-family: $font-sharpSans-Semibold;
                padding: 10px 0px;
                color: #F36A30;
                z-index: 500;
                width: 100%;
                float: left;
                text-decoration: none;
                span.arrow {
                    float: right;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    position: relative;
                    right: 3px;
                    border-radius: 2px;
                    -webkit-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    margin-top: 9px;
                    border-top: 3px solid #F36A30;
                    border-left: 3px solid #F36A30;
                }
            }
        }
    }
}


/* Small devices (portrait tablets and large phones ) */

@media only screen and (min-width: 600px) {}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 676px) and (max-width: 991px) {
	.node--type-page-program {
		.slider-alumni-nav-parent{
			.slider-nav{
				.slick-prev, .slick-next, .slick-arrow {
					display: none !important;
				}
			}
		}
	}	
}
@media only screen and (min-width: 992px) {
    .node--type-page-program {
        .carousel-control-next-icon, .carousel-control-prev-icon {
            display:block;
        }
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .node--type-page-program {
        .slider-alumni-nav-parent {  
            .slick-list {
                width: 85%;
            }
            .slick-next {
				right: 10px;
			}
			.slick-prev {
				left: 10px;
			}
        }
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .node--type-page-program {
        .featured-top .field--name-field-banner-content-para .field--name-field-banner-info-text {
            width: 60%;
        }
        .program_experience {
            width: 100%;
            .erg_heading {
                .employee-resource-groups {
                    h2 {
                        margin-top: 40px;
                    }
                }
            }
            .erg_groups {
                padding-left: 0%;
                margin-top: 50px;
                .accordion {
                    .card-body {
                        padding: 15px 0px 0px;
                    }
                }
            }
            .mobile-hide {
                display: none;
            }
            .desktop-hide {
                display: block;
            }
            .erg_groups.desktop-hide {
                .collapse.show {
                    @include media-breakpoint-down(md) {
                        border-bottom: 2px solid #f06e37;
                        border-top: 1px solid #ffffff;
                        top: -1px;
                        position: relative;
                    }
                }
            }
            .erg_groups .accordion .card-header {
                padding: 4px 0px 5px 0px;
                background-color: #fff !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                overflow: auto;
                a.collapsed {
                    color: #212529;
                    span.arrow {
                        float: right;
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        position: relative;
                        border-radius: 2px;
                        -webkit-transform: rotate(-135deg);
                        -o-transform: rotate(-135deg);
                        transform: rotate(-135deg);
                        background: #fff;
                        margin-top: 5px;
                        border-top: 3px solid #ccc;
                        border-left: 3px solid #ccc;
                    }
                }
                a {
                    font-size: 18px;
                    font-family: $font-sharpSans-Semibold;
                    padding: 10px 0px;
                    color: #F36A30;
                    z-index: 500;
                    width: 100%;
                    float: left;
                    text-decoration: none;
                    span.arrow {
                        float: right;
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        position: relative;
                        right: 3px;
                        border-radius: 2px;
                        -webkit-transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        transform: rotate(45deg);
                        margin-top: 9px;
                        border-top: 3px solid #F36A30;
                        border-left: 3px solid #F36A30;
                    }
                }
            }
        }
        .slider-alumni-for-parent {
            .testimonial-for-main {
                .user-details {
                    .user-image {
                        width: 200px;
                        img {
                            height: auto;
                        }
                    }
                }
            }
        }
        .culture_sec_banner {
            .second_content_culture {
                .second_content_div {
                    padding: 5% 50px 27% 100px;
                }
            }
        }
        #faqs-react-main-wrapper-page-program {
            padding: 0px 20px;
        }
        .card-body {
            p {
                margin-top: 20px;
            }
        }
        // .slick-next {
        //     right: -50px;
        //     display: none !important;
        // }
        // .slick-prev {
        //     left: -50px;
        //     display: none !important;
        // }
        // .node--type-page-program {
        //     .slider-alumni-for-parent {
        //         .slick-prev {
        //             left: 0;
        //             display: block !important;
        //         }
        //         .slick-next {
        //             right: 0;
        //             display: block !important;
        //         }
        //     }
        // }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .node--type-page-program {
        .slider-alumni-for-parent {
            width: 100%;
            // .carousel-control-prev-icon {
            //     left: 0;
            //     display: block !important;
            // }
            // .carousel-control-next-icon {
            //     right: 0;
            //     display: block !important;
            // }
        }
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media screen and (min-width: 1200px) {
    .node--type-page-program {
        .culture_sec_banner {
            .spotlight_second_img {
                img {
                    max-height: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .node--type-page-program {
        .slider-alumni-for-parent {
            .testimonial-for-main {
                .brightcove-video-main {
                    .video-js {
                        height: 180px !important;
                    }				
                }
            }
        }
    } 
}

@media only screen and (max-width: 767px) {
    .node--type-page-program {
        .erg_groups .accordion .card-header {
            padding: 4px 0px 5px 0px;
            background-color: #fff !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            overflow: auto;
            a.collapsed {
                color: #212529;
                span.arrow {
                    float: right;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    position: relative;
                    border-radius: 2px;
                    -webkit-transform: rotate(-135deg);
                    -o-transform: rotate(-135deg);
                    transform: rotate(-135deg);
                    background: #fff;
                    margin-top: 5px;
                    border-top: 3px solid #ccc;
                    border-left: 3px solid #ccc;
                }
            }
            a {
                font-size: 18px;
                font-family: $font-sharpSans-Semibold;
                padding: 10px 0px;
                color: #F36A30;
                z-index: 500;
                width: 100%;
                float: left;
                text-decoration: none;
                span.arrow {
                    float: right;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    position: relative;
                    right: 3px;
                    border-radius: 2px;
                    -webkit-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    margin-top: 9px;
                    border-top: 3px solid #F36A30;
                    border-left: 3px solid #F36A30;
                }
            }
        }
        .banner_logos {
            display: none;
        }
        .mobile_logo_small {
            display: block;
        }
        // .featured-top .field--name-field-banner-content-para .field--name-field-banner-info-text {
        // 	display: none;
        // }
        .slider-alumni-for-parent {
            width: 100%;
            padding-top: 35px;
            padding-bottom: 30px;
            .close-button {
                top: 0 !important;
                right: 30px !important;
            }
            .slider {
                padding-top: 35px;
            }
            .slick-list {
                width: 85.5% !important;
                margin: auto;
            }
            .testimonial-for-main {
                .user-details {
                    padding: 20px 10px !important;
                    display: flex !important;
                    margin: 0;
                }
            } 
        }
        .brand-page-cont h2 {
            border-top: none;
        }
        #faqs-react-main-wrapper-page-program {
            padding: 0px 20px;
            margin-top: 40px;
        }
        .featured-top {
            aside {
                padding: 0px;
                section {
                    margin: 0px;
                }
            }
        }
    }
}


.pageprogram_popup {
    .modal-dialog {
        @include media-breakpoint-up(sm) {
            max-width: 650px;
        }
        .modal-content {
            border: none;
            background: none;
            .modal-header {
                border: none;
                .close {
                    cursor: pointer;
                    padding: 2px 5px;
                    line-height: 20px;
                    font-size: 40px;
                    background: #ffffff;
                    z-index: 999999;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    opacity: .5;
                }
            }
            .modal-body {
                .video-js {
                    height: 350px;
                    width: 100%;
                    @include media-breakpoint-down(sm) {
                        height: 205px;
                    }
                }
            }
        }
    }
}