.node-preview-container {
  background: #d1e8f5;
  background-image: -webkit-linear-gradient(top, #d1e8f5, #d3e8f4);
  background-image: linear-gradient(to bottom, #d1e8f5, #d3e8f4);
  font-family: Arial, sans-serif;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3333);
  position: fixed;
  z-index: 499;
  width: 100%;
  padding: 10px;
}
.node-preview-backlink {
  background-color: #419ff1;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #419ff1, #1076d5);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #419ff1, #1076d5); /* LTR */
  border: 1px solid #0048c8;
  border-radius: .4em;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .4);
  color: #fff;
  font-size: 0.9em;
  line-height: normal;
  margin: 0;
  padding: 4px 1em 4px 0.6em; /* LTR */
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
}
[dir="rtl"] .node-preview-backlink {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #419ff1, #1076d5);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #419ff1, #1076d5);
  padding: 4px 0.6em 4px 1em;
  float: right;
}
.node-preview-backlink:focus,
.node-preview-backlink:hover {
  background-color: #419cf1;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #59abf3, #2a90ef);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #59abf3, #2a90ef); /* LTR */
  border: 1px solid #0048c8;
  text-decoration: none;
  color: #fff;
}
[dir="rtl"] .node-preview-backlink:focus,
[dir="rtl"] .node-preview-backlink:hover {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #59abf3, #2a90ef);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #59abf3, #2a90ef);
}
.node-preview-backlink:active {
  background-color: #0e69be;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #0e69be, #2a93ef);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #0e69be, #2a93ef); /* LTR */
  border: 1px solid #0048c8;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .25);
}
[dir="rtl"] .node-preview-backlink:active {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #0e69be, #2a93ef);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #0e69be, #2a93ef);
}
.node-preview-backlink::before {
  content: '';
  width: 10px;
  display: inline-block;
}
