/**
 * @file
 * The visual styles for Bootstrap Barrio's search form(s).
 */

input[type="search"] {
    box-sizing: border-box;
}

header #search-block-form {
    padding: 5px 15px;
    flex-flow: row wrap;
}

#search-block-form {
    display: flex;
}