/* ------------------ Header ------------------ */

#navbar-top {
    padding: 0 1rem;
}

#navbar-top.navbar-dark .nav-link:hover,
#navbar-top.navbar-dark .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

#navbar-top.navbar-light .nav-link:hover,
#navbar-top.navbar-light .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

#navbar-top.navbar-light .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-brand  {
    margin-right: 0;
	padding-left: 40px !important;
	padding-top:0.5125rem;
	@include media-breakpoint-down(lg) {
		padding-left: 20px !important;
	}
	img {
		width: 250px;
		@include media-breakpoint-down(lg) {
			width: 220px;
		}
	}
}
.site-footer .navbar-brand  {
	@include media-breakpoint-down(lg) {
		padding-left: 0px !important;
	}
}
.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
#block-findajob {
	font-size: 18px;
	padding: 30px;
	a {
		text-decoration: none;
	}
	@include media-breakpoint-up(lg) {
		cursor:pointer;
		background-color: #400095;
		margin-left:20px;
		&:hover {
			background-color: #4E535A;
		}
		max-width: 185px;
		width:21%;
		height: 75px;
		padding: 0;
		p {
			margin-bottom: 0px;
			text-align: center;
			a {
				padding: 22px;
				display:block;
				color: #FFFFFF;
				text-decoration: none;
			}
		}
	}
}
#navbar-main {
	padding: 0px;
	background-color: #FFFFFF !important;
	@include media-breakpoint-down(md) {
		height: 70px !important;
	}
	#CollapsingNavbar {
		#block-nbcucareers-main-menu {
			margin-left: auto;
			width: 82%;
			@include media-breakpoint-down(lg) {
				width: 100%;
				padding-left:0px;
				padding-right:0px;
			}
			@include media-breakpoint-down(md) {
				background-image: url(../images/Typography_Art_Gray.png);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: right bottom;
				overflow: scroll;
			}
			ul .nav-link {
				position: relative;
				display: inline-block;
				font-size: 17px;
				padding: 0px;
				margin: 0.5rem 0rem;
				color: #2A2A2A;
				/*border-bottom: 2px solid transparent;*/
				@include media-breakpoint-down(md) {
					font-size: 20px;
					font-family: $font-sharpSans-Semibold;
					margin:0px;
					padding:10px 0px;
					width:100%;
				}
			}
		}
		a .fa-times, span.fa-times, .mobileSocialLink {display:none}
	}
	@include media-breakpoint-down(md) {
		height: 50px;
		a.navbar-brand {
			z-index:100;
			padding-top:25px;
			padding-left:15px;
			@include media-breakpoint-down(md) {
				padding-top:13px;
			}
		}
		#CollapsingNavbar {
			z-index: 10; /* to position it in front of the other content */
			position: fixed;
			overflow: hidden; /* to prevent scrollbar appearing */
			left: 0;
			top: 0px;
			width: 100%;
			z-index: 0;
		    height: 100%;
			min-height: 0px;
			max-height: 0%;
			visibility: visible;
			a .fa-times, span.fa-times {
				float: right;
				display: block;
				position: fixed;
				top: 10px;
				right: 5px;
				z-index: 1500;
				font-size: 32px;
				padding: 10px 10px;
				height: 40px;
					line-height: 20px;
				}
			.mobileSocialLink {
				padding-bottom: 20px;
				padding-top: 25px;
				display: block;
				ul {
					li {
						margin-right:15px;
						a {
							display: inline !important;
						}
					}
					li:first-child {
						margin-left:15px;
					}
				}
			}
		}
		.navigationParent {
			position: fixed;
			top: 0px;
			border: 0px;
			right: 0px;
			width: 100%;
			height: 100%;
		    padding-right: 20px;
			-webkit-transform: translate3d(-100vw,0,0);
			-ms-transform: translate3d(-100vw,0,0);
			transform: translate3d(-100vw,0,0);
			-webkit-transition: 700ms cubic-bezier(0.23,1,0.32,1);
			transition: 700ms cubic-bezier(0.23,1,0.32,1);
			background: #0079C0;

		}
		.navigation {
			height: 100%;
			position: fixed;
			top: 0;
			bottom: 0px;
			right: 0px;
			width: 98% !important;
			z-index: 100;
		    padding: 20px;
		    padding-top:10px;
		    background: #EEEEEE;
		   -webkit-transform: translate3d(-100vw,0,0);
			-ms-transform: translate3d(-100vw,0,0);
			transform: translate3d(-100vw,0,0);
			-webkit-transition: all cubic-bezier(0.23,1,0.32,1);
			transition: all cubic-bezier(0.23,1,0.32,1);
			-webkit-transition-delay: 200ms;
			transition-delay: 200ms;
			-webkit-transition-duration: 800ms;
			transition-duration: 800ms;
		}

		#CollapsingNavbar .navigation, .navigationParent {
		  transform: translateX(100%);
		  -webkit-transform: translateX(100%);
		}

		#CollapsingNavbar.show .navigation {
		  transform: translateX(0);
		  -webkit-transform: translateX(0);
		  -webkit-transform: translate3d(0,0,0);
			-webkit-transform: translate3d(0,0,0);
			-ms-transform: translate3d(0,0,0);
			transform: translate3d(0,0,0);
			-webkit-transition: 600ms cubic-bezier(0.23,1,0.32,1);
			transition: 600ms cubic-bezier(0.23,1,0.32,1);
			-webkit-transition-delay: 300ms;
			transition-delay: 300ms;
			padding-top: 10px;
		}
		#CollapsingNavbar.show .navigationParent {
			transform: translateX(0);
		    -webkit-transform: translateX(0);
			transform: translate3d(0,0,0);
			-webkit-transition: all cubic-bezier(0.23,1,0.32,1);
			transition: all cubic-bezier(0.23,1,0.32,1);
			-webkit-transition-delay: 100ms;
			transition-delay: 100ms;
			-webkit-transition-duration: 800ms;
			transition-duration: 800ms;
		}
		#block-findajob {
			display: none;
		}
		.bg-img-text-menu{
			z-index: 4;
			position: relative;
			width: 100%;
		}
		.cl-effect-11 {
			margin-top: 0px;
		}
	}
}

.intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.desktop {
	height: auto;
}
.intern-banner-dashboard-main-wrapper .featured-top .field--name-field-banner-image.mobile {
	height: 300px;
}

.featured-top {
		min-height: 675px;
	@include media-breakpoint-down(lg) {
		min-height: 475px;
	}
	@include media-breakpoint-down(md) {
		min-height: 375px;
	}
	@include media-breakpoint-down(sm) {
		min-height: 200px;
	}
	&.show-only-for-mobile {
		display: none;
		@include media-breakpoint-down(md) {
			display: block;
			min-height: auto;
		}
	}
	.block-fieldblock {
		width: 100%;
	}
	.field--name-field-banner-image {
		&.desktop {
		  display: block;
		  height: 775px;
		}
		&.desktop_image_display {
			display: none;	
		}
		@media (prefers-reduced-motion) {
			&.desktop_image_display {
			  display: block;
			}
			&.desktop_video_display {
				display: none;
			}
		}
		&.mobile {
		  display: none;
		}
		@include media-breakpoint-down(md) {
		  &.desktop {
			display: none;
		  }
		  &.mobile {
			display: block;
			height: auto;
			background-size: cover;
			background-position: center;
		  }
		  &.mobile_img {
			  height: 300px;
		  }
		  &.mobile_image_display {
			display: none;	
			}
			@media (prefers-reduced-motion) {
				&.mobile_image_display {
				display: block;
				}
				&.mobile_video_display {
					display: none;
				}
			}
		}
		position: relative;
		img {
			/*max-height: 675px;*/
			max-width: 100%;
			height: auto;
			height: 100%;
			width: 100%;
			min-height: 675px;
			@include media-breakpoint-down(lg) {
				min-height: 475px;
			}
			@include media-breakpoint-down(md) {
				min-height: 375px;
			}
			@include media-breakpoint-down(sm) {
				min-height: 235px;
			}
		}
		.video-js{
			width: 100%;
			height: 100%;
		}
		&.desktop {
			video {
				position: absolute;
				top: 50%;
				left: 50%;
				width: auto;
				height: auto;
				transform: translate(-50%, -50%);
				min-width: 100%;
				min-height: 100%;
			}
		}
		&.mobile {
			video {
				position: relative;
			}
		}
	}
	.field--name-field-banner-image:after {
	  content: " ";
	  display: block;
	  position: absolute;
	  height: 100%;
	  top: 0;
	  left: 0;
	  right: 0;
	  background: url(../images/Hero_gradiant_wht4.png) no-repeat;
	  background-repeat: repeat-y;
	  /*background-size: 75%;
	  opacity:0.85;*/
	  background-size: 100%;
	}
	.banner-content-wrapper-top {
		position: absolute;
		z-index: 99;
		width: 100%;
		height: 100%;
		.banner-content-wrapper {
			max-width: 1240px;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			padding-top: 40px;
			padding-left: 30px;
			padding-bottom: 1px;
			@include media-breakpoint-up(xl) {
				padding-top: 130px;
				padding-left: 0px;
				padding-bottom: 0px;
			}
		}
	}
	.search-form-wrapper {
		width: 46%;
		padding-top: 35px;
		padding-left: 0px;
		font-size: 20px;
		.fa-search, .fa-arrow-right {
			display:none;
			cursor:pointer;
		}
		.input-search {
			width: 100%;
			height: 50px;
			border-top: 0px;
			border-right: 0px;
			border-left: 0px;
			border-bottom: 2px solid #a7a9ac;
			padding-left:50px;
			outline:none;
			color: #2A2A2A;
		}
		.input-search::-ms-clear {
			display: none;
		}
		.input-search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		  color: #2A2A2A;
		  opacity: 1; /* Firefox */
		}

		.input-search:-ms-input-placeholder { /* Internet Explorer 10-11 */
		  color: #2A2A2A;
		}

		.input-search::-ms-input-placeholder { /* Microsoft Edge */
		  color: #2A2A2A;
		}
	}
	.field--name-field-banner-content, .field--name-field-banner-content-para {
		.field--name-field-banner-info-text, .field--name-field-banner-text {
			width: 46%;
			/*position: absolute;
			top: 13%;
			padding-left: 100px;*/
			color: #FFFFFF;
		}
	}
	.field--name-field-banner-logo-small {
		@include media-breakpoint-up(md) {
			position: absolute;
			bottom: 50px;
			z-index: 99;
		}
		@include media-breakpoint-up(xl) {
			position: absolute;
			bottom: 57px;
			z-index: 99;
		}
		img {
			max-width: 200px !important;
			height: 60px;
			margin-bottom: 0px;
		}
	}
	.field--name-field-banner-info-text, .field--name-field-banner-text {
		z-index: 99;
		h2, .h2{
			font-size: 90px;
			line-height: 1;
			font-family: $font-sharpSans-Bold;
			margin-bottom: 0px;
			@include media-breakpoint-down(lg) {
				font-size: 70px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 50px;
				line-height:53px;
			}
		}
		.cd-intro .cd-words-wrapper {
			width:100% !important;
			padding-bottom: 20px;
			color:#fff;
			b {
				font-family: $font-sharpSans-Light;
				font-size: 90px;
				font-weight: normal;
				white-space: pre-wrap;
				line-height: 1;
				@include media-breakpoint-down(lg) {
					font-size: 70px;
				}
				@include media-breakpoint-down(sm) {
					font-size: 50px;
					line-height:53px;
				}
			}
		}
		h3, .h3 {
			font-size: 1.625rem;
			font-family: $font-sharpSans-Medium;
			@include media-breakpoint-down(lg) {
				font-size: 1.625rem;
			}
		}
		.text-heading-3 {
			line-height: 36px;
			@include media-breakpoint-down(md) {
				margin-bottom: 25px !important;
			}
		}
	}
	.search-form {
		position:relative;
		.fa-search {
			display: block;
			position: absolute;
			left: 15px;
			top: 16px;
			width: 20px;
			height: 20px;
			font-size: 21px;
			color: #000;
		}
		.fa-arrow-right {
			display: block;
			position: absolute;
			right:15px;
			top: 16px;
			width: 20px;
			height: 20px;
			font-size: 21px;
			color: #000;
		}
	}
	.field-collection-item--name-field-banner-content {
		.content {
			position:relative;
		}
	}
	@include media-breakpoint-down(md) {
		.field-collection-item--name-field-banner-content {
			.content {
				position:relative;
				border-top:1px solid #ccc;
				.banner-content-wrapper-top {
					position: static;
					z-index: 99;
					width: 100%;
					background:#16181A;
					border-top: 1px solid #ccc;
					.field--name-field-banner-info-text {
						width:100%;
						margin-top: 30px;
						padding-left: 35px;
						@include media-breakpoint-down(md) {
							padding-left: 0px;
						}
						h1,h2,h3 {
							margin-bottom:0rem;
						}
						.cd-headline {
							.cd-words-wrapper {
								width:100% !important;
							}
						}
					}
					.search-form-wrapper {
						width: 100%;
						padding-top: 0px;
						position: absolute;
						top: 0px;
						margin-left: -30px;
					}
					.field--name-field-banner-logo-small {
						position:absolute;
						bottom: 6%;
						left:26px;
						display:none;
						img {
							width:150px  !important;
							height:50px;
						}
					}
					.mobile_logo_small.field--name-field-banner-logo-small {
						display:block;
						z-index:2;
					}
				}
				.field--name-field-banner-image::after {
					opacity:0;
				}
			}
		}
		.paragraph--type--banner-content{
			.content {
				position: relative;
				.banner-content-wrapper-top {
					position: static;
					z-index: 99;
					width: 100%;
					background:#16181A;
					border-top: 1px solid #ccc;
					.field--name-field-banner-logo-small-right {
						display:none;
					}
					.field--name-field-banner-logo-small {
						position:absolute;
						bottom:45px;
						left:26px;
						display:none;
						img {
							width:150px  !important;
							height:50px;
						}
					}
					.mobile_logo_small.field--name-field-banner-logo-small {
						display:block;
						z-index:2;
					}
				}
				.field--name-field-banner-image:after {
					background: unset;
				}
				.field--name-field-banner-info-text {
					width: 100%;
				}
			}
		}

	}
}

/* Banner text color update */
.node--type-careers-dashboard {
	.text-heading {
		color: #D0118C;
	}
}
.node--type-page-program {
	.text-heading {
		color: #deb932;
	}
}
.node--type-media-tech-page{
  .text-heading{
	 color: #009DD6 !important;
  }
  .featured-top {
	  margin-bottom: 29px !important;
  }
}
.node--type-specialized-programs, .node--type-programs-dashboard, .node--type-location-dashboard{
	.banner-content-wrapper .text-heading  {
		color: #00AEEF;
	}
}

.node--type-internships-dashboard , .node--type-benefits-dashboard {
	.banner-content-wrapper .text-heading  {
		color: #896EB1;
	}
}

.node--type-brand-dashboard {
	.banner-content-wrapper .text-heading  {
		color: #59A63F;
	}
}
.node--type-culture-dashboard, .node--type-faq-dashboard{
	.banner-content-wrapper .text-heading  {
		color: #F36A30;
	}
}

/* CSS for sliding in the underline from the left and sliding it out to the right */
.cl-effect-11 {
	@include media-breakpoint-down(md) {
		margin-top:20px;
	}
	.nav-item {
		padding: 0px;
		@include media-breakpoint-down(md) {
			text-align: left;
			padding-left: 15px;
		}
	}
	.nav-item-find-a-job {
		a {
			color: #007EAB !important;
		}
	}
	@include media-breakpoint-up(lg) {
		.nav-item-careers {
			.nav-link::before, .nav-link.is-active {
				border-bottom: 5px solid #D0118C;
			}
		}
		.nav-item-programs, .nav-item-locations{
			.nav-link::before, .nav-link.is-active  {
				border-bottom: 5px solid #009DD6;
			}
		}
		.nav-item-programs.sub-menu-item--active{
			.nav-link  {
				border-bottom: 5px solid #009DD6;
			}
		}

		.nav-item-internships, .nav-item-benefits {
			.nav-link::before, .nav-link.is-active  {
				border-bottom: 5px solid #896EB1;
			}
		}

		.nav-item-brands {
			.nav-link::before, .nav-link.is-active  {
				border-bottom: 5px solid #59A63F;
			}
		}
		.nav-item-culture, .nav-item-faq{
			.nav-link::before, .nav-link.is-active  {
				border-bottom: 5px solid #F36A30;
			}
		}
		.nav-item-find-a-job {
			display: none;
		}
	}
}

.cl-effect-11 a {
	padding: 0;
	color: #0972b4;
	text-shadow: none;
}
.navbar-toggler.navbar-toggler-right {
	/*outline:none;*/
	z-index:2;
}
@include media-breakpoint-up(lg) {
	.cl-effect-11 a::before {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		padding: 0;
		max-width: 0;
		content: attr(data-hover);
		-webkit-transition: max-width 0.5s;
		-moz-transition: max-width 0.5s;
		transition: max-width 0.5s;
	}
}

.cl-effect-11 a:hover::before,
.cl-effect-11 a:focus::before {
	max-width: 100%;
}

/*
.path-find-a-job, .path-all-locations, .path-faq, .path-candidate-privacy-policy, .node--type-page, body.node--type-careers-article .custom-nav-height {
	.header{
		border-bottom: 1px solid #2A2A2A;
	}
}
*/


@media (max-width: 33.9em) {
    .navbar-toggleable-xs .navbar-nav .nav-item+.nav-item,
    .navbar-toggleable-xs.collapse.in .navbar-nav .nav-item {
        margin-left: 0;
        float: none;
    }
}

@media (max-width: 47.9em) {
    .navbar-toggleable-sm .navbar-nav .nav-item+.nav-item,
    .navbar-toggleable-xs.collapse.in .navbar-nav .nav-item {
        margin-left: 0;
        float: none;
    }
}
@media only screen and (max-width: 767px) {
	.featured-top {
		.search-form {
			border-radius: 0px;
			.input-search {
				border-radius: 0px;
			}
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
	.featured-top {
		.field--name-field-banner-image.mobile {
			height: auto;
		}
		.field--name-field-banner-image.mobile_img {
			height: 420px;
		}
		.search-form {
			border-radius: 0px;
			.input-search {
				border-radius: 0px;
			}
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1100px) {
	#block-findajob p a {
		padding: 22px 10px;
	}
	.featured-top {
		.search-form {
			border-radius: 0px;
			.input-search {
				border-radius: 0px;
			}
		}
	}
}
@media only screen and (min-width: 993px){
	#header {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 2000;
	}
	.path-home .featured-top, .path-careers .featured-top, .path-node .featured-top, .path-user-login .layout-main-wrapper,
	.node--type-location-dashboard .layout-main-wrapper, .node--type-faq-dashboard .layout-main-wrapper, .node--type-jobs-search .layout-main-wrapper, .path-candidate-privacy-policy .layout-main-wrapper {
		margin-top: 75px !important;
	}
	/*.user-logged-in {
		#header {
			position: fixed;
			top: 39px;
			width: 100%;
			z-index: 2000;
		}
	}
	.path-home.user-logged-in .featured-top, .path-careers.user-logged-in .featured-top, .path-node.user-logged-in .featured-top {
		margin-top: 114px;
	}
	.node--type-location-dashboard.user-logged-in .layout-main-wrapper, .path-candidate-privacy-policy .layout-main-wrapper, .path-user-login .layout-main-wrapper,
	.node--type-faq-dashboard.user-logged-in .layout-main-wrapper, .node--type-jobs-search.user-logged-in .layout-main-wrapper {
		margin-top: 114px;
	}
	.toolbar-tray-open .toolbar-menu .menu-item--expanded.hover-intent ul, .toolbar-oriented .toolbar-bar {
		z-index:2001;
	}*/
}
@media only screen and (max-width: 992px){
	#header {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 2000;
		background:#fff;
	}
	.path-home .featured-top, .path-careers .featured-top, .path-node .featured-top, .path-user-login .layout-main-wrapper,
	.node--type-location-dashboard .layout-main-wrapper, .node--type-faq-dashboard .layout-main-wrapper, .node--type-jobs-search .layout-main-wrapper,.path-candidate-privacy-policy .layout-main-wrapper {
		margin-top: 70px !important;
	}
	/*.user-logged-in {
		#header {
			position: fixed;
			top: 39px;
			width: 100%;
			z-index: 2000;
			background:#fff;
		}
	}
	.path-home.user-logged-in .featured-top, .path-careers.user-logged-in .featured-top, .path-node.user-logged-in .featured-top {
		margin-top: 109px;
	}
	.node--type-location-dashboard.user-logged-in .layout-main-wrapper, .path-candidate-privacy-policy .layout-main-wrapper, .path-user-login .layout-main-wrapper,
	.node--type-faq-dashboard.user-logged-in .layout-main-wrapper, .node--type-jobs-search.user-logged-in .layout-main-wrapper {
		margin-top: 109px;
	}
	.toolbar-oriented .toolbar-bar {
		z-index:2001;
	}*/
}
@media only screen and (max-width: 767px) {
	.node--type-page-program {
		.featured-top {
			.field--name-field-banner-image.mobile {
				background-size: 100%;
				background-repeat: no-repeat;
				height: 170px;
			}
		}
		.paragraph--type--banner-content {
			.content .banner-content-wrapper-top {
				.field--name-field-banner-logo-small {
					bottom: 17px;
				}
			}
		}
	}
	.node--type-media-tech-page {
		.featured-top {
			.field--name-field-banner-image.mobile {
				display: block;
				height: 300px !important;
				background-size: cover;
				background-position: right;
				&::after {
					opacity: 0;
				}
			}
		}
		.paragraph--type--banner-content {
			.content .banner-content-wrapper-top {
				.field--name-field-banner-logo-small {
					bottom: 15px;
					left: 18px;
					img {
						height: 50px !important;
						padding-right: 10px;
						&:last-child {
							width: 103px !important;
							height: 55px !important;
							border-left: 1px solid #979797;
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
}
/* iphone 6/7/8 Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
	.node--type-page-program {
		.featured-top {
			.field--name-field-banner-image.mobile {
				background-size: 100%;
				background-repeat: no-repeat;
				height: 400px;
			}
		}
		.paragraph--type--banner-content {
			.content .banner-content-wrapper-top {
				.field--name-field-banner-logo-small {
					bottom: 56px;
				}
			}
		}
		.program_experience {
			width: 100% !important;
			.erg_groups {
				padding-left: 0% !important;
				margin-top: 50px !important;
				.erg_img {
					margin-bottom: 50px !important;
					padding-left: 0px;
				}
			}
		}
		.slick-prev {
			left: -35px !important;
		}
		.slick-next {
			right: -35px !important;
		}
	}
}
/* iphone 6/7/8 plus Landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {
	.node--type-page-program {
		.featured-top {
			.field--name-field-banner-image.mobile {
				background-size: 100%;
				background-repeat: no-repeat;
				height: 345px;
			}
		}
		.paragraph--type--banner-content {
			.content .banner-content-wrapper-top {
				.field--name-field-banner-logo-small {
					bottom: 56px;
				}
			}
		}
	}
}
/* iphone 5, 5S, 5C, and 5SE Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
	.node--type-page-program  {
		.featured-top {
			.field--name-field-banner-image.mobile {
				background-size: 100%;
				background-repeat: no-repeat;
				height: 260px;
			}
		}
		.paragraph--type--banner-content {
			.content .banner-content-wrapper-top {
				.field--name-field-banner-logo-small {
					bottom: 72px;
				}
			}
		}
		.program_experience {
			.erg_groups {
				padding-left: 0% !important;
				margin-top: 50px !important;
				.erg_img {
					margin-bottom: 50px !important;
					padding-left: 0px;
				}
			}
		}
	}
}
// tablet, ipad
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {
	.node--type-page-program {
		.content .banner-content-wrapper-top {
			.field--name-field-banner-logo-small {
				bottom: 45px;
			}
		}
		.featured-top  {
			.field--name-field-banner-image.mobile {
				height: 360px;
			}
		}
		.slider-alumni-for-parent  {
			.testimonial-for-main .user-details {
				width: 100% !important;
			}
		}
	}
	.node--type-media-tech-page {
		.featured-top  {
			.field--name-field-banner-image.mobile {
				height: 420px;
				background-size: cover;
				background-position: right;
				display: block;
			}
			.paragraph--type--banner-content {
				.content .banner-content-wrapper-top {
					.field--name-field-banner-logo-small {
						bottom: 30px !important;
						left: 20px !important;
						img {
							width: 150px !important;
							height: 50px !important;
							&:first-child {
								margin-right: 20px;
							}
							&:last-child {
								width: 146px !important;
								height: 77px !important;
								border-left: 1px solid #979797;
								padding-left: 20px;
								position: relative;
								&::before {
									display: block;
									content: '#';
									background: #979797;
									height: 68px;
									width: 1px;
								}
							}
						}
					}
				}
			}
		}
	}
}
/* iphone SE Portrait */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
	.node--type-page-program {
		.featured-top {
			.field--name-field-banner-image.mobile {
				height: 150px;
			}
		}
	}
}
/* ihone 6/7/8 Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
	.node--type-page-program {
		.featured-top {
			.field--name-field-banner-image.mobile {
				height: 175px;
			}
		}
	}
}
/* iphone 6/7/8 pluds Portrait */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {
	.node--type-page-program {
		.featured-top {
			.field--name-field-banner-image.mobile {
				height: 197px;
			}
		}
	}
}
/* samsung galaxy S5 Portrait */
@media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: portrait) {
	.node--type-page-program {
		.featured-top {
			.field--name-field-banner-image.mobile {
				height: 170px;
			}
		}
	}
}
@media only screen
  and (min-device-width: 1440px) {
	.image-banner{
	  height:auto !important;
	}
	.featured-top{
	  .field--name-field-banner-logo-small{
	    bottom: 60px !important;
	  }
	  .banner_logos{
	    bottom: 60px !important;
		.field--name-field-banner-logo-small {
           bottom: 0px !important;
        }
	  }
	}
}
/* home page gradient overlay css */
.path-home {
	.featured-top {
		.field--name-field-banner-image {
			&::after {
				background: -webkit-gradient(linear,left top,right top,color-stop(20%,rgba(0,0,0,0.8)),to(rgba(255,255,255,0)));
				background: -webkit-linear-gradient(left,rgba(0,0,0,0.8) 20%,rgba(255,255,255,0) 100%);
				background: -o-linear-gradient(left,rgba(0,0,0,0.8) 20%,rgba(255,255,255,0) 100%);
				background: linear-gradient(90deg,rgba(0,0,0,0.8) 20%,rgba(255,255,255,0) 100%);
				background: url(../images/Hero_gradiant_wht4.png);
    			z-index: 0;
				background-repeat: repeat-y;
				-webkit-background-size: 100% 100%;
				background-size: 100%;
			}
		}
		.field--name-field-banner-info-text {
			.cd-intro {
				.cd-words-wrapper {
					b {
						color: #ffffff;
					}
				}
			}
			.text-heading-3 {
				color: #ffffff;
			}
		}
		.field--name-field-banner-text {
			.cd-intro {
				.cd-words-wrapper {
					b {
						color: #ffffff;
					}
				}
			}
			.text-heading-3 {
				color: #ffffff;
			}
		}
	}
}