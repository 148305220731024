.node--type-media-tech-page{
  .instagram-popup-container .popup-text-container p {
    box-sizing: border-box;
  }
    .featured-top{
     .field--name-field-banner-image:after {
        background-size: 70% !important;
	  }
    }
  .title-border{
    width: 65px !important;
    height: 6px;
    background-color: #009DD6;
    margin-bottom: 31px;
	border-bottom: none !important;
  }
  #faqs-react-main-wrapper {
		margin-top: 93px;
		.faqs-main-wrapper {	
			.sub-header-title, .item-title-hidden h3:hover, .accordion-title {
				color: #009DD6;
			}
			.accordion-item-body {
				border-bottom: 2px solid #009DD6;
			}
			.item-title-hidden h3 {
                color: #444;
            }
			.accordion-title h3 {
				background: url(../images/resources/blue-up-arrow.png) no-repeat scroll;
				background-size: 20px 11px;
				-webkit-background-size: 20px 11px;
				background-position: 99.7% 31px;
			}
			.accordion-title.item-title-hidden {
				color: #444;
				border: 0;
				outline: 0;
				background: transparent;
				border-bottom: 1px solid rgba(119, 136, 153, 0.5);
				h3 {
				  padding-right: 40px;
				  font-size: 18px;
				  position: relative;
				  padding: 25px 35px 35px 0;
				  background: url("../images/resources/down-arrow.png") no-repeat scroll;
				  background-size: 20px 20px;
				  -webkit-background-size: 20px 20px;
				  background-position: 99.7% 27px;
				  &:hover {
					background: url("../images/resources/blue-down-arrow.png") no-repeat scroll;
					background-size: 20px 11px;
					-webkit-background-size: 20px 11px;
					background-position: 99.7% 31px;
				  }
				}
			}
			.mediatech-faq{
              text-align: left !important;
			  width: 1080px;
			  cursor:pointer;
			  padding: 30px 0;
        background: url("../images/resources/blue-up-arrow.png") no-repeat scroll !important;
              background-size: 20px 10px !important;
              background-position: 99.7% 45px !important;
              border-bottom-style: solid;
              border-bottom-width: .8px;
              border-bottom-color: rgb(234, 234, 234);
              &.false {
                background: url("../images/resources/down-arrow.png") no-repeat scroll !important;
                background-size: 20px 20px !important;
                background-position: 99.7% 40px !important;
                &:hover{
                  background: url("../images/resources/blue-down-arrow.png") no-repeat scroll !important;
                  background-size: 19px 10px !important;
                  background-position: 99.7% 45px !important;
                }
            }
          }
			
		}
	}
  .brand-page-cont{
    h2{
      color: #2A2A2A;
      font-size: 40px;
      letter-spacing: 0.44px;
      line-height: 50px;
      margin-bottom: 21px;
	  font-family:$font-sharpSans-Medium !important;
	}
	p{
	  margin-top: 10px;
	  font-family: $font-SourceSans-Regular;
	}
  }
  .mt-stat-blocks{
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
    height: 132px;
	.static-title {
      color:#009DD6;
      font-family:$font-SourceSans-Semibold;
      font-size: 18px;
      line-height: 23px;
      padding-top: 25px;
    }
	.static-desc{
      p{
       line-height: 23px;
       margin-top: 0px;
       font-size: 17px;
	   font-family: $font-SourceSans-Regular;
     }
	}
  }
  #opportunities{
    margin-bottom: 53px;
    margin-top: 29px;
    h2{
	  font-family:$font-sharpSans-Medium;
      font-size: 40px;
      letter-spacing: 0.44px;
      line-height: 50px;
	}
	.opportunity-desc{
	  font-family: $font-SourceSans-Regular;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 30px;
	  span{
	    font-family: $font-SourceSans-Bold;
		font-weight:regular;
	  }
	}
  }
  
	.mt-programs{
    margin-left: 0px !important;
    margin-right: 0px !important;
	  .mt-asso-block{
	    background-color: #009DD6;
	  }
	  .mt-intern-block{
	    background-color: #59A63F;
	  }
	  .mt-pgm-block{
	    padding-left:73px;
	    padding-top:39px;
	    padding-bottom:60px;
		  padding-right:85px;
    }
    .mt-asso-block {
      .mt-pgm-link {
        .btn-light {
          &:hover {
            background-color: #fff !important;
            border-color: #fff !important;
            color: #009DD6;
          }
        }
      }
    }
    .mt-intern-block {
      .mt-pgm-link {
        .btn-light {
          &:hover {
            background-color: #fff !important;
            border-color: #fff !important;
            color: #59A63F;
          }
        }
      }
    }
	  .mt-pgm-title{
	    color: #FFFFFF;
        font-family:$font-sharpSans-Semibold;
        font-size: 23px;
        letter-spacing: 0.01px;
        line-height: 29px;
	  }
	  .mt-pgm-desc{
	    color: #FFFFFF;
        font-family: $font-SourceSans-Regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 30px;
	  }
      .mt-pgm-link{
        a{
          color: #2A2A2A;
              font-family:$font-SourceSans-Semibold;
              font-size: 17px;
              letter-spacing: 0.52px;
              line-height: 22px;
              width: 150px;
              height: 45px;
              padding: 10px 0px;
              span {
                position: relative;
                left: 2px;
                top: 2px;
              }
        }
	    }
  }
  .employee-resource-groups{
    h2{
      font-size: 34px !important;
      letter-spacing: 0;
      line-height: 44px;
	  font-family:$font-sharpSans-Medium !important;
	}
  }
  #faqs-react-main-wrapper{
	 margin-bottom: 97px;
    .faqs-main-wrapper{
      .faqs-wrapper{ 
         h1{
          font-size: 40px;
          letter-spacing: 0.44px !important;
          line-height: 50px;
		  font-family:$font-sharpSans-Medium;
		}
	    .sub-header-title{
	      font-family:$font-SourceSans-Semibold !important;
	    }
	    .accordion-container:first-child{
	      margin-bottom:35px;
      }
      .accordion-container {
        padding-right: 100px;
      }
	    .accordion-body > *:last-child .accordion-title {
            border-bottom: none !important;
		}
		.MuiCardContent-root-5, .opened-track-content{
		  padding-bottom: 0px !important;
		}
	  }
    }
  }
  .intern-instagram-main-wrapper.container-fluid-lg{
    max-width: 1135px !important;
  }
  .cultureHeaderSection  {
    .side-title{
	   span{
	     font-family:$font-sharpSans-Semibold;
         font-size: 23px;
         letter-spacing: 0.25px;
         line-height: 29px;
	   }
	  }
    .sidelink {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 23px;
      color: #686868;
      font-family:$font-SourceSans-Semibold;
      a {
        color: #686868 !important;
      }
	   a:hover {
         color: #009DD6 !important;
         text-decoration: none;
      }
	  
    }
    .sidelink:last-child {
      margin-bottom: 0px;
    }
  }
  .banner_logos {
    .field--name-field-banner-logo-small-right {
      padding-left: 23px;
      position: relative;
      bottom: 10px;
      .field__item {
        img {
          margin-bottom: 0px;
        }
      }
    }
    .field--name-field-banner-logo-small {
      padding-right: 23px;
    }
  }
  .ops-row {
    margin-top: 38px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .mt-asso-block {
    .mt-pgm-desc {
      p {
        margin-bottom: 20px;
        margin-top: 20px;
        &:last-child {
          margin-bottom: 51px;
        }
      }
    }
    .as-link {
      .btn-light {
        color: #2A2A2A;
        font-family: "Source Sans Pro";
        font-size: 17px;
        letter-spacing: 0.52px;
        line-height: 22px;
      }
      span {
        margin-left: 5px;
      }
    }
  }
  .mt-intern-block {
    .mt-pgm-desc {
      p {
        margin-bottom: 20px;
        margin-top: 20px;
        &:last-child {
          margin-bottom: 51px;
        }
      }
    }
    .in-link {
      .btn-light {
        color: #2A2A2A;
        font-family: "Source Sans Pro";
        font-size: 17px;
        letter-spacing: 0.52px;
        line-height: 22px;
      }
      span {
        margin-left: 5px;
      }
    }
  }
  .sec-banner-section {
    margin-left: 0px !important;
    margin-right: 0px !important;
    .desktop-hide {
      display: none;
    }
    .sec-banner-content {
      background: #7F679F;
      padding: 122px 125px 69px 96px;
      .sec-banner-title{
        p {
          color: #FFFFFF;
          font-family:$font-sharpSans-Bold;
          font-size: 48px;
          letter-spacing: 0.53px;
          line-height: 60px;
          &:first-child {
            margin-bottom: 0px;
          }
          &:last-child {
            font-family:$font-sharpSans-Medium;
            font-size: 48px;
            margin-bottom: 18px;
          }
        }
      }
      .sec-banner-desc {
        color: #FFFFFF;
        font-family: $font-SourceSans-Regular;
        font-size: 18px;
        letter-spacing: 0.2px;
        line-height: 23px;
      }
    }
    .sec_banner_img {
      padding: 0px;
      .sec-feature-img {
        .img-fluid {
          width: 100%;
        }
      }
    }
  }
  .mt-stat-blocks {
    border-right: 1px solid #979797;
    padding-left: 51px;
    &:last-child {
      border-right: 0px;
    }
    &:first-child {
      padding-left: 0px;
    }
  }
    #video_popup {
		.modal-dialog {
			@include media-breakpoint-up(sm) {
				max-width: 650px;
			}
			.modal-content {
				border: none;
				background: none;
				.modal-header {
					border: none;
					.close {
						cursor: pointer;
						padding: 2px 5px;
						line-height: 20px;
						font-size: 40px;
						background: #ffffff;
						z-index: 999999;
						color: #000;
						text-shadow: 0 1px 0 #fff;
						opacity: .5;
					}
				}
				.modal-body {
					.video-js {
						height: 350px;
						width: 100%;
						@include media-breakpoint-down(sm) {
							height: 205px;
						}
					}
				}
			}
		}
  }
}
// media queries mobile
@media only screen and (max-width: 768px) {
  .node--type-media-tech-page {
    .text-heading-1{
	  font-size: 48px;
  }
  .carousel-control-next-icon{
    display: block !important;
    top: -10px;
  }
  .carousel-control-prev-icon{
     display: block !important;
     top: -10px;
  }
  #faqs-react-main-wrapper  {
    padding: 0px 20px;
    .faqs-main-wrapper {
      .faqs-wrapper {
        h1 {
          width: 100%;
		  color: #009DD6 !important;
        }
        .accordion-container {
          padding-right: 0px !important;
        }
      }
    }
  }
	.featured-top{
    .banner-content-wrapper-top {
      .banner-content-wrapper {
        padding-top: 67px;
        padding-left: 20px;
        font-size: 48px;
        padding-right: 45px;
      }
    }
	  .cd-intro{ 
	    .cd-words-wrapper{ 
		  b{
		    font-size: 48px;
		  }
		}
      }
	}
	.sidelink {
	  font-family:$font-SourceSans-Semibold;
	}
	.cultureHeaderSection{ 
	  .side-title{
	    span{
		  font-size: 20px !important;
		}
	  }
	}
    div.brand-page-cont h2 {
      font-size: 26px !important;
	  font-family:$font-sharpSans-Book !important;
    }
    .mt-stat-blocks {
      padding-left: 0px !important;
      padding-right: 0px !important;
      height: auto;
      border-right: none;
      .static-title {
        font-family: $font-SourceSans-Bold !important;
        font-weight: normal !important;
      }
      &:first-child {
        padding-left: 15px;
      }
      .static-desc {
        padding-bottom: 20px;
        border-bottom: 1px solid #979797;
        &:last-child {
          padding-bottom: 0px;
          border-bottom: unset;
        }
      }
    }
    .mt-programs  {
      .mt-pgm-block {
        padding: 30px;
      }
    }
    .sec-banner-section   {
      .desktop-hide {
        display: block;
      }
      .mobile-hide {
        display: none;
      }
      .sec-banner-content {
        padding: 30px;
        .sec-banner-title {
          p {
            &:first-child {
              font-size: 36px;
              line-height: 45px;
            }
            &:last-child {
              font-size: 36px;
              line-height: 45px;
            }
          }
        }
      }
    }
    .mt-programs {
      margin: 0px;
    }
    .cultureHeaderSection {
      .sidelink {
        a {
          color: #0079C0 !important;
        }
      }
    }
	.employee-resource-groups{
      h2{
        font-size: 24px !important;
	  }
    }
      .faqs-main-wrapper{
        .faqs-wrapper{ 
	      h1 {
            font-size: 26px !important;
		    color:#009DD6 !important;
            font-family: $font-sharpSans-Book !important;
			margin-left: 0px;
		  }  
	    }	
      }
    #opportunities {
      margin-top: 0px;
      margin-bottom: 51px;
      .opportunity-desc {
        p {
          span {
            font-family: $font-SourceSans-Bold !important;
            font-weight: normal !important;
          }
        }
      }
    }
    .mt-programs  {
      .mt-pgm-block {
        padding: 51px 25px 59px 23px;
      }
    }
    .sec-banner-logo {
      display: block !important;
      position: unset;
      margin-top: 51px;
    }
	.styles_overlay__CLSq- {
      padding: 0px 34px;
    }
  }
  // media tech internship program page mobile css
  .path-mediatech-internship-program {
    .brand-page-row {
      margin-top: 0px !important;
    }
    .slick-slide, .single-slider-img {
      &:focus {
        outline: none;
      }
    }
    .carousel-control-next-icon, .carousel-control-prev-icon {
      display: none !important;
    }
    .title-border {
      margin-bottom: 30px;
    }
    .featured_locations_heading {
      h2 {
        margin-bottom: 80px;
      }
    }
    .internship-cards {
      width: 100% !important;
      display: block !important;
      padding: 0px 15px;
      margin-top: 15px !important;
      .internship-cards-box {
        margin-right: 0px !important;
        margin-bottom: 15px;
        padding: 55px 10px 30px 20px !important;
        .internships-title {
          p {
            margin-bottom: 90px !important;
            font-size: 34px !important;
            line-height: 36px !important;
            span {
              font-size: 34px !important;
            }
          }
        }
        .internship-duration {
          font-size: 18px;
          line-height: 23px;
        }
        .internships-details {
          font-size: 23px;
          line-height: 23px;
        }
      }
    }
    .sidelink {
      a {
        color: #0079C0 !important;
      }
    }
    .side-title {
      margin-top: 29px;
      font-size: 20px !important;
      line-height: 25px !important;
    }
    .job-container-section {
      padding: 0px !important;
    }
    .job-container {
      padding: 0px !important;
      .mediatech-job-main-container {
        .mediatech-job-inner-container {
          padding: 0px 15px;
        }
        .job-qualification-cont {
          padding: 0px;
          margin-bottom: 64px;
          &:last-child {
            margin-bottom: 0px;
          }
          .side-cont-title {
            margin-bottom: 14px !important;
            font-size: 20px !important;
            line-height: 25px !important;
          }
          .program-info-list, .eligibility-list {
            padding: 0px;
            ul {
              padding: 0px 0px 0px 20px !important;
              li {
                list-style: disc;
              }
            }
          }
        }
        .job-apply-cointer {
          padding: 0px;
          margin-top: 0px !important;
          margin-bottom: 54px;
          .jobapply-inner {
            .apply-button-cont {
              padding: 0px 0px;
              .btn-light {
                padding: 0px 0px;
              }
            }
            .side-cont-title {
              margin-bottom: 9px !important;
            }
            div {
              p {
                margin-bottom: 38px !important;
              }
            }
          }
        }
        .mediatech-page-link {
          padding-left: 16px !important;
          padding-top: 10px;
          padding-bottom: 10px;
          a {
            font-family: 'Source Sans Pro', sans-serif !important;
            color: #009DD6 !important;
            font-weight: 600 !important;
            font-style: normal !important;
          }
        }
      }
    }
    .path-all-locations .featured_locations_heading {
      padding-top: 62px;
    }
    .slider-outer-banner {
      padding: 54px 0px 84px 0px;
      .single-slider-main  {
        .slider-title {
          margin-bottom: 54px;
          font-size: 28px;
          line-height: 35px;
          span {
            font-size: 28px !important;
            line-height: 35px !important;
          }
        }
        .slider-description {
          border-left: unset !important;
          padding: 0px 0px 0px 20px !important;
          background-color: unset;
          .col-sm-12 {
            padding: 0px !important;
            h2 {
              font-size: 23px !important;
              line-height: 29px !important;
              margin-bottom: 15px !important;
            }
            p {
              font-size: 16px !important;
              line-height: 25px !important;
              margin-bottom: 39px !important;
              padding-top: 0px;
            }
          }
        }
      }
    }
  }
  .mediatech-associates {
    .mediatech-page-link {
      margin-bottom: 78px !important;
    }
  }
  .path-mediatech-associate-program {
    #tracks-react-main-wrapper{
      .slick-slide.slick-active.slick-current {
        width: 16% !important;
      }
      .slick-slide.slick-active {
        white-space: nowrap;
      }
      .slick-slide.slick-cloned:last-child {
        display: none;
      }
    }
    .internship-cards {
      width: 100% !important;
      display: block !important;
      padding: 0px 15px;
      margin-top: 56px !important;
      .internship-cards-box {
        margin-right: 0px !important;
        margin-bottom: 27px;
        padding: 70px 17px 30px 20px !important;
        height: 310px !important;
        
        .internships-title {
          margin-top: 34px !important;
          p {
            font-size: 126px !important;
            font-family: $font-sharpSans-Semibold !important;
            font-weight: normal;
            line-height: 14px !important;
            span {
              font-size: 29px !important;
              font-family: $font-sharpSans-Semibold !important;
              font-weight: normal;
              right: -80px !important;
              top: -14px !important;
            }
          }
        }
        .internships-details {
          line-height: 23px;
        }
      }
    }
    .job-container-section {
      padding: 0px !important;
    }
    .job-container {
      padding: 0px !important;
      .mediatech-job-main-container {
        .mediatech-job-inner-container {
          padding: 0px 15px;
        }
        #hear-from-our-alumni {
          .alumni-title {
            margin-top: 64px;
          }
        }
        margin-top: 54px;
        .job-qualification-cont {
          padding: 0px;
          margin-bottom: 64px;
          .side-cont-title {
            margin-bottom: 14px !important;
            font-size: 20px !important;
            line-height: 25px !important;
          }
          .program-info-list {
            padding: 0px;
            ul {
              padding: 0px 0px 0px 20px !important;
              li {
                list-style: disc;
              }
            }
          }
        }
        .job-apply-cointer {
          padding: 0px;
          margin-bottom: 74px !important;
          margin-top: 0px !important;
          &:last-child {
            margin-bottom: 0px !important;
          }
          .jobapply-inner {
            .side-cont-title {
              margin-bottom: 9px !important;
            }
            div {
              p {
                margin-bottom: 29px !important;
              }
            }
          }
        }
        .mediatech-page-link {
          padding: 10px 0px 10px 16px;
          a {
            color: #0079C0 !important;
          }
        }
      }
    }
    .side-title {
      margin-top: 0px;
      font-size: 20px !important;
      line-height: 25px !important;
      font-family: $font-sharpSans-Semibold !important;
      padding: 0px 15px !important;
      margin-top: 2px;
    }
    .sidelink {
      font-weight: normal;
      margin-bottom: 20px !important;
      padding: 0px 15px !important;
      a {
        color: #0079C0 !important;
      }
    }
    .title-border {
      margin-bottom: 22px !important;
      margin-top: 10px;
    }
    .brand-page-cont {
      
      h2 {
        padding-top: 36px !important;
      }
      p {
        margin-bottom: 0px;
        padding-bottom: 0px !important;
      }
    }
    .brand-page-row {
      margin-top: 2px !important;
    }
    .tracks-section-wrapper {
      width: 100%;
      padding: 0px 15px;
      .all-tracks-container {
        margin-top: 47px;
        .track-container {
          .track-title {
            padding-bottom: 27px;
          }
          .MuiCollapse-container-1 {
            .MuiCollapse-wrapper-3 {
              .MuiCardContent-root-5, .opened-track-content {
                .track-description {
                  margin-bottom: 24px;
                }
                .opened-track-content {
                  padding-top: 8px;
                  .track-tab-container {
                    .mobile-track-slider-container {
                      .slick-slider {
                        .slick-list {
                          .slick-track {
                            height: 40px;
                            .slick-slide.slick-active.slick-current {
                             
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .track-tab-container {
                  div {
                    margin-right: 0px;
                    width: 100%;
                    .mobile-track-slide {
                      width: 100%;
                    }
                  }
                  .mobile-track-slider-container {
                    .slick-list {
                      margin-left: 0px;
                    }
                  }
                }
              }
            }
          }
        }
        .explore-tracks {
          font-size: 26px;
          letter-spacing: 0.29px;
          line-height: 34px;
          font-family: $font-sharpSans-Medium;
          text-align: left;
          height: auto;
        }
      }
    }
  }
}
// media queries for ipad
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
  .node--type-media-tech-page {
    .text-heading-1{
	  font-size: 48px;
  }
  .path-mediatech-associate-program {
    .carousel-control-next-icon, .slick-next{
      display: block !important;
    }
    .carousel-control-prev-icon, .slick-prev{
      display: block !important;
    }
  }
  .slick-next{
	right: 0px !important;
  }
  .slick-prev{
	 left: 0px !important;
  }
  #faqs-react-main-wrapper  {
    padding: 0px 20px;
    .faqs-main-wrapper {
      .faqs-wrapper {
        h1 {
          width: 100%;
        }
        .accordion-container {
          padding-right: 0px !important;
        }
      }
    }
  }
    .sec-banner-logo {
      display: none;
    }
	.text-heading-1 {
       font-size: 70px;
    }
	.featured-top{
	  .cd-intro{ 
	    .cd-words-wrapper{ 
		  b{
		    font-size: 70px;
		  }
		}
      }
	}
	.employee-resource-groups{
      h2{
        font-size: 34px !important;
	  }
    }
    .ops-row {
      .mt-stat-blocks {
        max-width: 33%;
        padding-left: 23px !important;
        border-right: 1px solid #979797;
        &:first-child {
          padding-left: 0px !important;
        }
        &:last-child {
          border-right: unset;
        }
        .static-desc {
          border-bottom: unset;
        }
      }
    }
    .faqs-main-wrapper {
      .faqs-wrapper {
        h1 {
          &:first-child {
            color: #009DD6;
			font-family: $font-sharpSans-Book !important;
			margin-left: 0px;
          }
        }
      }
    }
    .cultureHeaderSection {
      .side-title{
	    span {
          font-size: 20px !important;
          line-height: 25px;
		}
      }
    }
    div.brand-page-cont{
      h2 {
        margin-top: 69px;
      }
    }
    .sec-banner-logo {
      position: absolute;
      bottom: 36px;
      right: 32px;
    }
    .sec-banner-section {
      .desktop-hide {
        display: block;
      }
      .mobile-hide {
        display: none;
      }
      .sec-banner-content {
        padding-bottom: 68px;
        .sec-banner-desc {
          max-width: 65%;
        }
      }
    }
    .featured-top{
	  .field--name-field-banner-content-para{
	   .field--name-field-banner-info-text {
          width: 82%;
	   }
	  }
    }
	.intern-instagram-main-wrapper {
      .insta-image-container {
        height: 560px !important;
        .insta-image-mobile {
          height: 100% !important;
        }
      }
    }
  }
  .path-mediatech-internship-program {
    .carousel-control-next-icon, .carousel-control-prev-icon {
      top: 50% !important;
      display: none !important;
    }
    div.brand-page-cont h2 {margin-top: 28px !important;}
    .internship-cards {
      display: table;
      margin-top: 52px;
      .internship-cards-box {
        width: 31%;
        display: inline-block;
        margin-right: 12px !important;
        padding: 41px 10px 20px 25px !important;
        height: 234px !important;
        .internships-title {
          p {
            margin-bottom: 38px !important;
            line-height: 24px !important;
            font-size: 26px !important;
            span {
              line-height: 24px !important;
              font-size: 26px !important;
            }
          }
        }
      }
    }
    .featured-top .banner-content-wrapper-top .banner-content-wrapper {
      padding-top: 44px;
    }
    div.brand-page-cont h2 {
      h2 {
        margin-top: 38px;
      }
      p {
        margin-top: 0px;
        margin-bottom: 52px;
      }
    }
    .slider-outer-banner {
      padding: 50px 0px 46px 0px;
      margin-top: 10px;
      .single-slider-main  {
        .slider-title {
          margin-bottom: 54px;
          font-size: 36px !important;
          line-height: 45px !important;
          padding-left: 30px;
          span {
            font-size: 36px !important;
            line-height: 45px !important;
          }
        }
        .slider-description {
          border-left: 1px solid #fff !important;
          padding: 0px !important;
          .col-md-8 {
            padding: 0px;
            padding: 0px 0px 0px 52px !important;
            h2 {
              font-size: 23px;
              line-height: 29px;
              margin-bottom: 15px;
            }
            p {
              font-size: 16px;
              line-height: 25px;
              margin-bottom: 35px;
              padding-top: 0px;
            }
          }
        }
      }
    }
    .brand-page-row {
      margin-top: 14px;
    }
    .title-border {
      margin-bottom: 34px;
    }
    .job-container {
      .mediatech-job-main-container {
        .job-apply-cointer {
          .jobapply-inner {
            .apply-button-cont {
              padding: 0px;
              .btn-light {
                padding: 0px;
              }
            }
          }
        }
        .mediatech-page-link {
          a {
            font-family: 'Source Sans Pro', sans-serif !important;
            color: #009DD6 !important;
            font-style: normal !important;
            font-weight: 600 !important;
          }
        }
      }
    }
  }
  .path-mediatech-associate-program {
    .carousel-control-next-icon {
      margin-right: 0px !important;
    }
    .carousel-control-prev-icon {
      margin-left: 0px !important;
    }
    .internship-cards {
      width: 100% !important;
      margin-top: 39px !important;
      .internship-cards-box {
        width: 233px;
        display: inline-grid;
        margin-right: 11px !important;
        padding: 43px 12px 26px 12px !important;
        height: 225px !important;
        margin-bottom: 0px !important;
        &:last-child {
          margin-right: 0px !important;
        }
        .internships-title {
          margin-top: 45px !important;
          p {
            margin-bottom: 0px !important;
            line-height: 0px !important;
            font-size: 105px !important;
            font-family: $font-sharpSans-Semibold !important;
            letter-spacing: 1.16px !important;
            span {
              font-size: 24px !important;
              line-height: 31px !important;
              top: 0px !important;
              right: -64px !important;
              font-family: $font-sharpSans-Semibold !important;
            }
          }
        }
        .internship-duration {
          display: none;
        }
        .internships-details {
          font-size: 16px !important;
          line-height: 20px !important;
          padding-top: 0px !important;
          &::before {
            content: '';
            border-bottom: 1px solid #fff;
            width: 96%;
            display: block;
            margin-top: 7px;
            margin-bottom: 4px;
          }
        }
      }
    }
    .mediatech-job-main-container {
      .mediatech-job-inner-container {
        .job-apply-cointer {
          margin-bottom: 65px;
        }
      }
      .mediatech-page-link {
        padding-left: 0px;
        margin-bottom: 86px !important;
        a {
          color: #0079C0 !important;
        }
      }
      #hear-from-our-alumni {
        .alumni-title {
          margin-top: 116px;
        }
        .testimonial-career-section-cont {
          .testimonial-career-section {
            .ipad-display {
              height: 300px;
            }
          }
        }
      }
    }
    div.brand-page-cont  {
      margin-top: 0px !important;
      h2 {
        margin-top: 0px !important;
        margin-bottom: 20px;
        padding-top: 34px !important;
      }
    }
    .title-border {
      margin-bottom: 22px;
    }
    .side-title {
      margin-top: 17px !important;
    }
    .tracks-section-wrapper {
      width: 100%;
      padding: 0px 15px;
      .all-tracks-container {
        margin-top: 100px;
        .explore-tracks {
          margin-bottom: 63px;
        }
        .track-container {
          .track-title {
            padding-bottom: 31px;
          }
          .track-tab-container {
            margin-bottom: 58px !important;
            div {
              margin-right: 30px !important;
              width: auto !important;
              &:last-child {
                margin-right: 0px !important;
              }
            }
            .track-tab-title {
              font-size: 20px;
              line-height: 25px;
              padding: 10px 0px !important;
            }
            .track-accent {
              margin-right: 0px !important;
            }
          }
          .opened-track-content {
            .track-description {
              p {
                margin-bottom: 30px !important;
              }
            }
            .track-tab-header-title {
              font-family: $font-sharpSans-Semibold;
              font-size: 20px;
              line-height: 25px;
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}
// media tech Internship program css
.path-mediatech-internship-program {
  .sidelink {
    a {
      color: #686868;
      font-family: $font-SourceSans-Semibold;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 0.01px;
      line-height: 23px;
      &:hover {
        text-decoration: none !important;
        color: #009DD6;
      }
    }
  }
  .internship-cards {
    width: 1027px;
    margin: 56px auto 0px;
    display: flex;
    .internship-cards-box {
      margin-right: 30px;
      padding: 52px 10px 52px 24px;
      height: 311px;
      &:last-child {
        margin-right: 0px;
      }
      .internships-title {
        p {
          color: #FFFFFF;
          font-family: $font-sharpSans-Bold;
          font-size: 34px;
          font-weight: normal;
          letter-spacing: 0.29px;
          line-height: 34px;
          margin-bottom: 100px;
          span {
            font-family: $font-sharpSans-Book;
            font-size: 34px;
          }
        }
      }
      .internship-duration {
        color: #FFFFFF;
        font-family: $font-SourceSans-Regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 22px;
        &::after {
          content: '';
          border-bottom: 1px solid #fff;
          width: 88%;
          display: block;
          margin-top: 7px;
          margin-bottom: 4px;
        }
      }
      .internships-details {
        color: #FFFFFF;
        font-family: $font-SourceSans-Regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
  .slider-outer-banner {
    padding: 45px 0px 45px;
    .single-slider-main {
      .slider-description {
        border-left: 1px solid rgb(255, 255, 255);
        padding: 0px;
        .col-lg-6 {
          padding: 0px 0px 0px 82px;
          h2 {
            font-family: $font-sharpSans-Semibold;
            color: #FFFFFF;
            font-size: 23px;
            font-weight: normal;
            letter-spacing: 0.25px;
            line-height: 29px;
            margin-bottom: 20px;
          }
          p {
            color: #FFFFFF;
            font-family: $font-SourceSans-Regular;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 25px;
            border-left: none !important;
            padding-left: 0px;
          }
        }
      }
      .slider-title {
        span {
          font-family: $font-sharpSans-Semibold;
          color: #FFFFFF;
          font-size: 36px;
          font-weight: normal;
          letter-spacing: 0.4px;
          line-height: 45px;
          text-align: center;
        }
      }
    }
  }
  .job-container {
    margin-top: 50px;
    .mediatech-job-main-container {
      .job-qualification-cont {
        .side-cont-title {
          color: #2A2A2A;
          font-family: $font-sharpSans-Semibold;
          font-size: 23px;
          font-weight: normal;
          letter-spacing: 0.25px;
          line-height: 29px;
          margin-bottom: 25px;
        }
        .program-info-list, .eligibility-list {
          ul {
            padding: 0px 0px 0px 20px;
            margin: 0px;
            li {
              list-style: disc;
              color: #2A2A2A;
              font-family: $font-SourceSans-Regular;
              font-size: 18px;
              letter-spacing: 0;
              line-height: 30px;
            }
          }
        }
      }
      .job-apply-cointer {
        margin-top: 49px;
        .jobapply-inner {
          .side-cont-title {
            margin-bottom: 25px;
            color: #2A2A2A;
            font-family: $font-sharpSans-Semibold;
            font-size: 23px;
            font-weight: normal;
            letter-spacing: 0.25px;
            line-height: 29px;
          }
          div {
            p {
              color: #2A2A2A;
              font-family: $font-SourceSans-Regular;
              font-size: 18px;
              letter-spacing: 0;
              line-height: 30px;
              margin-bottom: 29px;
            }
          }
           .apply-button-cont {
            color: #FFFFFF;
            font-family: $font-SourceSans-Semibold;
            font-size: 17px;
            letter-spacing: 0.52px;
            line-height: 22px;
            height: 45px;
            width: 150px !important;
            &:focus {
              box-shadow: none !important;
            }
            a {
              padding: 0px 0px;
              &:focus {
                box-shadow: none !important;
              }
              &:hover {
                background-color: unset;
                border-color: #fff;
              }
              .btn-light {
                &:focus {
                  box-shadow: none !important;
                }
                &:hover {
                  background: #0079C0 !important;
                }
              }
              .btn-light.active {
                background: #009DD6;
              }
            }
          }
        }
      }
      .mediatech-page-link {
        margin-top: 30px;
        padding-left: 15px;
        a {
          color: #2A2A2A;
          font-family: $font-SourceSans-Semibold;
          font-size: 20px;
          letter-spacing: 0.63px;
          line-height: 26px;
          font-weight: normal;
          font-style: normal;
          &:hover {
            text-decoration: none !important;
            color: #009DD6;
          }
        }
      }
    }
  }
  div.brand-page-cont h2 {
    font-family: $font-sharpSans-Medium;
  }
  .brand-page-row {
    margin-top: 28px;
    .side-title {
      font-family: $font-sharpSans-Semibold;
      color: #2A2A2A;
      font-size: 23px;
      font-weight: normal;
      letter-spacing: 0.25px;
      line-height: 29px;
    }
  }
  .featured-top {
    margin-top: 75px !important;
  }
  
}
/* Iphone X Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    .path-mediatech-internship-program {
      .internship-cards {
        width: 100%;
        display: block;
        padding-left: 20px;
        .internship-cards-box {
          margin-right: 15px;
        }
      }
      .job-container {
        padding: 0px;
        .mediatech-job-main-container {
          .mediatech-job-inner-container {
            .job-qualification-cont {
              &:first-child {
                margin-bottom: 50px;
              }
            }
            .job-apply-cointer {
              padding: 0px;
            }
          }
        }
      }
    }
    .path-mediatech-associate-program {
      .internship-cards {
        width: 100% !important;
        display: block !important;
        padding-left: 20px;
        .internship-cards-box {
          margin-right: 30px !important;
        }
      }
      .job-container {
        padding: 0px !important;
        .mediatech-job-main-container {
          .mediatech-job-inner-container {
            .job-qualification-cont {
              padding: 0px 10px !important;
              &:first-child {
                margin-bottom: 50px;
              }
            }
            .job-apply-cointer {
              padding: 0px 10px !important;
            }
          }
        }
      }
    }
  }

  .mediatech-associates{
    .internship-cards-row {
      margin-left: 0px;
      margin-right: 0px;
      .internship-cards {
        width: 1027px;
        margin: 56px auto 0px;
        display: flex;
        color: white;
        .internship-cards-box {
          margin-right: 30px;
          padding: 60px 16px 60px 16px;
          height: 311px;
          flex: 0 0 31.33333%;
          &:last-child {
            margin-right: 0px;
          }
          .internships-title {
            p {
              color: #FFFFFF;
              font-family: $font-sharpSans-Bold;
              font-size: 145px;
              font-weight: normal;
              letter-spacing: 0.29px;
              margin-bottom: 0;
              span {
                font-family: $font-sharpSans-Book;
                font-size: 34px;
              }
            }
          }
          .internship-duration {
            color: #FFFFFF;
            font-family: $font-SourceSans-Regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 22px;
            &::after {
              content: '';
              border-bottom: 1px solid #fff;
              width: 88%;
              display: block;
              margin-top: 7px;
              margin-bottom: 4px;
            }
          }
          .internships-details {
            color: #FFFFFF;
            font-family: $font-SourceSans-Regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 20px;
            padding-top: 8px;
          }
        }
      }
    }
    #tracks-react-main-wrapper {

    }
    #opportunities{
      text-align: center;
    }
    .mediatech-page-link {
      margin-bottom: 167px;
      margin-top: 0px;
    }
    .brand-page-cont {
      p {
        margin-bottom: 0px;
        padding-bottom: 0px !important;
      }
    }
  }

  // mediatech associate program styles
  .path-mediatech-associate-program {
    .featured-top {
      margin-top: 75px !important;
    }
    .mediatech-job-main-container{
      text-align: left;
      margin-top: 94px;
      ul{
        padding-left: 3px;
      }
      .mediatech-job-inner-container {
        .job-qualification-cont {
          .side-cont-title {
            color: #2A2A2A;
            font-family: "sharpSans-Semibold";
            font-size: 23px;
            font-weight: normal;
            letter-spacing: 0.25px;
            line-height: 29px;
            margin-bottom: 25px;
          }
          .program-info-list, .eligibility-list {
            p {
              color: #2A2A2A;
              font-family: "SourceSansPro-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 30px;
              margin-bottom: 10px;
            }
            ul {
              padding: 0px 0px 0px 20px;
              margin: 0px;
              li {
                list-style: disc;
                color: #2A2A2A;
                font-family: "SourceSansPro-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 30px;
              }
            }
          }
        }
        .job-apply-cointer {
          margin-top: 49px;
          .jobapply-inner {
            .side-cont-title {
              margin-bottom: 25px;
              color: #2A2A2A;
              font-family: "sharpSans-Semibold";
              font-size: 23px;
              font-weight: normal;
              letter-spacing: 0.25px;
              line-height: 29px;
            }
            div {
              p {
                color: #2A2A2A;
                font-family: "SourceSansPro-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 29px;
              }
            }
            .apply-button-cont {
              color: #FFFFFF;
              font-family: "SourceSansPro-Semibold";
              font-size: 17px;
              letter-spacing: 0.52px;
              line-height: 22px;
              height: 45px;
              width: 150px !important;
              .fas {
                position: relative;
                top: 1px;
                left: 2px;
              }
              a {
                padding: 0px;
                .btn-light.active {
                  background: #009DD6;
                  border: #009DD6;
                }
                .btn-light {
                  &:hover {
                    background: #0079C0 !important;
                  }
                  &:focus {
                    box-shadow: none;
                  }
                  .fas {
                    position: relative;
                    top: 1px;
                    left: 2px;
                  }
                }
              }
            }
          }
        }
      }
      .mediatech-page-link {
        padding-left: 15px;
        a {
          color: #2A2A2A;
          font-family: "SourceSansPro-Semibold";
          font-size: 20px;
          letter-spacing: 0.63px;
          line-height: 26px;
          font-weight: normal;
          font-style: normal;
          &:hover {
            text-decoration: none !important;
            color: #009DD6;
          }
        }
      }
      #hear-from-our-alumni {
        .alumni-title {
          text-align: center;
          font-family: $font-sharpSans-Medium;
          font-size: 40px;
          line-height: 50px;
          color: #2A2A2A;
          letter-spacing: 0.44px;
          font-weight: 500;
          margin-top: 100px;
          margin-bottom: 0px;
        }
        .testimonial-career-section-cont {
          .testimonial-career-section {
            margin-top: 60px;
            .desktop-display {
              height: 300px;
            }
          }
        }
      }
    }
    .cultureHeaderSection {
      .careerSideHeader {
        .title-border {
          margin-bottom: 31px;
        }
      }
      .brand-page-cont {
        h2 {
          padding-bottom: 0px;
        }
      }
    }
    .brand-page-row {
      margin-top: 28px;
      .side-title {
        font-family: "sharpSans-Semibold";
        color: #2A2A2A;
        font-size: 23px;
        font-weight: normal;
        letter-spacing: 0.25px;
        line-height: 29px;
      }
    }
    .internship-cards {
      width: 1027px;
      margin: 40px auto 0px;
      display: flex;
      color: white;
      .internship-cards-box {
        margin-right: 30px;
        padding: 60px 16px 60px 16px;
        height: 311px;
        &:last-child {
          margin-right: 0px;
        }
        .internships-title {
          margin-top: 70px;
          p {
            color: #FFFFFF;
            font-family: $font-sharpSans-Semibold;
            font-size: 145px;
            font-weight: normal;
            letter-spacing: 0.29px;
            margin-bottom: 0;
            line-height: 10px;
            span {
              font-family:$font-sharpSans-Semibold;
              font-size: 34px;
              line-height: 34px;
              font-weight: normal;
              position: relative;
              top: -10px;
              right: -92px;
            }
          }
        }
        .internship-duration {
          color: #FFFFFF;
          font-family: $font-SourceSans-Regular;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 22px;
          &::after {
            content: '';
            border-bottom: 1px solid #fff;
            width: 96%;
            display: block;
            margin-top: 7px;
            margin-bottom: 4px;
          }
        }
        .internships-details {
          color: #FFFFFF;
          font-family: $font-SourceSans-Regular;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 23px;
          padding-top: 8px;
        }
      }
    }
    .sidelink {
      a {
        color: #686868;
        font-family: "SourceSansPro-Semibold";
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0.01px;
        line-height: 23px;
        &:hover {
          text-decoration: none !important;
          color: #009DD6;
        }
      }
    }
  }

.tracks-section-wrapper {
  width: 1027px;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    width: 92vw;
  }
  @include media-breakpoint-down(md) {
    width: 86vw;
  }
  .all-tracks-container{
    margin-top: 109px;
    .explore-tracks{
      height: 50px;
      color: #2A2A2A;
      font-family: $font-sharpSans-Medium;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0.44px;
      line-height: 50px;
      text-align: center;
      margin-bottom: 22px;
      @include media-breakpoint-down(sm) {
        height: 120px;
      }
    }
    .track-container{
      margin-bottom: 34px;
      box-sizing: border-box;
      border-bottom: 1.5px solid #A7A9AC;
      padding-bottom: 0px;
      &:last-child {
        margin-bottom: 0px;
      }
      .track-title{
        font-size: 18px;
        color: #2A2A2A;
        font-family: $font-sharpSans-Medium;
        max-width: 1240px;
        text-align: left;
        padding: 0px 0px 30px 0px;
        background: url("../images/resources/down-arrow.png") no-repeat scroll;
        background-size: 20px 20px;
        background-position: 99.7% 0;
        margin-bottom: 0px;
        font-family: $font-SourceSans-Regular;
        line-height: 23px;
        &:hover {
          background: url("../images/resources/blue-down-arrow.png") no-repeat scroll;
          background-size: 19px 11px;
          background-position: 99.7% 5px;
          cursor: pointer;
          color: #009DD6;
        }
        &.true {
          background: url("../images/resources/blue-up-arrow.png") no-repeat scroll;
          background-size: 19px 11px;
          background-position: 99.7% 0px;
          color: #009DD6;
        }
      }
      .opened-track-content{
        .track-description{
          font-size: 17px;
          margin: 24px 0 34px;
          font-family: $font-sharpSans-Medium;
        }
        .track-tab-container{
            display: flex;
            .track-tab-title{
              padding: 10px 0px;
              color: #2A2A2A;
              font-family: $font-SourceSans-Semibold;
              font-size: 18px;
              font-weight: 500;
              line-height: 23px;
              color: #686868;
              cursor: pointer;
              &.selected{
                color: #2A2A2A;
              }
              &:first-child {
                padding-left: 0;
              }
            }
            .track-accent{
              height: 5px !important;
              width: 50px !important;
              margin-top: 8px;
              background: #009DD6;
              margin-right: 0px !important;
            }
            .mobile-track-slide{
              // width: 90vw;
              // padding-right: 10px;
            }
            .mobile-track-slider-container{
              display: block;
              width: 98vw;
              // height: 100%;
              margin: 0 auto;
            }
            .mobile-track-tab-title{
              // font-family: $font-sharpSans-Medium;
              font-family: $font-SourceSans-Semibold;
              font-size: 18px;
              font-weight: 500;
            }
            .slick-slide{
              // width: 200px !important;
            }
          }
        .track-tab-header-title{
          margin-top: 30px;
          margin-bottom: 20px;
          font-family: $font-SourceSans-Semibold;
          font-size: 18px;
        }
      }
      .MuiCollapse-container-1 {
        .MuiCollapse-wrapper-3 {
          .MuiCardContent-root-5, .MuiCardContent-root-6, .MuiCardContent-root-7, .MuiCardContent-root-8, .opened-track-content {
            padding: 0px;
            .track-description {
              font-family: $font-SourceSans-Regular;
              font-size: 18px;
              line-height: 30px;
              color: #2A2A2A;
              margin-top: 2px;
              margin-bottom: 32px;
            }
            .track-tab-container {
              margin-bottom: 30px;
              div {
                margin-right: 100px;
                width: auto;
                &:last-child {
                  margin-right: 0px !important;
                }
              }
              .track-tab-title {
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
} 
// Iphone 6,7,8 plus
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .node--type-media-tech-page {
      .featured-top {
        .field--name-field-banner-info-text h2 {
          font-size: 43px;
        }
      }
    }
}
@media only screen and (max-width: 600px) {
	.node--type-page-program.node--type-media-tech-page {
		.erg_heading  {
			.employee-resource-groups {
				h2 {
					margin-top: 40px;
				}
			}
		}
	}
}

// new added for accordion css
.path-mediatech-associate-program {
	.tracks-section-wrapper {
		.all-tracks-container {
			.track-container {
				border-bottom: none !important;
				margin-bottom: 25px !important;
				.accordion__button[aria-expanded='false'] {
					h3.track-title {
						border-bottom: 1.5px solid #A7A9AC !important;
					}
				}
				.accordion__button[aria-expanded='true'] {
					h3.track-title {
						background: url("../images/resources/blue-up-arrow.png") no-repeat scroll !important;
						margin-bottom: 0px !important;
						padding: 0px 0 10px 0 !important;
						color: #009DD6 !important;
						background-size: 20px 10px !important;
						background-position: 99.7% 12px !important;
						border-bottom: none !important;
					}
				}
				.track-description {
					font-family: "SourceSansPro-Regular";
					font-size: 18px;
					line-height: 30px;
					color: #2A2A2A;
					margin-top: 2px;
					margin-bottom: 32px;
					border-bottom: 1.5px solid #A7A9AC;
				}
				.track-inner-description {
					margin-bottom: 32px;
				}
			}
		}
	}
}

@media (max-width: 991.98px) {
	.path-mediatech-associate-program {
		.tracks-section-wrapper {
			.all-tracks-container {
				.track-container {
					.accordion__button[aria-expanded='true'] {
						h3.track-title {
							background-position: 99.7% 7px !important;
						}
					}
				}
			}
		}
	}
}
// new added
.node--type-media-tech-page {
	#faqs-react-main-wrapper {
		.faqs-main-wrapper {
			.accordion__button[aria-expanded='true'] {
				h3 {
					color: #009DD6 !important;
					background: url(../images/resources/blue-up-arrow.png) no-repeat scroll !important;
					background-size: 20px 11px !important;
					-webkit-background-size: 20px 11px !important;
					background-position: 99.7% 31px !important;
				}
			}
		}
	}
}