/**
 * @file
 * Tablesort indicator styles.
 */

.tablesort {
  width: 14px;
  height: 14px;
  vertical-align: top;
  margin: 1px 0 0 5px;
}
.tablesort--asc {
  background-image: url(/core/misc/icons/ffffff/twistie-down.svg);
}
.tablesort--desc {
  background-image: url(/core/misc/icons/ffffff/twistie-up.svg);
}
