/**
 * @file
 * Visual styles for comments in Barrio.
 */

.alert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}