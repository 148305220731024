/**
 * @file
 * Styles for Bootstrap Barrio's tables.
 */

.sticky-header thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
}

.sticky-header th {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
}