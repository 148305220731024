/** Talent section */

.path-find-a-job {
    #jobs-search-message-wrapper {
        border-top: 1px solid rgba(119, 136, 153, 0.5);
        margin-top: 60px;
        .joblist-message {
            font-weight: bold;
            font-style: italic;
            font-size: 20px;
            margin-top: 20px;
        }
    }
    .talent-regions-wrapper {
        .modal-header {
            padding: 8px 10px;
            text-align: center;
            width: 100%;
            background: #eee;
            border: none;
            h2 {
                width: 100%;
                font-weight: 700;
                font-size: 18px;
                margin: 0;
                color: #000;
            }
        }
        h3 {
            text-align: center;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
        }
        .modal-body {
            padding: 35px;
        }
        .talent-external-links li {
            list-style: none !important;
        }
        .iframe-wrapper {
            width: 100%;
            min-height: 1400px;
            border: 0px;
        }
        color: #2a2a2a;
        .title {
            text-align: center;
            font-size: 40px;
            margin-top: 75px;
            font-family: $font-sharpSans-Book;
            @include media-breakpoint-down(md) {
                margin-top: 35px;
                font-size: 26px;
                text-align: left;
            }
        }
        .description {
            font-size: 18px;
            padding: 0 10%;
            margin-top: 30px;
            text-align: center;
            line-height: 30px;
            @include media-breakpoint-down(lg) {
                padding: 0 50px;
            }
            @include media-breakpoint-down(md) {
                padding: 0px;
                text-align: left;
            }
        }
        .talent-external-links {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-bottom: 50px;
            @include media-breakpoint-down(lg) {
                padding: 0 50px;
            }
            @include media-breakpoint-down(md) {
                padding: 0px;
            }
            .item-wrapper {
                background: #400095;
                margin-top: 10px;
                &:hover {
                    background: #4E535A;
                }
            }
            a {
                color: #ffffff;
                margin: auto;
                text-decoration: none;
                font-family: $font-SourceSans-Semibold;
                text-align: center;
                line-height: 24px;
                font-size: 18px;
                font-weight: 100;
                display: block;
                width: 100%;
                @include media-breakpoint-down(md) {
                    padding: 0px;
                    color: #009DD6;
                    margin: 0px;
                    text-align: left;
                }
            }
            .link-item {
                font-size: 18px;
                display: table-cell;
                vertical-align: middle;
                height: 75px;
                text-align: center;
                position: relative;
                font-family: "Source Sans Pro";
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                padding: 10px 50px;
                width: 270px;
                color: #FFFFFF;
                .fas {
                    color: #ffffff;
                    margin: auto 5px auto auto;
                    position: absolute;
                    right: 11px;
                    top: 30px;
                }
            }
        }
    }
    .talent-regions-wrapper {
        position: absolute;
        left: 50%;
        z-index: 2002;
        min-height: 400px;
        max-width: 600px;
        width: 100%;
        top: 30px;
        transform: translate(-50%, 0);
        border-radius: 3px;
        .modal-content {
            border: none
        }
    }
    .dark-background {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2001;
        width: 100vw;
        height: 100vh;
        background-color: #00000085;
    }
}

@media only screen and (max-width: 991px) {
    .path-find-a-job {
        .talent-regions-wrapper {
            position: fixed;
            top: 0px;
            left: 0px;
            overflow-y: auto;
            overflow-x: hidden;
            transform: translate(0, 0);
            height: 100%;
            width: 100%;
            border-radius: 0;
            max-width: 100%;
            .modal-content {
                width: 100%;
                height: auto;
                max-width: none;
                border-radius: 0;
            }
        }
    }
}
@media screen and (max-width: 991px) and (max-height: 600px) {
    .path-find-a-job {
        .talent-regions-wrapper {
            .modal-body {
                background: #fff;
                padding-bottom: 120px;
            }
        }
    }
}