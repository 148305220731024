.node--type-business-page {
    .featured-top {
        .field--name-field-banner-image {
            &:after {
                background: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.0001)), color-stop(27.64%, rgba(0, 0, 0, 0.0001)), color-stop(54.49%, rgba(0, 0, 0, 0.884634)), to(#000000));
                background: -o-linear-gradient(right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 27.64%, rgba(0, 0, 0, 0.884634) 54.49%, #000000 100%);
                background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 27.64%, rgba(0, 0, 0, 0.884634) 54.49%, #000000 100%);
                opacity: 0.95;
            }
        }

        .field--name-field-banner-logo-small {
            img {
                max-width: 156px !important;
				width: 100%;
				height: auto;
            }
        }
    }
}

.business-content-card {
    padding: 70px 0;

    .business-width-wrap {
        max-width: 1240px;
        padding: 0 20px;
        width: 100%;
        margin: 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .busienss-content-info {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 41%;
            flex: 0 0 41%;

            h4 {
                font-family: 'sharpSans-Medium';
                font-size: 40px;
                line-height: 48px;
                color: #2A2A2A;
                margin-bottom: 16px;
                position: relative;
            }
            .border-line {
                display: inline-block;
                width: 96px;
                height: 4px;
                margin-bottom: 32px;
            }

            p {
                font-size: 18px;
                line-height: 30px;
                color: #2A2A2A;
                padding-bottom: 25px;
                margin: 0;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .business-img-wrap {
            position: relative;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 54%;
            flex: 0 0 54%;
            background-color: #000;
            border-radius: 16px;
        }

        .video-wrap {
            position: relative;
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                background: url('https://stg.nbcunicareers.com/themes/custom/nbcucareers/images/svg/Icon_Video.svg') no-repeat;
                width: 64px;
                height: 64px;
                z-index: 10;
            }

            .content-banner-img {
                opacity: 0.5;
            }
        }

        .content-banner-img {
            width: 100%;
            border-radius: 16px;
        }

        .business-talent-btn {
            display: inline-block;
            font-size: 18px;
            font-family: "SourceSansPro-Regular";
            font-weight: 600;
            color: #FFFFFF;
            line-height: 30px;
            text-decoration: none;
            text-align: center;
            background: #000;
            padding: 7px 20px;

            .fa-arrow-right {
                padding-left: 10px;
                font-weight: 500;
                font-size: 18px;
            }
        }
    }
}

.business-video-popup {
    .modal-dialog {
        max-width: 650px;

        .modal-content {
            border: none;
            background: none;

            .modal-body {
                .video-js {
                    height: 350px;
                    width: 100%;
                }
            }
        }

        .modal-header {
            border: none;
        }

        .close {
            cursor: pointer;
            padding: 2px 5px;
            line-height: 20px;
            font-size: 40px;
            background: #ffffff;
            z-index: 999999;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            opacity: .5;
        }
    }
}

.business-open-position-section {
    .position-title {
        font-family: 'sharpSans-Medium';
        font-size: 40px;
        line-height: 48px;
        color: #2A2A2A;
        padding: 40px 0;
    }
    .open-positions-main-wrapper {
        margin-top: 0 !important;
        padding: 0 !important;
    }

    h1.main-title {
        margin-top: 0;
        margin-bottom: 0 !important;
    }
}
@media (min-width: 1200px) {
    .business-open-position-section {
        .open-positions-main-wrapper {
            padding-top: 40px !important;
        }
    }
}
@media (max-width: 1199.98px) {
    .node--type-business-page {
        .featured-top {
            .field--name-field-banner-info-text {
                .h2 {
                    letter-spacing: 1.4px;
                }
            }
        }
    }
    #business-page-react-main-wrapper {
        .business-page-react-main-wrapper {
            .open-positions-main-wrapper.container-fluid-lg {
                padding: 0 !important;
            }
        }
    }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
    .featured-top {
        .banner-content-wrapper-top {
            .banner-content-wrapper {
                padding-top: 100px;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .business-video-popup {
        .modal-dialog {
            max-width: 768px;

            .close {
                margin-right: 0px;
            }

            .modal-content {
                .modal-body {
                    .video-js {
                        height: 414px;
                    }
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .node--type-business-page {
        .featured-top {
            margin-bottom: 0;

            .banner-content-wrapper-top {
                height: auto;

                .banner-content-wrapper {
                    height: auto;
                    padding-top: 27px;
                    padding-left: 32px;
                    padding-bottom: 27px;
                }
            }

            .field--name-field-banner-image.mobile_img {
                height: 300px;
            }

            .field-collection-item--name-field-banner-content {
                .content {
                    .field--name-field-banner-image {
                        &::after {
                            background: -webkit-gradient(linear, right top, left top, color-stop(-42%, rgba(0, 0, 0, 0.0001)), color-stop(0.64%, rgba(0, 0, 0, 0.0001)), color-stop(0.49%, rgba(0, 0, 0, 0.884634)), to(#000000));
                            background: -o-linear-gradient(right, rgba(0, 0, 0, 0.0001) -42%, rgba(0, 0, 0, 0.0001) 0.64%, rgba(0, 0, 0, 0.884634) 0.49%, #000000 100%);
                            background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) -42%, rgba(0, 0, 0, 0.0001) 0.64%, rgba(0, 0, 0, 0.884634) 0.49%, #000000 100%);
                            opacity: 0.35;
                        }
                    }

                    .banner-content-wrapper-top {
                        .field--name-field-banner-logo-small {
                            left: 32px;

							img {
								width: 100% !important;
								height: auto;
							}
                        }
                    }
                }
            }
        }
    }

    .business-content-card {
        padding: 40px;

        .business-width-wrap {
            max-width: 100%;
            padding: 0;
            width: 100%;
            margin: 0 auto;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            .business-img-wrap {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                width: 100%;
                margin-bottom: 40px;
            }
        }

        .business-width-wrap.reverse-column {
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            .busienss-content-info {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                .border-line {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .node--type-business-page {
        .featured-top {
            .banner-content-wrapper-top {
                .banner-content-wrapper {
                    padding: 32px 22px;
                }
            }

            .field--name-field-banner-info-text {
                .h2 {
                    letter-spacing: 0.02em;
                }

                .cd-intro {
                    .cd-words-wrapper {
                        padding-bottom: 0;
                    }
                }
            }

            .field--name-field-banner-image.mobile_img {
                height: 320px;
            }
        }
    }

    .business-content-card {
        padding: 40px 20px 30px;

        .business-width-wrap {
            .busienss-content-info {
                h4 {
                    font-size: 26px;
                }
                .border-line {
                    margin-bottom: 24px;
                }
            }
        }
    }

    .business-video-popup {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .video-js {
                        height: 205px;
                    }
                }
            }
        }
    }

    .business-open-position-section {
        .position-title {
            display: none;
        }
    }
}

@media only screen and (max-width: 400px) {
    .business-video-popup {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .video-js {
                        height: 184px;
                    }
                }
            }
        }
    }
}

// talent page css starts from here
.node--type-talent-community-page {
    .featured-top {
        .field--name-field-banner-image.desktop {
            height: 775px !important;
        }
        .field--name-field-banner-image {
            img {
                margin: 0 !important;
                object-fit: cover;
            }
            &:after {
                background: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.0001)), color-stop(27.64%, rgba(0, 0, 0, 0.0001)), color-stop(54.49%, rgba(0, 0, 0, 0.884634)), to(#000000));
                background: -o-linear-gradient(right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 27.64%, rgba(0, 0, 0, 0.884634) 54.49%, #000000 100%);
                background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 27.64%, rgba(0, 0, 0, 0.884634) 54.49%, #000000 100%);
                opacity: 0.95;
            }
        }
    }

    .site-footer {
        margin-top: 0;
    }
}

.talent-banner-wrapper {
    .talent-main-wrapper {
        width: 46%;
        .heading {
            font-size: 60px;
            line-height: 74px;
            color: #FFFFFF;
            font-family: 'sharpsans-light';
        }
        .field--name-field-banner-desc {
            font-size: 18px;
            line-height: 30px;
            color: #FFFFFF;
            font-family: 'SourceSansPro-Regular';
            margin: 24px 0;
        }
        .talent-btn {
            background: #FFFFFF;
            padding: 7px 20px;
            display: inline-flex;
            align-items: center;
            font-size: 18px;
            line-height: 30px;
            color: #000000;
            border: none;
            font-family: 'SourceSansPro-Semibold';
            margin-top: 8px;

            .fas {
                font-size: 16px;
                padding-left: 10px;
            }

            &:active {
                background-color: #FFFFFF !important;
                color: #000000 !important;
            }
        }
    }
}

@media (max-width: 991.98px) {
    .node--type-talent-community-page {
        .featured-top {
            .banner-content-wrapper-top {
                .banner-content-wrapper {
                    padding: 40px 30px;
                }
            }
        }
    }
    .talent-banner-wrapper {
        .talent-main-wrapper {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
    .node--type-talent-community-page .featured-top .banner-content-wrapper-top .banner-content-wrapper {
        padding-top: 45px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .node--type-talent-community-page {
        .featured-top {
            .field--name-field-banner-image.mobile_img {
                background-size: auto;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .node--type-talent-community-page {
        .featured-top {
            .banner-content-wrapper-top {
                .banner-content-wrapper {
                    padding: 32px 22px;
                }
            }
        }
    }
    .talent-banner-wrapper {
        .talent-main-wrapper {
            .heading {
                font-size: 42px;
                line-height: 52px;
            }
        }
    }
}