/*
Theme Name: Bootstrap 4 Sass
Description: Bootstrap 4 with Sass
*/

// import files
@import "import";

/* GENERAL STYLES
-------------------------------------------------*/

body {
	font-family: $font-SourceSans-Regular;
    background: #FFFFFF !important;
}
/* body.modal-open {
	padding-right: 0px !important;
} */

.navbar-collapse {
	font-family: $font-sharpSans-Semibold;
}
/* COLORS
--------------------------------------------------*/

a {
    color: #2A2A2A;
}
.btn {
	font-size: 18px;
	border-radius: 0px;
}
.bg-inverse {
    background: $primary-shade !important;
}

.site-footer {
    background: #EEEEEE	 !important;
	margin-top: 80px;
    position: relative; 
}
.styles_overlay__CLSq-, .modal-backdrop{
	    background: rgba(255, 255, 255, 0.9);
}
@include media-breakpoint-down(lg) {
	.styles_overlay__CLSq-{
		padding: 2.2rem;
	}
}

.mobile_logo_small {
	display:none;
}

.menu--main li a {
    color: $primary-shade;
}

.menu--account li a {
    color: $primary-shade;
}

.site-name-slogan a {
    text-decoration: none;
}

.card-group img {
    margin: 0;
    width: 100%;
    height: auto;
}

.card-group .field--type-image {
    margin: 0
}

.slick {
    width: 100%;
}

.slick>div {
    margin: 0 15px;
}

.container {
    max-width: 1280px;
}

.extra-width{
    max-width: 1440px;
}
.header, .highlighted, .featured-top, #main-wrapper, .container-fluid-xl{
	 //max-width: 1440px;
	 margin: 0 auto;
}
.container-fluid-xl {
	max-width: 1440px;
}
.featured-top {
	margin-bottom: 55px;
}
.site-footer .container-fluid , .container-fluid-lg{
	 max-width: 1240px;
	 margin: 0 auto;
	 padding: 0px;
	 @include media-breakpoint-down(lg) {
		padding: 0px 20px;
	 }
}
.container-fluid-md {
	 max-width: 991px;
	 margin: 0 auto;
	 padding: 20px;
}
#main-wrapper .container-fluid {
	padding: 0px;
}
#main-wrapper .container-fluid  > .row {
	margin: 0px;
}
.text-heading {
	color: #009DD6;
}
.text-black {
	color: #000000;
}
.video-thumbnail {
  position: relative;
  cursor: pointer;
  &:before {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: url('../images/svg/Icon_Video.svg');
    width: 75px;
    color: #fff;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  &:hover:before {
    color: #eee;
  }
}

#back-to-top {
    position: absolute;
    bottom: 30%;
    right: 40px;
    z-index: 9900;
    text-align: center;
    line-height: 30px;
    color: #444;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
}

.btn-light.disabled, .btn-light:disabled {
    color: #FFFFFF;
    background-color: #929599;
}

.video-js:not(.vjs-fullscreen).vjs-layout-tiny .vjs-subs-caps-button,
.video-js:not(.vjs-fullscreen).vjs-layout-x-small:not(.vjs-live) .vjs-subs-caps-button,
.video-js:not(.vjs-fullscreen).vjs-layout-x-small:not(.vjs-liveui) .vjs-subs-caps-button {
	display: block !important;
}
.vjs-layout-tiny .vjs-menu-button-popup .vjs-menu .vjs-menu-content, .vjs-layout-x-small .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
	max-height: 14em;
}
@media only screen and (max-width: 5000px) {
	#back-to-top.show {
			display: block;
	}
}
.home-internship-talent-banner {
    margin: 40px 0 0;
	background: #F8F8F8;
    border-bottom: 1px solid #F1F1F1;
    padding: 40px 0;
}

// new talent communit popup css
.talent-community-popup {
    display: flex;
    position: fixed;
    bottom: 12px;
    right: 24px;
    border-radius: 12px;
    border: 1px solid #000000;
    background: #FFFFFF;
    z-index: 9900;

    .close-talent {
        position: absolute;
        top: -5px;
        left: -5px;
        cursor: pointer;
    }

    .talent-community-popup-link {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        color: #000000;
        padding: 8px 12px;
        text-decoration: none;
        cursor: pointer;

        img {
            margin-right: 8px;
        }

        .fas {
            margin: 2px 0 0 8px;
            font-size: 15px;
            line-height: 16px;
            color: #2a2a2a;
            font-weight: 900;
        }
    }
}
.cookie-check {
    display: none;
}

.talent-regions-wrapper {
    position: absolute;
    left: 50%;
    z-index: 9991;
    min-height: 400px;
    max-width: 600px;
    width: 100%;
    top: 30px;
    transform: translate(-50%, 0);
    border-radius: 3px;

    .modal-content {
        border: none
    }

    .modal-header {
        padding: 8px 10px;
        text-align: center;
        width: 100%;
        background: #eee;
        border: none;

        h2 {
            width: 100%;
            font-weight: 700;
            font-size: 18px;
            margin: 0;
            color: #000;
        }
    }

    h3 {
        text-align: center;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
    }

    .modal-body {
        padding: 35px;
    }

    .talent-external-links li {
        list-style: none !important;
    }

    .iframe-wrapper {
        width: 100%;
        min-height: 1400px;
        border: 0px;
    }

    color: #2a2a2a;

    .title {
        text-align: center;
        font-size: 40px;
        margin-top: 75px;
        font-family: $font-sharpSans-Book;

        @include media-breakpoint-down(md) {
            margin-top: 35px;
            font-size: 26px;
            text-align: left;
        }
    }

    .description {
        font-size: 18px;
        padding: 0 10%;
        margin-top: 30px;
        text-align: center;
        line-height: 30px;

        @include media-breakpoint-down(lg) {
            padding: 0 50px;
        }

        @include media-breakpoint-down(md) {
            padding: 0px;
            text-align: left;
        }
    }

    .talent-external-links {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-bottom: 50px;

        @include media-breakpoint-down(lg) {
            padding: 0 50px;
        }

        @include media-breakpoint-down(md) {
            padding: 0px;
        }

        .item-wrapper {
            background: #400095;
            margin-top: 10px;

            &:hover {
                background: #4E535A;
            }
        }

        a {
            color: #ffffff;
            margin: auto;
            text-decoration: none;
            font-family: $font-SourceSans-Semibold;
            text-align: center;
            line-height: 24px;
            font-size: 18px;
            font-weight: 100;
            display: block;
            width: 100%;

            @include media-breakpoint-down(md) {
                padding: 0px;
                color: #009DD6;
                margin: 0px;
                text-align: left;
            }
        }

        .link-item {
            font-size: 18px;
            display: table-cell;
            vertical-align: middle;
            height: 75px;
            text-align: center;
            position: relative;
            font-family: "Source Sans Pro";
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 10px 50px;
            width: 270px;
            color: #FFFFFF;

            .fas {
                color: #ffffff;
                margin: auto 5px auto auto;
                position: absolute;
                right: 11px;
                top: 30px;
            }
        }
    }
}

.dark-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2001;
    width: 100vw;
    height: 100vh;
    background-color: #00000085;
}

// talent community banner section css
.talent-banner-section {
    display: flex;
    margin: 0;
    padding: 0;
    .talent-banner-img {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            @include media-breakpoint-down(md){
                height: auto;
            }
    
            @include media-breakpoint-down(sm){
                height: auto;
            }
        }
    }

    .talent-banner-content {
        max-width: 480px;
        color: #2A2A2A;
        padding: 40px 30px;
        align-self: center;

        @include media-breakpoint-down(lg){
			padding: 50px 30px;
            max-width: 430px;
		}

        @include media-breakpoint-down(md){
            max-width: 100%;
            padding: 48px;
        }

        @include media-breakpoint-down(sm){
            max-width: 100%;
            padding: 48px 24px;
        }

        h2 {
            font-size: 40px;
            line-height: 48px;
            font-family: "sharpSans-Medium";
            margin-bottom: 20px;
            padding-right: 45px;

            @include media-breakpoint-down(lg){
                padding-right: 15px;
            }

            @include media-breakpoint-down(md){
                font-size: 32px;
                padding-right: 0;
            }
        }

        p {
            font-size: 18px;
            line-height: 30px;
            font-family: "SourceSansPro-Regular";
            margin-bottom: 32px;

            @include media-breakpoint-down(md){
                margin-bottom: 40px;
            }
        }

        .talent-btn {
            background: #000000;
            padding: 7px 20px;
            display: inline-flex;
            align-items: center;
            line-height: 30px;

            .fas {
                font-size: 16px;
                padding-left: 10px;
            }
        }
    }
}
// 
@media (min-width: 1440px) {
    .talent-banner-section {
        max-width: 1260px;
        width: 100%;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 991px) {
    .talent-community-popup {
        bottom: initial;
        top: 85svh;
    }

    .talent-regions-wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        transform: translate(0, 0);
        height: 100%;
        width: 100%;
        border-radius: 0;
        max-width: 100%;

        .modal-content {
            width: 100%;
            height: auto;
            max-width: none;
            border-radius: 0;
        }
    }

    #back-to-top {
        bottom: 12%;
    }

}

@media screen and (max-width: 991px) and (max-height: 600px) {
    .talent-regions-wrapper {
        .modal-body {
            background: #fff;
            padding-bottom: 120px;
        }
    }

}

@media only screen and (max-width: 767px) {
    .talent-community-popup {
        top: 90svh;
        right: 16px;

        .talent-community-popup-link {
            img {
                width: 72px;
                height: 37px;
            }
        }
    }

    #back-to-top {
        bottom: 26px;
    }

}

.what_nbcuniversal, .path-culture .slider-for, .path-culture .slider-nav,  .node--type-page-program .slider-for, .node--type-page-program .slider-nav {display: none !important;}
.what_nbcuniversal.slick-initialized, .path-culture .slider-for.slick-initialized, .path-culture .slider-nav.slick-initialized, .node--type-page-program .slider-for.slick-initialized, .node--type-page-program .slider-nav.slick-initialized {display: block !important;}