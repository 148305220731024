.hide-onload {
	display:none;
}
.path-culture {
	.culture_testimonials {
		padding:50px 0px 80px;
	}
	.vjs-poster {
		background-size: 100% 100%;
	}
	.container-fluid-lg {
		@include media-breakpoint-down(sm){
			padding: 0px 15px;
		}
	}
	.featured-top {
		/* margin-bottom:30px !important; */
		.banner-content-wrapper-top .banner-content-wrapper{
			padding-bottom:15%;
			@include media-breakpoint-down(md){
				padding-bottom:1px;
			}
		}
		.fa-search, .fa-arrow-right {
			cursor:pointer;
		}
	}
	.row.no-gutters.culture_sec_banner {
		margin-top:100px;
		@include media-breakpoint-down(md){
			margin-top:0px;
		}
	}
	.main-content .tab3{
		margin-right: 0px !important;
		margin-left:0px;
	}
	.slick-next {
		right:2px;
	}
	.bg-img-text-award{
		background-image:url("../images/svg/text_background_image.png");
		/*background-size: 70% 135%;*/
		background-size: contain;
		background-position: right 0% bottom 0%;
		background-repeat: no-repeat;
		@include media-breakpoint-down(sm){
			background-size: 340px 344px;
		}
	}
	.bg-img-text-social{
		background-color: #EEEEEE;
		background-image:url("../images/svg/culture_text_bg_white.png");
		/*background-size: 70% 65%;*/
		background-size: contain;
		background-position: right 0% bottom 0%;
		background-repeat: no-repeat;
		@include media-breakpoint-down(md){
			background: none;
			background-color: #EEEEEE;
		}
		.social-diversity {
			@include media-breakpoint-down(sm){
				padding:0px 15px;
			}
		}
	}
	.site-footer{
		margin-top:0px;
	}
	.brand-page-cont h2{
		font-family:$font-sharpSans-Book;
		margin-bottom: 40px;
		@include media-breakpoint-down(xs){
			font-size:26px;
		}
		@include media-breakpoint-down(sm){
			font-size:26px;
		}
	}
	.single-slider-main:after,.single-slider-main:before{
		z-index: -1;
		position: absolute;
		content: "";
		bottom: 20px;
		left: 10px;
		width: 50%;
		top: 80%;
		max-width: 300px;
		background: #EEE;
		-webkit-box-shadow: 0 16px 17px #777;
		box-shadow: 0 16px 17px #777;
		-webkit-transform: rotate(-4deg);
		-o-transform: rotate(-4deg);
		transform: rotate(-4deg);
	}
	.single-slider-main:after {
		-webkit-transform: rotate(4deg);
		-moz-transform: rotate(4deg);
		-o-transform: rotate(4deg);
		-ms-transform: rotate(4deg);
		transform: rotate(4deg);
		right: 10px;
		left: auto;
	}
	.hover-awards-and-recognition .single-slider-main:hover:before,.hover-awards-and-recognition .single-slider-main:hover:after{
		-webkit-box-shadow:0 43px 11px #777;
		-moz-box-shadow:0 43px 11px #777;
		box-shadow:0 43px 11px #777;
		bottom: 0px;
		height: 25px;
		@include media-breakpoint-down(lg) {
			-webkit-box-shadow:0 28px 11px #777;
			-moz-box-shadow:0 28px 11px #777;
			box-shadow:0 28px 11px #777;
		}
	}
	.testimonial-nav-main:after,.testimonial-nav-main:before{
		z-index: -1;
		position: absolute;
		content: "";
		bottom: 19px;
		left: 10px;
		width: 50%;
		top: 80%;
		max-width: 300px;
		background: #EEE;
		-webkit-box-shadow:0 6px 12px #777;
		box-shadow: 0 6px 12px #777;
		-webkit-transform: rotate(-3deg);
		-o-transform: rotate(-3deg);
		transform: rotate(-3deg);
	}
	.testimonial-nav-main:before {
		@include media-breakpoint-down(sm){
			left: 20px; 
		}
	}
	.testimonial-nav-main{
		position:relative;
	}
	#awards-and-recognition {
		padding-top:72px;
	}
	.testimonial-nav-main:after {
		-webkit-transform: rotate(3deg);
		-moz-transform: rotate(3deg);
		-o-transform: rotate(3deg);
		-ms-transform: rotate(3deg);
		transform: rotate(3deg);
		right: 28px;
		left: auto;
	}
	.hover-testimonial-nav-main .testimonial-nav-main:hover:before,.hover-testimonial-nav-main .testimonial-nav-main:hover:after{
		-webkit-box-shadow: 0 8px 9px #777;
		-moz-box-shadow:0 8px 9px #777;
		box-shadow:0 8px 9px #777;
	}
	
	.slick-arrow {
		border-radius: 0px !important;
		height: 20px;
		width: 21px;
		z-index: 1;
		display:none;
	}
	.back_fade {
		position: fixed;
		top: 0px;
		left: 0px;
		bottom: 0px;
		right: 0px;
		background: #fff;
		z-index: 1050;
		opacity: .8;
		display: none;
	}
	.slick-arrow:hover {
		border:1px solid #FFF;
	}
	.slick-arrow::before {
		content:' ' !important;
		font-size:0px !important;
	}
	.carousel-control-next-icon, .carousel-control-prev-icon {
		border: 1px solid transparent;
		background-color: transparent;
		display:block;
		background-size:100% 100% !important;
		background-repeat:no-repeat;
		display: block;
	}
	.carousel-control-next-icon {
		background-image: url(../images/icons/carousel-right.png) !important;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-property: transform;
		transition-property: transform;
	}
	.carousel-control-next-icon:hover {
		background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Right.svg) !important;
		-webkit-transform : scale(1.2); /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform     : scale(1.2); /* IE 9 */
		transform         : scale(1.2);
		background-size:100% 100%;
		background-repeat:no-repeat;
	}
	.carousel-control-prev-icon {
		background-image: url(../images/icons/carousel-left.png) !important;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-property: transform;
		transition-property: transform;
	}
	.carousel-control-prev-icon:hover {
		background-image: url(../images/icons/Icon_Accordion_Large_Active_Blue_Left.svg) !important;
		-webkit-transform : scale(1.2); /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform     : scale(1.2); /* IE 9 */
		transform         : scale(1.2);
		background-size:100% 100%;
		background-repeat:no-repeat;
	}
	.culture-title{
		@include media-breakpoint-down(md){
			padding:0px;
		}	
		h2.side-title {
			font-size:23px;
			font-family:$font-sharpSans-Semibold;
			@include media-breakpoint-down(sm){
				font-size:20px;
				padding-left:15px;
			}
      @include media-breakpoint-down(md){
				padding-left:15px;
			}
			span {
				padding-right:5px;
			}
		}
		.title-border {
			border-bottom: 6px solid #F36A30;
			width:75px;
			margin-bottom: 61px;
			@include media-breakpoint-down(sm){
				margin-bottom: 60px;
				margin-left:15px;
			}
      @include media-breakpoint-down(xs){
				margin-bottom: 60px;
				margin-left:15px;
			}
      @include media-breakpoint-down(md){
				margin-bottom: 60px;
				margin-left:15px;
			}
		}
		a {
			color: #686868;
			text-decoration: none;
			font-family:$font-SourceSans-Semibold;
			font-size:20px;
			line-height:27px;
			@include media-breakpoint-down(sm){
				font-size:18px;
				line-height:45px;
			}
		}
		a:hover {
			color: #F36A30;
			font-family:$font-SourceSans-Semibold;
			font-size:20px;
			line-height:27px;
			@include media-breakpoint-down(sm){
				font-size:18px;
				line-height:45px;
			}
		}
		p{
			@include media-breakpoint-down(md){
				margin-bottom: 0px;
				padding-left:15px;
			}
		}
	}		

	.tab3,.culture_awards_recognition {
		margin-top: 45px;
		@include media-breakpoint-down(xs){
			margin-top: 10%;
		}
		@include media-breakpoint-down(sm){
			margin-top: 10%;
		}
		@include media-breakpoint-down(md){
			margin-top: 0%;
		}
	}
	.tab3 {
		margin-top: 10px !important;
		.inner-content {
			.tab3_content {
				.text-white {
					line-height:36px;
					font-family:$font-sharpSans-Book;
					font-size:26px;
				}
			}
		}
	}
	.culture_awards_recognition{
		padding-bottom:70px;
	}
	.erg_heading{
		padding-top: 45px;
		h4 {
			font-size:26px;
			font-family:$font-sharpSans-Semibold;
			@include media-breakpoint-down(sm){
				padding: 25px 0 0;
			}
		}
		@include media-breakpoint-down(md) {
			margin-left:auto;
			margin-right:auto;
			text-align:center;
			margin-bottom: 50px;
		}
		@include media-breakpoint-down(sm){
			margin-bottom: 0px;
			padding-top: 0px;
			padding-bottom: 0px;
		}
	}
	.erg_groups.mobile-hide{
		width: 1000px;
		margin: 0 auto;
		margin-top: 5%;
	}
	.second_content_culture .second_content_div h4{
		    font-size: 20px;
			line-height: 28px;
			font-family:$font-SourceSans-Bold;
			padding-bottom: 40px;
			text-transform: uppercase;
			@include media-breakpoint-down(sm){
				padding-bottom: 0px;
				padding-right: 15px;
			}
	}
	.second_content_culture .second_content_div h2{
			font-size: 40px;
			line-height: 46px;
			font-family:$font-sharpSans-Medium;
			padding-bottom: 20px;
	}
	.second_content_culture .second_content_div p{
			font-size: 18px;
			line-height: 30px;
			font-family:$font-SourceSans-Regular;
			word-spacing: 1px;
			overflow:hidden;
			@include media-breakpoint-up(lg){
				max-height: 180px;
			}
			@include media-breakpoint-up(xl){
				max-height: 210px;
				margin-bottom:0px;
			}
	}
	.second_content_culture.noHeader .second_content_div p{
		@include media-breakpoint-up(lg){
			max-height: 305px;
		}
		@include media-breakpoint-up(xl){
			max-height: 300px;
		}
	}
	.second_content_culture .second_content_div{
	    padding: 75px 0px 75px 60px;
		@include media-breakpoint-down(lg){
			padding: 75px 20px 50px 20px;
		}
		@include media-breakpoint-down(sm){
			h4{
				 font-size: 18px;
				 text-transform: uppercase;
			}
			h2{
				 font-size: 26px;
			}
			p{
				font-size: 18px;
				line-height:35px;
			}
		}
	}
	.tab1-title, .tab2-title {
		color: #0079C0;
		margin-top: 4%;
		margin-bottom: 4%;
		font-size:40px;
		line-height:46px;
		font-family:$font-sharpSans-Book;
		@include media-breakpoint-down(sm){
			font-size:26px;
		    line-height:30px;
		}
	}
	.tab1 .left-image img {
		width:100%;
		height:100%;
		object-fit:cover;
	}
	#talent-development {
		.left-image {
			padding: 0px 0px 70px 0px;
			@include media-breakpoint-down(md) {
				padding: 0px;
			}
			@include media-breakpoint-down(sm){
				padding: 0px;
			}
		}
		.right-content { 
			.inner-content {
				padding:0px 15px 70px 35px;
				@include media-breakpoint-down(lg){
					padding: 0px 30px 60px 30px;
				}
				@include media-breakpoint-down(sm){
					padding: 0px;
				}
				img {
					width:250px;
					@include media-breakpoint-down(lg){
						padding-top: 0px;
					}
					@include media-breakpoint-down(md){
						padding-top: 20px;
						width:100%;
					}
				}
				.tab1-title {
					color: #0079C0;
					font-size: 30px;
					line-height: 36px;
				}
				a {
					color:#212529;
				}
			}
		}
	}
	.tab2 {
		.right-content {
			padding-left:0px;
			@include media-breakpoint-down(md) {
				padding:0px 15px;
			}
			.inner-content {
				padding:10%;
				@include media-breakpoint-down(md) {
					padding:20px 0px;
				}
			}
		}
	}
	.tab2 .left-image img, .tab3 .left-image img, .erg_heading h2 {
		width: 100%;
	}
	.culture_sec_banner .spotlight_second_img img {
		width: 100%;
		height:100%;
		max-height:520px;
	}
	.tab2 .left-image img {
		margin-bottom: 70px;
	}
	 .erg_heading h2{
		font-size:40px;
		line-height:46px;
		font-family:$font-sharpSans-Book;
		@include media-breakpoint-down(xs){
			font-size:26px;
		}
		@include media-breakpoint-down(sm){
			font-size:26px;
		}
	 }
	.slick-arrow {
		display:none;
	}
	@include media-breakpoint-down(sm){
			.mobile-tab3-outer{
				padding:0 !important;
			}
			.mobile-outer-award{
				padding:0px !important;
				margin:45px 0px 50px 0px;
				.slick-list {
					width: 100% !important;
					margin: auto;
				}
				.close-button {
					right:30px !important;
					top: -6px;
				}
				.slider-testi-for-parent {
					.slick-list {
						width: 85% !important;
						margin: auto;
					}
				}
			}
	}
	@include media-breakpoint-down(md){
			.mobile-tab3-outer{
				padding:0 !important;
			}
			.brand-page-cont h2{
				font-size:26px;
			}
			.culture_awards_recognition .carousel-control-next-icon,.culture_awards_recognition .carousel-control-prev-icon{
				display:none;
			}
	}
	.tab3 .right-content {
		background-color: #F36A30;
		background-image:url("../images/svg/ERG_Art_lightWhite.png");
		background-size: 90% 90%;
		background-position: right 0% bottom 0%;
		background-repeat: no-repeat;
		@include media-breakpoint-down(sm){
			background-size: 100% 100%;
		}
	}
	.erg_groups.desktop-hide{
		@include media-breakpoint-down(md) {
				margin-left:15px !important;
				margin-right:15px !important;
		}
		@include media-breakpoint-down(sm){
			margin-left:15px !important;
			margin-right:15px !important;
			margin-bottom: 70px;
		}
		.collapse.show {
			@include media-breakpoint-down(md) {
				border-bottom:2px solid #f06e37;
			}
		}
		.card-body {
			@include media-breakpoint-down(md){
				padding:20px 0px;
			}
			img {
				@include media-breakpoint-down(md){
					margin-bottom:30px;
				}
			}
			p {
				@include media-breakpoint-down(md){
					font-size: 18px;
					line-height: 35px;
					font-family:$font-SourceSans-Regular;
					word-spacing: 1px;
				}
			}
		}
	}
	.tab1 .left-image{
		    padding: 70px 0px 0px 0px;
			@include media-breakpoint-down(md) {
				padding: 0px;
			}
			@include media-breakpoint-down(sm){
				padding: 0px;				
			}
	}
	.tab2 .left-image{
		    padding: 70px 0px 0px 0px;
			@include media-breakpoint-down(md) {
				padding: 0px;
			}
			@include media-breakpoint-down(sm){
				padding: 0px;
				img{
					margin-bottom: 0px;
				}
			}
	}
	.tab1, .tab2 {
		margin:0px;
		@include media-breakpoint-down(md) {
			margin:0px -15px;
		}
	}
	.tab3 .left-image {
		padding: 0px 0px 0px 20px;
		@include media-breakpoint-down(sm){
				padding: 0px 0px 0px 0px;
				img{
					height:250px;
				}
		}
	}
	.tab3 .right-content{
		padding: 0px 20px 0px 20px;
	}
	.culture_sec_banner .spotlight_second_img {
		padding:0px;
	}
	.tab3_content span.text-black{
		font-size:70px;
		font-family:$font-sharpSans-Bold;
		line-height:74px;
		@include media-breakpoint-down(xs){
			font-size:30px;
			line-height:38px;
		}
		@include media-breakpoint-down(sm){
			font-size:30px;
			line-height:38px;
		}
	}
	.tab3_content span.text-white{
		font-size:70px;
		font-family:$font-sharpSans-Light;
		line-height:74px;
		padding-top:20px;
		@include media-breakpoint-down(xs){
			font-size:30px;
			line-height:38px;
		}
		@include media-breakpoint-down(sm){
			font-size:30px;
			line-height:38px;
		}
	}
	.tab3_content p.text-white{
		font-size:26px;
		font-family:$font-sharpSans-Book;
		line-height:36px;
		padding-top:40px;
		@include media-breakpoint-down(sm){
			font-size:18px;
			line-height:35px;
		}
	}
	#employee-resource-groups h2{
		font-size:40px;
		line-height:46px;
		margin-top: 34px;
		font-family:$font-sharpSans-Book;
		margin-bottom: 40px;
	}
	
	.right-content .inner-content {
		padding: 70px 35px 0px;
		@include media-breakpoint-down(lg){
				padding: 30px;
				img {
					padding-top: 20px;
				}
		}
		@include media-breakpoint-down(sm){
			padding: 0px;
		}
		p{
			font-size:18px;
			line-height:30px;
			font-family:$font-SourceSans-Regular;
			padding-right: 10px;
			@include media-breakpoint-down(sm){
				font-size:18px;
				line-height:35px;
				padding-right: 0px;
			}
		}
		a{
			font-size:18px;
			line-height:30px;
			font-family:$font-SourceSans-Semibold;
			text-decoration: none;
			color:#0079C0;
			@include media-breakpoint-down(sm){
				font-size:18px;
				line-height:35px;
			}
		}
	}
	.erg_groups .erg_img {
		text-align: center;
		margin-bottom: 55px;
	}
	.erg_groups .erg_img img{
		margin: 0 auto;
	}
	.slider-for-parent {
		position:relative;
		.close-button {
			padding: 0;
			background-color: transparent;
			border: 0;
			font-size: 34px;
			font-weight: 700;
			line-height: 15px;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			opacity: .5;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			z-index: 1800;
			position: absolute;
			right: 30px;
		}
		.slick-list{
			width: 85%;
			margin: 0 auto;
		}
		.slider {
			z-index: 1500;
		}
		.single-slider-img {
			background-color: #ccc;
			.slider-left{
				width: 60%;
				float: left;
				position: relative;
				height: 620px;
				 @include media-breakpoint-down(md){
					height: 378px;
				 }
				img {
					max-height: 620px;
					max-width: 100%;
					width: 100%;
					height: auto;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
				}
				.slider-title{
					display: none;
					position: absolute;
					top: 15%;
					margin-left: 5%;
					font-size: 35px;
					width: 85%;	
				}	
			}
			.slider-right {			
				.slider-learn-more {
					margin-top: 50px;
					a {
						border-radius: 0;
					}
				}
			}
		}
		.cr-sky-blue {
			.slider-right {				
				background-color: #2ba9e0;				
			}
			.slider-learn-more a:hover{
					color: #2ba9e0;
			}
		}
		.cr-lavendar {
			.slider-right {				
				background-color: #886db0;				
			}
			.slider-learn-more a:hover{				
					color: #886db0;
			}
		}
		.cr-light-green {
			.slider-right {				
				background-color: #59a63f;				
			}
			.slider-learn-more a:hover{				
					color: #59a63f;
			}
		}
		.cr-orange {
			.slider-right {				
				background-color: #F36A30;				
			}
			.slider-learn-more a:hover{				
					color: #F36A30;
			}
		}
		.cr-pink {
			.slider-right {				
				background-color: #D0118C;				
			}
			.slider-learn-more a:hover{				
					color: #D0118C;
			}
		}		
		.slider-right{
			width: 40%;
			float: left;
			padding: 5%;
			color: #fff;
			min-height: 620px;			
			.slider-learn-more {
				a{
					/* color: #000;
					background-color: #fff;
					padding: 3% 3% 3% 3%;
					font-size: 12px;
					font-weight: bold;
					text-decoration: none; */
					i {
						margin: 5px;
					}
				}
				a:hover{
					background-color: #fff;
				}
			}
		}		
	} 
	.slider-nav-parent {
		.single-slider-main {
			background: #eee;
			margin: .5rem;
			position: relative;
			min-height: 330px;
			padding: 8% 5% 5% 8%;
			margin-bottom:15px;
			transition-duration: 0.3s;
			-webkit-transition-property: color, background-color;
			transition-property: color, background-color;
			@include media-breakpoint-down(md){
				min-height: 250px;
			}
			.slider-title{
				font-size:24px;
				line-height:32px;
				font-family:$font-sharpSans-Semibold;
				padding-top: 26px;
				@include media-breakpoint-down(md){
					font-size:20px;
				}
			}
			.cultureAwardsWhiteLogo {
				display:none;
			}
			.cultureAwardsDefaultLogo {
				display:block;
			}
		}
		.single-slider-main:hover {
			.cultureAwardsWhiteLogo {
				display:block;
			}
			.cultureAwardsDefaultLogo {
				display:none;
			}
		}
		.slick-list{
			width: 85%;
			margin: 0 auto;
			@include media-breakpoint-down(lg){
				width: 100%;
			}
		}
		.slider-bottom{
			position: absolute;
			top: 77%;
			width: 100%;
			.trophy-text{
				font-family: "sharpSans-Bold";
				color: #A7A9AC;
			}
			.trophy-img{
				width:64px;
				height:58px;
				position: absolute;
				top: -26%;
				right: 18%;
				font-size: 58px;
				color: #a09f9f;
				background-image: url(../images/svg/icon_trophy.svg);
				background-repeat: no-repeat;
			}
		}
		.cr-sky-blue {
			.slider-title {				
				color: #0079C0;				
			}			
		}
		.cr-sky-blue:hover {
			background-color: #009DD6;
			.slider-title, .slider-bottom .trophy-text{				
				color: #fff;			
			}
			.slider-bottom .trophy-img{
				width:64px;
				height:58px;
				position: absolute;
				top: -26%;
				right: 18%;
				font-size: 58px;
				color: #a09f9f;
				background-image: url(../images/svg/icon_trophy_white.svg);
				background-repeat: no-repeat;
				@include media-breakpoint-down(sm) {
					padding: 0px 20px;
					top: -4%;
					right: 7%;
				}
			}
		}
		.cr-lavendar {
			.slider-title {				
				color: #886db0;				
			}			
		}
		.cr-lavendar:hover {
			background-color: #886db0;
			.slider-title, .slider-bottom .trophy-text{				
				color: #fff;			
			}
			.slider-bottom .trophy-img{
				width:64px;
				height:58px;
				position: absolute;
				top: -26%;
				right: 18%;
				font-size: 58px;
				color: #a09f9f;
				background-image: url(../images/svg/icon_trophy_white.svg);
				background-repeat: no-repeat;
				@include media-breakpoint-down(sm) {
					padding: 0px 20px;
					top: -4%;
					right: 7%;
				}
			}
		}
		.cr-light-green {
			.slider-title {				
				color: #59a63f;				
			}			
		}
		.cr-light-green:hover {
			background-color: #59a63f;
			.slider-title, .slider-bottom .trophy-text {				
				color: #fff;			
			}
			.slider-bottom .trophy-img{
				width:64px;
				height:58px;
				position: absolute;
				top: -26%;
				right: 18%;
				font-size: 58px;
				color: #a09f9f;
				background-image: url(../images/svg/icon_trophy_white.svg);
				background-repeat: no-repeat;
			}
		}
		.cr-orange {
			.slider-title {				
				color: #F36A30;				
			}			
		}
		.cr-orange:hover {
			background-color: #F36A30;
			.slider-title, .slider-bottom .trophy-text {				
				color: #fff;			
			}
			.slider-bottom .trophy-img{
				width:64px;
				height:58px;
				position: absolute;
				top: -26%;
				right: 18%;
				font-size: 58px;
				color: #a09f9f;
				background-image: url(../images/svg/icon_trophy_white.svg);
				background-repeat: no-repeat;
			}
		}
		.cr-pink {
			.slider-title {				
				color: #D0118C;				
			}			
		}
		.cr-pink:hover {
			background-color: #D0118C;
			.slider-title, .slider-bottom .trophy-text {				
				color: #fff;			
			}
			.slider-bottom .trophy-img{
				width:64px;
				height:58px;
				position: absolute;
				top: -26%;
				right: 18%;
				font-size: 58px;
				color: #a09f9f;
				background-image: url(../images/svg/icon_trophy_white.svg);
				background-repeat: no-repeat;
			}
		}
	}
	
	.slider-testi-nav-parent {
		.slick-list{
			width: 85%;
			margin: 0 auto;
			@include media-breakpoint-down(lg){
				width: 100%;
			}
		}
		.testimonial-nav-main {
				padding: 10px 20px 14px 0px;
				@include media-breakpoint-down(sm){
					padding: 10px 7.5px 14px 7.5px;
				}
				.user-image {
					position: relative;
					height: 260px;
					vertical-align: middle;
					border: 1px solid #ccc;
					img {
						width: 100%;
						height: auto;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						margin: auto;
					}
				}
				.user-image:before {
					position: absolute;
					top: 50%;
					left: 50%;
					-o-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					-webkit-transform: translate(-50%, -50%);
					content: url(../images/svg/Icon_Video.svg);
					width: 75px;
					color: #fff;
					text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
				}
				.user-details {
					color: #fff;
					text-align: center;
					padding: 2% 4%;
					position: relative;
					min-height: 96px;
					display: table;
					width: 100%;
					height: 102px;
					@include media-breakpoint-down(sm) {
						min-height: 130px;
						height: 130px;
					}
					@include media-breakpoint-down(sm) {
						min-height: 150px;
						height: 150px;
					}
					.test_UserDetailsubDiv {
						display: table-cell;
						vertical-align: middle;
					}
					.user-title{
						color:#FFFFFF;
						font-size:24px;
						font-family:$font-SourceSans-Regular;
					}
					.user-designation {
						font-size: 18px;
						font-family:$font-SourceSans-Semibold;
						text-transform: uppercase;
						width: 92%;
                        margin: 0 auto;
						@include media-breakpoint-down(md) {							
							padding:10px;
							font-family:$font-SourceSans-Semibold;
						}
					}
					i {
						position: absolute;
						right: 10px;
						bottom: 20px;
						transform: rotate(45deg);
					}
				}			
				.cr-orange {
					background-color: #F36A30;
				}
				.cr-sky-blue {
					background-color: #009DD6;
				}
				.cr-lavendar {
					background-color: #7F679F;
				}			
			}
	}
	.slider-testi-for-parent {
		position:relative;
		width: 750px;
		margin: 0 auto;
		@include media-breakpoint-down(sm){
			width: 100%;
			padding-top:35px;
			padding-bottom:30px;
		}
		.close-button {
			padding: 0;
			background-color: transparent;
			border: 0;
			font-size: 34px;
			font-weight: 700;
			line-height: 15px;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			opacity: .5;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			z-index: 1800;
			position: absolute;
			right: 60px;
			top:10px;
			@include media-breakpoint-down(sm){
				top:0px;
			}
		}
		.slick-list{
			width: 630px;
			margin: 0 auto;
		}
		.slider {
			z-index: 1500;
			padding-top:60px;
			@include media-breakpoint-down(sm){
				padding-top:35px;
			}
		}
		.testimonial-for-main {
			.brightcove-video-main {
				.video-js {
					width: 100%;
					margin: 0 auto;
					height: 355px;
					@include media-breakpoint-down(sm){
						height: 197px;
					}
				}				
			}
			.user-details {
				padding: 2%;
				color: #fff;
				@include media-breakpoint-down(sm){
					padding: 20px;
				}
				.user-title {
					font-size: 24px;
					line-height:35px;
					@include media-breakpoint-down(sm){
						font-size: 24px;
						font-family:$font-SourceSans-Regular;
					}
				}
				.user-content {
					border-left: 1px solid #fff;
					font-family:$font-SourceSans-Semibold;
					.user-designation{
						font-size: 18px;	
						line-height:35px;
						text-transform: uppercase;
					}
					.user-des {
						font-size: 18px;
						padding-top: 5px;							
					}
				}
			}
			.cr-orange {
				background-color: #F36A30;
			}
			.cr-sky-blue {
				background-color: #009DD6; 
			}
			.cr-lavendar {
				background-color: #7F679F;
			}			
		}
	}	
	@include media-breakpoint-down(sm) { 
		.slider-nav-parent {
			.slick-slide {
				.cr-light-green {
					background-color: #59a63f;
				}
				.cr-sky-blue {
					background-color: #009DD6;
				}
				.cr-lavendar {
					background-color: #7F679F;
				}
				.slider-title, .slider-bottom .trophy-text{				
					color: #fff;			
				}
				.cr-orange {
					background-color: #F36A30;				
				}
				.cr-pink {
					background-color: #D0118C;				
				}
				.slider-bottom .trophy-img{
					width:64px;
					height:58px;
					position: absolute;
					top: -4%;
					right: 7%;
					font-size: 58px;
					color: #a09f9f;
					background-image: url(../images/svg/icon_trophy_white.svg);
					background-repeat: no-repeat;
					background-size:65%;
				}
				.cultureAwardsDefaultLogo {
					display: none;
				}
				.cultureAwardsWhiteLogo {
					display: block;
				}
			}
		}
	
	}
}

@media only screen and (max-width: 680px) {
	.path-culture {
		.slider-nav-parent, .slider-testi-nav-parent{
			.slider-nav{
				.slick-prev, .slick-next {
					display: none !important;
				}
			}
		}
	}
	
}
@media only screen and (max-width: 767px) {
	.path-culture {
		.awards-and-recognition{
			padding-left:0px !important;
			padding-right:0px !important;
		}
		.right-content .inner-content img{
			width: 220px;
		}
		.culture-title p:last-child {
			border-bottom: 4px solid #FFFFFF;
			margin-bottom: 5%;
		}
		.culture-title a {
			color:#F36A30;
			font-family:$font-SourceSans-Semibold;
			display:block;
			width:100%;
		}
		.desktop-hide {
			display: block;
		}
		.mobile-hide {
			display: none !important;
		}
		.culture_sec_banner .second_content_culture {
			background-color: #886db0;
			.second_content_div {
				padding: 15px 0px 20px 15px;
			}
		}
		.erg_heading {
			text-align: left;
			padding:0px 15px;
		}
		.erg_groups .accordion .card-header {
			padding:4px 0px 5px 0px;
			background-color: #fff !important;
			border-bottom: 1px solid rgba(0, 0, 0, 0.125);
			overflow:auto;
			a.collapsed {
				color: #212529;
				span.arrow {
					float: right;
					display: inline-block;
					width: 12px;
					height: 12px;
					position: relative;
					border-radius: 2px;
					-webkit-transform: rotate(-135deg);
					-o-transform: rotate(-135deg);
					transform: rotate(-135deg);
					background: #fff;
					margin-top: 5px;
					border-top: 3px solid #ccc;
					border-left: 3px solid #ccc;
				}
			}
			a{
				font-size:18px;
				font-family:$font-sharpSans-Semibold;
				padding: 10px 0px;
				color: #F36A30;
				z-index:500;
				width:100%;
				float:left;
				text-decoration:none;
				span.arrow {
					float: right;
					display: inline-block;
					width: 12px;
					height: 12px;
					position: relative;
					right:3px;
					border-radius: 2px;
					-webkit-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
					margin-top: 9px;
					border-top: 3px solid #F36A30;
					border-left: 3px solid #F36A30;
				}				
			}
		}
		.erg_groups{
			font-size:18px;
			font-family:$font-sharpSans-Light;
		}
		.slider-testi-nav-parent{
			.slider-nav{
				.slick-prev, .slick-next {
					display: none !important;
				}
			}
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.path-culture {
		.awards-and-recognition{
			padding-left:0px !important;
			padding-right:0px !important;
		}
		.container-fluid-lg {
			padding: 0px 15px;
		}
		.culture_testimonials {
			padding: 50px 0px 80px;
		}
		.culture-title p{
			overflow:auto;
		}
		.culture-title a {
			color:#F36A30;
			font-family:$font-SourceSans-Semibold;
			width:100%;
			float:left;
		}
		.slider-nav-parent {
			.slick-list {
				width:85%;
			}
			.slick-next {
				right:10px;
			}
			.slick-prev {
				left: 10px;
			}
			.single-slider-main {
				.slider-title {
					padding-top:0px;
					font-size: 18px;
					line-height: 30px;
				}
			}
			.slider-bottom {
				.trophy-img {
					width: 35px;
					height: 32px;
					position: absolute;
					top: 9%;
					right: 13%;
					font-size: 50px;
				}
			}
			.single-slider-main:hover {
				.trophy-img {
					width: 35px !important;
					top: 9% !important;
					right: 13% !important;
				}
			}
		}
		.culture_awards_recognition {
			.carousel-control-next-icon, .carousel-control-prev-icon {
				display:block;
			}
		}
		.culture-title a {
			color: #F36A30;
		}
		.desktop-hide {
			display: block;
		}
		.mobile-hide {
			display: none;
		}
		.culture_sec_banner .second_content_culture {
			background-color: #dedede;
			.second_content_div {
				margin: 2% auto;			
				.text-white {
					color: #000 !important;
				}
			}
		}
		.erg_groups .accordion .card-header {
			background-color: #fff !important;
			border-bottom: 1px solid rgba(0, 0, 0, 0.125);
			overflow:auto;
			a.collapsed {
				color: #212529;
				span.arrow {
					float: right;
					display: inline-block;
					width: 12px;
					height: 12px;
					position: relative;
					border-radius: 2px;
					-webkit-transform: rotate(-135deg);
					-o-transform: rotate(-135deg);
					transform: rotate(-135deg);
					background: #fff;
					margin-top: 5px;
					border-top: 3px solid #ccc;
					border-left: 3px solid #ccc;
				}
			}
			a{
				font-size:18px;
				font-family:$font-sharpSans-Semibold;
				padding: 10px 0px;
				color: #F36A30;
				width:100%;
				float:left;
				text-decoration:none;
				span.arrow {
					float: right;
					display: inline-block;
					width: 12px;
					height: 12px;
					position: relative;
					border-radius: 2px;
					right:3px;
					-webkit-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
					margin-top: 9px;
					border-top: 3px solid #F36A30;
					border-left: 3px solid #F36A30;
				}				
			}
		}
		.erg_groups{
			font-size:18px;
			font-family:$font-sharpSans-Light;
		}
		.slider-for-parent {			
			.slider-right {
				min-height: 378px;
				.slider-right-title {
					h2 {
						font-size:1rem;
					}
				}				
				.slider-body {
					font-size: 13px;
					line-height: 15px;
				}
			}
		}
		.slider-testi-nav-parent{
			.slider-nav{
				.slick-prev, .slick-next {
					display: none !important;
				}
			}
		}
	}
}

@media only screen and (min-width: 992px) {
	.path-culture {
		.right-content .inner-content img{
			width: 200px;
		}
		.desktop-hide {
			display: none;
		}
		.mobile-hide {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
		}
		.culture_sec_banner .second_content_culture {
			background-color: #886db0;
			.second_content_div {
				  padding: 9% 50px 7% 100px;
			}
		}
		.erg_heading {
			text-align: center;
		}
		.carousel-control-next-icon, .carousel-control-prev-icon {
			display:block;
		}
	}
}
@media only screen and (max-width: 400px) {
	.testimonial-for-main {
		.brightcove-video-main {
			.video-js {
				height: 180px !important;
			}				
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
	.path-culture {
		.slider-for-parent {
			.single-slider-img {
				.slider-left {
					height: 423px;
				}
			}
			.slider-right {
				min-height: 423px;
			}
		}
		.testimonial-nav-main:after {
			transform: rotate(2deg);
		}
		.testimonial-nav-main:before {
			transform: rotate(-2deg);
		}
		.slider-nav-parent {
			.single-slider-main {
				min-height:200px;
				.slider-title {
					line-height:28px;
				}
				.cultureAwardsDefaultLogo, .cultureAwardsWhiteLogo {
					width:100px;
				}
			}
			.single-slider-main:hover:before, .single-slider-main:hover:after {
				box-shadow: 0 19px 11px #777;
			}
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1100px) {
	.path-culture {
		.culture_sec_banner .second_content_culture {
			.second_content_div {
				padding: 30px 30px 30px 20px;
			}
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1199px) {
	.path-culture {
		.slider-nav-parent {
			.slick-list {
				width: 90%;
			}
			.single-slider-main {
				min-height:250px;
			}
		}
		.slider-for-parent {
			.single-slider-img {
				.slider-left {
					background-size:35% 50%;
				}
			}
		}
		.culture_sec_banner .second_content_culture {
			background-color: #886db0;
		}
		.slider-testi-nav-parent {
			.slick-list {
				width: 90%;
			}
			.slick-next {
				right: 10px;
			}
			.slick-prev {
				left: 10px;
			}
		}
	}
}