/**
 * @file
 * Vertical tabs component.
 */

/* Vertical Tabs Reset */
.vertical-tabs__menu {
  margin: -1px 0 -1px; /* LTR */
  padding: 0;
}
[dir="rtl"] .vertical-tabs__menu {
  margin-left: 0;
  margin-right: -1em;
  /* This is required to win specificity over [dir="rtl"] .region-content ul */
  padding: 0;
}
